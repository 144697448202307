import React, { Component } from "react";
import styles from "./Timer.module.scss";
import Wrapper from "../../hoc/Wrapper/Wrapper";
import { matchPath } from "react-router";
import { withRouter } from "react-router-dom";
import Loading from "../UI/Loading/Loading";

class Timer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
      height: window.innerHeight,
      timerOn: false,
      timerTime: {
        seconds: null,
        minutes: null,
        hours: null,
      },
    };
    this.isMobile = this.state.width < 768;
    this.isMobileLandscape =
      this.state.width <= 1023 && this.state.height < 768;
    this.isTablet = this.state.width <= 1023;
  }

  //Convert timer to readable text for timer readout on turnout page
  readableTimerText(handleTimerText) {
    const seconds = parseInt(this.state.timerTime.seconds);
    const minutes = parseInt(this.state.timerTime.minutes);
    const hours = parseInt(this.state.timerTime.hours);
    const readableTimerString =
      (hours ? hours + " hours" : "") +
      (minutes ? minutes + " minutes" : "") +
      (seconds + " seconds");
    handleTimerText(readableTimerString);
  }

  componentDidMount() {
    this.Timer();
    if (this.props.eventStatus === "Open") {
      this.interval = setInterval(this.Timer, 1000);
    }
  }

  componentDidUpdate() {
    // start the timer if the event gets restarted and an interval has not already been set
    if (this.props.eventStatus === "Open") {
      if (!this.interval) {
        this.interval = setInterval(this.Timer, 1000);
      }
    }
    if (this.props.readableTimerText) {
      this.readableTimerText(this.props.readableTimerText);
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  // converts date/time so Safari reads it properly
  convertDate = (date) => {
    if (date) {
      return date
        .replace(/-/g, "/")
        .replace("T", " ")
        .replace(/\..*|\+.*/, "");
    } else {
      return null;
    }
  };

  Timer = () => {
    let InitialTimestamp = this.props.initialTimestamp;

    // Check if the intial timestamp is an array, it will check to determine what is
    // the earliest time stamp in the messages array
    if (Array.isArray(this.props.initialTimestamp)) {
      const messageLength = this.props.initialTimestamp.length;
      // Get the last item in the array assuming that is the earliest timestamp
      InitialTimestamp =
        this.props.initialTimestamp[messageLength - 1].messageInputTimeStamp;

      // Do a quick check to determine if there is more than one array item
      if (messageLength > 0) {
        const earliestTimeStamp = new Date(InitialTimestamp);
        this.props.initialTimestamp.forEach((item) => {
          const checkingTimeStamp = new Date(item.messageInputTimeStamp);
          if (earliestTimeStamp > checkingTimeStamp) {
            InitialTimestamp = item.messageInputTimeStamp;
          }
        });
      }
    }

    const currentDate = new Date();
    const alertDate = new Date(this.convertDate(InitialTimestamp));
    const timeDiff = currentDate.getTime() - alertDate.getTime();
    let Seconds = ("0" + (Math.floor(timeDiff / 1000) % 60)).slice(-2);
    let Minutes = ("0" + (Math.floor(timeDiff / 60000) % 60)).slice(-2);
    let Hours = Math.floor(timeDiff / 3600000);

    this.setState({
      timerTime: {
        seconds: Seconds,
        minutes: Minutes,
        hours: Hours,
      },
    });
  };

  render() {
    const alertBanner = this.props.alertBanner && styles.alertBanner;
    const dashboardHeader =
      this.props.dashboardHeader && styles.dashboardHeader;
    const turnoutHeader = this.props.turnoutHeader && styles.turnoutHeader;
    const isDashboard =
      !!matchPath(this.props.location.pathname, "/dashboard") ||
      !!matchPath(this.props.location.pathname, "/turnout");

    return (
      <Wrapper>
        {this.props.pagerMessage ? (
          <div
            className={
              [styles.timer, styles.pagerMessage, dashboardHeader].join(" ") +
              " " +
              (this.props.showMobileView && styles.mobileView)
            }
          >
            <div className={styles.stopwatch}>
              <div
                className={
                  isDashboard ||
                  this.isMobile ||
                  this.isMobileLandscape ||
                  this.isTablet
                    ? styles.stopwatch_display +
                      "txt4" +
                      (this.props.homeMessage && styles.homeTimer)
                    : styles.stopwatch_display +
                      " enableCopyPaste" +
                      (this.props.homeMessage && styles.homeTimer)
                }
              >
                {this.props.eventStatus === "Open" ? (
                  <span className="enableCopyPaste">
                    {this.state.timerTime.hours}:{this.state.timerTime.minutes}:
                    {this.state.timerTime.seconds}&nbsp;
                  </span>
                ) : (
                  <span>MAX TIME REACHED</span>
                )}
              </div>
              <div
                className={
                  isDashboard ||
                  this.isMobile ||
                  this.isMobileLandscape ||
                  this.isTablet
                    ? styles.heading +
                      "txt4" +
                      (this.props.homeMessage && styles.homeTimer)
                    : styles.heading +
                      " " +
                      (this.props.homeMessage && styles.homeTimer)
                }
              >
                <span className="enableCopyPaste">SINCE ALERT</span>
              </div>
            </div>
          </div>
        ) : (
          <div className={[styles.timer, alertBanner].join(" ")}>
            <div className={styles.stopwatch}>
              {this.props.eventStatus === "Open" ? (
                !turnoutHeader ? (
                  <Wrapper>
                    <div
                      className={
                        styles.stopwatch_display +
                        " " +
                        (isDashboard && " txt4")
                      }
                    >
                      <span>
                        {this.state.timerTime.hours}:
                        {this.state.timerTime.minutes}:
                        {this.state.timerTime.seconds}&nbsp;
                      </span>
                    </div>
                    <div
                      className={
                        styles.heading + " " + (isDashboard && " txt4")
                      }
                    >
                      <span>SINCE ALERT</span>
                    </div>
                  </Wrapper>
                ) : (
                  <Wrapper>
                    <div
                      className={
                        styles.turnoutHeader + "  " + (isDashboard && " txt4")
                      }
                    >
                      <span>
                        {this.state.timerTime.hours}:
                        {this.state.timerTime.minutes}:
                        {this.state.timerTime.seconds}
                      </span>
                    </div>
                  </Wrapper>
                )
              ) : this.props.eventStatus === "Closed" ? (
                <div className={styles.stopwatch_display}>
                  <span>TIMER DISABLED</span>
                </div>
              ) : (
                <Loading />
              )}
            </div>
          </div>
        )}
      </Wrapper>
    );
  }
}

export default withRouter(Timer);
