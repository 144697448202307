import React from "react";
import Wrapper from "../../hoc/Wrapper/Wrapper";
import { ReactComponent as AppLOGO } from "../../assets/img/logos/SAS-APP.svg";
import ErrorBanner from "../../hoc/ErrorBanner/ErrorBanner";
import { Requests } from "../../api/IdentityServerRequests/Requests";
import styles from "./LoginView.module.scss";
import { AuthService } from "../../api/Authentication/AuthService";
import { ErrorHandler } from "../../hoc/ErrorBanner/ErrorHandler";
import copy from "../../assets/copy/copy";
import mapImage from "../../assets/img/location-disclosure-map.png";
import { matchPath } from "react-router";

/**
 * This screen displays an explanation of the way we use users' location data and how we store it.
 * It must be accepted to trigger the request to get user's location permission.
 */
export default class LocationDisclosure extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: null,
    };
    this.Requests = new Requests();
    this.errorHandler = new ErrorHandler();
    this.authService = new AuthService();
  }

  componentDidMount = () => {
    if (
      !window.cordova ||
      window.localStorage.getItem("seenLocationDisclosure") === "true"
    ) {
      // do not redirect to home if already in event details page
      if (!!matchPath(this.props.history.location.pathname, "/eventdetails")) {
        this.props.history.push({
          pathname: this.props.history.location.pathname,
        });
      } else {
        // Don't show location disclosure on desktop or if user has seen the location disclosure page already.
        this.goToHome();
      }
    }
  };

  /**
   * Called if user clicks on Deny.
   * Marks seenLocationDisclosure as true, sets share location as false in the DB and goes home.
   * Requests android users permission for Android Auto
   */
  denyDisclosure = () => {
    this.setShareMyLocation(false);
    this.seenDisclosure();
    this.goToHome();
  };

  /**
   * Called if user clicks on Accept.
   * Requests location permission from user, marks seenLocationDisclosure as true, sets share location as true in the DB and goes home.
   * Requests android users permission for Android Auto
   */
  acceptDisclosure = () => {
    if (window.cordova) {
      // Popup to ask for user permission
      window.BackgroundGeolocation.requestPermission();
    }
    this.setShareMyLocation(true);
    this.seenDisclosure();
    this.goToHome();
  };

  /**
   * Goes to home screen.
   */
  goToHome = () => {
    this.props.history.push({
      pathname: "/home",
    });
  };

  /**
   * Marks seenLocationDisclosure as true.
   */
  seenDisclosure = () => {
    window.localStorage.setItem("seenLocationDisclosure", true);
  };

  /**
   * Sets share my location in the database to either true or false.
   * @param {Boolean} status Whether or not share my location is on.
   */
  setShareMyLocation = (status) => {
    this.Requests.callAPI(this.Requests.setShareMyLocation, status).then(
      (data) => {
        if (!(data && data.status && data.status === 200)) {
          let ErrorMessage =
            copy.settings.profile.shareMyLocationUpdateError +
            ` (Error #${copy.errorCodes.shareMyLocationUpdateError})`;
          this.setState(
            {
              errorMessage: ErrorMessage,
            },
            () => {
              setTimeout(
                function () {
                  this.setState({ errorMessage: null });
                }.bind(this),
                5000
              );
            }
          );
        }
      }
    );
  };

  render() {
    return (
      <Wrapper>
        <ErrorBanner
          isVisible={this.state.errorMessage ? true : false}
          ErrorMessage={this.state.errorMessage}
          onTop={true}
        />
        <div className={styles.pageHeader}></div>
        <AppLOGO />
        <div className={"box_default " + styles.termsofuse}>
          {/* Text of header - in copy file */}
          <h2>{copy.location.title}</h2>
          {/* Text of the disclosure - in copy file */}
          <img className={styles.map} src={mapImage} alt={"map"} />
          <p>
            <b>{copy.location.disclosure1}</b>
          </p>
          <p>{copy.location.disclosure2}</p>
          <div className={styles.buttonWrapper}>
            <button
              aria-label="I accept the location disclosure"
              content="Accept"
              className="btn_primary"
              onClick={this.acceptDisclosure}
            >
              {copy.location.acceptBtn}
            </button>
            <button
              aria-label="I do not accept the location disclosure"
              content="Deny"
              className="btn_primary"
              onClick={this.denyDisclosure}
            >
              {copy.location.denyBtn}
            </button>
          </div>
        </div>
      </Wrapper>
    );
  }
}
