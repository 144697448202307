import React from "react";
import Wrapper from "../../hoc/Wrapper/Wrapper";
import { Requests } from "../../api/IdentityServerRequests/Requests";
import Loader from "../../components/UI/Loading/Loading";
import InfiniteScroll from "react-infinite-scroll-component";
import ErrorBanner from "../../hoc/ErrorBanner/ErrorBanner";
import { format } from "date-fns";

// Page Component
import RefreshTimer from "../../components/Timer/RefreshTimer";
import PagerEvent from "../../components/PagerEvents/PagerEvent";
import ActiveEventDashboardView from "./ActiveEventDashboardView";
import HalfHrView from "../../components/Availability/HalfHourView";
import GroupThreshold from "../../components/Availability/GroupThreshold";

// UIs & styles
import Button from "../../components/UI/Button/Button";
import { Dropdown } from "semantic-ui-react";

import styles from "./DashboardView.module.scss";
import HideNavigationIcon from "../../assets/img/icons/hide_nav_icon_green.svg";
import copy from "../../assets/copy/copy.json";

export default class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
      isMobile: window.innerWidth <= 1024,
      updateEventCounter: 0, // This will be used to update the details of the selected event
      isCheckingForEvents: false, // This is used to display the loader while the API call to fetch events data is going through
      getActivePagerMessagesIsFinished: false,
      isLoadingAllEvents: true,
      isLoadingEvent: true,
      loadAllEventsWithoutRefreshing: false,
      addingLatestEventsToStartOfArray: false,
      allActiveEvents: null,
      dateTimeAfter: null,
      currentActiveEventIsLocked: false,
      currentActiveEvent: null,
      hidePageNavigation: false,
      viewAvailabilityPermission: null,
      hasMoreMessagesToLoad: true,
      expandedMap: false,
      // availability
      day: null, // days
      week: null, // one week
      availabilityPagingGroupsOption: null,
      pagingGroupsPartOfWithAvailability: [],
      // EVENT FILTER
      showEventFilter: false,
      allTypes: true,
      Emergency: false,
      NonEmergency: false,
      Admin: false,
      messageType: null,
      agency: [], // event filter input
      pagingNumber: [], // event filter input
      userAgenciesOption: null, // Agency data that is in a suitable format for the filter
      userPagingGroupsOption: null, // Paging groups data that is in a suitable format for the filter
      defaultPagingGroup: null,
      errorMessage: null, // Error message for the banner
    };
    this.Requests = new Requests();
    this.messageTimeout = null;
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    window.addEventListener("orientationchange", this.handleWindowSizeChange);
    window.addEventListener("resize", this.handleWindowSizeChange);
    window.sessionStorage.setItem("redirectHomeFromEvent", "");
    this.getPagingGroupsWithAvailability();
    this.setState({
      viewAvailabilityPermission: this.getViewAvailabilityPermission(),
    });

    // Initial load of pager event
    this.getActivePagerMessages();
    this.prepareFilterData();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
    clearInterval(this.messageTimeout);
  }

  /**
   * Called when a page refresh is triggered by teh ActiveEventDashboardView
   */
  handleRefresh = () => {
    // Remake API calls to get paging groups for the filter if required
    if (!this.state.userPagingGroupsOption) {
      this.prepareFilterData();
    }

    // Remake API calls to availability if required
    if (!this.state.day || !this.state.week) {
      this.selectPagingGroup(
        this.state.availabilityPagingGroupsOption[0].value
      );
    }
    // Remake API call to get all active pager messages if required (i.e. if API call fails on initial page load)
    if (!this.state.allActiveEvents) {
      this.getActivePagerMessages();
    }
  };

  /**
   * This function determines whether the user has permissions to view the availability tab
   */
  getViewAvailabilityPermission = () => {
    // See if the user is a paging group owner
    let isPagingGroupOwner = false;
    Object.entries(this.props.pagingGroupsOwned).forEach((entry) => {
      if (entry[1].length > 0 && entry[0] !== "AV") {
        isPagingGroupOwner = true;
      }
    });

    // The user has permission to view availability if they are either a superuser, admin, manager or PGO
    // Now also not only in AV
    return (
      this.props.userRoleAgency.indexOf("SuperUser") !== -1 ||
      this.props.userRoleAgency.indexOf("Administrator_CFA") !== -1 ||
      this.props.userRoleAgency.indexOf("Manager_CFA") !== -1 ||
      this.props.userRoleAgency.indexOf("Administrator_SES") !== -1 ||
      this.props.userRoleAgency.indexOf("Manager_SES") !== -1 ||
      this.props.userRoleAgency.indexOf("Administrator_AV") !== -1 ||
      this.props.userRoleAgency.indexOf("Manager_AV") !== -1 ||
      isPagingGroupOwner
    );
  };

  handleWindowSizeChange = () => {
    this.setState({
      width: window.innerWidth,
      isMobile: window.innerWidth <= 1024,
    });
    if (document.body.classList.contains("fullscreen-map-mode")) {
      document.body.classList.remove("fullscreen-map-mode");
    }
  };
  redirectToReport = () => {
    this.props.history.push({
      pathname: "/dashboard/report",
    });
  };

  /**
   * This function is used to prepare the data for the paging groups filter
   */
  getPagingGroupsWithAvailability = () => {
    // Fetch all the paging groups the user is a part of and has availability enabled
    let body = {
      availabilityEnabled: true,
    };
    // Request to get an array of the agencies the user belongs to
    this.Requests.callAPI(this.Requests.postUserPagingGroups, body).then(
      (data) => {
        if (data && data.status && data.status === 200) {
          let pagingGroupsPartOfWithAvailability = {};
          pagingGroupsPartOfWithAvailability.all = [];
          this.props.userAgencies.forEach((item) => {
            pagingGroupsPartOfWithAvailability[item] = [];
          });
          // This sorts all paging groups by their respective agencies into the pagingGroupsPartOf object.
          data.data.forEach((item) => {
            if (
              item.agency &&
              pagingGroupsPartOfWithAvailability[item.agency]
            ) {
              pagingGroupsPartOfWithAvailability[item.agency].push({
                key: item.number,
                text: item.name,
                value: item.number,
              });
              // Paging groups with no agency are placed in the 'all' child.
            } else if (!item.agency) {
              pagingGroupsPartOfWithAvailability.all.push({
                key: item.number,
                text: item.name,
                value: item.number,
              });
            }
          });
          this.setState(
            {
              pagingGroupsPartOfWithAvailability:
                pagingGroupsPartOfWithAvailability,
            },
            () => {
              // Now prepare the paging group data
              this.preparePagingGroupData();
            }
          );
        } else {
          let ErrorMessage =
            copy.availability.gettingAvailabilityGroupsErrorMessage;
          this.setState(
            {
              pagingGroupsPartOfWithAvailability: false,
              errorMessage: ErrorMessage,
            },
            () => {
              setTimeout(
                function () {
                  this.setState({ errorMessage: null });
                }.bind(this),
                5000
              );
            }
          );
        }
      }
    );
  };

  /**
   * This function is used to prepare the data for the paging groups filter
   */
  preparePagingGroupData = () => {
    // Getting all agencies the user has admin rights to
    let adminAgencies = [];
    // If they are superuser, they will have admin rights to all agencies
    if (
      this.props.userRoleAgency !== undefined &&
      this.props.userRoleAgency.indexOf("SuperUser") !== -1
    ) {
      adminAgencies = ["CFA", "AV", "SES"];
    } else {
      let agencies = ["CFA", "SES", "AV"];
      agencies.forEach((agency) => {
        // Check if they are an Admin of an Agency, if so add that agency.
        if (
          this.props.userRoleAgency.indexOf("Administrator_" + agency) !== -1
        ) {
          adminAgencies.push(agency);
        }
        //Check if they are a manager of an Agency, if so add that agency.
        if (this.props.userRoleAgency.indexOf("Manager_" + agency) !== -1) {
          adminAgencies.push(agency);
        }
      });
    }

    // Getting all paging groups the user has access to
    let pagingGroups = [];
    adminAgencies.forEach((agency) => {
      // If the user has permission to view paging groups of that agency, add the paging groups to the filter
      if (
        this.state.pagingGroupsPartOfWithAvailability[agency] !== undefined &&
        this.state.pagingGroupsPartOfWithAvailability[agency].length > 0
      ) {
        pagingGroups = pagingGroups.concat(
          this.state.pagingGroupsPartOfWithAvailability[agency]
        );
      }
    });
    Object.entries(this.props.pagingGroupsOwned).forEach((entry) => {
      // If user is not admin of the agency but owns paging groups of that agency, add the paging groups they own to the filter
      // Check to see if the paging group the user is an owner has availabilityenabled
      if (adminAgencies.indexOf(entry[0]) === -1 && entry[1].length > 0) {
        Object.entries(entry[1]).forEach((group) => {
          if (group[1] && group[1].availabilityenabled === "true") {
            // Adding paging group to filter
            pagingGroups = pagingGroups.concat(group[1]);
          }
        });
      }
    });

    // Also add personal paging groups to the filter
    if (
      this.state.pagingGroupsPartOfWithAvailability &&
      this.state.pagingGroupsPartOfWithAvailability.all
    ) {
      pagingGroups = pagingGroups.concat(
        this.state.pagingGroupsPartOfWithAvailability.all
      );
    }

    this.setState(
      {
        availabilityPagingGroupsOption: pagingGroups,
      },
      () => {
        if (
          this.state.availabilityPagingGroupsOption &&
          this.state.availabilityPagingGroupsOption[0]
        ) {
          let defaultPagingGroup =
            this.state.availabilityPagingGroupsOption[0].value;
          this.setState({ defaultPagingGroup: defaultPagingGroup });
          this.selectPagingGroup(
            this.state.availabilityPagingGroupsOption[0].value
          );
        } else {
          // No paging groups available
          this.setState({ defaultPagingGroup: false });
        }
      }
    );
  };

  updateEventCounter = () => {
    this.setState({
      isCheckingForEvents: true,
      updateEventCounter: this.state.updateEventCounter + 1,
    });
  };

  triggerPagination = () => {
    if (
      this.state.allActiveEvents.length >= 10 &&
      this.state.hasMoreMessagesToLoad
    ) {
      let messageId;
      // let messageDateTimeBefore; Not being used anymore
      if (this.state.allActiveEvents && this.state.allActiveEvents.length > 1) {
        messageId =
          this.state.allActiveEvents[this.state.allActiveEvents.length - 1].Id;

        // messageDateTimeBefore = this.state.allActiveEvents[  Not being used anymore
        //   this.state.allActiveEvents.length - 1
        // ].messageInputTimeStamp;
      }

      const body = {
        // dateTimeAfterOrEqual: this.state.dateTimeAfter,
        messageType: this.state.messageType ? this.state.messageType : null,
        agency: this.state.agency.length === 0 ? null : this.state.agency,
        pagingNumber:
          this.state.pagingNumber.length === 0 ? null : this.state.pagingNumber,
        messageIdBefore: messageId,
        includeAttendanceCount: true,
        // dateTimeBeforeOrEqual: messageDateTimeBefore,
        // dateTimeAfterOrEqual: this.state.dateTimeAfter,
        isHomePage: true,
        skip: 0,
        take: 10,
      };

      this.setState(
        {
          addingLatestEventsToStartOfArray: false,
        },
        () => {
          // Get more pagination events
          this.getActivePagerMessages(body);
        }
      );
    } else {
      if (this.state.hasMoreMessagesToLoad) {
        this.setState({
          hasMoreMessagesToLoad: false,
        });
      }
    }
  };

  /**
   * This function will return the active pager messages
   */
  getActivePagerMessages = (Body) => {
    let msg = {};

    if (Body) {
      msg = Body;
    } else {
      msg = {
        messageType: this.state.messageType,
        includeAttendanceCount: true,
        agency: this.state.agency.length === 0 ? null : this.state.agency,
        pagingNumber:
          this.state.pagingNumber.length === 0 ? null : this.state.pagingNumber,
        isHomePage: true,
        skip: 0,
        take: 10,
      };
    }

    this.setState(
      {
        getActivePagerMessagesIsFinished: false,
        isLoadingAllEvents:
          Body || this.state.loadAllEventsWithoutRefreshing ? false : true,
        isLoadingEvent:
          Body || this.state.loadAllEventsWithoutRefreshing ? false : true,
        // isCheckingForEvents: true,
        // allPagerMessages: !Body && []
      },
      () => {
        this.Requests.callAPI(this.Requests.postPagerMessages, msg).then(
          (data) => {
            if (!data) {
              let ErrorMessage = copy.error_handling.activeEventsError;
              if (data && data.data && data.data.SASMessageClient) {
                ErrorMessage = data.data.SASMessageClient;
              }
              this.setState(
                {
                  errorMessage: ErrorMessage,
                  getActivePagerMessagesIsFinished: true,
                  isCheckingForEvents: false,
                  loadAllEventsWithoutRefreshing: false,
                },
                () => {
                  this.messageTimeout = setTimeout(
                    function () {
                      this.setState({ errorMessage: null });
                    }.bind(this),
                    5000
                  );
                }
              );
            } else {
              if (data && data.status && data.status === 200) {
                let allActiveEvents;

                if (data.data) {
                  // If there is no body, then that means this function was called as a result of a filter change or page initialisation, which means that
                  // no adding to the start/end of this.state.allActiveEvents is required
                  if (!Body) {
                    allActiveEvents = data.data;
                  }
                  //Adds latest events to the beginning of array
                  else if (this.state.addingLatestEventsToStartOfArray) {
                    if (data.data.length > 0) {
                      const allActiveEventsArray = this.state.allActiveEvents;
                      const Data = data.data;
                      allActiveEvents = Data.concat(allActiveEventsArray);
                    } else if (data.data.length === 0) {
                      allActiveEvents = this.state.allActiveEvents;
                      this.setState({
                        hasMoreMessagesToLoad: true,
                        loadAllEventsWithoutRefreshing: false,
                      });
                    }
                  }
                  //Loads all events or adds more events to end of array through pagination
                  else {
                    allActiveEvents = this.state.allActiveEvents.concat(
                      data.data
                    );
                    if (data.data.length === 0) {
                      this.setState({
                        hasMoreMessagesToLoad: false,
                        loadAllEventsWithoutRefreshing: false,
                      });
                    }
                  }

                  if (!Body) {
                    this.setState(
                      {
                        getActivePagerMessagesIsFinished: true,
                        isLoadingAllEvents: false,
                        isCheckingForEvents: false,
                        isLoadingEvent: false,
                        allActiveEvents: data.data[0] ? allActiveEvents : [],
                        // currentActiveEvent: data.data[0]
                        //   ? data.data[0].eventId
                        //   : null,
                        latestEvent: allActiveEvents[0]
                          ? allActiveEvents[0].eventId
                          : false,
                        hasMoreMessagesToLoad:
                          data.data.length < 10 ? false : true,
                        loadAllEventsWithoutRefreshing: false,
                      },
                      () => {
                        this.removeUnreadMessageIndicator();
                      }
                    );
                  } else {
                    if (data.data.length > 0) {
                      if (data.data.length < 10) {
                        this.setState({
                          hasMoreMessagesToLoad: false,
                          loadAllEventsWithoutRefreshing: false,
                        });
                      }
                      this.setState(
                        {
                          getActivePagerMessagesIsFinished: true,
                          allActiveEvents: allActiveEvents,
                          latestEvent: allActiveEvents[0]
                            ? allActiveEvents[0].eventId
                            : [],
                          isLoadingAllEvents: false,
                          isLoadingEvent: false,
                          loadAllEventsWithoutRefreshing: false,
                        },
                        () => {
                          this.removeUnreadMessageIndicator();
                        }
                      );
                    } else if (data.data.length === 0) {
                      this.setState(
                        {
                          getActivePagerMessagesIsFinished: true,
                          allActiveEvents: allActiveEvents,
                          isLoadingAllEvents: false,
                          isLoadingEvent: false,
                          loadAllEventsWithoutRefreshing: false,
                        },
                        () => {
                          this.removeUnreadMessageIndicator();
                        }
                      );
                    }
                  }
                }
              } else {
                let ErrorMessage =
                  copy.dashboard.messageAPIErrorMessage +
                  ` (Error #${copy.errorCodes.messageAPIErrorMessage})`;
                // if (data && data.data && data.data.SASMessageClient) {
                //   ErrorMessage = data.data.SASMessageClient;
                // }
                this.setState(
                  (prevState) => ({
                    getActivePagerMessagesIsFinished: true,
                    allActiveEvents: !prevState.allActiveEvents
                      ? false // If failure on initial load of the page
                      : prevState.allActiveEvents, // Failure when refreshing
                    isCheckingForEvents: false,
                    isLoadingAllEvents: false,
                    isLoadingEvent: false,
                    errorMessage: ErrorMessage,
                    loadAllEventsWithoutRefreshing: false,
                  }),
                  () => {
                    this.messageTimeout = setTimeout(
                      function () {
                        this.setState({
                          errorMessage: null,
                        });
                      }.bind(this),
                      5000
                    );
                  }
                );
              }
            }
          }
        );
      }
    );

    // this.getDateTime() // Get current date time for 24hr worth of messages when a user clicks 'show all alerts'
  };
  removeUnreadMessageIndicator = () => {
    const { allActiveEvents } = this.state;
    const isEventUnreadArray = allActiveEvents.map((event) => !event.dateRead);
    this.setState({ isEventUnreadArray });
  };

  callGetAllMessages = () => {
    this.setState(
      {
        loadAllEventsWithoutRefreshing: true,
      },
      () => {
        this.getActivePagerMessages();
      }
    );
  };

  /**
   * This function will be called whenever a new event card is clicked to update on the right
   */
  updateActiveEvent = (currentEvent) => {
    if (currentEvent.eventId) {
      this.setState({
        expandedMap: false,
        currentActiveEventIsLocked: true,
        currentActiveEvent: currentEvent.eventId,
      });
    }
  };

  removeLockedEvent = () => {
    this.setState({
      currentActiveEventIsLocked: false,
      currentActiveEvent: null,
      expandedMap: false,
    });
  };

  expandMap = () => {
    this.setState(
      (prevState) => ({
        expandedMap: !prevState.expandedMap,
      }),
      () => {
        if (this.state.expandedMap) {
          this.setState({
            currentActiveEventIsLocked: true,
            showEventFilter: false,
          });
        }
      }
    );
  };

  /**
   * This function prepares the data that is used for the event filter
   */
  prepareFilterData = () => {
    // Preparing agency data for the filter
    let userAgenciesOption = [];
    this.props.userAgencies.forEach((agency, index) => {
      userAgenciesOption.push({
        key: index,
        text: agency,
        value: agency,
      });
    });
    this.setState({
      userAgenciesOption: userAgenciesOption,
    });

    if (this.props.pagingGroupsPartOf) {
      // Set state
      this.setState({
        userPagingGroupsOption: this.props.pagingGroupsPartOf,
      });
    } else {
      let ErrorMessage =
        copy.dashboard.pagingGroupsFilterAPIErrorMessage +
        ` (Error #${copy.errorCodes.pagingGroupsFilterAPIErrorMessage})`;
      this.setState(
        { errorMessage: ErrorMessage, userPagingGroupsOption: false },
        () => {
          setTimeout(
            function () {
              this.setState({ errorMessage: null });
            }.bind(this),
            5000
          );
        }
      );
    }
  };

  /**
   * This function will toggle the event filter
   */
  toggleEventFilter = () => {
    this.setState((prevState) => ({
      showEventFilter: !prevState.showEventFilter,
    }));
  };

  /**
   * This function handles what happens when the user selects 'show all alerts'
   */
  AllTypes = () => {
    // Uncheck all checkboxes
    // Add all alert types to messageType array
    this.setState({
      allTypes: true,
      Emergency: false,
      NonEmergency: false,
      Admin: false,
      messageType: null,
    });
  };

  /**
   * This function handles what happens when one of the alter types is selected
   * type - the checkbox which was selected (Emergency, NonEmergency, Admin)
   */
  handleAlertTypeSelect = (type) => {
    // Clear pager alert types first
    if (this.state.allTypes === true) {
      this.setState({ messageType: [], allTypes: false });
    }
    this.setState(
      (prevState) => ({
        // Toggle checkbox that was selected
        [type]: !prevState[type],
      }),
      () => {
        // If the checkbox was checked
        if (this.state[type] === true) {
          // Add alert type admin to array
          let joined = this.state.messageType.concat(type);
          // If checkboxes have all been selected, then all alert types should be shown
          if (joined.length === 3) {
            this.setState({
              allTypes: true,
              Emergency: false,
              NonEmergency: false,
              Admin: false,
              messageType: null,
            });
          } else {
            // Update data
            this.setState({ messageType: joined });
          }
          // If the checkbox was unchecked
        } else {
          if (this.state.messageType !== null) {
            // Remove alert type from array
            var array = [...this.state.messageType]; // make a separate copy of the array
            var index = array.indexOf(type);
            if (index !== -1) {
              array.splice(index, 1);
              // If no boxes are checked, then all messages should be displayed
              if (array.length === 0) {
                this.setState({
                  allTypes: true,
                  Emergency: false,
                  NonEmergency: false,
                  Admin: false,
                  messageType: null,
                });
              } else {
                // Update data
                this.setState({ messageType: array });
              }
            }
          }
        }
      }
    );
  };

  /** This function renders the alert type filter
   */
  alertTypeFilters = () => {
    return (
      // all type radio btn
      <Wrapper>
        <label aria-label={copy.global.radioAll} className={styles.radio}>
          <input
            type="radio"
            name={copy.global.radioAll}
            checked={this.state.allTypes === true ? "checked" : ""}
            onChange={this.AllTypes}
          />
          <span className={styles.checkmark}></span>
          Show all alerts
        </label>
        {/* alert type checkboxes */}
        <label aria-label={copy.global.emerg} className={styles.checkbox}>
          <input
            type="checkbox"
            name={copy.global.emerg}
            checked={this.state.Emergency === true ? "checked" : ""}
            onChange={() => {
              this.handleAlertTypeSelect("Emergency");
            }}
          />
          {copy.global.emerg}
          <span className={styles.checkmark}></span>
        </label>

        <label aria-label={copy.global.nonEmerg} className={styles.checkbox}>
          <input
            type="checkbox"
            name={copy.global.nonEmerg}
            checked={this.state.NonEmergency === true ? "checked" : ""}
            onChange={() => {
              this.handleAlertTypeSelect("NonEmergency");
            }}
          />
          {copy.global.nonEmerg}
          <span className={styles.checkmark}></span>
        </label>

        <label aria-label={copy.global.admin} className={styles.checkbox}>
          <input
            type="checkbox"
            name={copy.global.admin}
            checked={this.state.Admin === true ? "checked" : ""}
            onChange={() => {
              this.handleAlertTypeSelect("Admin");
            }}
          />
          {copy.global.admin}
          <span className={styles.checkmark}></span>
        </label>
      </Wrapper>
    );
  };

  /**
   * This function handles what happens when the agency filter changes
   */
  handleAgencyFilterChange = (value) => {
    let tempPagingNumbers = this.state.pagingNumber;

    // If paging groups are currently being filtered
    if (tempPagingNumbers !== null && tempPagingNumbers.length !== 0) {
      // If an agency was removed
      if (
        value.length !== 0 &&
        this.state.agency !== null &&
        this.state.agency.length === value.length + 1
      ) {
        // // Get the removed agency
        let removedAgency = null;

        this.state.agency.forEach((agency) => {
          // Get the agency that was removed from the filter
          if (value.indexOf(agency) === -1) {
            removedAgency = agency;
          }
        });
        // Remove all paging groups from the filter that are part of that agency
        this.state.userPagingGroupsOption[removedAgency].forEach(
          (pagingGroup) => {
            let index = tempPagingNumbers.indexOf(pagingGroup.value);
            if (index !== -1) {
              tempPagingNumbers.splice(index, 1);
            }
          }
        );
        // If an agency was added when previously none were being filtered
      } else if (this.state.agency.length === 0 && value.length === 1) {
        let addedAgency = value[0];
        tempPagingNumbers = [];
        // Remove all paging groups from the filter that are not part of that agency
        this.state.userPagingGroupsOption[addedAgency].forEach(
          (pagingGroup) => {
            let index = this.state.pagingNumber.indexOf(pagingGroup.value);
            if (index !== -1) {
              tempPagingNumbers.push(pagingGroup.value);
            }
          }
        );
      }
    }

    // Update the events with the new agency filter applied
    this.setState({
      agency: value,
      pagingNumber: tempPagingNumbers,
    });
  };

  agencyAndPagingGroupFilter = () => {
    let pagingGroups = null;

    // If API call was successful and paging groups have been loaded, then render the filter
    if (this.state.userPagingGroupsOption) {
      // // Preparing paging group data for the filter
      let pagingGroupOption = [];
      // If user has not filtered by agency yet, they should be able to search all paging groups they are a part of
      if (this.state.agency === null || this.state.agency.length === 0) {
        // Go through all agencies the user is part of and add the paging groups to the search data
        this.props.userAgencies.forEach((agency) => {
          pagingGroupOption = pagingGroupOption.concat(
            this.state.userPagingGroupsOption[agency]
          );
        });
        pagingGroupOption = pagingGroupOption.concat(
          this.state.userPagingGroupsOption.all
        );
        // If the user has filtered by agency already, they should only be able to search the paging groups belonging to the
        // filtered agencies
      } else {
        // Go through all agencies the user is part of and add the paging groups to the search data
        this.state.agency.forEach((agency) => {
          pagingGroupOption = pagingGroupOption.concat(
            this.state.userPagingGroupsOption[agency]
          );
        });
      }

      // sort the paging groups in ascending order by name
      pagingGroupOption.sort((a, b) => {
        return a["text"].localeCompare(b["text"]);
      });

      pagingGroups = pagingGroupOption.length > 1 && (
        // this user must have more than 1 paging group for this filter to appear
        <div
          className={
            this.state.multiPagingGroup === true
              ? "hide pgroupDropdown"
              : "show pgroupDropdown " + styles.dropDownItem
          }
        >
          <span className={styles.dropdownLabel}>Paging Group</span>
          {this.state.multiPagingGroup === true && (
            <span
              aria-label="Multiple groups selected"
              className={styles.multi}
              onClick={this.changeBack}
            >
              <span>
                Multiple
                <span className={styles.desktopTxt}> groups </span> selected
              </span>
              <i aria-hidden="true" className="dropdown icon"></i>
            </span>
          )}
          <Dropdown
            // placeholder="Paging Group"
            fluid
            multiple
            search
            selection
            className={styles.dropdown}
            value={this.state.pagingNumber}
            options={pagingGroupOption}
            onChange={(e, { value }) => {
              this.setState({
                pagingNumber: value,
              });
            }}
          />
        </div>
      );
    }

    return (
      <Wrapper>
        {this.state.userAgenciesOption.length > 1 && (
          // this user must have more than 1 agency for this filter to appear
          <div
            className={
              this.state.multiAgency === true
                ? "hide agencyDropdown"
                : "show agencyDropdown " + styles.dropDownItem
            }
          >
            <span className={styles.dropdownLabel}>Agency</span>
            {this.state.multiAgency === true && (
              <span
                aria-label="Multiple agencies selected"
                className={styles.multi}
                onClick={this.changeBack}
              >
                <span>
                  Multiple
                  <span className={styles.desktopTxt}>agencies</span> selected
                </span>
                <i aria-hidden="true" className="dropdown icon"></i>
              </span>
            )}
            <Dropdown
              // placeholder="Agency"
              fluid
              multiple
              search
              selection
              className={styles.dropdown}
              value={this.state.agency}
              options={this.state.userAgenciesOption}
              on={"true"}
              onChange={(e, { value }) => this.handleAgencyFilterChange(value)}
            />
          </div>
        )}
        {/* Render paging group filter (if there are paging groups to show) */}
        {pagingGroups}
      </Wrapper>
    );
  };

  /**
   *
   * This function will render everything for the Event Statistice tab of the Dashboards View.
   */
  eventDashboardRender() {
    return (
      <div className={[styles.content_wrapper].join(" ")}>
        <div
          className={
            "col-one-third" +
            " " +
            styles.dashboardContent +
            " " +
            styles.pagerEventsColumn
          }
        >
          <div className={styles.eventsHeader}>
            <RefreshTimer
              updateEventCounter={this.updateEventCounter}
              getActivePagerMessages={this.callGetAllMessages}
              prepareFilterData={this.prepareFilterData}
              userPagingGroupsOption={this.state.userPagingGroupsOption}
              getActivePagerMessagesIsFinished={
                this.state.getActivePagerMessagesIsFinished
              }
            />
            {/* <div className={styles.refreshTimer + " refreshTimer"}>
              <div className={styles.heading}>
                {copy.dashboard.dashboardUpdate}
              </div>
              <div>
                {this.state.currentIntervalSeconds}{" "}
                {!this.state.isCheckingForEvents
                  ? copy.dashboard.dashboaradTimer
                  : ""}
              </div>
            </div> */}
            <div className={styles.eventFilterSection}>
              <Button
                label={copy.global.btnFilter}
                // content={copy.global.btnFilter}
                variant="btn_outline"
                styles={
                  "grey no_text" +
                  " " +
                  (this.state.showFilters === true ? styles.isActive : "")
                }
                icon="icon_filter"
                buttonClick={this.toggleEventFilter}
              />
            </div>
          </div>
          <div
            className={
              this.state.showEventFilter === true
                ? styles.filterControlsWrapper
                : "hidden"
            }
          >
            <div className={styles.filterControls + " cardBg2"}>
              <p>LIMIT RESULTS TO:</p>
              <div className={styles.holder}>{this.alertTypeFilters()}</div>
              <div className={styles.holder}>
                {this.agencyAndPagingGroupFilter()}
              </div>
              <div className={styles.buttonWrapper}>
                <Button
                  label={copy.global.btnReset}
                  content={copy.global.btnReset}
                  variant="btn_outline"
                  styles="btn_secondary"
                  buttonClick={() => {
                    this.setState(
                      {
                        agency: [],
                        pagingNumber: [],
                      },
                      () => {
                        this.AllTypes();
                      }
                    );
                  }}
                />
                <Button
                  label={copy.global.btnFilter}
                  content={copy.global.btnFilter}
                  variant="btn_outline green"
                  styles="btn_secondary"
                  buttonClick={this.getActivePagerMessages}
                />
              </div>
            </div>
          </div>
          <div id="pagerMessageContainer" className={styles.pagerEvents}>
            {/* The event state is null until the API to check for messages is complete. 
            So if its null, it will show the Spinner until the API call has returned. */}
            {/* {this.state.allActiveEvents ? (
              <PagerEvent
                event={this.state.allActiveEvents}
                buttonClick={this.updateActiveEvent.bind(this)}
              />
            ) : (
              <Loader />
            )} */}

            {this.state.allActiveEvents === null ||
            this.state.isLoadingAllEvents ? (
              <div className={styles.loadingContainer}>
                <Loader />
              </div>
            ) : this.state.allActiveEvents === false ? null : (
              <Wrapper>
                {this.state.isCheckingForEvents && (
                  <div className={styles.loadingEvents}>
                    <Loader />
                  </div>
                )}
                <InfiniteScroll
                  dataLength={this.state.allActiveEvents.length}
                  next={this.triggerPagination}
                  hasMore={this.state.hasMoreMessagesToLoad}
                  loader={
                    <div className={styles.pagerEventLoader}>
                      <Loader />
                    </div>
                  }
                  scrollableTarget="pagerMessageContainer"
                  // endMessage={
                  //   <div className={styles.pagerEventLoader}>
                  //     <span>{copy.global.noMessages}</span>
                  //   </div>
                  // }
                  scrollThreshold="100%"
                >
                  {this.state.allActiveEvents.length === 0 ? (
                    <h4>
                      <span className="noMessages">{copy.home.noMessages}</span>
                    </h4>
                  ) : (
                    <PagerEvent
                      event={this.state.allActiveEvents}
                      isEventUnreadArray={this.state.isEventUnreadArray}
                      buttonClick={this.updateActiveEvent}
                      lockedEvent={this.state.currentActiveEvent}
                    />
                  )}
                </InfiniteScroll>
              </Wrapper>
            )}
          </div>
        </div>
        <div
          className={
            "col-two-third" +
            " " +
            styles.dashboardContent +
            " " +
            styles.eventDetailsSection +
            " " +
            (this.state.expandedMap && styles.fullScreenMap)
          }
        >
          {this.state.latestEvent === false ? (
            <h4>
              <span className="noMessages">{copy.home.noMessages}</span>
            </h4>
          ) : (
            <ActiveEventDashboardView
              userAgencyInfo={this.props.userInfo.userAgencyInfo}
              expandMap={this.expandMap}
              isMapExpanded={this.state.expandedMap}
              removeLockedEvent={this.removeLockedEvent}
              eventIsLocked={this.state.currentActiveEventIsLocked}
              updateEvent={this.state.updateEventCounter}
              eventID={
                this.state.currentActiveEvent
                  ? this.state.currentActiveEvent
                  : this.state.latestEvent
              }
              dashboardRefresh={this.handleRefresh}
              allActiveEvents={this.state.allActiveEvents}
            />
          )}
        </div>
      </div>
    );
  }

  /**
   *
   * This function will render everything for the Availability tab of the Dashboards View.
   */
  // availabilityDashboardRender() {
  //   return (
  //     <div className="main-content-holder">
  //       <p>Availability</p>
  //     </div>
  //   );
  // }

  /**
   *
   * This function will toggle the Navigation Header from the Dashboard View.
   */
  hideNavigation = () => {
    this.setState({
      hidePageNavigation: true,
    });
  };

  showNavBar = () => {
    this.setState({
      hidePageNavigation: false,
    });
  };

  // updateTabVal = (tab) => {
  //   this.setState({
  //     currentTabVal: tab,
  //   });
  // };

  selectPagingGroup = (value) => {
    this.setState({ day: null, week: null, pagingGroup: value });

    let hrsBody = {
      pagingNumbers: [value],
      startDate: format(new Date(), "yyyy-MM-dd"),
      startTime: format(new Date(), "HH:mm"),
    };

    let daysBody = {
      pagingNumbers: [value],
      startDate: format(new Date(), "yyyy-MM-dd"),
    };

    // Show availability for the next 24hr for the selected paging group
    this.Requests.callAPI(this.Requests.getHrs, hrsBody, 24).then((data) => {
      if (data && data.status && data.status === 200) {
        // Get availability for the month
        this.setState({ day: data.data });
      } else {
        let ErrorMessage =
          copy.availability.getPaginGroupHourThresholdAvailAPIErrorMessage +
          ` (Error #${copy.errorCodes.getPaginGroupHourThresholdAvailAPIErrorMessage})`;
        // if (data && data.data && data.data.SASMessageClient) {
        //   ErrorMessage = data.data.SASMessageClient;
        // }
        this.setState(
          (prevState) => ({
            errorMessage: ErrorMessage,
            day: !prevState.day ? false : prevState.day,
          }),
          () => {
            this.messageTimeout = setTimeout(
              function () {
                this.setState({ errorMessage: null });
              }.bind(this),
              5000
            );
          }
        );
      }
    });
    // Show availability for the next week for the selected paging group
    this.Requests.callAPI(this.Requests.getDays, daysBody, 7).then((data) => {
      if (data && data.status && data.status === 200) {
        // Get availability for the month
        this.setState({ week: data.data });
      } else {
        let ErrorMessage =
          copy.availability.getPaginGroupWeekThresholdAvailAPIErrorMessage +
          ` (Error #${copy.errorCodes.getPaginGroupWeekThresholdAvailAPIErrorMessage})`;
        // if (data && data.data && data.data.SASMessageClient) {
        //   ErrorMessage = data.data.SASMessageClient;
        // }
        this.setState(
          (prevState) => ({
            errorMessage: ErrorMessage,
            week: !prevState.week ? false : prevState.week,
          }),
          () => {
            this.messageTimeout = setTimeout(
              function () {
                this.setState({ errorMessage: null });
              }.bind(this),
              5000
            );
          }
        );
      }
    });
  };

  render() {
    let navigationBtnLabel = this.state.hidePageNavigation
      ? "Show Menu"
      : "Hide Menu";

    if (
      this.state.userAgenciesOption === null ||
      this.state.userPagingGroupsOption === null
    ) {
      return (
        <div className={styles.SASChat + " main-content-holder"}>
          <div className={styles.chat_content_holder}>
            <div className={styles.fullPageLoader}>
              <div className={styles.loadingContainer}>
                <Loader />
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <Wrapper>
          <ErrorBanner
            isVisible={this.state.errorMessage ? true : false}
            ErrorMessage={this.state.errorMessage}
          />
          <div
            className={
              styles.fullScreenWrapper +
              " " +
              (this.state.hidePageNavigation && styles.fullscreen)
            }
          >
            <div
              className={
                styles.dashboardWrapper +
                " dashboard bg5 " +
                (this.state.hidePageNavigation && "no_navigation")
              }
            >
              <div className={styles.dashboardNav + " bg3"}>
                <div className={styles.buttonContainer}>
                  <div className={styles.dashboardNavBtns}>
                    <Button
                      label={navigationBtnLabel}
                      content={navigationBtnLabel}
                      styles="btn_tertiary"
                      icon="icon_hideNav"
                      buttonClick={this.hideNavigation}
                    />
                    {this.state.viewAvailabilityPermission &&
                      !window.cordova &&
                      !this.state.isMobile && (
                        <Button
                          label="Generate Reports"
                          content="Generate Reports"
                          styles="btn_tertiary"
                          icon="icon_reporting"
                          buttonClick={this.redirectToReport}
                        />
                      )}
                  </div>
                </div>
                <div className={styles.showFullScreenContainer}>
                  <div
                    className={styles.showNavButton}
                    onClick={this.showNavBar}
                  >
                    <div className={styles.button}>
                      <span>SHOW MENU</span>
                      <img src={HideNavigationIcon} alt="hide menu" />
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={[
                  "main-content-holder ",
                  styles.content_holder_no_shadow,
                ].join(" ")}
              >
                <div className={styles.noAvailability}>
                  {/* Availability should only be shown for CFA and SES  */}
                  {(this.props.userAgencies.includes("CFA") ||
                    this.props.userAgencies.includes("SES")) && (
                    <div className={styles.DashboardHeader + " tileBg"}>
                      {this.state.defaultPagingGroup ? (
                        <Wrapper>
                          <div className={styles.dropdownHolder + " cardBg2"}>
                            <span className={styles.title}>
                              Availability overview
                            </span>
                            <Dropdown
                              placeholder="Paging Group"
                              fluid
                              selection
                              search
                              options={
                                this.state.availabilityPagingGroupsOption
                              }
                              onChange={(e, { value }) =>
                                this.selectPagingGroup(value)
                              }
                              defaultValue={this.state.defaultPagingGroup}
                            />
                          </div>
                          <div className="availabilityOverview">
                            {/* {this.state.day === null ||
                          this.state.week === null ? (
                            <div className={styles.loadingContainer}>
                              <Loader />
                            </div>
                          ) : ( */}
                            <Wrapper>
                              {this.state.day && (
                                <div className={styles.row}>
                                  <span className={styles.title}>Today</span>
                                  <HalfHrView
                                    overview={true}
                                    times={this.state.day[0].timeAvailable}
                                    firstOne={true} // add header (dates) to the first row
                                  />
                                </div>
                              )}
                              {this.state.week && (
                                <div className={styles.row}>
                                  <span className={styles.title}>
                                    This Week
                                  </span>
                                  <GroupThreshold
                                    overview={true}
                                    threshold={this.state.week[0].dateAvailable}
                                    shorten={false}
                                  />
                                </div>
                              )}
                            </Wrapper>
                            {/* )} */}
                          </div>
                        </Wrapper>
                      ) : this.state.defaultPagingGroup === false ? (
                        <span className={styles.noGroupWrapper}>
                          <h4 className={styles.noGroupsMessage}>
                            {copy.dashboard.noPagingGroupsMessage}
                          </h4>
                        </span>
                      ) : (
                        <Loader />
                      )}
                    </div>
                  )}
                  {this.eventDashboardRender()}
                </div>
              </div>
            </div>
          </div>
        </Wrapper>
      );
    }
  }
}
