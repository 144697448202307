import React from "react";
import Wrapper from "../../../hoc/Wrapper/Wrapper";
import { ReactComponent as BackButton } from "../../../assets/img/icons/back-arrow.svg";
import { ReactComponent as Logo } from "../../../assets/img/logos/SASLogo.svg";
import { ReactComponent as MobileLogo } from "../../../assets/img/logos/SASLogo-cropped.svg";
import { ReactComponent as HomeIcon } from "../../../assets/img/icons/home.svg";
import { ReactComponent as ChatIcon } from "../../../assets/img/icons/chat.svg";
import { ReactComponent as HistoryIcon } from "../../../assets/img/icons/pager-history.svg";
import { ReactComponent as SettingsIcon } from "../../../assets/img/icons/settings.svg";
// import { ReactComponent as AdvancedSettingsIcon } from "../../../assets/img/icons/advanced-settings.svg";
import { ReactComponent as AdminIcon } from "../../../assets/img/icons/admin.svg";
import { ReactComponent as DashboardIcon } from "../../../assets/img/icons/dashboard.svg";
import { AuthService } from "../../../api/Authentication/AuthService";
import LogoutIcon from "../../../assets/img/icons/logout.svg";
import styles from "./Navigation.module.scss";
import { NavLink, withRouter } from "react-router-dom";
import { matchPath } from "react-router";
import copy from "../../../assets/copy/copy.json";
import { Redirect } from "react-router-dom";
import { Requests } from "../../../api/IdentityServerRequests/Requests";
import Popup from "../../UI/Popup/Popup";

var classNames = require("classnames");

// Paths for tabs
const chatPaths = ["/chat/" + copy.taburls.chats];
const settingsPaths = [
  "/settings/profile",
  "/settings/availability",
  "/settings/notifications",
];
const availabilityPaths = [
  "/availability/threshold",
  "/availability/qulification",
  "/availability/type",
  "/availability/overview",
];

class Navigation extends React.Component {
  constructor(props) {
    super(props);
    this.authService = new AuthService();
    this.Requests = new Requests();
    this.state = {
      unreadChatCount: null,
      width: window.innerWidth,
      height: window.innerHeight,
      itemHeight: "10%",
      showMobileNavigation: false,
      refs: {
        // These refs are for getting the position and width values of nav items.
        // Add new refs as required if new nav tabs are added.
        home: React.createRef(),
        sasChat: React.createRef(),
        settings: React.createRef(),
        availability: React.createRef(),
        dashboard: React.createRef(),
        turnout: React.createRef(),
        pagerHistory: React.createRef(),
        admin: React.createRef(),
        notifications: React.createRef(),
      },
      userType: this.props.userRoles,
      userAccessToken: null,
      sliderLeft: 0,
      sliderVisibility: "default",
      sliderWidth: "33.33%",
      sliderTab: null,
      showLogoutControls: false,
    };
  }

  /**
   * This function will delete the FCM token first before starting the logout process for mobile.
   */
  handleLogout = () => {
    if (window.cordova) {
      // Delete the mobile app device
      let deviceId = window.device.uuid;
      this.Requests.callAPI(this.Requests.deleteDevice, deviceId).then(
        (data) => {
          if (data && data.status && data.status === 200) {
            //FCM token has been deleted, update it again the next time the user logs in
            window.localStorage.setItem("updateFCMToken", "true");
          }
        }
      );
      // Delete all the notification channels on Android
      this.deleteNotificationChannels();
      this.authService.logout();
    } else {
      this.setState({ showLogoutControls: true });
    }
  };

  browserLogout = () => {
    this.setState({ showLogoutControls: false });
    // Delete the browser device
    if (navigator.platform && navigator.userAgent) {
      // gets the browser name as the device name
      let browserVersion = null;
      var Sys = {};
      var ua = navigator.userAgent.toLowerCase(); // eslint-disable-next-line
      var s;
      s = ua.match(/msie ([\d.]+)/)
        ? (Sys.ie = s[1]) // eslint-disable-next-line
        : (s = ua.match(/firefox\/([\d.]+)/))
        ? (Sys.firefox = s[1]) // eslint-disable-next-line
        : (s = ua.match(/chrome\/([\d.]+)/))
        ? (Sys.chrome = s[1]) // eslint-disable-next-line
        : (s = ua.match(/opera.([\d.]+)/))
        ? (Sys.opera = s[1]) // eslint-disable-next-line
        : (s = ua.match(/version\/([\d.]+).*safari/))
        ? (Sys.safari = s[1])
        : 0;
      if (Sys.ie) {
        browserVersion = "IE";
      } else if (Sys.firefox) {
        browserVersion = "Firefox";
      } else if (Sys.chrome) {
        browserVersion = "Chrome";
      } else if (Sys.opera) {
        browserVersion = "Opera";
      } else if (Sys.safari) {
        browserVersion = "Safari";
      }

      let deviceId = [navigator.platform, navigator.vendor, browserVersion]
        .join(" ")
        .replace(/\s/g, "");
      this.Requests.callAPI(this.Requests.deleteDevice, deviceId)
        .then((data) => {
          if (data && data.status && data.status === 200) {
            // Device has been deleted
          }
          this.authService.logout();
        })
        .catch((error) => {
          // Device has NOT been deleted
          this.authService.logout();
        });
    }

    window.localStorage.removeItem("accessToken");
    let mapLayers = [
      "SES Units",
      "Ambulance Stations",
      "Emergency Markers",
      "Fire Stations",
      "Fire Stations (Forest)",
      "General Hospitals",
      "Emergency Hospitals",
      "CFALocations",
      "SESLocations",
    ];
    mapLayers.forEach((layer) => {
      window.sessionStorage.removeItem(layer);
    });
  };

  /**
   * This will delete all the exsisting channels on the Android devices apart from the default ones.
   */
  deleteNotificationChannels = () => {
    if (
      window.cordova &&
      window.device &&
      window.device.platform === "Android"
    ) {
      // List all exsisting channels
      window.FirebasePlugin.listChannels(
        function (channels) {
          if (typeof channels == "undefined") return;
          for (let i = 0; i < channels.length; i++) {
            // delete the channel if not default
            if (
              channels[i].id !== "channel_chat" &&
              channels[i].id !== "channel_default" &&
              channels[i].id !== "au.gov.vic.emv.sasTSLocationManager"
            ) {
              window.FirebasePlugin.deleteChannel(
                channels[i].id,
                function () {
                  console.log("Channel deleted: " + channels[i].id);
                },
                function (error) {
                  console.log("Delete channel error: " + error);
                }
              );
            }
          }
        },
        function (error) {
          console.log("List notifications channels error: " + error);
        }
      );
    }
  };

  initSlider = () => {
    return (
      <div
        className="slider"
        style={{
          width: this.state.sliderWidth,
          left: this.state.sliderLeft,
          visibility: this.state.sliderVisibility,
        }}
      ></div>
    );
  };

  getUnreadChatCount = () => {
    // if (
    //   this.state.unreadChatCount === null ||
    //   this.state.unreadChatCount <= 0
    // ) {
    this.Requests.callAPI(this.Requests.getUnreadThreadCount).then((data) => {
      if (data && data.status && data.status === 200) {
        if (data.data !== this.state.unreadChatCount) {
          this.setState(
            {
              unreadChatCount: data.data,
            },
            () => {
              this.calcSliderVals();
              this.createNavigationMenus();
            }
          );
        }
      } else if (data && data.status && data.status === 401) {
        // Stop making the unreadThreadCount call if accesstoken expired
        clearInterval(this.interval);
      }
      //Don't need error message as this is checked every 5 mins as a background check
      // else {
      //   // let ErrorMessage = copy.error_handling.generalError;
      //   // if (data && data.data && data.data.SASMessageClient) {
      //   //   ErrorMessage = data.data.SASMessageClient;
      //   // }
      //   // this.setState({ errorMessage: ErrorMessage }, () => {
      //   //   setTimeout(
      //   //     function () {
      //   //       this.setState({ errorMessage: null });
      //   //     }.bind(this),
      //   //     5000
      //   //   );
      //   // });
      // }
    });
    // }
  };

  componentDidMount() {
    this.getUnreadChatCount();
    this.interval = setInterval(() => this.getUnreadChatCount(), 30000);
    this.setState({ width: this.state.width });
    this.createNavigationMenus();
    window.addEventListener("resize", this.handleWindowSizeChange);
    this.checkTurnoutAccess();
  }

  componentDidUpdate = () => {
    this.calcSliderVals();
  };

  componentWillUnmount() {
    clearInterval(this.interval);
    this.setState({ showLogoutControls: false });
    window.localStorage.removeItem("tabSliderIndex");
    window.removeEventListener("resize", this.handleWindowSizeChange);
    document.removeEventListener("backbutton", this.hamburgerMenu);
  }

  handleWindowSizeChange = () => {
    // This is different for iOS due to a WKWebView bug that does gives incorrect innerHeight and innerWidth values after onresize events.
    if (window.cordova && window.device && window.device.platform === "iOS") {
      this.setState({
        width: this.state.height,
        height: this.state.width,
      });
    } else {
      this.setState({ width: window.innerWidth, height: window.innerHeight });
    }
  };

  /**
   * Calculates position of slider by identifying selected tab and moving slider to that tab's left position.
   * Uses React.createRef() to get tab's reference and getBoundingClientRect() to get tab's left position.
   * When adding a new nav tab:
   *  - Create a new reference in the state,
   *  - Add the reference to its NavLink component in render(), and
   *  - Add a condition here to update currentTab to the name of the reference in state when on the new path.
   */
  calcSliderVals() {
    const { width, height } = this.state;
    const isMobile = width < 768;
    const isMobileLandscape = width <= 1023 && height < 768;
    const isTablet = width <= 1023;

    let UserAgencies;
    if (this.props.userAgencies) {
      UserAgencies = this.props.userAgencies;
    } else {
      UserAgencies = window.localStorage.getItem("userAgencies");
    }

    // This gets the current page the user is on.
    // If a new nav tab is added, add a condition here to update currentTab to the name of the reference when the on the new path.
    let currentTab;
    if (!!matchPath(this.props.location.pathname, "/home")) {
      currentTab = "home";
    } else if (!!matchPath(this.props.location.pathname, "/chat/chats")) {
      currentTab = "sasChat";
    } else if (
      !!matchPath(this.props.location.pathname, "/settings/profile") ||
      !!matchPath(this.props.location.pathname, "/settings/availability") ||
      !!matchPath(this.props.location.pathname, "/settings/notifications")
    ) {
      currentTab = "settings";
    } else if (
      !!matchPath(this.props.location.pathname, "/availability/threshold") ||
      !!matchPath(
        this.props.location.pathname,
        "/availability/qualification"
      ) ||
      !!matchPath(this.props.location.pathname, "/availability/type") ||
      !!matchPath(this.props.location.pathname, "/availability/overview")
    ) {
      currentTab = "availability";
    } else if (!!matchPath(this.props.location.pathname, "/dashboard")) {
      currentTab = "dashboard";
    } else if (!!matchPath(this.props.location.pathname, "/turnout")) {
      currentTab = "turnout";
    } else if (!!matchPath(this.props.location.pathname, "/admin")) {
      currentTab = "admin";
    } else if (!!matchPath(this.props.location.pathname, "/pagerhistory")) {
      currentTab = "pagerHistory";
    } else if (!!matchPath(this.props.location.pathname, "/eventdetails")) {
      currentTab = "eventDetails";
    } else if (!!matchPath(this.props.location.pathname, "/notifications")) {
      currentTab = "notifications";
    } else {
      currentTab = null;
    }

    let navigationWrapperLeftVal;
    let CurrentTabLeft;
    let calculatedCurrentTabLeft;

    if (currentTab !== null && currentTab !== "eventDetails") {
      navigationWrapperLeftVal =
        document.getElementById("navigationWrapper") &&
        document.getElementById("navigationWrapper").getBoundingClientRect()
          .left;
      CurrentTabLeft =
        this.state.refs[currentTab].current &&
        this.state.refs[currentTab].current.getBoundingClientRect().left;
      calculatedCurrentTabLeft =
        CurrentTabLeft - navigationWrapperLeftVal - (isTablet ? 23 : 3);
    }

    // Slider values for mobile - 1/3 of screen width.
    // 'if' statements are in order of tab position on screen - change currentTab to reflect any changes to tab order.
    if (
      currentTab !== null &&
      currentTab !== "eventDetails" &&
      (isMobile || isMobileLandscape) &&
      (currentTab !== this.state.sliderTab ||
        this.state.sliderWidth !== "33.33%")
    ) {
      // First tab (furthest left) on mobile
      if (currentTab === "home") {
        this.setState({
          sliderWidth: "33.33%",
          sliderLeft: 0,
          sliderVisibility: "visible",
        });
        // Second tab (middle) on mobile
      } else if (
        currentTab === "sasChat" ||
        ((currentTab === "notifications" || currentTab === "pagerHistory") &&
          UserAgencies &&
          !UserAgencies.includes("CFA") &&
          !UserAgencies.includes("SES"))
      ) {
        this.setState({
          sliderWidth: "33.33%",
          sliderLeft: "33.33%",
          sliderVisibility: "visible",
        });
        // Last tab (furthest right) on mobile
      } else if (currentTab === "settings") {
        this.setState({
          sliderWidth: "33.33%",
          sliderLeft: "66.67%",
          sliderVisibility: "visible",
        });
      } else {
        this.setState({
          sliderWidth: "33.33%",
          sliderLeft: 0,
          sliderVisibility: "hidden",
        });
      }
      this.setState({
        sliderTab: currentTab,
      });
    }
    // This if only changes the values of the slider if the item it is under moves, as well as to avoid inifinite recursion due to setState
    else if (
      currentTab !== null &&
      currentTab !== "eventDetails" &&
      this.state.refs[currentTab].current &&
      this.state.sliderLeft !== calculatedCurrentTabLeft
    ) {
      // Sets values if not mobile view
      this.setState({
        sliderLeft: calculatedCurrentTabLeft,
        sliderWidth:
          this.state.refs[currentTab].current.getBoundingClientRect().width + 6,
        sliderVisibility: "visible",
      });
    }
  }

  /**
   *
   * @param {array} paths - url paths for the 'Chat' tab
   *
   * This function is used to make a tab in the primary header active if any of the secondary
   * tabs within it is active
   */
  checkPaths(paths) {
    return (match, location) => {
      return paths.indexOf(location.pathname) !== -1;
    };
  }

  /**
   * This function is used to redirect back to home
   */
  /*
  goBackHome = () => {
    if (this.props.location.state && 
      this.props.location.state.from && 
      this.props.location.state.from === "/pagerhistory") {
        this.props.history.goBack();
    } else {
      this.props.history.push({
        pathname: "/home",
      });
    }
    if (window.cordova) {
      // This will stop the app from redirect to event or chat from push notification
      window.localStorage.removeItem("redirectToEvent");
      window.localStorage.removeItem("redirectToChat");
    }
  };
  */

  hamburgerMenu = () => {
    this.setState(
      {
        showMobileNavigation: !this.state.showMobileNavigation,
      },
      () => {
        if (this.state.showMobileNavigation) {
          if (window.device && window.device.platform === "Android") {
            document.addEventListener("backbutton", this.hamburgerMenu);
          }
          document.body.classList.add("body_locked");
        } else {
          if (window.device && window.device.platform === "Android") {
            document.removeEventListener("backbutton", this.hamburgerMenu);
          }
          document.body.classList.remove("body_locked");
        }
      }
    );
  };
  checkTurnoutAccess = () => {
    this.setState(() => {
      this.Requests.callAPI(this.Requests.getUserSettings).then((data) => {
        if (!data || data.status !== 200) {
          let ErrorMessage =
            copy.turnout.turnoutSettingsAPIErrorMessage +
            ` (Error #${copy.errorCodes.getTurnoutSettingsErrorMessage})`;
          this.setState(
            {
              loading: false,
              errorMessage: ErrorMessage,
              getSettingsError: true,
            },
            () => {
              setTimeout(
                function () {
                  this.setState({
                    errorMessage: null,
                    getSettingsError: null,
                  });
                }.bind(this),
                5000
              );
            }
          );
        } else {
          if (data && data.status && data.status === 200 && data.data) {
            this.setState({
              showTurnout: data.data.canAccessTurnoutDashboard,
            });
          }
        }
      });
    });
  };
  handleHomeButtonClick = () => {
    window.sessionStorage.clear(); // Clears the sessionStorage
  };
  createNavigationMenus = () => {
    const home = (
      <NavLink
        className={styles.navigationItem}
        exact
        activeClassName={styles.activeNav}
        to="/home"
        key={"home"}
        onClick={this.handleHomeButtonClick}
      >
        <span ref={this.state.refs.home}>{copy.navigation.home}</span>
      </NavLink>
    );
    const sasChat = (
      <NavLink
        className={styles.navigationItem}
        exact
        activeClassName={styles.activeNav}
        to="/chat/chats"
        isActive={this.checkPaths(chatPaths)}
        key={"sasChat"}
      >
        <span ref={this.state.refs.sasChat}>{copy.navigation.sasChat}</span>
        {this.state.unreadChatCount === 1 && (
          <span className={styles.chatIcon}></span>
        )}
      </NavLink>
    );
    const settings = (
      <NavLink
        className={styles.navigationItem}
        exact
        activeClassName={styles.activeNav}
        to="/settings/profile"
        isActive={this.checkPaths(settingsPaths)}
        key={"settings"}
      >
        <span ref={this.state.refs.settings}>{copy.navigation.settings}</span>
      </NavLink>
    );
    const availability = (
      <NavLink
        className={styles.navigationItem}
        exact
        activeClassName={styles.activeNav}
        to="/availability/threshold"
        isActive={this.checkPaths(availabilityPaths)}
        key={"availability"}
      >
        <span ref={this.state.refs.availability}>
          {copy.navigation.availability}
        </span>
      </NavLink>
    );
    const dashboard = (
      <NavLink
        className={styles.navigationItem}
        exact
        activeClassName={styles.activeNav}
        to="/dashboard/events"
        key={"dashboard"}
      >
        <span ref={this.state.refs.dashboard}>{copy.navigation.dashboard}</span>
      </NavLink>
    );
    const turnout = (
      <NavLink
        className={styles.navigationItem}
        exact
        activeClassName={styles.activeNav}
        to="/turnout/events"
        key={"turnout"}
      >
        <span ref={this.state.refs.turnout}>{copy.navigation.turnout}</span>
      </NavLink>
    );
    const pagerHistory = (
      <NavLink
        className={styles.navigationItem}
        exact
        activeClassName={styles.activeNav}
        to="/pagerhistory"
        key={"pagerHistory"}
        onClick={this.handleHomeButtonClick}
      >
        <span ref={this.state.refs.pagerHistory}>
          {copy.navigation.pagerHistory}
        </span>
      </NavLink>
    );
    const admin = (
      <NavLink
        className={styles.navigationItem}
        exact
        activeClassName={styles.activeNav}
        to="/admin"
        key={"admin"}
      >
        <span ref={this.state.refs.admin}>{copy.navigation.admin}</span>
      </NavLink>
    );

    let UserType = false;
    if (this.state.userType) {
      UserType = this.state.userType;
    } else if (window.localStorage.getItem("userRoles")) {
      UserType = window.localStorage.getItem("userRoles");
    }

    let UserAgencies;
    if (this.props.userAgencies) {
      UserAgencies = this.props.userAgencies;
    } else {
      UserAgencies = window.localStorage.getItem("userAgencies");
    }

    const itemHeight = this.state.itemHeight;

    const homeMenu = (
      <NavLink
        onClick={this.hamburgerMenu}
        className={styles.navigationItem}
        exact
        activeClassName={styles.activeNav}
        to="/home"
        style={{ height: itemHeight }}
        key={"homeMenu"}
      >
        <div className={styles.content}>
          <div className={styles.icon}>
            <HomeIcon />
          </div>
          <span>{copy.navigation.home}</span>
        </div>
      </NavLink>
    );
    const sasChatMenu = (
      <NavLink
        onClick={this.hamburgerMenu}
        className={styles.navigationItem}
        exact
        activeClassName={styles.activeNav}
        to="/chat/chats"
        isActive={this.checkPaths(chatPaths)}
        style={{ height: itemHeight }}
        key={"sasChatMenu"}
      >
        <div className={styles.content}>
          <div className={styles.icon}>
            <ChatIcon />
          </div>
          <span>{copy.navigation.sasChat}</span>
        </div>
      </NavLink>
    );
    const settingsMenu = (
      <NavLink
        onClick={this.hamburgerMenu}
        className={styles.navigationItem}
        exact
        activeClassName={styles.activeNav}
        to="/settings/profile"
        isActive={this.checkPaths(settingsPaths)}
        style={{ height: itemHeight }}
        key={"settingsMenu"}
      >
        <div className={styles.content}>
          <div className={styles.icon}>
            <SettingsIcon />
          </div>
          <span>{copy.navigation.settings}</span>
        </div>
      </NavLink>
    );
    const availabilityMenu = (
      <NavLink
        onClick={this.hamburgerMenu}
        className={styles.navigationItem}
        exact
        activeClassName={styles.activeNav}
        to="/availability/threshold"
        isActive={this.checkPaths(availabilityPaths)}
        style={{ height: itemHeight }}
        key={"availabilityMenu"}
      >
        <div className={styles.content}>
          <div className={styles.icon}>
            <DashboardIcon />
          </div>
          <span>{copy.navigation.availability}</span>
        </div>
      </NavLink>
    );

    // const dashboardMenu = (
    //   <NavLink
    //     onClick={() => {
    //       this.setState({ showMobileNavigation: false });
    //     }}
    //     className={styles.navigationItem}
    //     exact
    //     activeClassName={styles.activeNav}
    //     to="/dashboard"
    //     style={{ height: itemHeight }}
    //     key={"dashboardMenu"}
    //   >
    //     <div className={styles.content}>
    //       <div className={styles.icon}>
    //         <DashboardIcon />
    //       </div>
    //       <span>{copy.navigation.dashboard}</span>
    //     </div>
    //   </NavLink>
    // );

    const pagerHistoryMenu = (
      <NavLink
        onClick={this.hamburgerMenu}
        className={styles.navigationItem}
        exact
        activeClassName={styles.activeNav}
        to="/pagerhistory"
        style={{ height: itemHeight }}
        key={"pagerHistoryMenu"}
      >
        <div className={styles.content}>
          <div className={styles.icon}>
            <HistoryIcon />
          </div>
          <span>{copy.navigation.pagerHistory}</span>
        </div>
      </NavLink>
    );
    const adminMenu = (
      <NavLink
        onClick={this.hamburgerMenu}
        className={styles.navigationItem}
        exact
        activeClassName={styles.activeNav}
        to="/admin"
        style={{ height: itemHeight }}
        key={"adminMenu"}
      >
        <div className={styles.content}>
          <div className={styles.icon}>
            <AdminIcon />
          </div>
          <span>{copy.navigation.admin}</span>
        </div>
      </NavLink>
    );

    // Calculate what permission level a user has
    this.navItems = undefined;
    this.navMenuItems = [];

    if (UserType !== false) {
      if (UserType.indexOf("SuperUser") !== -1) {
        this.navItems = [
          home,
          UserAgencies &&
          (UserAgencies.includes("CFA") || UserAgencies.includes("SES"))
            ? sasChat
            : null,
          settings,
          UserAgencies &&
          (UserAgencies.includes("CFA") || UserAgencies.includes("SES"))
            ? availability
            : null,
          dashboard,
          turnout,
          pagerHistory,
          admin,
        ];
        this.navMenuItems = [
          homeMenu,
          UserAgencies &&
          (UserAgencies.includes("CFA") || UserAgencies.includes("SES"))
            ? sasChatMenu
            : null,
          settingsMenu,
          UserAgencies &&
          (UserAgencies.includes("CFA") || UserAgencies.includes("SES"))
            ? availabilityMenu
            : null,
          pagerHistoryMenu,
          adminMenu,
        ];
      } else if (UserType.indexOf("Administrator") !== -1) {
        this.navItems = [
          home,
          UserAgencies &&
          (UserAgencies.includes("CFA") || UserAgencies.includes("SES"))
            ? sasChat
            : null,
          settings,
          UserAgencies &&
          (UserAgencies.includes("CFA") || UserAgencies.includes("SES"))
            ? availability
            : null,
          dashboard,
          turnout,
          pagerHistory,
          admin,
        ];
        this.navMenuItems = [
          homeMenu,
          UserAgencies &&
          (UserAgencies.includes("CFA") || UserAgencies.includes("SES"))
            ? sasChatMenu
            : null,
          settingsMenu,
          UserAgencies &&
          (UserAgencies.includes("CFA") || UserAgencies.includes("SES"))
            ? availabilityMenu
            : null,
          pagerHistoryMenu,
          adminMenu,
        ];
      } else if (UserType.indexOf("Manager") !== -1) {
        this.navItems = [
          home,
          UserAgencies &&
          (UserAgencies.includes("CFA") || UserAgencies.includes("SES"))
            ? sasChat
            : null,
          settings,
          UserAgencies &&
          (UserAgencies.includes("CFA") || UserAgencies.includes("SES"))
            ? availability
            : null,
          dashboard,
          turnout,
          pagerHistory,
          admin,
        ];
        this.navMenuItems = [
          homeMenu,
          UserAgencies &&
          (UserAgencies.includes("CFA") || UserAgencies.includes("SES"))
            ? sasChatMenu
            : null,
          settingsMenu,
          UserAgencies &&
          (UserAgencies.includes("CFA") || UserAgencies.includes("SES"))
            ? availabilityMenu
            : null,
          pagerHistoryMenu,
          adminMenu,
        ];
      } else if (UserType.indexOf("User") !== -1) {
        this.navItems = [
          home,
          UserAgencies &&
          (UserAgencies.includes("CFA") || UserAgencies.includes("SES"))
            ? sasChat
            : null,
          settings,
          UserAgencies &&
          (UserAgencies.includes("CFA") || UserAgencies.includes("SES"))
            ? availability
            : null,
          dashboard,
          turnout,
          pagerHistory,
          this.props.isPagingGroupOwner ? admin : null,
        ];
        this.navMenuItems = [
          homeMenu,
          UserAgencies &&
          (UserAgencies.includes("CFA") || UserAgencies.includes("SES"))
            ? sasChatMenu
            : null,
          settingsMenu,
          UserAgencies &&
          (UserAgencies.includes("CFA") || UserAgencies.includes("SES"))
            ? availabilityMenu
            : null,
          pagerHistoryMenu,
          ...(this.props.isPagingGroupOwner ? [adminMenu] : []),
        ];
      }
    } else {
      // These are the default menu options for when we can't work out what roles or agencies the user has
      this.navItems = [home, settings, pagerHistory];
      this.navMenuItems = [homeMenu, settingsMenu, pagerHistoryMenu];
    }

    // Turnout should not appear in mobile/tablet
    if (window.cordova || !this.state.showTurnout) {
      this.navItems.splice(this.navItems.indexOf(turnout), 1);
    }

    if (this.navMenuItems && this.navMenuItems.length > 0) {
      const newItemHeight = 100 / this.navMenuItems.length + "%";
      if (newItemHeight !== this.state.itemHeight) {
        this.setState({
          itemHeight: newItemHeight,
        });
      }
    }
  };

  render() {
    this.createNavigationMenus();
    this.getUnreadChatCount();
    let UserAgencies;
    if (this.props.userAgencies) {
      UserAgencies = this.props.userAgencies;
    } else {
      UserAgencies = window.localStorage.getItem("userAgencies");
    }
    this.Requests.getAccessToken().then((accessToken) => {
      if (!accessToken) {
        return <Redirect to={"/login"} />;
      }
    });
    // Breakpoints
    const { width, height } = this.state;
    const isMobile = width < 768;
    const isMobileLandscape = width <= 1023 && height < 768;
    const isTablet = width <= 1023;

    // Query to check what current location path is
    const isEventNavigation = !!matchPath(
      this.props.location.pathname,
      "/eventdetails"
    );

    const isHomepage = !!matchPath(this.props.location.pathname, "/home");

    // Slideout Navigation
    let HamburgerIcon = classNames(styles.hamburger, this.props.className, {
      [styles.active]: this.state.showMobileNavigation,
    });

    let MobileSlideoutMenu = classNames(
      "cardBg2 " + styles.SlideoutNavigation,
      this.props.className,
      {
        [styles.open]: this.state.showMobileNavigation,
      }
    );

    if (!this.state.showMobileNavigation) {
      document.body.classList.remove("body_locked");
    }

    // let userType = this.state.userType;

    // let userType = classNames(
    //   styles.userType,
    //   this.props.className,
    //   {
    //     [styles.User]: this.state.userType
    //   }
    // );

    const MobileMenu = (
      <div className={MobileSlideoutMenu}>
        <Popup
          isVisible={this.state.showLogoutControls}
          titleText={copy.navigation.logoutPopup}
          button1Text={copy.global.btnLogout}
          button2Text={copy.global.btnCancel}
          button1Action={this.browserLogout}
          button2Action={() => {
            this.setState({ showLogoutControls: false });
          }}
        />
        <div className={styles.wrapper}>
          <div className={styles.header}>
            <div className={styles.logout}>
              <div
                className={styles.logoutIcon + " txt secondaryBtn"}
                onClick={() => {
                  this.handleLogout();
                }}
              >
                <span>{copy.navigation.logout}</span>
                <img src={LogoutIcon} alt="logout icon" />
              </div>
            </div>
            <NavLink
              {...{
                onClick: this.hamburgerMenu,
              }}
              className={styles.logo}
              exact
              activeClassName={styles.activeNav}
              to="/home"
            >
              <MobileLogo />
            </NavLink>
          </div>
          <div id="navigationWrapper" className={styles.navigationWrapper}>
            {this.navMenuItems}
          </div>
        </div>
      </div>
    );

    let MobileNavigation;

    if (isEventNavigation) {
      MobileNavigation = (
        <nav
          className={[styles.MobileNavigation, styles.EventNavigation].join(
            " "
          )}
        >
          <BackButton
            className={styles.backButton}
            {...{
              onClick: this.props.history.goBack,
            }}
          />
          <div className={HamburgerIcon} {...{ onClick: this.hamburgerMenu }}>
            <div className={styles.hamburgerBox}>
              <div className={styles.hamburgerInner}></div>
            </div>
          </div>
          {MobileMenu}
        </nav>
      );
    } else {
      MobileNavigation = (
        <nav className={[styles.MobileNavigation].join(" ") + " primaryNavBg"}>
          <div className={styles.PrimaryNavigation}>
            <div className={styles.backButton}>
              {isHomepage ? null : (
                <BackButton {...{ onClick: this.props.history.goBack }} />
              )}
            </div>
            <div className={styles.logo}>
              <NavLink
                className={styles.logo}
                exact
                activeClassName={styles.activeNav}
                to="/home"
              >
                <MobileLogo />
              </NavLink>
            </div>
            <div
              className={[HamburgerIcon, styles.primary].join(" ")}
              {...{ onClick: this.hamburgerMenu }}
            >
              <div className={styles.hamburgerBox}>
                <div className={styles.hamburgerInner}></div>
              </div>
            </div>
          </div>
          <div className={styles.SecondaryNavigation + " secondaryNavBg"}>
            <NavLink
              className={styles.navigationItem}
              exact
              activeClassName={styles.activeNav}
              to="/home"
            >
              <div className={styles.content}>
                <span>{copy.navigation.home}</span>
                <div className={styles.icon}>
                  <HomeIcon />
                </div>
              </div>
            </NavLink>
            {/* Only CFA and SES users should see Chat, if not show Pager history instead */}
            {UserAgencies &&
            (UserAgencies.includes("CFA") || UserAgencies.includes("SES")) ? (
              <NavLink
                className={styles.navigationItem}
                exact
                activeClassName={styles.activeNav}
                to="/chat/chats"
                isActive={this.checkPaths(chatPaths)}
              >
                <div className={styles.content}>
                  <span>{copy.navigation.sasChat}</span>
                  <div
                    className={[
                      styles.icon,
                      this.state.unreadChatCount === 1 && styles.newMessage,
                    ].join(" ")}
                  >
                    <ChatIcon />
                  </div>
                </div>
              </NavLink>
            ) : window.cordova ? (
              <NavLink
                className={styles.navigationItem}
                exact
                activeClassName={styles.activeNav}
                to="/notifications"
                isActive={this.checkPaths(chatPaths)}
              >
                <div className={styles.content}>
                  <span>{copy.navigation.notifications}</span>
                  {/* <div className={styles.icon}>
                  <HistoryIcon />
                </div> */}
                </div>
              </NavLink>
            ) : (
              <NavLink
                className={styles.navigationItem}
                exact
                activeClassName={styles.activeNav}
                to="/pagerHistory"
                isActive={this.checkPaths(chatPaths)}
              >
                <div className={styles.content}>
                  <span>{copy.navigation.pagerHistory}</span>
                  <div className={styles.icon}>
                    <HistoryIcon />
                  </div>
                </div>
              </NavLink>
            )}
            <NavLink
              className={styles.navigationItem}
              exact
              activeClassName={styles.activeNav}
              to="/settings/profile"
              isActive={this.checkPaths(settingsPaths)}
            >
              <div className={styles.content}>
                <span>{copy.navigation.settings}</span>
                <div className={styles.icon}>
                  <SettingsIcon />
                </div>
              </div>
            </NavLink>
            {!!matchPath(this.props.location.pathname, "/eventdetails")
              ? null
              : this.initSlider()}
          </div>
          {MobileMenu}
        </nav>
      );
    }

    let TabletNavigation = (
      <nav className={[styles.Navigation, styles.Tablet].join(" ")}>
        <Popup
          isVisible={this.state.showLogoutControls}
          titleText={copy.navigation.logoutPopup}
          button1Text={copy.global.btnLogout}
          button2Text={copy.global.btnCancel}
          button1Action={this.browserLogout}
          button2Action={() => {
            this.setState({ showLogoutControls: false });
          }}
        />
        <div id="navigationWrapper" className={styles.navigationWrapper}>
          <div className={styles.topBar}>
            <div className={styles.logo}>
              <NavLink exact activeClassName={styles.activeNav} to="/">
                <MobileLogo />
              </NavLink>
            </div>
            <div className={styles.logout}>
              <div
                className={styles.logoutIcon + " txt secondaryBtn"}
                onClick={() => {
                  this.handleLogout();
                }}
              >
                <span>{copy.navigation.logout}</span>
                <img src={LogoutIcon} alt="logout icon" />
              </div>
            </div>
          </div>
          <div className={styles.primaryNavigation}>
            <div className={styles.navigation}>{this.navItems}</div>
            {!!matchPath(this.props.location.pathname, "/eventdetails")
              ? null
              : this.initSlider()}
          </div>
        </div>
      </nav>
    );

    let DesktopNavigation = (
      <nav
        className={
          [styles.Navigation, styles.Desktop].join(" ") + " primaryNavBg"
        }
      >
        <Popup
          isVisible={this.state.showLogoutControls}
          titleText={copy.navigation.logoutPopup}
          button1Text={copy.global.btnLogout}
          button2Text={copy.global.btnCancel}
          button1Action={this.browserLogout}
          button2Action={() => {
            this.setState({ showLogoutControls: false });
          }}
        />
        <div id="navigationWrapper" className={styles.navigationWrapper}>
          <div className={styles.logo}>
            <NavLink exact activeClassName={styles.activeNav} to="/home">
              <Logo />
            </NavLink>
          </div>
          <div className={styles.navigation}>{this.navItems}</div>
          {!!matchPath(this.props.location.pathname, "/eventdetails")
            ? null
            : this.initSlider()}
          <div className={styles.logout}>
            <button
              className={styles.logoutIcon + " txt secondaryBtn"}
              onClick={() => {
                this.handleLogout();
              }}
            >
              <span>{copy.navigation.logout}</span>
              <img src={LogoutIcon} alt="logout icon" />
            </button>
          </div>
        </div>
      </nav>
    );

    if (isMobile || isMobileLandscape) {
      return <Wrapper>{MobileNavigation}</Wrapper>;
    } else if (isTablet) {
      return <Wrapper>{TabletNavigation}</Wrapper>;
    } else {
      return <Wrapper>{DesktopNavigation}</Wrapper>;
    }
  }
}

export default withRouter(Navigation);
