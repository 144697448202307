import React, { Component } from "react";
import PropTypes from "prop-types";
import Wrapper from "../../../hoc/Wrapper/Wrapper";
import AccordionSection from "./AccordionSection";
import ToggleSection from "./ToggleSection";

import Button from "../Button/Button";

class Accordion extends Component {
  static propTypes = {
    allowMultipleOpen: PropTypes.bool,
    children: PropTypes.instanceOf(Object).isRequired,
  };

  constructor(props) {
    super(props);

    const openSections = {};

    if (this.props.children.length > 1) {
      this.props.children.forEach((child) => {
        if (child && child.props.isOpen) {
          openSections[child.props.label] = true;
        }
      });
    } else {
      if (this.props.children && this.props.children.props.isOpen) {
        openSections[this.props.children.props.label] = true;
      }
    }
    this.state = { openSections };
  }

  handleClick = (label) => {
    const {
      props: { allowMultipleOpen },
      state: { openSections },
    } = this;

    const isOpen = !!openSections[label];

    if (allowMultipleOpen) {
      this.setState({
        openSections: {
          ...openSections,
          [label]: !isOpen,
        },
      });
    } else {
      this.setState({
        openSections: {
          [label]: !isOpen,
        },
      });
    }
    if (this.props.buttonClick) {
      this.props.buttonClick(label);
    }
  };

  render() {
    const {
      handleClick,
      props: { children },
      state: { openSections },
      isChildren = children.length > 1,
    } = this;
    return (
      <Wrapper>
        {this.props.toggle ? (
          <Wrapper>
            {isChildren
              ? children.map(
                  (child, index) =>
                    child && (
                      <ToggleSection
                        isOpen={!!openSections[child.props.label]}
                        logo={child.props.logo}
                        label={child.props.label}
                        txt={child.props.txt}
                        subtxt={child.props.subtxt}
                        handleClick={handleClick}
                        key={index}
                        keyValue={index}
                        navigation={child.props.navigation}>
                        {child.props.children}
                      </ToggleSection>
                    )
                )
              : children && (
                  <ToggleSection
                    isOpen={!!openSections[children.props.label]}
                    logo={children.props.logo}
                    label={children.props.label}
                    txt={children.props.txt}
                    subtxt={children.props.subtxt}
                    handleClick={handleClick}
                    navigation={children.props.navigation}>
                    {children.props.children}
                  </ToggleSection>
                )}
          </Wrapper>
        ) : (
          <Wrapper>
            {isChildren
              ? children.map(
                  (child, index) =>
                    child && (
                      <AccordionSection
                        isOpen={!!openSections[child.props.label]}
                        logo={child.props.logo}
                        label={child.props.label}
                        txt={child.props.txt}
                        subtxt={child.props.subtxt}
                        handleClick={handleClick}
                        key={index}
                        keyValue={index}
                        navigation={child.props.navigation}>
                        {child.props.children}
                      </AccordionSection>
                    )
                )
              : children && (
                  <AccordionSection
                    isOpen={!!openSections[children.props.label]}
                    logo={children.props.logo}
                    label={children.props.label}
                    txt={children.props.txt}
                    subtxt={children.props.subtxt}
                    handleClick={handleClick}
                    navigation={children.props.navigation}>
                    {" "}
                    {children.props.children}
                    {this.props.button ? (
                      <Button
                        label={this.props.label}
                        content={this.props.content}
                        variant={this.props.variant}
                        styles={this.props.styles}
                        buttonClick={handleClick}
                      />
                    ) : (
                      false
                    )}
                  </AccordionSection>
                )}
          </Wrapper>
        )}
      </Wrapper>
    );
  }
}

export default Accordion;
