import React from "react";
import styles from "./Button.module.scss";

export default class Toggle extends React.Component {
  render() {
    const { label, checked, fromAdmin, fromAvailability, fromTurnout } = this.props;
    if (checked) {
      return (
        <label
          aria-label={label}
          onChange={this.props.buttonClick}
          className={styles.toggle}>
          <input
            type="checkbox"
            defaultChecked
            name={label}
            {...((fromAdmin || fromAvailability || fromTurnout) && { checked: checked })}
          />
          <span className={styles.slider}></span>
        </label>
      );
    } else {
      return (
        <label
          aria-label={label}
          onChange={this.props.buttonClick}
          className={styles.toggle}>
          <input
            type="checkbox"
            name={label}
            {...((fromAdmin||fromTurnout) && { checked: checked })}
          />
          <span className={styles.slider}></span>
        </label>
      );
    }
  }
}
