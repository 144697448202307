import React from "react";
import debounce from "lodash.debounce";
import Wrapper from "../../hoc/Wrapper/Wrapper";
import ErrorBanner from "../../hoc/ErrorBanner/ErrorBanner";
import Loader from "../../components/UI/Loading/Loading";
import InfiniteScroll from "react-infinite-scroll-component";

// Page UIs & styles
import ScrollButton from "../../components/UI/Button/BackToTop";
import Button from "../../components/UI/Button/Button";
import styles from "./SASChat.module.scss";
import { Dropdown } from "semantic-ui-react";
import Checkbox from "../../components/UI/Button/Checkbox";
import { ReactComponent as Close } from "../../assets/img/icons/close_red.svg";

// Copy file
import copy from "../../assets/copy/copy.json";
// Chat
import Chat from "../../components/Chat/Chat";
import ChatThread from "../../components/Chat/ChatThread";
import NewChat from "../../components/Chat/NewChat";

import { Requests } from "../../api/IdentityServerRequests/Requests";
import { isValid, isAfter, endOfDay } from "date-fns";
let recipientName = null;

export default class SASChatView extends React.Component {
  constructor(props) {
    super(props);
    this.Requests = new Requests();
    this.state = {
      width: window.innerWidth,
      isMobile: window.innerWidth <= 1023 ? true : false,
      resultsHeadingHeight: 0,
      resultsWrapperHeight: "100%",
      pagingGroupResultsHeight: "45%",
      userResultsHeight: "45%",
      // API ERROR HANDLING
      errorMessage: null, // Error message for API calls
      // LOADING STATES
      isLoadingAllChats: true, // Loader for entire chat page
      isLoadingChats: true, // Loading state for all chat messages
      isLoadingThread: true, // Loading state for chat thread
      hasMoreMessagesToLoad: true, // Will be set to false if API call returns less than 30 messages
      showUserAndGroupSearch: true, // Flag to show User and Group search to create a new message. Set to true as default to show if user has no messages.
      createNewMessage: false, // Flag set to true when user clicks New Chat button. Button click will show user and group search
      loadAllChatsWithoutReloadingThread: false, // When set to true, loads all chat messages without triggering loading state for silent update
      allChatMessages: [], // Array of all chat messages
      chatThread: null, // All chat thread data (subject, original message, replies etc...)
      chatIndex: null, // Sets the index of the selected chat thread. Set to the latest chat thread by default when page is initialised. Updated when a thread is clicked
      showMessageList: true, // Flag to show all message for mobile. Hidden when a thread is clicked
      showFullMessage: false, // Flag to show full message thread

      scrollable: true, // State for whether messages are scrollable

      // START FILTER, SORT & PAGINATION STATE OBJECTS
      // loadDataWithoutRefreshing: false,
      loadUsersWithoutRefreshing: false, // Flag set to true if loading user pagination
      hasMorePagingGroupsToLoad: true, // Flag set to true if there are 30 paging groups returned from the Get for paging groups search. Set to false if less the 30 paging groups returned
      hasMoreUsersToLoad: true, // Flag set to true if there are 30 users returned from the get for User search. Set to false if less the 30 users returned
      pagingGroupSkip: 0, // Paging group skip count for pagination. Amount is increased by 30 every time pagination is triggered
      userListSkip: 0, // User skip count for pagination. Amount is increased by 30 every time pagination is triggered
      skipCount: 0, // Skip count for get all chat messages
      takeCount: 30, // Take count for get all chat messages
      multiAgency: false, // Multi Agency flag for agency search in user and group search
      multiPGroups: false, // Multi Paging Group flag for paging group search in user and group search
      multiQual: false, // Multi qualifications flag for qualifications search in user and group search
      multiAvail: false, // Multi availability times for availability search in user and group search
      // These are the parameters being passed into the filter
      userSearchHeaderHeight: null, // Calculated height of search results header, including keywords
      showRefineSearchButton: false, // Flag to show or hide refine search button
      showSearchParameters: false, //  Flag to show search parameters in search results header
      noSearchDataAgencySelected: false,
      searchData: {
        keyword: [],
        agency: [],
        // pagingNumber: [],
        qualificationId: [],
        availability: null,
      },
      pagingGroupsReferenceList: [],
      showSendMessageButton: false, // Shows the send message button when a user has selected a user, paging group or custom group if the user is permitted to send a message
      isPagingGroupMessage: false, // Flag is marked as true if paging group message is selected (and user is permitted to make paging group messages)
      isAdhocGroupMessage: false, // Flag is marked as true if multiple users are selected (and user is permitted to make adhoc group messages)
      newMessageUsers: [], // Array of user(s) selected for messaging
      newMessagePagingGroup: [], // Array of Paging Group(s) selected for messaging
      newMessagePagingGroupSearchData: [], // Array of search data entered for user and group search. Separated to keep data purely for search and not passed into the create new message component as create new message data has to be passed in after user permissions have been applied
      newMessageThread: false, // Flag to show new message thread
      // USER AGENCY INFO
      UserId: this.props.userInfo ? this.props.userInfo.userId : null, // UserId is calculated from props data and passed into Chat and ChatThread components as those components don't have access to props data
      userAgencyInfo: null, // Calculated from props data and used to calculate user agency permissions
      userRoleAgency: null, // Calculated from props data and used to calculate user agency permissions
      AVChatSettings: {
        // AV chat permissions
        chatOneToOneEnabled: null,
        chatGroupEnabled: null,
        chatAdhocGroupEnabled: null,
      },
      CFAChatSettings: {
        // CFA chat permissions
        chatOneToOneEnabled: null,
        chatGroupEnabled: null,
        chatAdhocGroupEnabled: null,
      },
      SESChatSettings: {
        // SES chat permissions
        chatOneToOneEnabled: null,
        chatGroupEnabled: null,
        chatAdhocGroupEnabled: null,
      },
      showCreateNewMessageButton: false, // Flag to show create new chat button based on usr agency permissions. If agency permissions don't allow any form of chats, button is hidden.
      showShowAllUserToggleButton: false, // Flag to show toggle to show all children members associated to an agency or paging group.
      includePagingNumberDecendants: false, // Flag to indicate whether to show all children members associated to an agency or paging group in the API call

      // NEW CHAT SEARCH
      userAgenciesOption: null, // Agency data that is in a suitable format for the filter
      userPagingGroupsOption: null, // Paging groups data that is in a suitable format for the filter
      allUserPagingGroups: null, // These have not been split on agency. Purpose is to help return the search results
      userQualificationsOption: null, // Qualifications data in a suitable format for the filter
      allUserQualifications: null, // These have not been split on agency. Purpose is to help return the search results
      // Results to be displayed
      pagingGroupResults: [], // Results of the returned paging groups from user and group search
      membersResults: [], // Results of users returned from user and group search
      searchResultsLoading: true, // Loading state for search results
      memberResultsLoading: false, // Loading state for member results only. Used when a paging group or show all associated users is clicked and user results need to be reloaded but not paging group results

      // Stores which paging group/member was clicked on
      activePagingGroup: null,
      activeMember: null,

      // CHAT SEARCH (LHS)
      showFilters: false,
      chatFilter: {
        agency: [],
        pagingNumber: [],
        startDate: "",
        endDate: "",
        filterError: null,
      },
      sortDirection: "DESC",
      sortIcon: "icon_sort",
      recipientName: null,
    };
  }

  //=======================================================================
  //================ START DATA INITIALISATION FUNCTIONALITY ==============
  //=======================================================================

  // Get derived state from props sets user agency info before the page renders in order to apply agency permission conditionals before page load.
  // Conditionals include whether to show the create new message button
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.userInfo && nextProps.userInfo.userId !== prevState.UserId) {
      return {
        UserId: nextProps.userInfo.userId,
      };
    }
    if (
      nextProps.userInfo &&
      nextProps.userInfo.userAgencyInfo !== prevState.userAgencyInfo
    ) {
      return {
        userAgencyInfo: nextProps.userInfo.userAgencyInfo,
      };
    }
    if (
      nextProps.userRoleAgency &&
      nextProps.userRoleAgency !== prevState.userRoleAgency
    ) {
      return {
        userRoleAgency: nextProps.userRoleAgency,
      };
    }
    return null;
  }

  // ComponentDidUpdate updates user agency info if the data comes through as null or undefined initially
  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.userInfo &&
      this.props.userInfo.userAgencyInfo !== prevProps.userInfo.userAgencyInfo
    ) {
      this.setState(
        {
          userAgencyInfo: this.props.userInfo.userAgencyInfo,
        },
        () => {
          this.getUserAgencyInfo();
        }
      );
    }
    if (
      this.props.userRoleAgency &&
      this.props.userRoleAgency !== prevProps.userRoleAgency
    ) {
      this.setState(
        {
          userRoleAgency: this.props.userRoleAgency,
        },
        () => {
          this.getUserAgencyInfo();
        }
      );
    }
    if (
      this.props.match &&
      typeof prevProps.match.params.id !== "undefined" &&
      typeof this.props.match.params.id !== "undefined" &&
      prevProps.match.params.id !== this.props.match.params.id
    ) {
      if (this.state.isMobile) {
        this.updateTab(this.props.match.params.id);
      }
    }
  }

  componentDidMount() {
    this.Requests.checkIfForceLogoutIsEnabled();
    this.prepareSearchGroupsAndUsers();
    window.scrollTo(0, 0);
    window.addEventListener("resize", this.handleWindowSizeChange);
    window.addEventListener("orientationchange", this.handleWindowSizeChange);
    window.sessionStorage.setItem("redirectHomeFromEvent", "");
    if (this.state.isMobile) {
      this.updateTab(this.props.match.params.id);
    }
    this.getAllChatMessages();
    this.getUserAgencyInfo();
    this.calculateUserSearchHeaderHeight();
  }

  // Function to track window rescalling
  handleWindowSizeChange = () => {
    this.setState({
      width: window.innerWidth,
      isMobile: window.innerWidth <= 1023 ? true : false,
    });
    this.calculateUserSearchHeaderHeight();
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  // Function to get user agency info from props data and calculate user agency permissions specifically for chat
  // Permissions determine whether a user is allowed to create OneOnOne, Paging group and Adhoc group chats
  getUserAgencyInfo = () => {
    let AV;
    let CFA;
    let SES;
    if (this.state.userAgencyInfo) {
      for (let i = 0; i < this.state.userAgencyInfo.length; i++) {
        if (this.state.userAgencyInfo[i].agency === "AV") {
          AV = this.state.userAgencyInfo[i];
          this.setState({
            AVChatSettings: {
              chatOneToOneEnabled: AV.chatOneToOneEnabled,
              chatGroupEnabled: AV.chatGroupEnabled,
              chatAdhocGroupEnabled: AV.chatAdhocGroupEnabled,
            },
          });
          if (
            AV.chatOneToOneEnabled ||
            AV.chatGroupEnabled ||
            AV.chatAdhocGroupEnabled
          ) {
            this.setState({
              showCreateNewMessageButton: true,
            });
          }
        } else if (this.state.userAgencyInfo[i].agency === "CFA") {
          CFA = this.state.userAgencyInfo[i];
          this.setState({
            CFAChatSettings: {
              chatOneToOneEnabled: CFA.chatOneToOneEnabled,
              chatGroupEnabled: CFA.chatGroupEnabled,
              chatAdhocGroupEnabled: CFA.chatAdhocGroupEnabled,
            },
          });
          if (
            CFA.chatOneToOneEnabled ||
            CFA.chatGroupEnabled ||
            CFA.chatAdhocGroupEnabled
          ) {
            this.setState({
              showCreateNewMessageButton: true,
            });
          }
        } else if (this.state.userAgencyInfo[i].agency === "SES") {
          SES = this.state.userAgencyInfo[i];
          this.setState({
            SESChatSettings: {
              chatOneToOneEnabled: SES.chatOneToOneEnabled,
              chatGroupEnabled: SES.chatGroupEnabled,
              chatAdhocGroupEnabled: SES.chatAdhocGroupEnabled,
            },
          });
          if (
            SES.chatOneToOneEnabled ||
            SES.chatGroupEnabled ||
            SES.chatAdhocGroupEnabled
          ) {
            this.setState({
              showCreateNewMessageButton: true,
            });
          }
        }
      }
    }
  };
  //=======================================================================
  //================== END DATA INITIALISATION FUNCTIONALITY ==============
  //=======================================================================

  //=======================================================================
  //==================== START DATA REQUEST FUNCTIONALITY =================
  //=======================================================================

  /**
   * Called when the page needs to be refreshed
   */
  handleRefresh = () => {
    this.prepareSearchGroupsAndUsers();
    this.getAllChatMessages();
  };

  // Function to get all chat messages for a user.
  // body is only passed in if the call is made due to pagination
  getAllChatMessages = debounce((body) => {
    let Body;
    if (body) {
      Body = body;
    } else {
      Body = {
        skip: this.state.skipCount,
        take: this.state.takeCount,
        agency:
          this.state.chatFilter.agency.length === 0
            ? null
            : this.state.chatFilter.agency,
        pagingNumber:
          this.state.chatFilter.pagingNumber.length === 0
            ? null
            : this.state.chatFilter.pagingNumber,
        sortDirection: this.state.sortDirection,
        dateTimeAfterOrEqual:
          this.state.chatFilter.startDate === ""
            ? null
            : new Date(this.state.chatFilter.startDate).toISOString(),
        dateTimeBeforeOrEqual:
          this.state.chatFilter.endDate === ""
            ? null
            : endOfDay(new Date(this.state.chatFilter.endDate)).toISOString(),
      };
    }

    this.setState(
      {
        // If a body is passed in as an argument or if the state flag "loadAllChatsWithoutReloadingThread" is true, messages are loaded without triggering loading state for silent update or pagination update
        isLoadingAllChats:
          body || this.state.loadAllChatsWithoutReloadingThread ? false : true,
      },
      () => {
        // API call to get all Chat messages
        this.Requests.callAPI(this.Requests.postChatMessages, Body).then(
          (data) => {
            if (data && data.status && data.status === 200) {
              let allChatMessages;
              if (data.data) {
                // If no body has been passed in as an argument, it's assumed that it is an initial get and an array needs to be created rather than concatonating the data into the array
                if (
                  (!body && this.state.loadAllChatsWithoutReloadingThread) ||
                  this.state.allChatMessages === false
                ) {
                  allChatMessages = data.data;
                } else {
                  // If a body has been passed in as an argument, the data is concatonated into the existing array of chat messages as the body would indicate that pagination has been called, and 30 more messages are being added into the existing array rather than replacing the array entirely.
                  //Check to see if API did not previously fail
                  if (this.state.allChatMessages) {
                    allChatMessages = this.state.allChatMessages.concat(
                      data.data
                    );
                  }
                }
              }
              // If body has not been passed in as an argument
              if (!body) {
                // If data return has less than 30 results, "hasMoreMessagesToLoad" flag is set to false as less than 30 results indicates there are no more messages to load.
                // When flag is set to false, it prevents pagination from firing GET again and causing unnecessary data download
                if (data.data.length < 30) {
                  this.setState({
                    hasMoreMessagesToLoad: false,
                  });
                }
                this.setState(
                  {
                    allChatMessages: data.data[0] ? allChatMessages : null,
                    chatIndex: this.state.loadAllChatsWithoutReloadingThread
                      ? this.state.chatIndex
                      : data.data[0] && data.data[0].id,
                    isLoadingAllChats: false,
                    isLoadingChats: false,
                    // searchResultsLoading: false
                  },
                  () => {
                    if (
                      !this.state.isMobile &&
                      !this.state.loadAllChatsWithoutReloadingThread &&
                      !body
                    ) {
                      this.getChatThread();
                    }
                    if (this.state.loadAllChatsWithoutReloadingThread) {
                      this.setState({
                        loadAllChatsWithoutReloadingThread: false,
                      });
                    }
                  }
                );
              } else {
                // If data return has less than 30 results, "hasMoreMessagesToLoad" flag is set to false as less than 30 results indicates there are no more messages to load.
                // When flag is set to false, it prevents pagination from firing GET again and causing unnecessary data download
                if (data.data.length < 30) {
                  this.setState({
                    hasMoreMessagesToLoad: false,
                  });
                }
                this.setState(
                  {
                    allChatMessages: allChatMessages,
                    isLoadingAllChats: false,
                    isLoadingChats: false,
                    // searchResultsLoading: false
                  },
                  () => {
                    if (
                      !this.state.isMobile &&
                      !this.state.loadAllChatsWithoutReloadingThread &&
                      !body
                    ) {
                      this.getChatThread();
                    }
                    if (this.state.loadAllChatsWithoutReloadingThread) {
                      this.setState({
                        loadAllChatsWithoutReloadingThread: false,
                      });
                    }
                  }
                );
              }
            } else {
              let ErrorMessage =
                copy.sasChat.getChatMessagesAPIErrorMessage +
                ` (Error #${copy.errorCodes.getChatMessagesAPIErrorMessage})`;
              // if (data && data.data && data.data.SASMessageClient) {
              //   ErrorMessage = data.data.SASMessageClient;
              // }
              this.setState(
                {
                  allChatMessages: false,
                  chatIndex: null,
                  chatThread: null,
                  isLoadingAllChats: false,
                  isLoadingChats: false,
                  isLoadingThread: false,
                  errorMessage: ErrorMessage,
                  showSendMessageButton: false,
                  // searchResultsLoading: false,
                  loadAllChatsWithoutReloadingThread: false,
                  newMessageThread: false,
                  newMessagePagingGroup: [],
                  newMessagePagingGroupSearchData: [],
                  newMessageUsers: [],
                  searchData: {
                    keyword: [],
                    agency: [],
                    pagingNumber: [],
                    qualificationId: [],
                    availability: null,
                  },
                },
                () => {
                  setTimeout(
                    function () {
                      this.setState({ errorMessage: null });
                    }.bind(this),
                    5000
                  );
                }
              );
            }
          }
        );
      }
    );
  }, 100);

  // Function to get chat thread data. Function called on initial load of page using the ID of the latest message in the array of chat messages.
  // Function will be called with a new ID whenever a message is clicked
  // PropsId will only be passed in when creating a new message
  getChatThread = debounce((propsId) => {
    const id = propsId ? propsId : this.state.chatIndex;

    if (id) {
      this.setState(
        {
          isLoadingThread: propsId ? false : true,
        },
        () => {
          // Get Chat Thread API call
          this.Requests.callAPI(this.Requests.getChatThread, id).then(
            (data) => {
              if (data && data.status && data.status === 200) {
                this.setState(
                  {
                    createNewMessage: false,
                    chatThread: data.data,
                    isLoadingThread: false,
                  },
                  () => {
                    // PropsId is passed into the function as an argument when a new message thread has been created by the user.
                    // The thread is loaded without triggering loading states for the get all chat messages and the get chat thread functions.
                    if (propsId) {
                      this.setState(
                        {
                          loadAllChatsWithoutReloadingThread: true,
                          isLoadingChats: true,
                          allChatMessages: [],
                          hasMoreMessagesToLoad: true,
                          chatIndex: id,
                        },
                        () => {
                          this.getAllChatMessages();
                        }
                      );
                    }
                    // Unread message indicator only removed if API call was successful
                    this.removeUnreadMessageIndicator();
                  }
                );
              } else {
                let ErrorMessage =
                  copy.sasChat.updateChatThreadAPIErrorMessage +
                  ` (Error #${copy.errorCodes.updateChatThreadAPIErrorMessage})`;
                // if (data && data.data && data.data.SASMessageClient) {
                //   ErrorMessage = data.data.SASMessageClient;
                // }
                this.setState(
                  {
                    errorMessage: ErrorMessage,
                    createNewMessage: false,
                    isLoadingThread: false,
                    showMessageList: true,
                    chatThread: false,
                  },
                  () => {
                    setTimeout(
                      function () {
                        this.setState({ errorMessage: null });
                      }.bind(this),
                      5000
                    );
                  }
                );
              }
            }
          );
        }
      );
    }
  }, 100);

  //=======================================================================
  //===================== END DATA REQUEST FUNCTIONALITY ==================
  //=======================================================================

  //=======================================================================
  //========== START OPEN AND CREATE NEW MESSAGE FUNCTIONALITY ============
  //=======================================================================

  // This function will be called whenever a new tab is clicked (which leads to the URL changing) It will update the current tab
  updateTab(index) {
    if (index !== undefined) {
      if (index === "new-message") {
        this.setState({
          newMessageThread: false,
          createNewMessage: true,
          showMessageList: false,
        });
      } else {
        this.setState(
          {
            chatIndex: index,
            showFullMessage: true,
            showMessageList: false,
          },
          () => {
            this.getChatThread();
          }
        );
      }
    } else {
      this.setState({
        showFullMessage: false,
        showMessageList: true,
      });
    }
  }

  // Function called when a message is clicked on Desktop. Function updates chat index, then calls getChatThread function with updated index, loading that chat thread.
  openMessage = (index) => {
    this.setState(
      {
        createNewMessage: false,
        chatIndex: index,
      },
      () => {
        this.getChatThread();
      }
    );
  };

  // This function removes the unread message indicator on the chat component
  removeUnreadMessageIndicator = () => {
    let allChatMessages = this.state.allChatMessages;
    if (allChatMessages && allChatMessages.length) {
      const activeChat = allChatMessages.findIndex(
        (element) => element.id === this.state.chatIndex
      );
      recipientName = allChatMessages[activeChat].recipientName;
      let newAllChatMessage = [...allChatMessages];
      newAllChatMessage[activeChat] = {
        ...newAllChatMessage[activeChat],
        threadReadStatus: true,
      };
      this.setState({
        allChatMessages: newAllChatMessage,
      });
    }
  };

  // Function called when a message is clicked on Mobile. Function updates chat index, then calls getChatThread function with updated index, loading that chat thread in a new tab.
  openMessageMobile = (index) => {
    this.setState(
      {
        createNewMessage: false,
        chatIndex: index,
        showMessageList: false,
      },
      () => {
        this.props.history.push({
          pathname: "/chat/chats/" + index,
        });
        this.removeUnreadMessageIndicator();
      }
    );
  };

  // Called when create new message button is clicked on mobile. Function opens the user and group search in a new tab.
  createNewMessageMobile = () => {
    this.setState(
      {
        newMessageThread: false,
        createNewMessage: true,
      },
      () => {
        this.props.history.push({
          pathname: "/chat/chats/new-message",
        });
      }
    );
  };

  // Returns user back to message list when in either create new message tab or a message thread tab
  mobileChatBack = () => {
    this.props.history.goBack();
  };

  /**
   * This function allows the user to create a new message
   * Clicking on it will bring up the search so that the user can search
   * for users/paging groups to send a message to
   */
  CreateNewMessage = () => {
    this.setState({
      showUserAndGroupSearch: true,
      newMessageThread: false,
      createNewMessage: true,
      showSendMessageButton: false,
      newMessagePagingGroup: [],
      newMessagePagingGroupSearchData: [],
      newMessageUsers: [],
      searchData: {
        keyword: [],
        agency: [],
        pagingNumber: [],
        qualificationId: [],
        availability: null,
      },
      pagingGroupSkip: 0,
      userListSkip: 0,
    });

    this.refineSearch();
  };

  ReadAllMessage = () => {
    this.Requests.callAPI(this.Requests.readAllChatNotifications).then(
      (data) => {
        if (data && data.status && data.status === 200) {
          this.props.history.push({
            pathname: "/chat/chats/",
          });
        } else {
          let ErrorMessage =
            copy.sasChat.readAllChatAPIErrorMessage +
            ` (Error #${copy.errorCodes.readAllChatAPIErrorMessage})`;
          this.setState({ errorMessage: ErrorMessage }, () => {
            setTimeout(
              function () {
                this.setState({ errorMessage: null });
              }.bind(this),
              5000
            );
          });
        }
      }
    );
  };

  // Function opens the new message component to compose a message to the selected user(s) or paging group
  createNewMessageThread = () => {
    let newMessageUsers = this.state.newMessageUsers;

    const includesCurrentUser = newMessageUsers.findIndex(
      (element) => element === this.state.UserId
    );

    if (includesCurrentUser === -1) {
      this.setState({
        newMessageThread: true,
      });
    } else {
      let ErrorMessage = copy.error_handling.messageSentToSelf;
      this.setState(
        {
          errorMessage: ErrorMessage,
        },
        () => {
          setTimeout(
            function () {
              this.setState({ errorMessage: null });
            }.bind(this),
            5000
          );
        }
      );
    }
  };

  // Function closes chat thread. Button is only shown on tablet, as mobile uses back button and desktop shows all messages and a chat thread.
  closeThread = () => {
    this.setState({
      showUserAndGroupSearch: true,
      chatThread: null,
    });
    this.props.history.push({
      pathname: "/chat/chats/",
    });
  };

  //=======================================================================
  //============ END OPEN AND CREATE NEW MESSAGE FUNCTIONALITY ============
  //=======================================================================

  //=======================================================================
  //==================== START PAGINATION FUNCTIONALITY ===================
  //=======================================================================

  // Pagination for all chat messages
  triggerPagination = () => {
    if (
      // If allChatMessages is less than 30, the user does not have enough messages to require pagination.
      // If hasMoreMessagesToLoad is false, it means the pagination has reached less that 30 messages
      this.state.allChatMessages.length >= 30 &&
      this.state.hasMoreMessagesToLoad
    ) {
      let messageId;
      // Gets messageId of the last message from the array of chat messages
      if (this.state.allChatMessages && this.state.allChatMessages.length > 1) {
        messageId =
          this.state.allChatMessages[this.state.allChatMessages.length - 1].id;
      }

      // Constructed body that's passed into the getAllChatMessages function as an argument
      const body = {
        skip: this.state.skipCount,
        take: this.state.takeCount,
        agency:
          this.state.chatFilter.agency.length === 0
            ? null
            : this.state.chatFilter.agency,
        pagingNumber:
          this.state.chatFilter.pagingNumber.length === 0
            ? null
            : this.state.chatFilter.pagingNumber,
        sortDirection: this.state.sortDirection,

        dateTimeAfterOrEqual:
          this.state.chatFilter.startDate === ""
            ? null
            : new Date(this.state.chatFilter.startDate).toISOString(),
        dateTimeBeforeOrEqual:
          this.state.chatFilter.endDate === ""
            ? null
            : endOfDay(new Date(this.state.chatFilter.endDate)).toISOString(),

        // If we are sorting by ascending, the message at the bottom of the screen is the message after
        [this.state.sortDirection === "DESC" ? "chatIdBefore" : "chatIdAfter"]:
          messageId,
      };

      this.getAllChatMessages(body);
    } else {
      // If hasMoreMessagesToLoad is true, sets flag to false as the returned data is less than 30 and pagination isn't needed any more.
      if (this.state.hasMoreMessagesToLoad) {
        this.setState({
          hasMoreMessagesToLoad: false,
        });
      }
    }
  };

  // Pagination for paging group results for user and group search
  triggerGroupPagination = () => {
    if (
      // If pagingGroupResults is less than 30, the user does not have enough messages to require pagination.
      // If hasMorePagingGroupsToLoad is false, it means the pagination has reached less that 30 paging groups
      this.state.pagingGroupResults.length >= 30 &&
      this.state.hasMorePagingGroupsToLoad
    ) {
      this.setState(
        {
          // Sets flag to indicate paging data is being loaded.
          // This means that only the paging data gets called in the "searchGroupsAndUsers" function instead of searching for both groups and users
          isLoadingPagingData: true,
          isLoadingUserData: false,
        },
        () => {
          // Setting up body of the API call
          this.setState(
            {
              // Adds 30 to the existing paging group skip count
              pagingGroupSkip: this.state.pagingGroupSkip + 30,
            },
            () => {
              // Constructed body that's passed into the "searchGroupsAndUsers" function as an argument
              let body = {
                take: 30,
                skip: this.state.pagingGroupSkip,
                // keyword should be null if includePagingNumberDecendants us true or the user has selected a group so check if includePagingNumberDecendants has some groups
                keywords: this.state.searchData
                  ? (this.state.searchData.keyword &&
                      this.state.searchData.keyword.length === 0) ||
                    this.state.newMessagePagingGroupSearchData.length !== 0
                    ? null
                    : this.state.searchData.keyword
                  : null,
                agency: this.state.searchData
                  ? this.state.searchData.agency.length === 0
                    ? null
                    : this.state.searchData.agency
                  : null,
                // pagingNumber: this.state.newMessagePagingGroupSearchData
                //   ? this.state.newMessagePagingGroupSearchData.length === 0
                //     ? null
                //     : this.state.newMessagePagingGroupSearchData
                //   : null,
                qualificationId: this.state.searchData
                  ? this.state.searchData.qualificationId.length === 0
                    ? null
                    : this.state.searchData.qualificationId
                  : null,
                // if "show all associated users" is checked, includes "includePagingNumberDecendants" in the API request to show all users associated to paging group or agency selected
                includePagingNumberDecendants: this.state
                  .includePagingNumberDecendants
                  ? true
                  : false,
              };

              // Call the filter data function to get more data
              this.searchGroupsAndUsers(body);
            }
          );
        }
      );
    } else {
      // If hasMorePagingGroupsToLoad is true, sets flag to false as the returned data is less than 30 and pagination isn't needed any more.
      if (this.state.hasMorePagingGroupsToLoad) {
        this.setState({
          hasMorePagingGroupsToLoad: false,
        });
      }
    }
  };

  // Pagination for user results for user and group search
  triggerUserPagination = () => {
    if (
      // If membersResults is less than 30, the user does not have enough messages to require pagination.
      // If hasMorePagingGroupsToLoad is false, it means the pagination has reached less that 30 users
      this.state.membersResults &&
      this.state.membersResults.length >= 30 &&
      this.state.hasMoreUsersToLoad
    ) {
      this.setState(
        {
          // Sets flag to indicate users are being loaded.
          // This means that only the user search gets called in the "searchGroupsAndUsers" function instead of searching for both groups and users
          isLoadingUserData: true,
          isLoadingPagingData: false,
        },
        () => {
          // Setting up body of the API call
          this.setState(
            {
              // Adds 30 to the existing paging group skip count
              userListSkip: this.state.userListSkip + 30,
            },
            () => {
              let body = {
                take: 30,
                skip: this.state.userListSkip,
                // keyword should be null if includePagingNumberDecendants us true or the user has selected a group so check if includePagingNumberDecendants has some groups
                keywords: this.state.searchData
                  ? (this.state.searchData.keyword &&
                      this.state.searchData.keyword.length === 0) ||
                    this.state.newMessagePagingGroupSearchData.length !== 0
                    ? null
                    : this.state.searchData.keyword
                  : null,
                agency: this.state.searchData
                  ? this.state.searchData.agency.length === 0
                    ? null
                    : this.state.searchData.agency
                  : null,
                pagingNumber: this.state.newMessagePagingGroupSearchData
                  ? this.state.newMessagePagingGroupSearchData.length === 0
                    ? null
                    : this.state.newMessagePagingGroupSearchData
                  : null,
                qualificationId: this.state.searchData
                  ? this.state.searchData.qualificationId.length === 0
                    ? null
                    : this.state.searchData.qualificationId
                  : null,
                // if "show all associated users" is checked, includes "includePagingNumberDecendants" in the API request to show all users associated to paging group or agency selected
                includePagingNumberDecendants: this.state
                  .includePagingNumberDecendants
                  ? true
                  : false,
              };

              // Call the filter data function to get more data
              this.searchGroupsAndUsers(body);
            }
          );
        }
      );
    } else {
      // If hasMoreUsersToLoad is true, sets flag to false as the returned data is less than 30 and pagination isn't needed any more.
      if (this.state.hasMoreUsersToLoad) {
        this.setState({
          hasMoreUsersToLoad: false,
        });
      }
    }
  };

  //=======================================================================
  //==================== END PAGINATION FUNCTIONALITY =====================
  //=======================================================================

  //=======================================================================
  //================ START GROUP AND USER SEARCH FUNCTIONS ================
  //=======================================================================

  //This function prepares the filter data (agencies and paging groups) to ensure they are in a suitable format for the plugin
  prepareSearchGroupsAndUsers = () => {
    // Preparing agency data for the filter
    let userAgenciesOption = [];
    let pagingGroups = this.props.authorisedPagingGroups;
    let allUserPagingGroups = {};

    if (this.props.userAgencies) {
      this.props.userAgencies.forEach((agency, index) => {
        // Dynamic and AV should not be a paging group filter for chat
        if (agency !== "DYNAMIC" && agency !== "AV") {
          userAgenciesOption.push({
            key: index,
            text: agency,
            value: agency,
          });
          // null/undefined check for pagingGroups[agency]
          if (pagingGroups && pagingGroups[agency]) {
            pagingGroups[agency].forEach((pagingGroup) => {
              allUserPagingGroups[pagingGroup.key] = {
                key: pagingGroup.key,
                text: pagingGroup.text,
                value: pagingGroup.key,
              };
            });
          }
        }
      });
    }

    this.setState({
      userAgenciesOption: userAgenciesOption ? userAgenciesOption : [],
      userPagingGroupsOption: pagingGroups ? pagingGroups : [],
      allUserPagingGroups: allUserPagingGroups,
    });

    // Preparing qualifications data
    this.Requests.callAPI(this.Requests.getUserQualifications).then((data) => {
      if (data && data.status && data.status === 200) {
        let qualificationsOption = {};
        let allUserQualifications = {};

        this.props.userAgencies.forEach((agency) => {
          qualificationsOption[agency] = [];
        });

        data.data.forEach((qualification) => {
          qualificationsOption[qualification.agency].push({
            key: qualification.id,
            text: qualification.name,
            value: qualification.id,
          });
          allUserQualifications[qualification.id] = {
            key: qualification.id,
            text: qualification.name,
            value: qualification.id,
          };
        });

        this.setState({
          userQualificationsOption: qualificationsOption,
          allUserQualifications: allUserQualifications,
        });
      } else {
        let ErrorMessage =
          copy.sasChat.chatFilterQualsInfoAPIErrorMessage +
          ` (Error #${copy.errorCodes.chatFilterQualsInfoAPIErrorMessage})`;
        // if (data && data.data && data.data.SASMessageClient) {
        //   ErrorMessage = data.data.SASMessageClient;
        // }
        this.setState(
          {
            errorMessage: ErrorMessage,
            userQualificationsOption: {},
          },
          () => {
            setTimeout(
              function () {
                this.setState({ errorMessage: null });
              }.bind(this),
              5000
            );
          }
        );
      }
    });
  };

  // Function to calculate the height of the search results header. Header height changes depending on the amount of keywords and search parameters applied
  // Calculated height is then subtracted from height of container showing search results.
  calculateUserSearchHeaderHeight = () => {
    let MessageHeading;

    if (document.getElementById("userSearchHeader")) {
      MessageHeading = document.getElementById("userSearchHeader").clientHeight;
    } else {
      MessageHeading = 0;
    }

    if (this.resultsWrapperHeight) {
      let ResultsWrapperHeight =
        this.resultsWrapperHeight.clientHeight - MessageHeading;

      let PagingGroupResultsHeight;
      if (this.state.showShowAllUserToggleButton) {
        PagingGroupResultsHeight = ResultsWrapperHeight / 2 - 69;
      } else {
        PagingGroupResultsHeight = ResultsWrapperHeight / 2 - 67;
      }
      let UserResultsHeight = ResultsWrapperHeight / 2 - 57;
      this.setState({
        resultsHeadingHeight: MessageHeading,
        resultsWrapperHeight: ResultsWrapperHeight,
        pagingGroupResultsHeight: PagingGroupResultsHeight,
        userResultsHeight: UserResultsHeight,
      });
    } else {
      return;
    }
  };

  //Function that's called when a user clicks on a paging group result
  pagingGroupResultClick = (item) => {
    this.setState({
      activePagingGroup: item,
      activeMember: null,
    });
  };

  //Function that's called when the user clicks on a member result
  membersResultClick = (item) => {
    this.setState({
      activePagingGroup: null,
      activeMember: item,
    });
  };

  /**
   * This function takes the user back to the search/filter so that they can
   * refine their search
   */
  refineSearch = () => {
    this.setState({
      showUserAndGroupSearch: true,
      showSendMessageButton: false,
      showRefineSearchButton: false,
      showSearchParameters: false,
      searchResultsLoading: true,
      pagingGroupResults: null,
      newMessagePagingGroup: [],
      newMessageUsers: [],
      membersResults: null,
      // activePagingGroup: null,
      // activeMember: null
      pagingGroupSkip: 0,
      userListSkip: 0,
      searchData: {
        keyword: [],
        agency: [],
        qualificationId: [],
        availability: null,
      },
    });
  };

  showAllUsersForSelectedGroup = () => {
    this.setState(
      (prevState) => ({
        includePagingNumberDecendants: !prevState.includePagingNumberDecendants,
        loadUsersWithoutRefreshing: true,
        isLoadingUserData: true,
        memberResultsLoading: true,
        membersResults: [],
      }),
      () => {
        let body = {
          take: 30,
          skip: 0,
          // keyword to NOT be applied if at least one paging group is selected or if includePagingNumberDescendants
          // is true when a group is selected
          keywords: this.state.searchData
            ? this.state.newMessagePagingGroupSearchData.length !== 0 ||
              (this.state.includePagingNumberDecendants &&
                this.state.newMessagePagingGroupSearchData.length !== 0)
              ? null
              : this.state.searchData.keyword
            : null,
          agency: this.state.searchData
            ? this.state.searchData.agency.length === 0
              ? null
              : this.state.searchData.agency
            : null,
          pagingNumber:
            // If no paging groups have been selected, the filter using the original search criteria
            this.state.newMessagePagingGroupSearchData.length === 0
              ? null
              : this.state.newMessagePagingGroupSearchData,
          qualificationId: this.state.searchData
            ? this.state.searchData.qualificationId.length === 0
              ? null
              : this.state.searchData.qualificationId
            : null,
          includePagingNumberDecendants: this.state
            .includePagingNumberDecendants
            ? true
            : false,
        };
        this.searchGroupsAndUsers(body);
      }
    );
  };

  /**
   * This function updates the users displayed based on the paging groups that have been selected
   */
  handlePagingGroupSelection = (item) => {
    // Get the agency selected
    const agencyChatSettings = `${this.state.searchData.agency[0]}ChatSettings`;

    // Grab the selected agency's settings that are saved in the state AVChatSettings, CFAChatSettings, SESChatSettings
    let chatOneToOneEnabled =
      this.state[agencyChatSettings].chatOneToOneEnabled;
    let chatGroupEnabled = this.state[agencyChatSettings].chatGroupEnabled;
    let chatAdhocGroupEnabled =
      this.state[agencyChatSettings].chatAdhocGroupEnabled;

    // Update the list of selected paging groups
    let newMessagePagingGroupSearchData;
    // If a paging group was selected, then add it to the list of paging groups
    if (this.state.newMessagePagingGroupSearchData.indexOf(item) === -1) {
      newMessagePagingGroupSearchData = [
        ...this.state.newMessagePagingGroupSearchData,
        item,
      ];
      // If a paging group was removed, then remove it from the list of paging groups
    } else {
      newMessagePagingGroupSearchData = [
        ...this.state.newMessagePagingGroupSearchData,
      ];

      newMessagePagingGroupSearchData.splice(
        newMessagePagingGroupSearchData.indexOf(item),
        1
      );
    }

    // Update the list of paging groups and state of the loader for member results
    this.setState(
      {
        showUserAndGroupSearch: false,
        userListSkip: 0,
        hasMoreUsersToLoad: true,
        newMessagePagingGroupSearchData: newMessagePagingGroupSearchData,
        newMessageUsers: [],
        newMessagePagingGroup:
          chatGroupEnabled && newMessagePagingGroupSearchData.length === 1
            ? newMessagePagingGroupSearchData
            : [],
        memberResultsLoading: true,
      },
      () => {
        // Creating body for the API call
        let body = {
          take: 30,
          // keyword to NOT be applied if at least one paging group is selected or if includePagingNumberDescendants
          // is true when a group is selected
          keywords: this.state.searchData
            ? this.state.newMessagePagingGroupSearchData.length !== 0 ||
              (this.state.includePagingNumberDecendants &&
                this.state.newMessagePagingGroupSearchData.length !== 0)
              ? null
              : this.state.searchData.keyword
            : null,
          agency:
            this.state.searchData.agency.length === 0
              ? null
              : this.state.searchData.agency,
          pagingNumber:
            // If no paging groups have been selected, the filter using the original search criteria
            this.state.newMessagePagingGroupSearchData.length === 0
              ? null
              : this.state.newMessagePagingGroupSearchData,
          qualificationId:
            this.state.searchData.qualificationId.length === 0
              ? null
              : this.state.searchData.qualificationId,
          includePagingNumberDecendants: this.state
            .includePagingNumberDecendants
            ? true
            : false,
        };

        if (this.state.newMessagePagingGroupSearchData.length > 0) {
          this.setState({
            showShowAllUserToggleButton: true,
          });
        } else {
          this.setState({
            showShowAllUserToggleButton: false,
          });
        }

        if (this.state.newMessagePagingGroupSearchData.length === 0) {
          this.setState(
            {
              loadUsersWithoutRefreshing: false,
              memberResultsLoading: true,
            },
            () => {
              this.searchGroupsAndUsers();
            }
          );
        }

        // if (this.state.newMessagePagingGroupSearchData.length === 1) {
        //   this.setState({
        //     newMessagePagingGroup: this.state.newMessagePagingGroupSearchData,
        //     showSendMessageButton: true,
        //     isPagingGroupMessage: true,
        //   });
        // }

        // Make API call to get updated list of users
        this.Requests.callAPI(this.Requests.filterUsers, body)
          .then((data) => {
            if (data && data.status && data.status === 200) {
              this.setState(
                {
                  membersResults: data.data,
                  memberResultsLoading: false,
                },
                () => {
                  this.calculateUserSearchHeaderHeight();
                }
              );
            } else {
              let ErrorMessage =
                copy.sasChat.prepareUserFilterResultsAPIErrorMessage +
                ` (Error #${copy.errorCodes.filterChangeAPIErrorMessage})`;
              // if (data && data.data && data.data.SASMessageClient) {
              //   ErrorMessage = data.data.SASMessageClient;
              // }
              this.setState(
                {
                  errorMessage: ErrorMessage,
                  memberResultsLoading: false,
                },
                () => {
                  setTimeout(
                    function () {
                      this.setState({ errorMessage: null });
                    }.bind(this),
                    5000
                  );
                }
              );
            }
          })
          .then(() => {
            // Hides button if no paging group or users are selected
            if (
              this.state.newMessagePagingGroup &&
              this.state.newMessagePagingGroup.length === 0 &&
              this.state.newMessageUsers &&
              this.state.newMessageUsers.length === 0
            ) {
              this.setState({
                isAdhocGroupMessage: false,
                showSendMessageButton: false,
                isPagingGroupMessage: false,
              });
            }
            // Shows paging group chat button when one paging group is selected
            else if (
              chatGroupEnabled &&
              this.state.newMessagePagingGroup &&
              this.state.newMessagePagingGroup.length === 1 &&
              this.state.newMessageUsers &&
              this.state.newMessageUsers.length === 0
            ) {
              this.setState({
                isAdhocGroupMessage: false,
                showSendMessageButton: true,
                isPagingGroupMessage: true,
              });
            }
            // Shows chat button for adhoc group message
            if (
              chatAdhocGroupEnabled &&
              this.state.newMessagePagingGroup &&
              this.state.newMessagePagingGroup.length === 0 &&
              this.state.newMessageUsers &&
              this.state.newMessageUsers.length > 1
            ) {
              this.setState({
                showSendMessageButton: true,
                isPagingGroupMessage: false,
                isAdhocGroupMessage: true,
              });
            }
            // Shows new chat button for OneOnOne chat or Custom Group Chat as multiple paging groups selected and 1 or more users selected
            else if (
              chatOneToOneEnabled &&
              this.state.newMessagePagingGroup &&
              this.state.newMessagePagingGroup.length === 0 &&
              this.state.newMessageUsers &&
              this.state.newMessageUsers.length === 1
            ) {
              this.setState({
                isAdhocGroupMessage: false,
                showSendMessageButton: true,
                isPagingGroupMessage: false,
              });
            }
          });
      }
    );
  };

  handleUserSelection = (user) => {
    // Get the agency selected
    const agencyChatSettings = `${this.state.searchData.agency[0]}ChatSettings`;

    // Grab the selected agency's settings that are saved in the state AVChatSettings, CFAChatSettings, SESChatSettings
    let chatOneToOneEnabled =
      this.state[agencyChatSettings].chatOneToOneEnabled;
    let chatGroupEnabled = this.state[agencyChatSettings].chatGroupEnabled;
    let chatAdhocGroupEnabled =
      this.state[agencyChatSettings].chatAdhocGroupEnabled;

    // Resets paging group to blank when a user is selected
    if (this.state.newMessagePagingGroup.length === 1) {
      this.setState({
        newMessagePagingGroup: [],
      });
    }

    // Shows and hides the show all associated users button
    if (this.state.newMessagePagingGroupSearchData.length > 0) {
      this.setState({
        showShowAllUserToggleButton: true,
      });
    } else {
      this.setState({
        showShowAllUserToggleButton: false,
      });
    }
    // Update the list of selected paging groups
    let newMessageUsers;
    // If a paging group was selected, then add it to the list of paging groups
    if (
      this.state.newMessageUsers.indexOf(user) === -1 ||
      this.state.newMessageUsers.length === 0
    ) {
      if (chatAdhocGroupEnabled) {
        newMessageUsers = [...this.state.newMessageUsers, user];
      } else {
        newMessageUsers = [user];
      }
      // If a paging group was removed, then remove it from the list of paging groups
    } else {
      //  // This finds the object in the array that has the same id as the index of the chat that called the function
      newMessageUsers = [...this.state.newMessageUsers];
      newMessageUsers.splice(newMessageUsers.indexOf(user), 1);

      // While removing a user,
      // Check if only one user selected, reset the list since they may not have permissions for one-on-one chat
      if (newMessageUsers.length === 1 && !chatOneToOneEnabled) {
        newMessageUsers = [];
      }
    }
    this.setState(
      {
        showUserAndGroupSearch: false,
        newMessageUsers: newMessageUsers,
      },
      () => {
        // Re adds the paging group number to the newMessagePagingGroup array so the message paging group button reappears
        if (
          this.state.newMessageUsers.length === 0 &&
          this.state.newMessagePagingGroupSearchData.length === 1
        ) {
          this.setState(
            {
              newMessagePagingGroup: [
                this.state.newMessagePagingGroupSearchData[0],
              ],
            },
            () => {
              if (
                chatGroupEnabled &&
                this.state.newMessagePagingGroup &&
                this.state.newMessagePagingGroup.length === 1 &&
                this.state.newMessageUsers &&
                this.state.newMessageUsers.length === 0
              ) {
                this.setState({
                  isAdhocGroupMessage: false,
                  showSendMessageButton: true,
                  isPagingGroupMessage: true,
                });
              }
            }
          );
        }

        // When array reaches one user, this function checks the user's permission to see if one on one messaging is allowed
        // If one on one messaging isn't allowed, clears array.
        if (this.state.newMessageUsers.length === 1) {
          const user = this.state.newMessageUsers[0];
          // Individual user settings
          let individualOneToOneEnabled;
          for (let i = 0; i < user.userAgencyInfo.length; i++) {
            let agency = user.userAgencyInfo[i];
            if (this.state.searchData.agency.indexOf(agency.agency) !== -1) {
              individualOneToOneEnabled =
                user.userAgencyInfo[i].chatOneToOneEnabled; // One to one enabled attibute
            }
          }
          if (!individualOneToOneEnabled) {
            this.setState({
              showSendMessageButton: false,
              newMessageUsers: [],
            });
          }
        }

        // Hides button if no paging group or users are selected
        if (
          this.state.newMessagePagingGroup &&
          this.state.newMessagePagingGroup.length === 0 &&
          this.state.newMessageUsers &&
          this.state.newMessageUsers.length === 0
        ) {
          this.setState({
            isAdhocGroupMessage: false,
            showSendMessageButton: false,
            isPagingGroupMessage: false,
          });
        }
        // Shows paging group chat button when one paging group is selected
        else if (
          chatGroupEnabled &&
          this.state.newMessagePagingGroup &&
          this.state.newMessagePagingGroup.length === 1 &&
          this.state.newMessageUsers &&
          this.state.newMessageUsers.length === 0
        ) {
          this.setState({
            isAdhocGroupMessage: false,
            showSendMessageButton: true,
            isPagingGroupMessage: true,
          });
        }
        // Shows chat button for adhoc group message
        if (
          chatAdhocGroupEnabled &&
          this.state.newMessagePagingGroup &&
          this.state.newMessagePagingGroup.length === 0 &&
          this.state.newMessageUsers &&
          this.state.newMessageUsers.length > 1
        ) {
          this.setState({
            showSendMessageButton: true,
            isPagingGroupMessage: false,
            isAdhocGroupMessage: true,
          });
        }
        // Shows new chat button for OneOnOne chat or Custom Group Chat as multiple paging groups selected and 1 or more users selected
        else if (
          chatOneToOneEnabled &&
          this.state.newMessagePagingGroup &&
          this.state.newMessagePagingGroup.length === 0 &&
          this.state.newMessageUsers &&
          this.state.newMessageUsers.length === 1
        ) {
          this.setState({
            isAdhocGroupMessage: false,
            showSendMessageButton: true,
            isPagingGroupMessage: false,
          });
        }
      }
    );
  };

  /**
   * This function is used to handle when a user removes a search parameter
   * It will remove the value from the searchData object and remake an API call
   * to get the updated results
   */
  handleSearchParameterRemoval = (value, filter) => {
    let tempSearch, index;
    // Identify which filter option the removed value belongs to
    switch (filter) {
      case "keyword":
        tempSearch = [...this.state.searchData.keyword];
        index = tempSearch.indexOf(value);
        tempSearch.splice(index, 1);
        this.setState(
          (prevState) => ({
            ...prevState,
            searchData: {
              ...prevState.searchData,
              keyword: tempSearch,
            },
          }),
          () => {
            this.searchGroupsAndUsers();
          }
        );

        break;
      case "agency":
        this.handleAgencyFilterChange("", true);
        break;
      // case "pagingNumber":
      //   tempSearch = [...this.state.searchData.pagingNumber];
      //   index = tempSearch.indexOf(value);
      //   tempSearch.splice(index, 1);
      //   this.setState(
      //     (prevState) => ({
      //       ...prevState,
      //       searchData: {
      //         ...prevState.searchData,
      //         pagingNumber: tempSearch,
      //       },
      //     }),
      //     () => {
      //       this.searchGroupsAndUsers();
      //     }
      //   );
      //   break;
      case "pagingNumberGroupSearch":
        tempSearch = [...this.state.newMessagePagingGroupSearchData];
        index = tempSearch.indexOf(value);
        tempSearch.splice(index, 1);
        this.setState(
          (prevState) => ({
            ...prevState,
            newMessagePagingGroupSearchData: tempSearch,
            // loadUsersWithoutRefreshing:
            //   this.state.newMessagePagingGroupSearchData.length === 0
            //     ? false
            //     : true,
            memberResultsLoading: true,
            membersResults: [],
            pagingGroupResults: [],
          }),
          () => {
            if (
              this.state.searchData.keyword &&
              this.state.searchData.keyword.length === 0
            ) {
              this.state.searchData.keyword.forEach((keyword) => {
                let body = {
                  take: 30,
                  // keyword should be null if includePagingNumberDecendants is true or the user has selected a group so check if includePagingNumberDecendants has some groups
                  keywords: keyword,
                  agency: this.state.searchData
                    ? this.state.searchData.agency.length === 0
                      ? null
                      : this.state.searchData.agency
                    : null,
                  pagingNumber: this.state.newMessagePagingGroupSearchData
                    ? this.state.newMessagePagingGroupSearchData.length === 0
                      ? null
                      : this.state.newMessagePagingGroupSearchData
                    : null,
                  qualificationId: this.state.searchData
                    ? this.state.searchData.qualificationId.length === 0
                      ? null
                      : this.state.searchData.qualificationId
                    : null,
                  // if "show all associated users" is checked, includes "includePagingNumberDecendants" in the API request to show all users associated to paging group or agency selected
                  includePagingNumberDecendants: this.state
                    .includePagingNumberDecendants
                    ? true
                    : false,
                };
                this.searchGroupsAndUsers(body);
              });
            }
            this.searchGroupsAndUsers();
          }
        );
        break;
      case "qualificationId":
        tempSearch = [...this.state.searchData.qualificationId];
        index = tempSearch.indexOf(value);
        tempSearch.splice(index, 1);
        this.setState(
          (prevState) => ({
            ...prevState,
            searchData: {
              ...prevState.searchData,
              qualificationId: tempSearch,
            },
            membersResults: [],
            pagingGroupResults: [],
          }),
          () => {
            if (
              this.state.searchData.keyword &&
              this.state.searchData.keyword.length === 0
            ) {
              this.state.searchData.keyword.forEach((keyword) => {
                let body = {
                  take: 30,
                  // keyword should be null if includePagingNumberDecendants is true or the user has selected a group so check if includePagingNumberDecendants has some groups
                  keywords: keyword,
                  agency: this.state.searchData
                    ? this.state.searchData.agency.length === 0
                      ? null
                      : this.state.searchData.agency
                    : null,
                  pagingNumber: this.state.newMessagePagingGroupSearchData
                    ? this.state.newMessagePagingGroupSearchData.length === 0
                      ? null
                      : this.state.newMessagePagingGroupSearchData
                    : null,
                  qualificationId: this.state.searchData
                    ? this.state.searchData.qualificationId.length === 0
                      ? null
                      : this.state.searchData.qualificationId
                    : null,
                  // if "show all associated users" is checked, includes "includePagingNumberDecendants" in the API request to show all users associated to paging group or agency selected
                  includePagingNumberDecendants: this.state
                    .includePagingNumberDecendants
                    ? true
                    : false,
                };
                this.searchGroupsAndUsers(body);
              });
            }
            this.searchGroupsAndUsers();
          }
        );
        break;
      default:
        break;
    }
  };

  handleNewMessageParameterRemoval = (value, filter) => {
    let tempSearch, index;
    // Identify which filter option the removed value belongs to
    switch (filter) {
      case "pagingNumber":
        tempSearch = [...this.state.newMessagePagingGroup];
        index = tempSearch.indexOf(value);
        tempSearch.splice(index, 1);
        this.setState(
          (prevState) => ({
            ...prevState,
            // newMessagePagingGroupSearchData: tempSearch,
            newMessagePagingGroup: tempSearch,
            // memberResultsLoading: true,
          }),
          () => {
            if (
              !this.state.newMessagePagingGroup.length &&
              !this.state.newMessageUsers.length
            ) {
              this.setState({
                newMessageThread: false,
              });
            }
          }
        );
        break;
      case "user":
        tempSearch = [...this.state.newMessageUsers];
        index = tempSearch.indexOf(value);
        tempSearch.splice(index, 1);
        this.setState(
          (prevState) => ({
            ...prevState,
            newMessageUsers: tempSearch,
          }),
          () => {
            if (
              !this.state.newMessagePagingGroup.length &&
              !this.state.newMessageUsers.length
            ) {
              this.setState({
                newMessageThread: false,
              });
            }
          }
        );
        break;
      default:
        break;
    }
  };

  //Runs when the 'Search' button is clicked
  //Will store all the search criteria the user has entered
  handleGroupsAndUsersSearch = () => {
    let keyword = null;

    if (
      !this.state.searchData.keyword ||
      this.state.searchData.keyword.length === 0
    ) {
      keyword =
        document.getElementById("userAndPagingGroupSearch") &&
        document.getElementById("userAndPagingGroupSearch").value;
    }

    this.prepareStateForSearch(keyword);
  };

  /**
   * Handles when the Add button is clicked on the results page
   */
  handleAddToSearch = () => {
    let keyword =
      document.getElementById("addToSearchInput") &&
      document.getElementById("addToSearchInput").value;

    this.prepareStateForSearch(keyword);
  };

  handleAddSearchParameter = () => {
    let keyword =
      document.getElementById("userAndPagingGroupSearch") &&
      document.getElementById("userAndPagingGroupSearch").value;

    let keywordList = this.state.searchData.keyword;
    keywordList.push(keyword);
    this.setState((prevState) => ({
      ...prevState,
      searchData: {
        ...prevState.searchData,
        keyword: keywordList,
      },
    }));
  };

  handleAddSearchParameterRemoval = (item) => {
    let tempSearch = [...this.state.searchData.keyword];
    let index = tempSearch.indexOf(item);
    tempSearch.splice(index, 1);
    this.setState((prevState) => ({
      ...prevState,
      searchData: {
        ...prevState.searchData,
        keyword: tempSearch,
      },
    }));
  };

  prepareStateForSearch = (keyword) => {
    let keywordList = [];
    if (this.state.searchData.keyword !== null) {
      keywordList = this.state.searchData.keyword;
    }
    if (keyword !== null && keyword !== "") {
      keywordList.push(keyword);
    }
    // Updates state with what the user has filtered/searched on
    if (this.state.searchData.agency.length > 0) {
      this.setState(
        (prevState) => ({
          ...prevState,
          searchData: {
            ...prevState.searchData,
            keyword: keywordList,
          },
          activePagingGroup: null,
          activeMember: null,
          showRefineSearchButton: true,
          showSearchParameters: true,
          noSearchDataAgencySelected: false,
        }),
        () => {
          this.searchGroupsAndUsers();
        }
      );
    } else {
      this.setState({
        noSearchDataAgencySelected: true,
      });
    }
  };

  //This filters the data and produces the paging group and members results by calling the relevant API
  searchGroupsAndUsers = (bodyData) => {
    // If user is trying to search with no data, then show nothing
    if (
      this.state.searchData.agency &&
      this.state.searchData.agency.length === 0 &&
      this.state.newMessagePagingGroupSearchData &&
      this.state.newMessagePagingGroupSearchData.length === 0 &&
      this.state.searchData.qualificationId &&
      this.state.searchData.qualificationId.length === 0 &&
      this.state.searchData.keyword &&
      this.state.searchData.keyword.length === 0
    ) {
      this.setState({
        pagingGroupResults: null,
        membersResults: null,
        showSearchParameters: false,
      });
    } else {
      // Setting up body of the API call
      let body;
      if (bodyData) {
        body = bodyData;
      } else {
        body = {
          take: 30,
          // keyword should be null if includePagingNumberDecendants is true or the user has selected a group so check if includePagingNumberDecendants has some groups
          keywords: this.state.searchData
            ? (this.state.searchData.keyword &&
                this.state.searchData.keyword.length === 0) ||
              this.state.newMessagePagingGroupSearchData.length !== 0
              ? null
              : this.state.searchData.keyword
            : null,
          agency: this.state.searchData
            ? this.state.searchData.agency.length === 0
              ? null
              : this.state.searchData.agency
            : null,
          pagingNumber: this.state.newMessagePagingGroupSearchData
            ? this.state.newMessagePagingGroupSearchData.length === 0
              ? null
              : this.state.newMessagePagingGroupSearchData
            : null,
          qualificationId: this.state.searchData
            ? this.state.searchData.qualificationId.length === 0
              ? null
              : this.state.searchData.qualificationId
            : null,
          // if "show all associated users" is checked, includes "includePagingNumberDecendants" in the API request to show all users associated to paging group or agency selected
          includePagingNumberDecendants: this.state
            .includePagingNumberDecendants
            ? true
            : false,
        };
      }

      this.setState(
        {
          // If bodyData or loadUsersWithoutRefreshing, loading state not needed
          // Loading state only needed on initial data load
          searchResultsLoading:
            bodyData || this.state.loadUsersWithoutRefreshing ? false : true,
          // Hides user and group search section and shows user and group search results instead
          showUserAndGroupSearch: false,
        },
        () => {
          // Conditional to only search for users. Triggered by "triggerUserPagination" function
          if (this.state.isLoadingUserData) {
            this.Requests.callAPI(this.Requests.filterUsers, bodyData).then(
              (data) => {
                if (data && data.status && data.status === 200) {
                  let membersResults = null; // Should be equal to null if membersResults is null
                  if (this.state.membersResults) {
                    // Makes a copy of membersResults state object, then concats new data into the array
                    membersResults = this.state.membersResults.concat(
                      data.data
                    );
                  }
                  // If less than 30 users returned, sets hasMoreUsersToLoad to false and blocks pagination from firing again
                  if (data.data.length < 30) {
                    this.setState({
                      hasMoreUsersToLoad: false,
                    });
                  }
                  // Fallback in case pagination triggers this to false before all data is finished loading
                  if (data.data.length === 30) {
                    this.setState({
                      hasMoreUsersToLoad: true,
                    });
                  }
                  this.setState(
                    {
                      // Replaces memberResults state array with the newly constructed membersResults array
                      membersResults: membersResults,
                      searchResultsLoading: false,
                      searchParameters: body,
                      memberResultsLoading: false,
                      isLoadingUserData: false,
                    },
                    () => {
                      this.calculateUserSearchHeaderHeight();
                    }
                  );
                } else {
                  // Refine search function resets user and group search data and search related states back to its initial state.
                  this.refineSearch();
                  let ErrorMessage =
                    copy.sasChat.prepareUserFilterResultsAPIErrorMessage +
                    ` (Error #${copy.errorCodes.filterChangeAPIErrorMessage})`;
                  // if (data && data.data && data.data.SASMessageClient) {
                  //   ErrorMessage = data.data.SASMessageClient;
                  // }
                  this.setState(
                    {
                      errorMessage: ErrorMessage,
                      searchResultsLoading: false,
                      isLoadingUserData: false,
                    },
                    () => {
                      setTimeout(
                        function () {
                          this.setState({ errorMessage: null });
                        }.bind(this),
                        5000
                      );
                    }
                  );
                }
              }
            );
          }
          // Conditional to only search for paging groups. Triggered by "triggerGroupPagination" function
          else if (this.state.isLoadingPagingData) {
            this.Requests.callAPI(
              this.Requests.filterPagingGroups,
              bodyData
            ).then((data) => {
              if (data && data.status && data.status === 200) {
                let pagingGroupResults = null;
                if (this.state.pagingGroupResults) {
                  pagingGroupResults = this.state.pagingGroupResults.concat(
                    data.data
                  );
                }
                // show "Show All Inherited Users" checkbox if search parameter contains at least one paging group
                if (this.state.newMessagePagingGroupSearchData.length > 0) {
                  this.setState({
                    showShowAllUserToggleButton: true,
                  });
                } else {
                  this.setState({
                    showShowAllUserToggleButton: false,
                  });
                }
                // If less than 30 paging groups returned, sets hasMorePagingGroupsToLoad to false and blocks pagination from firing again
                if (data.data.length < 30) {
                  this.setState({
                    hasMorePagingGroupsToLoad: false,
                  });
                }
                // Fallback in case pagination triggers this to false before all data is finished loading
                if (data.data.length === 30) {
                  this.setState({
                    hasMorePagingGroupsToLoad: true,
                  });
                }
                // Add
                this.setState(
                  {
                    searchResultsLoading: false,
                    // Replaces pagingGroupResults state array with the newly constructed pagingGroupResults array
                    pagingGroupResults: pagingGroupResults,
                    searchParameters: body,
                    memberResultsLoading: false,
                    isLoadingPagingData: false,
                  },
                  () => {
                    this.calculateUserSearchHeaderHeight();
                  }
                );
              } else {
                // Refine search function resets user and group search data and search related states back to its initial state.
                this.refineSearch();
                let ErrorMessage =
                  copy.sasChat.preparePagingGroupFilterResultsAPIErrorMessage +
                  ` (Error #${copy.errorCodes.filterPagingGroupInfoAPIErrorMessage})`;
                // if (data && data.data && data.data.SASMessageClient) {
                //   ErrorMessage = data.data.SASMessageClient;
                // }
                this.setState(
                  {
                    errorMessage: ErrorMessage,
                    searchResultsLoading: false,
                    isLoadingPagingData: false,
                  },
                  () => {
                    setTimeout(
                      function () {
                        this.setState({ errorMessage: null });
                      }.bind(this),
                      5000
                    );
                  }
                );
              }
            });
          } else {
            // If no bodydata passed in as argument, function is not being used for pagination for user / paging groups
            // Both paging group and user API calls are made using the initial body constructed above and both Paging Group and Member Results arrays are initiated.

            // Paging group search API
            this.Requests.callAPI(this.Requests.filterPagingGroups, body).then(
              (data) => {
                if (data && data.status && data.status === 200) {
                  let pagingGroupResults;
                  if (data.data) {
                    pagingGroupResults = data.data;
                  }
                  // show "Show All Inherited Users" checkbox if search parameter contains at least one paging group
                  if (this.state.newMessagePagingGroupSearchData.length > 0) {
                    this.setState({
                      showShowAllUserToggleButton: true,
                    });
                  } else {
                    this.setState({
                      showShowAllUserToggleButton: false,
                    });
                  }
                  // If less than 30 paging groups returned, sets hasMorePagingGroupsToLoad to false and blocks pagination from firing again
                  if (data.data.length < 30) {
                    this.setState({
                      hasMorePagingGroupsToLoad: false,
                    });
                  }
                  // Fallback in case pagination triggers this to false before all data is finished loading
                  if (data.data.length === 30) {
                    this.setState({
                      hasMorePagingGroupsToLoad: true,
                    });
                  }
                  this.setState(
                    {
                      searchResultsLoading: false,
                      // Replaces pagingGroupResults state array with the newly constructed pagingGroupResults array
                      pagingGroupResults: pagingGroupResults,
                      searchParameters: body,
                    },
                    () => {
                      this.calculateUserSearchHeaderHeight();
                    }
                  );
                } else {
                  // Refine search function resets user and group search data and search related states back to its initial state.
                  this.refineSearch();
                  let ErrorMessage =
                    copy.sasChat
                      .preparePagingGroupFilterResultsAPIErrorMessage +
                    ` (Error #${copy.errorCodes.filterPagingGroupInfoAPIErrorMessage})`;
                  // if (data && data.data && data.data.SASMessageClient) {
                  //   ErrorMessage = data.data.SASMessageClient;
                  // }
                  this.setState(
                    {
                      errorMessage: ErrorMessage,
                      searchResultsLoading: false,
                      memberResultsLoading: false,
                    },
                    () => {
                      setTimeout(
                        function () {
                          this.setState({ errorMessage: null });
                        }.bind(this),
                        5000
                      );
                    }
                  );
                }
              }
            );

            // Member results search API
            this.Requests.callAPI(this.Requests.filterUsers, body).then(
              (data) => {
                if (data && data.status && data.status === 200) {
                  let membersResults;
                  if (data.data) {
                    membersResults = data.data;
                  }
                  // If less than 30 users returned, sets hasMoreUsersToLoad to false and blocks pagination from firing again
                  if (data.data.length < 30) {
                    this.setState({
                      hasMoreUsersToLoad: false,
                    });
                  }
                  // Fallback in case pagination triggers this to false before all data is finished loading
                  if (data.data.length === 30) {
                    this.setState({
                      hasMoreUsersToLoad: true,
                    });
                  }
                  this.setState(
                    {
                      // Replaces membersResults state array with the newly constructed membersResults array
                      membersResults: membersResults,
                      searchResultsLoading: false,
                      searchParameters: body,
                      memberResultsLoading: false,
                    },
                    () => {
                      this.calculateUserSearchHeaderHeight();
                    }
                  );
                } else {
                  // Refine search function resets user and group search data and search related states back to its initial state.
                  this.refineSearch();
                  let ErrorMessage =
                    copy.sasChat.prepareUserFilterResultsAPIErrorMessage +
                    ` (Error #${copy.errorCodes.filterChangeAPIErrorMessage})`;
                  // if (data && data.data && data.data.SASMessageClient) {
                  //   ErrorMessage = data.data.SASMessageClient;
                  // }
                  this.setState(
                    {
                      errorMessage: ErrorMessage,
                      searchResultsLoading: false,
                      memberResultsLoading: false,
                    },
                    () => {
                      setTimeout(
                        function () {
                          this.setState({
                            errorMessage: null,
                          });
                        }.bind(this),
                        5000
                      );
                    }
                  );
                }
              }
            );
          }
        }
      );
    }
  };

  /**
   * This function is used to handle when the agency filter values change
   * It is needed because changes in agency will affect the paging numbers being displayed
   * Specifically for the filter when creating a new chat
   *
   * NOTE:
   * this.state.agency = the previous filter values
   * value = the new filter values
   *
   */
  handleAgencyFilterChange = (value, refresh = false) => {
    // Since agency does not support multi-select, put everything into an array for consistency
    if (value === "") {
      value = [];
    } else {
      value = [value];
    }
    let tempPagingNumbers = this.state.newMessagePagingGroupSearchData;
    let tempQualifications = this.state.searchData.qualificationId;

    // If paging groups or qualifications are currently being filtered
    if (
      this.state.newMessagePagingGroupSearchData.length !== 0 ||
      this.state.searchData.qualificationId.length !== 0
    ) {
      // If the agency changes
      if (
        value.length === 1 &&
        this.state.searchData.agency.length === 1 &&
        value !== this.state.agency
      ) {
        // Get the removed agency
        let removedAgency = this.state.searchData.agency[0];

        // Changing paging group filter if there are currently selections
        if (
          this.state.newMessagePagingGroupSearchData.length !== 0 &&
          this.state.userPagingGroupsOption[removedAgency]
        ) {
          // Remove any paging groups which belong to that agency from the results
          this.state.userPagingGroupsOption[removedAgency].forEach(
            (pagingGroup) => {
              let index = tempPagingNumbers.indexOf(pagingGroup.value);
              if (index !== -1) {
                tempPagingNumbers.splice(index, 1);
              }
            }
          );
        }

        // Change qualifications filter if there are currently selections
        if (this.state.searchData.qualificationId.length !== 0) {
          // Remove any qualifications which belong to that agency from the results
          this.state.userQualificationsOption[removedAgency].forEach(
            (qualification) => {
              let index = tempQualifications.indexOf(qualification.value);
              if (index !== -1) {
                tempQualifications.splice(index, 1);
              }
            }
          );
        }
        // If an agency was added when previously none were being filtered
      } else if (
        this.state.searchData.agency.length === 0 &&
        value.length === 1
      ) {
        let addedAgency = value[0];

        // Changing paging group filter if there are currently selections
        if (
          this.state.newMessagePagingGroupSearchData.length !== 0 &&
          this.state.userPagingGroupsOption[addedAgency]
        ) {
          tempPagingNumbers = [];
          // Any paging groups which are not from that agency should be removed
          this.state.userPagingGroupsOption[addedAgency].forEach(
            (pagingGroup) => {
              let index = this.state.newMessagePagingGroupSearchData.indexOf(
                pagingGroup.value
              );
              if (index !== -1) {
                tempPagingNumbers.push(pagingGroup.value);
              }
            }
          );
        }

        // Change qualifications filter if there are currently selections
        if (this.state.searchData.qualificationId.length !== 0) {
          tempQualifications = [];
          // Any qualifications which are not from that agency should be removed
          this.state.userQualificationsOption[addedAgency].forEach(
            (qualification) => {
              let index = this.state.searchData.qualificationId.indexOf(
                qualification.value
              );
              if (index !== -1) {
                tempQualifications.push(qualification.value);
              }
            }
          );
        }
      }
    }

    // Update the search results with the new agency filter applied
    this.setState(
      (prevState) => ({
        ...prevState,
        searchData: {
          ...prevState.searchData,
          agency: value,
          // pagingNumber: tempPagingNumbers.length === 0 ? [] : tempPagingNumbers,
          qualification:
            tempQualifications.length === 0 ? [] : tempQualifications,
        },
        newMessagePagingGroupSearchData:
          tempPagingNumbers.length === 0 ? [] : tempPagingNumbers,
        noSearchDataAgencySelected: false,
      }),
      () => {
        if (refresh) {
          this.searchGroupsAndUsers();
        }
      }
    );
  };

  /**
   * This function handles what happens when the agency filter for all the chats
   */
  handleChatAgencyFilterChange = (value) => {
    let tempPagingNumbers = this.state.chatFilter.pagingNumber;
    // If paging groups are currently being filtered
    if (this.state.chatFilter.pagingNumber.length !== 0) {
      // If an agency was removed
      if (
        value.length !== 0 &&
        this.state.chatFilter.agency.length !== 0 &&
        this.state.chatFilter.agency.length === value.length + 1
      ) {
        // // Get the removed agency
        let removedAgency = null;

        this.state.chatFilter.agency.forEach((agency) => {
          if (value.indexOf(agency) === -1) {
            removedAgency = agency;
          }
        });

        // Remove paging groups which belong to the removed agency from the search
        if (this.state.userPagingGroupsOption[removedAgency]) {
          this.state.userPagingGroupsOption[removedAgency].forEach(
            (pagingGroup) => {
              let index = tempPagingNumbers.indexOf(pagingGroup.value);
              if (index !== -1) {
                tempPagingNumbers.splice(index, 1);
              }
            }
          );
        }

        // If an agency was added when previously none were being filtered
      } else if (
        this.state.chatFilter.agency.length === 0 &&
        value.length === 1
      ) {
        let addedAgency = value[0];
        tempPagingNumbers = [];
        // Remove all paging groups from the filter that are not part of that agency
        if (this.state.userPagingGroupsOption[addedAgency]) {
          this.state.userPagingGroupsOption[addedAgency].forEach(
            (pagingGroup) => {
              let index = this.state.chatFilter.pagingNumber.indexOf(
                pagingGroup.value
              );
              if (index !== -1) {
                tempPagingNumbers.push(pagingGroup.value);
              }
            }
          );
        }
      }
    }

    // Update the search results with the new agency filter applied
    this.setState((prevState) => ({
      ...prevState,
      chatFilter: {
        ...prevState.chatFilter,
        agency: value,
        // pagingNumber:
        //   tempPagingNumbers === null || tempPagingNumbers.length === 0
        //     ? []
        //     : tempPagingNumbers,
      },
      newMessagePagingGroupSearchData:
        tempPagingNumbers === null || tempPagingNumbers.length === 0
          ? []
          : tempPagingNumbers,
    }));
  };

  //=======================================================================
  //=============== RENDER SECTION OF USER AND GROUP SEARCH================
  //=======================================================================

  /**
   * This renders the agency filter
   */
  renderAgencies() {
    if (
      this.state.userAgenciesOption.length === 1 &&
      this.state.searchData.agency[0] !== this.state.userAgenciesOption[0].value
    ) {
      this.setState((prevState) => ({
        ...prevState,
        searchData: {
          ...prevState.searchData,
          agency: [this.state.userAgenciesOption[0].value],
        },
      }));
    }

    return (
      <div
        className={
          this.state.multiAgency === true
            ? "hide " + styles.dropdownHolder
            : "show " + styles.dropdownHolder + " chatUserAndAgencySearch"
        }
      >
        <span className={styles.dropdownLabel}>Agencies</span>
        {this.state.userAgenciesOption.length > 1 ? (
          <div className={styles.searchDropdownWrapper}>
            {this.state.multiAgency === true && (
              <span
                aria-label="Multiple agencies selected"
                className="multi"
                onClick={this.changeBack}
              >
                <span>{copy.searchAndFilter.multipleSelected}</span>
                <i aria-hidden="true" className="dropdown icon"></i>
              </span>
            )}
            <Dropdown
              key="AgencySelectDropdown"
              id="AgencySelect"
              placeholder={
                this.state.noSearchDataAgencySelected ? "Agency required" : null
              }
              fluid
              // multiple
              clearable
              search
              selection
              className={
                this.state.noSearchDataAgencySelected ? styles.error : null
              }
              value={
                this.state.searchData.agency.length === 0
                  ? ""
                  : this.state.searchData.agency[0]
              }
              options={this.state.userAgenciesOption}
              onChange={(e, { value }) => this.handleAgencyFilterChange(value)}
            />
          </div>
        ) : this.state.userAgenciesOption.length === 1 ? (
          <span>{this.state.userAgenciesOption[0].text}</span>
        ) : (
          <span>{this.state.userAgenciesOption[0].text}</span>
        )}
      </div>
    );
  }

  /**
   * This function renders the paging groups for the filter
   */
  renderPagingGroups() {
    let pagingGroupOption = [];
    // If no agencies has been selected, then all paging groups should be selectable
    if (this.state.searchData && this.state.searchData.agency.length === 0) {
      this.props.userAgencies &&
        this.props.userAgencies.forEach((agency) => {
          if (agency !== "AV" && this.state.userPagingGroupsOption[agency]) {
            pagingGroupOption = pagingGroupOption.concat(
              this.state.userPagingGroupsOption[agency]
            );
          }
        });
    } else {
      // If agencies have been selected, only the paging groups from the agencies should be selectable
      // Go through all agencies the user is part of and add the paging groups to the search data
      this.state.searchData &&
        this.state.searchData.agency.forEach((agency) => {
          if (agency !== "AV" && this.state.userPagingGroupsOption[agency]) {
            pagingGroupOption = pagingGroupOption.concat(
              this.state.userPagingGroupsOption[agency]
            );
          }
        });
    }

    // sort the paging groups in ascending order by name
    pagingGroupOption.sort((a, b) => {
      return a["text"].localeCompare(b["text"]);
    });

    if (
      pagingGroupOption &&
      pagingGroupOption.length === 1 &&
      this.state.newMessagePagingGroupSearchData[0] &&
      this.state.newMessagePagingGroupSearchData[0] !==
        pagingGroupOption[0].value
    ) {
      this.setState((prevState) => ({
        ...prevState,
        // searchData: {
        //   ...prevState.searchData,
        //   pagingNumber: [pagingGroupOption[0].value],
        // },
        newMessagePagingGroupSearchData: [pagingGroupOption[0].value],
      }));
    }

    return (
      <div
        className={
          this.state.multiPGroups === true
            ? "hide " + styles.dropdownHolder
            : "show " + styles.dropdownHolder
        }
      >
        <span className={styles.dropdownLabel}>Groups</span>
        {pagingGroupOption.length > 1 ? (
          <div className={styles.searchDropdownWrapper}>
            {this.state.multiPGroups === true && (
              <span
                aria-label="Multiple groups selected"
                className={styles.multi}
                onClick={this.changeBack}
              >
                <span>{copy.searchAndFilter.multipleSelected}</span>
                <i aria-hidden="true" className="dropdown icon"></i>
              </span>
            )}
            {/* {this.props.userRoles[0] === "SuperUser" ||
            this.props.userRoles[0] === "Administrator" ||
            this.props.userRoles[0] === "Manager" ? ( */}
            <Dropdown
              key="PagingNumberDropdown"
              placeholder=""
              fluid
              multiple
              search
              selection
              value={this.state.newMessagePagingGroupSearchData}
              options={pagingGroupOption}
              onChange={(e, { value }) => {
                this.setState(
                  (prevState) => ({
                    ...prevState,
                    // searchData: {
                    //   ...prevState.searchData,
                    //   pagingNumber: value,
                    // },
                    newMessagePagingGroupSearchData: value,
                  }),
                  () => {
                    let groupList = this.state.pagingGroupsReferenceList;
                    let newGroupName = pagingGroupOption.find(
                      (el) => el.value === value[0]
                    );
                    if (newGroupName !== undefined) {
                      groupList.push(newGroupName);
                    }
                    if (
                      this.state.newMessagePagingGroupSearchData.length === 1
                    ) {
                      this.setState({
                        newMessagePagingGroup:
                          this.state.newMessagePagingGroupSearchData,
                        showSendMessageButton: true,
                        isPagingGroupMessage: true,
                        pagingGroupsReferenceList: groupList,
                      });
                    } else {
                      this.setState({
                        newMessagePagingGroup: [],
                        showSendMessageButton: false,
                        isPagingGroupMessage: false,
                        pagingGroupsReferenceList: groupList,
                      });
                    }
                  }
                );
              }}
            />
            {/* ) : (
              <Dropdown
                placeholder=""
                fluid
                multiple
                search
                selection
                value={this.state.searchData.pagingNumber}
                options={pagingGroupOption}
                onChange={(e, { value }) => {
                  this.setState((prevState) => ({
                    ...prevState,
                    searchData: {
                      ...prevState.searchData,
                      pagingNumber: value,
                    },
                  }));
                }}
              />
            )} */}
          </div>
        ) : pagingGroupOption && pagingGroupOption.length === 1 ? (
          <span>{pagingGroupOption[0] && pagingGroupOption[0].text}</span>
        ) : (
          <span>{copy.searchAndFilter.noGroups}</span>
        )}
      </div>
    );
  }

  /**
   * This function prepares qualifications filtre
   */
  renderQualification() {
    let qualificationsOption = [];
    // If no agencies has been selected, then all qualifications should be selectable
    if (
      this.state.searchData &&
      this.state.searchData.agency &&
      this.state.searchData.agency.length === 0
    ) {
      this.props.userAgencies &&
        this.props.userAgencies.forEach((agency) => {
          if (
            this.state.userQualificationsOption &&
            this.state.userQualificationsOption[agency]
          ) {
            qualificationsOption = qualificationsOption.concat(
              this.state.userQualificationsOption[agency]
            );
          }
        });
    } else {
      // If agencies have been selected, only the qualifications from the agencies should be selectable
      // Go through all agencies the user is part of and add the qualifications to the search data
      this.state.searchData &&
        this.state.searchData.agency.forEach((agency) => {
          if (
            this.state.userQualificationsOption &&
            this.state.userQualificationsOption[agency]
          ) {
            qualificationsOption = qualificationsOption.concat(
              this.state.userQualificationsOption[agency]
            );
          }
        });
    }

    if (
      qualificationsOption.length === 1 &&
      this.state.searchData.qualificationId[0] &&
      this.state.searchData.qualificationId[0] !== qualificationsOption[0].value
    ) {
      this.setState((prevState) => ({
        ...prevState,
        searchData: {
          ...prevState.searchData,
          qualificationId: [qualificationsOption[0].value],
        },
      }));
    }

    return (
      <div
        className={
          this.state.multiQual === true
            ? "hide " + styles.dropdownHolder
            : "show " + styles.dropdownHolder
        }
      >
        <span className={styles.dropdownLabel}>Qualification</span>

        {qualificationsOption.length > 1 ? (
          <div className={styles.searchDropdownWrapper}>
            {this.state.multiQual === true && (
              <span
                aria-label="Multiple selected"
                className={styles.multi}
                onClick={this.changeBack}
              >
                <span>{copy.searchAndFilter.multipleSelected}</span>
                <i aria-hidden="true" className="dropdown icon"></i>
              </span>
            )}

            <Dropdown
              key="QualificationSelectDropdown"
              placeholder=""
              fluid
              multiple
              search
              selection
              value={this.state.searchData.qualificationId}
              options={qualificationsOption}
              onChange={(e, { value }) => {
                this.setState((prevState) => ({
                  ...prevState,
                  searchData: {
                    ...prevState.searchData,
                    qualificationId: value,
                  },
                }));
              }}
            />
          </div>
        ) : qualificationsOption.length === 1 ? (
          <span>{qualificationsOption[0] && qualificationsOption[0].text}</span>
        ) : (
          <span>{copy.searchAndFilter.noQuals}</span>
        )}
      </div>
    );
  }

  renderAvailability() {
    const availabilityOption = [
      { key: 1, text: "TODAY", value: "TODAY" },
      { key: 2, text: "TOMORROW", value: "TOMORROW" },
      { key: 3, text: "DATE RANGE", value: "RANGE" },
    ];

    return (
      <div
        className={
          this.state.multiAvail === true
            ? "hide " + styles.dropdownHolder
            : "show " + styles.dropdownHolder
        }
      >
        <span className={styles.dropdownLabel}>Availability</span>

        {this.state.multiAvail === true && (
          <span
            aria-label="Multiple selected"
            className={styles.multi}
            onClick={this.changeBack}
          >
            <span>{copy.searchAndFilter.multipleSelected}</span>
            <i aria-hidden="true" className="dropdown icon"></i>
          </span>
        )}

        <Dropdown
          key="AvailabilitySelectDropdown"
          placeholder=""
          fluid
          multiple
          search
          selection
          options={availabilityOption}
          onChange
        />
      </div>
    );
  }

  renderGroupsAndUsersSearchFiltersSection() {
    return (
      <Wrapper>
        <ErrorBanner
          isVisible={this.state.errorMessage ? true : false}
          ErrorMessage={this.state.errorMessage}
        />
        <div className="box_default">
          <div className="Sub-Section-titles">
            <h3>{copy.searchAndFilter.searchPersonOrGroup}</h3>
          </div>
          <div className={styles.searchbar}>
            <Wrapper>
              <input
                id="userAndPagingGroupSearch"
                placeholder="Search for a person or group..."
                defaultValue={
                  this.state.searchData.keyword[
                    this.state.searchData.keyword.length - 1
                  ]
                }
                className={styles.searchbox + " highContrast"}
              ></input>
            </Wrapper>
            <Wrapper>
              <Button
                label="Add To Search"
                content="Add"
                variant="btn_secondary"
                styles=""
                icon="icon_add"
                buttonClick={this.handleAddSearchParameter}
              />
            </Wrapper>
          </div>
          {this.state.searchData.keyword.length > 0 && (
            <div className={styles.userSearchHeader} style={{ padding: "0" }}>
              <div
                className={styles.searchParameters}
                style={{ marginLeft: "-4px" }}
              >
                {this.state.searchData.keyword && (
                  <Wrapper>
                    {this.state.searchData.keyword.map((item, index) => (
                      <div className={styles.searchParameterItem + " tileBg"}>
                        <Close
                          onClick={() => {
                            this.handleAddSearchParameterRemoval(item);
                          }}
                        />
                        {this.state.searchData.keyword[index]}
                      </div>
                    ))}
                  </Wrapper>
                )}
              </div>
            </div>
          )}
          <p className={styles.label}>{copy.searchAndFilter.filterResults}</p>
          {this.renderAgencies()}
          {this.renderPagingGroups()}
          {this.renderQualification()}
          <Button
            label="Search"
            content="Search"
            variant="btn_outline"
            styles="btn_primary"
            icon="icon_search"
            buttonClick={this.handleGroupsAndUsersSearch}
          />
        </div>
      </Wrapper>
    );
  }

  renderGroupsAndUsersResultsSection() {
    const agencyChatSettings = `${this.state.searchData.agency[0]}ChatSettings`;
    let chatOneToOneEnabled =
      this.state[agencyChatSettings].chatOneToOneEnabled;
    let chatAdhocGroupEnabled =
      this.state[agencyChatSettings].chatAdhocGroupEnabled;

    return (
      <div className={styles.resultsWrapper + " box_default"}>
        {this.state.showSendMessageButton &&
        (this.state.newMessageUsers.length ||
          this.state.newMessagePagingGroup.length) &&
        !this.state.memberResultsLoading ? (
          <Button
            label="Start new chat"
            content={
              this.state.isPagingGroupMessage
                ? "Message this Paging Group"
                : "Start new chat"
            }
            variant="btn_primary new_message"
            styles="redTxt"
            buttonClick={this.createNewMessageThread}
          />
        ) : null}

        <Wrapper>
          <p className={styles.label}>{copy.searchAndFilter.groupResults}</p>
          <div
            id="searchGroupResultsWrapper"
            className={
              styles.searchResults +
              " " +
              // (this.state.isMobile ? styles.mobile : "") +
              // " " +
              (this.state.showShowAllUserToggleButton
                ? styles.includesShowAllButton
                : "")
            }
          >
            {this.state.searchResultsLoading ? (
              <div className={styles.loadingContainer}>
                <Loader />
              </div>
            ) : (
              <Wrapper>
                {this.state.pagingGroupResults ? (
                  this.state.pagingGroupResults.length > 0 ? (
                    <Wrapper>
                      <InfiniteScroll
                        key="pagingGroupResults"
                        dataLength={this.state.pagingGroupResults.length}
                        next={this.triggerGroupPagination}
                        hasMore={this.state.hasMorePagingGroupsToLoad}
                        loader={
                          <div className={styles.paginationLoader}>
                            <Loader />
                          </div>
                        }
                        scrollableTarget="searchGroupResultsWrapper"
                        // endMessage={
                        //   <div className={styles.paginationLoader}>
                        //     <span>{copy.global.noGroups}</span>
                        //   </div>
                        // }
                        scrollThreshold="100%"
                        style={{ padding: "1px" }}
                      >
                        {this.state.pagingGroupResults.map((item, index) => {
                          return (
                            <li key={index}>
                              <Checkbox
                                key={index}
                                name={item.name}
                                label={item.name}
                                checked={
                                  this.state.newMessagePagingGroupSearchData.includes(
                                    item.number
                                  )
                                    ? true
                                    : false
                                }
                                onChange={() =>
                                  this.handlePagingGroupSelection(item.number)
                                }
                              />
                            </li>
                          );
                        })}
                      </InfiniteScroll>
                    </Wrapper>
                  ) : (
                    this.state.pagingGroupResults.length === 0 && (
                      <p>{copy.searchAndFilter.noResults}</p>
                    )
                  )
                ) : (
                  <div className={styles.loadingContainer}>
                    <Loader />
                  </div>
                )}
              </Wrapper>
            )}
          </div>
          <span className={styles.divider}></span>
          {this.state.showShowAllUserToggleButton && (
            <div className={styles.showAllUsersButton}>
              <Checkbox
                key="showChildrenUsers"
                name="showChildrenUsers"
                label={copy.global.showAllUsers}
                checked={this.state.includePagingNumberDecendants && true}
                onChange={this.showAllUsersForSelectedGroup}
              />
            </div>
          )}
          {this.state.newMessagePagingGroupSearchData.length === 0 ? (
            // Show user results label is no groups are selected by the user
            <p className={styles.label}>{copy.searchAndFilter.userResults}</p>
          ) : (
            // Show group details label if the user has selected some groups
            <p className={styles.label}>{copy.searchAndFilter.groupDetails}</p>
          )}

          <div
            id="searchUserResultsWrapper"
            className={
              styles.searchResults
              // " " +
              // (this.state.isMobile ? styles.mobile : "")
            }
          >
            {this.state.searchResultsLoading ? (
              // <div className={styles.loading_holder}>
              <div className={styles.loadingContainer}>
                <Loader />
              </div>
            ) : (
              // </div>
              <Wrapper>
                {this.state.memberResultsLoading ? (
                  // <div className={styles.loading_holder}>
                  <div className={styles.loadingContainer}>
                    <Loader />
                  </div>
                ) : (
                  // </div>
                  <Wrapper>
                    {this.state.membersResults &&
                    this.state.membersResults.length > 0 ? (
                      <Wrapper>
                        <InfiniteScroll
                          dataLength={this.state.membersResults.length}
                          next={this.triggerUserPagination}
                          hasMore={this.state.hasMoreUsersToLoad}
                          loader={
                            <div className={styles.paginationLoader}>
                              <Loader />
                            </div>
                          }
                          scrollableTarget="searchUserResultsWrapper"
                          // endMessage={
                          //   <div className={styles.paginationLoader}>
                          //     <span>{copy.global.noUsers}</span>
                          //   </div>
                          // }
                          scrollThreshold="100%"
                          style={{ padding: "1px" }}
                        >
                          {this.state.membersResults.map((item, index) => {
                            // Get all the qualifications for that user
                            // Individuals permissions check
                            let individualAdhocEnabled;
                            let individualOneToOneEnabled;
                            let individualLocation;
                            for (
                              let i = 0;
                              i < item.userAgencyInfo.length;
                              i++
                            ) {
                              let agency = item.userAgencyInfo[i];
                              if (
                                this.state.searchData.agency.indexOf(
                                  agency.agency
                                ) !== -1
                              ) {
                                individualAdhocEnabled = // Adhoc chat enabled attribute
                                  item.userAgencyInfo[i].chatAdhocGroupEnabled;
                                individualOneToOneEnabled = // One to one enabled attibute
                                  item.userAgencyInfo[i].chatOneToOneEnabled;
                                individualLocation =
                                  item.userAgencyInfo[i].locationAgencyHome; // Location attribute
                              }
                            }
                            // ADD LOCATION INSTEAD OF QUALIFICATIONS
                            // Check if the agency permits onetoone or adhoc chat
                            if (chatAdhocGroupEnabled || chatOneToOneEnabled) {
                              // Check if this user is allowed to be messaged
                              // If selected users is 0 and this person has one-on-one chat disabled, disable the checkbox
                              if (
                                this.state.newMessageUsers &&
                                this.state.newMessageUsers.length === 0 &&
                                !individualOneToOneEnabled
                              ) {
                                return (
                                  <li key={index}>
                                    <Checkbox
                                      className={styles.disabled}
                                      key={index}
                                      name={item.name}
                                      label={item.name}
                                      disabled={true}
                                      checked={false}
                                      // onChange={() =>
                                      //   this.handleUserSelection(
                                      //     item.userId,
                                      //     index
                                      //   )
                                      // }
                                      description={
                                        copy.searchAndFilter.noOneOnOneChat
                                      }
                                    />
                                  </li>
                                );
                              } else if (
                                // If selected users is atleast 1 and this person has adhoc chat disabled, disable the checkbox. As long as they aren't selected already
                                this.state.newMessageUsers &&
                                this.state.newMessageUsers.length > 0 &&
                                this.state.newMessageUsers.indexOf(item) ===
                                  -1 &&
                                !individualAdhocEnabled
                              ) {
                                return (
                                  <li key={index}>
                                    <Checkbox
                                      className={styles.disabled}
                                      key={index}
                                      name={item.name}
                                      label={item.name}
                                      disabled={true}
                                      checked={false}
                                      // onChange={() =>
                                      //   this.handleUserSelection(
                                      //     item.userId,
                                      //     index
                                      //   )
                                      // }
                                      description={
                                        copy.searchAndFilter.noAdhocChat
                                      }
                                    />
                                  </li>
                                );
                              } else {
                                // This user is allowed for one-on-one and adhoc, enable the checkbox
                                return (
                                  <li key={index}>
                                    <Checkbox
                                      className={styles.disabled}
                                      key={index}
                                      name={item.name}
                                      label={item.name}
                                      // disabled={true}
                                      checked={
                                        this.state.newMessageUsers &&
                                        this.state.newMessageUsers.find(
                                          (el) => el === item
                                        )
                                          ? true
                                          : false
                                      }
                                      onChange={() =>
                                        this.handleUserSelection(item, index)
                                      }
                                      description={individualLocation} // QUALIFICATIONS ARE NOT DISPLAYED. LOCATION IS.
                                    />
                                  </li>
                                );
                              }
                            } else {
                              // Put error message here that agency prevents chat
                              return null;
                            }
                          })}
                        </InfiniteScroll>
                      </Wrapper>
                    ) : (
                      <p>{copy.searchAndFilter.noResults}</p>
                    )}
                  </Wrapper>
                )}
              </Wrapper>
            )}
          </div>
        </Wrapper>
      </div>
    );
  }

  //=======================================================================
  //================ END GROUP AND USER SEARCH FUNCTIONS ================
  //=======================================================================

  //=======================================================================
  //================= START CHAT FILTER AND SORT FUNCTIONS ================
  //=======================================================================

  /**
   * Shows/hides the filter
   */
  toggleFilter = () => {
    this.setState((prevState) => ({
      showFilters: !prevState.showFilters,
    }));
  };

  /**
   * Updates state object with changes to the dates in the filter
   */
  handleDateInputChange = (e) => {
    let value = e.target.value;
    let name = e.target.name;

    this.setState((prevState) => ({
      ...prevState,
      chatFilter: {
        ...prevState.chatFilter,
        [name]: value,
        filterError: null,
      },
    }));
  };

  /**
   * This function handles what happens when the user clicks the filter button
   */
  handleChatFilterSubmit = (event) => {
    event.preventDefault();

    let filter = false;
    let status = null;

    // DATE INPUT VALIDATION
    // If there are no entries in the date fields, filter works
    if (
      this.state.chatFilter.startDate === "" &&
      this.state.chatFilter.endDate === ""
    ) {
      filter = true;
      // If both date fields have entries
    } else if (
      this.state.chatFilter.startDate !== "" &&
      this.state.chatFilter.endDate !== ""
    ) {
      let startDate = new Date(this.state.chatFilter.startDate);
      let endDate = new Date(this.state.chatFilter.endDate);

      // Check if start date is valid
      if (isValid(startDate)) {
        // Check if end date is valid
        if (isValid(endDate)) {
          // Check if end date is after start date
          if (isAfter(endDate, startDate)) {
            filter = true;
          } else {
            status =
              "Filter failed: 'Start Date' needs to be before 'End Date'";
          }
        } else {
          status = "Filter failed: Invalid date entered for 'End Date' field";
        }
      } else {
        status = "Filter failed: Invalid date entered for 'Start Date' field";
      }
      // If only start date is filled...
    } else if (this.state.chatFilter.startDate !== "") {
      let startDate = new Date(this.state.chatFilter.startDate);
      // Check if it is valid
      if (isValid(startDate)) {
        filter = true;
      } else {
        status = "Filter failed: Invalid date entered for 'End Date' field";
      }
      // If only end date is filled
    } else if (this.state.chatFilter.endDate !== "") {
      // Check if it is valid
      let endDate = new Date(this.state.chatFilter.endDate);
      if (isValid(endDate)) {
        filter = true;
      } else {
        status = "Filter failed: Invalid date entered for 'Date Start' field";
      }
    }

    // If date information is valid, then filter, if not show error message
    if (filter) {
      this.toggleFilter();
      this.setState(
        {
          loadAllChatsWithoutReloadingThread: true,
          isLoadingChats: true,
          allChatMessages: [],
          hasMoreMessagesToLoad: true,
        },
        () => {
          this.getAllChatMessages();
        }
      );
    } else {
      this.setState((prevState) => ({
        ...prevState,
        chatFilter: {
          ...prevState.chatFilter,
          filterError: status,
        },
      }));
    }
  };

  /**
   * This function resets all the filters for chat messages
   */
  handleChatFilterReset = () => {
    this.setState((prevState) => ({
      ...prevState,
      chatFilter: {
        agency: [],
        pagingNumber: [],
        startDate: "",
        endDate: "",
        filterError: null,
      },
      newMessagePagingGroupSearchData: [],
    }));
  };

  chatFilter = () => {
    // // Preparing paging group data for the filter
    let pagingGroupOption = [];
    // If user has not filtered by agency yet, they should be able to search all paging groups they are a part of
    if (
      (this.state.chatFilter &&
        this.state.chatFilter.agency &&
        !this.state.chatFilter.agency) ||
      this.state.chatFilter.agency.length === 0
    ) {
      // Go through all agencies the user is part of and add the paging groups to the search data
      this.props.userAgencies &&
        this.props.userAgencies.forEach((agency) => {
          if (this.state.userPagingGroupsOption[agency]) {
            pagingGroupOption = pagingGroupOption.concat(
              this.state.userPagingGroupsOption[agency]
            );
          }
        });

      // If the user has filtered by agency already, they should only be able to search the paging groups belonging to the
      // filtered agencies
    } else {
      // Go through all agencies the user is part of and add the paging groups to the search data
      this.state.chatFilter &&
        this.state.chatFilter.agency &&
        this.state.chatFilter.agency.forEach((agency) => {
          if (this.state.userPagingGroupsOption[agency]) {
            pagingGroupOption = pagingGroupOption.concat(
              this.state.userPagingGroupsOption[agency]
            );
          }
        });
    }

    // sort the paging groups in ascending order by name
    pagingGroupOption.sort((a, b) => {
      return a["text"].localeCompare(b["text"]);
    });

    return (
      <Wrapper>
        <form
          onSubmit={this.handleChatFilterSubmit}
          onReset={this.handleChatFilterReset}
        >
          <div className={styles.dropDownWrapper}>
            {this.state.userAgenciesOption.length > 1 && (
              // this user must have more than 1 agency for this filter to appear
              <div
                className={[
                  styles.dropDown,
                  this.state.multiAgency === true
                    ? "hide agencyDropdown"
                    : "show agencyDropdown",
                ].join(" ")}
              >
                <span className={styles.dropdownLabel}>Agency</span>
                {this.state.multiAgency === true && (
                  <span
                    aria-label="Multiple agencies selected"
                    className={styles.multi}
                    onClick={this.changeBack}
                  >
                    <span>
                      Multiple
                      <span className={styles.desktopTxt}>agencies</span>{" "}
                      selected
                    </span>
                    <i aria-hidden="true" className="dropdown icon"></i>
                  </span>
                )}
                <Dropdown
                  key="UserSelectDropdown"
                  // placeholder="Select agency"
                  fluid
                  multiple
                  search
                  selection
                  className={styles.dropdown}
                  value={this.state.chatFilter.agency}
                  options={this.state.userAgenciesOption}
                  on={"true"}
                  onChange={(e, { value }) =>
                    this.handleChatAgencyFilterChange(value)
                  }
                />
              </div>
            )}
            {pagingGroupOption.length > 1 && (
              // this user must have more than 1 paging group for this filter to appear
              <div
                className={[
                  styles.dropDown,
                  this.state.multiPagingGroup === true
                    ? "hide pgroupDropdown"
                    : "show pgroupDropdown",
                ].join(" ")}
              >
                <span className={styles.dropdownLabel}>Paging Group</span>
                {this.state.multiPagingGroup === true && (
                  <span
                    aria-label="Multiple groups selected"
                    className={styles.multi}
                    onClick={this.changeBack}
                  >
                    <span>
                      Multiple
                      <span className={styles.desktopTxt}> groups </span>{" "}
                      selected
                    </span>
                    <i aria-hidden="true" className="dropdown icon"></i>
                  </span>
                )}
                <Dropdown
                  key="PagingNumberSelectDropdown"
                  // placeholder="Select paging group"
                  fluid
                  multiple
                  search
                  selection
                  className={styles.dropdown}
                  value={this.state.chatFilter.pagingNumber}
                  options={pagingGroupOption}
                  onChange={(e, { value }) => {
                    if (value.length === 0) {
                      this.setState((prevState) => ({
                        ...prevState,
                        chatFilter: {
                          ...prevState.chatFilter,
                          pagingNumber: [],
                        },
                      }));
                    } else {
                      this.setState((prevState) => ({
                        ...prevState,
                        chatFilter: {
                          ...prevState.chatFilter,
                          pagingNumber: value,
                        },
                      }));
                    }
                  }}
                />
              </div>
            )}
          </div>
          <div className={styles.dateSelectorWrapper}>
            <div className={styles.dateSelector}>
              <span>Date From</span>
              <input
                className="ui fluid multiple search selection dropdown"
                type="date"
                id="startDate"
                name="startDate"
                placeholder="dd/mm/yyyy"
                minLength="8"
                maxLength="10"
                defaultValue=""
                onInput={this.handleDateInputChange}
                value={
                  this.state.chatFilter && this.state.chatFilter.startDate
                    ? this.state.chatFilter.startDate
                    : ""
                }
              />
            </div>
            <div className={styles.dateSelector}>
              <span>Date To</span>
              <input
                className="ui fluid multiple search selection dropdown"
                type="date"
                id="endDate"
                name="endDate"
                placeholder="dd/mm/yyyy"
                minLength="8"
                maxLength="10"
                defaultValue=""
                onInput={this.handleDateInputChange}
                value={
                  this.state.chatFilter && this.state.chatFilter.endDate
                    ? this.state.chatFilter.endDate
                    : ""
                }
              />
            </div>
          </div>
          <p>{this.state.chatFilter.filterError}</p>
          <div className={styles.filterButtonWrapper}>
            <Button
              label={copy.global.btnReset}
              content={copy.global.btnReset}
              styles="btn_secondary"
              type="reset"
            />
            <button
              aria-label={copy.global.btnFilter}
              className={styles.submitButton}
              type="submit"
            >
              {copy.global.btnFilter}
            </button>
          </div>
        </form>
      </Wrapper>
    );
  };

  handleChatSortClick = () => {
    this.setState(
      {
        sortDirection: this.state.sortDirection === "DESC" ? "ASC" : "DESC",
        sortIcon:
          this.state.sortDirection === "DESC" ? "icon_sort flip" : "icon_sort",
      },
      () => {
        this.setState(
          {
            loadAllChatsWithoutReloadingThread: true,
            isLoadingChats: true,
            allChatMessages: [],
            hasMoreMessagesToLoad: true,
          },
          () => {
            this.getAllChatMessages();
          }
        );
      }
    );
  };

  //=======================================================================
  //=================== END CHAT FILTER AND SORT FUNCTIONS ================
  //=======================================================================

  //=======================================================================
  //========================== START RENDER FUNCTION ======================
  //=======================================================================
  /**
   * Allows scrolling to be toggled on and off to support
   * the Back to Top button's functionality
   */
  setScrollable = (scrollable) => {
    this.setState({ scrollable: scrollable }, () => {});
  };

  render() {
    const { width } = this.state;
    const isMobile = width <= 1023;
    if (this.state.isLoadingAllChats) {
      return (
        <div className={styles.SASChat + " main-content-holder"}>
          <div className={styles.chat_content_holder}>
            <div className={styles.fullPageLoader}>
              <div className={styles.loadingContainer}>
                <Loader />
              </div>
            </div>
          </div>
        </div>
      );
    }
    //Getting Chat Messages failed
    else if (this.state.allChatMessages === false) {
      return (
        <h4>
          <span className="noMessages">
            {copy.sasChat.getChatMessagesAPIErrorMessage +
              ` (Error #${copy.errorCodes.getChatMessagesAPIErrorMessage})`}
            <div>
              <Button
                label="Refresh"
                content={copy.global.btnRefresh}
                variant="btn_solid"
                styles="greyBg"
                icon="icon_refresh"
                buttonClick={this.handleRefresh}
              />
            </div>
          </span>
        </h4>
      );
    } else {
      const NewMessageModule = (
        <div
          ref={(resultsWrapperHeight) => {
            this.resultsWrapperHeight = resultsWrapperHeight;
          }}
          className={styles.userSearchWrapper}
        >
          <div
            id="userSearchHeader"
            className={styles.userSearchHeader + " Section-titles"}
          >
            <div className={styles.content}>
              <h2>{copy.searchAndFilter.newChat}</h2>
              <div className={styles.btnHolder}>
                {this.state.showRefineSearchButton &&
                  !this.state.newMessageThread && (
                    <Button
                      label="New Search"
                      content="New Search"
                      variant="btn_secondary"
                      styles="btn_outline"
                      icon="icon_search"
                      buttonClick={() => {
                        this.refineSearch();
                      }}
                    />
                  )}
              </div>
            </div>
            {this.state.showSearchParameters && !this.state.newMessageThread ? (
              <div className={styles.searchParametersContainer + " bg4"}>
                <div className={styles.searchParameters}>
                  {this.state.searchData.agency && (
                    <Wrapper>
                      {this.state.searchData.agency.map((item, key) => (
                        <div className={styles.searchParameterItem} key={key}>
                          {item}
                        </div>
                      ))}
                    </Wrapper>
                  )}
                  {this.state.searchData.keyword && (
                    <Wrapper>
                      {this.state.searchData.keyword.map((item, index) => (
                        <div className={styles.searchParameterItem + " tileBg"}>
                          <Close
                            onClick={() => {
                              this.handleSearchParameterRemoval(
                                item,
                                "keyword"
                              );
                            }}
                          />
                          {this.state.searchData.keyword[index]}
                        </div>
                      ))}
                    </Wrapper>
                  )}
                  {this.state.newMessagePagingGroupSearchData && (
                    <Wrapper>
                      {this.state.newMessagePagingGroupSearchData.map(
                        (item) => (
                          <div className={styles.searchParameterItem}>
                            <Close
                              onClick={() => {
                                this.handleSearchParameterRemoval(
                                  item,
                                  "pagingNumberGroupSearch"
                                );
                              }}
                            />
                            {this.state.pagingGroupsReferenceList.find(
                              (el) => el.key === item
                            )
                              ? this.state.pagingGroupsReferenceList.find(
                                  (el) => el.key === item
                                ).text
                              : this.state.pagingGroupResults &&
                                this.state.pagingGroupResults.find(
                                  (el) => el.number === item
                                ) &&
                                this.state.pagingGroupResults.find(
                                  (el) => el.number === item
                                ).name}
                          </div>
                        )
                      )}
                    </Wrapper>
                  )}
                  {this.state.searchData.qualificationId && (
                    <Wrapper>
                      {this.state.searchData.qualificationId.map((item) => (
                        <div className={styles.searchParameterItem}>
                          <Close
                            onClick={() => {
                              this.handleSearchParameterRemoval(
                                item,
                                "qualificationId"
                              );
                            }}
                          />
                          {this.state.allUserQualifications &&
                            this.state.allUserQualifications[item].text}
                        </div>
                      ))}
                    </Wrapper>
                  )}
                </div>
                <div className={styles.addToSearchParameters}>
                  <Wrapper>
                    <input
                      id="addToSearchInput"
                      placeholder="Add another person or group..."
                      // defaultValue={this.state.searchData.keyword}
                      className={styles.searchbox + " highContrast"}
                    />
                  </Wrapper>
                  <Wrapper>
                    <Button
                      label="Add To Search"
                      content="Add"
                      variant="btn_secondary"
                      styles=""
                      icon="icon_add"
                      buttonClick={this.handleAddToSearch}
                    />
                  </Wrapper>
                </div>
              </div>
            ) : (
              this.state.newMessageThread && (
                <div className={styles.searchParametersContainer + " bg4"}>
                  <div className={styles.searchParameters}>
                    {this.state.newMessagePagingGroup.map((item) => (
                      <div className={styles.searchParameterItem}>
                        <Close
                          onClick={() => {
                            this.handleNewMessageParameterRemoval(
                              item,
                              "pagingNumber"
                            );
                          }}
                        />
                        {this.state.pagingGroupResults &&
                          this.state.pagingGroupResults.find(
                            (el) => el.number === item
                          ) &&
                          this.state.pagingGroupResults.find(
                            (el) => el.number === item
                          ).name}
                      </div>
                    ))}
                    {this.state.newMessageUsers.map((item) => (
                      <div className={styles.searchParameterItem}>
                        <Close
                          onClick={() => {
                            this.handleNewMessageParameterRemoval(item, "user");
                          }}
                        />
                        {this.state.membersResults &&
                          this.state.membersResults.find(
                            (el) => el.userId === item.userId
                          ) &&
                          this.state.membersResults.find(
                            (el) => el.userId === item.userId
                          ).name}
                      </div>
                    ))}
                  </div>
                </div>
              )
            )}
          </div>
          {this.state.newMessageThread ? (
            <NewChat
              location="SASChat"
              getChatThread={this.getChatThread}
              newMessagePagingGroup={this.state.newMessagePagingGroup}
              newMessageUsers={this.state.newMessageUsers}
              chatRefresh={this.handleRefresh}
            ></NewChat>
          ) : (
            <Wrapper>
              {this.state.showUserAndGroupSearch
                ? this.renderGroupsAndUsersSearchFiltersSection()
                : this.renderGroupsAndUsersResultsSection()}
            </Wrapper>
          )}
        </div>
      );

      //Mobile rendering
      if (isMobile) {
        return (
          <Wrapper>
            <ErrorBanner
              isVisible={this.state.errorMessage ? true : false}
              ErrorMessage={this.state.errorMessage}
            />
            <div className={styles.SASChat + " main-content-holder"}>
              <div className={styles.chat_content_holder + " col-one-third"}>
                {/* Loading chat messages  */}
                <Wrapper>
                  {this.state.isLoadingChats ? (
                    <Wrapper>
                      <div className={styles.contentHeader}>
                        <div
                          className={styles.buttonHeader + " Section-titles"}
                        >
                          <div className={styles.buttonWrapper}>
                            {this.state.showCreateNewMessageButton && (
                              <Button
                                label="new chat message"
                                content={copy.sasChat.btnNewChat}
                                variant="btn_outline"
                                styles="greenTxt"
                                icon="icon_post"
                                buttonClick={this.CreateNewMessage}
                              />
                            )}
                            <Button
                              label="Mark read-all"
                              content={copy.sasChat.btnReadAll}
                              variant="btn_outline"
                              styles="greenTxt"
                              buttonClick={this.ReadAllMessage}
                            />
                          </div>
                          {!this.state.showCreateNewMessageButton && (
                            <div className={styles.noNewMessage}>
                              {copy.sasChat.noNewMessage}
                            </div>
                          )}
                          <div className={styles.buttonWrapper}>
                            <Button
                              label="filter"
                              content={copy.global.btnFilter}
                              variant="btn_solid"
                              styles={[
                                "greyBg",
                                this.state.showFilters === true
                                  ? styles.filterButtonIsActive
                                  : "",
                              ].join(" ")}
                              icon="icon_filter"
                              buttonClick={this.toggleFilter}
                            />
                            <Button
                              label="sort"
                              content={copy.global.btnSort}
                              variant="btn_solid"
                              styles="greyBg"
                              icon={this.state.sortIcon}
                              buttonClick={this.handleChatSortClick}
                            />
                          </div>
                        </div>
                        <div
                          className={
                            this.state.showFilters === true
                              ? styles.controls + " chat_controls"
                              : "hidden"
                          }
                        >
                          <div className={styles.filterControls}>
                            <p>{copy.searchAndFilter.limitResults}</p>
                            <div className={styles.holder}>
                              {this.chatFilter()}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={styles.fullPageLoader}>
                        <div className={styles.loadingContainer}>
                          <Loader />
                        </div>
                      </div>
                    </Wrapper>
                  ) : this.state.allChatMessages &&
                    this.state.allChatMessages[0] ? (
                    // If chat messages are available
                    <Wrapper>
                      {this.state.showMessageList ? (
                        <Wrapper>
                          {this.state.createNewMessage ? (
                            <Wrapper>{NewMessageModule}</Wrapper>
                          ) : (
                            <Wrapper>
                              <div className={styles.contentHeader}>
                                <div
                                  className={
                                    styles.buttonHeader + " Section-titles"
                                  }
                                >
                                  <div className={styles.buttonWrapper}>
                                    {this.state.showCreateNewMessageButton && (
                                      <Button
                                        label="new chat message"
                                        content={copy.sasChat.btnNewChat}
                                        variant="btn_outline"
                                        styles="greenTxt"
                                        icon="icon_post"
                                        buttonClick={this.CreateNewMessage}
                                      />
                                    )}
                                    <Button
                                      label="Mark read-all"
                                      content={copy.sasChat.btnReadAll}
                                      variant="btn_outline"
                                      styles="greenTxt"
                                      buttonClick={this.ReadAllMessage}
                                    />
                                  </div>
                                  {!this.state.showCreateNewMessageButton && (
                                    <div className={styles.noNewMessage}>
                                      {copy.sasChat.noNewMessage}
                                    </div>
                                  )}
                                  <div className={styles.buttonWrapper}>
                                    <Button
                                      label="filter"
                                      content={copy.global.btnFilter}
                                      variant="btn_solid"
                                      styles={[
                                        "greyBg",
                                        this.state.showFilters === true
                                          ? styles.filterButtonIsActive
                                          : "",
                                      ].join(" ")}
                                      icon="icon_filter"
                                      buttonClick={this.toggleFilter}
                                    />
                                    <Button
                                      label="sort"
                                      content={copy.global.btnSort}
                                      variant="btn_solid"
                                      styles="greyBg"
                                      icon={this.state.sortIcon}
                                      buttonClick={this.handleChatSortClick}
                                    />
                                  </div>
                                </div>
                                <div
                                  className={
                                    this.state.showFilters === true
                                      ? styles.controls + " chat_controls"
                                      : "hidden"
                                  }
                                >
                                  <div className={styles.filterControls}>
                                    <p>{copy.searchAndFilter.limitResults}</p>
                                    <div className={styles.holder}>
                                      {this.chatFilter()}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                id="chatMessagesWrapper"
                                className={
                                  styles.message_holder +
                                  " " +
                                  (this.state.scrollable
                                    ? styles.scrollable
                                    : styles.unscrollable)
                                }
                              >
                                <InfiniteScroll
                                  dataLength={this.state.allChatMessages.length}
                                  next={this.triggerPagination}
                                  hasMore={this.state.hasMoreMessagesToLoad}
                                  loader={
                                    <div className={styles.newMessageLoader}>
                                      <Loader />
                                    </div>
                                  }
                                  scrollableTarget="chatMessagesWrapper"
                                  scrollThreshold="100%"
                                >
                                  {this.state.allChatMessages.map(
                                    (item, key) => (
                                      <Chat
                                        key={key}
                                        ChatData={item}
                                        UserId={this.state.UserId}
                                        RecipientName={recipientName}
                                        buttonClick={() =>
                                          this.openMessageMobile(item.id)
                                        }
                                        paramVal={key}
                                      />
                                    )
                                  )}
                                </InfiniteScroll>
                              </div>
                              {document.getElementById(
                                "chatMessagesWrapper"
                              ) ? (
                                <ScrollButton
                                  scrollStepInPx="50"
                                  delayInMs="1"
                                  display={true}
                                  scrollableElement={document.getElementById(
                                    "chatMessagesWrapper"
                                  )}
                                  setScrollable={(scrollable) => {
                                    this.setScrollable(scrollable);
                                  }}
                                />
                              ) : null}
                            </Wrapper>
                          )}
                        </Wrapper>
                      ) : (
                        <Wrapper>
                          {this.state.createNewMessage ? (
                            <Wrapper>{NewMessageModule}</Wrapper>
                          ) : (
                            <Wrapper>
                              {this.state.isLoadingThread ? (
                                <div
                                  className={
                                    styles.loading_holder +
                                    " " +
                                    styles.loading_thread
                                  }
                                >
                                  <div className={styles.loadingContainer}>
                                    <Loader />
                                  </div>
                                </div>
                              ) : (
                                <Wrapper>
                                  {this.state.createNewMessage ? (
                                    <Wrapper>{NewMessageModule}</Wrapper>
                                  ) : (
                                    <Wrapper>
                                      {this.state.chatThread && (
                                        <ChatThread
                                          key={this.state.chatIndex}
                                          UserId={this.state.UserId}
                                          chatMessages={this.state.chatThread}
                                          closeThread={this.closeThread}
                                          RecipientName={recipientName}
                                        />
                                      )}
                                    </Wrapper>
                                  )}
                                </Wrapper>
                              )}
                            </Wrapper>
                          )}
                        </Wrapper>
                      )}
                    </Wrapper>
                  ) : (
                    <Wrapper>
                      {this.state.createNewMessage ? (
                        <Wrapper>{NewMessageModule}</Wrapper>
                      ) : (
                        <Wrapper>
                          {/* New chat button when no messages available */}
                          <div className={styles.contentHeader}>
                            <div
                              className={
                                styles.buttonHeader + " Section-titles"
                              }
                            >
                              <div className={styles.buttonWrapper}>
                                {this.state.showCreateNewMessageButton && (
                                  <Button
                                    label="new chat message"
                                    content={copy.sasChat.btnNewChat}
                                    variant="btn_outline"
                                    styles="greenTxt"
                                    icon="icon_post"
                                    buttonClick={this.CreateNewMessage}
                                  />
                                )}
                                <Button
                                  label="Mark read-all"
                                  content={copy.sasChat.btnReadAll}
                                  variant="btn_outline"
                                  styles="greenTxt"
                                  buttonClick={this.ReadAllMessage}
                                />
                              </div>
                              {!this.state.showCreateNewMessageButton && (
                                <div className={styles.noNewMessage}>
                                  {copy.sasChat.noNewMessage}
                                </div>
                              )}
                              <div className={styles.buttonWrapper}>
                                <Button
                                  label="filter"
                                  content={copy.global.btnFilter}
                                  variant="btn_solid"
                                  styles={[
                                    "greyBg",
                                    this.state.showFilters === true
                                      ? styles.filterButtonIsActive
                                      : "",
                                  ].join(" ")}
                                  icon="icon_filter"
                                  buttonClick={this.toggleFilter}
                                />
                                <Button
                                  label="sort"
                                  content={copy.global.btnSort}
                                  variant="btn_solid"
                                  styles="greyBg"
                                  icon={this.state.sortIcon}
                                  buttonClick={this.handleChatSortClick}
                                />
                              </div>
                            </div>
                            <div
                              className={
                                this.state.showFilters === true
                                  ? styles.controls + " chat_controls"
                                  : "hidden"
                              }
                            >
                              <div className={styles.filterControls}>
                                <p>{copy.searchAndFilter.limitResults}</p>
                                <div className={styles.holder}>
                                  {this.chatFilter()}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className={styles.loading_holder}>
                            <div className={styles.loadingContainer}>
                              <h3>{copy.sasChat.noChats}</h3>
                            </div>
                          </div>
                        </Wrapper>
                      )}
                    </Wrapper>
                  )}
                </Wrapper>
              </div>
            </div>
          </Wrapper>
        );
      }
      // Desktop rendering
      else {
        return (
          <Wrapper>
            <ErrorBanner
              isVisible={this.state.errorMessage ? true : false}
              ErrorMessage={this.state.errorMessage}
            />
            <div className={styles.SASChat + " main-content-holder "}>
              {this.state.isLoadingAllChats ? (
                <div className={styles.fullPageLoader}>
                  <div className={styles.loadingContainer}>
                    <Loader />
                  </div>
                </div>
              ) : (
                <Wrapper>
                  {this.state.allChatMessages &&
                  this.state.allChatMessages.length > 0 ? (
                    <div
                      className={styles.chat_content_holder + " col-one-third"}
                    >
                      <div className={styles.contentHeader}>
                        <div className={styles.buttonHeader}>
                          <div className={styles.buttonWrapper}>
                            {this.state.showCreateNewMessageButton && (
                              <Button
                                label="new chat message"
                                content={copy.sasChat.btnNewChat}
                                variant="btn_outline"
                                styles="greenTxt"
                                icon="icon_post"
                                buttonClick={this.CreateNewMessage}
                              />
                            )}
                            <Button
                              label="Mark read-all"
                              content={copy.sasChat.btnReadAll}
                              variant="btn_outline"
                              styles="greenTxt "
                              buttonClick={this.ReadAllMessage}
                            />
                          </div>

                          {!this.state.showCreateNewMessageButton && (
                            <div className={styles.noNewMessage}>
                              {copy.sasChat.noNewMessage}
                            </div>
                          )}

                          <div className={styles.buttonWrapper}>
                            <Button
                              label="filter"
                              content={copy.global.btnFilter}
                              variant="btn_solid"
                              styles={[
                                "greyBg",
                                this.state.showFilters === true
                                  ? styles.filterButtonIsActive
                                  : "",
                              ].join(" ")}
                              icon="icon_filter"
                              buttonClick={this.toggleFilter}
                            />
                            <Button
                              label="sort"
                              content={copy.global.btnSort}
                              variant="btn_solid"
                              styles="greyBg"
                              icon={this.state.sortIcon}
                              buttonClick={this.handleChatSortClick}
                            />
                          </div>
                        </div>
                        <div
                          className={
                            this.state.showFilters === true
                              ? styles.controls + " chat_controls"
                              : "hidden"
                          }
                        >
                          <div className={styles.filterControls}>
                            <p>{copy.searchAndFilter.limitResults}</p>
                            <div className={styles.holder}>
                              {this.chatFilter()}
                            </div>
                          </div>
                        </div>
                      </div>
                      <Wrapper>
                        {this.state.isLoadingChats ? (
                          <div className={styles.loading_holder}>
                            <div className={styles.loadingContainer}>
                              <Loader />
                            </div>
                          </div>
                        ) : (
                          <div
                            id="chatMessagesWrapper"
                            className={styles.message_holder + " box_default"}
                          >
                            {this.state.allChatMessages &&
                              this.state.allChatMessages[0] && (
                                <Wrapper>
                                  <InfiniteScroll
                                    dataLength={
                                      this.state.allChatMessages.length
                                    }
                                    next={this.triggerPagination}
                                    hasMore={this.state.hasMoreMessagesToLoad}
                                    loader={
                                      <div className={styles.newMessageLoader}>
                                        <Loader />
                                      </div>
                                    }
                                    scrollableTarget="chatMessagesWrapper"
                                    scrollThreshold="50%"
                                  >
                                    {this.state.allChatMessages.map(
                                      (item, key) => (
                                        <Chat
                                          id="ChatComponent"
                                          key={key}
                                          ChatData={item}
                                          UserId={this.state.UserId}
                                          buttonClick={() =>
                                            this.openMessage(item.id)
                                          }
                                          paramVal={key}
                                          className={
                                            this.state.chatIndex === item.id
                                              ? "chatMessageActiveState"
                                              : ""
                                          }
                                        />
                                      )
                                    )}
                                  </InfiniteScroll>
                                </Wrapper>
                              )}
                          </div>
                        )}
                      </Wrapper>
                    </div>
                  ) : (
                    <div
                      className={styles.chat_content_holder + " col-one-third"}
                    >
                      <div className={styles.contentHeader}>
                        <div className={styles.buttonHeader}>
                          <div className={styles.buttonWrapper}>
                            {this.state.showCreateNewMessageButton && (
                              <Button
                                label="new chat message"
                                content={copy.sasChat.btnNewChat}
                                variant="btn_outline"
                                styles="greenTxt"
                                icon="icon_post"
                                buttonClick={this.CreateNewMessage}
                              />
                            )}
                            <Button
                              label="Mark read-all"
                              content={copy.sasChat.btnReadAll}
                              variant="btn_outline"
                              styles="greenTxt"
                              buttonClick={this.ReadAllMessage}
                            />
                          </div>
                          {!this.state.showCreateNewMessageButton && (
                            <div className={styles.noNewMessage}>
                              {copy.sasChat.noNewMessage}
                            </div>
                          )}
                          <div className={styles.buttonWrapper}>
                            <Button
                              label="filter"
                              content={copy.global.btnFilter}
                              variant="btn_solid"
                              styles={[
                                "greyBg",
                                this.state.showFilters === true
                                  ? styles.filterButtonIsActive
                                  : "",
                              ].join(" ")}
                              icon="icon_filter"
                              buttonClick={this.toggleFilter}
                            />
                            <Button
                              label="sort"
                              content={copy.global.btnSort}
                              variant="btn_solid"
                              styles="greyBg"
                              icon={this.state.sortIcon}
                              buttonClick={this.handleChatSortClick}
                            />
                          </div>
                        </div>
                        <div
                          className={
                            this.state.showFilters === true
                              ? styles.controls
                              : "hidden"
                          }
                        >
                          <div className={styles.filterControls}>
                            <p>{copy.searchAndFilter.limitResults}</p>
                            <div className={styles.holder}>
                              {this.chatFilter()}
                            </div>
                          </div>
                        </div>
                      </div>
                      {this.state.isLoadingChats ? (
                        <div className={styles.loading_holder}>
                          <div className={styles.loadingContainer}>
                            <Loader />
                          </div>
                        </div>
                      ) : (
                        <div className={styles.loading_holder}>
                          <div className={styles.loadingContainer}>
                            <h3>{copy.sasChat.noChats}</h3>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  {this.state.chatIndex ? (
                    <div
                      className={styles.chat_content_holder + " col-one-third"}
                    >
                      {this.state.isLoadingThread ? (
                        <div
                          className={
                            styles.loading_holder + " " + styles.loading_thread
                          }
                        >
                          <div className={styles.loadingContainer}>
                            <Loader />
                          </div>
                        </div>
                      ) : (
                        <Wrapper>
                          {this.state.createNewMessage ? (
                            <Wrapper>{NewMessageModule}</Wrapper>
                          ) : (
                            <Wrapper>
                              {this.state.chatThread ? (
                                <ChatThread
                                  key={this.state.chatIndex}
                                  UserId={this.state.UserId}
                                  chatMessages={this.state.chatThread}
                                  RecipientName={recipientName}
                                />
                              ) : (
                                <div
                                  className={
                                    styles.loading_holder +
                                    " " +
                                    styles.loading_thread
                                  }
                                >
                                  <div className={styles.loadingContainer}>
                                    <h4>
                                      <span className={styles.noMessages}>
                                        {copy.sasChat
                                          .getChatMessagesAPIErrorMessage +
                                          ` (Error #${copy.errorCodes.getChatMessagesAPIErrorMessage})`}
                                        <div>
                                          <Button
                                            label="Refresh"
                                            content={copy.global.btnRefresh}
                                            variant="btn_solid"
                                            styles="greyBg"
                                            icon="icon_refresh"
                                            buttonClick={() => {
                                              this.openMessage(
                                                this.state.chatIndex
                                              );
                                            }}
                                          />
                                        </div>
                                      </span>
                                    </h4>
                                  </div>
                                </div>
                              )}
                            </Wrapper>
                          )}
                        </Wrapper>
                      )}
                    </div>
                  ) : (
                    <div
                      className={styles.chat_content_holder + " col-one-third"}
                    >
                      {NewMessageModule}
                    </div>
                  )}
                </Wrapper>
              )}
            </div>
          </Wrapper>
        );
      }
    }
  }
}
