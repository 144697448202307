import React, { Component } from "react";
import styles from "./ErrorBanner.module.scss";
import Wrapper from "../Wrapper/Wrapper";
// import { ReactComponent as AlertIcon } from "../../assets/img/icons/appalert.svg";
// import Button from "../../components/UI/Button/Button";
import { withRouter } from "react-router-dom";
import copy from "../../assets/copy/copy.json";

var classNames = require("classnames");
// URL to poll to check online status.
// let offlinePollingURL = "https://www.google.com/";

class ErrorBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showErrorBanner: false,
      errorType: null,
      errorMessage: null,
      isOnline: true,
    };
  }

  componentDidMount() {
    this.setState({
      errorType: this.props.errorType,
      errorMessage: this.props.errorMessage,
    });

    if (window.cordova) {
      document.addEventListener("offline", this.onOffline, false);
      document.addEventListener("online", this.onOnline, false);
    }

    // If the error that has occurred is because the user needs to re-accept a more recent version of the terms of use,
    // direct them to the terms of use page
    if (this.state.errorType === "acceptTermsOfUse") {
      this.props.history.push({
        pathname: "/terms-of-use",
        state: { fromOtherPage: true },
      });
    }
  }

  componentWillUnmount() {
    this.setState({ showErrorBanner: false });
    if (window.cordova) {
      document.removeEventListener("offline", this.onOffline);
      document.removeEventListener("online", this.onOnline);
    }
  }

  /**
   * Handles offline event
   */
  onOffline = () => {
    this.setState({ isOnline: false });
  };

  /**
   * Handles online event
   */
  onOnline = () => {
    this.setState({ isOnline: true });
  };

  render() {
    let ErrorBanner = classNames(styles.ErrorBanner, this.props.className, {
      [styles.visible]: this.props.isVisible,
      [styles.open]: this.state.showErrorBanner,
      [styles.onTop]: this.props.onTop,
    });

    let ErrorType = "Application Error";

    let connectionErrorBanner = (
      <div
        className={
          ErrorBanner +
          " " +
          styles.visible +
          " " +
          styles.open +
          " connection_error"
        }>
        <div className={styles.content}>
          <div className={styles.errorMessage}>
            <span className={styles.bold}>
              {copy.error_handling.connectionError}
            </span>
          </div>
        </div>
      </div>
    );

    // if (this.state.errorType === "connection") {
    //   ErrorType = "No connection to the server";
    // } else {
    //   ErrorType = "Application error";
    // }

    // These are the parameters for the offline plugin
    // Plugin documentation: https://www.npmjs.com/package/react-detect-offline
    // const polling = {
    //   interval: 15000,
    //   url: offlinePollingURL,
    // };

    // let polling = {
    //   interval: 15000,
    //   url: "https://www.google.com/",
    // };

    // if (window.cordova) {
    //   // Don't poll in the mobile app to save data and network usage.
    //   polling = {
    //     enabled: false,
    //   };
    // }

    return (
      <Wrapper>
        <div
          className={
            ErrorBanner +
            " " +
            (this.state.errorType === "connection"
              ? "connection_error"
              : "application_error")
          }>
          <div className={styles.content}>
            <div className={styles.errorMessage}>
              <span className={styles.bold}>{ErrorType}</span>
              {this.props.ErrorMessage && (
                <span>{this.props.ErrorMessage}</span>
              )}
            </div>
          </div>
        </div>
        {!this.state.isOnline && connectionErrorBanner}
      </Wrapper>
    );
  }
}

export default withRouter(ErrorBanner);
