// import _ from 'lodash'
import React, { Component } from "react";
import Loader from "../../components/UI/Loading/Loading";
import Wrapper from "../../hoc/Wrapper/Wrapper";
import ErrorBanner from "../../hoc/ErrorBanner/ErrorBanner";
// layout
import Tabs from "../../components/UI/Tabs/Tabs";
import Accordion from "../../components/UI/Accordion/Accordion";

// Page Component
import QualificationTile from "../../components/Tiles/QualificationTile";
import Calendar from "../../components/Availability/Calendar";
import WeekView from "../../components/Availability/WeekView";
import NewChat from "../../components/Chat/NewChat";

// Page UIs & styles
import Button from "../../components/UI/Button/Button";
import Checkbox from "../../components/UI/Button/Checkbox";
import Toggle from "../../components/UI/Button/Toggle";
import copy from "../../assets/copy/copy.json";
import styles from "./AdminView.module.scss";

// Requests for API calls
import { Requests } from "../../api/IdentityServerRequests/Requests";
import { format } from "date-fns";

/*
 * This is the user details section of Admin,
 * it will only appear after a user has completed a search and selected a user
 */

export default class UserDetailsView extends Component {
  constructor(props) {
    super(props);
    this.Requests = new Requests();
    this.state = {
      refresh: true, // Used to trigger loader when information is updated
      userId: null, // ID of the selected iser
      QualList: null, // List of the selected user's qualifcations
      //userAgencies: [], //Not used
      hasSSOAccount: null,
      passwordStatus: null,
      deactivationStatus: null,
      userAgencies: null,
      userRoles: null, // A list of all the selected user's roles
      administratorRoles: null, // Used to store all the administrator roles the selected user has
      administratorRolesCheckbox: null,
      superuserRole: null, // Used to store if the selected user is a superuser
      superuserRoleCheckbox: null,
      userPermissionsExpanded: false,
      createNewMessage: false,
      showUserLogoutSuccessMessage: false, // Used to show the success message when the user has been locked out
      isSavingUserPermissions: false,
      isShowReactivate: false, // Used to show the reactivate & permanent delete button
      reactivationStatus: null, // Used to store the status of the reactivation request
      permanentDeleteStatus: null, // Used to store the status of the permanent delete request
      permanentDeleteConfirmation: false, // Used to show the confirmation message when the user clicks the permanent delete button
      isSavingTurnoutAccess: false,
      userRolesError: false,
      showAvailabilityAndQualifications: null, // Determines whether the availability and qualifications section should be displayed (null means API call is loading)
      // ASN SECTION
      loadingASN: [true, true, true],
      groupLoading: false,
      addASN: [false, false, false],
      ASNValueList: [], // List of ASNs to check for duplicates,
      viperProfile: null, // Full VIPER profile
      invalidASN: false,
      duplicateASN: false,
      asnLimitReached: true,
      asnLimits: {
        // Maximum number of ASNs allowed for each agency. Change values here if business rules change.
        AV: 1,
        CFA: 1,
        SES: 1,
        DYNAMIC: 0,
      },
      resetASN: false,

      showProfileView: false,
      userProfileData: null,
      showPagingGroupView: false,
      userPagingGroups: null,
      showAddGroupConfirmation: false,
      groupsLoading: true,

      // searching for paging groups
      searchValue: "",
      validAgencies: [],
      groupResults: [],
      updateGroupResults: false,
      added: false,
      groupToAdd: false,
      removed: false,
      errorInRemovingPagingGroup: false,
      qualsLoading: true, // indicates whether qualification data is loading or not
      userRolesLoading: true, // indicates whether user role data is loading or not
      userSSOAccountLoading: true, // indicates whether user SSO data is loading or not,
      userName: null,
    };
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.user && nextProps.user.userId !== prevState.userId) {
      return {
        userId: nextProps.user.userId,
        userName: nextProps.user.name,
      };
    }
    return null;
  }

  componentDidMount() {
    if (this.state.userId !== null) {
      this.getQuals();
      this.getUserRoles();
      this.getUserSSOAccount();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.user && this.props.user.userId !== prevProps.user.userId) {
      this.setState(
        {
          userId: this.props.user.userId,
          refresh: false,
          userPermissionsExpanded: false,
          showAvailabilityAndQualifications: null, // When new user is clicked, display loader again
          qualsLoading: true,
          userRolesLoading: true,
          userSSOAccountLoading: true,
          createNewMessage: false, // when new user is clicked, hide create new message section
        },
        () => {
          //this.getUserDetails();
          this.getQuals();
          this.getUserRoles();
          this.getUserSSOAccount();
          this.setState({ refresh: true });
        }
      );
    }
  }
  /**
   * This function deletes the user's refresh token in the BE to log them out
   */
  forceUserLockout = () => {
    this.Requests.callAPI(
      this.Requests.deleteUserRefreshToken,
      this.state.userId
    ).then((data) => {
      if (data && data.status && data.status === 200) {
        this.getUserProfile();
        this.setState(
          {
            showUserLogoutSuccessMessage: true,
          },
          () => {
            setTimeout(
              function () {
                this.setState({ showUserLogoutSuccessMessage: false });
              }.bind(this),
              7000
            );
          }
        );
      } else {
        let ErrorMessage =
          copy.admin.userDetailsView.userLockoutAllDevicesError +
          ` (Error #${copy.errorCodes.userLockoutAllDevicesError})`;
        // if (data && data.data && data.data.SASMessageClient) {
        //   ErrorMessage = data.data.SASMessageClient;
        // }
        this.setState({ errorMessage: ErrorMessage }, () => {
          setTimeout(
            function () {
              this.setState({ errorMessage: null });
            }.bind(this),
            5000
          );
        });
      }
    });
  };

  canOneOnOneChat = () => {
    //This is to check if the user able to send One on One chats - i.e. U18 users and AV users will not be able to
    let isAllowedOneOnOneChat = [];
    if (this.props.user) {
      if (this.props.user.userAgencyInfo) {
        for (let i = 0; i < this.props.user.userAgencyInfo.length; i++) {
          isAllowedOneOnOneChat.push(
            this.props.user.userAgencyInfo[i].chatOneToOneEnabled
          );
        }
      }
    }

    if (isAllowedOneOnOneChat.includes(true)) {
      return true;
    } else {
      return false;
    }
  };

  /**
   * Function which gets the user's qualifications
   */
  getQuals = () => {
    this.Requests.callAPI(
      this.Requests.getSelectedUserQualification,
      this.state.userId
    ).then((data) => {
      if (data && data.status && data.status === 200) {
        this.setState(
          {
            QualList: data.data,
            qualsLoading: false,
          },
          () => {}
        );
      } else {
        let ErrorMessage =
          copy.admin.userDetailsView.getSelectedUserQualsAPIErrorMessage +
          ` (Error #${copy.errorCodes.getSelectedUserQualsAPIErrorMessage})`;
        // if (data && data.data && data.data.SASMessageClient) {
        //   ErrorMessage = data.data.SASMessageClient;
        // }
        this.setState({ errorMessage: ErrorMessage }, () => {
          setTimeout(
            function () {
              this.setState({ errorMessage: null, QualList: [] });
            }.bind(this),
            5000
          );
        });
      }
    });
  };

  /**
   * This function makes an API call to see if the user has other SSO accounts linked
   * */
  getUserSSOAccount = () => {
    this.Requests.callAPI(
      this.Requests.getUserSSOAccount,
      this.state.userId
    ).then((data) => {
      if (data && data.status && data.status === 200) {
        this.setState({
          hasSSOAccount: data.data,
          userSSOAccountLoading: false,
        });
      } else {
        // let ErrorMessage =
        //   copy.admin.userDetailsView.getUserInfoAPIErrorMessage;
        // if (data && data.data && data.data.SASMessageClient) {
        //   ErrorMessage = data.data.SASMessageClient;
        // }
        // Needs no Error Message as it only checks if the user an AD or SAS user
        // If this fails the user can't reset the password and update permissions
        this.setState({ hasSSOAccount: null }, () => {
          setTimeout(
            function () {
              this.setState({ errorMessage: null });
            }.bind(this),
            5000
          );
        });
      }
    });
  };

  /**
   * This function gets the selected user's roles and also determines which roles the user can change for the selected user
   */
  getUserRoles = () => {
    this.setState({ isSavingUserPermissions: true });
    this.Requests.callAPI(this.Requests.getUserInheritedRoles).then((data) => {
      if (data && data.status && data.status === 200) {
        const inheritedRoles = data.data;
        this.Requests.callAPI(
          this.Requests.getUserRoles,
          this.state.userId
        ).then((data) => {
          if (data && data.status && data.status === 200) {
            // All valid roles a user of SAS can have
            const currentUserRoles = data.data;

            let validRoles = [
              "SuperUser",
              "User_CFA",
              "User_AV",
              "User_SES",
              "Manager_CFA",
              "Manager_AV",
              "Manager_SES",
              "Administrator_CFA",
              "Administrator_AV",
              "Administrator_SES",
            ];

            // Obtain a list of all the user's valid roles
            let userRoles;
            if (typeof data.data !== "string") {
              // Filter out the invalid roles from the user's list of roles
              userRoles = data.data.filter((role) => {
                return validRoles.indexOf(role) !== -1;
              });
            } else {
              if (validRoles.indexOf(this.state.userInformation.role) !== -1) {
                userRoles = [this.state.userInformation.role.split("_")[0]];
              } else {
                userRoles = [];
              }
            }

            // // Get the agencies the selected user is part of (based on what roles they have)
            // let selectedUserAgencies = [];
            // for (let i = 0; i < userRoles.length; i++) {
            //   let role = userRoles[i];
            //   if (
            //     role !== "SuperUser" &&
            //     selectedUserAgencies.indexOf(role.split("_")[1]) === -1
            //   ) {
            //     selectedUserAgencies.push(role.split("_")[1]);
            //   }
            // }

            let userAgencies = [];
            let administratorRoles = []; // Used to store all the permission options that can be changed for the user
            let administratorRolesCheckbox = {}; // Stores the state of checkboxes for non-superuser roles
            let superuserRole = false;
            let superuserRoleCheckbox = false;

            // Conditional shows super user checkbox if user trying to change permissions has super user permissions
            if (this.props.userRoleAgency.indexOf("SuperUser") !== -1) {
              superuserRole = true;
              superuserRoleCheckbox = userRoles.indexOf("SuperUser") !== -1;
            } else {
              superuserRole = false;
              superuserRoleCheckbox = false;
            }
            /*   OLD LOGIC FOR CHECKING WHICH ROLES TO DISPLAY IN USER ACCESS SECTION         
            // Maps over inherited roles of user trying to change permissions and checks current roles of selected user
            for (let i = 0; i < inheritedRoles.length; i++) {
              // If user trying to change permissions is a super user, show the option to make a user a super user
              if (inheritedRoles[i] !== "SuperUser") {
                // Adds roles of user trying to change permissions to admin roles array.
                administratorRoles.push(inheritedRoles[i]);
                administratorRolesCheckbox[inheritedRoles[i]] =
                  userRoles.indexOf(inheritedRoles[i]) !== -1;
              }
            }
            */

            // NEW LOGIC
            // Check to make sure logged in user is not a non-SuperUser trying to change permissions of a SuperUser
            if (
              !(
                this.props.userRoleAgency.indexOf("SuperUser") === -1 &&
                currentUserRoles.indexOf("SuperUser") !== -1
              )
            ) {
              // These are flags to check if the logged in user's agencies have been considered
              let agencies = {
                AV: false,
                CFA: false,
                SES: false,
              };

              currentUserRoles.sort();
              // Iterate through the selected user's roles.
              for (let i = 0; i < currentUserRoles.length; i++) {
                let selectedUserRole = currentUserRoles[i];
                let agency = selectedUserRole.split("_")[1];
                // Only add inherited roles if selected user's role can be found in the inherited roles
                /* 
                Checking if the agency has been considered before means we will only ever select one box per agency
                By sorting currentUserRoles, we will prioritise selecting the role with the highest permissions as the array
                will be ordered by Admins then Managers then Users
                */
                if (
                  inheritedRoles.indexOf(selectedUserRole) !== -1 &&
                  selectedUserRole !== "SuperUser" &&
                  !agencies[agency]
                ) {
                  for (let j = 0; j < inheritedRoles.length; j++) {
                    let inheritedRole = inheritedRoles[j];
                    // Only add the inherited role if it belongs to the same agency as the selectedUserRole
                    if (inheritedRole.split("_")[1] === agency) {
                      administratorRoles.push(inheritedRole);
                      administratorRolesCheckbox[inheritedRole] =
                        userRoles.indexOf(inheritedRole) !== -1;
                    }
                  }
                }
                agencies[agency] = true;
              }

              for (let agency in agencies) {
                // Check for roles in agencies that the logged in user belongs to but the selected user does not belong to
                // These should also be displayed
                if (!agencies[agency]) {
                  for (let j = 0; j < inheritedRoles.length; j++) {
                    let inheritedRole = inheritedRoles[j];
                    if (inheritedRole.split("_")[1] === agency) {
                      administratorRoles.push(inheritedRole);
                      administratorRolesCheckbox[inheritedRole] =
                        userRoles.indexOf(inheritedRole) !== -1;
                    }
                  }
                }
              }
            }

            // Maps over inherited roles of user trying to change permissions and checks current roles of selected user
            for (let i = 0; i < currentUserRoles.length; i++) {
              // If user trying to change permissions is a super user, show the option to make a user a super user
              if (currentUserRoles[i] !== "SuperUser") {
                if (
                  userAgencies.indexOf(currentUserRoles[i].split("_")[1]) === -1
                ) {
                  userAgencies.push(currentUserRoles[i].split("_")[1]);
                }
              }
            }

            // Getting all the permissions the user is able to change for the selected user
            // If the current user is a superuser...
            // if (this.props.userRoleAgency.indexOf("SuperUser") !== -1) {
            //   // They can make the selected user a superuser
            //   // superuserRole = true;
            //   // superuserRoleCheckbox = userRoles.indexOf("SuperUser") !== -1;

            //   // They can make the selected user an admin for any agency they are part of, provided they are a SAS only user
            //   // if (!this.state.hasSSOAccount) {

            //   // for (let i = 0; i < selectedUserAgencies.length; i++) {
            //   //   let admin = "Administrator_" + selectedUserAgencies[i];
            //   //   administratorRoles.push(admin);
            //   //   administratorRolesCheckbox[admin] =
            //   //     userRoles.indexOf(admin) !== -1;

            //   //   let managerRole = "Manager_" + selectedUserAgencies[i];
            //   //   administratorRoles.push(managerRole);
            //   //   administratorRolesCheckbox[managerRole] =
            //   //     userRoles.indexOf(managerRole) !== -1;
            //   //   let userRole = "User_" + selectedUserAgencies[i];
            //   //   administratorRoles.push(userRole);
            //   //   administratorRolesCheckbox[userRole] =
            //   //     userRoles.indexOf(userRole) !== -1;
            //   // }
            //   // If the current user is not a superuser...
            // } else {
            //   superuserRole = false;
            //   superuserRoleCheckbox = false;
            //   // Only when the selected user is not a superuser can you change their admin permissions
            //   if (userRoles.indexOf("SuperUser") === -1) {
            //     // for (let i = 0; i < selectedUserAgencies.length; i++) {
            //     //   let admin = "Administrator_" + selectedUserAgencies[i];
            //     //   let manager = "Manager_" + selectedUserAgencies[i];
            //     //   // Only when the current user is an administrator of the agency, can they change the admin permissions of that agency for the selected user
            //     //   if (this.props.userRoleAgency.indexOf(admin) !== -1) {
            //     //     administratorRoles.push(admin);
            //     //     administratorRolesCheckbox[admin] =
            //     //       userRoles.indexOf(admin) !== -1;

            //     //     let managerRole = "Manager_" + selectedUserAgencies[i];
            //     //     administratorRoles.push(managerRole);
            //     //     administratorRolesCheckbox[managerRole] =
            //     //       userRoles.indexOf(managerRole) !== -1;
            //     //     let userRole = "User_" + selectedUserAgencies[i];
            //     //     administratorRoles.push(userRole);
            //     //     administratorRolesCheckbox[userRole] =
            //     //       userRoles.indexOf(userRole) !== -1;
            //     //   } else if (
            //     //     this.props.userRoleAgency.indexOf(manager) !== -1
            //     //   ) {
            //     //     administratorRoles.push(manager);
            //     //     administratorRolesCheckbox[manager] =
            //     //       userRoles.indexOf(manager) !== -1;

            //     //     let userRole = "User_" + selectedUserAgencies[i];
            //     //     administratorRoles.push(userRole);
            //     //     administratorRolesCheckbox[userRole] =
            //     //       userRoles.indexOf(userRole) !== -1;
            //     //   }
            //     // }
            //   }
            // }
            this.setState(
              {
                isSavingUserPermissions: false,
                userAgencies: userAgencies,
                userRoles: data.data,
                administratorRoles: administratorRoles,
                administratorRolesCheckbox: administratorRolesCheckbox,
                superuserRole: superuserRole,
                superuserRoleCheckbox: superuserRoleCheckbox,
                userRolesLoading: false,
              },
              () => {
                if (
                  !(
                    userAgencies !== null &&
                    userAgencies.length === 1 &&
                    userAgencies[0] === "AV"
                  )
                ) {
                  this.setState({ showAvailabilityAndQualifications: true });
                } else {
                  this.setState({ showAvailabilityAndQualifications: false });
                }
                this.checkProfileAndGroupViewPermissions();
              }
            );
          } else {
            let ErrorMessage =
              copy.admin.userDetailsView.getUserInfoAPIErrorMessage +
              ` (Error #${copy.errorCodes.getUserInfoAPIErrorMessage})`;
            // if (data && data.data && data.data.SASMessageClient) {
            //   ErrorMessage = data.data.SASMessageClient;
            // }
            this.setState(
              {
                isSavingUserPermissions: false,
                errorMessage: ErrorMessage,
                userRoles: [],
                administratorRoles: [],
                administratorRolesCheckbox: {},
                superuserRole: false,
                superuserRoleCheckbox: false,
                showAvailabilityAndQualifications: false,
              },
              () => {
                setTimeout(
                  function () {
                    this.setState({ errorMessage: null });
                  }.bind(this),
                  5000
                );
                this.checkProfileAndGroupViewPermissions();
              }
            );
          }
        });
      } else {
        let ErrorMessage =
          copy.admin.userDetailsView.getUserInfoAPIErrorMessage +
          ` (Error #${copy.errorCodes.getUserInfoAPIErrorMessage})`;
        // if (data && data.data && data.data.SASMessageClient) {
        //   ErrorMessage = data.data.SASMessageClient;
        // }
        this.setState(
          {
            errorMessage: ErrorMessage,
            hasSSOAccount: null,
            showAvailabilityAndQualifications: false,
          },
          () => {
            setTimeout(
              function () {
                this.setState({ errorMessage: null });
              }.bind(this),
              5000
            );
          }
        );
      }
    });
  };

  /**
   * This function will reset the user's password by sending them an email
   */
  handleResetPasswordClick = () => {
    this.Requests.callAPI(
      this.Requests.resetUserPassword,
      this.state.userId
    ).then((data) => {
      if (data && data.status && data.status === 200) {
        this.setState(
          {
            passwordStatus: true,
          },
          () => {
            setTimeout(
              function () {
                this.setState({ passwordStatus: null });
              }.bind(this),
              3000
            );
          }
        );
      } else {
        let ErrorMessage =
          copy.admin.userDetailsView.resetPasswordAPIErrorMessage +
          ` (Error #${copy.errorCodes.resetPasswordAPIErrorMessage})`;
        // if (data && data.data && data.data.SASMessageClient) {
        //   ErrorMessage = data.data.SASMessageClient;
        // }
        this.setState(
          {
            errorMessage: ErrorMessage,
            passwordStatus: false,
          },
          () => {
            setTimeout(
              function () {
                this.setState({ errorMessage: null, passwordStatus: null });
              }.bind(this),
              5000
            );
          }
        );
      }
    });
  };

  /**
   * Deactivates the selected user's account
   */
  deactivateUserAccount = () => {
    this.Requests.callAPI(
      this.Requests.deactivateUserAccount,
      this.state.userId
    ).then((data) => {
      if (data && data.status && data.status === 200) {
        const updatedUsername = `${this.state.userName} (Deactivated)`;
        this.setState(
          {
            deactivationStatus: true,
            userName: updatedUsername,
          },
          () => {
            setTimeout(
              function () {
                this.setState({ deactivationStatus: null });
                this.getUserProfile();
              }.bind(this),
              3000
            );
          }
        );
      } else {
        let ErrorMessage =
          copy.admin.userDetailsView.userDeactivationAPIErrorMessage +
          ` (Error #${copy.errorCodes.userDeactivationAPIErrorMessage})`;
        // if (data && data.data && data.data.SASMessageClient) {
        //   ErrorMessage = data.data.SASMessageClient;
        // }
        this.setState(
          {
            errorMessage: ErrorMessage,
            deactivationStatus: false,
          },
          () => {
            setTimeout(
              function () {
                this.setState({ errorMessage: null, deactivationStatus: null });
              }.bind(this),
              5000
            );
          }
        );
      }
    });
  };
  /**
   * Reactivates the selected user's account
   */
  reactivateUserAccount = () => {
    this.Requests.callAPI(
      this.Requests.reactivateUserAccount,
      this.state.userId
    ).then((data) => {
      if (data && data.status && data.status === 200) {
        const updatedUsername = this.state.userName.replace(
          " (Deactivated)",
          ""
        );
        this.setState(
          {
            reactivationStatus: true,
            userName: updatedUsername,
          },
          () => {
            setTimeout(
              function () {
                this.setState({ reactivationStatus: null });
                this.getUserProfile();
              }.bind(this),
              3000
            );
          }
        );
      } else {
        let ErrorMessage =
          copy.admin.userDetailsView.userReactivationAPIErrorMessage +
          ` (Error #${copy.errorCodes.userReactivationAPIErrorMessage})`;
        // if (data && data.data && data.data.SASMessageClient) {
        //   ErrorMessage = data.data.SASMessageClient;
        // }
        this.setState(
          {
            errorMessage: ErrorMessage,
            reactivationStatus: false,
          },
          () => {
            setTimeout(
              function () {
                this.setState({ errorMessage: null, reactivationStatus: null });
              }.bind(this),
              5000
            );
          }
        );
      }
    });
  };
  /**
   * Permanently deletes the selected user's account
   */
  permanentDeleteUserAccount = () => {
    this.Requests.callAPI(
      this.Requests.permanentlyDeactivateUserAccount,
      this.state.userId
    ).then((data) => {
      if (data?.status === 200) {
        this.setState(
          {
            permanentDeleteStatus: true,
            permanentDeleteConfirmation: false,
          },
          () => {
            setTimeout(
              function () {
                this.setState({ permanentDeleteStatus: null });
                this.getUserProfile();
              }.bind(this),
              3000
            );
          }
        );
      } else {
        let ErrorMessage =
          copy.admin.userDetailsView.userPermanentDeleteAPIErrorMessage +
          ` (Error #${copy.errorCodes.userPermanentDeleteAPIErrorMessage})`;
        // if (data && data.data && data.data.SASMessageClient) {
        //   ErrorMessage = data.data.SASMessageClient;
        // }
        this.setState(
          {
            errorMessage: ErrorMessage,
            permanentDeleteStatus: false,
            permanentDeleteConfirmation: false,
          },
          () => {
            setTimeout(
              function () {
                this.setState({
                  errorMessage: null,
                  permanentDeleteStatus: null,
                });
              }.bind(this),
              5000
            );
          }
        );
      }
    });
  };
  /**
   * Triggered when the user clicks on permanent delete and will display a confirmation box
   */
  confirmPD = () => {
    this.setState({
      permanentDeleteConfirmation: true,
    });
  };

  /**
   * Closes the permanent delete confirmation box
   */
  closePD = () => {
    this.setState({
      permanentDeleteConfirmation: false,
    });
  };

  // FUNCTIONS FOR TURNOUT ACCESS SECTION
  /**
   * Called to handle turnout toggle changes
   */
  toggleTurnout = () => {
    this.setState(
      (prevState) => {
        return {
          showTurnout: !prevState.showTurnout,
        };
      },
      () => {
        this.handleSaveTurnoutAccessClick();
      }
    );
  };

  /**
   * API call to update Turnout Access
   */
  handleSaveTurnoutAccessClick = () => {
    this.setState({ isSavingTurnoutAccess: true }, () => {
      this.Requests.callAPI(
        this.Requests.updateTurnoutAccess,
        this.state.showTurnout,
        this.state.userId
      ).then((data) => {
        if (data.status && data.status === 200) {
          this.setState({ isSavingTurnoutAccess: false });
          this.getUserProfile();
        } else {
          let ErrorMessage =
            copy.admin.userDetailsView.getUserInfoAPIErrorMessage +
            ` (Error #${copy.errorCodes.getUserInfoAPIErrorMessage})`;
          this.setState({ loading: false, errorMessage: ErrorMessage }, () => {
            setTimeout(
              function () {
                this.setState({ errorMessage: null });
              }.bind(this),
              5000
            );
          });
        }
      });
    });
  };

  // FUNCTIONS FOR USER ACCESS SECTION
  /**
   * This function opens up the permission accordion
   */
  handleEditUserPermissionsClick = () => {
    this.setState({ userPermissionsExpanded: true });
  };

  /**
   * This function handles what happens when the user clicks the save button when they change user permissions
   */
  handleSaveUserPermissionsClick = () => {
    // Handle when a adminstrator role has been added/removed
    this.setState({ isSavingUserPermissions: false }, () => {
      let UserRolesArray = [];
      // Maps over admin roles and pushes all roles that are checked to userRolesArray
      // eslint-disable-next-line
      Object.entries(this.state.administratorRolesCheckbox).map((role) => {
        if (role[1]) {
          UserRolesArray.push(role[0]);
        }
      });

      let body;
      if (this.state.superuserRole) {
        if (
          this.state.superuserRoleCheckbox && // check if the super user checkbox is selected
          this.state.superuserRole // check if the current user is a super user
        ) {
          body = {
            roles: ["SuperUser"],
          };
        } else {
          body = {
            roles: UserRolesArray,
          };
        }
      } else {
        body = {
          roles: UserRolesArray,
        };
      }

      if (!body.roles.length) {
        this.setState({
          isSavingUserPermissions: false,
          userRolesError: true,
        });
      } else {
        this.Requests.callAPI(
          this.Requests.addUserRoles,
          this.state.userId,
          body
        ).then((data) => {
          if (data && data.status && data.status === 200) {
            this.getUserRoles();
            this.setState({
              userRolesError: false,
            });
          } else {
            let ErrorMessage =
              copy.admin.userDetailsView.addRoleAPIErrorMessage +
              ` (Error #${copy.errorCodes.addRoleAPIErrorMessage})`;
            // if (data && data.data && data.data.SASMessageClient) {
            //   ErrorMessage = data.data.SASMessageClient;
            // }
            this.setState(
              { errorMessage: ErrorMessage, userRolesError: false },
              () => {
                setTimeout(
                  function () {
                    this.setState({ errorMessage: null });
                  }.bind(this),
                  5000
                );
              }
            );
            this.getUserRoles();
          }
        });
      }
    });
  };

  /**
   * This function renders the user access accordion
   */
  handleAdminRoleUpdate = (item) => {
    let agency = item.split("_")[1];
    let admin = `Administrator_${agency}`;
    let manager = `Manager_${agency}`;
    let user = `User_${agency}`;

    this.setState(
      (prevState) => ({
        userRolesError: false,
        administratorRolesCheckbox: {
          ...prevState.administratorRolesCheckbox,
          [admin]:
            item !== admin
              ? false
              : this.state.administratorRolesCheckbox[admin],
          [manager]:
            item !== manager
              ? false
              : this.state.administratorRolesCheckbox[manager],
          [user]:
            item !== user ? false : this.state.administratorRolesCheckbox[user],
        },
      }),
      () => {
        this.setState((prevState) => ({
          superuserRoleCheckbox:
            !prevState.administratorRolesCheckbox[item] === true && false,
          administratorRolesCheckbox: {
            ...prevState.administratorRolesCheckbox,
            [item]: !prevState.administratorRolesCheckbox[item],
          },
        }));
      }
    );
  };

  renderUserAccess = () => {
    let administratorRoles;
    if (
      this.state.administratorRoles !== null &&
      this.state.administratorRolesCheckbox !== null
    ) {
      if (this.state.administratorRoles.length === 0) {
        administratorRoles = false;
      } else {
        // Creating checkboxes for the administrator roles the user can escalate them to
        administratorRoles = this.state.administratorRoles.map(
          (item, index) => {
            return (
              <li key={index}>
                <Checkbox
                  name={item.replace(/_/g, " ")}
                  label={item.replace(/_/g, " ")}
                  checked={
                    this.state.superuserRole && this.state.superuserRoleCheckbox
                      ? false
                      : this.state.administratorRolesCheckbox[item]
                  }
                  onChange={() => {
                    this.handleAdminRoleUpdate(item);
                  }}
                />
              </li>
            );
          }
        );
      }
    }

    // Create a checkbox for superuser role (if the user has permission to do so)
    let superuserRole;
    if (
      this.state.superuserRole !== null &&
      this.state.superuserRole === true &&
      this.state.superuserRoleCheckbox !== null
    ) {
      superuserRole = (
        <li key={0}>
          <Checkbox
            name={"Superuser"}
            label={"Superuser"}
            checked={this.state.superuserRoleCheckbox}
            onChange={() => {
              this.setState((prevState) => ({
                userRolesError: false,
                superuserRoleCheckbox: !prevState.superuserRoleCheckbox,
              }));
            }}
          />
        </li>
      );
    } else {
      superuserRole = false;
    }

    return (
      <div className="box_default">
        <div className="Sub-Section-titles">
          <h3>{copy.admin.userAccess.title}</h3>
        </div>
        <ul className="settingOptions">
          <li>
            <div className={styles.userPermissions}>
              <div>
                <span className="primaryText">
                  {copy.admin.userAccess.userPermissions}
                </span>
                <span className="subText">
                  {!superuserRole && !administratorRoles
                    ? copy.admin.userAccess.userPermissionsDenied
                    : copy.admin.userAccess.userPermissionsDesc}
                </span>
              </div>
              {!superuserRole && !administratorRoles ? null : this.state
                  .userPermissionsExpanded ? (
                <Button
                  label="Save"
                  content="Save"
                  variant="btn_secondary"
                  styles=" "
                  icon=" "
                  buttonClick={this.handleSaveUserPermissionsClick}
                />
              ) : (
                <Button
                  label="Edit"
                  content="Edit"
                  variant="btn_secondary"
                  styles=" "
                  icon=" "
                  buttonClick={this.handleEditUserPermissionsClick}
                />
              )}
            </div>
            {this.state.userRolesError && (
              <span className="API_error_text">
                {copy.admin.userAccess.userPermissionMinimum}
              </span>
            )}
            {this.state.userPermissionsExpanded ? (
              <ul className={styles.userRoles}>
                {this.state.isSavingUserPermissions ? (
                  <div className={styles.loadingContainer}>
                    <Loader />
                  </div>
                ) : (
                  <Wrapper>
                    {superuserRole}
                    {administratorRoles}
                  </Wrapper>
                )}
              </ul>
            ) : null}
          </li>
        </ul>
      </div>
    );
  };

  // FUNCTIONS FOR CREATING A NEW CHAT
  createNewMessage = () => {
    this.setState({
      createNewMessage: true,
    });
  };
  closeNewMessage = () => {
    this.setState({
      createNewMessage: false,
    });
  };

  /**
   * Get user profile data
   */
  getUserProfile() {
    // add API call
    this.Requests.callAPI(
      this.Requests.getOtherUsersProfile,
      this.state.userId,
      true
    ).then((data) => {
      if (data && data.status && data.status === 200) {
        this.setState({
          userProfileData: data.data,
          showTurnout: data.data.canAccessTurnoutDashboard,
          isShowReactivate: data.data.deactivated,
        });
      } else {
        let ErrorMessage =
          copy.admin.userDetailsView.getUserProfileAPIErrorMessage +
          ` (Error #${copy.errorCodes.getUserProfileAPIErrorMessage})`;
        this.setState({ errorMessage: ErrorMessage }, () => {
          setTimeout(
            function () {
              this.setState({ errorMessage: null });
            }.bind(this),
            5000
          );
        });
      }
    });
  }

  /**
   * Render user profile view
   */
  renderProfile() {
    let username = "Not Available";
    let accountType = "Not Available";
    let userAgency = "Not Available";
    let userRole = "Not Available";
    let userEmail = "Not Available";
    let userLocation = "Not Available";
    let lastLogin = "Not Available";
    let concurrentDevices = "None";

    if (this.state.userProfileData) {
      if (
        this.state.userProfileData.nameFirst &&
        this.state.userProfileData.nameLast
      ) {
        username =
          this.state.userProfileData.nameFirst +
          " " +
          this.state.userProfileData.nameLast;
      }
      if (this.state.userProfileData.joinedVia) {
        accountType = this.state.userProfileData.joinedVia;
      }
      if (this.state.userProfileData.agencies) {
        userAgency = "";
        for (let i = 0; i < this.state.userProfileData.agencies.length; i++) {
          if (this.state.userProfileData.agencies[i] !== "SAS_DYNGRP") {
            userAgency += this.state.userProfileData.agencies[i] + ", ";
          }
        }
        userAgency = userAgency.substring(0, userAgency.length - 2);
      }
      if (this.state.userProfileData.userRoles) {
        userRole = "";
        for (let i = 0; i < this.state.userProfileData.userRoles.length; i++) {
          userRole +=
            this.state.userProfileData.userRoles[i].replace("_", " ") + ", ";
        }
        userRole = userRole.substring(0, userRole.length - 2);
      }
      if (this.state.userProfileData.emails) {
        userEmail = this.state.userProfileData.emails.join(", ");
      }
      if (this.state.userProfileData.locations) {
        userLocation = this.state.userProfileData.locations.join(", ");
        if (userLocation === "") {
          userLocation = "No Location";
        }
      }
      if (this.state.userProfileData.lastLoggedIn) {
        let datetime = new Date(this.state.userProfileData.lastLoggedIn);
        lastLogin = format(datetime, "yyyy-MM-dd HH:mm");
      }
      if (this.state.userProfileData.concurrentDevices) {
        concurrentDevices = this.state.userProfileData.concurrentDevices;
      }
    }

    return (
      <div className="box_default">
        <Accordion>
          <div key="0001" label={copy.admin.userDetailsView.profileSection}>
            <ul className={styles.profile}>
              <li>
                <b>{copy.admin.userDetailsView.userName}</b>
                {username}
              </li>
              <li>
                <b>{copy.admin.userDetailsView.accountType}</b>
                {accountType}
              </li>
              <li>
                <b>{copy.admin.userDetailsView.userAgency}</b>
                {userAgency}
              </li>
              <li>
                <b>{copy.admin.userDetailsView.userRole}</b>
                {userRole}
              </li>
              <li>
                <b>{copy.admin.userDetailsView.userEmail}</b>
                {userEmail}
              </li>
              <li>
                <b>{copy.admin.userDetailsView.userLocation}</b>
                {userLocation}
              </li>
              <li>
                <b>{copy.admin.userDetailsView.lastLogin}</b>
                {lastLogin}
              </li>
              <li>
                <b>{copy.admin.userDetailsView.userConcurrentDevices}</b>
                {concurrentDevices}
              </li>
            </ul>
            <div></div>
          </div>
        </Accordion>

        <p>{copy.admin.userDetailsView.profileDesc}</p>
      </div>
    );
  }

  /**
   * Closes the reset ASN confirmation box
   */
  closeResetASN = () => {
    this.setState({
      resetASN: false,
    });
  };

  /**
   * API call to hard reset all of the user's linked ASNs after the user confirms it
   */
  DeleteAllASN = () => {
    this.Requests.callAPI(
      this.Requests.deleteAllAdminASN,
      this.state.userId
    ).then((data) => {
      if (data && data.status && data.status === 200) {
        this.getUserASNList();
      } else {
        let ErrorMessage =
          copy.settings.profile.deleteAllViperProfiles +
          ` (Error #${copy.errorCodes.deleteAllViperProfiles})`;
        this.setState({ errorMessage: ErrorMessage }, () => {
          setTimeout(
            function () {
              this.setState({ errorMessage: null });
            }.bind(this),
            5000
          );
        });
      }
      this.setState({
        resetASN: false,
      });
    });
  };

  /**
   * Triggered when the user clicks on reset ASN button and will display a confirmation box
   */
  resetASN = () => {
    this.setState({
      resetASN: true,
    });
  };

  /**
   * to check if ASN limit has been met
   */
  checkASNLimit = () => {
    // get the agencies that the searched user is in
    let searchedUserAgencies = [];

    if (this.state.userRoles.indexOf("SuperUser") !== -1) {
      searchedUserAgencies = ["AV", "CFA", "SES"];
    } else {
      searchedUserAgencies = this.state.userAgencies;
    }
    // Keeps track of maximum ASN limit.
    let limit = 0;
    // Whether the limit has been reached by current number of ASNs entered.
    let asnLimitReached = false;
    // Goes through each of user's agencies to get limits for each.
    searchedUserAgencies.forEach((agency) => {
      // User should have a maximum of one ASN profile per agency
      limit = limit + this.state.asnLimits[agency];
    });

    // If the user's amount of ASN's is equal or over the limit.
    if (this.state.viperProfile && this.state.viperProfile.length >= limit) {
      asnLimitReached = true;
    }
    this.setState({
      asnLimitReached: asnLimitReached,
    });
  };

  /**
   * API put method to delete ASN from user profile
   */
  deleteASN = (viperProfileID, viperProfileIndex) => {
    // Adds loading state to ASN being deleted while request is made
    this.setState(
      (state) => {
        const loadingState = state.loadingASN.map((item, j) => {
          if (j === viperProfileIndex) {
            return (state.loadingASN[viperProfileIndex] = true);
          } else {
            return item;
          }
        });
        return {
          loadingState,
        };
      },
      () => {
        this.Requests.callAPI(this.Requests.deleteAdminVIPERProfile, {
          viperId: viperProfileID,
          userId: this.state.userId,
        }).then((data) => {
          if (data && data.status && data.status === 200) {
            this.getUserPagingGroups();
            this.getUserASNList();
            // window.localStorage.setItem("updateAgencyAndGroups", "true");
          } else {
            let ErrorMessage =
              copy.settings.profile.deleteViperProfile +
              ` (Error #${copy.errorCodes.deleteViperProfile})`;
            // if (data && data.data && data.data.SASMessageClient) {
            //   ErrorMessage = data.data.SASMessageClient;
            // }
            this.setState({ errorMessage: ErrorMessage }, () => {
              setTimeout(
                function () {
                  this.setState({ errorMessage: null });
                }.bind(this),
                5000
              );
            });
            this.getUserASNList();
          }
        });
      }
    );
  };

  /**
   * API put method to add ASN to user profile
   */
  addASN = (index) => {
    let ASN;

    const input1 = document.getElementById("ASNInput1")
      ? document.getElementById("ASNInput1").value.toUpperCase()
      : null;

    if (input1) {
      ASN = input1;
    }
    //Check if ASN already exist then display error message
    let isDuplicate = false;

    for (var profile in this.state.viperProfile) {
      for (var asn in this.state.viperProfile[profile].pagers) {
        if (input1 === this.state.viperProfile[profile].pagers[asn].ASN) {
          isDuplicate = true;
        }
      }
    }

    if (isDuplicate) {
      this.setState({ duplicateASN: true }, () => {
        setTimeout(
          function () {
            this.setState({ duplicateASN: false });
          }.bind(this),
          5000
        );
      });
    } else {
      // Adds loading state to ASN being deleted while request is made
      this.setState(
        (state) => {
          const loadingState = state.loadingASN.map((item, j) => {
            if (j === index) {
              return (state.loadingASN[index] = true);
            } else {
              return item;
            }
          });
          return {
            loadingState,
          };
        },
        () => {
          // This function has error handling for PUT, POST & DELETE requests.
          // This function's error handling will NOT work for GET requests
          this.Requests.callAPI(this.Requests.sendSingleUserASNAdmin, {
            asn: ASN,
            userId: this.state.userId,
          }).then((data) => {
            if (data && data.status && data.status === 200) {
              this.getUserASNList();
              this.getUserPagingGroups();
            } else {
              let ErrorMessage = copy.settings.profile.addViperProfileError;
              if (data && data.data && data.data.SASMessageClient) {
                ErrorMessage = data.data.SASMessageClient;
                if (data.data.SASENumErrorCode === 17) {
                  this.setState({
                    invalidASN: copy.settings.profile.invalidASN,
                  });
                } else {
                  this.setState({
                    invalidASN: data.data.SASMessageClient,
                  });
                }
              }
              this.setState({ errorMessage: ErrorMessage }, () => {
                setTimeout(
                  function () {
                    this.setState({
                      errorMessage: null,
                      invalidASN: false,
                    });
                  }.bind(this),
                  5000
                );
              });
              this.getUserASNList();
            }
          });
        }
      );
    }
  };

  /**
   * Get user ASN list
   */
  getUserASNList = () => {
    this.Requests.callAPI(
      this.Requests.getOtherUsersASN,
      this.state.userId
    ).then((data) => {
      if (data && data.status && data.status === 200) {
        this.setState(
          {
            loadingASN: [false, false, false],
            viperProfile: data.data,
          },
          () => {
            // Checks if user has exceeded ASN limit.
            this.checkASNLimit();
          }
        );
      } else {
        let ErrorMessage =
          copy.settings.profile.getASNAPIErrorMessage +
          ` (Error #${copy.errorCodes.getASNAPIErrorMessage})`;
        // if (data && data.data && data.data.SASMessageClient) {
        //   ErrorMessage = data.data.SASMessageClient;
        // }
        this.setState(
          { errorMessage: ErrorMessage, viperProfile: false },
          () => {
            setTimeout(
              function () {
                this.setState({ errorMessage: null });
              }.bind(this),
              5000
            );
          }
        );
      }
    });
  };

  renderASN() {
    return (
      <div className="box_default">
        <div className="Sub-Section-titles navigation">
          <h3>{copy.admin.userDetailsView.pagers}</h3>
          {/* show ASN reset button */}
          {this.state.viperProfile && (
            <div
              style={
                this.state.resetASN
                  ? { visibility: "hidden" }
                  : { visibility: "visible" }
              }
            >
              <Button
                label="reset"
                content={copy.global.btnReset}
                variant="btn_outline red"
                styles="redTxt"
                buttonClick={this.resetASN}
              />
            </div>
          )}
        </div>
        {this.state.resetASN ? (
          /* reset ASN confirmation box */
          <div>
            <p>{copy.admin.userDetailsView.resetASNConfirmation}</p>
            <div className={styles.resetASNModal}>
              <Button
                label="Reset ASN"
                buttonClick={this.DeleteAllASN}
                content="Reset ASN"
                variant="btn_outline"
                styles="red"
              />
              <Button
                label="Close"
                buttonClick={this.closeResetASN}
                content="Close"
                variant="btn_outline grey"
                styles=""
              />
            </div>
          </div>
        ) : (
          /* ASN linking, removing and listing of linked ASNs */
          <Wrapper>
            <p>{copy.admin.userDetailsView.pagersDesc}</p>
            {this.state.viperProfile === null ? (
              <Loader />
            ) : this.state.viperProfile ? (
              <Wrapper>
                {/* Only show ASN input field if currently below ASN limit. */}
                {this.state.asnLimitReached ? null : (
                  <div
                    id="ASNAdd"
                    className={styles.content + " " + styles.addASN}
                  >
                    <div>
                      <input
                        className="default_input"
                        id="ASNInput1"
                        type="text"
                        placeholder="Empty"
                        maxLength="10"
                      />
                      {this.state.duplicateASN && (
                        <div className={styles.duplicate_ASN_error}>
                          {copy.settings.profile.duplicateASN}
                        </div>
                      )}
                      {this.state.invalidASN && (
                        <div className={styles.duplicate_ASN_error}>
                          {this.state.invalidASN}
                        </div>
                      )}
                    </div>
                    <Button
                      label="save ASN"
                      content={copy.global.btnSave}
                      variant="btn_outline"
                      styles="greenTxt"
                      buttonClick={() => this.addASN(0)}
                    />
                  </div>
                )}
                {/* Loop through every viper profile - upto 3 */}
                {this.state.viperProfile.map((value, viperProfileIndex) => {
                  return (
                    <div key={viperProfileIndex} className={styles.content}>
                      <span className={styles.title + " txt2"}>
                        Profile {viperProfileIndex + 1}
                      </span>
                      <div className={styles.viperProfiles}>
                        {/* Loops through all the pagers with this VIPER profile */}
                        <div>
                          {value.pagers.map((asnObject, asnIndex) => {
                            return (
                              <div className={styles.asn} key={asnIndex}>
                                {asnObject.ASN}
                                <br />
                                {asnObject.name}
                              </div>
                            );
                          })}
                        </div>
                        {this.state.loadingASN[viperProfileIndex] ? (
                          <Loader />
                        ) : (
                          <Button
                            label="remove ASN"
                            content={copy.global.btnRemove}
                            variant="btn_outline red"
                            styles="redTxt"
                            buttonClick={() =>
                              this.deleteASN(value.id, viperProfileIndex)
                            }
                          />
                        )}
                      </div>
                    </div>
                  );
                })}
              </Wrapper>
            ) : (
              <div
                className={
                  styles.content +
                  " " +
                  styles.addASN +
                  " " +
                  styles.API_error_text
                }
              >
                <div>
                  {copy.settings.profile.getASNAPIErrorMessage +
                    ` (Error #${copy.errorCodes.getASNAPIErrorMessage})`}
                </div>
              </div>
            )}
          </Wrapper>
        )}
      </div>
    );
  }
  renderAccesstoTurnout() {
    return (
      <div className="box_default">
        <div className="Sub-Section-titles">
          <h3>{copy.admin.turnoutAccess.title}</h3>
        </div>
        <ul className="settingOptions">
          <li>
            <div className={styles.userPermissions}>
              <div>
                <span className="primaryText">
                  {copy.admin.turnoutAccess.userPermissions}
                </span>
                <span className="subText">
                  {copy.admin.turnoutAccess.userPermissionsDesc}
                </span>
              </div>
              <Toggle
                key="11"
                variant="toggle"
                checked={this.state.showTurnout}
                buttonClick={this.toggleTurnout}
                label="show or hide Turnout tab"
                fromAdmin
              />
            </div>
          </li>
        </ul>
        {this.state.isSavingTurnoutAccess && (
          <div className={styles.loadingContainer}>
            <Loader />
          </div>
        )}
      </div>
    );
  }

  checkProfileAndGroupViewPermissions() {
    // check user role permissions for what agency tiles they can see
    if (this.props.userRoleAgency) {
      let hasProfilePermission = false;
      let hasPagingGroupPermission = false;

      // get the agencies that the searched user is in
      let searchedUserAgencies = [];
      let validAgencies = [];

      if (this.state.userRoles.indexOf("SuperUser") !== -1) {
        searchedUserAgencies = ["AV", "CFA", "SES"];
      } else {
        searchedUserAgencies = this.state.userAgencies;
      }

      if (this.props.userRoleAgency.indexOf("SuperUser") !== -1) {
        // superusers has access
        hasProfilePermission = true;
        hasPagingGroupPermission = true;
        validAgencies = searchedUserAgencies;
      } else {
        // check individual agency permissions
        for (let i = 0; i < searchedUserAgencies.length; i++) {
          if (
            this.props.userRoleAgency.indexOf(
              "Manager_" + searchedUserAgencies[i]
            ) !== -1 ||
            this.props.userRoleAgency.indexOf(
              "Administrator_" + searchedUserAgencies[i]
            ) !== -1
          ) {
            hasProfilePermission = true;
            hasPagingGroupPermission = true;
            validAgencies.push(searchedUserAgencies[i]);
          }
        }
      }

      this.setState(
        {
          showProfileView: hasProfilePermission,
          showPagingGroupView: hasPagingGroupPermission,
          validAgencies: validAgencies,
        },
        () => {
          if (this.state.showProfileView) {
            this.getUserProfile();
            this.getUserASNList();
          }
          if (this.state.showPagingGroupView) {
            this.getUserPagingGroups();
          }
        }
      );
    }
  }

  /**
   * Get user paging groups
   */
  getUserPagingGroups() {
    // add API call
    let pagingGroups = [];
    this.setState({
      groupsLoading: true,
    });
    this.Requests.callAPI(
      this.Requests.getOtherUserPagingGroups,
      this.state.userId
    ).then((data) => {
      if (data && data.status && data.status === 200) {
        if (data.data && data.data.pagingGroups) {
          // eslint-disable-next-line
          data.data.pagingGroups.map((item) => {
            // do not include DYNAMIC paging groups
            if (
              item.pagingNumber &&
              item.pagingName &&
              item.groupAgency !== "DYNAMIC"
            ) {
              pagingGroups.push({
                number: item.pagingNumber,
                name: item.pagingName.replace(/_/g, " "),
                owner: item.isGroupOwner,
                showRemoveModal: false,
                isMember: item.isMember,
              });
            }
          });
        }
      } else {
        let ErrorMessage =
          copy.admin.userDetailsView.getOtherUserPagingGroupsAPIErrorMessage +
          ` (Error #${copy.errorCodes.getOtherUserPagingGroupsAPIErrorMessage})`;
        this.setState(
          { errorMessage: ErrorMessage, groupsLoading: false },
          () => {
            setTimeout(
              function () {
                this.setState({ errorMessage: null });
              }.bind(this),
              5000
            );
          }
        );
      }
      this.setState({
        userPagingGroups: pagingGroups,
        groupsLoading: false,
      });
    });
  }

  renderPagingGroupSection() {
    this.groups =
      this.state.userPagingGroups && this.state.userPagingGroups.length > 0 ? (
        this.state.userPagingGroups.map((item, index) => (
          <li key={item.number}>
            <div className={styles.pagingGroupRow}>
              {this.state.errorInRemovingPagingGroup &&
              this.state.errorInRemovingPagingGroup === item.number ? (
                <div className={styles.center}>
                  <p className={styles.fail + " fadeaway"}>
                    {item.name} could not be removed as the user is linked to it
                    via ASN.
                  </p>
                </div>
              ) : item.showRemoveModal ? (
                <div>
                  <p>Do you wish to remove user from {item.name}?</p>
                  <div className={styles.removeGroupModal}>
                    <Button
                      label="Remove"
                      buttonClick={() => {
                        this.removePagingGroup(item.number, item);
                      }}
                      content="Remove"
                      variant="btn_outline"
                      styles="red"
                    />
                    <Button
                      label="Cancel"
                      buttonClick={() => {
                        this.showRemoveGroupModal(index);
                      }}
                      content="Cancel"
                      variant="btn_outline grey"
                      styles=""
                    />
                  </div>
                </div>
              ) : (
                <div className={styles.center}>
                  <span className="primaryText">{item.name}</span>
                  <div className={styles.contents}>
                    <Checkbox
                      name={"Paging Group Owner"}
                      label={"Paging Group Owner"}
                      checked={item.owner}
                      onChange={() => {
                        this.togglePagingGroupOwnerCheck(
                          !item.owner,
                          item.number
                        );
                      }}
                    />
                    <Button
                      label="Remove"
                      content=""
                      variant="btn_noOutline"
                      styles="white no_text"
                      icon="icon_trashred"
                      disabled={!item.isMember}
                      hidden={!item.isMember}
                      buttonClick={() => {
                        this.showRemoveGroupModal(index);
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          </li>
        ))
      ) : (
        <li>
          <div className={styles.pagingGroupRow}>
            <div className={styles.center}>
              <span className="primaryText">
                This user has no paging groups.
              </span>
            </div>
          </div>
        </li>
      );

    return (
      /* ADD SEARCH PAGING GROUP SECTION */
      <ul className={"settingOptions"}>{this.groups}</ul>
    );
  }

  togglePagingGroupOwnerCheck(makeOwner, pagingGroupID) {
    if (makeOwner) {
      this.makeOwner(pagingGroupID);
    } else {
      this.removeOwner(pagingGroupID);
    }
  }

  makeOwner = (pagingGroupID) => {
    this.Requests.callAPI(
      this.Requests.makeUserPagingGroupsOwner,
      pagingGroupID,
      this.state.userId
    ).then((data) => {
      if (data && data.status && data.status === 200) {
        this.getUserPagingGroups();
      } else {
        let ErrorMessage =
          copy.admin.makeOwnerAPIMessageError +
          ` (Error #${copy.errorCodes.makeOwnerAPIMessageError})`;
        this.setState(
          {
            errorMessage: ErrorMessage,
          },
          () => {
            setTimeout(
              function () {
                this.setState({ errorMessage: null });
              }.bind(this),
              5000
            );
          }
        );
      }
    });
  };

  removeOwner = (pagingGroupID) => {
    this.Requests.callAPI(
      this.Requests.removePagingGroupsOwner,
      pagingGroupID,
      this.state.userId
    ).then((data) => {
      if (data && data.status && data.status === 200) {
        this.getUserPagingGroups();
      } else {
        let ErrorMessage =
          copy.admin.removeOwnerAPIMessageError +
          ` (Error #${copy.errorCodes.removeOwnerAPIMessageError})`;
        this.setState(
          {
            errorMessage: ErrorMessage,
          },
          () => {
            setTimeout(
              function () {
                this.setState({ errorMessage: null });
              }.bind(this),
              5000
            );
          }
        );
      }
    });
  };

  addPagingGroup = (pagingGroupID) => {
    if (
      this.state.userPagingGroups.every((group) => {
        if (pagingGroupID === group.number) {
          this.setState({ added: "memberExists" }, () => {
            this.getUserPagingGroups();
          });
          return false;
        }
        return true;
      })
    ) {
      this.Requests.callAPI(
        this.Requests.addUsertoPagingGroup,
        pagingGroupID,
        this.state.userId
      ).then((data) => {
        if (data && data.status && data.status === 200) {
          this.setState({ added: "success" }, () => {
            this.getUserPagingGroups();
            // update props' paging groups if the searched user's ID matches the logged in user's ID
            if (this.state.userId === this.props.userId) {
              window.localStorage.setItem("updateAgencyAndGroups", "true");
            }
          });
        } else {
          let ErrorMessage =
            copy.admin.addMemberAPIMessageError +
            ` (Error #${copy.errorCodes.addMemberAPIMessageError})`;
          this.setState(
            {
              errorMessage: ErrorMessage,
            },
            () => {
              setTimeout(
                function () {
                  this.setState({ errorMessage: null });
                }.bind(this),
                5000
              );
            }
          );
        }
      });
    }
    this.hideAddGroupModal();
  };

  removePagingGroup = (pagingGroupID) => {
    this.Requests.callAPI(
      this.Requests.deleteUserfromPagingGroup,
      pagingGroupID,
      this.state.userId
    ).then((data) => {
      if (data && data.status && data.status === 200) {
        this.getUserPagingGroups();
        this.setState(
          {
            removed: true,
          },
          () => {
            setTimeout(
              function () {
                this.setState({ removed: false });
              }.bind(this),
              5000
            );
          }
        );
        // update props' paging groups if the searched user's ID matches the logged in user's ID
        if (this.state.userId === this.props.userId) {
          window.localStorage.setItem("updateAgencyAndGroups", "true");
        }
      } else {
        // hide remove group modal
        let index = 0;
        for (let i = 0; i < this.state.userPagingGroups.length; i++) {
          if (this.state.userPagingGroups[i].number === pagingGroupID) {
            index = i;
            break;
          }
        }
        this.showRemoveGroupModal(index);

        let ErrorMessage =
          copy.admin.removeMemberAPIMessageError +
          ` (Error #${copy.errorCodes.removeMemberAPIMessageError})`;
        if (data && data.data && data.data.SASENumErrorCode) {
          if (data.data.SASENumErrorCode === 9) {
            ErrorMessage =
              copy.admin.removeMemberAPIMessageErrorASN +
              ` (Error #${copy.errorCodes.removeMemberAPIMessageError})`;
          }
        }
        this.setState(
          {
            errorMessage: ErrorMessage,
            errorInRemovingPagingGroup: pagingGroupID,
          },
          () => {
            setTimeout(
              function () {
                this.setState({
                  errorMessage: null,
                  errorInRemovingPagingGroup: false,
                });
              }.bind(this),
              5000
            );
          }
        );
      }
    });
  };

  showRemoveGroupModal = (index) => {
    let pagingGroups = [...this.state.userPagingGroups];
    pagingGroups[index].showRemoveModal = !pagingGroups[index].showRemoveModal;
    this.setState({
      userPagingGroups: pagingGroups,
    });
  };

  showAddGroupModal = (item) => {
    this.setState({
      showAddGroupConfirmation: true,
      groupToAdd: item,
    });
  };

  hideAddGroupModal = () => {
    this.setState({
      showAddGroupConfirmation: false,
      groupToAdd: false,
    });
  };

  searchChange = (e) => {
    this.setState({ searchValue: e.target.value, added: false }, () => {
      if (this.state.searchValue === "") {
        this.setState({ groupResults: [], updateGroupResults: false });
      } else {
        let body = {
          agency: this.state.validAgencies,
          keywords: [this.state.searchValue],
        };

        this.Requests.callAPI(this.Requests.filterPagingGroups, body).then(
          (data) => {
            if (data && data.status && data.status === 200) {
              this.setState(
                { groupResults: data.data, updateGroupResults: true },
                () => {}
              );
            } else {
              let ErrorMessage =
                copy.admin.filter.filterChangeAPIErrorMessage +
                ` (Error #${copy.errorCodes.filterChangeAPIErrorMessage})`;
              this.setState(
                {
                  errorMessage: ErrorMessage,
                  groupResults: [],
                },
                () => {
                  setTimeout(
                    function () {
                      this.setState({ errorMessage: null });
                    }.bind(this),
                    5000
                  );
                }
              );
            }
          }
        );
      }
    });
  };

  /**
   * This function will update the search results displayed
   * It also provides a confirmation message when member(s) are successfully added
   */
  returnSearchResults() {
    return (
      <div>
        {this.state.added && this.state.added === "success" ? (
          <p className={styles.success + " fadeaway"}>
            {copy.admin.userDetailsView.groupAdded}
          </p>
        ) : this.state.added && this.state.added === "memberExists" ? (
          <p className={styles.fail + " fadeaway"}>
            {copy.admin.userDetailsView.groupExists}
          </p>
        ) : null}
        {this.state.showAddGroupConfirmation ? (
          <div>
            <p>Do you wish to add user to {this.state.groupToAdd.name}?</p>
            <div className={styles.removeGroupModal}>
              <Button
                label="Add"
                buttonClick={() => {
                  this.addPagingGroup(this.state.groupToAdd.number);
                }}
                content="Add"
                variant="btn_outline"
                styles="green"
              />
              <Button
                label="Cancel"
                buttonClick={this.hideAddGroupModal}
                content="Cancel"
                variant="btn_outline grey"
                styles=""
              />
            </div>
          </div>
        ) : this.state.groupResults.length === 0 ? (
          <p>No results found</p>
        ) : (
          <ul className={styles.groupSearchResults}>
            {this.state.groupResults.map((item, key) => {
              return (
                <li
                  key={key}
                  onClick={() => {
                    this.setState({ ready: null });
                    this.showAddGroupModal(item);
                  }}
                >
                  {item.name}
                </li>
              );
            })}
          </ul>
        )}
      </div>
    );
  }

  render() {
    // Check which agency was searched for
    let agencySearchedFor =
      this.props.searchData && this.props.searchData.agency
        ? this.props.searchData.agency
        : false;

    // Flags for if the logged in user is an admin in each agency
    let isValidAdmin = {
      AV: false,
      CFA: false,
      SES: false,
    };

    // Flags for if the logged in user is a manager in each agency
    let isValidManager = {
      AV: false,
      CFA: false,
      SES: false,
    };

    if (agencySearchedFor && agencySearchedFor.length) {
      // Check if the logged in user is a Admin in the searched agencies or a Manager in the searched agencies
      for (let i = 0; i < agencySearchedFor.length; i++) {
        if (
          this.props.userRoleAgency.indexOf(
            "Administrator_" + agencySearchedFor[i]
          ) !== -1
        ) {
          isValidAdmin[agencySearchedFor[i]] = true;
        }
        if (
          this.props.userRoleAgency.indexOf(
            "Manager_" + agencySearchedFor[i]
          ) !== -1
        ) {
          isValidManager[agencySearchedFor[i]] = true;
        }
      }
    }

    // Determine whether the password reset should be displayed for this agency, Admins of that agency and SuperUsers can reset password
    let shouldDisplayPasswordReset = false;
    let shouldDisplayDeactivateUserAccount = false;
    let shouldDisplayReactivateAndPD = false;

    // If the logged in user is a SuperUser, they can perform password reset and deactivate user accounts
    if (
      this.props.userRoleAgency &&
      this.props.userRoleAgency.indexOf("SuperUser") !== -1
    ) {
      shouldDisplayPasswordReset = true;
      shouldDisplayDeactivateUserAccount = true;
      shouldDisplayReactivateAndPD = true;
    } else {
      let selectedUserAgencies;

      if (
        this.state.userRoles &&
        this.state.userRoles.indexOf("SuperUser") !== -1
      ) {
        selectedUserAgencies = ["AV", "CFA", "SES"];
      } else if (this.state.userAgencies && this.state.userAgencies.length) {
        selectedUserAgencies = this.state.userAgencies;
      }

      if (selectedUserAgencies) {
        // Iterate through selected user's agencies and compare these to the logged in user's permissions to determine what to display
        for (let i = 0; i < selectedUserAgencies.length; i++) {
          if (isValidAdmin[selectedUserAgencies[i]]) {
            shouldDisplayPasswordReset = true;
            shouldDisplayDeactivateUserAccount = true;
            shouldDisplayReactivateAndPD = true;
          } else if (isValidManager[selectedUserAgencies[i]]) {
            shouldDisplayDeactivateUserAccount = true;
          }
        }
      }
    }

    const isAllowedOneOnOneChat = this.canOneOnOneChat();

    return (
      <Wrapper>
        <ErrorBanner
          isVisible={this.state.errorMessage ? true : false}
          ErrorMessage={this.state.errorMessage}
          onTop={true}
        />
        <div className={"columnTab " + styles.adminColumn}>
          <div className="Section-titles">
            <h2>{this.props.user && this.state.userName}</h2>
            <div>
              {isAllowedOneOnOneChat ? (
                this.state.createNewMessage ? (
                  <Button
                    label="Back"
                    content="Back"
                    variant="btn_secondary"
                    styles="green"
                    icon="icon_chat"
                    buttonClick={this.closeNewMessage}
                  />
                ) : (
                  <Button
                    label="Chat"
                    content="Chat"
                    variant="btn_secondary"
                    styles="green"
                    icon="icon_chat"
                    buttonClick={this.createNewMessage}
                  />
                )
              ) : null}
            </div>
          </div>
          {this.state.qualsLoading ||
          this.state.userSSOAccountLoading ||
          this.state.userRolesLoading ? (
            <div className={styles.loadingContainer}>
              <Loader />
            </div>
          ) : (
            <Wrapper>
              <div className={styles.scrollableContent}>
                {this.state.createNewMessage ? (
                  <NewChat
                    location="Admin"
                    newMessageUsers={[this.state.userId]}
                    chatRefresh={this.handleRefresh}
                  ></NewChat>
                ) : (
                  <Wrapper>
                    {this.state.showAvailabilityAndQualifications === null ? (
                      // Show loader while API call to determine whether user is only part of AV is happening
                      <div className={styles.loadingContainer}>
                        <Loader />
                      </div>
                    ) : !this.state.showAvailabilityAndQualifications ? null : (
                      // Only show availability and qualifications if user is not only part of AV
                      <Wrapper>
                        <Tabs noHistory>
                          <div content="7 days" variant="btn_solid">
                            {this.state.refresh === true ? (
                              <WeekView
                                viewOnlyMode={true} // When viewing someone else profile, you are NOT allow to set a new time, hence View only mode true
                                parentCallback="" // This is only needed when you need to add/edit/ delete a timeslot
                                update={true}
                                id={this.state.userId} // Sending the id to this component will tell it to render for a selected user and not the logged in user
                              />
                            ) : (
                              <Loader />
                            )}
                          </div>
                          <div content="MONTH" variant="btn_solid">
                            <Calendar
                              type="personal" // show a calender for a person (repeat or single classes) NOT threshold (met or not met classes needed)
                              viewOnlyMode={true} // When viewing someone else profile, you are NOT allow to set a new time, hence View only mode true
                              id={this.state.userId} // Sending the id to this component will tell it to render for a selected user and not the logged in user
                            />
                          </div>
                        </Tabs>
                        <QualificationTile
                          QualList={this.state.QualList}
                          getQuals={this.getQuals}
                        />
                      </Wrapper>
                    )}
                    {this.state.showProfileView && this.renderProfile()}
                    {this.state.showProfileView && this.renderASN()}
                    {this.renderAccesstoTurnout()}
                    {
                      /* Paging Groups View */
                      this.state.showPagingGroupView && (
                        <div className="box_default">
                          <div className="Sub-Section-titles">
                            <h3>
                              {copy.admin.userDetailsView.pagingGroupSection}
                            </h3>
                          </div>
                          <div>
                            <div className={"cardBg " + styles.addGroupHolder}>
                              <span>
                                {copy.admin.userDetailsView.addPagingGroupTitle}
                              </span>
                              <input
                                autoComplete="off"
                                id="userAndPagingGroupSearch"
                                placeholder={
                                  copy.admin.userDetailsView
                                    .searchPagingGroupPlaceHolder
                                }
                                className={styles.searchbox}
                                value={this.state.searchValue}
                                onChange={this.searchChange.bind(this)}
                              />

                              {this.state.updateGroupResults === true ? (
                                this.returnSearchResults()
                              ) : (
                                <p className={styles.noSearchDesc}>
                                  {copy.admin.memberList.noSearch}
                                </p>
                              )}
                            </div>
                            {this.state.removed && (
                              <p className={styles.success + " fadeaway"}>
                                {copy.admin.userDetailsView.groupRemoved}
                              </p>
                            )}
                            {this.state.groupsLoading ? (
                              <div className="inLineloadingContainer">
                                <Loader />
                              </div>
                            ) : (
                              this.renderPagingGroupSection()
                            )}
                          </div>
                        </div>
                      )
                    }
                    <div className="box_default">
                      <div className="Sub-Section-titles">
                        <h3>{copy.admin.userDetailsView.securitySection}</h3>
                      </div>
                      {this.state.permanentDeleteConfirmation ? (
                        /* permanent delete confirmation box */
                        <div>
                          <p>
                            {
                              copy.admin.userDetailsView
                                .permanentDeleteConfirmation
                            }
                          </p>
                          <div className={styles.resetASNModal}>
                            <Button
                              label="Delete"
                              buttonClick={this.permanentDeleteUserAccount}
                              content="Delete"
                              variant="btn_outline"
                              styles="red"
                            />
                            <Button
                              label="Cancel"
                              buttonClick={this.closePD}
                              content="Cancel"
                              variant="btn_outline grey"
                              styles=""
                            />
                          </div>
                        </div>
                      ) : (
                        <ul className="settingOptions">
                          {shouldDisplayPasswordReset &&
                            (this.state.hasSSOAccount !== null &&
                            this.state.hasSSOAccount !== undefined &&
                            !this.state.hasSSOAccount ? (
                              <li>
                                <div className={styles.password_reset}>
                                  <div>
                                    <span className="primaryText">
                                      {copy.admin.userDetailsView.resetPassword}
                                    </span>
                                    <span className="subText">
                                      {copy.settings.profile.passwordResetDesc}
                                    </span>
                                    {this.state.passwordStatus ===
                                    null ? null : this.state.passwordStatus ===
                                      true ? (
                                      <span className={styles.reset_success}>
                                        {
                                          copy.settings.profile
                                            .passwordResetSuccess
                                        }
                                      </span>
                                    ) : (
                                      <span className={styles.reset_fail}>
                                        {
                                          copy.settings.profile
                                            .passwordResetFail
                                        }
                                      </span>
                                    )}
                                  </div>
                                  <Button
                                    label="Reset"
                                    content="Reset"
                                    variant="btn_secondary"
                                    styles=" "
                                    icon=" "
                                    buttonClick={this.handleResetPasswordClick}
                                  />
                                </div>
                              </li>
                            ) : (
                              <li>
                                <div className={styles.password_reset}>
                                  <div>
                                    <span className="primaryText">
                                      {copy.admin.userDetailsView.resetPassword}
                                    </span>
                                    <span className="subText">
                                      {
                                        copy.settings.profile
                                          .passwordResetDescNonSAS
                                      }
                                    </span>
                                  </div>
                                </div>
                              </li>
                            ))}

                          <li>
                            <div>
                              <div>
                                <span className="primaryText">
                                  {copy.admin.userDetailsView.userLogout}
                                </span>
                                <span className="subText">
                                  {
                                    copy.admin.userDetailsView
                                      .userLogoutDescription
                                  }
                                </span>
                                {this.state.showUserLogoutSuccessMessage && (
                                  <span className="successMessage">
                                    {
                                      copy.admin.userDetailsView
                                        .userLockoutAllDevicesSuccess
                                    }
                                  </span>
                                )}
                              </div>
                              <Button
                                label="Logout"
                                content="Logout"
                                buttonClick={this.forceUserLockout}
                                variant="btn_secondary"
                                styles=" "
                                icon=" "
                              />
                            </div>
                          </li>
                          {/* Account deactivation will only be allowed if the signed in user is not a paging group owner */}
                          {shouldDisplayDeactivateUserAccount &&
                            !this.state.isShowReactivate &&
                            (this.state.hasSSOAccount !== null &&
                            this.state.hasSSOAccount !== undefined &&
                            !this.state.hasSSOAccount ? (
                              <li>
                                <div>
                                  <div>
                                    <span className="primaryText">
                                      {
                                        copy.admin.userDetailsView
                                          .userDeactivation
                                      }
                                    </span>
                                    {this.state.deactivationStatus ===
                                    null ? null : this.state
                                        .deactivationStatus === true ? (
                                      <span className="successMessage">
                                        {
                                          copy.admin.userDetailsView
                                            .userDeactivationSuccess
                                        }
                                      </span>
                                    ) : (
                                      <span className="failMessage">
                                        {
                                          copy.admin.userDetailsView
                                            .userDeactivationAPIErrorMessage
                                        }
                                      </span>
                                    )}
                                  </div>
                                  <Button
                                    label="Commit"
                                    content="Commit"
                                    variant="btn_secondary"
                                    styles=" "
                                    icon=" "
                                    buttonClick={this.deactivateUserAccount}
                                  />
                                </div>
                              </li>
                            ) : (
                              <li>
                                <div className={styles.password_reset}>
                                  <div>
                                    <span className="primaryText">
                                      {
                                        copy.admin.userDetailsView
                                          .userDeactivation
                                      }
                                    </span>
                                    <span className="subText">
                                      {
                                        copy.admin.userDetailsView
                                          .userDeactivationNonSAS
                                      }
                                    </span>
                                  </div>
                                </div>
                              </li>
                            ))}
                          {/* Account reactivation and permanent deleting of deactivated accounts if with SuperUser/Admin access*/}
                          {shouldDisplayReactivateAndPD &&
                            this.state.isShowReactivate &&
                            (this.state.hasSSOAccount !== null &&
                            this.state.hasSSOAccount !== undefined &&
                            !this.state.hasSSOAccount ? (
                              <ul>
                                <li>
                                  <div>
                                    <div>
                                      <span className="primaryText">
                                        {
                                          copy.admin.userDetailsView
                                            .userReactivation
                                        }
                                      </span>
                                      {this.state.reactivationStatus ===
                                      null ? null : this.state
                                          .reactivationStatus === true ? (
                                        <span className="successMessage">
                                          {
                                            copy.admin.userDetailsView
                                              .userReactivationSuccess
                                          }
                                        </span>
                                      ) : (
                                        <span className="failMessage">
                                          {
                                            copy.admin.userDetailsView
                                              .userReactivationAPIErrorMessage
                                          }
                                        </span>
                                      )}
                                    </div>
                                    <Button
                                      label="Commit"
                                      content="Commit"
                                      variant="btn_secondary"
                                      styles=" "
                                      icon=" "
                                      buttonClick={this.reactivateUserAccount}
                                    />
                                  </div>
                                </li>
                                <li>
                                  <div>
                                    <div>
                                      <span className="primaryText">
                                        {
                                          copy.admin.userDetailsView
                                            .userPermanentDelete
                                        }
                                      </span>
                                      {this.state.permanentDeleteStatus ===
                                      null ? null : this.state
                                          .permanentDeleteStatus === true ? (
                                        <span className="successMessage">
                                          {
                                            copy.admin.userDetailsView
                                              .userPermanentDeleteSuccess
                                          }
                                        </span>
                                      ) : (
                                        <span className="failMessage">
                                          {
                                            copy.admin.userDetailsView
                                              .userPermanentDeleteAPIErrorMessage
                                          }
                                        </span>
                                      )}
                                    </div>
                                    <Button
                                      label="Commit"
                                      content="Commit"
                                      variant="btn_secondary"
                                      styles=" "
                                      icon=" "
                                      buttonClick={this.confirmPD}
                                    />
                                  </div>
                                </li>
                              </ul>
                            ) : null)}
                        </ul>
                      )}
                    </div>
                    {this.state.hasSSOAccount !== null &&
                      this.state.hasSSOAccount !== undefined &&
                      !this.state.hasSSOAccount &&
                      this.renderUserAccess()}
                  </Wrapper>
                )}
              </div>
            </Wrapper>
          )}
        </div>
      </Wrapper>
    );
  }
}
