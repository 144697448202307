import React from "react";
import Wrapper from "../../hoc/Wrapper/Wrapper";
import ErrorBanner from "../../hoc/ErrorBanner/ErrorBanner";
import { ReactComponent as AppLOGO } from "../../assets/img/logos/SAS-APP.svg";
import { ReactComponent as VicLOGO } from "../../assets/img/logos/VIC-LOGO.svg";
import { Redirect } from "react-router-dom";
import Button from "../../components/UI/Button/Button";
import { AuthService } from "../../api/Authentication/AuthService";
import styles from "./LoginView.module.scss";
import copy from "../../assets/copy/copy.json";
import { Requests } from "../../api/IdentityServerRequests/Requests";
import { ErrorHandler } from "../../hoc/ErrorBanner/ErrorHandler";

export default class Login extends React.Component {
  constructor(props) {
    super(props);

    this.authService = new AuthService();
    this.state = {
      errorMessage: null,
      flavourErrorMessage: null,
      api: {},
      userAccessToken: null,
      allowLogin: false,
    };
    this.shouldCancel = false;
    this.Requests = new Requests();
    this.errorHandler = new ErrorHandler();
  }

  componentDidMount() {
    this.getUser();
  }

  /**
   * This is handles the login flow for the user in AuthService.jsx
   * Login is handeled differently for mobile and web. More info in Auth Service.
   * @param enableLocalLogin - This flag is used to tell Identity Server if it should display SAS only login options or Active Directory Login options
   */
  login = (enableLocalLogin) => {
    this.authService.login(enableLocalLogin);
  };

  /**
   * This is handles the logout flow for the user in AuthService.jsx
   * Logout is handeled differently for mobile and web. More info in Auth Service.
   */
  logout = () => {
    window.localStorage.removeItem("accessToken");
    window.localStorage.removeItem("fcmToken");
    this.authService.logout();
  };

  /**
   * This function will get the user's details from local storage in the mobile apps.
   * On the web version, it will utilise the OIDC library's getUser function.
   * Both of these will return the accessToken that will be decoded using the jwt library.
   */
  getUser = () => {
    // Checks if the accessToken exists in native storage.
    this.Requests.getAccessToken().then((accessToken) => {
      this.setState({ userAccessToken: accessToken }, () => {
        this.checkRolesLocal();
      });
    });
  };

  /**
   * Checks local storage for an invalid roles flag. This is set in terms of use if the user does not have adequate roles to be logged into the app.
   * Prevents login if true.
   */
  checkRolesLocal = () => {
    if (window.localStorage.getItem("rolesNotValid") === "true") {
      this.setState({
        allowLogin: false,
        flavourErrorMessage: copy.login.userNoRoles,
      });
    } else if (window.localStorage.getItem("rolesNotValid") === "false") {
      this.setState({
        allowLogin: false,
        flavourErrorMessage: null,
      });
    } else {
      this.setState({
        allowLogin: true,
        flavourErrorMessage: null,
      });
    }
  };

  render() {
    // If the user has successfully logged in and also accepted the terms of use, redirect them to the ASN page
    if (
      this.state.userAccessToken &&
      this.props.location &&
      ((this.props.location.state && this.props.location.state.allowLogin) ||
        !this.props.location.state) &&
      this.state.allowLogin
    ) {
      return <Redirect to="/terms-of-use" />;
    } else {
      return (
        <Wrapper>
          <ErrorBanner
            isVisible={this.state.errorMessage ? true : false}
            ErrorMessage={this.state.errorMessage}
          />
          <div className={styles.login}>
            <div className={styles.pageHeader}></div>
            <div className="box_default">
              <AppLOGO />
              <div className="col-one-third only-child">
                <h2 id="textTest">{copy.global.AppTitle}</h2>
                <div className={styles.buttonWrapper}>
                  <Button
                    label="Login with AD"
                    buttonClick={() => {
                      this.login(false);
                    }}
                    content="Agency Login"
                    variant="btn_primary loginWithAD"
                    styles="login"
                  />
                </div>
                <div className={styles.SASLoginHolder}>
                  <p style={{ fontWeight: "bold" }}>
                    {copy.login.SASLoginTitle}
                  </p>
                  {this.props.location &&
                  this.props.location.state !== undefined ? (
                    <p style={{ color: "red" }}>
                      {this.props.location.state.errorMessage}
                    </p>
                  ) : (
                    this.state.flavourErrorMessage && (
                      <p style={{ color: "red" }}>
                        {this.state.flavourErrorMessage}
                      </p>
                    )
                  )}
                  <p>
                    {copy.login.SASLoginTextPart1}
                    <span onClick={() => this.login(true)}>
                      {copy.login.SASLoginTextPart2}
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-one-third only-child">
              <div className={styles.holder}>
                <p>{copy.login.loginDesc}</p>
                <p>{copy.login.TnC}</p>
                <p>{copy.login.loginDesc2}</p>
                {/* Possible new text - still TBC by EMV - need to add it from copy file if needed*/}
                {/* <p>The Supplementary Alerting Service application (SAS App) is made available by the State of  Victoria through Emergency Management Victoria (EMV). Please read our <a target="_blank" title="EMV Privacy Policy" href="https://www.emv.vic.gov.au/privacy"> Privacy Policy </a>, as well as the Terms of Use (Terms) and our Privacy Statement, that follows, before using the SAS App</p>
                <p><b>The SAS App receives information from the Emergency Alert Service (EAS) and does not replace the EAS as the primary alerting service for emergency services communications. You must carry your EAS pager with you at all times.</b></p>
                <p>The words 'EMV', 'us', 'our' or 'we' refer to EMV.  The words 'users', 'you' or 'your' refer to users or viewers of the SAS App. The word 'Organisation' refers to any participating emergency services organisation, such as Ambulance Victoria, the Country Fire Authority, the Emergency Services Telecommunications Authority, the Victoria </p> */}
              </div>
              <VicLOGO />
            </div>
          </div>
        </Wrapper>
      );
    }
  }
}
