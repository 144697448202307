import React, { Component } from "react";
import Wrapper from "../../hoc/Wrapper/Wrapper";
import { withRouter, Link } from "react-router-dom";
import styles from "./UnknownError.module.scss";

import copy from "../../assets/copy/copy";

class UnknownError extends Component {
  render() {
    return (
      <Wrapper>
        <div className="main-content-holder">
          <div className={styles.errorPage}>
            {this.props.location.state !== undefined ? (
              <h1>{this.props.location.state.errorMessage}</h1>
            ) : (
              <h1>{copy.error_page.errorMessage}</h1>
            )}
            <Link to="/home">{copy.error_page.linkText}</Link>
          </div>
        </div>
      </Wrapper>
    );
  }
}

export default withRouter(UnknownError);
