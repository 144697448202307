import { logger } from "../../utils";
// import sha256 from 'crypto-js/sha256';
// import base64url from "base64url";

export class AuthService {
  constructor(props) {
    this.login = this.login.bind(this);
    // The Redirect URI is generated based on the production or dev enviornment.
    let clientRootURI =
      process.env.NODE_ENV === "production"
        ? process.env.REACT_APP_ROOT_DEV
        : process.env.REACT_APP_ROOT_LOCAL;
    let redirectURI = `${clientRootURI}signin-callback.html`;

    this.state = {
      clientRootURI: clientRootURI,
      redirectURI: redirectURI,
      redirectURIMobile: process.env.REACT_APP_LOGIN_REDIRECT_URI_MOBILE,
    };
  }

  getUser() {
    // Uses the OIDC Library to get User details. Function is mentioned below.
    return this.userManager.getUser();
  }

  removeUser() {
    // Uses the OIDC Library to remove User details. Function is mentioned below.
    return this.userManager.removeUser();
  }

  login(enableLocalLogin) {
    // Remove roles flag
    window.localStorage.removeItem("rolesNotValid");
    // Get the current theme preference
    let isDarkMode = document
      .getElementsByTagName("HTML")[0]
      .classList.contains("theme-dark");
    return this.processSigninRequestURL(enableLocalLogin, isDarkMode);
  }

  processSigninRequestURL(enableLocalLogin, isDarkMode) {
    // These are the Identity Server Settings.
    let platform = "null";
    let deviceName = "null";
    let deviceDetailsObject = "null";
    let deviceId = "null";

    if (window.cordova) {
      //First we get the device information from the cordova-device plugin:
      let modelName = "null";
      let version = "null";
      let deviceId = "null";
      let manufacturer = "null";
      let serial = "null";

      if (window.device.platform !== undefined) {
        platform = window.device.platform;
      }
      if (window.device.name !== undefined) {
        deviceName = window.device.name;
      }
      if (window.device.model !== undefined) {
        modelName = window.device.model;
      }
      if (window.device.version !== undefined) {
        version = window.device.version;
      }
      if (window.device.uuid !== undefined) {
        deviceId = window.device.uuid;
      }
      if (window.device.manufacturer !== undefined) {
        manufacturer = window.device.manufacturer;
      }
      if (window.device.serial !== undefined) {
        serial = window.device.serial;
      }

      // Set the device id and platform so additional information will be logged
      logger.setDeviceInfo(deviceId, platform);

      deviceDetailsObject =
        "platform=" +
        platform +
        "|deviceName=" +
        deviceName +
        "|modelName=" +
        modelName +
        "|version=" +
        version +
        "|deviceId=" +
        deviceId +
        "|manufacturer=" +
        manufacturer +
        "|serial=" +
        serial;
    } else {
      // Desktop app
      // Set the platform and deviceID for Loggly logs
      let browserVersion = "null";
      let browserVendor = "null";
      if (navigator.platform !== undefined) {
        platform = navigator.platform;
      }
      if (navigator.vendor !== undefined) {
        browserVendor = navigator.vendor;
      }
      if (navigator.userAgent !== undefined) {
        // gets the browser name as the device name
        var Sys = {};
        var ua = navigator.userAgent.toLowerCase();
        var s = ua.match(/msie ([\d.]+)/)
          ? (Sys.ie = s[1])
          : // eslint-disable-next-line
          (s = ua.match(/firefox\/([\d.]+)/))
          ? (Sys.firefox = s[1])
          : // eslint-disable-next-line
          (s = ua.match(/chrome\/([\d.]+)/))
          ? (Sys.chrome = s[1])
          : // eslint-disable-next-line
          (s = ua.match(/opera.([\d.]+)/))
          ? (Sys.opera = s[1])
          : // eslint-disable-next-line
          (s = ua.match(/version\/([\d.]+).*safari/))
          ? (Sys.safari = s[1])
          : 0;

        if (Sys.ie) {
          browserVersion = "IE";
        } else if (Sys.firefox) {
          browserVersion = "Firefox";
        } else if (Sys.chrome) {
          browserVersion = "Chrome";
        } else if (Sys.opera) {
          browserVersion = "Opera";
        } else if (Sys.safari) {
          browserVersion = "Safari";
        }
      }
      deviceId = [platform, browserVendor, browserVersion]
        .join(" ")
        .replace(/\s/g, "");
    }

    logger.setDeviceInfo(deviceId, platform);
    logger.log(
      `AuthService:: login - Login request made for ${deviceId} on ${platform}.`
    );

    let redirectURI = window.cordova
      ? this.state.redirectURIMobile
      : this.state.redirectURI;

    var details = {
      client_id: process.env.REACT_APP_LOGIN_OIDC_CLIENT_ID,
      redirect_uri: redirectURI,
      response_type: "code",
      scope: "openid profile APISAS APISAS2 offline_access",
      state: "fa77edf8491f46919400818bfa46e716",
      code_challenge: "Jm9t0sjIrI69ZywugTFNa0UOPtncTAHS0RLPnTCZEWw",
      code_challenge_method: "S256",
      response_mode: "query",
      prompt: "login",
    };

    // This is the GET call to Identity Server Authorize endpoint.
    let queryParams = Object.keys(details)
      .map(
        (key) =>
          encodeURIComponent(key) + "=" + encodeURIComponent(details[key])
      )
      .join("&");

    // Identity Server URL generation for the Web
    let loginReqURL =
      process.env.REACT_APP_ROOT_IDENTITY_SERVER +
      "/connect/authorize?" +
      queryParams +
      "&enableLocalLogin=" +
      enableLocalLogin +
      "&darkTheme=" +
      isDarkMode;

    if (window.cordova) {
      // Identity Server URL generation for the Mobile app
      let loginReqURL =
        process.env.REACT_APP_ROOT_IDENTITY_SERVER +
        "/connect/authorize?" +
        queryParams +
        "&enableLocalLogin=" +
        enableLocalLogin +
        "&darkTheme=" +
        isDarkMode +
        "&deviceDetails=" +
        encodeURIComponent(deviceDetailsObject);

      /*
      // This launches the inApp Browser.
      let inAppBrowserRef = window.cordova.InAppBrowser.open(loginReqURL, '_blank', 'location=yes,clearcache=yes,clearsessioncache=yes');

      inAppBrowserRef.addEventListener('loaderror', (params) => {
        // close in app browser
        inAppBrowserRef.close();

        if (params && params.url) {
          // obtain data from login
          if (params.url.startsWith("sasapp://auth/?")) {
            const parsedURL = new URL(params.url);
            const callback = parsedURL.searchParams.get("callback");

            switch (callback) {
              case "signin":
                var storage = window.localStorage;
                storage.setItem(
                  "accessToken",
                  parsedURL.searchParams.get("accessToken")
                );
                storage.setItem("idToken", parsedURL.searchParams.get("idToken"));
                storage.setItem(
                  "refreshToken",
                  parsedURL.searchParams.get("refreshToken")
                );
                storage.setItem(
                  "expiresAt",
                  parsedURL.searchParams.get("expiresAt")
                );
                if (window.location.host.indexOf("reloaded=true") !== -1) {
                  var updateURL = window.location.href.split("?")[0];
                  window.location.replace(updateURL);
                  window.location.reload(true);
                }
                break;
              case "signin-error":
                break;
              default:
                break;
            }

            if (window.location.host.indexOf("reloaded=true") === -1) {
              var updateURL = window.location.href;
              if (updateURL.indexOf("?") > -1) {
                updateURL += "&reloaded=true";
              } else {
                updateURL += "?reloaded=true";
              }
              window.location.replace(updateURL);
              window.location.reload(true);
            }
          }
        }
      });
      */

      window.SafariViewController.isAvailable(function (available) {
        if (available) {
          window.SafariViewController.show(
            {
              url: loginReqURL,
            },
            function (result) {},
            function (error) {
              console.log(error);
            }
          );
        }
      });
    } else {
      // Open the Identity Server URL
      window.location.href = loginReqURL;
    }
  }

  async processSignoutRequestURL() {
    // These are the Identity Server Settings for logging the user out.
    // localhost is running on http instead of https
    let redirectURI = window.location.host.includes("localhost")
      ? "http://" + window.location.host + "/signout-callback.html"
      : "https://" + window.location.host + "/signout-callback.html";

    if (window.cordova) {
      // If its the mobile app, get the app url from the enviornment variables.
      redirectURI = process.env.REACT_APP_LOGOUT_REDIRECT_URI_MOBILE;
    }
    var details = {
      id_token_hint: await this.getIdentityTokensFromStorage("idToken"),
      post_logout_redirect_uri: redirectURI,
    };

    // This is the GET call to Identity Server end session endpoint.
    let queryParams = Object.keys(details)
      .map(
        (key) =>
          encodeURIComponent(key) + "=" + encodeURIComponent(details[key])
      )
      .join("&");
    let logoutReqURL =
      process.env.REACT_APP_ROOT_IDENTITY_SERVER +
      "/connect/endsession?" +
      queryParams;

    if (window.cordova) {
      // This launches the inApp Browser.
      window.SafariViewController.isAvailable(function (available) {
        if (available) {
          window.SafariViewController.show(
            {
              url: logoutReqURL,
            },
            function (result) {},
            function (error) {
              console.log("error in logging out: " + error);
            }
          );
        }
      });
    } else {
      // Reload the page will take the user back to the login / landing screen
      // Open the Identity Server URL
      window.location.href = logoutReqURL;
    }
  }

  renewToken() {
    return this.userManager.signinSilent();
  }

  logout() {
    logger.log(`AuthService:: logout - logout request made.`);

    // Clear notificaion database used for Android Auto Screen
    if (window.cordova && window.cordova.platformId === "android") {
      window.SasFcmNotificationReceiverPlugin.clearDbForAuto();
    }

    // Remove roles flag
    window.localStorage.removeItem("rolesNotValid");
    if (window.cordova) {
      // Stop Location Tracking
      window.BackgroundGeolocation.stop();
      window.localStorage.removeItem("currentlyAttendingEventId");
      window.localStorage.removeItem(
        "currentlyAttendingEventDestinationCoords"
      );
      window.localStorage.removeItem("lastKLKey");
      window.localStorage.removeItem("userId");
    }
    return this.processSignoutRequestURL();
  }

  // This function will fecth the current values of accessToken, refreshToken, idToken and expiresAt from the relevant storage for Mobile & Web
  async getIdentityTokensFromStorage(tokenName) {
    let tokenValue;
    // Tokens for Mobile are retireved from Native Storage
    if (window.cordova) {
      tokenValue = await this.getFromMobileStorage(tokenName);
    } else {
      // Token for Web are retirvedd from Local Storage
      tokenValue = await this.getFromWebStorage(tokenName);
    }
    return tokenValue;
  }

  getFromMobileStorage = (key) =>
    new Promise((resolve, reject) => {
      window.NativeStorage.getString(key, resolve, reject);
    });

  getFromWebStorage = (key) => {
    return window.localStorage.getItem(key);
  };

  // THIS MAY BE USED IF WE GENERATE OUR OWN VERIFIER. DONT DELETE. @Shourya.
  // generateRandomString(length) {

  //   var code_verifier = this.generateRandomString(128);
  //   console.log("code_verifier: " + code_verifier)
  //   console.log("code_verifier_hash: " + sha256(code_verifier).toString())
  //   console.log("code_verifier_hash_base64url: " + base64url.encode(sha256(code_verifier).toString(), "utf8"))

  //   window.localStorage.setItem('codeVerifier', '57cb2bd60ace48ea9f95ddeaab873208bdde9a22db9e421e9033ab29111b62ba992a60cd2b3b4828b1bf11c25fd7ec00');
  //   var code_challenge = base64url.encode(sha256(code_verifier).toString(), "utf8");
  //   window.localStorage.setItem('codeChallenge', 'Jm9t0sjIrI69ZywugTFNa0UOPtncTAHS0RLPnTCZEWw');

  //   var text = "";
  //   var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  //   for (var i = 0; i < length; i++) {
  //     text += possible.charAt(Math.floor(Math.random() * possible.length));
  //   }
  //   return text;
  // }
}
