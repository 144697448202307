import React from "react";
import styles from "./Button.module.scss";

export default class Radio extends React.Component {
  render() {
    const {
      label,
      name,
      checked,
      setChecked,
      onChange,
      onClick,
      value,
    } = this.props;

    if (checked) {
      return (
        <label aria-label={label} className={styles.radio}>
          <input
            type="radio"
            name={name}
            value={value}
            onChange={onChange}
            checked={setChecked}
            defaultChecked
            onClick={onClick}
          />
          <span className={styles.checkmark}></span>
          {label}
        </label>
      );
    } else {
      return (
        <label aria-label={label} className={styles.radio}>
          <input
            type="radio"
            name={name}
            value={value}
            onChange={onChange}
            checked={setChecked}
            onClick={onClick}
          />
          <span className={styles.checkmark}></span>
          {label}
        </label>
      );
    }
  }
}
