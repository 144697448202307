import React, { Component } from "react";
import Wrapper from "../../hoc/Wrapper/Wrapper";
import ErrorBanner from "../../hoc/ErrorBanner/ErrorBanner";
import Loader from "../UI/Loading/Loading";
import Button from "../../components/UI/Button/Button";
import styles from "./Chats.module.scss";
import copy from "../../assets/copy/copy.json";

import { Requests } from "../../api/IdentityServerRequests/Requests";

class NewChat extends Component {
  constructor(props) {
    super(props);
    this.Requests = new Requests();
    this.state = {
      errorMessage: null,
      showMessageSent: false,
      invalidSubject: false,
      invalidField: false,
      isSendingMessage: false,
      textAreaInput: null,
      textAreaCharacterCount: 0,
      subjectInput: null,
      subjectCharacterCount: 0,
    };
  }

  characterCountSubject = (event) => {
    var count = event.target.value.length;
    this.setState({
      subjectInput: event.target.value,
      subjectCharacterCount: count,
    });
  };

  characterCount = (event) => {
    var count = event.target.value.length;
    this.setState({
      textAreaInput: event.target.value,
      textAreaCharacterCount: count,
    });
  };

  sendChatMessage = () => {
    if (!this.state.textAreaInput || !this.state.subjectInput) {
      if (!this.state.subjectInput) {
        this.setState({
          invalidSubject: true,
        });
      } else {
        this.setState({
          invalidSubject: false,
        });
      }
      if (!this.state.textAreaInput) {
        this.setState({
          invalidField: true,
        });
      } else {
        this.setState({
          invalidField: false,
        });
      }
    } else {
      let body;

      if (
        this.props.newMessagePagingGroup &&
        this.props.newMessagePagingGroup.length === 1
      ) {
        body = {
          subject: this.state.subjectInput,
          pagingNumber:
            this.props.newMessagePagingGroup.length === 1
              ? this.props.newMessagePagingGroup[0]
              : null,
          message: this.state.textAreaInput,
        };
      } else if (
        this.props.newMessageUsers &&
        this.props.newMessageUsers.length > 1
      ) {
        let NewMessageUsers = [];
        for (let i = 0; i < this.props.newMessageUsers.length; i++) {
          NewMessageUsers.push(this.props.newMessageUsers[i].userId);
        }
        body = {
          subject: this.state.subjectInput,
          userRecipientIDs: NewMessageUsers,
          message: this.state.textAreaInput,
        };
      } else if (
        this.props.newMessageUsers &&
        this.props.newMessageUsers.length === 1
      ) {
        body = {
          subject: this.state.subjectInput,
          userRecipientID:
            this.props.location === "Admin"
              ? this.props.newMessageUsers[0]
              : this.props.newMessageUsers[0].userId,
          message: this.state.textAreaInput,
        };
      }

      this.setState(
        {
          isSendingMessage: true,
        },
        () => {
          if (
            this.props.newMessagePagingGroup &&
            this.props.newMessagePagingGroup.length === 1
          ) {
            this.Requests.callAPI(this.Requests.postGroupThread, body).then(
              (data) => {
                if (data && data.status && data.status === 200) {
                  this.setState({
                    isSendingMessage: false,
                  });
                  if (this.props.location === "SASChat") {
                    this.props.getChatThread(data.data);
                  }
                  if (this.props.location === "Admin") {
                    this.setState({
                      showMessageSent: true,
                    });
                  }
                } else if (data && data.status && data.status === 403) {
                  let ErrorMessage =
                    copy.sasChat.sendChatMessageNoPermissionAPIErrorMessage +
                    ` (Error #${copy.errorCodes.sendChatMessageNoPermissionAPIErrorMessage})`;
                  this.setState(
                    {
                      isSendingMessage: false,
                      errorMessage: ErrorMessage,
                    },
                    () => {
                      setTimeout(
                        function () {
                          this.setState({ errorMessage: null });
                        }.bind(this),
                        5000
                      );
                    }
                  );
                } else {
                  let ErrorMessage =
                    copy.sasChat.sendChatMessageAPIErrorMessage +
                    ` (Error #${copy.errorCodes.sendChatMessageAPIErrorMessageGroup})`;
                  if (
                    data &&
                    data.status &&
                    data.status === 400 &&
                    data.data.SASENumErrorCode === 53
                  ) {
                    ErrorMessage =
                      copy.sasChat.prohibitedWordInChatMessageAPIErrorMessage;
                  }
                  this.setState(
                    {
                      isSendingMessage: false,
                      errorMessage: ErrorMessage,
                    },
                    () => {
                      setTimeout(
                        function () {
                          this.setState({ errorMessage: null });
                        }.bind(this),
                        5000
                      );
                    }
                  );
                }
              }
            );
          } else if (
            this.props.newMessageUsers &&
            this.props.newMessageUsers.length > 1
          ) {
            this.Requests.callAPI(
              this.Requests.postAdhocGroupThread,
              body
            ).then((data) => {
              if (data && data.status && data.status === 200) {
                this.setState({
                  isSendingMessage: false,
                });
                if (this.props.location === "SASChat") {
                  this.props.getChatThread(data.data);
                }
              } else {
                let ErrorMessage =
                  copy.sasChat.sendChatMessageAPIErrorMessage +
                  ` (Error #${copy.errorCodes.sendChatMessageAPIErrorMessageGroupAdHoc})`;
                if (
                  data &&
                  data.status &&
                  data.status === 400 &&
                  data.data.SASENumErrorCode === 53
                ) {
                  ErrorMessage =
                    copy.sasChat.prohibitedWordInChatMessageAPIErrorMessage;
                }
                this.setState(
                  {
                    isSendingMessage: false,
                    errorMessage: ErrorMessage,
                  },
                  () => {
                    setTimeout(
                      function () {
                        this.setState({ errorMessage: null });
                      }.bind(this),
                      5000
                    );
                  }
                );
              }
            });
          } else if (
            this.props.newMessageUsers &&
            this.props.newMessageUsers.length === 1
          ) {
            this.Requests.callAPI(this.Requests.postOneOnOneThread, body).then(
              (data) => {
                if (data && data.status && data.status === 200) {
                  // this.props.chatRefresh();
                  this.setState({
                    isSendingMessage: false,
                  });
                  if (this.props.location === "SASChat") {
                    this.props.getChatThread(data.data);
                  }
                  if (this.props.location === "Admin") {
                    this.setState({
                      showMessageSent: true,
                    });
                  }
                } else {
                  let ErrorMessage =
                    copy.sasChat.sendChatMessageAPIErrorMessage +
                    ` (Error #${copy.errorCodes.sendChatMessageAPIErrorMessageOneOnOne})`;
                  if (
                    data &&
                    data.status &&
                    data.status === 400 &&
                    data.data.SASENumErrorCode === 53
                  ) {
                    ErrorMessage =
                      copy.sasChat.prohibitedWordInChatMessageAPIErrorMessage;
                  }
                  // Check if the user was trying to send themselves a message
                  if (
                    this.props.newMessageUsers &&
                    this.props.newMessageUsers[0] &&
                    this.props.newMessageUsers[0].userId
                  ) {
                    let receiptID = this.props.newMessageUsers[0].userId;
                    let senderID = window.localStorage.getItem("userId");

                    if (receiptID === senderID) {
                      ErrorMessage =
                        copy.sasChat
                          .sendChatMessageAPIErrorMessageSameRecipient +
                        ` (Error #${copy.errorCodes.sendChatMessageAPIErrorMessageSameRecipient})`;
                    }
                  }
                  // if (data && data.data && data.data.SASMessageClient) {
                  //   ErrorMessage = data.data.SASMessageClient;
                  // }
                  this.setState(
                    {
                      isSendingMessage: false,
                      errorMessage: ErrorMessage,
                    },
                    () => {
                      setTimeout(
                        function () {
                          this.setState({ errorMessage: null });
                        }.bind(this),
                        5000
                      );
                    }
                  );
                }
              }
            );
          }
        }
      );
    }
  };

  render() {
    return (
      <Wrapper>
        <ErrorBanner
          isVisible={this.state.errorMessage ? true : false}
          ErrorMessage={this.state.errorMessage}
        />
        <div className={styles.newChatWrapper}>
          <div
            className={
              (this.props.location === "SASChat" && styles.SASChat) +
              " box_default"
            }
          >
            <div id="input_holder" className={styles.messageInput_holder}>
              {this.state.showMessageSent ? (
                <div className={styles.messageSentWrapper}>
                  <span>{copy.sasChat.messageSent}</span>
                </div>
              ) : (
                <Wrapper>
                  <div className={styles.messageSubject}>
                    <div className={styles.chatHeader}>
                      <label>{copy.sasChat.subject}</label>
                      <span>
                        {this.state.subjectCharacterCount}
                        {copy.sasChat.subjectCharacters}
                      </span>
                    </div>
                    <input
                      className={
                        this.state.invalidSubject ? styles.errorTextField : null
                      }
                      aria-label="Enter subject line"
                      name="subject"
                      placeholder="Enter your subject"
                      maxLength="32"
                      onChange={this.characterCountSubject}
                    />
                    {this.state.invalidSubject && (
                      <span className={styles.errorMessage}>
                        {copy.error_handling.required}
                      </span>
                    )}
                  </div>
                  <div className={styles.messageBody}>
                    <div className={styles.chatHeader}>
                      <label>{copy.sasChat.createNewMessage}</label>
                      <span>
                        {this.state.textAreaCharacterCount}
                        {copy.sasChat.characters}
                      </span>
                    </div>
                    <textarea
                      className={
                        this.state.invalidField ? styles.errorTextField : null
                      }
                      aria-label="Reply to message here"
                      name="reply to message"
                      placeholder="Enter your message"
                      maxLength="512"
                      onChange={this.characterCount}
                    />
                    {this.state.invalidField && (
                      <span className={styles.errorMessage}>
                        {copy.error_handling.required}
                      </span>
                    )}
                  </div>
                  <div className={styles.postTxtBtn}>
                    <p>{copy.sasChat.messageDesc}</p>
                    {this.state.isSendingMessage ? (
                      <Loader />
                    ) : (
                      <Button
                        label="Send message"
                        content={copy.global.btnSend}
                        variant="btn_outline"
                        styles="greenTxt"
                        icon="icon_post"
                        buttonClick={this.sendChatMessage}
                      />
                    )}
                  </div>
                </Wrapper>
              )}
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }
}

export default NewChat;
