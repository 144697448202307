import { MapLayer } from "react-leaflet";
import L from "leaflet";
import "leaflet-routing-machine";
import { withLeaflet } from "react-leaflet";
import "leaflet-routing-machine-here";
import "leaflet-routing-machine/dist/leaflet-routing-machine.css";

class Routing extends MapLayer {
  createLeafletElement() {
    const { map, unit, event } = this.props;
    if (!this.controller) {
      this.controller = L.Routing.control({
        router: new L.Routing.Here(`${process.env.REACT_APP_HERE_KEY}`),
        waypoints: [L.latLng(unit), L.latLng(event)],
        lineOptions: {
          styles: [{ color: "#000", opacity: 1, weight: 5 }],
        },
        createMarker: function () {
          return null;
        },
        show: false,
        autoRoute: false,
      }).addTo(map.leafletElement);
    
      if (map) this.controller.route();
    }
    return this.controller.getPlan();
  }
  
  updateLeafletElement(props) {
    const { unit, event, show, overlayClicked} = this.props;
    
    if (this.controller && overlayClicked === true) {
      if (show) {
        this.controller.setWaypoints([L.latLng(unit), L.latLng(event)]);
      } else {
        this.controller.setWaypoints([]);
      }
     this.controller.route();
    }
  }
}
export default withLeaflet(Routing);
