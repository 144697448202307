import React from "react";
import { format } from "date-fns";
import Wrapper from "../../hoc/Wrapper/Wrapper";

// Page Component
import MetIcon from "../../assets/img/icons/Threshold-Met.svg";
import NotMetIcon from "../../assets/img/icons/Threshold-NotMet.svg";
import styles from "./Availability.module.scss";

/*
 * This component is used to show the week view of a paging group availabilty threshold
 * It appears in the admin group details & (views/Admin/GroupDetailsView.jsx)
 * availability "dashboard" overview & (views/Availability/AvailabilityOverview.jsx)
 * above dashboard (views/Dashboard/DashboardView.jsx)
 *
 * this component allows 7 or 14 days, hence show a week or a fortnight
 * you can also show short date or long date format. eg. Mon or Monday
 */

export default class GroupThreshold extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      overview: this.props.overview,
    };
  }
  /*
   * This function renders week view for the paging group threshold
   * it renders a column of days
   * this is used in group details (views/Admin/GroupDetailsView.jsx)
   */
  renderWeek() {
    if (this.props.threshold) {
      return this.props.threshold.map((item, key) => (
        <li
          key={key}
          className={item.thresholdReached === true ? styles.reached : ""}>
          <span className={styles.primaryText + " " + styles.dayofweek}>
            {format(new Date(item.date), "iiii")}
          </span>
          <span className="subText">
            {format(new Date(item.date), "dd/MM/yyyy")}
          </span>
          {item.thresholdReached === true ? (
            <img className={styles.icon} src={MetIcon} alt="Threshold Met" />
          ) : (
            <img
              className={styles.icon}
              src={NotMetIcon}
              alt="Threshold Has Not been Met"
            />
          )}
        </li>
      ));
    }
  }
  /*
   * This function renders week view for the availability overview
   * it renders a row of days
   * this is used in availability "dashboard" overview & (views/Availability/AvailabilityOverview.jsx)
   * above dashboard (views/Dashboard/DashboardView.jsx)
   */
  renderOverview() {
    return this.props.threshold.map((item, key) => (
      <Wrapper key={key}>
        {/*
         * firstOne adds the headers to the first row
         * this will add the dates (04/05) above each day (Monday)
         */}
        {this.props.firstOne === true ? (
          <li>
            <span className={styles.header}>
              {format(new Date(item.date), "dd/MM")}
            </span>

            <span
              className={[
                "grey6 ",
                styles.primaryText,
                styles.dayofweek,
                item.thresholdReached === true && styles.reached,
              ].join(" ")}>
              {item.thresholdReached === true ? (
                <img
                  className={styles.icon}
                  src={MetIcon}
                  alt="Threshold Met"
                />
              ) : (
                <img
                  className={styles.icon}
                  src={NotMetIcon}
                  alt="Threshold Has Not been Met"
                />
              )}
              {/*
               * This shorten the day - iii = Mon, iiii = Monday
               * The shorten version is used when you are showing 14 days instead of 7 days
               */}
              {this.props.shorten === true
                ? format(new Date(item.date), "iii")
                : format(new Date(item.date), "iiii")}
            </span>
          </li>
        ) : (
          // This is no the first row and therefore does not need a header of dates
          <li>
            <span
              className={[
                "grey6 ",
                styles.primaryText,
                styles.dayofweek,
                item.thresholdReached === true && styles.reached,
              ].join(" ")}>
              {item.thresholdReached === true ? (
                <img
                  className={styles.icon}
                  src={MetIcon}
                  alt="Threshold Met"
                />
              ) : (
                <img
                  className={styles.icon}
                  src={NotMetIcon}
                  alt="Threshold Has Not been Met"
                />
              )}
              {/*
               * This shorten the day - iii = Mon, iiii = Monday
               * The shorten version is used when you are showing 14 days instead of 7 days
               */}
              {this.props.shorten === true
                ? format(new Date(item.date), "iii")
                : format(new Date(item.date), "iiii")}
            </span>
          </li>
        )}
      </Wrapper>
    ));
  }

  render() {
    return (
      <Wrapper>
        {/* this decides if you need the week to be display as a column or row */}
        {this.state.overview === true ? (
          <ul className={styles.overview + " " + styles.week}>
            {this.renderOverview()}
          </ul>
        ) : (
          <ul className={styles.groupThreshold}>{this.renderWeek()}</ul>
        )}
      </Wrapper>
    );
  }
}
