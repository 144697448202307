import React from "react";
import Wrapper from "../../hoc/Wrapper/Wrapper";
// Page Component
import Accordion from "../UI/Accordion/Accordion";
import styles from "./Availability.module.scss";
// Requests for API calls

export default class ResultList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      results: this.props.results,
      missingToggle: false,
      available: {},
      unavailable: {},
      availableOther: {},
      unavailableOther: {},
    };
  }

  renderAvailable() {
    if (this.props.results) {
      if (this.state.results.available.length > 0) {
        return this.state.results.available.map((item, key) => (
          <li key={item.userInfo.userId}>
            <div>
              <span className={styles.person} itemProp="person">
                <span className={styles.name} itemProp="name">
                  {item.userInfo.name}
                </span>
              </span>
            </div>
            {typeof item.userQualifications == "object" &&
            item.userQualifications.length > 1 ? (
              // IF there is more than one qualification loop through them and make a list
              <Wrapper>
                <ul
                  className={
                    styles.skillList +
                    " " +
                    styles.close +
                    " " +
                    (this.state.available[key] ? styles.hide : styles.show)
                  }
                  {...{
                    onClick: () =>
                      this.setState({
                        available: {
                          ...this.state.available,
                          [key]: !this.state.available[key],
                        },
                      }),
                  }}
                >
                  {/* shorten list of four item showing first two words */}
                  {item.userQualifications.slice(0, 4).map((skill) => (
                    <li key={skill.id}>
                      {skill.name.split(" ").slice(0, 2).join(" ")}
                    </li>
                  ))}
                </ul>

                <ul
                  className={
                    styles.skillList +
                    " " +
                    styles.open +
                    " " +
                    (this.state.available[key] ? styles.show : styles.hide)
                  }
                  {...{
                    onClick: () =>
                      this.setState({
                        available: {
                          ...this.state.available,
                          [key]: !this.state.available[key],
                        },
                      }),
                  }}
                >
                  {/* full list */}
                  {item.userQualifications.map((skill) => (
                    <li key={skill.id}>{skill.name}</li>
                  ))}
                </ul>
              </Wrapper>
            ) : typeof item.userQualifications == "object" &&
              item.userQualifications.length === 1 ? (
              <p
                className={styles.noSkills}
                key={item.userQualifications[0].id}
              >
                {item.userQualifications[0].name}
              </p>
            ) : (
              <p className={styles.noSkills}>No qualification</p>
            )}

            {typeof item.userAvailabilityTypes == "object" &&
              item.userAvailabilityTypes !== null && (
                <p>
                  {item.userAvailabilityTypes.map((types) => (
                    <span>{types} | </span>
                  ))}
                </p>
              )}
          </li>
        ));
      } else {
        return <li>No available members</li>;
      }
    }
  }
  renderAvailableOther() {
    if (this.props.results) {
      if (this.state.results.availableOther.length > 0) {
        return this.state.results.availableOther.map((item, key) => (
          <li key={item.userInfo.userId}>
            <div>
              <span className={styles.person} itemProp="person">
                <span className={styles.name} itemProp="name">
                  {item.userInfo.name}
                </span>
              </span>
            </div>
            {typeof item.userQualifications == "object" &&
            item.userQualifications.length > 1 ? (
              // IF there is more than one qualification loop through them and make a list
              <Wrapper>
                <ul
                  className={
                    styles.skillList +
                    " " +
                    styles.close +
                    " " +
                    (this.state.availableOther[key] ? styles.hide : styles.show)
                  }
                  {...{
                    onClick: () =>
                      this.setState({
                        availableOther: {
                          ...this.state.availableOther,
                          [key]: !this.state.availableOther[key],
                        },
                      }),
                  }}
                >
                  {/* shorten list of four item showing first two words */}
                  {item.userQualifications.slice(0, 4).map((skill) => (
                    <li key={skill.id}>
                      {skill.name.split(" ").slice(0, 2).join(" ")}
                    </li>
                  ))}
                </ul>

                <ul
                  className={
                    styles.skillList +
                    " " +
                    styles.open +
                    " " +
                    (this.state.availableOther[key] ? styles.show : styles.hide)
                  }
                  {...{
                    onClick: () =>
                      this.setState({
                        availableOther: {
                          ...this.state.availableOther,
                          [key]: !this.state.availableOther[key],
                        },
                      }),
                  }}
                >
                  {" "}
                  {/* full list */}
                  {item.userQualifications.map((skill) => (
                    <li key={skill.id}>{skill.name}</li>
                  ))}
                </ul>
              </Wrapper>
            ) : typeof item.userQualifications == "object" &&
              item.userQualifications.length === 1 ? (
              <p
                className={styles.noSkills}
                key={item.userQualifications[0].id}
              >
                {item.userQualifications[0].name}
              </p>
            ) : (
              <p className={styles.noSkills}>No qualification</p>
            )}

            {typeof item.userAvailabilityTypes == "object" &&
              item.userAvailabilityTypes !== null && (
                <p>
                  {item.userAvailabilityTypes.map((types) => (
                    <span>{types} | </span>
                  ))}
                </p>
              )}
          </li>
        ));
      } else {
        return <li>No other available members</li>;
      }
    }
  }
  renderUnAvailable() {
    if (this.props.results) {
      if (this.state.results.unavailable.length > 0) {
        return this.state.results.unavailable.map((item, key) => (
          <li key={item.userInfo.userId}>
            <div>
              <span className={styles.person} itemProp="person">
                <span className={styles.name} itemProp="name">
                  {item.userInfo.name}
                </span>
              </span>
            </div>
            {typeof item.userQualifications == "object" &&
            item.userQualifications.length > 1 ? (
              // IF there is more than one qualification loop through them and make a list
              <Wrapper>
                <ul
                  className={
                    styles.skillList +
                    " " +
                    styles.close +
                    " " +
                    (this.state.unavailable[key] ? styles.hide : styles.show)
                  }
                  {...{
                    onClick: () =>
                      this.setState({
                        unavailable: {
                          ...this.state.unavailable,
                          [key]: !this.state.unavailable[key],
                        },
                      }),
                  }}
                >
                  {/* shorten list of four item showing first two words */}
                  {item.userQualifications.slice(0, 4).map((skill) => (
                    <li key={skill.id}>
                      {skill.name.split(" ").slice(0, 2).join(" ")}
                    </li>
                  ))}
                </ul>

                <ul
                  className={
                    styles.skillList +
                    " " +
                    styles.open +
                    " " +
                    (this.state.unavailable[key] ? styles.show : styles.hide)
                  }
                  {...{
                    onClick: () =>
                      this.setState({
                        unavailable: {
                          ...this.state.unavailable,
                          [key]: !this.state.unavailable[key],
                        },
                      }),
                  }}
                >
                  {/* full list */}
                  {item.userQualifications.map((skill) => (
                    <li key={skill.id}>{skill.name}</li>
                  ))}
                </ul>
              </Wrapper>
            ) : typeof item.userQualifications == "object" &&
              item.userQualifications.length === 1 ? (
              <p
                className={styles.noSkills}
                key={item.userQualifications[0].id}
              >
                {item.userQualifications[0].name}
              </p>
            ) : (
              <p className={styles.noSkills}>No qualification</p>
            )}

            {typeof item.userAvailabilityTypes == "object" &&
              item.userAvailabilityTypes !== null && (
                <p>
                  {item.userAvailabilityTypes.map((types) => (
                    <span>{types} | </span>
                  ))}
                </p>
              )}
          </li>
        ));
      } else {
        return <li>No unavailable members</li>;
      }
    }
  }

  renderUnAvailableOther() {
    if (this.props.results) {
      if (this.state.results.unavailableOther.length > 0) {
        return this.state.results.unavailableOther.map((item, key) => (
          <li key={item.userInfo.userId}>
            <div>
              <span className={styles.person} itemProp="person">
                <span className={styles.name} itemProp="name">
                  {item.userInfo.name}
                </span>
              </span>
            </div>
            {typeof item.userQualifications == "object" &&
            item.userQualifications.length > 1 ? (
              // IF there is more than one qualification loop through them and make a list
              <Wrapper>
                <ul
                  className={
                    styles.skillList +
                    " " +
                    styles.close +
                    " " +
                    (this.state.unavailableOther[key]
                      ? styles.hide
                      : styles.show)
                  }
                  {...{
                    onClick: () =>
                      this.setState({
                        unavailableOther: {
                          ...this.state.unavailableOther,
                          [key]: !this.state.unavailableOther[key],
                        },
                      }),
                  }}
                >
                  {/* shorten list of four item showing first two words */}
                  {item.userQualifications.slice(0, 4).map((skill) => (
                    <li key={skill.id}>
                      {skill.name.split(" ").slice(0, 2).join(" ")}
                    </li>
                  ))}
                </ul>

                <ul
                  className={
                    styles.skillList +
                    " " +
                    styles.open +
                    " " +
                    (this.state.unavailableOther[key]
                      ? styles.show
                      : styles.hide)
                  }
                  {...{
                    onClick: () =>
                      this.setState({
                        unavailableOther: {
                          ...this.state.unavailableOther,
                          [key]: !this.state.unavailableOther[key],
                        },
                      }),
                  }}
                >
                  {" "}
                  {/* full list */}
                  {item.userQualifications.map((skill) => (
                    <li key={skill.id}>{skill.name}</li>
                  ))}
                </ul>
              </Wrapper>
            ) : typeof item.userQualifications == "object" &&
              item.userQualifications.length === 1 ? (
              <p
                className={styles.noSkills}
                key={item.userQualifications[0].id}
              >
                {item.userQualifications[0].name}
              </p>
            ) : (
              <p className={styles.noSkills}>No qualification</p>
            )}
            {typeof item.userAvailabilityTypes == "object" &&
              item.userAvailabilityTypes !== null && (
                <p>
                  {item.userAvailabilityTypes.map((types) => (
                    <span>{types} | </span>
                  ))}
                </p>
              )}
          </li>
        ));
      } else {
        return <li>No other unavailable members</li>;
      }
    }
  }

  render() {
    return (
      <Wrapper>
        <ul className={styles.results}>{this.renderAvailable()}</ul>
        <Accordion allowMultipleOpen>
          <div key="1111" label="Unavailable at the moment" isOpen={true}>
            <ul className={styles.results}>{this.renderUnAvailable()}</ul>
          </div>
          <div key="2222" label="Available Paging group off" isOpen={true}>
            <ul className={styles.results}>{this.renderAvailableOther()}</ul>
          </div>
          <div key="3333" label="Unavailable paging group off">
            <ul className={styles.results}>{this.renderUnAvailableOther()}</ul>
          </div>
        </Accordion>
      </Wrapper>
    );
  }
}
