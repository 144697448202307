import React from "react";
import Wrapper from "../../hoc/Wrapper/Wrapper";
import ErrorBanner from "../../hoc/ErrorBanner/ErrorBanner";
import Loader from "../../components/UI/Loading/Loading";

// Layouts
import DefaultHeader from "../../components/Headers/DefaultHeader/DeafultHeader";
import Tabs from "../../components/UI/Tabs/Tabs";
import Accordion from "../../components/UI/Accordion/Accordion";
import { Dropdown } from "semantic-ui-react";

import GroupSettings from "./GroupSettings";
import ChatSettings from "./ChatSettings";
import AvailabilityView from "./AvailabilityView";
import QualificationTile from "../../components/Tiles/QualificationTile";
import InsertHelpTile from "../../components/Tiles/InsertHelpTile";

// UIs & styles
import Radio from "../../components/UI/Button/Radio";
import Button from "../../components/UI/Button/Button";
import Checkbox from "../../components/UI/Button/Checkbox";
import Toggle from "../../components/UI/Button/Toggle";
import styles from "./Settings.module.scss";

// Data
import { AuthService } from "../../api/Authentication/AuthService";
import { Requests } from "../../api/IdentityServerRequests/Requests";
import copy from "../../assets/copy/copy.json";

// Images
import SES from "../../assets/img/logos/logo_ses.png";
import CFA from "../../assets/img/logos/logo_cfa.png";
import AV from "../../assets/img/logos/logo_av.png";

export default class SettingsView extends React.Component {
  constructor(props) {
    super(props);

    this.authService = new AuthService();
    this.state = {
      errorMessage: null, // Error message for error banner
      width: window.innerWidth,
      // ASN SECTION
      loadingASN: [true, true, true],
      groupLoading: false,
      addASN: [false, false, false],
      ASNValueList: [], // List of ASNs to check for duplicates,
      viperProfile: null, // Full VIPER profile
      invalidASN: false,
      duplicateASN: false,
      asnLimitReached: true,
      asnLimits: {
        // Maximum number of ASNs allowed for each agency. Change values here if business rules change.
        AV: 1,
        CFA: 1,
        SES: 1,
        DYNAMIC: 0,
      },
      resetASN: false, // will be set to true when the reset ASN confirmation box is open
      // NOTIFICATIONS SECTION
      showGlobal: true, // Used for the state of the radio buttons ("Global" or "Per Group")
      showGlobalToggle: true, // This will just update the toggle and not the UI underneath
      showGroupSettings: false,
      currentSettingID: null,
      notificationPagingGroups: {},
      currentNotificationSettings: null,
      groupNotificationsEditState: false, // Whether global notifications is in edit mode
      chatNotificationsEditState: false, // Whether chat notifications is in edit mode
      // TABS
      activeTab: null,
      currentTab: null,
      currentTabNumber: null,
      // USER INFORMATION AND SETTINGS
      userAgencies: this.props.userAgencies,
      userName: this.props.userName,
      userInformation: this.props.userInfo,
      hasSSOAccount: null,
      hasSSOAccountErrorMessage: null,
      helpSection: [], //Help section for the page
      userSettings: null,
      // DEFAULT NAVIGATION DESTINATION
      userAgencyNavigateToBoolean: {},
      // MUTING PAGING GROUPS
      userPagingGroups: {}, // Stores paging groups the user can has
      AVListSelectAll: null,
      CFAListSelectAll: null,
      SESListSelectAll: null,
      numberOfUnmutedPagingGroups: {},
      allDynamicGroups: [],
      currentDynamic: null,
      // Maintaining the currently opened manage agencies accordions.
      openAccordionAgencies: {
        AV: false,
        CFA: false,
        SES: false,
        DYNAMIC: false,
      },
      shareMyLocation: null, // This is the toggle state for shareMyLocation
      shareMyLocationErrorMessage: false,
      // DEFAULT NAVIGATION APP SECTION
      defaultNavigationApp: null, // User's default navigation app
      navigationApps: null,
      isOzRunwaysExist: false,
      displayPreference: null, // only used to update the UI checkbox
      // DEFAULT MAPPING LAYER SECTION
      defaultPoi: [],
      // TERMS OF USE SECTION
      termsOfUse: false, // Terms of use for the page
      termsOfUseAccordionOpen: true,
      QualList: null,
      heading1: [],
      descriptor: [],
      contentString: [],
      renderAs: [],
      // EDITING PASSWORD SECTION
      showPassword: false, //Whether the user wants to see password they are entering
      editingPassword: false, // Whether the user is currently editing their password
      passwordLoading: false, // Whether the loader is displayed for password section
      displayPasswordRequirements: false,
      passwordStatus: null,
      passwordCriteria: {
        // Different criteria for password validity (true=criteria met, false=criteria not met)
        length: false,
        uppercase: false,
        lowercase: false,
        number: false,
        symbol: false,
      },

      removeAgencyModal: { agency: null },
      removingAgency: { agency: null },
      successfulAgencyUnlink: null, // will be set to true when user has successfully unlinked an agency - will show a message in UI
      notificationMuteTimeframes: [
        { key: 0, text: "Please select", value: 0 },
        { key: 1, text: "Indefinitely", value: -1 },
        { key: 2, text: "1 hour", value: 1 },
        { key: 3, text: "4 hours", value: 4 },
        { key: 4, text: "8 hours", value: 8 },
        { key: 5, text: "12 hours", value: 12 },
        { key: 6, text: "24 hours", value: 24 },
      ],
      notificationMuteTime: 0, // Default value is 0
      hasNotificationsMuted: null, // API call will determine this
      hasNotificationsMutedErrorMessage: null,
      mobilePushNotificationMuteUntil: null, // API call will determine how long are push notifications are muted for
      muteNotificationsErrorMessage: null,
    };
    this.Requests = new Requests();
    this.platformStr = this.getDevicePlatform();
    this.qualificationsRef = React.createRef();
    this.helpSectionRef = React.createRef();
    this.termsOfUseRef = React.createRef();
    this.locationDisclosureRef = React.createRef();
    this.androidAutoDisclosureRef = React.createRef();
    this.isAVtabMobile = this.props.match.url === "/notifications";
    this.handleNotificationRadio = this.handleNotificationRadio.bind(this);
    this.getUserSettings();
    this.getUserASNList();
  }

  componentDidMount() {
    this.Requests.checkIfForceLogoutIsEnabled();
    window.scrollTo(0, 0);
    window.addEventListener("resize", this.handleWindowSizeChange);
    window.addEventListener("orientationchange", this.handleWindowSizeChange);
    window.sessionStorage.setItem("redirectHomeFromEvent", "");
    // Switch to the correct tab
    if (
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.tab
    ) {
      this.updateTab(this.props.match.params.tab);
    }

    // If on mobile device, get user's navigation apps
    if (window.cordova) {
      this.getNavigationApps();
      this.getDefaultNavigationApp();
      this.getMuteNotificationsStatus();
      this.getAndroidAutoDisclosure();
    }

    this.getOwnSSOAccount();

    // Checking if share my location settings
    // if (window.device) {
    //   let userID = null;
    //   if (this.state.userInformation) {
    //     userID = this.state.userInformation.userId;
    //   }
    //   if (window.localStorage.getItem(userID + "shareMyLocation") === "true") {
    //     this.setState({
    //       shareMyLocation: true,
    //     });
    //   } else {
    //     this.setState({
    //       shareMyLocation: false,
    //     });
    //   }
    // }

    // obtain notification settings on tablet
    if ((this.state.width > 1023 && window.cordova) || this.isAVtabMobile) {
      this.getGlobalNotificationSettings(true);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      typeof this.props.match.params.tab !== "undefined" &&
      typeof prevProps.match.params.tab !== "undefined" &&
      this.props.match.params.tab !== prevProps.match.params.tab
    ) {
      this.updateTab(this.props.match.params.tab);
    }
  }

  /**
   *
   * This function will be called whenever a new tab is clicked (which leads to the URL changing)
   * This also updates where the blue underline slider sits under the tab.
   * It will update the current tab
   * Change number in the copy file to change the placement of the slider which highlight which tab is active
   * 0 far left, 1 middle, 2 far right
   */
  updateTab(taburls) {
    let showAvailability =
      this.props.userAgencies &&
      (this.props.userAgencies.includes("CFA") ||
        this.props.userAgencies.includes("SES"))
        ? true
        : false;
    if (taburls === copy.taburls.profile) {
      this.setState({
        currentTab: copy.settings.profile.profile,
        currentTabNumber: copy.tabIndices.profile,
      });
    } else if (taburls === copy.taburls.availability) {
      this.setState({
        currentTab: copy.settings.availability.availTitle,
        currentTabNumber: copy.tabIndices.availability,
      });
    } else if (taburls === copy.taburls.notifications) {
      let notificationsTabNumber = copy.tabIndices.notifications;
      if (!showAvailability) {
        notificationsTabNumber = 1;
      }
      this.setState(
        {
          currentTab: copy.settings.notifications.notificationTitle,
          currentTabNumber: notificationsTabNumber,
        },
        () => {
          this.getGlobalNotificationSettings(true);
        }
      );
    } else {
      this.props.history.push({
        pathname: "/*",
        state: { errorMessage: copy.error_page.errorMessage },
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
    window.removeEventListener(
      "orientationchange",
      this.handleWindowSizeChange
    );
  }

  handleWindowSizeChange = () => {
    const newWidth = window.innerWidth;
    this.setState({ width: newWidth });
  };

  getDevicePlatform = () => {
    if (window.device) {
      return window.device.platform.toLowerCase();
    } else {
      return "browser";
    }
  };

  /**
   * Called when the page needs to be refreshed
   * Will only remake API calls which have failed initially
   */
  handleRefresh = () => {
    this.getUserSettings();
    if (!this.state.viperProfile) {
      this.getUserASNList();
    }
    if (
      window.cordova &&
      !this.state.hasNotificationsMuted &&
      !this.state.mobilePushNotificationMuteUntil
    ) {
      this.getMuteNotificationsStatus();
    }
    if (this.state.hasSSOAccount === null) {
      this.getOwnSSOAccount();
    }
  };

  /**
   * Gets all current user settings from the backend
   */
  getUserSettings = () => {
    this.Requests.callAPI(this.Requests.getUserSettings).then((data) => {
      if (data && data.data && data.status && data.status === 200) {
        this.setState(
          {
            userSettings: data.data,
            shareMyLocation:
              data.data.shareMyLocation !== null
                ? data.data.shareMyLocation
                : "error",
            defaultPoi: data.data.defaultPointsOfInterest,
            androidAutoPermission: data.data.androidAutoTermsOfUseAccepted,
          },
          () => {
            // for Mobile to get default nav apps (OzRunways addition)
            if (window.cordova) {
              this.getDefaultNavigationApp();
            }
          }
        );
      } else {
        let ErrorMessage =
          copy.settings.profile.getUserSettingsAPIErrorMessage +
          ` (Error #${copy.errorCodes.userInfoSettingsAPIErrorMessage})`;
        if (data && data.data && data.data.SASMessageClient) {
          ErrorMessage = data.data.SASMessageClient;
        }
        this.setState(
          { errorMessage: ErrorMessage, userSettings: false },
          () => {
            setTimeout(
              function () {
                this.setState({ errorMessage: null });
              }.bind(this),
              5000
            );
          }
        );
      }
    });
  };

  /**
   * This function makes an API call to see if the user has other SSO accounts linked
   */
  getOwnSSOAccount = () => {
    this.Requests.callAPI(this.Requests.getOwnSSOAccount).then((data) => {
      if (data && data.status && data.status === 200) {
        this.setState({
          hasSSOAccount: data.data,
          hasSSOAccountErrorMessage: null,
        });
      } else {
        let ErrorMessage =
          copy.settings.profile.getUserSettingsAPIErrorMessageNoRetry;
        // if (data && data.data && data.data.SASMessageClient) {
        //   ErrorMessage = data.data.SASMessageClient;
        // }
        this.setState(
          {
            // errorMessage: ErrorMessage,
            hasSSOAccountErrorMessage: ErrorMessage,
          },
          () => {
            setTimeout(
              function () {
                this.setState({ errorMessage: null });
              }.bind(this),
              5000
            );
          }
        );
      }
    });
  };

  /**
   * This function is triggered when the user clicks on the Link Account button
   */
  addAccount() {
    this.authService.login(false);
  }

  /**
   * This function is called when the accordion for an agency is clicked
   */
  handleMutingPagingGroup = (agency) => {
    // Change dynamic group name to be the same as variables
    agency === "Dynamic Paging Groups" && (agency = "DYNAMIC");
    // Maintain if the accordion is open for that agency.
    this.setState(
      {
        ...this.state,
        openAccordionAgencies: {
          ...this.state.openAccordionAgencies,
          [agency]: !this.state.openAccordionAgencies[agency],
        },
      },
      () => {
        // Only call APIs if the accordions are opened.
        if (this.state.openAccordionAgencies[agency]) {
          if (agency !== "DYNAMIC") {
            // Dynamic groups do not have default navigation destination so they do not call that API.
            this.getUserAgencyNavigateTo(agency);
          }
          // Getting currently enabled paging groups.
          this.getUserAgencyPagingGroup(agency, "mute");
        }
      }
    );
  };
  /**
   * Gets all the paging groups for the particular agency that the user can see
   * @param {String} agencyRequests - agency we are getting paging groups for
   * @param {String} section - the section of the app these paging groups are for (muting or notifications)
   */
  getUserAgencyPagingGroup = (agencyRequested, section) => {
    let pagingGroups = null; // Object to store the user's paging groups for each agency
    let numberOfUnmutedPagingGroupsLocal = null;
    const body = { agency: [agencyRequested] };
    this.Requests.callAPI(this.Requests.postUserAgencyPagingGroups, body).then(
      (data) => {
        if (data && data.status && data.status === 200) {
          // Creating the paging groups to be displayed for that agency
          numberOfUnmutedPagingGroupsLocal = data.data.length;
          if (agencyRequested === "DYNAMIC") {
            this.setState({ allDynamicGroups: [] });
          }

          let currentDynamic = null;

          //Sets the paging groups for the agency
          pagingGroups = data.data.map((item, index) => {
            if (!item.isMuted) {
              numberOfUnmutedPagingGroupsLocal--;
            }
            //ismuted when true means that the checkbox is not ticked, mutable is if true means they can mute
            //when checked it means we want to see the messages

            if (section === "mute") {
              // For muting paging groups section
              if (item.agency === "DYNAMIC") {
                // For dynamic paging groups
                let allDynamicGroups = this.state.allDynamicGroups;
                allDynamicGroups.push({
                  pagingNumber: item.number,
                  isMuted: true,
                });
                this.setState({
                  allDynamicGroups: allDynamicGroups,
                });
                !item.isMuted && (currentDynamic = item.number);
                return (
                  <li key={index}>
                    <Radio
                      name={item.agency + "GroupList"}
                      label={item.name.replace(/_/g, " ")}
                      checked={!item.isMuted}
                      disabled={item.settings.mutable ? false : true}
                      onChange={() => {
                        this.changePagingGroupMute(
                          item.settings.mutable,
                          item.number,
                          true,
                          item.agency,
                          true
                        );
                      }}
                    />
                  </li>
                );
              } else {
                return { item, index };
              }
            } else if (section === "notifications") {
              // For the notifications section
              return (
                <li key={index}>
                  <span>{item.name.replace(/_/g, " ")}</span>
                  <Button
                    label="Edit"
                    content={copy.global.btnEdit}
                    variant="btn_outline"
                    styles="greenTxt"
                    buttonClick={this.openNotificationSetting}
                    paramVal={item}
                  />
                </li>
              );
            } else {
              return {};
            }
          });

          if (currentDynamic === null) {
            this.setState({ currentDynamic: "None" });
          } else {
            this.setState({ currentDynamic: currentDynamic });
          }

          // Checks to see if we need to click select all
          let numberOfCheckedBoxes = data.data.length;
          // Find out how many paging groups have been muted for the particular agency
          data.data.forEach((item) => {
            if (item.isMuted) {
              numberOfCheckedBoxes--;
            }
          });

          if (section === "mute") {
            // Updates the states
            this.setState((prevState) => {
              return {
                numberOfUnmutedPagingGroups: {
                  ...prevState.numberOfUnmutedPagingGroups,
                  [agencyRequested]: numberOfUnmutedPagingGroupsLocal,
                },
                userPagingGroups: {
                  ...prevState.userPagingGroups,
                  [agencyRequested]: pagingGroups,
                },
                [agencyRequested + "ListSelectAll"]:
                  numberOfCheckedBoxes === data.data.length ? true : false,
              };
            });
          } else if (section === "notifications") {
            this.setState((prevState) => {
              return {
                notificationPagingGroups: {
                  ...prevState.notificationPagingGroups,
                  [agencyRequested]: pagingGroups,
                },
              };
            });
          }
        } else {
          let ErrorMessage =
            copy.settings.profile.pagingGroupHideShowInfoAPIErrorMessage +
            ` (Error #${copy.errorCodes.pagingGroupHideShowInfoAPIErrorMessage})`;
          // if (data && data.data && data.data.SASMessageClient) {
          //   ErrorMessage = data.data.SASMessageClient;
          // }
          this.setState({ errorMessage: ErrorMessage }, () => {
            setTimeout(
              function () {
                this.setState({ errorMessage: null });
              }.bind(this),
              5000
            );
          });
        }
      }
    );
  };

  /**
   * This function loops through the paging groups of an agency and creates
   * a checkbox for each of them
   */
  createCheckbox = (agency) => {
    let pagingGroup = this.state.userPagingGroups[agency];
    return pagingGroup.map((item, index) => {
      return (
        <li key={index} className="pagingGroupsListItem">
          <Checkbox
            name={item.item.agency + "GroupList"}
            label={item.item.name.replace(/_/g, " ")}
            checked={!item.item.isMuted || !item.item.settings.mutable}
            disabled={item.item.settings.mutable ? false : true}
            onChange={() => {
              this.changePagingGroupMute(
                item.item.settings.mutable,
                item.item.number,
                item.item.isMuted,
                agency
              );
            }}
          />
        </li>
      );
    });
  };

  savePagingGroupUpdates = (agency) => {
    // Creating of the API call body
    let pagingGroupsBody = [];

    for (let j = 0; j < this.state.userPagingGroups[agency].length; j++) {
      let pagingGroup = this.state.userPagingGroups[agency][j];
      pagingGroupsBody.push({
        pagingNumber: pagingGroup.item.number,
        isMuted: pagingGroup.item.isMuted,
      });
    }

    this.setState({ [agency + "ListSelectAll"]: null }, () => {
      this.Requests.callAPI(
        this.Requests.setPagingGroupMuteMultiple,
        pagingGroupsBody
      ).then((data) => {
        if (data && data.status && data.status === 200) {
          this.getUserAgencyPagingGroup(agency, "mute");
        } else {
          let ErrorMessage =
            copy.settings.profile.hidingPagingGroupsAPIErrorMessage +
            ` (Error #${copy.errorCodes.hidingPagingGroupsAPIErrorMessage})`;
          // if (data && data.data && data.data.SASMessageClient) {
          //   ErrorMessage = data.data.SASMessageClient;
          // }
          this.setState(
            {
              errorMessage: ErrorMessage,
            },
            () => {
              setTimeout(
                function () {
                  this.setState({
                    errorMessage: null,
                  });
                }.bind(this),
                5000
              );
            }
          );
        }
      });
    });
  };

  /**
   * This function will mute/unmute all the paging groups of the specified agency
   */
  selectAllList = (agency) => {
    // Flag of whether to unmute or mute all paging groups
    let flag = this.state[agency + "ListSelectAll"];

    let formattedAgencyArray = this.state.userPagingGroups[agency].map(
      (obj) => {
        obj = {
          ...obj,
          item: {
            ...obj.item,
            isMuted:
              flag !== obj.item.isMuted && obj.item.settings.mutable && flag,
          },
        };
        return obj;
      }
    );

    this.setState({
      [agency + "ListSelectAll"]: !this.state[agency + "ListSelectAll"],
      userPagingGroups: { [agency]: formattedAgencyArray },
    });

    // // Creating of the API call body
    // let pagingGroupsBody = [];

    // for (let j = 0; j < this.state.userPagingGroups[agency].length; j++) {
    //   let pagingGroup = this.state.userPagingGroups[agency][j];

    //   if (
    //     flag !== pagingGroup.item.isMuted &&
    //     pagingGroup.item.settings.mutable
    //   ) {
    //     pagingGroupsBody.push({
    //       pagingNumber: pagingGroup.item.number,
    //       isMuted: flag,
    //     });
    //   }
    // }

    // Make API call to mute/unmute all the paging groups under that agency
    // Set the state of the agency select all to null first to ensure that the loader appears
    // this.setState({ [agency + "ListSelectAll"]: null }, () => {
    //   this.Requests.callAPI(
    //     this.Requests.setPagingGroupMuteMultiple,
    //     pagingGroupsBody
    //   ).then((data) => {
    //     if (data && data.status && data.status === 200) {
    //       this.getUserAgencyPagingGroup(agency, "mute");
    //     } else {
    //       let ErrorMessage =
    //         copy.settings.profile.hidingPagingGroupsAPIErrorMessage;
    //       if (data && data.data && data.data.SASMessageClient) {
    //         ErrorMessage = data.data.SASMessageClient;
    //       }
    //       this.setState({ errorMessage: ErrorMessage }, () => {
    //         setTimeout(
    //           function () {
    //             this.setState({ errorMessage: null });
    //           }.bind(this),
    //           5000
    //         );
    //       });
    //     }
    //   });
    // });
  };

  /**
   * This function unmutes/mutes a single paging group
   */
  changePagingGroupMute = (
    mutable,
    pagingGroupNumber,
    flag,
    agency,
    muteCurrent
  ) => {
    // If the paging group is mutable, make the API call to mute it
    if (mutable) {
      if (muteCurrent) {
        let allDynamicGroups = this.state.allDynamicGroups;
        this.Requests.callAPI(
          this.Requests.setPagingGroupMuteMultiple,
          allDynamicGroups
        ).then((data) => {
          if (data && data.status && data.status === 200) {
            if (flag) {
              this.changePagingGroupMute(
                mutable,
                pagingGroupNumber,
                true,
                agency
              );
            }
          } else {
            let ErrorMessage =
              copy.settings.profile.hidingPagingGroupsAPIErrorMessage +
              ` (Error #${copy.errorCodes.hidingPagingGroupsAPIErrorMessage})`;
            // if (data && data.data && data.data.SASMessageClient) {
            //   ErrorMessage = data.data.SASMessageClient;
            // }
            this.setState({ errorMessage: ErrorMessage }, () => {
              setTimeout(
                function () {
                  this.setState({ errorMessage: null });
                }.bind(this),
                5000
              );
            });
          }
        });
      } else {
        if (agency === "DYNAMIC") {
          this.Requests.callAPI(
            this.Requests.setPagingGroupMute,
            pagingGroupNumber,
            !flag
          ).then((data) => {
            if (data && data.status && data.status === 200) {
              this.getUserAgencyPagingGroup(agency, "mute");
            } else {
              let ErrorMessage =
                copy.settings.profile.hidingPagingGroupsAPIErrorMessage +
                ` (Error #${copy.errorCodes.hidingSinglePagingGroupsAPIErrorMessage})`;
              // if (data && data.data && data.data.SASMessageClient) {
              //   ErrorMessage = data.data.SASMessageClient;
              // }
              this.setState({ errorMessage: ErrorMessage }, () => {
                setTimeout(
                  function () {
                    this.setState({ errorMessage: null });
                  }.bind(this),
                  5000
                );
              });
            }
          });
        } else {
          const pagingGroupIndex = this.state.userPagingGroups[
            agency
          ].findIndex((element) => element.item.number === pagingGroupNumber);
          let newAgencyArray = [...this.state.userPagingGroups[agency]];
          newAgencyArray[pagingGroupIndex] = {
            ...newAgencyArray[pagingGroupIndex],
            item: {
              ...newAgencyArray[pagingGroupIndex].item,
              isMuted: !newAgencyArray[pagingGroupIndex].item.isMuted,
            },
          };

          this.setState({
            userPagingGroups: { [agency]: newAgencyArray },
            [agency + "ListSelectAll"]: newAgencyArray.filter(
              (pagingGroup) => pagingGroup.item.isMuted === true
            )
              ? false
              : true,
          });
        }
      }

      // Set the state of the agency's select all to null so that the loader appears
      // this.setState(
      //   {
      //     [agency + "ListSelectAll"]: agency === "DYNAMIC" ? false : null,
      //   },
      //   () => {

      //     // if (muteCurrent) {
      //     //   let allDynamicGroups = this.state.allDynamicGroups;
      //     //   this.Requests.callAPI(
      //     //     this.Requests.setPagingGroupMuteMultiple,
      //     //     allDynamicGroups
      //     //   ).then((data) => {
      //     //     if (data && data.status && data.status === 200) {
      //     //       if (flag) {
      //     //         this.changePagingGroupMute(
      //     //           mutable,
      //     //           pagingGroupNumber,
      //     //           true,
      //     //           agency
      //     //         );
      //     //       }
      //     //     } else {
      //     //       let ErrorMessage =
      //     //         copy.settings.profile.hidingPagingGroupsAPIErrorMessage;
      //     //       if (data && data.data && data.data.SASMessageClient) {
      //     //         ErrorMessage = data.data.SASMessageClient;
      //     //       }
      //     //       this.setState({ errorMessage: ErrorMessage }, () => {
      //     //         setTimeout(
      //     //           function () {
      //     //             this.setState({ errorMessage: null });
      //     //           }.bind(this),
      //     //           5000
      //     //         );
      //     //       });
      //     //     }
      //     //   });
      //     // } else {
      //     //   this.Requests.callAPI(
      //     //     this.Requests.setPagingGroupMute,
      //     //     pagingGroupNumber,
      //     //     !flag
      //     //   ).then((data) => {
      //     //     if (data && data.status && data.status === 200) {
      //     //       this.getUserAgencyPagingGroup(agency, "mute");
      //     //     } else {
      //     //       let ErrorMessage =
      //     //         copy.settings.profile.hidingPagingGroupsAPIErrorMessage;
      //     //       if (data && data.data && data.data.SASMessageClient) {
      //     //         ErrorMessage = data.data.SASMessageClient;
      //     //       }
      //     //       this.setState({ errorMessage: ErrorMessage }, () => {
      //     //         setTimeout(
      //     //           function () {
      //     //             this.setState({ errorMessage: null });
      //     //           }.bind(this),
      //     //           5000
      //     //         );
      //     //       });
      //     //     }
      //     //   });
      //     // }
      //   }
      // );
    }
  };

  /**
   * Gets the current navigateTo of a particular agency of the current user (station/unit or event)
   */
  getUserAgencyNavigateTo = (agencyRequested) => {
    this.Requests.callAPI(
      this.Requests.getUserNavigateToSettings,
      agencyRequested
    ).then((data) => {
      if (data && data.status && data.status === 200) {
        this.setState((prevState) => {
          return {
            userAgencyNavigateToBoolean: {
              ...prevState.userAgencyNavigateToBoolean,
              [agencyRequested]: data.data === "Station",
            },
          };
        });
      } else {
        let ErrorMessage =
          copy.settings.profile.getNavigateToAPIErrorMessage +
          ` (Error #${copy.errorCodes.getNavigateToAPIErrorMessage})`;
        // if (data && data.data && data.data.SASMessageClient) {
        //   ErrorMessage = data.data.SASMessageClient;
        // }
        this.setState({ errorMessage: ErrorMessage }, () => {
          setTimeout(
            function () {
              this.setState({ errorMessage: null });
            }.bind(this),
            5000
          );
        });
      }
    });
  };
  /**
   * This function changes the user's default navigation
   */
  changeNavigationDestination = (agency, buttonClicked, toStation) => {
    if (buttonClicked === "Station") {
      if (!this.state.userAgencyNavigateToBoolean[agency]) {
        this.setChangeNavigationDestination(agency, toStation);
      }
    } else if (buttonClicked === "Event") {
      if (this.state.userAgencyNavigateToBoolean[agency]) {
        this.setChangeNavigationDestination(agency, toStation);
      }
    }
  };
  /**
   * This function updates the database with the user's selected default navigation
   */
  setChangeNavigationDestination = (agency, toStation) => {
    let destination;
    if (toStation) {
      destination = "Station";
    } else {
      destination = "Event";
    }

    // Maked an API call to change the user's destination
    this.Requests.callAPI(
      this.Requests.changeNavigationDestination,
      agency,
      destination
    ).then((data) => {
      if (data.status !== 200) {
        let ErrorMessage =
          copy.settings.profile.setNavigateToAPIErrorMessage +
          ` (Error #${copy.errorCodes.setNavigateToAPIErrorMessage})`;
        // if (data && data.data && data.data.SASMessageClient) {
        //   ErrorMessage = data.data.SASMessageClient;
        // }
        this.setState(
          {
            errorMessage: ErrorMessage,
          },
          () => {
            setTimeout(
              function () {
                this.setState({
                  errorMessage: null,
                });
              }.bind(this),
              5000
            );
          }
        );
      }
      this.getUserAgencyNavigateTo(agency);
    });
  };

  /**
   * This function gets the name of app in plugin format
   */
  getNavAppName = (appName) => {
    let pluginName;
    switch (appName) {
      case "Google":
        pluginName = "google_maps";
        break;
      case "Waze":
        pluginName = "waze";
        break;
      case "Here":
        pluginName = "here_maps";
        break;
      case "Apple":
        pluginName = "apple_maps";
        break;
      default:
        break;
    }

    return pluginName;
  };

  getAndroidAutoDisclosure = () => {
    this.Requests.callAPI(
      this.Requests.getCurrentAcceptedTermsOfUseAndroidAuto
    ).then((data) => {
      if (data?.status === 200) {
        // if user is opening SAS and viewing the event details page, don't load home too
        this.setState({
          termsconditionsID: data.data.id,
          androidAutoDisclosureText: data.data.Payload,
        });
      } else {
        let ErrorMessage =
          copy.settings.profile.androidAutoPermissionError +
          ` (Error #${copy.errorCodes.androidAutoPermissionError})`;

        this.setState(
          {
            errorMessage: ErrorMessage,
          },
          () => {
            setTimeout(
              function () {
                this.setState({ errorMessage: null });
              }.bind(this),
              5000
            );
          }
        );
      }
    });
  };
  /**
   * This function gets all the navigation apps available on the user's device
   */
  getNavigationApps = () => {
    const ln = window.launchnavigator;
    // All the navigation apps SAS supports
    const supportedApps = [
      ln.APP.GOOGLE_MAPS,
      ln.APP.HERE_MAPS,
      ln.APP.WAZE,
      ln.APP.APPLE_MAPS,
    ];
    // Plugin function to get a list of whether certain navigation apps
    // have been installed on the user's device
    ln.availableApps((results) => {
      let navigationApps = [];

      // check if OzRunways is available
      if (window.device.platform.toLowerCase() === "ios") {
        window.plugins.launcher.canLaunch(
          { uri: "ozwpt://" },
          () => this.setState({ isOzRunwaysExist: true }),
          (error) => console.log("OzRunways not available")
        );
      }

      for (var app in results) {
        // If the app is installed and it is an app that SAS supports, add to the list
        if (results[app] && supportedApps.indexOf(app) !== -1) {
          navigationApps.push(app);
        }
      }
      // If there is only one navigation app installed, then it should be set as the default
      if (navigationApps.length === 1 && !this.state.isOzRunwaysExist) {
        ln.appSelection.userChoice.set(navigationApps[0]);
        this.setState({
          navigationApps: navigationApps,
          defaultNavigationApp: navigationApps[0],
        });
      } else if (navigationApps.length === 0 && this.state.isOzRunwaysExist) {
        this.setState({
          defaultNavigationApp: "OzRunways",
        });
      } else {
        this.setState({ navigationApps: navigationApps });
      }
    });
  };
  /**
   * This function gets the user's default navigation app
   */
  getDefaultNavigationApp = () => {
    const ln = window.launchnavigator;
    // Checks if the user has already set a default navigation app
    // and updates state accordingly
    if (
      this.state?.userSettings?.defaultNavigationApplication === "OzRunways" &&
      window.device.platform.toLowerCase() === "ios" &&
      this.state.isOzRunwaysExist
    ) {
      this.setState({ defaultNavigationApp: "OzRunways" });
    } else {
      ln.appSelection.userChoice.exists((exists) => {
        if (exists) {
          ln.appSelection.userChoice.get((app) => {
            if (this.state.navigationApps.indexOf(app) !== -1) {
              this.setState({
                defaultNavigationApp: app,
              });
            }
          });
        } else {
          this.setState({ defaultNavigationApp: null });
        }
      });
    }
  };

  setDefaultNavigationApp = (appName) => {
    this.Requests.callAPI(this.Requests.changeDefaultNavigationApp, {
      navigationdefaultApplication: appName,
    }).then((data) => {
      if (data.status !== 200) {
        let ErrorMessage =
          copy.settings.profile.setDefaultNavAppErrorMessage +
          ` (Error #${copy.errorCodes.setDefaultNavAppErrorMessage})`;
        this.setState({ errorMessage: ErrorMessage }, () => {
          setTimeout(
            function () {
              this.setState({ errorMessage: null });
            }.bind(this),
            5000
          );
        });
      }
    });
  };

  /**
   * This function allows the user to change their default navigation app
   * It uses the launchnavigator plugin
   */
  changeDefaultNavigationApp = (appName) => {
    let platform = window.device.platform.toLowerCase();
    const ln = window.launchnavigator;
    switch (appName) {
      case ln.APP.GOOGLE_MAPS:
        ln.appSelection.userChoice.set(ln.APP.GOOGLE_MAPS);
        this.setState(
          {
            defaultNavigationApp: ln.APP.GOOGLE_MAPS,
          },
          () => {
            if (platform === "ios") this.setDefaultNavigationApp(null);
          }
        );
        break;
      case ln.APP.WAZE:
        ln.appSelection.userChoice.set(ln.APP.WAZE);
        this.setState({ defaultNavigationApp: ln.APP.WAZE }, () => {
          if (platform === "ios") this.setDefaultNavigationApp(null);
        });
        break;
      case ln.APP.HERE_MAPS:
        ln.appSelection.userChoice.set(ln.APP.HERE_MAPS);
        this.setState(
          {
            defaultNavigationApp: ln.APP.HERE_MAPS,
          },
          () => {
            if (platform === "ios") this.setDefaultNavigationApp(null);
          }
        );
        break;
      case ln.APP.APPLE_MAPS:
        ln.appSelection.userChoice.set(ln.APP.APPLE_MAPS);
        this.setState(
          {
            defaultNavigationApp: ln.APP.APPLE_MAPS,
          },
          () => {
            if (platform === "ios") this.setDefaultNavigationApp(null);
          }
        );
        break;
      case "OzRunways":
        ln.appSelection.userChoice.clear();
        ln.appSelection.userPrompted.clear();
        this.setState(
          {
            defaultNavigationApp: "OzRunways",
          },
          () => {
            if (platform === "ios") this.setDefaultNavigationApp("OzRunways");
          }
        );
        break;
      case null:
        ln.appSelection.userChoice.clear();
        ln.appSelection.userPrompted.clear();
        this.setState(
          {
            defaultNavigationApp: null,
          },
          () => {
            if (platform === "ios") this.setDefaultNavigationApp(null);
          }
        );
        break;
      default:
        break;
    }
  };

  /**
   * This function allows the user to set their default map layer
   */
  handleDefaultMapLayer = (layer) => {
    let layerName;
    switch (layer) {
      case "mapscape":
        layerName = "Mapscape_Colour";
        break;
      case "mapscape greyscale":
        layerName = "Mapscape_Greyscale";
        break;
      case "melway":
        layerName = "Melway";
        break;
      case "vicmap aerial":
        layerName = "Vicmap_Aerial";
        break;
      case "satellite imagery":
        layerName = "Satellite_Imagery";
        break;
      case null:
        layerName = null;
        break;
      default:
        layerName = null;
        break;
    }
    if (layerName) {
      this.Requests.callAPI(
        this.Requests.setDefaultMappingLayer,
        layerName
      ).then((data) => {
        if (
          data !== null &&
          data !== undefined &&
          data.status !== null &&
          data.status !== undefined &&
          data.status !== 200
        ) {
          let ErrorMessage =
            copy.settings.profile.setDefaultMapAPIErrorMessage +
            ` (Error #${copy.errorCodes.setDefaultMapAPIErrorMessage})`;
          // if (data && data.data && data.data.SASMessageClient) {
          //   ErrorMessage = data.data.SASMessageClient;
          // }
          this.setState({ errorMessage: ErrorMessage }, () => {
            setTimeout(
              function () {
                this.setState({ errorMessage: null });
              }.bind(this),
              5000
            );
          });
        } else {
          // updates which toggle is checked based on selection
          this.getUserSettings();
        }
      });
    }
  };

  /**
   * This function allows the user to set their default Points of Interests layer
   */
  handleDefaultPoILayer = (layer) => {
    let defaultPoi = [...this.state.defaultPoi];
    // check if PoI has been selected
    let index = defaultPoi.indexOf(layer);
    index !== -1 ? defaultPoi.splice(index, 1) : defaultPoi.push(layer);
    this.setState(
      {
        defaultPoi: defaultPoi,
      },
      () => {
        this.Requests.callAPI(
          this.Requests.setDefaultPoiLayer,
          defaultPoi
        ).then((data) => {
          if (
            data !== null &&
            data !== undefined &&
            data.status !== null &&
            data.status !== undefined &&
            data.status !== 200
          ) {
            let ErrorMessage =
              copy.settings.profile.setDefaultMapAPIErrorMessage +
              ` (Error #${copy.errorCodes.setDefaultMapAPIErrorMessage})`;
            this.setState({ errorMessage: ErrorMessage }, () => {
              setTimeout(
                function () {
                  this.setState({ errorMessage: null });
                }.bind(this),
                5000
              );
            });
          } else {
            // updates which toggle is checked based on selection
            this.getUserSettings();
          }
        });
      }
    );
  };

  /**
   * This function gets all the user's qualifications
   */
  getQuals = () => {
    this.Requests.callAPI(this.Requests.getUsersQuals).then((data) => {
      if (data && data.status && data.status === 200) {
        this.setState({ QualList: data.data }, () => {
          window.scrollTo(0, this.qualificationsRef.current.offsetTop);
        });
      } else {
        let ErrorMessage =
          copy.settings.profile.getQualAPIErrorMessage +
          ` (Error #${copy.errorCodes.getQualAPIErrorMessage})`;
        // if (data && data.data && data.data.SASMessageClient) {
        //   ErrorMessage = data.data.SASMessageClient;
        // }
        this.setState({ errorMessage: ErrorMessage, QualList: [] }, () => {
          setTimeout(
            function () {
              this.setState({ errorMessage: null });
            }.bind(this),
            5000
          );
        });
      }
    });
  };
  /**
   * This function gets the help section
   */
  getHelpSection = () => {
    this.Requests.callAPI(this.Requests.getHelpSectionToSettings).then(
      (data) => {
        if (data && data.status && data.status === 200) {
          this.setState({ helpSection: data.data }, () => {
            window.scrollTo(0, this.helpSectionRef.current.offsetTop);
          });
        } else {
          let ErrorMessage =
            copy.settings.profile.getHelpAPIErrorMessage +
            ` (Error #${copy.errorCodes.getHelpAPIErrorMessage})`;
          this.setState({ errorMessage: ErrorMessage }, () => {
            setTimeout(
              function () {
                this.setState({ errorMessage: null });
              }.bind(this),
              5000
            );
          });
        }
      }
    );
  };

  /**
   * This function gets the current terms of use
   */
  getTermsOfUse = () => {
    this.Requests.callAPI(this.Requests.getCurrentAcceptedTermsOfUse).then(
      (data) => {
        if (data && data.status && data.status === 200) {
          this.setState({ termsOfUse: data.data }, () => {
            window.scrollTo(0, this.termsOfUseRef.current.offsetTop);
          });
        } else {
          let ErrorMessage =
            copy.settings.profile.getTOUAPIErrorMessage +
            ` (Error #${copy.errorCodes.getTOUAPIErrorMessage})`;
          // if (data && data.data && data.data.SASMessageClient) {
          //   ErrorMessage = data.data.SASMessageClient;
          // }
          this.setState({ errorMessage: ErrorMessage }, () => {
            setTimeout(
              function () {
                this.setState({ errorMessage: null });
              }.bind(this),
              5000
            );
          });
        }
      }
    );
  };

  /**
   * Get user ASN list
   */
  getUserASNList = () => {
    this.Requests.callAPI(this.Requests.getUserVIPERProfile).then((data) => {
      if (data && data.status && data.status === 200) {
        this.setState(
          {
            loadingASN: [false, false, false],
            viperProfile: data.data,
          },
          () => {
            // Checks if user has exceeded ASN limit.
            this.checkASNLimit();
          }
        );
      } else {
        let ErrorMessage =
          copy.settings.profile.getASNAPIErrorMessage +
          ` (Error #${copy.errorCodes.getASNAPIErrorMessage})`;
        // if (data && data.data && data.data.SASMessageClient) {
        //   ErrorMessage = data.data.SASMessageClient;
        // }

        this.setState(
          { errorMessage: ErrorMessage, viperProfile: false },
          () => {
            setTimeout(
              function () {
                this.setState({ errorMessage: null });
              }.bind(this),
              5000
            );
          }
        );
      }
    });
  };

  //Give permission for Android auto and accept terms of conditions
  acceptAndroidAuto = () => {
    this.Requests.callAPI(
      this.Requests.termsOfUseAcceptance,
      this.state.termsconditionsID
    ).then((data) => {
      if (data?.status === 200) {
        // Save in localstorage that the user has now accepted/denied terms of use so accessAllowed function in Routes.jsx knows
        window.SasFcmNotificationReceiverPlugin.setAutoStatus(true);
        this.getUserSettings();
      } else {
        let ErrorMessage =
          copy.settings.profile.androidAutoPermissionError +
          ` (Error #${copy.errorCodes.androidAutoPermissionError})`;
        this.setState(
          {
            errorMessage: ErrorMessage,
          },
          () => {
            setTimeout(
              function () {
                this.setState({ errorMessage: null });
              }.bind(this),
              5000
            );
          }
        );
      }
    });
  };
  /**
   * API put method to add ASN to user profile
   */
  addASN = (index) => {
    let ASN;

    const input1 = document.getElementById("ASNInput1")
      ? document.getElementById("ASNInput1").value.toUpperCase()
      : null;

    if (input1) {
      ASN = input1;
    }
    //Check if ASN already exist then display error message
    let isDuplicate = false;

    for (var profile in this.state.viperProfile) {
      for (var asn in this.state.viperProfile[profile].pagers) {
        if (input1 === this.state.viperProfile[profile].pagers[asn].ASN) {
          isDuplicate = true;
        }
      }
    }

    if (isDuplicate) {
      this.setState({ duplicateASN: true }, () => {
        setTimeout(
          function () {
            this.setState({ duplicateASN: false });
          }.bind(this),
          5000
        );
      });
    } else {
      // Adds loading state to ASN being deleted while request is made
      this.setState(
        (state) => {
          const loadingState = state.loadingASN.map((item, j) => {
            if (j === index) {
              return (state.loadingASN[index] = true);
            } else {
              return item;
            }
          });
          return {
            loadingState,
          };
        },
        () => {
          // This function has error handling for PUT, POST & DELETE requests.
          // This function's error handling will NOT work for GET requests
          this.Requests.callAPI(this.Requests.sendSingleUserASN, ASN).then(
            (data) => {
              if (data && data.status && data.status === 200) {
                this.getUserASNList();
                window.localStorage.setItem("updateAgencyAndGroups", "true");
              } else {
                let ErrorMessage = copy.settings.profile.addViperProfileError;
                if (data && data.data && data.data.SASMessageClient) {
                  ErrorMessage = data.data.SASMessageClient;
                  if (data.data.SASENumErrorCode === 17) {
                    this.setState({
                      invalidASN: copy.settings.profile.invalidASN,
                    });
                  } else {
                    this.setState({
                      invalidASN: data.data.SASMessageClient,
                    });
                  }
                }
                this.setState({ errorMessage: ErrorMessage }, () => {
                  setTimeout(
                    function () {
                      this.setState({
                        errorMessage: null,
                        invalidASN: false,
                      });
                    }.bind(this),
                    5000
                  );
                });
                this.getUserASNList();
              }
            }
          );
        }
      );
    }
  };
  /**
   * API put method to delete ASN from user profile
   */
  deleteASN = (viperProfileID, viperProfileIndex) => {
    // Adds loading state to ASN being deleted while request is made
    this.setState(
      (state) => {
        const loadingState = state.loadingASN.map((item, j) => {
          if (j === viperProfileIndex) {
            return (state.loadingASN[viperProfileIndex] = true);
          } else {
            return item;
          }
        });
        return {
          loadingState,
        };
      },
      () => {
        this.Requests.callAPI(
          this.Requests.deleteVIPERProfile,
          viperProfileID
        ).then((data) => {
          if (data && data.status && data.status === 200) {
            this.getUserASNList();
            window.localStorage.setItem("updateAgencyAndGroups", "true");
          } else {
            let ErrorMessage =
              copy.settings.profile.deleteViperProfile +
              ` (Error #${copy.errorCodes.deleteViperProfile})`;
            // if (data && data.data && data.data.SASMessageClient) {
            //   ErrorMessage = data.data.SASMessageClient;
            // }
            this.setState({ errorMessage: ErrorMessage }, () => {
              setTimeout(
                function () {
                  this.setState({ errorMessage: null });
                }.bind(this),
                5000
              );
            });
            this.getUserASNList();
          }
        });
      }
    );
  };

  /**
   * Triggered when the user clicks on reset ASN button and will display a confirmation box
   */
  resetASN = () => {
    this.setState({
      resetASN: true,
    });
  };

  /**
   * API call to hard reset all of the user's linked ASNs after the user confirms it
   */
  confirmASNReset = () => {
    this.Requests.callAPI(this.Requests.deleteAllVIPERProfiles).then((data) => {
      if (data && data.status && data.status === 200) {
        this.getUserASNList();
        window.localStorage.setItem("updateAgencyAndGroups", "true");
      } else {
        let ErrorMessage =
          copy.settings.profile.deleteAllViperProfiles +
          ` (Error #${copy.errorCodes.deleteAllViperProfiles})`;
        this.setState({ errorMessage: ErrorMessage }, () => {
          setTimeout(
            function () {
              this.setState({ errorMessage: null });
            }.bind(this),
            5000
          );
        });
        this.getUserASNList();
      }
      this.setState({
        resetASN: false,
      });
    });
  };

  /**
   * Closes the reset ASN confirmation box
   */
  closeResetASN = () => {
    this.setState({
      resetASN: false,
    });
  };

  /**
   * Triggered when the user clicks the unlink button
   * Will bring up confirmation box
   */
  unlinkAgency = (Agency) => {
    this.setState({
      removeAgencyModal: {
        agency: Agency,
      },
    });
  };
  /**
   * API call to unlink an agency after the user confirms it
   */
  confirmAgencyUnlink = (Agency) => {
    this.setState(
      {
        removingAgency: Agency,
      },
      () => {
        this.Requests.callAPI(this.Requests.deleteUserAgency, Agency).then(
          (data) => {
            if (data && data.status && data.status === 200) {
              let updatedUserAgencies;
              if (this.state.userAgencies.indexOf(Agency) !== -1) {
                updatedUserAgencies = [...this.state.userAgencies];
                updatedUserAgencies.splice(
                  updatedUserAgencies.indexOf(Agency),
                  1
                );
                this.setState({
                  userAgencies: updatedUserAgencies,
                  successfulAgencyUnlink: true,
                });
              }
              window.localStorage.setItem("updateAgencyAndGroups", "true");
            } else {
              let ErrorMessage =
                copy.settings.profile.unlinkAccountAPIErrorMessage +
                ` (Error #${copy.errorCodes.unlinkAccountAPIErrorMessage})`;
              // if (data && data.data && data.data.SASMessageClient) {
              //   ErrorMessage = data.data.SASMessageClient;
              // }
              this.setState(
                {
                  errorMessage: ErrorMessage,
                  removingAgency: null,
                },
                () => {
                  setTimeout(
                    function () {
                      this.setState({ errorMessage: null });
                    }.bind(this),
                    5000
                  );
                }
              );
            }
          }
        );
      }
    );
  };
  /**
   * Closes the unlink agency confirmation box
   */
  closeUnlinkAgencyModal = () => {
    this.setState({
      removeAgencyModal: {
        agency: null,
      },
    });
  };

  /**
   * Allows use to toggle share my location on and off
   */
  toggleTracking = () => {
    if (this.state.shareMyLocation !== null) {
      this.setState(
        (prevState) => ({
          shareMyLocation: !prevState.shareMyLocation,
        }),
        () => {
          this.toggleShareMyLocation(this.state.shareMyLocation);
        }
      );
    }
  };

  toggleShareMyLocation = (flag) => {
    this.Requests.callAPI(this.Requests.setShareMyLocation, flag).then(
      (data) => {
        if (data && data.status && data.status === 200) {
          if (flag === true && window.cordova) {
            // If the user is turning the toggle on, ask for location permission for SAS.
            // It will automatically be successfull if the user has already given permission.
            // Popup to ask for user permission
            window.BackgroundGeolocation.requestPermission().catch(() => {
              this.setState(
                {
                  shareMyLocation: false,
                },
                () => {
                  // Set it back to false in the dB
                  this.toggleShareMyLocation(false);
                }
              );
            });
          } else if (flag === false && window.cordova) {
            // Stop tracking if already tracking.
            window.BackgroundGeolocation.stop();
          }
        } else {
          let ErrorMessage =
            copy.settings.profile.shareMyLocationUpdateError +
            ` (Error #${copy.errorCodes.shareMyLocationUpdateError})`;
          // if (data && data.data && data.data.SASMessageClient) {
          //   ErrorMessage = data.data.SASMessageClient;
          // }
          this.setState(
            {
              errorMessage: ErrorMessage,
              shareMyLocation: !flag,
              shareMyLocationErrorMessage: true,
            },
            () => {
              setTimeout(
                function () {
                  this.setState({ errorMessage: null });
                }.bind(this),
                5000
              );
            }
          );
        }
      }
    );
  };

  // toggleHideAvailability = () => {
  //   let flag;
  //   try {
  //     flag = this.state.userSettings.hideMyAvailability;
  //   } catch {
  //     flag = false;
  //   }

  //   this.Requests.callAPI(this.Requests.setHideAvailability, !flag).then(
  //     (data) => {
  //       if (data && data.status && data.status === 200) {
  //         this.setState({
  //           userSettings: {
  //             ...this.state.userSettings,
  //             hideMyAvailability: !flag,
  //           },
  //         });
  //       } else {
  //         let ErrorMessage;
  //         if (data.data) {
  //           if (data.data.SASMessageClient) {
  //             ErrorMessage = data.data.SASMessageClient;
  //           }
  //         }
  //         this.setState({ errorMessage: ErrorMessage }, () => {
  //           setTimeout(
  //             function () {
  //               this.setState({ errorMessage: null });
  //             }.bind(this),
  //             5000
  //           );
  //         });
  //       }
  //     }
  //   );
  // };

  /**
   * Toggle handler for switching dark mode preferences in local storage
   */
  toggleDarkMode = (currentPreference) => {
    // toggles the current theme
    let currentDisplayTheme = window.localStorage.getItem("displayPreference");
    // checks local storage v/s what the user has clicked to see if its different
    if (currentDisplayTheme !== currentPreference) {
      //  updates local storage
      window.localStorage.setItem("displayPreference", currentPreference);
      // updates which display choice is checked in UI
      this.setState({
        displayPreference: currentPreference,
      });
      this.checkDisplayPreferences();
    }
  };

  /**
   * Check local storage to see the user's appearance preferences
   */
  checkDisplayPreferences = () => {
    // Check display preferences
    let displayPreferences = window.localStorage.getItem("displayPreference");
    if (displayPreferences === "default") {
      // Apply the display preferences from the device (dark mode on launch)
      if (window.device) {
        // Android uses the plugin - matchMedia not supported
        const self = this;
        if (window.cordova.plugins.ThemeDetection) {
          window.cordova.plugins.ThemeDetection.isDarkModeEnabled(function (
            success
          ) {
            // success is either true or false
            if (success.value) {
              self.updateAppTheme("dark");
            } else {
              self.updateAppTheme("light");
            }
          });
        }
      } else if (
        window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches
      ) {
        this.updateAppTheme("dark");
      } else {
        this.updateAppTheme("light");
      }
    } else if (displayPreferences === "light") {
      // Overwrite display preferences and apply light mode
      this.updateAppTheme("light");
    } else if (displayPreferences === "dark") {
      // Overwrite display preferences and apply dark mode
      this.updateAppTheme("dark");
    }
  };

  /**
   * Apply's the theme on the app based on whats passed into the function
   */
  updateAppTheme = (theme) => {
    document
      .getElementsByTagName("HTML")[0]
      .setAttribute("class", "theme-" + theme);

    // Update the status bar in the app
    if (window.cordova && window.device.platform === "iOS") {
      if (theme === "dark") {
        window.StatusBar.backgroundColorByName("black");
        window.StatusBar.styleLightContent();
      } else if (theme === "light") {
        window.StatusBar.backgroundColorByName("white");
        window.StatusBar.styleDefault();
      }
    }
  };

  // changeActiveTab(index) {
  //   this.setState({ activeTab: index });
  // }

  // clearActiveTab() {
  //   this.setState({ activeTab: null });
  // }

  /**
   * This function checks if the new password is valid
   * If it is not valid, it will return an array of error messages
   * If it is valid, then it will return true
   */
  checkPasswordValidity = (newPassword) => {
    let passwordValid = true;
    let passwordRequirements = {};

    // Check if password is at least 8 characters long
    if (newPassword.length < 8) {
      passwordRequirements[
        copy.settings.profile.passwordRequirements.length
      ] = false;
      passwordValid = false;
    } else {
      passwordRequirements[
        copy.settings.profile.passwordRequirements.length
      ] = true;
    }
    // Check if password has at least one lower case
    if (!/[a-z]/.test(newPassword)) {
      passwordRequirements[
        copy.settings.profile.passwordRequirements.lowercase
      ] = false;
      passwordValid = false;
    } else {
      passwordRequirements[
        copy.settings.profile.passwordRequirements.lowercase
      ] = true;
    }
    // Check if password has at least one upper case
    if (!/[A-Z]/.test(newPassword)) {
      passwordRequirements[
        copy.settings.profile.passwordRequirements.uppercase
      ] = false;
      passwordValid = false;
    } else {
      passwordRequirements[
        copy.settings.profile.passwordRequirements.uppercase
      ] = true;
    }
    // Check if password has at least one number
    if (!/[0-9]/.test(newPassword)) {
      passwordRequirements[
        copy.settings.profile.passwordRequirements.number
      ] = false;
      passwordValid = false;
    } else {
      passwordRequirements[
        copy.settings.profile.passwordRequirements.number
      ] = true;
    }
    // Check if password has at least one symbol
    if (!/[!@#$%^&*]/.test(newPassword)) {
      passwordRequirements[
        copy.settings.profile.passwordRequirements.symbol
      ] = false;
      passwordValid = false;
    } else {
      passwordRequirements[
        copy.settings.profile.passwordRequirements.symbol
      ] = true;
    }

    if (passwordValid) {
      return passwordValid;
    } else {
      // // Preparation of error message based on identified errors
      return (
        <div>
          <p>Some password requirements were not met:</p>
          <ul className={styles.password_requirements}>
            {Object.entries(passwordRequirements).map((requirement) => {
              if (!requirement[1]) {
                return <li>{requirement[0]}</li>;
              } else {
                return null;
              }
            })}
          </ul>
        </div>
      );
    }
  };
  /**
   * This function handles what happens when the password edit button is clicked
   */
  handlePasswordEdit = () => {
    this.setState({
      editingPassword: true,
      displayPasswordRequirements: true,
    });
  };
  /**
   * This function handles what happens when user clicks on show password
   */
  showPassword = () => {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  };
  /**
   * This function changes the user's password
   */
  handlePasswordSave = () => {
    // Put loading animation and remove password requirements text
    this.setState(
      {
        passwordLoading: true,
        displayPasswordRequirements: false,
      },
      () => {
        let newPassword = document.getElementById("newPassword").value;
        let passwordValid = this.checkPasswordValidity(newPassword);

        // If the password entered is not valid, then show the error message
        if (passwordValid !== true) {
          this.setState({
            passwordStatus: passwordValid,
            passwordLoading: false,
          });
          // If the password entered is valid, update the user's password
        } else {
          this.Requests.callAPI(
            this.Requests.editOwnPassword,
            newPassword
          ).then((data) => {
            this.setState({
              editingPassword: false,
              passwordLoading: false,
            });
            if (data && data.status && data.status === 200) {
              this.setState(
                {
                  passwordStatus: true,
                  showPassword: false,
                },
                () => {
                  setTimeout(
                    function () {
                      this.setState({ passwordStatus: null });
                    }.bind(this),
                    3000
                  );
                }
              );
            } else if (
              data &&
              data.status &&
              data.status === 400 &&
              data.data.SASENumErrorCode === 13
            ) {
              let passwordValid = this.checkPasswordValidity(newPassword);

              if (passwordValid !== true) {
                this.setState(
                  {
                    passwordStatus: passwordValid,
                  },
                  () => {
                    setTimeout(
                      function () {
                        this.setState({ passwordStatus: null });
                      }.bind(this),
                      2000
                    );
                  }
                );
              }
            } else {
              let ErrorMessage =
                copy.settings.profile.editPasswordAPIErrorMessage +
                ` (Error #${copy.errorCodes.editPasswordAPIErrorMessage})`;
              // if (data && data.data && data.data.SASMessageClient) {
              //   ErrorMessage = data.data.SASMessageClient;
              // }
              this.setState(
                {
                  errorMessage: ErrorMessage,
                  passwordStatus: null,
                },
                () => {
                  setTimeout(
                    function () {
                      this.setState({ errorMessage: null });
                    }.bind(this),
                    5000
                  );
                }
              );
            }
          });
        }
      }
    );
  };

  // Removes timestamp to return a date in yyyy/mm/dd
  fixFormat = (timestamp) => {
    if (timestamp) {
      return timestamp
        .replace(/-/g, "/")
        .replace("T", " ")
        .replace(/\..*|\+.*/, "");
    } else {
      return "";
    }
  };

  getTime = (timestamp) => {
    if (timestamp) {
      let fixedFormat = this.fixFormat(timestamp);
      let time = fixedFormat.substr(10, 6); // Extracts the time in hh:mm to add back later after changing the format
      return time;
    } else {
      return "";
    }
  };

  getDate = (timestamp) => {
    if (timestamp) {
      let yyyymmdd = this.fixFormat(timestamp).substr(0, 10); // Removes the time;
      var datePart = yyyymmdd.match(/\d+/g),
        year = datePart[0],
        month = datePart[1],
        day = datePart[2];
      // returns dd/mm/yyyy
      return day + "/" + month + "/" + year;
    } else {
      return "";
    }
  };

  getYear = (timestamp) => {
    if (timestamp) {
      let yyyymmdd = this.fixFormat(timestamp).substr(0, 10); // Removes the time;
      var datePart = yyyymmdd.match(/\d+/g);
      return datePart[0];
    } else {
      return "";
    }
  };

  getMuteNotificationsStatus = () => {
    let deviceId = window.device.uuid;
    this.Requests.callAPI(this.Requests.getDeviceDetails, deviceId).then(
      (data) => {
        if (data && data.data && data.status && data.status === 200) {
          // Get the mute status and mute duration of current device
          // 0 means the push notifications are not muted.
          let mobilePushNotificationMuteUntil = 0;
          // false means push notifications aren't muted by default
          let hasNotificationsMuted = false;

          if (data.data.mobilePushNotificationMuteUntil !== null) {
            // if null, the push notifications are not muted
            mobilePushNotificationMuteUntil =
              data.data.mobilePushNotificationMuteUntil;
            hasNotificationsMuted = true;
          }

          this.setState({
            hasNotificationsMuted: hasNotificationsMuted,
            hasNotificationsMutedErrorMessage: null,
            mobilePushNotificationMuteUntil: mobilePushNotificationMuteUntil,
            notificationMuteTime: 0, // Default back to Please select
          });
        } else {
          let ErrorMessage =
            copy.settings.profile.mutingNotificationsAPIErrorMessageGET +
            ` (Error #${copy.errorCodes.mutingNotificationsAPIErrorMessageGET})`;
          if (data && data.data && data.data.SASMessageClient) {
            ErrorMessage = data.data.SASMessageClient;
          }
          this.setState(
            {
              errorMessage: ErrorMessage,
              hasNotificationsMutedErrorMessage: ErrorMessage,
            },
            () => {
              setTimeout(
                function () {
                  this.setState({
                    errorMessage: null,
                  });
                }.bind(this),
                5000
              );
            }
          );
        }
      }
    );
  };

  changeMuteTimeVal = (value) => {
    // Updates mute time duration
    this.setState({
      notificationMuteTime: value,
      muteNotificationsErrorMessage: null,
    });
  };

  muteNotifications = () => {
    if (
      this.state.hasNotificationsMuted === false &&
      this.state.notificationMuteTime === 0
    ) {
      // Validation that the user has selected a value in the dropdown
      this.setState(
        {
          muteNotificationsErrorMessage:
            copy.settings.notifications.muteNotificationsValidationError,
          hasNotificationsMuted: false,
        },
        () => {
          setTimeout(
            function () {
              this.setState({
                muteNotificationsErrorMessage: null,
              });
            }.bind(this),
            5000
          );
        }
      );
    } else {
      this.setState(
        {
          hasNotificationsMuted: !this.state.hasNotificationsMuted,
        },
        () => {
          let deviceId = window.device.uuid;
          const muteStatus = this.state.hasNotificationsMuted ? true : false;
          const muteDuration = muteStatus ? this.state.notificationMuteTime : 0;
          let body = {
            MuteHours: muteDuration,
          };

          this.Requests.callAPI(
            this.Requests.mutePushNotifications,
            deviceId,
            body
          ).then((data) => {
            if (data && data.status && data.status === 200) {
              // Your push notifications have been muted.
              // Update the UI to show how when they are muted until.
              this.getMuteNotificationsStatus();
            } else {
              let ErrorMessage =
                copy.settings.profile.mutingNotificationsAPIErrorMessage +
                ` (Error #${copy.errorCodes.mutingNotificationsAPIErrorMessage})`;
              if (data && data.data && data.data.SASMessageClient) {
                ErrorMessage = data.data.SASMessageClient;
              }
              this.setState(
                {
                  muteNotificationsErrorMessage: ErrorMessage,
                  hasNotificationsMuted: false,
                },
                () => {
                  setTimeout(
                    function () {
                      this.setState({
                        muteNotificationsErrorMessage: null,
                      });
                    }.bind(this),
                    5000
                  );
                }
              );
            }
          });
        }
      );
    }
  };

  /**
   * Checks whether the user has entered the maximum allowed number of ASNs for their agency.
   * To change agency limits, change limits in the state.
   */
  checkASNLimit = () => {
    // Keeps track of maximum ASN limit.
    let limit = 0;
    // Whether the limit has been reached by current number of ASNs entered.
    let asnLimitReached = false;
    // Goes through each of user's agencies to get limits for each.
    this.state.userAgencies.forEach((agency) => {
      // User should have a maximum of one ASN profile per agency
      limit = limit + this.state.asnLimits[agency];
    });
    // If the user's amount of ASN's is equal or over the limit.
    if (this.state.viperProfile && this.state.viperProfile.length >= limit) {
      asnLimitReached = true;
    }
    this.setState({
      asnLimitReached: asnLimitReached,
    });
  };

  /**
   * Renders the profile section of settings page
   */
  profile() {
    return (
      <div className={"col-one-third profile_settings " + styles.adminColumn}>
        <div className="Section-titles">
          <h2>Profile</h2>
        </div>
        <div className={styles.scrollableContent}>
          <div className="box_default">
            {/* API failing*/}
            {this.state.userSettings === false ? (
              <div className="settingsWrapper">
                <h4>
                  <span className="noMessages">
                    {copy.settings.profile.getUserSettingsAPIErrorMessage +
                      ` (Error #${copy.errorCodes.userInfoSettingsAPIErrorMessage})`}
                    <div>
                      <Button
                        label="Refresh"
                        content={copy.global.btnRefresh}
                        variant="btn_solid"
                        styles="greyBg"
                        icon="icon_refresh"
                        buttonClick={this.handleRefresh}
                      />
                    </div>
                  </span>
                </h4>
              </div>
            ) : (
              <div className="Sub-Section-titles">
                <h3>{copy.settings.profile.profileTitle}</h3>
              </div>
            )}{" "}
            {this.state.userSettings === false ? null : (
              <div className="toggle_group agencies">
                <div className="link_account">
                  <Button
                    label="Add an account"
                    buttonClick={this.addAccount.bind(this)}
                    content="Link Account"
                    variant="btn_primary "
                    styles="login"
                  />
                </div>
                {this.state.successfulAgencyUnlink === true && (
                  <p className="fadeaway">
                    {copy.settings.profile.unlinkAgencySuccessful}
                  </p>
                )}
                <Accordion
                  allowMultipleOpen={true}
                  // buttonClick={agency => this.handleMutingPagingGroup(agency)}
                  buttonClick={(agency) => {
                    this.handleMutingPagingGroup(agency);
                  }}
                >
                  {this.state.userAgencies.indexOf("AV") !== -1 && (
                    <div
                      key="111"
                      logo={AV}
                      label={"AV"}
                      navigation={true}
                      className="agency_dropdown"
                    >
                      {this.state.userAgencies.length > 2 && (
                        <div className={styles.dropdownAgencyUnlink}>
                          <div className={styles.content}>
                            <p className={styles.title + " txt2"}>
                              {copy.settings.profile.unlinkPagingGroupsTitle}
                            </p>
                            <p>
                              {copy.settings.profile.unlinkPagingGroupsDescAV}
                            </p>
                          </div>
                          <div className={styles.btnHolder}>
                            <div
                              className={[
                                styles.unlinkAgencyModal,
                                "tileBg",
                                this.state.removeAgencyModal.agency &&
                                  this.state.removeAgencyModal.agency ===
                                    "AV" &&
                                  styles.open,
                              ].join(" ")}
                            >
                              <div className={styles.buttonHolder}>
                                {this.state.removingAgency &&
                                this.state.removingAgency === "AV" ? (
                                  <Loader />
                                ) : (
                                  <Wrapper>
                                    <Button
                                      label="Unlink Agency"
                                      buttonClick={() => {
                                        this.confirmAgencyUnlink("AV");
                                      }}
                                      content="Unlink Agency"
                                      variant="btn_outline"
                                      styles="red"
                                      // icon="icon_close"
                                    />
                                    <Button
                                      label="Close"
                                      buttonClick={this.closeUnlinkAgencyModal}
                                      content="Close"
                                      variant="btn_outline grey"
                                      styles=""
                                    />
                                  </Wrapper>
                                )}
                              </div>
                            </div>
                            <Button
                              label="remove ASN"
                              content={copy.global.btnUnlink}
                              variant="btn_outline red"
                              styles="redTxt"
                              buttonClick={() => this.unlinkAgency("AV")}
                            />
                          </div>
                        </div>
                      )}
                      <div
                        className={
                          this.state.userPagingGroups["AV"] &&
                          this.state.userPagingGroups["AV"].length > 0 &&
                          styles.hideshowPagingGroups
                        }
                      >
                        <p className={styles.title + " txt2"}>
                          {copy.settings.profile.hideShowPagingGroupsTitle}
                        </p>
                        {this.state.userPagingGroups["AV"] ? (
                          this.state.userPagingGroups["AV"].length > 0 ? (
                            <p>
                              {copy.settings.profile.hideShowPagingGroupsDesc}
                            </p>
                          ) : (
                            <p>No paging group to edit</p>
                          )
                        ) : null}
                      </div>
                      {this.state.AVListSelectAll === null ? (
                        <div className="inLineloadingContainer">
                          <Loader />
                        </div>
                      ) : (
                        <div>
                          {this.state.userPagingGroups["AV"] &&
                          this.state.userPagingGroups["AV"].length > 0 ? (
                            <div>
                              <div className={styles.selectAll}>
                                <Checkbox
                                  label={copy.global.btnSelectAll}
                                  checked={
                                    this.state.AVListSelectAll ? true : false
                                  }
                                  onChange={() => this.selectAllList("AV")}
                                />
                                <Button
                                  label="Save agency settings"
                                  buttonClick={() => {
                                    this.savePagingGroupUpdates("AV");
                                  }}
                                  content="Save"
                                  variant="btn_outline"
                                  styles="greenTxt"
                                />
                              </div>
                              <ul className={styles.pagingGroups}>
                                {this.createCheckbox("AV")}
                              </ul>
                            </div>
                          ) : null}
                        </div>
                      )}
                    </div>
                  )}
                  {this.state.userAgencies.indexOf("CFA") !== -1 && (
                    <div
                      key="112"
                      logo={CFA}
                      label={"CFA"}
                      className="agency_dropdown"
                      // onClick={(agency = "CFA") =>
                      //   this.handleMutingPagingGroup(agency)
                      // }
                    >
                      {this.state.userAgencies.length > 2 && (
                        <div className={styles.dropdownAgencyUnlink}>
                          <div className={styles.content}>
                            <p className={styles.title + " txt2"}>
                              {copy.settings.profile.unlinkPagingGroupsTitle}
                            </p>
                            <p>
                              {copy.settings.profile.unlinkPagingGroupsDescCFA}
                            </p>
                          </div>
                          <div className={styles.btnHolder}>
                            <div
                              className={[
                                styles.unlinkAgencyModal,
                                "tileBg",
                                this.state.removeAgencyModal.agency &&
                                  this.state.removeAgencyModal.agency ===
                                    "CFA" &&
                                  styles.open,
                              ].join(" ")}
                            >
                              <div className={styles.buttonHolder}>
                                {this.state.removingAgency &&
                                this.state.removingAgency === "CFA" ? (
                                  <Loader />
                                ) : (
                                  <Wrapper>
                                    <Button
                                      label="Unlink Agency"
                                      buttonClick={() => {
                                        this.confirmAgencyUnlink("CFA");
                                      }}
                                      content="Unlink Agency"
                                      variant="btn_outline"
                                      styles="red"
                                      // icon="icon_close"
                                    />
                                    <Button
                                      label="Close"
                                      buttonClick={this.closeUnlinkAgencyModal}
                                      content="Close"
                                      variant="btn_outline grey"
                                      styles=""
                                    />
                                  </Wrapper>
                                )}
                              </div>
                            </div>

                            <Button
                              label="remove ASN"
                              content={copy.global.btnUnlink}
                              variant="btn_outline red"
                              styles="redTxt"
                              buttonClick={() => this.unlinkAgency("CFA")}
                            />
                          </div>
                        </div>
                      )}
                      <div className={styles.agencyNavigationDefault}>
                        <p className={styles.title + " txt2"}>Navigate me to</p>

                        {this.state.userAgencyNavigateToBoolean["CFA"] ===
                          null ||
                        this.state.userAgencyNavigateToBoolean["CFA"] ===
                          undefined ? (
                          <div className="inLineloadingContainer">
                            <Loader />
                          </div>
                        ) : (
                          <div className={"btn_holder " + styles.buttons}>
                            <Radio
                              name="CFADefaultNavigationPreference"
                              // onClick={handleClick}

                              label={copy.settings.profile.CFAstation}
                              id={"station"}
                              paramVal={"station or unit"}
                              {...(this.state.userAgencyNavigateToBoolean["CFA"]
                                ? { checked: true }
                                : { checked: false })}
                              onClick={() => {
                                this.changeNavigationDestination(
                                  "CFA",
                                  "Station",
                                  !this.state.userAgencyNavigateToBoolean["CFA"]
                                );
                              }}
                            />
                            <Radio
                              name="CFADefaultNavigationPreference"
                              // onClick={handleClick}
                              label={copy.settings.profile.event}
                              id={"event"}
                              paramVal={"event"}
                              {...(!this.state.userAgencyNavigateToBoolean[
                                "CFA"
                              ]
                                ? { checked: true }
                                : { checked: false })}
                              onClick={() => {
                                this.changeNavigationDestination(
                                  "CFA",
                                  "Event",
                                  !this.state.userAgencyNavigateToBoolean["CFA"]
                                );
                              }}
                            />
                          </div>
                        )}
                        <p className={styles.subtxt}>
                          {copy.settings.profile.navigateMeTo}
                        </p>
                      </div>
                      <div
                        className={
                          this.state.userPagingGroups["CFA"] &&
                          this.state.userPagingGroups["CFA"].length > 0 &&
                          styles.hideshowPagingGroups
                        }
                      >
                        <p className={styles.title + " txt2"}>
                          {copy.settings.profile.hideShowPagingGroupsTitle}
                        </p>
                        {this.state.userPagingGroups["CFA"] ? (
                          this.state.userPagingGroups["CFA"].length > 0 ? (
                            <p>
                              {copy.settings.profile.hideShowPagingGroupsDesc}
                            </p>
                          ) : (
                            <p>No paging group to edit</p>
                          )
                        ) : null}
                      </div>
                      {this.state.CFAListSelectAll === null ? (
                        <div className="inLineloadingContainer">
                          <Loader />
                        </div>
                      ) : (
                        <div>
                          {this.state.userPagingGroups["CFA"] &&
                            this.state.userPagingGroups["CFA"].length > 0 && (
                              <div>
                                <div className={styles.selectAll}>
                                  <Checkbox
                                    label={copy.global.btnSelectAll}
                                    checked={
                                      this.state.CFAListSelectAll ? true : false
                                    }
                                    onChange={() => this.selectAllList("CFA")}
                                  />
                                  <Button
                                    label="Save agency settings"
                                    buttonClick={() => {
                                      this.savePagingGroupUpdates("CFA");
                                    }}
                                    content="Save"
                                    variant="btn_outline"
                                    styles="greenTxt"
                                  />
                                </div>
                                <ul className={styles.pagingGroups}>
                                  {this.createCheckbox("CFA")}
                                </ul>
                              </div>
                            )}
                        </div>
                      )}
                    </div>
                  )}
                  {this.state.userAgencies.indexOf("SES") !== -1 && (
                    <div
                      key="113"
                      logo={SES}
                      label={"SES"}
                      className="agency_dropdown"
                      // onClick={(agency = "SES") =>
                      //   this.handleMutingPagingGroup(agency)
                      // }
                    >
                      {this.state.userAgencies.length > 2 && (
                        <div className={styles.dropdownAgencyUnlink}>
                          <div className={styles.content}>
                            <p className={styles.title + " txt2"}>
                              {copy.settings.profile.unlinkPagingGroupsTitle}
                            </p>
                            <p>
                              {copy.settings.profile.unlinkPagingGroupsDescSES}
                            </p>
                          </div>
                          <div className={styles.btnHolder}>
                            <div
                              className={[
                                styles.unlinkAgencyModal,
                                "tileBg",
                                this.state.removeAgencyModal.agency &&
                                  this.state.removeAgencyModal.agency ===
                                    "SES" &&
                                  styles.open,
                              ].join(" ")}
                            >
                              <div className={styles.buttonHolder}>
                                {this.state.removingAgency &&
                                this.state.removingAgency === "SES" ? (
                                  <Loader />
                                ) : (
                                  <Wrapper>
                                    <Button
                                      label="Unlink Agency"
                                      buttonClick={() => {
                                        this.confirmAgencyUnlink("SES");
                                      }}
                                      content="Unlink Agency"
                                      variant="btn_outline"
                                      styles="red"
                                      // icon="icon_close"
                                    />
                                    <Button
                                      label="Close"
                                      buttonClick={this.closeUnlinkAgencyModal}
                                      content="Close"
                                      variant="btn_outline grey"
                                      styles=""
                                    />
                                  </Wrapper>
                                )}
                              </div>
                            </div>

                            <Button
                              label="remove ASN"
                              content={copy.global.btnUnlink}
                              variant="btn_outline red"
                              styles="redTxt"
                              buttonClick={() => this.unlinkAgency("SES")}
                            />
                          </div>
                        </div>
                      )}
                      <div className={styles.agencyNavigationDefault}>
                        <p className={styles.title + " txt2"}>Navigate me to</p>

                        {this.state.userAgencyNavigateToBoolean["SES"] ===
                          null ||
                        this.state.userAgencyNavigateToBoolean["SES"] ===
                          undefined ? (
                          <div className="inLineloadingContainer">
                            <Loader />
                          </div>
                        ) : (
                          <div className={"btn_holder " + styles.buttons}>
                            <Radio
                              name="SESDefaultNavigationPreference"
                              // onClick={handleClick}
                              label={copy.settings.profile.SESunit}
                              id={"station"}
                              paramVal={"station or unit"}
                              {...(this.state.userAgencyNavigateToBoolean["SES"]
                                ? { checked: true }
                                : { checked: false })}
                              onClick={() =>
                                this.changeNavigationDestination(
                                  "SES",
                                  "Station",
                                  !this.state.userAgencyNavigateToBoolean["SES"]
                                )
                              }
                            />
                            <Radio
                              name="SESDefaultNavigationPreference"
                              // onClick={handleClick}
                              label={copy.settings.profile.event}
                              id={"event"}
                              paramVal={"event"}
                              {...(!this.state.userAgencyNavigateToBoolean[
                                "SES"
                              ]
                                ? { checked: true }
                                : { checked: false })}
                              onClick={() =>
                                this.changeNavigationDestination(
                                  "SES",
                                  "Event",
                                  !this.state.userAgencyNavigateToBoolean["SES"]
                                )
                              }
                            />
                          </div>
                        )}

                        <p className={styles.subtxt}>
                          {copy.settings.profile.navigateMeTo}
                        </p>
                      </div>
                      <div
                        className={
                          this.state.userPagingGroups["SES"] &&
                          this.state.userPagingGroups["SES"].length > 0 &&
                          styles.hideshowPagingGroups
                        }
                      >
                        <p className={styles.title + " txt2"}>
                          {copy.settings.profile.hideShowPagingGroupsTitle}
                        </p>
                        {this.state.userPagingGroups["SES"] ? (
                          this.state.userPagingGroups["SES"].length > 0 ? (
                            <p>
                              {copy.settings.profile.hideShowPagingGroupsDesc}
                            </p>
                          ) : (
                            <p>No paging group to edit</p>
                          )
                        ) : null}
                      </div>
                      {this.state.SESListSelectAll === null ? (
                        <div className="inLineloadingContainer">
                          <Loader />
                        </div>
                      ) : (
                        <div>
                          {this.state.userPagingGroups["SES"] &&
                          this.state.userPagingGroups["SES"].length > 0 ? (
                            <div>
                              <div className={styles.selectAll}>
                                <Checkbox
                                  label={copy.global.btnSelectAll}
                                  checked={
                                    this.state.SESListSelectAll ? true : false
                                  }
                                  onChange={() => this.selectAllList("SES")}
                                />
                                <Button
                                  label="Save agency settings"
                                  buttonClick={() => {
                                    this.savePagingGroupUpdates("SES");
                                  }}
                                  content="Save"
                                  variant="btn_outline"
                                  styles="greenTxt"
                                />
                              </div>
                              <ul className={styles.pagingGroups}>
                                {this.createCheckbox("SES")}
                              </ul>
                            </div>
                          ) : null}
                        </div>
                      )}
                    </div>
                  )}
                  {this.state.userAgencies.indexOf("DYNAMIC") !== -1 && (
                    <div
                      key="114"
                      label={"Dynamic Paging Groups"}
                      className="agency_dropdown"
                      buttonClick={(agency = "DYNAMIC") =>
                        this.handleMutingPagingGroup(agency)
                      }
                    >
                      <div className={styles.hideshowPagingGroups}>
                        <p className={styles.title + " txt2"}>
                          {copy.settings.profile.hideShowPagingGroupsTitle}
                        </p>
                        <p>{copy.settings.profile.hideShowPagingGroupsDesc}</p>
                      </div>
                      {this.state.DYNAMICListSelectAll === null ||
                      this.state.DYNAMICListSelectAll === undefined ? (
                        <div className="inLineloadingContainer">
                          <Loader />
                        </div>
                      ) : (
                        <div>
                          <div className={styles.selectAll}>
                            <Radio
                              name={"DYNAMICGroupList"}
                              label={"None"}
                              checked={this.state.currentDynamic === "None"}
                              onChange={() => {
                                this.changePagingGroupMute(
                                  true,
                                  "None",
                                  false,
                                  "DYNAMIC",
                                  true
                                );
                              }}
                            />
                          </div>
                          <ul className={styles.pagingGroups}>
                            {this.state.userPagingGroups.DYNAMIC}
                          </ul>
                        </div>
                      )}
                    </div>
                  )}
                </Accordion>
              </div>
            )}
          </div>
          {/* ASN Linking  */}
          {/* API failing*/}
          {this.state.userSettings === false ? null : (
            <div className="box_default">
              <div className="Sub-Section-titles navigation">
                <h3>{copy.settings.profile.pagers}</h3>
                {/* show ASN reset button */}
                {this.state.viperProfile && (
                  <div
                    style={
                      this.state.resetASN
                        ? { visibility: "hidden" }
                        : { visibility: "visible" }
                    }
                  >
                    <Button
                      label="reset"
                      content={copy.global.btnReset}
                      variant="btn_outline red"
                      styles="redTxt"
                      buttonClick={this.resetASN}
                    />
                  </div>
                )}
              </div>
              {this.state.resetASN ? (
                /* reset ASN confirmation box */
                <div>
                  <p>{copy.settings.profile.resetASNConfirmation}</p>
                  <div className={styles.resetASNModal}>
                    <Button
                      label="Reset ASN"
                      buttonClick={this.confirmASNReset}
                      content="Reset ASN"
                      variant="btn_outline"
                      styles="red"
                    />
                    <Button
                      label="Close"
                      buttonClick={this.closeResetASN}
                      content="Close"
                      variant="btn_outline grey"
                      styles=""
                    />
                  </div>
                </div>
              ) : (
                /* ASN linking, removing and listing of linked ASNs */
                <Wrapper>
                  <p>{copy.settings.profile.pagersDesc}</p>
                  {this.state.viperProfile === null ? (
                    <Loader />
                  ) : this.state.viperProfile ? (
                    <Wrapper>
                      {/* Only show ASN input field if currently below ASN limit. */}
                      {this.state.asnLimitReached ? null : (
                        <div
                          id="ASNAdd"
                          className={styles.content + " " + styles.addASN}
                        >
                          <div>
                            <input
                              className="default_input"
                              id="ASNInput1"
                              type="text"
                              placeholder="Empty"
                              maxLength="10"
                            />
                            {this.state.duplicateASN && (
                              <div className={styles.duplicate_ASN_error}>
                                {copy.settings.profile.duplicateASN}
                              </div>
                            )}
                            {this.state.invalidASN && (
                              <div className={styles.duplicate_ASN_error}>
                                {this.state.invalidASN}
                              </div>
                            )}
                          </div>
                          <Button
                            label="save ASN"
                            content={copy.global.btnSave}
                            variant="btn_outline"
                            styles="greenTxt"
                            buttonClick={() => this.addASN(0)}
                          />
                        </div>
                      )}
                      {/* Loop through every viper profile - upto 3 */}
                      {this.state.viperProfile.map(
                        (value, viperProfileIndex) => {
                          return (
                            <div
                              key={viperProfileIndex}
                              className={styles.content}
                            >
                              <span className={styles.title + " txt2"}>
                                Profile {viperProfileIndex + 1}
                              </span>
                              <div className={styles.viperProfiles}>
                                {/* Loops through all the pagers with this VIPER profile */}
                                <div>
                                  {value.pagers.map((asnObject, asnIndex) => {
                                    return (
                                      <div
                                        className={styles.asn}
                                        key={asnIndex}
                                      >
                                        {asnObject.ASN}
                                        <br />
                                        {asnObject.name}
                                      </div>
                                    );
                                  })}
                                </div>
                                {this.state.loadingASN[viperProfileIndex] ? (
                                  <Loader />
                                ) : (
                                  <Button
                                    label="remove ASN"
                                    content={copy.global.btnRemove}
                                    variant="btn_outline red"
                                    styles="redTxt"
                                    buttonClick={() =>
                                      this.deleteASN(
                                        value.id,
                                        viperProfileIndex
                                      )
                                    }
                                  />
                                )}
                              </div>
                            </div>
                          );
                        }
                      )}
                    </Wrapper>
                  ) : (
                    <div
                      className={
                        styles.content +
                        " " +
                        styles.addASN +
                        " " +
                        styles.API_error_text
                      }
                    >
                      <div>
                        {copy.settings.profile.getASNAPIErrorMessage +
                          ` (Error #${copy.errorCodes.getASNAPIErrorMessage})`}
                      </div>
                    </div>
                  )}
                </Wrapper>
              )}
            </div>
          )}
          {this.state.userSettings === false ? null : (
            <div className="box_default">
              {window.cordova ? (
                <div className="Sub-Section-titles navigation">
                  <h3>{copy.settings.profile.navigation}</h3>
                </div>
              ) : null}
              <div className="defaultMapLayer">
                <div className="radio_holder">
                  <Accordion>
                    <div
                      key="115"
                      label={copy.settings.profile.defaultMappingLayerTitle}
                    >
                      <ul>
                        {copy.map.mapLayers.map((value, index) => {
                          let checked = false;
                          if (
                            (this.state.userSettings &&
                              this.state.userSettings.defaultMappingTiles ===
                                value.settingsName) ||
                            (index === 0 &&
                              this.state.userSettings.defaultMappingTiles ===
                                null)
                          ) {
                            checked = true;
                          }
                          return (
                            <li key={index}>
                              <Radio
                                name={"mappingLayer"}
                                onClick={() => {
                                  this.handleDefaultMapLayer(
                                    value.name.toLowerCase()
                                  );
                                }}
                                label={value.name}
                                checked={checked}
                              />
                            </li>
                          );
                        })}
                      </ul>
                      <span>
                        {copy.settings.profile.defaultPointsOfInterest}
                      </span>
                      <ul>
                        {copy.map.poiLayers.map((value, index) => {
                          let checked = false;

                          if (
                            this.state.userSettings.defaultPointsOfInterest.includes(
                              value.key
                            )
                          ) {
                            checked = true;
                          }

                          return (
                            <li key={index}>
                              <Checkbox
                                name={"poiLayer"}
                                label={value.label}
                                value={value.key}
                                onChange={() => {
                                  this.handleDefaultPoILayer(value.key);
                                }}
                                checked={checked}
                                disabled={false}
                              />
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </Accordion>
                </div>
                <p>{copy.settings.profile.defaultMappingLayerDesc}</p>
              </div>
              <div className="defaultNavigation">
                {window.cordova ? (
                  <Accordion>
                    <div
                      key="116"
                      label={copy.settings.profile.defaultNavigationAppTitle}
                    >
                      <div className="radio_holder">
                        {this.state.navigationApps === null ? null : (
                          <div>
                            {this.state.navigationApps.length === 0 ? (
                              <p>{copy.settings.profile.noNavigationApp}</p>
                            ) : (
                              <ul>
                                {this.state.navigationApps.length === 1 &&
                                !this.state.isOzRunwaysExist ? null : (
                                  <li key={0}>
                                    <Radio
                                      name={"navigationApp"}
                                      onClick={() =>
                                        this.changeDefaultNavigationApp(null)
                                      }
                                      label={"No Default"}
                                      {...(this.state.defaultNavigationApp ===
                                      null
                                        ? { checked: true }
                                        : { checked: false })}
                                    />
                                  </li>
                                )}
                                {this.state.navigationApps.map((app, index) => {
                                  let defaultNavigationApp = false;
                                  if (app === this.state.defaultNavigationApp) {
                                    defaultNavigationApp = true;
                                  }
                                  return (
                                    <li key={index + 1}>
                                      <Radio
                                        name={"navigationApp"}
                                        onClick={() =>
                                          this.changeDefaultNavigationApp(app)
                                        }
                                        label={copy.navigationApps[app]}
                                        {...(this.state.defaultNavigationApp ===
                                        null
                                          ? { checked: false }
                                          : {
                                              checked: defaultNavigationApp,
                                            })}
                                      />
                                    </li>
                                  );
                                })}
                                {this.state.isOzRunwaysExist && (
                                  <li
                                    key={this.state.navigationApps.length + 2}
                                  >
                                    <Radio
                                      name={"navigationApp"}
                                      onClick={() =>
                                        this.changeDefaultNavigationApp(
                                          "OzRunways"
                                        )
                                      }
                                      label={copy.navigationApps["OzRunways"]}
                                      {...(this.state.defaultNavigationApp ===
                                      null
                                        ? { checked: false }
                                        : {
                                            checked:
                                              this.state
                                                .defaultNavigationApp ===
                                              "OzRunways",
                                          })}
                                    />
                                  </li>
                                )}
                              </ul>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </Accordion>
                ) : null}
              </div>
              {window.cordova ? (
                <p>{copy.settings.profile.defaultNavigationAppDesc}</p>
              ) : null}
            </div>
          )}
          {/* This is the dark mode section */}
          {this.state.userSettings === false ? null : (
            <div className="box_default">
              <div className="defaultMapLayer">
                <div className="radio_holder">
                  <Accordion>
                    <div
                      key="117"
                      className="Sub-Section-titles"
                      label={copy.settings.profile.darkmodeTitle}
                    >
                      <ul>
                        {copy.settings.profile.displayOptions.map(
                          (value, index) => {
                            let checked = false;
                            // this will check the display preference from local storage
                            let currentTheme =
                              window.localStorage.getItem("displayPreference");
                            if (
                              currentTheme !== null &&
                              currentTheme === value.value
                            ) {
                              checked = true;
                            }
                            return (
                              <li key={index}>
                                <Radio
                                  name={"displayOptions"}
                                  onClick={() => {
                                    this.toggleDarkMode(
                                      value.value.toLowerCase()
                                    );
                                  }}
                                  label={value.name}
                                  checked={checked}
                                />
                              </li>
                            );
                          }
                        )}
                      </ul>
                    </div>
                  </Accordion>
                </div>
                <p>{copy.settings.profile.darkmodeDesc}</p>
              </div>
            </div>
          )}

          {/* This is the privacy section  */}
          {/* API failing*/}
          {this.state.userSettings === false
            ? null
            : window.device && (
                <div className="box_default">
                  <div className="Sub-Section-titles">
                    <h3>{copy.settings.profile.privacy}</h3>
                  </div>
                  {/* <span> */}
                  {this.state.shareMyLocation === "error" ? (
                    <div className={styles.section_content}>
                      <div>
                        <span>{copy.settings.profile.eventTrackingTitle}</span>
                        <p>{copy.settings.profile.shareMyLocationError}</p>
                      </div>
                    </div>
                  ) : (
                    <div className={styles.section_content}>
                      <div>
                        <span>{copy.settings.profile.eventTrackingTitle}</span>
                        <p>{copy.settings.profile.eventTrackingDesc}</p>
                        {this.state.shareMyLocationErrorMessage && (
                          <p className="API_error_text">
                            {copy.settings.profile.shareMyLocationUpdateError +
                              ` (Error #${copy.errorCodes.shareMyLocationUpdateError})`}
                          </p>
                        )}
                      </div>
                      <Toggle
                        checked={this.state.shareMyLocation}
                        fromAdmin
                        buttonClick={this.toggleTracking}
                        label="turn on off event tracking"
                      />
                    </div>
                  )}

                  {/* <div className={styles.section_content}>
                <div>
                  <span>{copy.settings.profile.overrideDNDTitle}</span>
                  <p>{copy.settings.profile.overrideDNDDesc}</p>
                </div>
                <Toggle label="Override Do Not Disturb" />
              </div> */}
                  {/* </span> */}
                  {/* <div className={styles.section_content}>
            <div>
              <span>{copy.settings.profile.hideAvailabilityTitle}</span>
              <p>{copy.settings.profile.hideAvailabilityDesc}</p>
            </div>
            <Toggle
              label="Hide show availability"
              checked={this.state.userSettings.hideMyAvailability}
              buttonClick={this.toggleHideAvailability}
            />
          </div> */}
                </div>
              )}
          {
            /* API failing*/

            this.state.userSettings === false ? null : this.state
                .hasSSOAccount === null ? (
              <div className="box_default">
                <div className="Sub-Section-titles">
                  <h3>{copy.settings.profile.security}</h3>
                </div>
                {this.state.hasSSOAccountErrorMessage ? (
                  <div className={styles.API_error_text}>
                    {this.state.hasSSOAccountErrorMessage}
                  </div>
                ) : (
                  <Loader />
                )}
              </div>
            ) : !this.state.hasSSOAccount ? (
              <div className="box_default">
                <div className="Sub-Section-titles">
                  <h3>{copy.settings.profile.security}</h3>
                </div>
                <div className={styles.edit_password}>
                  <div className={styles.content}>
                    <span className={styles.title + " txt2"}>
                      {copy.settings.profile.editPassword}
                    </span>
                    {this.state.editingPassword ? (
                      <input
                        id="newPassword"
                        type={this.state.showPassword ? "text" : "password"}
                        placeholder="Empty"
                        // maxlength="10"
                      />
                    ) : (
                      <p>{copy.settings.profile.editPasswordDesc}</p>
                    )}
                    {this.state.editingPassword ? (
                      <div>
                        <Checkbox
                          name={copy.settings.profile.showPassword}
                          label={copy.settings.profile.showPassword}
                          onChange={this.showPassword}
                          small={true}
                        />
                      </div>
                    ) : null}
                  </div>
                  {this.state.passwordLoading ? (
                    <Loader />
                  ) : this.state.editingPassword ? (
                    <Button
                      label="save password"
                      content={copy.global.btnSave}
                      variant="btn_outline"
                      styles="greenTxt"
                      buttonClick={this.handlePasswordSave}
                    />
                  ) : (
                    <Button
                      label="edit password"
                      content={copy.global.btnUpdate}
                      variant="btn_outline"
                      styles="greenTxt"
                      buttonClick={this.handlePasswordEdit}
                    />
                  )}
                </div>
                {this.state.displayPasswordRequirements ? (
                  <div>
                    <p>Password requirements:</p>
                    <ul className={styles.password_requirements}>
                      {Object.values(
                        copy.settings.profile.passwordRequirements
                      ).map((item, key) => {
                        return <li key={key}>{item}</li>;
                      })}
                    </ul>
                  </div>
                ) : null}
                <p
                  className={
                    styles.password_status +
                    " " +
                    (this.state.passwordStatus === true ? styles.success : "")
                  }
                >
                  {this.state.passwordStatus === null
                    ? null
                    : this.state.passwordStatus === true
                    ? "Password successfully updated"
                    : this.state.passwordStatus}
                </p>
              </div>
            ) : (
              <div className="box_default">
                <div className="Sub-Section-titles">
                  <h3>{copy.settings.profile.security}</h3>
                </div>
                <div className={styles.edit_password}>
                  <div className={styles.content + styles.sas_only}>
                    <span className={styles.title + " txt2"}>
                      {copy.settings.profile.editPassword}
                    </span>
                    <p>{copy.settings.profile.editPasswordDescNonSAS}</p>
                  </div>
                </div>
              </div>
            )
          }

          {
            /* API failing*/

            this.state.userSettings ===
            false ? null : this.state.userAgencies.includes("CFA") ||
              this.state.userAgencies.includes("SES") ? ( //This checks if the user is only an AV account, if so they should not see qualifications tile
              <div ref={this.qualificationsRef}>
                <QualificationTile
                  QualList={this.state.QualList}
                  getQuals={this.getQuals}
                />
              </div>
            ) : null
          }
          {
            /* API failing*/

            this.state.userSettings === false ? null : (
              <div ref={this.helpSectionRef} className="box_default">
                <InsertHelpTile
                  getHelpSection={this.getHelpSection}
                  sections={this.state.helpSection}
                />
              </div>
            )
          }
          {
            /* API failing*/

            this.state.userSettings === false ? null : (
              <div ref={this.termsOfUseRef} className="box_default">
                <Accordion
                  button={true}
                  label="Close Terms"
                  content={copy.settings.profile.closeTermsOfUse}
                  variant="btn_outline"
                  styles="greenTxt"
                  buttonClick={this.getTermsOfUse}
                >
                  <div key="117" label={copy.settings.profile.termsOfUse}>
                    <p>{this.state.termsOfUse.Payload}</p>
                  </div>
                </Accordion>
              </div>
            )
          }
          {window.cordova && (
            <div ref={this.locationDisclosureRef} className="box_default">
              <Accordion
                label="Location Disclosure"
                buttonClick={() => {
                  window.scrollTo(0, this.termsOfUseRef.current.offsetTop);
                }}
              >
                <div label={copy.settings.profile.locationDisclosure}>
                  <p>
                    <b>{copy.location.disclosure1}</b>
                  </p>
                  <p>{copy.location.disclosure2}</p>
                </div>
              </Accordion>
            </div>
          )}

          {window.cordova &&
            window.cordova.platformId === "android" &&
            (this.state.androidAutoPermission === false ? (
              <div ref={this.androidAutoDisclosureRef} className="box_default">
                <Accordion>
                  <div label={copy.settings.profile.androidAutoDisclosure}>
                    <p>{this.state.androidAutoDisclosureText}</p>
                    <Button
                      label={"Accept Android Auto Terms"}
                      content={copy.settings.profile.turnOnAndroidAuto}
                      variant="btn_outline"
                      styles="greenTxt"
                      buttonClick={() => this.acceptAndroidAuto()}
                    />
                  </div>
                </Accordion>
              </div>
            ) : (
              <div ref={this.androidAutoDisclosureRef} className="box_default">
                <Accordion
                  label="Android Auto Disclosure"
                  buttonClick={() => {
                    window.scrollTo(0, this.termsOfUseRef.current.offsetTop);
                  }}
                >
                  <div label={copy.settings.profile.androidAutoDisclosure}>
                    <p>{this.state.androidAutoDisclosureText}</p>
                  </div>
                </Accordion>
              </div>
            ))}

          <div className="box_default">
            <div className={styles.versionNumber}>
              {copy.settings.profile.sasVersionNumberText}
              {copy.global.sasVersionNumber}
              <br />
              {copy.settings.profile.sasReleaseNumberText}
              {copy.global.sasReleaseNumber}
            </div>
          </div>
        </div>
      </div>
    );
  }

  /**
   * Gets the current settings for a user for a particular paging group
   * Called when a paging group is selected on the notifications tab
   * @param {JSON object} settingID Object containing geolocation, agency, group owner, muted status, name, and paging number of the current agency
   */
  openNotificationSetting = (settingID) => {
    let deviceId = window.device.uuid;

    // Shows GroupSettings component (containing notification settings)
    // currentNotificationSettings will be overwritten with API data
    this.setState({
      showGroupSettings: true,
      currentSettingID: settingID,
      currentNotificationSettings: null,
    });
    // Gets current notifcation settings for settingID.number paging group
    this.Requests.callAPI(
      this.Requests.getPagingNotificationChannel,
      deviceId,
      settingID.number
    ).then((data) => {
      if (data && data.status && data.status === 200) {
        // Check and create the missing channels if an Android device
        this.updateNotificationChannels(data.data);
        // Settings will be passed in as currentNotificationSettings
        this.setState({
          currentNotificationSettings: data.data,
        });
      } else {
        let ErrorMessage =
          copy.settings.notifications
            .getPagingGroupNotificationAPIErrorMessage +
          ` (Error #${copy.errorCodes.getPagingGroupNotificationAPIErrorMessage})`;
        // if (data && data.data && data.data.SASMessageClient) {
        //   ErrorMessage = data.data.SASMessageClient;
        // }
        this.setState(
          {
            errorMessage: ErrorMessage,
          },
          () => {
            setTimeout(
              function () {
                this.setState({
                  errorMessage: null,
                });
              }.bind(this),
              5000
            );
          }
        );
      }
    });
  };

  /**
   * Gets notification settings for global settings
   * @param {boolean} initial True if it is the first time this is called per opening of the notifications tab
   */
  getGlobalNotificationSettings = (intitial) => {
    let deviceId = window.device.uuid;
    this.Requests.callAPI(
      this.Requests.getGlobalNotificationChannel,
      deviceId
    ).then((data) => {
      if (data && data.status && data.status === 200) {
        // Check and create the missing channels if an Android device
        this.updateNotificationChannels(data.data);
        this.setState({
          currentNotificationSettings: data.data,
        });
        // If it's the first time, show the correct tab (global or per group)
        if (intitial) {
          this.setState({
            showGlobal: data.data.useGlobalPreference,
            showGlobalToggle: data.data.useGlobalPreference,
          });
        }
      } else {
        let ErrorMessage =
          copy.settings.notifications.getGlobalNotificationAPIErrorMessage +
          ` (Error #${copy.errorCodes.getGlobalNotificationAPIErrorMessage})`;
        // if (data && data.data && data.data.SASMessageClient) {
        //   ErrorMessage = data.data.SASMessageClient;
        // }
        this.setState(
          {
            errorMessage: ErrorMessage,
            currentNotificationSettings: false,
          },
          () => {
            setTimeout(
              function () {
                this.setState({
                  errorMessage: null,
                });
              }.bind(this),
              5000
            );
          }
        );
      }
    });
  };

  /**
   * Clears all notification settings data, including current group and current settings, and hides GroupSettings component
   */
  closeNotificationSetting = () => {
    this.setState({
      showGroupSettings: false,
      currentSettingID: null,
      currentNotificationSettings: null,
    });
  };

  /**
   * This opens an OS settings window within the app of ID "setting"
   */
  openDeviceSettings = (setting) => {
    if (window.cordova && window.cordova.plugins.settings) {
      window.cordova.plugins.settings.open(setting);
    }
  };

  /**
   * Changes notification tab view between showing just global settings or a list of paging groups grouped by agency
   *  @param {String} applyChange - this will actually update the Backend and apply the change. Required to meet accessibility requirements before make a change
   */
  handleNotificationRadio = (applyChange) => {
    // Closes any open GroupSettings views
    if (applyChange) {
      if (this.state.showGlobal !== this.state.showGlobalToggle) {
        this.deleteNotificationChannels();
      }
      this.closeNotificationSetting();
      this.setState(
        {
          showGlobal: this.state.showGlobalToggle,
          currentNotificationSettings: null,
        },
        () => {
          let notificationType = null;
          // Gets global settings if it is showing global (since per group requires selection of paging group)
          if (this.state.showGlobal) {
            this.getGlobalNotificationSettings();
            notificationType = "Global";
          } else {
            notificationType = "PagingNumber";
          }
          let deviceId = window.device.uuid;
          this.Requests.callAPI(
            this.Requests.setNotificationBy,
            deviceId,
            notificationType
          ).then((data) => {
            if (data.status !== 200) {
              let ErrorMessage =
                copy.settings.notifications
                  .setNotificationPreferenceAPIErrorMessage +
                ` (Error #${copy.errorCodes.setNotificationPreferenceAPIErrorMessage})`;
              // if (data && data.data && data.data.SASMessageClient) {
              //   ErrorMessage = data.data.SASMessageClient;
              // }
              this.setState({ errorMessage: ErrorMessage }, () => {
                setTimeout(
                  function () {
                    this.setState({ errorMessage: null });
                  }.bind(this),
                  5000
                );
              });
            }
          });
        }
      );
    } else {
      // Just update the UI
      this.setState({
        showGlobalToggle: !this.state.showGlobalToggle,
      });
    }
  };

  /**
   * This will check if all the channels from the dB exsist in the mobile device if Android 8 and above
   */
  updateNotificationChannels = (currentChannelsInDb) => {
    if (
      window.device &&
      window.device.platform === "Android" &&
      currentChannelsInDb.messageTypeNotificationSettings &&
      currentChannelsInDb.messageTypeNotificationSettings.length > 0
    ) {
      // List all exsisting channels
      window.FirebasePlugin.listChannels(
        function (channels) {
          if (typeof channels == "undefined") return;
          let dbChannels = currentChannelsInDb.messageTypeNotificationSettings;
          // Loop over all the channels in the dB.
          for (let i = 0; i < dbChannels.length; i++) {
            let needToAddChannel = true;
            for (let j = 0; j < channels.length; j++) {
              if (dbChannels[i].channelId === channels[j].id) {
                needToAddChannel = false;
              }
            }
            if (needToAddChannel) {
              // This creates the channel
              // Fixes the name from id;
              let channelName =
                dbChannels[i].channelId.replace(/_/g, " ").slice(0, -2) +
                " Preferences";
              let channelToCreate = {
                // Channel ID is in format: channel_GLOBAL/GROUPNAME_ALERTTYPE_VERSION
                id: dbChannels[i].channelId,
                description:
                  "This is the highest priority channel with " +
                  dbChannels[i].lightColor +
                  " lights" +
                  (dbChannels[i].vibration ? ", vibration" : "") +
                  " and " +
                  dbChannels[i].sound +
                  " as the sound.",
                name: channelName.toUpperCase(),
                // Sound name needs to have underscores
                sound: dbChannels[i].sound,
                vibration: dbChannels[i].vibration
                  ? [0, 750, 250, 750, 250, 750, 1000, 750, 250, 750, 250, 750]
                  : false,
                light: dbChannels[i].light,
                // This converts the hex value for the light colour into ARGB format
                lightColor: dbChannels[i].lightColor,
                importance: dbChannels[i].importance,
                badge: dbChannels[i].badge,
                visibility: dbChannels[i].visibility,
              };
              window.FirebasePlugin.createChannel(
                channelToCreate,
                function () {
                  // console.log("Chat Channel created: " + channelToCreate.id);
                },
                function (error) {
                  // console.log("Create chat channel error: " + error);
                }
              );
            }
          }
        },
        function (error) {
          console.log("List notifications channels error: " + error);
        }
      );
    }
  };

  /**
   * This will delete all the exsisting channels on the Android devices apart from the default ones.
   */
  deleteNotificationChannels = () => {
    if (
      window.cordova &&
      window.device &&
      window.device.platform === "Android"
    ) {
      // List all exsisting channels
      window.FirebasePlugin.listChannels(
        function (channels) {
          if (typeof channels == "undefined") return;
          for (let i = 0; i < channels.length; i++) {
            // delete the channel if not default
            if (
              channels[i].id !== "channel_chat" &&
              channels[i].id !== "channel_default" &&
              channels[i].id !== "au.gov.vic.emv.sasTSLocationManager"
            ) {
              window.FirebasePlugin.deleteChannel(
                channels[i].id,
                function () {
                  console.log("Channel deleted: " + channels[i].id);
                },
                function (error) {
                  console.log("Delete channel error: " + error);
                }
              );
            }
          }
        },
        function (error) {
          console.log("List notifications channels error: " + error);
        }
      );
    }
  };

  /**
   * This method is used to keep track of whether Chat notifications is being edited
   */
  toggleChatNotificationsEditState = (open) => {
    this.setState({ chatNotificationsEditState: open });
  };

  /**
   * This method is used to keep track of whether Group notifications is being edited
   */
  toggleGroupNotificationsEditState = (open) => {
    this.setState({ groupNotificationsEditState: open });
  };

  /**
   * Contains the UI for the notifications tab in settings
   * Renders lists of user's paging groups for notification by agency
   * The settings themselves are within the GroupSettings component
   */
  notifications = () => {
    let showChatSettings =
      this.props.userAgencies &&
      (this.props.userAgencies.includes("CFA") ||
        this.props.userAgencies.includes("SES"))
        ? true
        : false;

    let dateIsIndefinite =
      this.state.mobilePushNotificationMuteUntil &&
      this.getYear(this.state.mobilePushNotificationMuteUntil) >= 2120;

    return (
      <div className={"col-one-third settings " + styles.adminColumn}>
        <div className="Section-titles">
          {/* Headings */}
          <h2>{copy.settings.notifications.notificationTitle}</h2>
        </div>
        <div className={styles.scrollableContent}>
          <div className="box_default">
            <div className="Sub-Section-titles">
              <h3>Mute Push Notifications</h3>
            </div>
            {this.state.hasNotificationsMuted === null ? (
              this.state.hasNotificationsMutedErrorMessage ? (
                <div className={styles.API_error_text}>
                  {this.state.hasNotificationsMutedErrorMessage}
                </div>
              ) : (
                // The API call is being made
                <div
                  className={
                    styles.loadingContainer +
                    " " +
                    styles.loadingContainerMobile
                  }
                >
                  <Loader />
                </div>
              )
            ) : (
              <Wrapper>
                <div className={styles.muteNotificationsSettings}>
                  {this.state.hasNotificationsMuted ? (
                    // Disable dropdown if notifications muted already
                    <Dropdown
                      fluid
                      selection
                      disabled
                      className={styles.muteNotificationsDropdown}
                      value={this.state.notificationMuteTime}
                      options={this.state.notificationMuteTimeframes}
                      onChange={(e, { value }) => this.changeMuteTimeVal(value)}
                    />
                  ) : (
                    <Dropdown
                      fluid
                      selection
                      className={styles.muteNotificationsDropdown}
                      value={this.state.notificationMuteTime}
                      options={this.state.notificationMuteTimeframes}
                      onChange={(e, { value }) => this.changeMuteTimeVal(value)}
                    />
                  )}
                  <div>
                    <Toggle
                      checked={this.state.hasNotificationsMuted}
                      buttonClick={this.muteNotifications}
                      fromAdmin
                      label="turn on off notification muting"
                    />
                  </div>
                </div>
                {this.state.muteNotificationsErrorMessage !== null && (
                  <p className="fadeaway error">
                    {this.state.muteNotificationsErrorMessage}
                  </p>
                )}
                {this.state.mobilePushNotificationMuteUntil !== null &&
                this.state.mobilePushNotificationMuteUntil !== 0 ? (
                  dateIsIndefinite ? (
                    <p>
                      {copy.settings.notifications.muteNotificationsIndefinite}
                    </p>
                  ) : (
                    <p>
                      {copy.settings.notifications.muteNotificationsActive}{" "}
                      {this.getTime(this.state.mobilePushNotificationMuteUntil)}{" "}
                      on{" "}
                      {this.getDate(this.state.mobilePushNotificationMuteUntil)}
                    </p>
                  )
                ) : (
                  <p>{copy.settings.notifications.muteNotificationsDesc}</p>
                )}
              </Wrapper>
            )}
          </div>
          <div id={"notificationSettings"}>
            <div className="box_default">
              <div className="Sub-Section-titles">
                <h3>{copy.settings.notifications.setNotificationsTitle}</h3>
                <Button
                  label="Save"
                  content={copy.global.btnSave}
                  variant="btn_outline"
                  styles="greenTxt"
                  buttonClick={() => {
                    this.handleNotificationRadio(true);
                  }}
                />
              </div>
              <div className="btn_holder">
                {/* Buttons for either global or group notification preferences */}
                <Radio
                  name={"notificationType"}
                  onChange={() => {
                    this.handleNotificationRadio(false);
                  }}
                  label={copy.settings.notifications.global}
                  id={"globalRadio"}
                  paramVal={"globalRadio"}
                  setChecked={this.state.showGlobalToggle}
                />
                <Radio
                  name={"notificationType"}
                  onChange={() => {
                    this.handleNotificationRadio(false);
                  }}
                  label={copy.settings.notifications.perGroup}
                  id={"groupRadio"}
                  paramVal={"groupRadio"}
                  setChecked={!this.state.showGlobalToggle}
                />
              </div>
              <p>{copy.settings.notifications.setNotificationsDesc}</p>
            </div>
            {/* Show either global settings or all paging groups */}
            {this.state.showGlobal ? (
              // Global settings only have the GroupSettings component

              <div id={"globalSettings"} className="toggle_group">
                <GroupSettings
                  currentSettings={this.state.currentNotificationSettings}
                  toggleGroupNotificationsEditState={(open) => {
                    this.toggleGroupNotificationsEditState(open);
                  }}
                  chatNotificationsEditState={
                    this.state.chatNotificationsEditState
                  }
                />
              </div>
            ) : (
              // Paging group settings show a list of all groups the user is a part of
              <span>
                {/* Show GroupSettings component if a current group has been selected */}
                {this.state.showGroupSettings ? (
                  <GroupSettings
                    pagerGroup={this.state.currentSettingID}
                    buttonClick={this.closeNotificationSetting}
                    currentSettings={this.state.currentNotificationSettings}
                    toggleGroupNotificationsEditState={(open) => {
                      this.toggleGroupNotificationsEditState(open);
                    }}
                    chatNotificationsEditState={
                      this.state.chatNotificationsEditState
                    }
                  />
                ) : (
                  <div id={"groupSettings"}>
                    {/* AV groups dropdown */}
                    {this.state.userAgencies.indexOf("AV") !== -1 && (
                      <div
                        className="box_default"
                        onClick={() =>
                          this.getUserAgencyPagingGroup("AV", "notifications")
                        }
                      >
                        <Accordion>
                          <div
                            key="111"
                            logo={AV}
                            label={copy.settings.notifications.AVsettings}
                          >
                            <ul className={styles.groupList}>
                              {this.state.notificationPagingGroups.AV ? (
                                this.state.notificationPagingGroups.AV
                              ) : (
                                <div className={styles.loadingContainer}>
                                  <Loader />
                                </div>
                              )}
                            </ul>
                          </div>
                        </Accordion>
                      </div>
                    )}
                    {/* CFA groups dropdown */}
                    {this.state.userAgencies.indexOf("CFA") !== -1 && (
                      <div
                        className="box_default"
                        onClick={() =>
                          this.getUserAgencyPagingGroup("CFA", "notifications")
                        }
                      >
                        <Accordion>
                          <div
                            key="111"
                            logo={CFA}
                            label={copy.settings.notifications.CFAsettings}
                          >
                            <ul className={styles.groupList}>
                              {this.state.notificationPagingGroups.CFA ? (
                                this.state.notificationPagingGroups.CFA
                              ) : (
                                <div className={styles.loadingContainer}>
                                  <Loader />
                                </div>
                              )}
                            </ul>
                          </div>
                        </Accordion>
                      </div>
                    )}
                    {/* SES groups dropdown */}
                    {this.state.userAgencies.indexOf("SES") !== -1 && (
                      <div
                        className="box_default"
                        onClick={() =>
                          this.getUserAgencyPagingGroup("SES", "notifications")
                        }
                      >
                        <Accordion>
                          <div
                            key="111"
                            logo={SES}
                            label={copy.settings.notifications.SESsettings}
                          >
                            <ul className={styles.groupList}>
                              {this.state.notificationPagingGroups.SES ? (
                                this.state.notificationPagingGroups.SES
                              ) : (
                                <div className={styles.loadingContainer}>
                                  <Loader />
                                </div>
                              )}
                            </ul>
                          </div>
                        </Accordion>
                      </div>
                    )}
                    {/* Shows a list of dynamic groups */}
                    {this.state.userAgencies.indexOf("DYNAMIC") !== -1 && (
                      <div
                        className="box_default"
                        onClick={() =>
                          this.getUserAgencyPagingGroup(
                            "DYNAMIC",
                            "notifications"
                          )
                        }
                      >
                        <Accordion>
                          <div
                            key="111"
                            label={copy.settings.notifications.DYNAMICsettings}
                          >
                            <ul className={styles.groupList}>
                              {this.state.notificationPagingGroups.DYNAMIC ? (
                                this.state.notificationPagingGroups.DYNAMIC
                              ) : (
                                <div className={styles.loadingContainer}>
                                  <Loader />
                                </div>
                              )}
                            </ul>
                          </div>
                        </Accordion>
                      </div>
                    )}
                  </div>
                )}
              </span>
            )}
            {/* Shows the chat notifications settings */}
            {showChatSettings && (
              <ChatSettings
                toggleChatNotificationsEditState={(open) => {
                  this.toggleChatNotificationsEditState(open);
                }}
                groupNotificationsEditState={
                  this.state.groupNotificationsEditState
                }
              />
            )}
            {/* Shows override do not disturb settings on Android 7 and below devices only */}
            {window.device &&
              window.device.platform === "Android" &&
              window.device.version.split(".")[0] < 8 && (
                <div className="box_default">
                  <div className="Sub-Section-titles">
                    <h3>{copy.settings.notifications.DNDTitle}</h3>
                  </div>
                  <div className={styles.edit_password}>
                    <span className={styles.title + " txt2"}>
                      {copy.settings.notifications.overrideDNDTitle}
                    </span>
                  </div>
                  <p>{copy.settings.notifications.overrideDNDDesc7part1}</p>
                  <p>{copy.settings.notifications.overrideDNDDesc7part2}</p>
                  <Button
                    label="Edit"
                    content={copy.global.btnEdit}
                    variant="btn_outline"
                    styles="greenTxt"
                    buttonClick={() => {
                      this.openDeviceSettings("notification_id");
                    }}
                  />
                </div>
              )}
          </div>
        </div>
      </div>
    );
  };

  render() {
    const isMobile = this.state.width <= 1023;
    let isMobileApp = window.cordova ? true : false;

    let showAvailabilityTab =
      this.props.userAgencies.includes("CFA") ||
      this.props.userAgencies.includes("SES");
    if (isMobile) {
      return (
        <Wrapper>
          <ErrorBanner
            isVisible={this.state.errorMessage ? true : false}
            ErrorMessage={this.state.errorMessage}
            onTop={true}
          />
          <DefaultHeader />
          <div
            className={
              "main-content-holder settings " +
              styles.settingsMainContentWrapper
            }
          >
            {/* The notifications settings are only displayed if its the mobile application. The browser does not see that setting.  */}
            {isMobileApp ? (
              this.isAVtabMobile ? (
                this.notifications()
              ) : showAvailabilityTab ? (
                // show availability tab
                <Tabs
                  showSlider={true}
                  activeTab={this.state.currentTab}
                  activeTabNumber={this.state.currentTabNumber}
                >
                  {this.state.userSettings === false ? (
                    <div
                      content={copy.settings.profile.profile}
                      taburl={copy.taburls.profile}
                    >
                      <h4>
                        <span className="noMessages">
                          {copy.settings.profile.getUserSettingsAPIErrorMessage}
                          <div>
                            <Button
                              label="Refresh"
                              content={copy.global.btnRefresh}
                              variant="btn_solid"
                              styles="greyBg"
                              icon="icon_refresh"
                              buttonClick={this.handleRefresh}
                            />
                          </div>
                        </span>
                      </h4>
                    </div>
                  ) : (
                    <div
                      content={copy.settings.profile.profile}
                      taburl={copy.taburls.profile}
                    >
                      {this.state.userSettings === null ? (
                        <div className="loadingContainer">
                          <Loader />
                        </div>
                      ) : (
                        this.profile()
                      )}
                    </div>
                  )}
                  <div
                    content={copy.settings.availability.availTitle}
                    taburl={copy.taburls.availability}
                  >
                    {this.state.userSettings === null ? (
                      <div className="loadingContainer">
                        <Loader />
                      </div>
                    ) : (
                      <AvailabilityView
                        userAgencies={this.props.userAgencies}
                        pagingGroupsOwned={
                          this.props.userPagingGroupsWithAvailability
                        }
                      />
                    )}
                  </div>
                  <div
                    content={copy.settings.notifications.notificationTitle}
                    taburl={copy.taburls.notifications}
                  >
                    {this.state.userSettings === null ? (
                      <div className="loadingContainer">
                        <Loader />
                      </div>
                    ) : (
                      this.notifications()
                    )}
                  </div>
                </Tabs>
              ) : (
                // dont show availability tab
                <Tabs
                  showSlider={true}
                  activeTab={this.state.currentTab}
                  activeTabNumber={this.state.currentTabNumber}
                >
                  {this.state.userSettings === false ? (
                    <div
                      content={copy.settings.profile.profile}
                      taburl={copy.taburls.profile}
                    >
                      <h4>
                        <span className="noMessages">
                          {copy.settings.profile.getUserSettingsAPIErrorMessage}
                          <div>
                            <Button
                              label="Refresh"
                              content={copy.global.btnRefresh}
                              variant="btn_solid"
                              styles="greyBg"
                              icon="icon_refresh"
                              buttonClick={this.handleRefresh}
                            />
                          </div>
                        </span>
                      </h4>
                    </div>
                  ) : (
                    <div
                      content={copy.settings.profile.profile}
                      taburl={copy.taburls.profile}
                    >
                      {this.state.userSettings === null ? (
                        <div className="loadingContainer">
                          <Loader />
                        </div>
                      ) : (
                        this.profile()
                      )}
                    </div>
                  )}
                  <div
                    content={copy.settings.notifications.notificationTitle}
                    taburl={copy.taburls.notifications}
                  >
                    {this.state.userSettings === null ? (
                      <div className="loadingContainer">
                        <Loader />
                      </div>
                    ) : (
                      this.notifications()
                    )}
                  </div>
                </Tabs>
              )
            ) : showAvailabilityTab ? (
              // Show availability as a tab
              <Tabs
                showSlider={true}
                activeTabIndex={this.state.activeTab}
                clearTab={this.clearActiveTab}
                activeTab={this.state.currentTab}
                activeTabNumber={this.state.currentTabNumber}
              >
                {/* If API failed for profile, display error message*/}
                {this.state.userSettings === false ? (
                  <div
                    content={copy.settings.profile.profile}
                    taburl={copy.taburls.profile}
                  >
                    <h4>
                      <span className="noMessages">
                        {copy.settings.profile.getUserSettingsAPIErrorMessage}
                        <div>
                          <Button
                            label="Refresh"
                            content={copy.global.btnRefresh}
                            variant="btn_solid"
                            styles="greyBg"
                            icon="icon_refresh"
                            buttonClick={this.handleRefresh}
                          />
                        </div>
                      </span>
                    </h4>
                  </div>
                ) : (
                  <div
                    content={copy.settings.profile.profile}
                    taburl={copy.taburls.profile}
                  >
                    {this.state.userSettings === null ? (
                      <div className="loadingContainer">
                        <Loader />
                      </div>
                    ) : (
                      this.profile()
                    )}
                  </div>
                )}
                <div
                  content={copy.settings.availability.availTitle}
                  taburl={copy.taburls.availability}
                >
                  {this.state.userSettings === null ? (
                    <div className="loadingContainer">
                      <Loader />
                    </div>
                  ) : (
                    <AvailabilityView
                      userAgencies={this.props.userAgencies}
                      pagingGroupsPartOf={this.props.pagingGroupsPartOf}
                      pagingGroupsOwned={
                        this.props.userPagingGroupsWithAvailability
                      }
                    />
                  )}
                </div>
              </Tabs>
            ) : // Don't show availability
            this.state.userSettings === false ? (
              <div
                content={copy.settings.profile.profile}
                taburl={copy.taburls.profile}
              >
                <h4>
                  <span className="noMessages">
                    {copy.settings.profile.getUserSettingsAPIErrorMessage}
                    <div>
                      <Button
                        label="Refresh"
                        content={copy.global.btnRefresh}
                        variant="btn_solid"
                        styles="greyBg"
                        icon="icon_refresh"
                        buttonClick={this.handleRefresh}
                      />
                    </div>
                  </span>
                </h4>
              </div>
            ) : (
              <div
                content={copy.settings.profile.profile}
                taburl={copy.taburls.profile}
              >
                {this.state.userSettings === null ? (
                  <div className="loadingContainer">
                    <Loader />
                  </div>
                ) : (
                  this.profile()
                )}
              </div>
            )}
          </div>
        </Wrapper>
      );
    } else if (this.state.userSettings === null) {
      return (
        <div className="loadingContainer">
          <Loader />
        </div>
      );
    } else {
      return (
        <Wrapper>
          <ErrorBanner
            isVisible={this.state.errorMessage ? true : false}
            ErrorMessage={this.state.errorMessage}
          />
          <div
            className={
              "main-content-holder " + styles.settingsMainContentWrapper
            }
          >
            {this.profile()}
            {/* Availability is not available for AV users */}
            {showAvailabilityTab ? (
              <AvailabilityView
                userAgencies={this.props.userAgencies}
                pagingGroupsPartOf={this.props.pagingGroupsPartOf}
                pagingGroupsOwned={this.props.userPagingGroupsWithAvailability}
              />
            ) : null}
            {isMobileApp ? this.notifications() : null}
          </div>
        </Wrapper>
      );
    }
  }
}
