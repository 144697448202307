import React, { Component } from "react";
import Wrapper from "../Wrapper/Wrapper";
import ErrorBanner from "../ErrorBanner/ErrorBanner";
import styles from "./AlertBanner.module.scss";
import "./AlertBanner.module.scss";

import { withRouter } from "react-router-dom";
import { matchPath } from "react-router";

//Response controls
import { ReactComponent as Attending } from "../../assets/img/icons/respond_attending.svg";
import { ReactComponent as Other } from "../../assets/img/icons/respond_other.svg";
import { ReactComponent as Unavailable } from "../../assets/img/icons/respond_unavailable.svg";

// Location tracking
import Button from "../../components/UI/Button/Button";

import { AuthService } from "../../api/Authentication/AuthService";
import { Requests } from "../../api/IdentityServerRequests/Requests";
import copy from "../../assets/copy/copy.json";

// Images
import SES from "../../assets/img/logos/logo_ses.png";
import CFA from "../../assets/img/logos/logo_cfa.png";
import AV from "../../assets/img/logos/logo_av.png";
import EMV from "../../assets/img/logos/logo_emv.svg";
import { ReactComponent as ForwardArrow } from "../../assets/img/icons/forwards-arrow.svg";

var classNames = require("classnames");

class AlertBanner extends Component {
  firebasePlugin;

  constructor(props) {
    super(props);
    this.authService = new AuthService();
    this.state = {
      errorMessage: null,
      eventID: this.props.ID,
      alertBanner: false,
      alertDetails: null,
      // alertDetails: AlertMessageData,
      event: {
        attendingStatus: null,
        eventIsActive: true,
        isAttending: false,
        showResponse: false,
        showAttendingBlock: true,
        showOtherBlock: true,
        showUnavailableBlock: true,
        responseMessage: "",
        showChangeResponseBlock: false,
        showLocationControls: false,
        showLocationSelection: false,
        travelingToUnit: false,
        travelingToEvent: true,
        userAttendance: "",
        navigateTo: "Station",
        eta: "3",
      },
      timerOn: false,
      timerTime: {
        seconds: null,
        minutes: null,
        hours: null,
      },
      media: null,
    };
    this.Requests = new Requests();
    if (window.cordova) {
      this.firebasePlugin = window.FirebasePlugin;
      this.firebasePlugin.onMessageReceived(
        this.onMessageReceived.bind(this),
        this.onMessageReceivedFailure.bind(this)
      );
    }
  }

  componentDidMount() {
    if (this.state.alertDetails !== null) {
      this.Timer();
      if (this.props.eventStatus === "Open") {
        this.interval = setInterval(this.Timer, 1000);
      }
      if (this.state.showResponseControls) {
        this.getUserAttendance();
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  closeAlertBanner = () => {
    if (this.state.media) {
      this.state.media.stop();
    }

    this.setState({
      alertBanner: false,
      event: {
        ...this.state.event,
        isAttending: false,
        showResponse: false,
        showAttendingBlock: true,
        showOtherBlock: true,
        showUnavailableBlock: true,
        responseMessage: "",
        showChangeResponseBlock: false,
        showLocationControls: false,
        showLocationSelection: false,
        userAttendance: "",
      },
      media: null,
    });

    clearInterval(this.interval);
  };

  handlePagerClick = () => {
    const message = this.state.alertDetails;
    this.props.history.push({
      pathname: "/eventdetails/" + message.eventId,
      search:
        "eventStatus=Open" +
        message.alertType +
        "&agency=" +
        message.agency +
        "&eventId=" +
        message.eventId +
        "&PFI=" +
        message.PFI +
        "&cADEventId=" +
        message.cADEventId +
        "&messageInputTimeStamp=" +
        message.messageInputTimeStamp +
        "&messageType=" +
        message.alertType +
        "&pagingName=" +
        message.pagingName +
        "&messageText=" +
        message.messageText,
    });
    window.localStorage.setItem("redirectToEvent", message.eventId);
    this.closeAlertBanner();
  };

  inAppNotif(sound) {
    // check if sound is provided
    if (!(sound === "sas_alert_tone" || sound === null || sound === "null")) {
      // construct file path to tones in regards to devices platform
      const extension =
        window.device.platform.toLowerCase() === "android" ? ".mp3" : ".wav";
      let src =
        window.cordova.file.applicationDirectory +
        "www/tones/" +
        sound +
        extension;
      // for iOS we need to remove file:// to play sound
      if (window.device.platform.toLowerCase() === "ios") {
        src = src.replace("file://", "");
      }
      // construct media object using Media plugin, {playAudioWhenScreenIsLocked:false} -- prevent audio playing when on Silent Mode (iOS only)
      let media = new window.Media(src);
      this.setState({ media: media }, () =>
        this.state.media.play({ playAudioWhenScreenIsLocked: false })
      );
    }
    // iOS only - trigger vibration
    if (window.device.platform.toLowerCase() === "ios") {
      window.navigator.vibrate(3000);
    }
  }

  getFromMobileStorage = (key) =>
    new Promise((resolve, reject) => {
      window.NativeStorage.getString(key, resolve, reject);
    });

  async onMessageReceived(message) {
    let accessToken = await this.getFromMobileStorage("accessToken");
    if (!(accessToken === "null" || accessToken === null)) {
      if (
        message.messageType === "notification" ||
        message.messageType === "data"
      ) {
        if (message.tap !== undefined) {
          //  This is the handler for the situtation when the notification is tapped in the background.
          // If eventId is present, redirect to the event details page
          if (message.eventId !== undefined) {
            this.props.history.push({
              pathname: "/eventdetails/" + message.eventId,
              search:
                "eventStatus=Open" +
                message.alertType +
                "&agency=" +
                message.agency +
                "&eventId=" +
                message.eventId +
                "&PFI=" +
                message.PFI +
                "&cADEventId=" +
                message.cADEventId +
                "&messageInputTimeStamp=" +
                message.messageInputTimeStamp +
                "&messageType=" +
                message.alertType +
                "&pagingName=" +
                message.pagingName +
                "&messageText=" +
                message.messageText,
            });
            window.localStorage.setItem("redirectToEvent", message.eventId);
            // Else if chatId is present, redirect to the chat details page
          } else if (message.chatId !== undefined) {
            this.props.history.push({
              pathname: "/chat/chats/" + message.chatId,
            });
            window.localStorage.setItem("redirectToChat", message.chatId);
          }
        } else {
          if (message.eventId !== undefined) {
            // In app notification alert sound and vibrate
            this.inAppNotif(message.sound);
            // Loads the alert banner only if an eventId is present
            this.setState(
              {
                alertDetails: message,
                event: {
                  ...this.state.event,
                  isAttending: false,
                  showResponse: false,
                  showAttendingBlock: true,
                  showOtherBlock: true,
                  showUnavailableBlock: true,
                  responseMessage: "",
                  showChangeResponseBlock: false,
                  showLocationControls: false,
                  showLocationSelection: false,
                  userAttendance: "",
                },
              },
              () => {
                this.Timer();
                if (this.props.eventStatus === "Open") {
                  this.interval = setInterval(this.Timer, 1000);
                }
                if (this.state.alertDetails.showResponseControls) {
                  this.getUserAttendance();
                  if (message.agency)
                    this.getUserNavigateToSettings(message.agency);
                }

                this.setState({
                  alertBanner: true,
                });

                if (!!matchPath(this.props.location.pathname, "/home")) {
                  this.props.history.push({
                    pathname: "/home",
                  });
                } else if (
                  !!matchPath(this.props.location.pathname, "/pagerhistory")
                ) {
                  this.props.history.push({
                    pathname: "/pagerhistory",
                  });
                }
              }
            );
            this.Timer();
            this.interval = setInterval(this.Timer, 1000);
          } else if (message.chatId !== undefined) {
            if (
              !!matchPath(this.props.location.pathname, {
                path: "/chat/chats",
                exact: true,
              })
            ) {
              this.props.history.push({
                pathname: "/chat/chats",
              });
            }
          }
        }
      }
    }
  }

  onMessageReceivedFailure(error) {
    console.log("Error opening push notification:");
    console.log(error);
  }

  getUserAttendance = () => {
    let attendance = this.state.alertDetails.attendanceStatus;

    if (attendance !== null) {
      if (attendance === "Attending") {
        this.setState({
          event: {
            ...this.state.event,
            userAttendance: "Attending",
            isAttending: true,
            showResponse: true,
            showLocationControls: true,
            showAttendingBlock: true,
            showOtherBlock: false,
            showUnavailableBlock: false,
            responseMessage: copy.event.att.toUpperCase(),
            showChangeResponseBlock: false,
          },
        });
      } else if (attendance === "Other") {
        let otherText = "OTHER";
        try {
          if (
            this.state.eventDetails.paging.settings.attendanceStatusOtherTitle
          ) {
            otherText =
              this.state.eventDetails.paging.settings
                .attendanceStatusOtherTitle;
          } else {
            otherText = "OTHER";
          }
        } catch {
          otherText = "OTHER";
        }
        this.setState({
          event: {
            ...this.state.event,
            userAttendance: "Other",
            isAttending: false,
            showResponse: true,
            showLocationControls: false,
            showAttendingBlock: false,
            showOtherBlock: true,
            showUnavailableBlock: false,
            responseMessage: otherText,
            showChangeResponseBlock: false,
          },
        });
      } else if (attendance === "Unavailable") {
        this.setState({
          event: {
            ...this.state.event,
            userAttendance: "Unavailable",
            isAttending: false,
            showResponse: true,
            showLocationControls: false,
            showAttendingBlock: false,
            showOtherBlock: false,
            showUnavailableBlock: true,
            responseMessage: copy.event.notAtt.toUpperCase(),
            showChangeResponseBlock: false,
          },
        });
      }
    } else {
      this.setState({
        event: {
          ...this.state.event,
          isAttending: false,
          showResponse: false,
          showAttendingBlock: true,
          showOtherBlock: true,
          showUnavailableBlock: true,
          responseMessage: "",
          showChangeResponseBlock: false,
          showLocationControls: false,
          showLocationSelection: false,
          userAttendance: "",
        },
      });
    }
  };

  // this function gets the user's navigation preference
  getUserNavigateToSettings = (agency) => {
    this.Requests.callAPI(this.Requests.getUserNavigateToSettings, agency).then(
      (data) => {
        if (data && data.status && data.status === 200) {
          this.setState({
            event: {
              ...this.state.event,
              navigateTo: data.data,
            },
          });
        } else {
          let ErrorMessage =
            copy.settings.profile.getNavigateToAPIErrorMessage +
            ` (Error #${copy.errorCodes.getNavigateToAPIErrorMessage})`;
          // if (data && data.data && data.data.SASMessageClient) {
          //   ErrorMessage = data.data.SASMessageClient;
          // }
          this.setState({ errorMessage: ErrorMessage }, () => {
            setTimeout(
              function () {
                this.setState({ errorMessage: null });
              }.bind(this),
              5000
            );
          });
        }
      }
    );
  };

  postAttendance = () => {
    const ID = this.state.alertDetails.eventId;
    const navigateTo = this.state.event.navigateTo;
    const attendance = this.state.event.userAttendance;

    const returnData = {
      attendanceStatus: attendance,
      navigateTo: navigateTo,
    };

    // This function has error handling for PUT, POST & DELETE requests.
    // This function's error handling will NOT work for GET requests
    this.Requests.callAPI(
      this.Requests.postUserAttendance,
      ID,
      returnData
    ).then((data) => {
      if (data && data.status && data.status === 200) {
        if (attendance === "Attending") {
          // In a successful response, the BE will send a Key that can be used by the mobile app to send auth requests for LastKnownLocation
          if (window.cordova && data.data) {
            window.localStorage.setItem("lastKLKey", data.data);
            window.localStorage.setItem("currentlyAttendingEventId", ID);
          }
          setTimeout(() => {
            this.handlePagerClick();
          }, 800);
        } else {
          setTimeout(() => {
            this.closeAlertBanner();
          }, 800);
        }
      } else {
        let ErrorMessage =
          copy.event.attendanceList.updateAttendanceListAPIErrorMessage +
          ` (Error #${copy.errorCodes.updateAttendanceListAPIErrorMessage})`;
        // if (data && data.data && data.data.SASMessageClient) {
        //   ErrorMessage = data.data.SASMessageClient;
        // }
        this.setState({ errorMessage: ErrorMessage }, () => {
          setTimeout(
            function () {
              this.setState({ errorMessage: null });
            }.bind(this),
            5000
          );
        });
      }
    });
  };

  postTravellellingTo = (location) => {
    this.setState(
      {
        event: {
          ...this.state.event,
          travellingTo: location,
        },
      },
      () => {
        this.postAttendance();
      }
    );
  };

  // START: Functions that use API requests
  handleAttendingClick = () => {
    this.setState(
      {
        event: {
          ...this.state.event,
          userAttendance: "Attending",
          isAttending: true,
          showResponse: true,
          showLocationControls: true,
          showAttendingBlock: true,
          showOtherBlock: false,
          showUnavailableBlock: false,
          responseMessage: copy.event.att.toUpperCase(),
          showChangeResponseBlock: false,
        },
      },
      () => {
        this.postAttendance();
      }
    );
  };

  handleOtherClick = () => {
    this.setState(
      {
        event: {
          ...this.state.event,
          userAttendance: "Other",
          isAttending: false,
          showResponse: true,
          showLocationControls: false,
          showAttendingBlock: false,
          showOtherBlock: true,
          showUnavailableBlock: false,
          responseMessage: "DELAYED",
          showChangeResponseBlock: false,
        },
      },
      () => {
        this.postAttendance();
      }
    );
  };

  handleUnavailableClick = () => {
    this.setState(
      {
        event: {
          ...this.state.event,
          userAttendance: "Unavailable",
          isAttending: false,
          showResponse: true,
          showLocationControls: false,
          showAttendingBlock: false,
          showOtherBlock: false,
          showUnavailableBlock: true,
          responseMessage: copy.event.notAtt.toUpperCase(),
          showChangeResponseBlock: false,
        },
      },
      () => {
        this.postAttendance();
      }
    );
  };

  // END: Functions that use API requests

  // START: Functions that use setStates

  handleChangeResponseClick = () => {
    this.setState({
      event: {
        ...this.state.event,
        showChangeResponseBlock: true,
      },
    });
  };

  handleHideChangeResponseClick = () => {
    this.setState({
      event: {
        ...this.state.event,
        showChangeResponseBlock: false,
      },
    });
  };

  // END: Functions that use setStates

  Timer = () => {
    if (this.state.alertDetails !== null) {
      const InitialTimestamp = this.state.alertDetails.messageInputTimeStamp;
      const currentDate = new Date();
      const alertDate = new Date(InitialTimestamp);
      const timeDiff = currentDate.getTime() - alertDate.getTime();
      let Seconds = ("0" + (Math.floor(timeDiff / 1000) % 60)).slice(-2);
      let Minutes = ("0" + (Math.floor(timeDiff / 60000) % 60)).slice(-2);
      let Hours = Math.floor(timeDiff / 3600000);

      this.setState({
        timerTime: {
          seconds: Seconds,
          minutes: Minutes,
          hours: Hours,
        },
      });
    }
  };

  agencyLogo(agency) {
    switch (agency) {
      case "AV": // 0
        return <img src={AV} alt="Ambulance Victoria logo" />;
      case "CFA": // 1
        return <img src={CFA} alt="CFA logo" />;
      case "SES": // 2
        return <img src={SES} alt="SES logo" />;
      default:
        return <img src={EMV} alt="EMV logo" />;
    }
  }

  // converts date/time so Safari reads it properly
  convertDate = (date) => {
    if (date) {
      return date
        .replace(/-/g, "/")
        .replace("T", " ")
        .replace(/\..*|\+.*/, "");
    } else {
      return null;
    }
  };

  render() {
    let AlertTypeStyle;
    let AlertHeader;
    let PagerEvent;
    let attendingBlock;
    let attendingButton;
    let otherBlock;
    let otherButton;
    let unavailableBlock;
    let unavailableButton;
    let Timer;
    let ResponseControlsModule;

    if (this.state.alertBanner) {
      AlertHeader = styles.open;
    }

    if (this.state.alertDetails !== null) {
      const {
        agency,
        // cADEventId,
        // eventId,
        messageInputTimeStamp,
        messageText,
        alertType,
        pagingName,
        showResponseControls,
      } = this.state.alertDetails;

      if (alertType !== null) {
        AlertTypeStyle = alertType.toUpperCase();
      }

      // Response Controls
      const showAttendingBlock = this.state.event.showAttendingBlock;
      const showOtherBlock = this.state.event.showOtherBlock;
      const showUnavailableBlock = this.state.event.showUnavailableBlock;
      // const showLocationControls = this.state.event.showLocationControls;
      // const showChangeResponseBlock = this.state.event.showChangeResponseBlock;
      // Location Controls
      // let HeaderStyling = AlertType.toUpperCase();
      // let Title = AlertType.toUpperCase();
      let attendingLabel = classNames(styles.label, this.props.className, {
        [styles.active]: !this.state.event.showResponse,
      });
      let responseModal = classNames(
        styles.responseModalWrapper,
        this.props.className,
        {
          [styles.open]: this.state.event.showResponse,
        }
      );
      let responseMessage = classNames(
        styles.responseMessage,
        this.props.className,
        {
          [styles.visible]: !this.state.event.showChangeResponseBlock,
        }
      );
      let changeResponseToggle = classNames(
        styles.changeResponseBlock,
        this.props.className,
        {
          [styles.visible]: this.state.event.showChangeResponseBlock,
        }
      );
      // let ArrowIcon = classNames(styles.icon, this.props.className, {
      //   [styles.rotated]: this.state.event.showLocationSelection
      // });
      // let showLocationSelection = classNames(
      //   styles.locationSelection,
      //   this.props.className,
      //   {
      //     [styles.visible]: this.state.event.showLocationSelection
      //   }
      // );
      if (showAttendingBlock) {
        attendingBlock = (
          <div className={styles.availabilityBlock}>
            <Attending
              className={styles.responseButton}
              onClick={this.handleAttendingClick}
            />
            <span className={attendingLabel}>{copy.event.att}</span>
          </div>
        );
      } else {
        attendingButton = (
          <Attending
            className={styles.responseButton}
            onClick={this.handleAttendingClick}
          />
        );
      }
      if (showOtherBlock) {
        otherBlock = (
          <div className={styles.availabilityBlock}>
            <Other
              className={styles.responseButton}
              onClick={this.handleOtherClick}
            />
            <span className={attendingLabel}>{copy.event.other}</span>
          </div>
        );
      } else {
        otherButton = (
          <Other
            className={styles.responseButton}
            onClick={this.handleOtherClick}
          />
        );
      }
      if (showUnavailableBlock) {
        unavailableBlock = (
          <div className={styles.availabilityBlock}>
            <Unavailable
              className={styles.responseButton}
              onClick={this.handleUnavailableClick}
            />
            <span className={attendingLabel}>{copy.event.unavail}</span>
          </div>
        );
      } else {
        unavailableButton = (
          <Unavailable
            className={styles.responseButton}
            onClick={this.handleUnavailableClick}
          />
        );
      }
      let response = (
        <div className={responseModal}>
          <div className={styles.attendanceResponse + " tileBg"}>
            <div className={styles.response}>
              <span className={responseMessage}>
                {this.state.event.responseMessage}
              </span>
            </div>
          </div>
          <div className={changeResponseToggle}>
            {attendingButton}
            {otherButton}
            {unavailableButton}
          </div>
        </div>
      );
      if (
        showResponseControls === "True" ||
        showResponseControls === "true" ||
        showResponseControls === true
      ) {
        ResponseControlsModule = (
          <div key={this.state.eventID} className={styles.responseControls}>
            <div className={styles.controlsWrapper}>
              {attendingBlock}
              {otherBlock}
              {unavailableBlock}
            </div>
            {response}
          </div>
        );
      }

      Timer = (
        <div className={styles.timer}>
          <div className={styles.stopwatch}>
            <div className={styles.stopwatch_display}>
              <span>
                {this.state.timerTime.hours}:{this.state.timerTime.minutes}:
                {this.state.timerTime.seconds}
              </span>
            </div>
            <div className={styles.heading}>
              <span>SINCE ALERT</span>
            </div>
          </div>
        </div>
      );

      PagerEvent = (
        <div
          className={styles.pagerMessage + " tileBg"}
          onClick={this.handlePagerClick}
        >
          <div className={styles.pagerContent}>
            <div
              className={
                styles.pagerHeader
                // closed (1) event status
              }
            >
              <div className={styles.top + " " + AlertTypeStyle}>
                <div className={styles.agencyLogo}>
                  {this.agencyLogo(agency)}
                </div>
                <div className={styles.alertType}>
                  <span>{alertType}</span>
                </div>
                <div className={styles.timeStamp}>
                  <span className={styles.time}>
                    {new Date(
                      this.convertDate(messageInputTimeStamp)
                    ).toLocaleTimeString("en-GB")}{" "}
                    {new Date(this.convertDate(messageInputTimeStamp))
                      .toLocaleDateString("en-GB")
                      .replace(/\//g, "-")}
                  </span>
                  {/* <span className={styles.time}>{item.time}</span>
                    <span className={styles.date}>{item.date}</span> */}
                </div>
              </div>
              <div className={styles.bottom}>
                <div className={styles.pagerGroup}>
                  <span>{pagingName}</span>
                </div>
              </div>
            </div>
            <div
              className={styles.pagerDetails + " smartlook-hide smartlookMask"}
            >
              <span>{messageText}</span>
            </div>
            <div className={styles.footerWrapper}>
              <div className={styles.respond + " fill"}>
                <span>DETAILS</span>
                <ForwardArrow />
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <Wrapper>
        <ErrorBanner
          isVisible={this.state.errorMessage ? true : false}
          ErrorMessage={this.state.errorMessage}
        />
        <div
          className={
            [styles.AlertBannerWrapper, AlertHeader].join(" ") + " bg4"
          }
        >
          <div className={styles.alertBanner + " " + AlertTypeStyle}>
            <div className={styles.bannerContent}>
              <div className={styles.alertHeader}>
                {Timer}
                <Button
                  label="Dismiss alert"
                  buttonClick={this.closeAlertBanner}
                  content={copy.global.btnDismiss}
                  variant="btn_outline white_outline"
                  styles=""
                  icon="_close"
                ></Button>
              </div>
              {PagerEvent}
            </div>
          </div>
          {ResponseControlsModule}
        </div>
      </Wrapper>
    );
  }
}
export default withRouter(AlertBanner);
