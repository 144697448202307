import React from "react";
import styles from "./Loading.module.scss";

class Loading extends React.Component {
  render() {
    return (
      <div className={styles.loadingContainer + " loadingWrap"}>
        <div className={styles.loading}>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    );
  }
}
export default Loading;
