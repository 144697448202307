import React from "react";
import styles from "./Button.module.scss";

export default class Checkbox extends React.Component {
  handleClick = () => {
    if (this.props.buttonClick) {
      this.props.buttonClick(this.props.paramVal);
    }
  };
  render() {
    const {
      ref,
      label,
      checked,
      onChange,
      disabled,
      description,
      small,
      value
    } = this.props;

    if (checked) {
      return (
        <label
          aria-label={label}
          className={
            small
              ? styles.checkbox_small
              : styles.checkbox +
                " " +
                (disabled ? styles.disabled + " disabledTxt" : "")
          }
          {...{ onClick: this.handleClick }}>
          <input
            ref={ref}
            type="checkbox"
            disabled={disabled}
            defaultChecked
            name={label}
            checked={checked}
            onChange={onChange}
            value={value}
          />
          {label}
          <p className={styles.checkboxDescription}>{description}</p>
          <span
            className={
              styles.checkmark + (disabled ? " disabledBox" : "")
            }></span>
        </label>
      );
    } else {
      return (
        <label
          aria-label={label}
          className={
            small
              ? styles.checkbox_small
              : styles.checkbox +
                " " +
                (disabled ? styles.disabled + " disabledTxt" : "")
          }
          {...{ onClick: this.handleClick }}>
          <input
            ref={ref}
            type="checkbox"
            disabled={disabled}
            name={label}
            checked={checked}
            onChange={onChange}
            value={value}
          />

          {label}
          <p className={styles.checkboxDescription}>{description}</p>
          <span
            className={
              styles.checkmark + (disabled ? " disabledBox" : "")
            }></span>
        </label>
      );
    }
  }
}
