import axios from "axios";
import { AuthService } from "../Authentication/AuthService";
import { logger } from "../../utils";

export class Requests {
  constructor() {
    this.state = {
      isRefreshingToken: false,
    };
    // Session storage is used for browser
    if (!window.cordova) {
      //this.storage = window.sessionStorage;
      this.storage = window.localStorage;
      this.authService = new AuthService();
    }
    // Local storage is used for mobile
    else {
      this.storage = window.localStorage;
    }
    this.functionsAwaitingRefreshToken = [];
  }

  /**
   * This function obtains the user's access token and auth creds ffrom natiove storage
   * If the accessToken is not present (i.e. user is not logged in), it will return null
   */

  getFromMobileStorage = (key) =>
    new Promise((resolve, reject) => {
      window.NativeStorage.getString(key, resolve, reject);
    });

  getFromWebStorage = (key) => {
    return window.localStorage.getItem(key);
  };

  setInMobileStorage = (key, value) =>
    new Promise((resolve, reject) => {
      window.NativeStorage.putString(key, value, resolve, reject);
    });

  setInWebStorage = (key, value) => {
    return window.localStorage.setItem(key, value);
  };

  removeFromMobileStorage = (key) =>
    new Promise((resolve, reject) => {
      window.NativeStorage.remove(key, resolve, reject);
    });

  removeFromWebStorage = (key) => {
    return window.localStorage.removeItem(key);
  };

  clearMobileStorage = () => {
    new Promise((resolve, reject) => {
      window.NativeStorage.clear(resolve, reject);
    });
  };
  /**
   * This function obtains the user's access token
   * If the accessToken is not present (i.e. user is not logged in), it will return false
   */
  // The Refresh Token is disabled for now
  // async getAccessToken(apiFunction, options, body, headers, urlinfo) {
  //   logger.verbose("Requests:: getAccessToken - called");
  //   // Check if the token is already being refreshed
  //   // If it is being refreshed, wait 1 second anf try again
  //   // The first token call will mark isRefreshingToken as false once its done
  //   if (window.localStorage.getItem("isRefreshingToken") === "true") {
  //     let i = 0;
  //     // Wait for 20 seconds
  //     while (i < 20) {
  //       // Wait for 1 seconds
  //       await this.awaitForTokenRefresh(1000);
  //       // Check if refreshing token has finished
  //       if (window.localStorage.getItem("isRefreshingToken") !== "true") {
  //         i = 20; // Stop waiting
  //       } else {
  //         i++;
  //       }
  //     }
  //   }
  //   // GET TOKEN
  //   logger.debug("Requests:: getAccessToken - requests");

  //   let accessToken;
  //   let expiryTime;
  //   // Tokens for Mobile are retireved from Native Storage
  //   if (window.cordova) {
  //     accessToken = await this.getFromMobileStorage("accessToken");
  //     expiryTime = await this.getFromMobileStorage("expiresAt");
  //   } else {
  //     // Token for Web are retirvedd from Local Storage
  //     accessToken = await this.getFromWebStorage("accessToken");
  //     expiryTime = await this.getFromWebStorage("expiresAt");
  //   }

  //   if (accessToken === "null" || accessToken === null) {
  //     accessToken = false;
  //   }
  //   logger.log("The expiry time is: " + expiryTime);
  //   logger.log("The current time is: " + Math.round(Date.now() / 1000));
  //   // Run logic
  //   // If user has logged in...
  //   if (accessToken !== false) {
  //     // If the token has expired...
  //     if (Math.round(Date.now() / 1000) > expiryTime) {
  //       logger.debug("Requests:: getAccessToken - request made");
  //       // Renew the access token
  //       return (
  //         this.renewAccessToken(apiFunction, options, body, headers, urlinfo)
  //           .then((renewedAccessToken) => {
  //             window.localStorage.setItem("isRefreshingToken", "false");
  //             logger.debug("Requests:: getAccessToken - response received");
  //             return Promise.resolve(renewedAccessToken);
  //           })
  //           // ERROR WITH RENEWING ACCESS TOKEN
  //           .catch((error) => {
  //             window.localStorage.setItem("isRefreshingToken", "false");
  //             logger.error(
  //               "Requests:: getAccessToken - error received",
  //               JSON.stringify(error)
  //             );
  //             // Remove roles flag
  //             window.localStorage.removeItem("rolesNotValid");
  //             return Promise.resolve(false);
  //           })
  //       );
  //     } else {
  //       logger.debug("Requests:: getAccessToken - token still valid");
  //       return Promise.resolve(accessToken);
  //     }
  //   }
  //   // If user has not logged in yet...
  //   else {
  //     logger.debug("Requests:: getAccessToken - user not logged in");
  //     // Remove roles flag
  //     window.localStorage.removeItem("rolesNotValid");
  //     return Promise.resolve(false);
  //   }
  // }

  async getAccessToken(apiFunction, options, body, headers, urlinfo) {
    logger.verbose("Requests:: getAccessToken - called");
    // GET TOKEN
    logger.debug("Requests:: getAccessToken - requests");

    let accessToken;
    let expiryTime;
    // Tokens for Mobile are retireved from Native Storage
    if (window.cordova) {
      accessToken = await this.getFromMobileStorage("accessToken");
      expiryTime = await this.getFromMobileStorage("expiresAt");
    } else {
      // Token for Web are retirvedd from Local Storage
      accessToken = await this.getFromWebStorage("accessToken");
      expiryTime = await this.getFromWebStorage("expiresAt");
    }

    if (accessToken === "null" || accessToken === null) {
      accessToken = false;
    }
    logger.log("The expiry time is: " + expiryTime);
    logger.log("The current time is: " + Math.round(Date.now() / 1000));
    // Run logic
    // If user has logged in...
    if (accessToken !== false) {
      // If the token has expired...
      if (Math.round(Date.now() / 1000) > expiryTime) {
        this.logTheUserOut("Expired token, log the user out");
        // remove the token from the storage
        if (window.cordova) {
          await this.removeFromMobileStorage("accessToken");
        } else {
          this.removeFromWebStorage("accessToken");
        }
        logger.debug("Requests:: getAccessToken - user not logged in");
      } else {
        logger.debug("Requests:: getAccessToken - token still valid");
        return Promise.resolve(accessToken);
      }
    }
    // If user has not logged in yet...
    else {
      logger.debug("Requests:: getAccessToken - user not logged in");
      // Remove roles flag
      window.localStorage.removeItem("rolesNotValid");
      return Promise.resolve(false);
    }
  }

  // This method renews a user's access token using their refresh token
  // If the request has failed, an error is thrown
  async renewAccessToken(apiFunction, options, body, headers, urlinfo) {
    // Token is not being refreshed, start refreshing it now
    // Refreshing Token
    let refreshToken;
    // Tokens for Mobile are retireved from Native Storage
    if (window.cordova) {
      refreshToken = await this.getFromMobileStorage("refreshToken");
    } else {
      // Token for Web are retirvedd from Local Storage
      refreshToken = await this.getFromWebStorage("refreshToken");
    }
    if (refreshToken === null || refreshToken === "null") {
      logger.log("Refresh token is null");
      logger.error("Requests:: renewAccessToken - already refreshing token ");
      return Promise.resolve(false);
    } else {
      // Setting up the parameters for the API call
      let params = new URLSearchParams();
      params.append("grant_type", "refresh_token");
      params.append("refresh_token", refreshToken);
      params.append("client_id", process.env.REACT_APP_LOGIN_OIDC_CLIENT_ID);

      // DONT REMOVE FROM NATIVE STORAGE IN CASE THE CALL FAILS
      // Remove the refresh token so other subsequent calls can't use it.
      // Tokens for Mobile are removed from Native Storage
      // if (window.cordova) {
      //   refreshToken = await this.removeFromMobileStorage("refreshToken");
      // } else {
      //   // Token for Web are removed from Local Storage
      //   refreshToken = await this.removeFromWebStorage("refreshToken");
      // }

      // Don't make the token call if the device is offline
      if (window.navigator.onLine === true) {
        return axios
          .post(process.env.REACT_APP_IDENTITY_DEV + `connect/token`, params, {
            timeout: 10000,
          })
          .then((response) => {
            // Update information in storage
            this.updateTokensInStorage(
              response.data.access_token,
              response.data.refresh_token,
              response.data.id_token,
              this.parseJwt(response.data.access_token).exp
            );

            // Make all the API calls again that are pending
            window.localStorage.setItem("isRefreshingToken", "false");

            // this.retryFunctionsAwaitingToken();
            return response.data.access_token;
          })
          .catch((error) => {
            logger.error(error.code);
            logger.error(error.messsage);
            logger.error(error.stack);

            if (error.code === 400 || error.code === 500) {
              logger.error("SERVER RESPONDED with an error, log the user out");
              this.logTheUserOut(error);
            }
            window.localStorage.setItem("isRefreshingToken", "false");
            return Promise.resolve("timeout");
          });
      } else {
        window.localStorage.setItem("isRefreshingToken", "false");
        return Promise.resolve("timeout");
      }
    }
  }

  // This function will fecth the current values of accessToken, refreshToken, idToken and expiresAt from the relevant storage for Mobile & Web
  async getIdentityTokensFromStorage(tokenName) {
    let tokenValue;
    // Tokens for Mobile are retireved from Native Storage
    if (window.cordova) {
      tokenValue = await this.getFromMobileStorage(tokenName);
    } else {
      // Token for Web are retirvedd from Local Storage
      tokenValue = await this.getFromWebStorage(tokenName);
    }
    return tokenValue;
  }

  async updateTokensInStorage(accessToken, refreshToken, idToken, expiresAt) {
    // Update information in storage
    if (window.cordova) {
      await this.setInMobileStorage("accessToken", accessToken);
      await this.setInMobileStorage("refreshToken", refreshToken);
      await this.setInMobileStorage("idToken", idToken);
      await this.setInMobileStorage("expiresAt", expiresAt);
    } else {
      // Token for Web are removed from Local Storage
      this.setInWebStorage("accessToken", accessToken);
      this.setInWebStorage("refreshToken", refreshToken);
      this.setInWebStorage("idToken", idToken);
      this.setInWebStorage("expiresAt", expiresAt);
    }
  }

  async logTheUserOut(error) {
    logger.log("About to log the user out");
    logger.error(
      "Requests:: renewAccessToken - error in refreshing token: ",
      JSON.stringify(error)
    );
    // Remove roles flag
    const value = window.localStorage.getItem(
      "notificationPoppedup"
    );
    window.localStorage.clear();
    window.localStorage.setItem("notificationPoppedup", value); 
    
    let userKey =
      `oidc.user:` + process.env.REACT_APP_IDENTITY_DEV + `:js_oidc`;
    window.sessionStorage.removeItem(userKey);
    if (window.cordova) {
      await this.clearMobileStorage();
    }
    window.location.reload();
    return Promise.resolve(false);
  }

  /**
   * This function will check if the user needs to be forced logged out from the backend
   * An admin user can force log someone out from the Admin page
   * Boolean flag returned from the server when the device details are sent in the payloadd, so they are logged out on each device
   */
  checkIfForceLogoutIsEnabled = () => {
    // These are the Device Details
    let deviceDetails = "null";
    let platform = "null";
    let deviceName = "null";
    let deviceId = "null";

    if (window.cordova) {
      //First we get the device information from the cordova-device plugin:
      let modelName = "null";
      let version = "null";
      let serial = "null";

      if (window.device.platform !== undefined) {
        platform = window.device.platform;
      }
      if (window.device.name !== undefined) {
        deviceName = window.device.name;
      }
      if (window.device.model !== undefined) {
        modelName = window.device.model;
      }
      if (window.device.version !== undefined) {
        version = window.device.version;
      }
      if (window.device.uuid !== undefined) {
        deviceId = window.device.uuid;
      }
      if (window.device.serial !== undefined) {
        serial = window.device.serial;
      }

      deviceDetails = {
        OSPlatform: platform,
        OSversion: version,
        DeviceName: deviceName,
        DeviceModel: modelName,
        DeviceUniqueId: deviceId,
        DeviceSerial: serial,
      };
    } else {
      let modelName = "null";
      let browserVersion = "null";
      let version = "null";
      let browserVendor = "null";
      let serial = "null";
      if (navigator.platform !== undefined) {
        platform = navigator.platform;
      }
      if (navigator.vendor !== undefined) {
        browserVendor = navigator.vendor;
      }
      if (navigator.appVersion !== undefined) {
        version = navigator.appVersion;
      }
      if (navigator.userAgent !== undefined) {
        // gets the browser name as the device name
        var Sys = {};
        var ua = navigator.userAgent.toLowerCase();
        // eslint-disable-next-line
        var s;
        s = ua.match(/msie ([\d.]+)/)
          ? (Sys.ie = s[1])
          : // eslint-disable-next-line
          (s = ua.match(/firefox\/([\d.]+)/))
          ? (Sys.firefox = s[1])
          : // eslint-disable-next-line
          (s = ua.match(/chrome\/([\d.]+)/))
          ? (Sys.chrome = s[1])
          : // eslint-disable-next-line
          (s = ua.match(/opera.([\d.]+)/))
          ? (Sys.opera = s[1])
          : // eslint-disable-next-line
          (s = ua.match(/version\/([\d.]+).*safari/))
          ? (Sys.safari = s[1])
          : 0;

        if (Sys.ie) {
          browserVersion = "IE";
        } else if (Sys.firefox) {
          browserVersion = "Firefox";
        } else if (Sys.chrome) {
          browserVersion = "Chrome";
        } else if (Sys.opera) {
          browserVersion = "Opera";
        } else if (Sys.safari) {
          browserVersion = "Safari";
        }
      }
      deviceId = [platform, browserVendor, browserVersion]
        .join(" ")
        .replace(/\s/g, "");

      deviceDetails = {
        OSPlatform: platform,
        OSversion: version,
        DeviceName: deviceName,
        DeviceModel: modelName,
        DeviceUniqueId: deviceId,
        DeviceSerial: serial,
      };
    }
    // Check if the user needs to be forced loggout
    this.callAPI(this.checkIfForcedLogoutEnabled, deviceDetails).then(
      (data) => {
        if (data && data.status && data.status === 200 && data.data) {
          if (data.data === true) {
            // Log the user out or else don't do anything
            const value = window.localStorage.getItem(
              "notificationPoppedup"
            );
            window.localStorage.clear();
            window.localStorage.setItem("notificationPoppedup", value); 
            
            if (window.cordova) {
              window.NativeStorage.clear();
            }
            window.location.replace(window.location.href);
            window.location.reload(true);
          }
        } else if (data && data.status && data.status === 401) {
          // Log the user out as they are unauthorised
          const value = window.localStorage.getItem(
            "notificationPoppedup"
          );
          window.localStorage.clear();
          window.localStorage.setItem("notificationPoppedup", value); 
                    if (window.cordova) {
            window.NativeStorage.clear();
          }
          window.location.replace(window.location.href);
          window.location.reload(true);
        } else {
          logger.error(
            "Routes:: checkIfForcedLoggout - error in fetching API request"
          );
        }
      }
    );
  };

  // This method decodes the access token.
  parseJwt(token) {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }

  // This method will wait for the 'ms' amount of milliseconds before resolving the promise
  awaitForTokenRefresh(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  // This a function to wrap all API calls that are made
  // It first obtains a valid access token and then passes it into the API call
  // apiFunction - function handle for the API call that needs to be made
  // options - any parameters that need to be passed in to the API call
  callAPI(apiFunction, options, body, headers, urlinfo) {
    // console.log(`Requests:: callAPI - Calling with API: ${apiFunction.name}`);
    const functionName = apiFunction.name;
    logger.log(`Requests:: callAPI - Calling with API: ${functionName}`);
    // Obtain the access token
    return (
      this.getAccessToken(apiFunction, options, body, headers, urlinfo)
        .then((accessToken) => {
          // Make the API call if the access token is not false
          // False indicates that the access Token is not available
          logger.log(
            "the access token returned for " +
              apiFunction +
              " is: " +
              accessToken
          );
          if (accessToken !== false && accessToken !== "timeout") {
            return (
              apiFunction(accessToken, options, body, headers, urlinfo)
                .then((response) => {
                  logger.log(
                    `Requests:: callAPI - finished call with API: ${functionName}`
                  );
                  return response;
                })
                // TO DO: HANDLE ERROR FROM API CALLS
                .catch((error) => {
                  if (error.response !== undefined) {
                    logger.error(
                      `Requests:: callAPI - API error happened in: ${error.config.url} - the error is: ${error.message} - with status code: ${error.response.status} - with status text: ${error.response.statusText}}`
                    );
                  } else {
                    logger.error(
                      `Requests:: callAPI - API error happend in: ${error.config.url} - the error is: ${error.message}`
                    );
                  }

                  let functionCalled = { functionName };
                  // if the error is due to an invalid grant, user will get logged out
                  if (error.response) {
                    if (
                      error.response.status === 400 &&
                      error.response.data &&
                      error.response.data.error &&
                      error.response.data.error === "invalid_grant"
                    ) {
                      logger.log("This is a valid logout");
                      logger.debug(
                        `Requests:: callAPI - Authentication error logging user out`
                      );
                      window.localStorage.removeItem("rolesNotValid");
                      // Log the user out so they can login again
                      this.logTheUserOut();
                      return error.response;
                    }
                    // If there an unauthorised client error status, renew the user's access token
                    // getUnreadThreadCount, filterAndSortEventAttendees, getEventDetails runs on an interval, and Android may call this in the background - so dont log the user out
                    else if (
                      error.response.status === 401 &&
                      functionCalled.functionName !== "getUnreadThreadCount" &&
                      !error.config.url.includes("getUnreadThreadCount") &&
                      functionCalled.functionName !==
                        "filterAndSortEventAttendees" &&
                      !error.config.url.includes("/attendance") &&
                      functionCalled.functionName !== "getEventDetails" &&
                      !error.config.url.includes("/v1.3/Event/")
                    ) {
                      this.renewAccessToken(
                        apiFunction,
                        options,
                        body,
                        headers,
                        urlinfo
                      )
                        .then((accessToken) => {
                          if (accessToken) {
                            // retry API call
                            return this.callAPI(
                              apiFunction,
                              options,
                              body,
                              headers,
                              urlinfo
                            );
                          } else {
                            // log user out if a new access token cannot be obtained
                            this.logTheUserOut();
                            return error.response;
                          }
                        })
                        // ERROR WITH RENEWING ACCESS TOKEN - log user out
                        .catch((error) => {
                          logger.error(
                            "Requests:: getAccessToken - error received",
                            JSON.stringify(error)
                          );
                          this.logTheUserOut();
                          return error.response;
                        });
                    } else {
                      return error.response;
                    }
                  } else if (error.request) {
                    return error.request;
                  } else if (error.message) {
                    return error.message;
                  } else {
                    return error;
                  }
                })
            );
          } else if (accessToken === "timeout") {
            return "timeout";
          } else {
            logger.log("Error in renewing token");
          }
        })
        // TO DO: HANDLE ERROR WITH GETTING ACCESS TOKEN
        .catch((error) => {
          logger.error(
            `Requests:: callAPI - error retrieving token: ${error.message}`
          );
          return error;
        })
    );
  }

  retryFunctionsAwaitingToken = () => {
    for (let i = 0; i < this.functionsAwaitingRefreshToken.length; i++) {
      let func = this.functionsAwaitingRefreshToken[i];
      this.callAPI(
        func.apiFunction,
        func.options,
        func.body,
        func.headers,
        func.urlinfo
      );
    }
    // Reset the array
    this.functionsAwaitingRefreshToken = [];
  };

  // This API call returns the user's information from their AD
  userInformation(accessToken) {
    return axios.get(process.env.REACT_APP_IDENTITY_DEV + `connect/userinfo`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  /* ----------------------------------------------------
         CALLS TO THE PROFILE APPLICATION API
----------------------------------------------------*/

  // This API will fetch all agency spcific settings
  getApplicationsSettings(accessToken) {
    return axios.get(process.env.REACT_APP_APPLICATION_API_DEV + `agency`, {
      headers: { Authorization: "Bearer " + accessToken },
    });
  }

  // This API call saves the number of concurrent devices to the dB
  setConcurrentUserDevices(accessToken, agency, devicesCount) {
    return axios.put(
      process.env.REACT_APP_APPLICATION_API_DEV +
        `agency/${agency}/concurrentUserDevices/${devicesCount}`,
      null,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }

  /* ----------------------------------------------------
             CALLS TO THE PROFILE API
  ----------------------------------------------------*/

  // This API call returns the user's information from SAS
  userSASInfo(accessToken) {
    return axios.get(process.env.REACT_APP_PROFILE_API_DEV + `info`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  // This API call returns the current user's roles
  getCurrentUserRoles(accessToken) {
    return axios.get(process.env.REACT_APP_PROFILE_API_DEV + `Role`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  // This API call returns a selected user's roles
  getUserInheritedRoles(accessToken, id) {
    return axios.get(process.env.REACT_APP_PROFILE_API_DEV + `Role/Inherited`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  // This API call returns a selected user's roles
  getUserRoles(accessToken, id) {
    return axios.get(process.env.REACT_APP_PROFILE_API_DEV + `${id}/Role`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  // This API call adds a role to the user
  addUserRoles(accessToken, id, body) {
    return axios.put(
      process.env.REACT_APP_PROFILE_API_DEV + `${id}/Role`,
      body,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }

  // This API call returns the user's qualification
  getUsersQuals(accessToken) {
    return axios.get(process.env.REACT_APP_PROFILE_API_DEV + `qualification`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  // This API calls return the selected user's information (including qualification)
  getSelectedUsersInfo(accessToken, id) {
    return axios.get(process.env.REACT_APP_PROFILE_API_DEV + `${id}/settings`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  updateTurnoutAccess(accessToken, canAccessTurnoutDashboard, id) {
    return axios.put(
      process.env.REACT_APP_PROFILE_API_DEV +
        `settings/canAccessTurnoutDashboard/${canAccessTurnoutDashboard}/${id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }
  // This API call returns the ASN numbers a user has linked to their account
  getUserASN(accessToken) {
    return axios.get(
      process.env.REACT_APP_PROFILE_API_DEV + `viper/pager/ASN`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }

  // Returns the user's VIPER profile with the associated ASN's
  getUserVIPERProfile(accessToken) {
    return axios.get(process.env.REACT_APP_PROFILE_API_DEV + `viper/user`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  // This API call gets the agencies the user is part of
  getUserAgencies(accessToken) {
    return axios.get(process.env.REACT_APP_PROFILE_API_DEV + `agency`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  // This API call deletes an ASN number from their account
  deleteUserAgency(accessToken, agency) {
    return axios.delete(
      process.env.REACT_APP_PROFILE_API_DEV + `Unlink/${agency}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }

  // This API call returns the ASN numbers a user has linked to their account
  sendSingleUserASN(accessToken, asn) {
    return axios.put(
      process.env.REACT_APP_PROFILE_API_DEV + `viper/pager/ASN/${asn}`,
      null,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }

  // This API call returns the ASN numbers linked to selected user account from Admin screen
  sendSingleUserASNAdmin(accessToken, viperDetails) {
    return axios.put(
      process.env.REACT_APP_PROFILE_API_DEV +
        `VIPER/pager/ASN/${viperDetails.asn}/SubjectUser/${viperDetails.userId}`,
      null,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }

  // This API call deletes an ASN number from their account
  deleteUserASN(accessToken, asn) {
    return axios.delete(
      process.env.REACT_APP_PROFILE_API_DEV + `viper/pager/ASN/${asn}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }

  // This API will delete the user's VIPER profile
  deleteVIPERProfile(accessToken, viperProfileId) {
    return axios.delete(
      process.env.REACT_APP_PROFILE_API_DEV + `viper/user/${viperProfileId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }

  // This API will delete all the VIPER user records of the user
  deleteAllVIPERProfiles(accessToken) {
    return axios.delete(process.env.REACT_APP_PROFILE_API_DEV + `viper/user`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  // This API will delete all the ASNs related to the user from admin
  deleteAllAdminASN(accessToken, userId) {
    return axios.delete(
      process.env.REACT_APP_PROFILE_API_DEV + `viper/${userId}/SubjectUser`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }

  // This API will delete the user's VIPER profile
  deleteAdminVIPERProfile(accessToken, viperDetails) {
    return axios.delete(
      process.env.REACT_APP_PROFILE_API_DEV +
        `VIPER/User/${viperDetails.viperId}/SubjectUser/${viperDetails.userId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }

  // This API call returns the latest terms of use accepted by the user
  getTermsOfUseAcceptance(accessToken) {
    return axios.get(
      process.env.REACT_APP_PROFILE_API_DEV +
        `termsOfUse/CurrentAcceptedAndLatest`,
      {
        headers: { Authorization: "Bearer " + accessToken },
      }
    );
  }

  // This API call returns the current accepted terms of use by the user
  getCurrentAcceptedTermsOfUse(accessToken) {
    return axios.get(
      process.env.REACT_APP_PROFILE_API_DEV + "termsOfUse/CurrentAccepted",
      {
        headers: { Authorization: "Bearer " + accessToken },
      }
    );
  }
  // This API call returns the current accepted terms of use for Android Auto by the user
  getCurrentAcceptedTermsOfUseAndroidAuto(accessToken) {
    return axios.get(
      process.env.REACT_APP_PROFILE_API_DEV +
        "termsOfUseAndroidAuto/CurrentAccepted",
      {
        headers: { Authorization: "Bearer " + accessToken },
      }
    );
  }
  // This API call stores the user's acceptance of the terms of use in the database
  termsOfUseAcceptance(accessToken, id) {
    return axios.put(
      process.env.REACT_APP_PROFILE_API_DEV + `termsOfUse/${id}/Accept`,
      null,
      {
        headers: { Authorization: "Bearer " + accessToken },
      }
    );
  }
  // This API call stores the user's decline of the terms of use in the database
  termsOfUseDeclined(accessToken, id) {
    return axios.put(
      process.env.REACT_APP_PROFILE_API_DEV + `termsOfUse/${id}/Decline`,
      null,
      {
        headers: { Authorization: "Bearer " + accessToken },
      }
    );
  }

  // This API call stores that the user has completed onboarding by going through the ASN screen
  sendOnboardingCompletion(accessToken, flag) {
    return axios.put(
      process.env.REACT_APP_PROFILE_API_DEV + `settings/onboarding/${flag}`,
      null,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }

  // This API call returns the user's default navigation app
  getUserSettings(accessToken) {
    return axios.get(process.env.REACT_APP_PROFILE_API_DEV + `settings`, {
      headers: { Authorization: "Bearer " + accessToken },
    });
  }

  // This API call returns the help section in settings view
  getHelpSectionToSettings(accessToken) {
    return axios.get(process.env.REACT_APP_PROFILE_API_DEV + `HelpSection`, {
      headers: { Authorization: "Bearer " + accessToken },
    });
  }

  getUserNavigateToSettings(accessToken, agencySelected) {
    return axios.get(
      process.env.REACT_APP_PROFILE_API_DEV +
        `settings/agency/${agencySelected}/navigateMeTo`,
      {
        headers: { Authorization: "Bearer " + accessToken },
      }
    );
  }

  // This API call stores the user's acceptance of the terms of use in the database
  changeDefaultNavigationApp(accessToken, app) {
    return axios.put(
      process.env.REACT_APP_PROFILE_API_DEV +
        "settings/navigationDefaultApplication",
      app,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }

  // Sets ability to hide availability from other users
  setHideAvailability(accessToken, flag) {
    return axios.put(
      process.env.REACT_APP_PROFILE_API_DEV +
        `settings/availabilityHide/${flag}`,
      null,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );
  }

  // This will toggle the ShareMyLocation flag for the User at a global settings level
  setShareMyLocation(accessToken, shareMyLocationFlag) {
    return axios.put(
      process.env.REACT_APP_PROFILE_API_DEV +
        `settings/locationShareMy/${shareMyLocationFlag}`,
      null,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );
  }

  // This API call members in a paging group
  getMembers(accessToken, PagingGroupNumber) {
    return axios.post(
      process.env.REACT_APP_PROFILE_API_DEV,
      PagingGroupNumber,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }

  // This API call checks if the user needs to be forced logged out
  checkIfForcedLogoutEnabled(accessToken, deviceDetails) {
    return axios.post(
      process.env.REACT_APP_PROFILE_API_DEV + `ForcedLogoutEnabled`,
      deviceDetails,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }
  v;

  // This API add a SAS only user into the application

  addSASonlyUser(accessToken, details) {
    return axios.put(process.env.REACT_APP_PROFILE_API_DEV, details, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  // Sets default map layer
  setDefaultMappingLayer(accessToken, layer) {
    return axios.put(
      process.env.REACT_APP_PROFILE_API_DEV +
        `settings/initialDefaultMappingTile/${layer}`,
      null,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );
  }

  // Sets default points of interests layer
  setDefaultPoiLayer(accessToken, layer) {
    return axios.put(
      process.env.REACT_APP_PROFILE_API_DEV +
        `settings/defaultPointsOfInterest/`,
      layer,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );
  }

  // This API call mutes/unmutes a paging group for the user (single paging group)
  setPagingGroupMute(accessToken, pagingNumber, flag) {
    return axios.put(
      process.env.REACT_APP_PROFILE_API_DEV +
        `settings/paging/${pagingNumber}/mute/${flag}`,
      null,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );
  }

  // This API call mutes/unmutes all specified paging groups for the user (multiple paging groups)
  setPagingGroupMuteMultiple(accessToken, body) {
    return axios.put(
      process.env.REACT_APP_PROFILE_API_DEV + `settings/paging/mute`,
      body,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );
  }

  // Sets the unread notification reminder flag globally
  setNotificationBy(accessToken, deviceId, notificationType) {
    return axios.put(
      process.env.REACT_APP_PROFILE_API_DEV +
        `device/${deviceId}/notifications/SetBy/${notificationType}`,
      null,
      {
        headers: { Authorization: "Bearer " + accessToken },
      }
    );
  }

  // This API call change's the user's preferred navigation destination
  changeNavigationDestination(accessToken, agency, destination) {
    return axios.put(
      process.env.REACT_APP_PROFILE_API_DEV +
        `settings/agency/${agency}/navigateMeTo/${destination}`,
      null,
      {
        headers: { Authorization: "Bearer " + accessToken },
      }
    );
  }

  /**
   * This API call allows the user to edit their own password
   * @param {*} accessToken - user's access token
   * @param {*} newPassword - password user wants to update
   */
  editOwnPassword(accessToken, newPassword) {
    return axios.put(
      process.env.REACT_APP_PROFILE_API_DEV + `password`,
      { password: newPassword },
      {
        headers: { Authorization: "Bearer " + accessToken },
      }
    );
  }

  getSelectedUserQualification(accessToken, userID) {
    return axios.get(
      process.env.REACT_APP_PROFILE_API_DEV + `${userID}/qualification`,
      {
        headers: { Authorization: "Bearer " + accessToken },
      }
    );
  }

  // This API call gets users based on filter criteria
  filterUsers(accessToken, body) {
    return axios.post(process.env.REACT_APP_PROFILE_API_DEV, body, {
      headers: { Authorization: "Bearer " + accessToken },
    });
  }

  // This API call returns an array of all the qualifications from all of the user's paging groups
  getUserQualifications(accessToken) {
    return axios.get(process.env.REACT_APP_QUALIFICATIONS_API_DEV, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  // Gets the notification preferences globally for a user
  getGlobalNotificationChannel(accessToken, deviceId) {
    return axios.get(
      process.env.REACT_APP_PROFILE_API_DEV +
        `device/${deviceId}/notifications/paging/global/`,
      {
        headers: { Authorization: "Bearer " + accessToken },
      }
    );
  }

  // Sets the unread notification reminder flag globally
  setGlobalNotificationReminder(accessToken, deviceId, reminder) {
    return axios.put(
      process.env.REACT_APP_PROFILE_API_DEV +
        `device/${deviceId}/notifications/paging/global`,
      {
        messageUnreadReminder: reminder,
      },
      {
        headers: { Authorization: "Bearer " + accessToken },
      }
    );
  }

  // This API call returns the user's information from SAS
  getOwnSSOAccount(accessToken) {
    return axios.get(process.env.REACT_APP_PROFILE_API_DEV + `HasSSOAccount`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  // Sets the notification preferences globally per alert type for a user
  setGlobalNotificationChannel(
    accessToken,
    deviceId,
    messageType,
    channelBody
  ) {
    return axios.put(
      process.env.REACT_APP_PROFILE_API_DEV +
        `device/${deviceId}/notifications/paging/global/type/${messageType}`,
      channelBody,
      {
        headers: { Authorization: "Bearer " + accessToken },
      }
    );
  }

  // Gets the notification preferences per paging number for a user
  getPagingNotificationChannel(accessToken, deviceId, pagingNumber) {
    return axios.get(
      process.env.REACT_APP_PROFILE_API_DEV +
        `device/${deviceId}/notifications/paging/${pagingNumber}`,
      {
        headers: { Authorization: "Bearer " + accessToken },
      }
    );
  }

  // Sets the unread notification reminder flag per paging group
  setPagingNotificationReminder(accessToken, deviceId, pagingNumber, reminder) {
    return axios.put(
      process.env.REACT_APP_PROFILE_API_DEV +
        `device/${deviceId}/notifications/paging/${pagingNumber}`,
      {
        messageUnreadReminder: reminder,
      },
      {
        headers: { Authorization: "Bearer " + accessToken },
      }
    );
  }

  // Sets the notification preferences per paging number and alert type for a user
  setPagingNotificationChannel(
    accessToken,
    deviceId,
    pagingNumber,
    messageType,
    channelBody
  ) {
    return axios.put(
      process.env.REACT_APP_PROFILE_API_DEV +
        `device/${deviceId}//notifications/paging/${pagingNumber}/type/${messageType}`,
      channelBody,
      {
        headers: { Authorization: "Bearer " + accessToken },
      }
    );
  }

  // Deletes the notification preferences for a user's paging number and alert type
  deletePagingNotificationChannel(
    accessToken,
    deviceId,
    pagingNumber,
    messageType
  ) {
    return axios.delete(
      process.env.REACT_APP_PROFILE_API_DEV +
        `device/${deviceId}/notifications/paging/${pagingNumber}/type/${messageType}`,
      {
        headers: { Authorization: "Bearer " + accessToken },
      }
    );
  }

  // This API call resets the user's password using their id
  resetUserPassword(accessToken, id) {
    return axios.put(
      process.env.REACT_APP_PROFILE_API_DEV + `${id}/password/reset`,
      null,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );
  }

  // This API call deactivates a user's account using their id
  deactivateUserAccount(accessToken, id) {
    return axios.put(
      process.env.REACT_APP_PROFILE_API_DEV + `${id}/Lockout`,
      null,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );
  }
  // This API call reactivates a user's deactivated account using their id
  reactivateUserAccount(accessToken, id) {
    return axios.delete(
      process.env.REACT_APP_PROFILE_API_DEV + `${id}/Lockout`,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );
  }
  // This API call permanently delete/deactivates a user's deactivated account using their id
  permanentlyDeactivateUserAccount(accessToken, id) {
    return axios.put(
      process.env.REACT_APP_PROFILE_API_DEV + `${id}/PermanentlyDeactivate`,
      null,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );
  }
  // This API call returns the user's information from SAS
  getUserSSOAccount(accessToken, id) {
    return axios.get(
      process.env.REACT_APP_PROFILE_API_DEV + `${id}/HasSSOAccount`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }

  // This API call deletes a user's refresh token
  deleteUserRefreshToken(accessToken, id) {
    return axios.delete(
      process.env.REACT_APP_PROFILE_API_DEV + `${id}/Identity/Token/Refresh`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }

  // This API call gets the agencies the user is part of
  getOtherUsersProfile(accessToken, id, showDeactivated) {
    return axios.get(
      process.env.REACT_APP_PROFILE_API_DEV + `${id}/UserProfile`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params: {
          showDeactivated,
        },
      }
    );
  }

  // This API call gets the ASN of the user
  getOtherUsersASN(accessToken, id) {
    return axios.get(
      process.env.REACT_APP_PROFILE_API_DEV + `VIPER/SubjectUser/${id}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }

  /* ----------------------------------------------------
        CALLS TO THE CHAT NOTIFICATION PREFERENCES API
  ---------------------------------------------------- */

  getChatNotificationSettings(accessToken, deviceId) {
    return axios.get(
      process.env.REACT_APP_PROFILE_API_DEV +
        `device/${deviceId}/notifications/chat/global`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }

  setChatNotificationSettings(accessToken, deviceId, channelBody) {
    return axios.put(
      process.env.REACT_APP_PROFILE_API_DEV +
        `device/${deviceId}/notifications/chat/global`,
      channelBody,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );
  }

  deleteChatNotificationSettings(accessToken, deviceId) {
    return axios.delete(
      process.env.REACT_APP_PROFILE_API_DEV +
        `device/${deviceId}/notifications/chat/global`,
      {
        headers: { Authorization: "Bearer " + accessToken },
      }
    );
  }

  readAllChatNotifications(accessToken) {
    return axios.put(
      process.env.REACT_APP_CHAT_API_DEV + "/readAllChatThreads",
      {},
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }

  /* ----------------------------------------------------
           CALLS TO THE PUSH NOTIFICATIONS API
  ---------------------------------------------------- */

  // This API call sets the mute time for all notifications
  mutePushNotifications(accessToken, deviceId, body) {
    return axios.put(
      process.env.REACT_APP_PROFILE_API_DEV +
        `device/${deviceId}/MutePushNotificationUntil`,
      body,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );
  }

  // This API get the device details for the device ID provided
  getDeviceDetails(accessToken, deviceId) {
    return axios.get(
      process.env.REACT_APP_PROFILE_API_DEV + `device/${deviceId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }

  // Sends the firebase token
  sendFirebaseToken(accessToken, deviceId, fcmTokenBody) {
    return axios.put(
      process.env.REACT_APP_PROFILE_API_DEV + `device/${deviceId}/Token`,
      fcmTokenBody,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }

  // Deletes only the firebase token
  deleteFirebaseToken(accessToken, deviceId) {
    return axios.delete(
      process.env.REACT_APP_PROFILE_API_DEV + `device/${deviceId}/Token`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }

  // Deletes the device for a user
  deleteDevice(accessToken, deviceId) {
    return axios.delete(
      process.env.REACT_APP_PROFILE_API_DEV + `device/${deviceId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }

  /* ----------------------------------------------------
             CALLS TO THE EVENT API
  ----------------------------------------------------*/
  // This API call Reactivates an event aftr it has been closed or expires
  reactivateEvent(accessToken, id, status) {
    return axios.put(
      process.env.REACT_APP_EVENT_API_DEV + `${id}/Status/${status}`,
      null,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }

  // This API call returns the pager msgs
  postPagerMessages(accessToken, msg) {
    return axios.post(
      process.env.REACT_APP_EVENT_API_DEV + `message`, // Dev API
      msg,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }

  // This API call returns event info
  getEventDetails(accessToken, eventID) {
    return axios.get(
      process.env.REACT_APP_EVENT_API_DEV + `${eventID}`, // This is the real one
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }

  // This API call current user's attendance info
  getCurrentUserEventAttendance(accessToken, eventID) {
    return axios.get(
      process.env.REACT_APP_EVENT_API_DEV + `${eventID}/attendance/user`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }

  // This API call returns the pager msgs
  postAttendance(accessToken, eventID, quals) {
    return axios.post(
      process.env.REACT_APP_EVENT_API_DEV + `${eventID}/attendance`,
      {
        qualificationId: quals,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }

  // This API call puts the last known location of a user per event
  sendLastKnownLocation(accessToken, id, coordinates) {
    return axios.put(
      process.env.REACT_APP_EVENT_API_DEV + `${id}/LastKnownLocation`,
      coordinates,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }

  // This API call puts the last known location of a user per event
  deleteLastKnownLocation(accessToken, id) {
    return axios.delete(
      process.env.REACT_APP_EVENT_API_DEV + `${id}/LastKnownLocation`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }

  // Gets the current ETA of the user
  getETA(accessToken, id) {
    return axios.get(
      process.env.REACT_APP_EVENT_API_DEV + `${id}/LastKnownLocation/ETA`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }

  // This API call puts the last known location of a user per event
  setEventTracking(accessToken, id, trackingFlag) {
    return axios.put(
      process.env.REACT_APP_EVENT_API_DEV +
        `${id}/LastKnownLocation/${trackingFlag}`,
      null,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }

  // This API call returns an array of all the qualifications for a particular agency
  getUserQualificationsAgency(accessToken, agency) {
    return axios.post(process.env.REACT_APP_QUALIFICATIONS_API_DEV, agency, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  postUserAttendance(accessToken, id, body) {
    return axios.put(
      process.env.REACT_APP_EVENT_API_DEV + `${id}/attendance`,
      body,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }

  // Sets a flag on an event to hide the event from that user's pager history
  deletePagerMessage(accessToken, ids) {
    return axios.delete(
      process.env.REACT_APP_EVENT_API_DEV + `message?Id=${ids}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }
  // Sets a flag to hide all Inactive and unlocked events from that user's pager history
  deleteAllInactiveUnlockedPagerMessage(accessToken) {
    return axios.delete(
      process.env.REACT_APP_EVENT_API_DEV +
        `Message/DeleteAllUserInactiveOrUnlockedMessagesBulk`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }

  // Prevents the previous API from hiding specific pager messages from the user
  lockPagerMessage(accessToken, lockStatus, ids) {
    return axios.put(
      process.env.REACT_APP_EVENT_API_DEV +
        `message/lock/${lockStatus}?lockStatus=${lockStatus}`,
      {
        messageIds: ids,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }

  // This API call returns a new list of attendees based on the filter and sort criteria
  filterAndSortEventAttendees(accessToken, id, body) {
    return axios.post(
      process.env.REACT_APP_EVENT_API_DEV + `${id}/attendance`,
      body,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }

  /* ----------------------------------------------------
            CALLS TO THE PAGING GROUP API
            Call using unique paging number assoicated with the paging group
----------------------------------------------------*/
  // set threshold for paging group by qualificationId
  updateQualificationThreshold(accessToken, pagingID, details) {
    return axios.put(
      process.env.REACT_APP_PAGING_API_DEV +
        `${pagingID}/qualification/${details.qualificationId}/order/${details.orderNum}/thresholdCount/${details.thresholdCount}`,
      null,
      {
        headers: { Authorization: "Bearer " + accessToken },
      }
    );
  }
  // set threshold for paging group by number of people
  // details = thresholdType & thresholdByPeopleCount
  updatePeopleThreshold(accessToken, pagingID, details) {
    return axios.put(
      process.env.REACT_APP_PAGING_API_DEV + `${pagingID}/threshold`,
      details,
      {
        headers: { Authorization: "Bearer " + accessToken },
      }
    );
  }

  // This API call gets paging groups based on filter criteria
  filterPagingGroups(accessToken, body) {
    return axios.post(process.env.REACT_APP_PAGING_API_DEV + "Group", body, {
      headers: { Authorization: "Bearer " + accessToken },
    });
  }

  // return all paging groups assoicated with that person by filtered by agency sent
  postUserPagingGroups(accessToken, body) {
    return axios.post(
      process.env.REACT_APP_PAGING_API_DEV + "SearchAuthorizedPagingsSummaries",
      body,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }

  // Return all paging groups owned by the user
  // Can filter by agency or based on whether availability is available
  postUserPagingGroupsOwned(accessToken, body) {
    return axios.post(process.env.REACT_APP_PAGING_API_DEV + "owner", body, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  // Returns a details view of all the groups the user is a part of and provides its details settings
  // Used to populate groups in the Settings page
  postUserAgencyPagingGroups(accessToken, agency) {
    return axios.post(
      process.env.REACT_APP_PAGING_API_DEV + "SearchJoinedPagingsDetails",
      agency,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }

  // returns all paging groups assoicated with that user
  getUserPagingGroupsJoinedAndOwned(accessToken) {
    return axios.get(
      process.env.REACT_APP_PAGING_API_DEV + "JoinedAndOwnedPagingsSummaries",
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }

  getOtherUserPagingGroups(accessToken, id) {
    return axios.get(
      process.env.REACT_APP_PROFILE_API_DEV + `${id}/UserPagings`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Pragma: "no-cache",
        },
      }
    );
  }

  // return all members of that paging group
  getPagingGroupMembers(accessToken, PagingGroupNumber) {
    return axios.get(
      process.env.REACT_APP_PAGING_API_DEV +
        `${PagingGroupNumber}/Users/IncludeDecendants/true`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }
  // make user paging group owner
  makeUserPagingGroupsOwner(accessToken, PagingGroupNumber, userId) {
    return axios.put(
      process.env.REACT_APP_PAGING_API_DEV +
        `${PagingGroupNumber}/owner/${userId}`,
      null,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }
  // make user paging group owner
  removePagingGroupsOwner(accessToken, PagingGroupNumber, userId) {
    return axios.delete(
      process.env.REACT_APP_PAGING_API_DEV +
        `${PagingGroupNumber}/owner/${userId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }
  // Returns all the paging groups owned by the user
  getPagingGroupsOwned(accessToken) {
    return axios.get(process.env.REACT_APP_PAGING_API_DEV + "Owner", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  // remove a paging groups from that user
  deleteUserPagingGroup(accessToken, PagingGroupNumber) {
    return axios.delete(
      process.env.REACT_APP_PAGING_API_DEV + PagingGroupNumber,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }
  // remove a user to the paging groups
  deleteUserfromPagingGroup(accessToken, PagingGroupNumber, userId) {
    return axios.delete(
      process.env.REACT_APP_PAGING_API_DEV +
        `${PagingGroupNumber}/user/${userId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }
  // add a paging groups to that user
  addUserPagingGroup(accessToken, PagingGroupNumber) {
    return axios.put(process.env.REACT_APP_PAGING_API_DEV + PagingGroupNumber, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }
  // add a user to the paging groups
  addUsertoPagingGroup(accessToken, PagingGroupNumber, userId) {
    return axios.put(
      process.env.REACT_APP_PAGING_API_DEV +
        `${PagingGroupNumber}/user/${userId}`,
      null,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }
  // get a paging groups to that user
  getPagingGroup(accessToken, PagingGroupNumber) {
    return axios.get(process.env.REACT_APP_PAGING_API_DEV + PagingGroupNumber, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  // Paging group always on - Use this to stop users from making the group inactive.
  // Admin would set Paging Group ID 123 mutable to false = uses can't turn this off
  setPagingGroupAlwaysOn(accessToken, GroupID, mutable) {
    return axios.put(
      process.env.REACT_APP_PAGING_API_DEV + `${GroupID}/mutable/${mutable}`,
      null,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }

  // For setting the "other" text on an event per group
  setOtherText(accessToken, GroupID, otherText) {
    return axios.put(
      process.env.REACT_APP_PAGING_API_DEV + `${GroupID}/attendanceOtherTitle`,
      { attendanceOtherTitle: otherText },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }

  /* ----------------------------------------------------
            CALLS TO THE VIPER GROUP API
            Call using imported viper IDs
  ----------------------------------------------------*/

  /* ----------------------------------------------------
          ETA CALLS
----------------------------------------------------*/

  // getETA(start, destination) {
  //   return axios.get(
  //     "https://route.ls.hereapi.com/routing/7.2/calculateroute.json?apiKey=0dDFL_lZ1N_IuP66jQ_vucdfMvScTacSCewN0pDyQg4" +
  //       "&waypoint0=geo!" +
  //       start +
  //       "&waypoint1=geo!" +
  //       destination +
  //       "&mode=fastest;car;traffic:enabled"
  //   );
  // }

  /* ----------------------------------------------------
            CHAT CALLS
----------------------------------------------------*/
  // get unread thread count
  getUnreadThreadCount(accessToken) {
    return axios.get(process.env.REACT_APP_CHAT_API_DEV + "unreadThreadCount", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  // get all message threads
  postChatMessages(accessToken, body) {
    return axios.post(
      //"https://localhost:5001/v1/Event/message", // This is using dummy data run locally - see README
      process.env.REACT_APP_CHAT_API_DEV + "Summary", // Dev API
      body,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }

  // get thread of chat message
  getChatThread(accessToken, ID) {
    return axios.get(process.env.REACT_APP_CHAT_API_DEV + ID, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  // get thread of chat messages by event ID
  getChatThreadByEvent(accessToken, eventId) {
    return axios.get(
      process.env.REACT_APP_CHAT_API_DEV + `Type/Event/${eventId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }

  // post chat message to chat thread
  putChatMessage(accessToken, ID, body) {
    return axios.put(
      process.env.REACT_APP_CHAT_API_DEV + ID + "/message",
      body,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }

  // post chat message to chat thread
  putThreadActiveState(accessToken, ID, body) {
    return axios.put(
      process.env.REACT_APP_CHAT_API_DEV + ID + "/active/" + body,
      null,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }

  // create new 1 on 1 thread
  postOneOnOneThread(accessToken, body) {
    return axios.post(
      process.env.REACT_APP_CHAT_API_DEV + "type/OneOnOne",
      body,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }

  // create new 1 on 1 thread
  postAdhocGroupThread(accessToken, body) {
    return axios.post(
      process.env.REACT_APP_CHAT_API_DEV + "type/GroupAdHoc",
      body,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }

  // create new 1 on 1 thread
  postGroupThread(accessToken, body) {
    return axios.post(process.env.REACT_APP_CHAT_API_DEV + "type/Group", body, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }
  /* ----------------------------------------------------
            PERSONAL AVAILABILITY
  ----------------------------------------------------*/
  // This API call returns the user's availability
  getUsersAvailability(accessToken, dates) {
    return axios.post(
      process.env.REACT_APP_PROFILE_API_DEV + `availability`,
      dates,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }
  // This API adds to the user's availability
  addAvailability(accessToken, details) {
    return axios.put(
      process.env.REACT_APP_PROFILE_API_DEV + `availability`,
      details,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }

  // // This API adds to the user's availability
  // editAvailability(accessToken, details, editID) {
  //   return axios.put(
  //     process.env.REACT_APP_PROFILE_API_DEV + `availability/${editID}`,
  //     details,
  //     {
  //       headers: {
  //         Authorization: `Bearer ${accessToken}`,
  //       },
  //     }
  //   );
  // }

  // This API removes a single time from the user's availability
  removeSingleAvailability(accessToken, id) {
    return axios.delete(
      process.env.REACT_APP_PROFILE_API_DEV + `availability/${id}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }

  // This API removes single recurring time from the user's availability
  removeSingleRecurringAvailability(accessToken, id, date) {
    return axios.delete(
      process.env.REACT_APP_PROFILE_API_DEV +
        `availability/${id}/occurrence/${date}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }
  // This API removes recurring time from the user's availability
  removeRecurringAvailability(accessToken, id, date) {
    return axios.delete(
      process.env.REACT_APP_PROFILE_API_DEV +
        `availability/timeseries/${id}/${date}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }

  // This API call returns a selcted user's availability
  getSelectedUsersAvailability(accessToken, dates, id) {
    return axios.post(
      process.env.REACT_APP_PROFILE_API_DEV + `${id}/availability`,
      dates,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }

  // This API call returns a selcted user's availability
  getUsersNextAvailTime(accessToken, dates, id) {
    return axios.post(
      process.env.REACT_APP_PROFILE_API_DEV + `Availability/`,
      dates,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }
  getNewAvail(accessToken) {
    return axios.post(
      process.env.REACT_APP_HOME_AVAILABILITY_API_DEV + `free/next`,
      null,
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
  }

  // This API adds to the user's unavailability
  addUnavailability(accessToken, details) {
    return axios.put(
      process.env.REACT_APP_PROFILE_API_DEV + `unavailability`,
      details,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }

  // // This API adds to the user's unavailability
  // editUnavailability(accessToken, details, editID) {
  //   return axios.put(
  //     process.env.REACT_APP_PROFILE_API_DEV + `unavailability/${editID}`,
  //     details,
  //     {
  //       headers: {
  //         Authorization: `Bearer ${accessToken}`,
  //       },
  //     }
  //   );
  // }

  // This API removes a single time from the user's unavailability
  removeSingleUnavailability(accessToken, id) {
    return axios.delete(
      process.env.REACT_APP_PROFILE_API_DEV + `unavailability/${id}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }

  // This API removes single recurring time from the user's unavailability
  removeSingleRecurringUnavailability(accessToken, id, date) {
    return axios.delete(
      process.env.REACT_APP_PROFILE_API_DEV +
        `unavailability/${id}/occurrence/${date}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }

  /* ----------------------------------------------------
            PAGING GROUP AVAILABILITY
  ----------------------------------------------------*/
  // get group threshold month view
  getThresholdMonth(accessToken, dates, id) {
    return axios.post(
      process.env.REACT_APP_AVAILABILITY_API_DEV + `${id}`,
      dates,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }
  // get group threshold 24 hr view
  getThresholdDay(accessToken, id, date) {
    return axios.get(
      process.env.REACT_APP_AVAILABILITY_API_DEV + `${id}/date/${date}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }
  // Get threshold information per group
  getThresholdDayDetailed(accessToken, id, date) {
    return axios.get(
      process.env.REACT_APP_AVAILABILITY_API_DEV +
        `${id}/date/${date}/detailed`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }
  // get group detail view
  getThresholdDetails(accessToken, id, details) {
    return axios.post(
      process.env.REACT_APP_AVAILABILITY_API_DEV + `${id}/user/timeblock`,
      details,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }
  // get next 24 hr - note this only works with 24 at the moment - 22 Apr 20
  getHrs(accessToken, body, hrs) {
    return axios.post(
      process.env.REACT_APP_AVAILABILITY_API_DEV + `hours/${hrs}`,
      body,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }
  // get next week or 2 weeks - numbers = 7 or 14
  getDays(accessToken, body, number) {
    return axios.post(
      process.env.REACT_APP_AVAILABILITY_API_DEV + `days/${number}`,
      body,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }
  getPagingGroupAvailability(accessToken, body, id, date, type) {
    return axios.post(
      process.env.REACT_APP_AVAILABILITY_API_DEV +
        `${id}/date/${date}/type/${type}/users`,
      body,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }

  /* ----------------------------------------------------
            PAGING GROUP QUALIFICATION
  ----------------------------------------------------*/
  // get qualification month view
  getQualificationMonth(accessToken, dates, id, qualificationId) {
    return axios.post(
      process.env.REACT_APP_AVAILABILITY_API_DEV +
        `${id}/qualification/${qualificationId}/thresholdachieved`,
      dates,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }
  // get qualification 24 hr view
  getQualificationDay(accessToken, id, date, qualificationId) {
    return axios.get(
      process.env.REACT_APP_AVAILABILITY_API_DEV +
        `${id}/date/${date}/qualification/${qualificationId}/thresholdachieved`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }
  // get qualification detail view
  getQualificationDetails(accessToken, id, details, qualificationId) {
    return axios.post(
      process.env.REACT_APP_AVAILABILITY_API_DEV +
        `${id}/user/timeblock/qualification/${qualificationId}`,
      details,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }

  /* ----------------------------------------------------
            AVAILABILITY TYPES
  ----------------------------------------------------*/
  //  month view
  getAvailTypeMonth(accessToken, dates, id, typeId) {
    return axios.post(
      process.env.REACT_APP_AVAILABILITY_API_DEV +
        `${id}/type/${typeId}/thresholdachieved`,
      dates,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }
  //  24 hr view
  getAvailTypeDay(accessToken, id, date, typeId) {
    return axios.get(
      process.env.REACT_APP_AVAILABILITY_API_DEV +
        `${id}/date/${date}/type/${typeId}/thresholdachieved`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }
  //detail view
  getAvailTypeDetails(accessToken, id, details, typeId) {
    return axios.post(
      process.env.REACT_APP_AVAILABILITY_API_DEV +
        `${id}/user/timeblock/type/${typeId}`,
      details,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }

  /* ----------------------------------------------------
             CALLS TO THE REPORT API
  ----------------------------------------------------*/

  // download PDF report
  downloadReport(accessToken, reportType, fileType, details) {
    return axios.post(
      process.env.REACT_APP_REPORT_API_DEV +
        `Report/Type/${reportType}/FileType/${fileType}`,
      details,
      {
        //responseType: "arraybuffer",
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: "application/pdf",
        },
      }
    );
  }

  // This will create a scheduled report
  setReportSchedule(accessToken, body, reportType, reportFrequency, fileType) {
    return axios.post(
      process.env.REACT_APP_REPORT_API_DEV +
        `Report/Schedule/Type/${reportType}/Frequency/${reportFrequency}/FileType/${fileType}`,
      body,
      {
        headers: { Authorization: "Bearer " + accessToken },
      }
    );
  }

  //  This will fetch all the exisiting reports
  getScheduledReports(accessToken) {
    return axios.get(process.env.REACT_APP_REPORT_API_DEV + `Report/Schedule`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  // This API call deletes a scheduled report for the user
  deleteScheduledReports(accessToken, id) {
    return axios.delete(
      process.env.REACT_APP_REPORT_API_DEV + `Report/Schedule/${id}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }

  /* ----------------------------------------------------
        SAS App Configs
----------------------------------------------------*/

  getSASAppConfigs() {
    return axios.get(process.env.REACT_APP_ROOT_DEV + `sasAppConfigs.json`, {});
  }

  /* ----------------------------------------------------
            CALLS TO THE TURNOUT API
            Call using unique paging number assoicated with the paging group
----------------------------------------------------*/
  // This API call returns the user's default navigation app
  getUserTurnoutSettings(accessToken) {
    return axios.get(process.env.REACT_APP_TURNOUTSETTINGS_API_DEV, {
      headers: { Authorization: "Bearer " + accessToken },
    });
  }

  // update turnout settings
  updateTurnoutSettings(accessToken, body) {
    return axios.put(
      process.env.REACT_APP_TURNOUTSETTINGS_API_DEV + "UpdateTurnoutSettings",
      body,
      {
        headers: { Authorization: "Bearer " + accessToken },
      }
    );
  }

  getTextToSpeechMessage(accessToken, id) {
    return axios.get(
      process.env.REACT_APP_TURNOUT_API_DEV + `TextToSpeech/${id}`,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );
  }

  getSignalRToken(accessToken, id) {
    return axios.get(
      process.env.REACT_APP_TURNOUT_API_DEV + `SignalR/GenerateSignalRTokenFE`,
      {
        params: {
          _url: process.env.REACT_APP_TURNOUT_SIGNALR_DEV,
          _userID: id,
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }

  sendUserProfileSignalR(accessToken, id) {
    return axios.post(
      process.env.REACT_APP_TURNOUT_API_DEV +
        `SignalR/AssignUserToPagingGroups?${id}`,
      {},
      {
        params: {
          _userID: id,
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }
}
