import React from "react";
import Wrapper from "../../hoc/Wrapper/Wrapper";
import styles from "./TurnoutView.module.scss";
import { withRouter } from "react-router-dom";
import { format } from "date-fns";

import Loader from "../../components/UI/Loading/Loading";
import copy from "../../assets/copy/copy.json";

import { Requests } from "../../api/IdentityServerRequests/Requests";
import Button from "../../components/UI/Button/Button";
import AttendanceCount from "../../components/Attendance/AttendanceCount";
import TurnoutAttendance from "./Attendance/TurnoutAttendance";
import DefaultMap from "../../components/Map/DefaultMap";
import Timer from "../../components/Timer/Timer";

import SES from "../../assets/img/logos/logo_ses.png";
import CFA from "../../assets/img/logos/logo_cfa.png";
import AV from "../../assets/img/logos/logo_av.png";
import EMV from "../../assets/img/logos/logo_emv.svg";
import ActiveEventTimer from "./ActiveEventTimer";

var classNames = require("classnames");

class ActiveEventTurnoutView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      turnoutSettings: null,
      pagerHeaderHeight: 0,
      pagerFontSize: 3.5,
      event: null,
      isLoading: true,
      userAgencyInfo: null,
      eventSettings: null,
      errorMessage: null, // Error message for the banner
      userSettings: null,
      close: false,
      intervalId: null,
      currentIntervalSeconds: 10,
      isCheckingForEvents: false,
    };
    this.timeout = null;
    this.Requests = new Requests();
    this.getUserSettings();
    this.getTurnoutSettings();
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    let activeEventID = this.props.eventID;

    if (activeEventID) {
      this.getActiveEventDetails(activeEventID, true);
    }
    this.calculateUserSearchHeaderHeight();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.eventID !== this.props.eventID ||
      prevProps.updateEvent !== this.props.updateEvent
    ) {
      this.clearEventRefresh();
      let activeEventID = this.props.eventID;

      if (activeEventID) {
        this.getActiveEventDetails(activeEventID, false);
      }
    }
    if (prevProps.isMapExpanded !== this.props.isMapExpanded) {
      if (this.props.isMapExpanded) {
        this.timeout = setTimeout(
          function () {
            this.calculateUserSearchHeaderHeight();
          }.bind(this),
          500
        );
      } else {
        this.timeout = setTimeout(
          function () {
            this.calculateUserSearchHeaderHeight();
          }.bind(this),
          500
        );
      }
    }
  }

  componentWillUnmount = () => {
    clearTimeout(this.timeout);
  };

  /**
   * Called when the page needs to be refreshed
   * Due to API call(s) failing at the start
   */
  handleRefresh = () => {
    this.setState({ errorMessage: null });

    // Only remake API call to get user settings if it wasn't successful
    if (!this.state.userSettings) {
      this.getUserSettings();
    }

    let activeEventID = this.props.eventID;

    if (activeEventID) {
      this.getActiveEventDetails(activeEventID, true);
    }

    // Trigger refresh of other components of dashboard (if needed)
    this.props.turnoutRefresh();
  };

  /**
   * Retrieve active event details from the api
   */
  getActiveEventDetails(id, showLoader) {
    this.setState(
      {
        isLoading: showLoader,
      },
      () => {
        this.Requests.callAPI(this.Requests.getEventDetails, id).then(
          (data) => {
            if (data && data.status && data.status === 200) {
              this.setState(
                {
                  event: data.data,
                  isLoading: false,
                },
                () => {
                  if (this.state.event === undefined) {
                    if (
                      this.state.event.location === undefined ||
                      this.state.event.location === null
                    ) {
                      this.setState({ verifiedCoords: false });
                    }
                  }
                  if (
                    this.state.event
                      ?.userAttendanceOtherUsersByAttendanceStatus !== null
                  ) {
                    this.startEventRefresh();
                  }
                  this.getUserAgencySettings();
                  this.calculateFontSize();
                  this.calculateUserSearchHeaderHeight();
                }
              );
            } else {
              let ErrorMessage =
                copy.dashboard.getEventDetailsAPIErrorMessage +
                ` (Error #${copy.errorCodes.getEventDetailsAPIErrorMessage})`;
              this.setState({ errorMessage: ErrorMessage });
              this.calculateUserSearchHeaderHeight();
            }
          }
        );
      }
    );
  }

  /**
   * Called when the current selected event is an event requiring attendance update
   * will call the event api every 10 seconds to update data
   */
  startEventRefresh = () => {
    let intervalId = setInterval(this.refreshTimer, 1000);
    this.setState({ intervalId: intervalId });
  };

  /**
   * Clear interval and reset timer when selecting other events
   */
  clearEventRefresh = () => {
    // clear refresh interval
    clearInterval(this.state.intervalId);
    this.setState({ currentIntervalSeconds: 10, isCheckingForEvents: false });
  };

  /**
   * Called from within refresh interval to decrement current interval seconds
   * and determine when it is time to refresh event data/call api
   */
  refreshTimer = () => {
    let currentTime = this.state.currentIntervalSeconds;
    if (currentTime > 1) {
      this.setState({
        currentIntervalSeconds: currentTime - 1,
      });
    } else if (currentTime === 1) {
      this.setState(
        {
          currentIntervalSeconds: "Checking for updates...",
          isCheckingForEvents: true,
        },
        () => {
          setTimeout(
            function () {
              this.getAttendanceUpdate();
            }.bind(this),
            25
          );
        }
      );
    }
  };

  /**
   * called the event api to check if for any update to the eta or the location coordinates
   */
  getAttendanceUpdate() {
    this.Requests.callAPI(
      this.Requests.getEventDetails,
      this.props.eventID
    ).then((data) => {
      if (data && data.status && data.status === 200) {
        const newEventAttendance =
          data.data.userAttendanceOtherUsersByAttendanceStatus;
        const currentEventAttendance =
          this.state.event.userAttendanceOtherUsersByAttendanceStatus;

        // Update data if there is attendance update
        if (
          JSON.stringify(newEventAttendance) !==
          JSON.stringify(currentEventAttendance)
        ) {
          this.setState({
            event: {
              ...this.state.event,
              userAttendanceOtherUsersByAttendanceStatus: newEventAttendance,
            },
          });
        }

        this.setState({
          isCheckingForEvents: false,
          currentIntervalSeconds: 10,
        });
      } else {
        let ErrorMessage =
          copy.dashboard.getEventDetailsAPIErrorMessage +
          ` (Error #${copy.errorCodes.getEventDetailsAPIErrorMessage})`;
        this.setState({ errorMessage: ErrorMessage });
      }
    });
  }

  getUserAgencySettings = () => {
    let agency;
    if (this.state.event.paging.agency) {
      agency = this.state.event.paging.agency;
    }

    let AV;
    let CFA;
    let SES;

    if (this.props.userAgencyInfo) {
      if (this.props.userAgencyInfo[0]) {
        if (this.props.userAgencyInfo[0].agency === "AV") {
          AV = this.props.userAgencyInfo[0];
        } else if (this.props.userAgencyInfo[0].agency === "CFA") {
          CFA = this.props.userAgencyInfo[0];
        } else if (this.props.userAgencyInfo[0].agency === "SES") {
          SES = this.props.userAgencyInfo[0];
        }
      }
      if (this.props.userAgencyInfo[1]) {
        if (this.props.userAgencyInfo[1].agency === "AV") {
          AV = this.props.userAgencyInfo[1];
        } else if (this.props.userAgencyInfo[1].agency === "CFA") {
          CFA = this.props.userAgencyInfo[1];
        } else if (this.props.userAgencyInfo[1].agency === "SES") {
          SES = this.props.userAgencyInfo[1];
        }
      }
      if (this.props.userAgencyInfo[2]) {
        if (this.props.userAgencyInfo[2].agency === "AV") {
          AV = this.props.userAgencyInfo[2];
        } else if (this.props.userAgencyInfo[2].agency === "CFA") {
          CFA = this.props.userAgencyInfo[2];
        } else if (this.props.userAgencyInfo[2].agency === "SES") {
          SES = this.props.userAgencyInfo[2];
        }
      }
      if (this.props.userAgencyInfo[3]) {
        if (this.props.userAgencyInfo[3].agency === "AV") {
          AV = this.props.userAgencyInfo[3];
        } else if (this.props.userAgencyInfo[3].agency === "CFA") {
          CFA = this.props.userAgencyInfo[3];
        } else if (this.props.userAgencyInfo[3].agency === "SES") {
          SES = this.props.userAgencyInfo[3];
        }
      }
    }

    if (agency && AV && agency === AV.agency) {
      this.setState({
        eventSettings: AV,
      });
    } else if (agency && CFA && agency === CFA.agency) {
      this.setState({
        eventSettings: CFA,
      });
    } else if (agency && SES && agency === SES.agency) {
      this.setState({
        eventSettings: SES,
      });
    } else {
      this.setState({
        eventSettings: null,
      });
    }
  };

  /**
   * This function will redirect the user to the event details page
   */
  redirectToEvent = (currentEvent) => {
    let isMobileApp = window.cordova;
    // query string contain information about the event to display before the event API loads
    if (isMobileApp) {
      this.props.history.push({
        pathname: "/eventdetails/" + currentEvent.id,
        search:
          "eventStatus=" +
          currentEvent.eventStatus +
          "&agency=" +
          currentEvent.messages[0].agency +
          "&cADEventId=" +
          currentEvent.messages[0].cADEventId +
          "&messageInputTimeStamp=" +
          currentEvent.messages[0].messageInputTimeStamp +
          "&messageType=" +
          currentEvent.messages[0].messageType +
          "&pagingName=" +
          currentEvent.messages[0].paging.name +
          "&messageText=" +
          currentEvent.messages[0].messageText,
      });
    } else {
      this.props.history.push({
        pathname: "/eventdetails/" + currentEvent.id,
        search:
          "eventStatus=" +
          currentEvent.eventStatus +
          "&messageType=" +
          currentEvent.messages[0].messageType,
      });
    }
  };

  /**
   * Gets all current user settings from the backend
   */
  getUserSettings = () => {
    this.Requests.callAPI(this.Requests.getUserSettings).then((data) => {
      if (data && data.status && data.status === 200) {
        this.setState({
          userSettings: data.data,
        });
      } else {
        // Check if this is required
        let ErrorMessage =
          copy.dashboard.userInfoAPIErrorMessage +
          ` (Error #${copy.errorCodes.userInfoSettingsAPIErrorMessage})`;
        this.setState({ errorMessage: ErrorMessage });
      }
    });
  };

  getTurnoutSettings = () => {
    this.Requests.callAPI(this.Requests.getUserTurnoutSettings).then((data) => {
      if (!data || data.status !== 200) {
        let ErrorMessage =
          copy.turnout.turnoutSettingsAPIErrorMessage +
          ` (Error #${copy.errorCodes.getTurnoutSettingsErrorMessage})`;
        this.setState(
          {
            errorMessage: ErrorMessage,
            getSettingsError: true,
          },
          () => {
            setTimeout(
              function () {
                this.setState({
                  errorMessage: null,
                  getSettingsError: null,
                });
              }.bind(this),
              5000
            );
          }
        );
      } else {
        if (data && data.status && data.status === 200 && data.data) {
          this.initialSettings = data.data;
          this.setState({ turnoutSettings: data.data });
        }
      }
    });
  };

  calculateUserSearchHeaderHeight = () => {
    if (this.pagerHeaderHeight) {
      let PagerHeaderHeight = this.pagerHeaderHeight.offsetHeight;
      this.setState({
        pagerHeaderHeight: PagerHeaderHeight,
      });
    } else {
      return;
    }
  };

  calculateFontSize = () => {
    let PagerMessageLength = this.state.event.messages[0].messageText.length;
    this.setState((prevState) => {
      return {
        pagerFontSize:
          PagerMessageLength < 75
            ? 3.1
            : PagerMessageLength < 200
            ? 2
            : PagerMessageLength < 350
            ? 1.8
            : 1.8,
      };
    });
  };

  agencyLogo(agency) {
    switch (agency) {
      case "AV": // 0
        return <img src={AV} alt="Ambulance Victoria logo" />;
      case "CFA": // 1
        return <img src={CFA} alt="CFA logo" />;
      case "SES": // 2
        return <img src={SES} alt="SES logo" />;
      default:
        return <img src={EMV} alt="EMV logo" />;
    }
  }

  handleFullscreenMap = (state) => {
    this.setState({
      fullscreenMap: state,
    });
    return this.state.fullscreenMap;
  };

  handleCloseLocation = (state) => {
    this.setState({ close: state });
    return state;
  };

  render() {
    if (this.state.event) {
      // This data is feed into the attendance map (attendance list second tab)
      let mapData = {
        unitLocation:
          this.state.event.paging && this.state.event.paging.GeoLocation
            ? this.state.event.paging.GeoLocation
            : null,
        eventLocation: this.state.event.location
          ? this.state.event.location
          : null,
        attending:
          this.state.event.userAttendanceOtherUsersByAttendanceStatus &&
          this.state.event.userAttendanceOtherUsersByAttendanceStatus.attending
            ? this.state.event.userAttendanceOtherUsersByAttendanceStatus
                .attending
            : null,
        agency:
          this.state.event.paging && this.state.event.paging.agency
            ? this.state.event.paging.agency
            : null,
        eventID: this.state.event.id ? this.state.event.id : null,
      };

      let eventLockedState = classNames(
        styles.eventLockedState,
        this.props.className,
        {
          [styles.locked]: this.props.eventIsLocked,
        }
      );

      let AlertTypeStyle;
      let AlertTypeText;

      if (
        this.state.event.messages[0] &&
        this.state.event.messages[0].messageType
      ) {
        if (this.state.event.messages[0].messageType === "NonEmergency") {
          AlertTypeStyle = styles.nonEmergency;
          AlertTypeText = "NON EMERGENCY";
        } else if (this.state.event.messages[0].messageType === "Emergency") {
          AlertTypeStyle = styles.emergency;
          AlertTypeText = "EMERGENCY";
        } else if (this.state.event.messages[0].messageType === "Admin") {
          AlertTypeStyle = styles.admin;
          AlertTypeText = "ADMIN";
        }
      }

      if (this.state.isLoading) {
        return (
          <div className={styles.loadingContainer}>
            <Loader />
          </div>
        );
      } else {
        return (
          <Wrapper>
            <div className={styles.container}>
              <div className={styles.eventContainer}>
                <div
                  className={[
                    styles.activeEventWrapper,
                    eventLockedState,
                    AlertTypeStyle,
                    this.props.isMapExpanded && styles.expandedMap,
                    "tileBg",
                  ].join(" ")}
                >
                  <div className={styles.header}>
                    {this.props.isMapExpanded && (
                      <ActiveEventTimer
                        event={this.state.event}
                        isExpandedView={true}
                      />
                    )}
                    {this.props.eventIsLocked ? (
                      <div
                        ref={(pagerHeaderHeight) => {
                          this.pagerHeaderHeight = pagerHeaderHeight;
                        }}
                        className={
                          styles.pagerHeader +
                          " " +
                          AlertTypeStyle +
                          " " +
                          (this.state.event &&
                            this.state.event.eventStatus === "Closed" &&
                            styles.ended)
                          // closed (1) event status
                        }
                      >
                        <div className={styles.top}>
                          <div className={styles.agencyLogo}>
                            {this.agencyLogo(
                              this.state.event &&
                                this.state.event.messages[0] &&
                                this.state.event.messages[0].agency
                            )}
                          </div>
                          <div className={styles.alertType}>
                            <span className={AlertTypeStyle}>
                              {AlertTypeText}
                            </span>
                          </div>
                          <div className={styles.timeStamp}>
                            <span className={styles.time}>
                              {format(
                                new Date(
                                  this.state.event &&
                                    this.state.event.messages[0] &&
                                    this.state.event.messages[0]
                                      .messageInputTimeStamp
                                ),
                                "HH:mm:ss dd-MM-yyyy"
                              )}
                            </span>
                          </div>
                        </div>
                        <div className={styles.bottom}>
                          <div className={styles.pagerGroup + " tileBg"}>
                            <span>
                              {this.state.event &&
                                this.state.event.messages[0] &&
                                this.state.event.messages[0].paging.name}
                            </span>
                          </div>
                        </div>
                        <div className={styles.details}>
                          <div
                            className={
                              styles.pagerDetails + " txt4 smartlookMask "
                            }
                          >
                            <span
                              style={{
                                fontSize: `${this.state.pagerFontSize}rem`,
                              }}
                            >
                              {this.state.event &&
                                this.state.event.messages[0] &&
                                this.state.event.messages[0].messageText}
                            </span>
                          </div>
                        </div>
                        <div className={styles.footerWrapper}>
                          <Wrapper>
                            {this.state.event &&
                            this.state.event.eventStatus === "Open" ? (
                              <Timer
                                initialTimestamp={this.state.event.messages}
                                eventStatus={this.state.event.eventStatus}
                                pagerMessage={true}
                                showMobileView={true}
                              />
                            ) : (
                              <div className={styles.inactiveIcon}>
                                <span className="grey7 txt4">Inactive</span>
                              </div>
                            )}
                            {/* Attendance count is only displayed on the turnout and not for AV */}
                            {this.state.event.messages[0].agency !== "AV" &&
                              this.state.event.messages[0].agency !== null &&
                              this.state.event
                                .userAttendanceOtherUsersByAttendanceStatus !==
                                null && (
                                <AttendanceCount
                                  attending={
                                    this.state.event
                                      ?.userAttendanceOtherUsersByAttendanceStatus
                                      ?.attending?.length
                                  }
                                  other={
                                    this.state.event
                                      ?.userAttendanceOtherUsersByAttendanceStatus
                                      ?.other?.length
                                  }
                                  notAttending={
                                    this.state.event
                                      ?.userAttendanceOtherUsersByAttendanceStatus
                                      ?.unavailable?.length
                                  }
                                />
                              )}
                          </Wrapper>
                        </div>
                      </div>
                    ) : (
                      <div
                        ref={(pagerHeaderHeight) => {
                          this.pagerHeaderHeight = pagerHeaderHeight;
                        }}
                        className={styles.activeEventHeader}
                      ></div>
                    )}
                  </div>
                </div>
                <div
                  style={{
                    height: `calc(100% - 12px - ${this.state.pagerHeaderHeight}px)`,
                  }}
                  className={styles.eventDetails}
                >
                  <div className={styles.eventDetailsMap}>
                    <DefaultMap
                      key={this.state.event.id}
                      showAttendeeMarkers={true}
                      event={this.state.event}
                      mapData={mapData}
                      height={"turnout"}
                      userSettings={this.state.userSettings}
                      expandMap={this.props.expandMap}
                      isMapExpanded={this.props.isMapExpanded}
                      FullscreenMap={this.handleFullscreenMap}
                      isFullscreen={this.state.fullscreenMap}
                      showRoute={this.state.turnoutSettings.routing}
                    />
                  </div>
                </div>
              </div>
              {this.state.turnoutSettings.showAttendance &&
                this.state.eventSettings &&
                this.state.eventSettings.eventAttendanceEnabled &&
                this.state.event.paging.settings.attendingResponseNeeded && (
                  <div
                    className={[
                      styles.eventDetailsAttendance,
                      AlertTypeStyle,
                      eventLockedState,
                    ].join(" ")}
                  >
                    <div className={styles.top}>
                      <div className={styles.alertType}>
                        <span className={AlertTypeStyle}>Attendance List</span>
                      </div>
                    </div>
                    <TurnoutAttendance
                      key={this.state.event.id}
                      mapData={mapData}
                      userSettings={this.state.userSettings}
                      eventStatus={this.state.event.eventStatus}
                      agency={this.state.event.paging.agency}
                      attendanceList={
                        this.state.event
                          .userAttendanceOtherUsersByAttendanceStatus
                      }
                      FullscreenMap={this.handleFullscreenMap}
                      closeFunction={this.handleCloseLocation}
                      eventId={this.state.event.id}
                      settings={this.state.turnoutSettings}
                    />
                  </div>
                )}
            </div>
          </Wrapper>
        );
      }
      // Situation where API calls fail on initial load
    } else {
      if (
        this.state.errorMessage !== null || // If an error with API call for the active event occurred
        this.props.allActiveEvents === false // If no events could be initially loaded
      ) {
        // API failed to fetch event
        return (
          <h4>
            <span className="noMessages">
              {copy.dashboard.getEventDetailsAPIErrorMessage +
                ` (Error #${copy.errorCodes.getEventDetailsAPIErrorMessage})`}
              <div>
                <Button
                  label="Refresh"
                  content={copy.global.btnRefresh}
                  variant="btn_solid"
                  styles="greyBg"
                  icon="icon_refresh"
                  buttonClick={this.handleRefresh}
                />
              </div>
            </span>
          </h4>
        );
      } else {
        // Loading state
        return (
          <div className={styles.loadingContainer}>
            <Loader />
          </div>
        );
      }
    }
  }
}
export default withRouter(ActiveEventTurnoutView);
