import React, { Component } from "react";
import styles from "./Popup.module.scss";
import Wrapper from "../../../hoc/Wrapper/Wrapper";
import { withRouter } from "react-router-dom";
import Button from "../Button/Button";

var classNames = require("classnames");

class Popup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPopup: false,
    };
  }

  componentWillUnmount() {
    this.setState({ showPopup: false });
  }

  render() {
    if (this.props.isVisible) {
      document.body.classList.add("popup-mode");
    } else {
      document.body.classList.remove("popup-mode");
    }

    let CloseAlertControls = classNames(
      "tileBg2 " + styles.closeAlertControls,
      this.props.className,
      {
        [styles.visible]: this.props.isVisible,
      }
    );

    let DarkMask = classNames(styles.darkMask, this.props.className, {
      [styles.visible]: this.props.isVisible,
    });

    return (
      <Wrapper>
        <div>
          <div className={CloseAlertControls}>
            <div className={styles.content}>
              <span>{this.props.titleText}</span>
              <span className={styles.subtitleText}>
                {this.props?.subtitleText}
              </span>
              <div className={styles.controls}>
                <Button
                  label="popup button 1"
                  buttonClick={this.props.button1Action}
                  content={this.props.button1Text}
                  variant="btn_primary"
                  styles="greenTxt"
                ></Button>
                <Button
                  label="cancel"
                  buttonClick={this.props.button2Action}
                  content={this.props.button2Text}
                  variant="btn_primary"
                  styles="red"
                ></Button>
              </div>
            </div>
          </div>
          <div className={DarkMask} />
        </div>
      </Wrapper>
    );
  }
}

export default withRouter(Popup);
