import React from "react";
import styles from "./GroupSettings.module.scss";
import Toggle from "../../components/UI/Button/Toggle";
import Accordion from "../../components/UI/Accordion/Accordion";
import Radio from "../../components/UI/Button/Radio";
import Button from "../../components/UI/Button/Button";
import Wrapper from "../../hoc/Wrapper/Wrapper";
import copy from "../../assets/copy/copy.json";
import { Requests } from "../../api/IdentityServerRequests/Requests";
import Loader from "../../components/UI/Loading/Loading";

export default class GroupSettings extends React.Component {
  constructor(props) {
    super(props);
    this.Requests = new Requests();
    this.state = {
      // List of available sounds. New sounds must be added here.
      sounds: ["SAS Chat Tone 1", "SAS Chat Tone 2", "SAS Chat Tone 3"],
      // List of available light colours. New colours must be added here.
      lights: ["White", "Black", "Yellow"],
      // Initial state for reminder.
      reminder: null,
      // Contains current settings and updated as they change.
      selected: {
        sound: {
          name: "SAS Chat Tone 1",
        },
        light: { name: "White", hex: "FFFFFF", active: true },
        vibration: true,
        channelVersion: 0,
      },
      // If true, shows the initial screen where users can select which alert type they want to edit.
      showEditScreen: false,
      // If true, shows loader.
      loading: true,
      errorMessage: null,
      // Only show on iOS when the user tries to turn off vibrations with sound
      vibrationsLimitationMessage: false,
    };
    // iOS and Android handle audio differently
    if (window.device) {
      if (window.device.platform === "iOS") {
        this.currentAudio = new window.Media(
          require("../../assets/tones/SAS Chat Tone 1.wav")
        );
      } else {
        this.currentAudio = new Audio(
          require("../../assets/tones_mp3/SAS Chat Tone 1.mp3")
        );
      }
    }
    // Keeps track of playing sound.
    this.timeout = null;
  }

  /**
   * componentDidMount for GroupSettings.
   * Sets current settings if there are settings passed in (returned from API).
   */
  componentDidMount() {
    this.getChatPreferences();
  }

  /**
   * componentDidUpdate for ChatSettings
   * Used to ensure that if GroupSettings is in Edit mode, ChatSettings is not
   * @param {JSON} prevProps The previous props.
   */
  componentDidUpdate(prevProps) {
    // Close the chat notifications settings if group notification settings was toggled to edit state
    if (
      prevProps.groupNotificationsEditState !==
        this.props.groupNotificationsEditState &&
      this.props.groupNotificationsEditState &&
      this.state.showEditScreen
    ) {
      this.setState({ showEditScreen: false }, () => {
        this.props.toggleChatNotificationsEditState(this.state.showEditScreen);
      });
    }
  }

  // Converts the sound file name from sas_chat_tone_1 to SAS Chat Tone 1
  fixSoundsFileName(str) {
    var fixedSoundName = str;
    fixedSoundName = fixedSoundName.replace(/_/g, " ");
    return fixedSoundName.replace(/\w\S*/g, function (txt) {
      let returnTxt = txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      returnTxt = returnTxt.replace("Sas", "SAS");
      return returnTxt;
    });
  }

  getChatPreferences = () => {
    let deviceId = window.device.uuid;
    // get the current settings
    this.Requests.callAPI(
      this.Requests.getChatNotificationSettings,
      deviceId
    ).then((data) => {
      if (data && data.data && data.status && data.status === 200) {
        let lightName = data.data.lightColor;
        if (lightName === "FFFFFF") {
          lightName = "White";
        } else if (lightName === "000000") {
          lightName = "Black";
        } else if (lightName === "FFFF00") {
          lightName = "Yellow";
        }

        // Gets the version of the current channel ID (integer after channel ID)
        const channelIdArray = data.data.channelId.split("_");
        let channelVersion = parseInt(
          channelIdArray[channelIdArray.length - 1],
          10
        );

        // Defaults to 0 if there is no version
        if (isNaN(channelVersion)) {
          channelVersion = 0;
        }

        let updatedSettings = {
          sound: {
            name: this.fixSoundsFileName(data.data.sound),
          },
          light: {
            name: lightName,
            hex: data.data.lightColor,
            active: data.data.light,
          },
          vibration: data.data.vibration,
          channelVersion: channelVersion,
        };
        this.setState({
          loading: false,
          selected: updatedSettings,
          errorMessage: null,
        });
      } else if (data && data.status && data.status === 204) {
        // The user has no set perferences
        this.updateChatPreferences();
      } else {
        let ErrorMessage =
          copy.settings.notifications.getChatNotificationAPIErrorMessage +
          ` (Error #${copy.errorCodes.getChatNotificationAPIErrorMessage})`;
        // if (data && data.data && data.data.SASMessageClient) {
        //   ErrorMessage = data.data.SASMessageClient;
        // }
        this.setState({
          errorMessage: ErrorMessage,
        });
      }
    });
  };

  updateChatPreferences = () => {
    let version = this.state.selected.channelVersion;

    let oldChannel = "channel_chat_" + (version - 1);

    // This is for the plugin to create in the mobile app
    let channelToCreate = {
      // channel ID - must be unique per app package
      id: "channel_chat_" + version,

      // Channel description. Default: empty string
      description:
        "This is the chat notification preferences with " +
        (this.state.selected.light.active
          ? this.state.selected.light.name + " lights"
          : "no lights") +
        (this.state.selected.vibration ? ", vibration" : "no vibration ") +
        " and " +
        this.state.selected.sound.name +
        " as the sound.",

      // Channel name. Default: empty string
      name: "Chat Channel",

      //The sound to play once a push comes. Default value: 'default'
      //Values allowed:
      //'default' - plays the default notification sound
      //'ringtone' - plays the currently set ringtone
      //'false' - silent; don't play any sound
      //filename - the filename of the sound file located in '/res/raw' without file extension (mysound.mp3 -> mysound)
      sound: this.state.selected.sound.name.toLowerCase().replace(/ /g, "_"), // Sound name needs to have underscores

      //Vibrate on new notification. Default value: true
      //Possible values:
      //Boolean - vibrate or not
      //Array - vibration pattern - e.g. [0, 500, 200, 500] - delay, milliseconds vibrate, milliseconds pause, vibrate, pause, etc.
      vibration: this.state.selected.vibration
        ? [0, 750, 250, 750, 250, 750, 1000, 750, 250, 750, 250, 750]
        : false,

      // Whether to blink the LED
      light: this.state.selected.light.active,

      //LED color in ARGB format - this example BLUE color. If set to -1, light color will be default. Default value: -1.
      lightColor: this.state.selected.light.hex,

      //Importance - integer from 0 to 4. Default value: 4
      //0 - none - no sound, does not show in the shade
      //1 - min - no sound, only shows in the shade, below the fold
      //2 - low - no sound, shows in the shade, and potentially in the status bar
      //3 - default - shows everywhere, makes noise, but does not visually intrude
      //4 - high - shows everywhere, makes noise and peeks
      importance: 4,

      //Show badge over app icon when non handled pushes are present. Default value: true
      badge: true,

      //Show message on locked screen. Default value: 1
      //Possible values (default 1):
      //-1 - secret - Do not reveal any part of the notification on a secure lockscreen.
      //0 - private - Show the notification on all lockscreens, but conceal sensitive or private information on secure lockscreens.
      //1 - public - Show the notification in its entirety on all lockscreens.
      visibility: 1,
    };

    // This is to send through the api for the dB to store
    let channelBody = {
      channelId: channelToCreate.id,
      vibration: this.state.selected.vibration,
      sound: this.state.selected.sound.name.toLowerCase().replace(/ /g, "_"), // Sound name needs to have underscores,
      light: this.state.selected.light.active,
      lightColor: this.state.selected.light.hex,
      importance: 4,
      visibility: 1,
    };
    let deviceId = window.device.uuid;

    this.Requests.callAPI(
      this.Requests.setChatNotificationSettings,
      deviceId,
      channelBody
    ).then((data) => {
      if (data && data.status && data.status === 200) {
        // this.getChatPreferences();
        // Create the channel in the mobile app
        if (window.cordova) {
          window.FirebasePlugin.deleteChannel(
            oldChannel,
            function () {
              // Create the new channel
              window.FirebasePlugin.createChannel(
                channelToCreate,
                function () {
                  console.log("Channel created: " + channelToCreate.id);
                },
                function (error) {
                  console.log("Create channel error: " + error);
                }
              );
            },
            function (error) {
              console.log("Delete channel error: " + error);
            }
          );
        }
        // If the function was called when the user first logs in, cancel loading state
        if (this.state.loading) {
          this.setState({ loading: false });
        }
      } else {
        let ErrorMessage =
          copy.settings.notifications.setChatNotificationAPIErrorMessage +
          ` (Error #${copy.errorCodes.setChatNotificationAPIErrorMessage})`;
        // if (data && data.data && data.data.SASMessageClient) {
        //   ErrorMessage = data.data.SASMessageClient;
        // }
        this.setState({
          errorMessage: ErrorMessage,
        });
      }
    });
  };

  /**
   * componentWillUnmount for GroupSettings.
   * Stops audio from playing.
   */
  componentWillUnmount() {
    this.unmountAudio();
  }

  /**
   * This function stops any current playing sounds from playing
   */
  unmountAudio = () => {
    if (window.device) {
      // iOS and Android handle sounds differently
      if (window.device.platform === "iOS") {
        this.currentAudio.stop();
      } else {
        this.currentAudio.pause();
      }
    }
  };

  /**
   * Handles the sounds radio buttons
   * Plays the sound and then sets the sound as the current sound in the state
   * @param {string} item The name of the sound in lowercase with underscores
   * @param {bool} fromToggle Whether the function was called because the sound toggle was pressed
   */
  handleNotificationSoundRadio = (item, fromToggle) => {
    if (window.device) {
      // Stops audio if another sound is playing
      // iOS and Android handle sounds differently
      if (window.device.platform === "iOS") {
        this.currentAudio.stop();
      } else {
        this.currentAudio.pause();
      }
      if (item !== "Off") {
        // Handles playing sound differently for iOS and Android
        if (window.device.platform === "iOS") {
          this.currentAudio = new window.Media(
            // Path to where the tone is stored
            require("../../assets/tones/" + item + ".wav")
          );
        } else {
          this.currentAudio.setAttribute(
            "src",
            // Path to where the tone is stored
            require("../../assets/tones_mp3/" + item + ".mp3")
          );
        }
      }
    }

    // Will play the newly selected sound, only when radio buttons are pressed, not when toggle is pressed
    // Timeout handles how long the sound should play for
    if (!fromToggle) {
      clearTimeout(this.timeout);
      if (window.device) {
        this.currentAudio.play();
        // Timeout is set to 2 seconds
        this.timeout = setTimeout(this.unmountAudio, 2000);
      }
    }

    // Check if iOS and if the vibrations are off. If the vibrations are off, and sound is being turned on, turn vibrations on too.
    let vibrationToggle = this.state.selected.vibration;
    if (
      window.device &&
      window.device.platform === "iOS" &&
      this.state.selected.sound.name === "Off" &&
      vibrationToggle === false
    ) {
      vibrationToggle = true;
    }

    this.setState(
      {
        vibrationsLimitationMessage: false,
        selected: {
          ...this.state.selected,
          sound: {
            name: item,
          },
          vibration: vibrationToggle,
          channelVersion: this.state.selected.channelVersion + 1,
        },
      },
      // This calls the API and sets the updated tone in the chat channel
      () => this.updateChatPreferences()
    );
  };

  /**
   * This handles toggling sounds and lights on and off via the toggle
   * @param {string} label Either "Lights" or "Sounds": the setting that is being toggled
   */
  handleNotificationToggle = (label) => {
    // Changes settings just for sounds
    if (label === "Sounds") {
      // Resets sounds to tone 1 if sounds are turned off
      if (this.state.selected.sound.name === "Off") {
        this.handleNotificationSoundRadio("SAS Chat Tone 1", true);
      } else {
        this.handleNotificationSoundRadio("Off", true);
      }
      // Changes settings just for lights
    } else if (label === "Lights") {
      // Resets lights to red if lights are turned off
      if (this.state.selected.light.active) {
        // Currently active, turn it off
        this.handleNotificationLightRadio("Off");
      } else {
        // Current inactive, turn it back on
        this.handleNotificationLightRadio("White");
      }
    }
  };

  /**
   * Handles the lights radio buttons
   * Sets the selected colour as the current light in the state and looks up its hex code (ANDROID ONLY)
   * @param {string} item The name of the light colour
   */
  handleNotificationLightRadio = (item) => {
    // Assigns hex colours.
    let hex;
    let active;
    switch (item) {
      case "White":
        hex = "FFFFFF";
        active = true;
        break;
      case "Black":
        hex = "000000";
        active = true;
        break;
      case "Yellow":
        hex = "FFFF00";
        active = true;
        break;
      case "Off":
        hex = "FFFFFF";
        active = false;
        break;
      default:
        hex = "FFFFFF";
        active = true;
        break;
    }

    this.setState(
      {
        selected: {
          ...this.state.selected,
          light: { name: item, hex: hex, active: active },
        },
        channelVersion: this.state.selected.channelVersion + 1,
      },
      // This calls the API and sets the notification channel
      () => this.updateChatPreferences()
    );
  };

  /**
   * Handles the vibration toggle
   * Toggles the selected state of vibrations in the state
   */
  handleNotificationVibrationToggle = () => {
    // Check if the device is an iOS device, if it is, the sounds needs to be ON, inorder to turn the toggle off.
    if (
      window.device &&
      window.device.platform === "iOS" &&
      this.state.selected.sound.name !== "Off" &&
      this.state.selected.vibration === true
    ) {
      this.setState({
        vibrationsLimitationMessage: true,
      });
    } else {
      this.setState(
        {
          vibrationsLimitationMessage: false,
          selected: {
            ...this.state.selected,
            vibration: !this.state.selected.vibration,
          },
          channelVersion: this.state.selected.channelVersion + 1,
        },
        // This calls the API and sets the notification channel
        () => {
          this.updateChatPreferences();
        }
      );
    }
  };

  render() {
    // Creates list of available sounds based on array in the state
    this.soundList = this.state.sounds.map((item, index) => (
      <li key={index}>
        <Radio
          name={"sound"}
          onClick={() => {
            this.handleNotificationSoundRadio(item);
          }}
          label={item}
          checked={
            // Defaults to selecting the first sound if there are currently no sounds selected
            (item === this.state.selected.sound.name && "true") ||
            (this.state.selected.sound.name === "Off" && index === 0)
          }
        />
      </li>
    ));

    // Creates list of available light colours based on array in the state
    this.lightList = this.state.lights.map((item, index) => (
      <li key={index}>
        <Radio
          name={"light"}
          onClick={() => {
            this.handleNotificationLightRadio(item);
          }}
          label={item}
          checked={
            // Defaults to selecting first light if there are currently no lights selected
            (item === this.state.selected.light.name && "true") ||
            (this.state.selected.light.name === "Off" && index === 0)
          }
        />
      </li>
    ));
    return this.state.loading ? (
      // Fetching the details from the API
      <div className="box_default">
        {/* if (!this.state.showEditScreen) { */}
        <div className="Sub-Section-titles">
          <h3>{copy.settings.notifications.chatNotificationsTitle}</h3>
        </div>
        {this.state.errorMessage ? (
          <div className={styles.API_error_text}>{this.state.errorMessage}</div>
        ) : (
          <div
            className={
              styles.loadingContainer + " " + styles.loadingContainerMobile
            }>
            <Loader />
          </div>
        )}
      </div>
    ) : (
      <div className="box_default">
        {/* if (!this.state.showEditScreen) { */}
        <div className="Sub-Section-titles">
          <h3>{copy.settings.notifications.chatNotificationsTitle}</h3>
        </div>
        <p>{copy.settings.notifications.chatNotificationsDesc}</p>
        <div className={styles.alertType}>
          <div className={styles.heading}>
            {/* Chat settings */}
            {this.state.showEditScreen ? (
              <div className="Sub-Section-titles fullWidth">
                <h3>{copy.settings.notifications.chatSettingsSection}</h3>
                <Button
                  label="Back"
                  content={copy.global.btnBack}
                  variant="btn_outline"
                  styles="greenTxt"
                  buttonClick={() => {
                    this.setState(
                      {
                        showEditScreen: !this.state.showEditScreen,
                      },
                      () => {
                        this.props.toggleChatNotificationsEditState(
                          this.state.showEditScreen
                        );
                      }
                    );
                  }}
                  className="openEmergencySettings"
                />
              </div>
            ) : (
              <Wrapper>
                <span className="txt4">
                  {copy.settings.notifications.chatSubTitle}
                </span>
                <Button
                  label="Edit"
                  content={copy.global.btnEdit}
                  variant="btn_outline"
                  styles="greenTxt"
                  buttonClick={() => {
                    this.setState(
                      {
                        showEditScreen: !this.state.showEditScreen,
                      },
                      () => {
                        this.props.toggleChatNotificationsEditState(
                          this.state.showEditScreen
                        );
                      }
                    );
                  }}
                  className="openEmergencySettings"
                />
              </Wrapper>
            )}
          </div>
          {this.state.showEditScreen ? (
            //  The user is editing the content
            <Wrapper>
              <div className="toggle_group">
                {window.device && (
                  <div className={styles.borderBottom}>
                    <div className={"toggle_heading"}>
                      <span>{copy.settings.notifications.vibrations}</span>
                      <Toggle
                        key="2"
                        checked={this.state.selected.vibration}
                        fromAdmin
                        variant="toggle"
                        label="turn on vibrate"
                        buttonClick={this.handleNotificationVibrationToggle}
                      />
                    </div>
                    {window.device &&
                      window.device.platform === "iOS" &&
                      this.state.vibrationsLimitationMessage && (
                        <div>
                          <p>
                            {
                              copy.settings.notifications
                                .vibrationsLimitationMessage
                            }
                          </p>
                        </div>
                      )}
                  </div>
                )}
                <Accordion
                  toggle={true}
                  allowMultipleOpen={true}
                  buttonClick={this.handleNotificationToggle}>
                  <div
                    key="112"
                    label={"Sounds"}
                    subtxt={this.state.selected.sound.name}
                    isOpen={
                      this.state.selected.sound.name &&
                      this.state.selected.sound.name !== "Off"
                    }>
                    <ul>{this.soundList}</ul>
                  </div>
                  {/* Only show lights on android */}
                  {window.device && window.device.platform === "Android" && (
                    <div
                      key="113"
                      label={"Lights"}
                      subtxt={this.state.selected.light.name}
                      isOpen={
                        this.state.selected.light.active &&
                        this.state.selected.light.name !== "Off"
                      }>
                      <ul>{this.lightList}</ul>
                    </div>
                  )}
                </Accordion>
              </div>
            </Wrapper>
          ) : (
            // The use is viewing the content
            <Wrapper>
              <div className={styles.content}>
                {window.device && (
                  <div className={styles.section}>
                    <span className={styles.title}>
                      {copy.settings.notifications.vibrations}
                    </span>
                    <p>
                      {this.state.selected.vibration ? "Enabled" : "Disabled"}
                    </p>
                  </div>
                )}
                <div className={styles.section}>
                  <span className={styles.title}>
                    {copy.settings.notifications.sounds}
                  </span>
                  <p>{this.state.selected.sound.name}</p>
                </div>
                {/* Only show lights on android */}
                {window.device && window.device.platform === "Android" && (
                  <div className={styles.section}>
                    <span className={styles.title}>
                      {copy.settings.notifications.lights}
                    </span>
                    {this.state.selected.light.active ? (
                      <p>{this.state.selected.light.name}</p>
                    ) : (
                      <p>Disabled</p>
                    )}
                  </div>
                )}
              </div>
            </Wrapper>
          )}
        </div>
      </div>
    );
  }
}
