import React from "react";
import Toggle from "../../../components/UI/Button/Toggle";
import ErrorBanner from "../../../hoc/ErrorBanner/ErrorBanner";
import Wrapper from "../../../hoc/Wrapper/Wrapper";
import { Requests } from "../../../api/IdentityServerRequests/Requests";
import styles from "./TurnoutSettings.module.scss";
import copy from "../../../assets/copy/copy.json";
import { Dropdown } from "semantic-ui-react";
import { TimeInput } from "@idui/react-date-components";
import Button from "../../../components/UI/Button/Button";
import Loader from "../../../components/UI/Loading/Loading";
import sounds from "./Sounds";

export default class TurnoutSettingsView extends React.Component {
  constructor(props) {
    super(props);
    this.Requests = new Requests();
    this.state = {
      layout: {
        default: "FitToScreen",
        showAttendance: true,
        showQualification: true,
        showNoResponse: true,
        showNotAttending: true,
        showOther: true,
        route: true,
      },
      // priority: false,
      tts: {
        toggleOn: true,
        toggleMuteSpecific: false,
        toggleMuteBetween: false,
        speechRate: 0.6,
        volume: 0.5,
        muteSpecific: 0,
        muteSpecificUntil: null,
        muteFrom: null,
        muteTo: null,
        newPhonetics: [],
        speechVoice: null,
        repeat: 0,
        gap: 60000,
        emergencyAlertSound: 0,
        nonEmergencyAlertSound: 0,
        adminAlertSound: 0,
      },
      timerReadout: true,
      duplicateWordError: false,
      invalidSpecificTime: false,
      invalidMuteFrom: false,
      invalidMuteTo: false,
      invalid: true,
      errorMessage: false,
      getSettingsError: false,
      loading: true,
    };

    this.muteSpecificOptions = [
      { key: 0, text: "Please select", value: 0 },
      { key: 1, text: "Indefinitely", value: -1 },
      { key: 2, text: "1 hour", value: 1 },
      { key: 3, text: "4 hours", value: 4 },
      { key: 4, text: "8 hours", value: 8 },
      { key: 5, text: "12 hours", value: 12 },
      { key: 6, text: "24 hours", value: 24 },
    ];

    this.repeats = [
      { key: 0, text: "0", value: 0 },
      { key: 1, text: "1", value: 1 },
      { key: 2, text: "2", value: 2 },
      { key: 3, text: "3", value: 3 },
      { key: 4, text: "4", value: 4 },
      { key: 5, text: "5", value: 5 },
      { key: 6, text: "6", value: 6 },
      { key: 7, text: "7", value: 7 },
      { key: 8, text: "8", value: 8 },
      { key: 9, text: "9", value: 9 },
      { key: 10, text: "10", value: 10 },
    ];

    this.gaps = [
      { key: 5, text: "30 seconds", value: 30000 },
      { key: 0, text: "1 minute", value: 60000 },
      { key: 1, text: "2 minutes", value: 120000 },
      { key: 2, text: "3 minutes", value: 180000 },
      { key: 3, text: "5 minutes", value: 300000 },
      { key: 4, text: "10 minutes", value: 600000 },
    ];

    this.initialSettings = null;
    this.speechUtterance = new SpeechSynthesisUtterance();
    this.audioRef = React.createRef();
    this.Requests = new Requests();
  }

  componentDidMount() {
    setTimeout(() => this.getVoice(), 100);
    this.getSettings();
  }

  componentWillUnmount() {
    window.speechSynthesis.cancel();
  }

  /**
   * Get initial settings state from the API
   */
  getSettings = () => {
    this.setState({ loading: true }, () => {
      this.Requests.callAPI(this.Requests.getUserTurnoutSettings).then(
        (data) => {
          if (!data || data.status !== 200) {
            let ErrorMessage =
              copy.turnout.turnoutSettingsAPIErrorMessage +
              ` (Error #${copy.errorCodes.getTurnoutSettingsErrorMessage})`;
            this.setState(
              {
                loading: false,
                errorMessage: ErrorMessage,
                getSettingsError: true,
              },
              () => {
                setTimeout(
                  function () {
                    this.setState({
                      errorMessage: null,
                      getSettingsError: null,
                    });
                  }.bind(this),
                  5000
                );
              }
            );
          } else {
            if (data && data.status && data.status === 200 && data.data) {
              this.initialSettings = data.data;
              this.setState(
                {
                  layout: {
                    ...this.state.layout,
                    default: data.data.defaultLayout,
                    showAttendance: data.data.showAttendance,
                    showQualification: data.data.showQualification,
                    showNoResponse: data.data.showNoResponse,
                    showNotAttending: data.data.showNotAttending,
                    showOther: data.data.showOther,
                    route: data.data.routing,
                  },
                  tts: {
                    ...this.state.tts,
                    toggleOn: data.data.textToSpeech.toggleOn,
                    toggleMuteSpecific: data.data.textToSpeech.muteSpecific
                      ? true
                      : false,
                    toggleMuteBetween: data.data.textToSpeech.toggleMuteBetween,
                    speechRate: data.data.textToSpeech.speechRate,
                    volume: data.data.textToSpeech.volume,
                    muteSpecific: 0,
                    muteSpecificUntil: data.data.textToSpeech.muteSpecific,
                    muteFrom: data.data.textToSpeech.muteFrom,
                    muteTo: data.data.textToSpeech.muteTo,
                    newPhonetics: data.data.textToSpeech.phonetics,
                    gap: data.data.textToSpeech.gap,
                    repeat: data.data.textToSpeech.repeat,
                    timerReadout: data.data.textToSpeech.timerReadout,
                    alertTones: data.data.textToSpeech.toggleAlertTone,
                    adminAlertSound: data.data.textToSpeech.adminAlertSound,
                    emergencyAlertSound:
                      data.data.textToSpeech.emergencyAlertSound,
                    nonEmergencyAlertSound:
                      data.data.textToSpeech.nonEmergencyAlertSound,
                  },
                  // priority: data.data.textToSpeech.togglePriority,
                  loading: false,
                },
                () => {
                  // initialise styling for input range
                  this.setRangeStyle(this.state.tts.speechRate, "rate");
                  this.setRangeStyle(this.state.tts.volume, "vol");
                  this.convertToInt(
                    this.state.tts.adminAlertSound,
                    this.state.tts.emergencyAlertSound,
                    this.state.tts.nonEmergencyAlertSound
                  );
                }
              );
            }
          }
        }
      );
    });
  };

  /**
   * Revert to the initial setting state
   */
  onCancel = () => {
    this.getSettings();
    this.setState({
      duplicateWordError: false,
      invalidSpecificTime: false,
      invalidMuteFrom: false,
      invalidMuteTo: false,
    });
  };

  /**
   * Update settings data
   */
  onSave = () => {
    // filter out empty phonetics object
    const phonetics = this.state.tts.newPhonetics?.filter(
      (x) => x.wordToReplace !== "" && x.replaceWith !== ""
    );

    const payload = {
      routing: this.state.layout.route,
      defaultLayout: this.state.layout.default,
      showAttendance: this.state.layout.showAttendance,
      showQualification: this.state.layout.showQualification,
      showNoResponse: this.state.layout.showNoResponse,
      showNotAttending: this.state.layout.showNotAttending,
      showOther: this.state.layout.showOther,
      textToSpeech: {
        gap: this.state.tts.gap,
        repeat: this.state.tts.repeat,
        toggleOn: this.state.tts.toggleOn,
        toggleMuteSpecific: this.state.tts.toggleMuteSpecific,
        muteSpecific: this.state.tts.muteSpecific,
        toggleMuteBetween: this.state.tts.toggleMuteBetween,
        muteFrom: this.state.tts.toggleMuteBetween
          ? this.state.tts.muteFrom
          : null,
        muteTo: this.state.tts.toggleMuteBetween ? this.state.tts.muteTo : null,
        speechRate: this.state.tts.speechRate,
        volume: this.state.tts.volume,
        timerReadout: this.state.tts.timerReadout,
        toggleAlertTone: this.state.tts.alertTones,
        adminAlertSound: this.state.tts.adminAlertSound,
        emergencyAlertSound: this.state.tts.emergencyAlertSound,
        nonEmergencyAlertSound: this.state.tts.nonEmergencyAlertSound,
        // togglePriority: this.state.priority,
        phonetics: phonetics ? phonetics : [],
      },
    };

    this.setState({ loading: true }, () => {
      this.Requests.callAPI(this.Requests.updateTurnoutSettings, payload).then(
        (data) => {
          if (data.status && data.status === 200) {
            this.getSettings();
          } else {
            let ErrorMessage =
              copy.turnout.updateTurnoutSettingsAPIErrorMessage +
              ` (Error #${copy.errorCodes.updateTurnoutSettingsErrorMessage})`;
            this.setState(
              { loading: false, errorMessage: ErrorMessage },
              () => {
                setTimeout(
                  function () {
                    this.setState({ errorMessage: null });
                  }.bind(this),
                  5000
                );
              }
            );
          }
        }
      );
    });
  };

  /**
   * Show or hide routing
   */
  toggleRouting = () => {
    this.setState((prevState) => {
      return {
        layout: {
          ...this.state.layout,
          route: !prevState.layout.route,
        },
      };
    });
  };

  /**
   * Show or hide routing
   */
  // togglePriority = () => {
  //   this.setState((prevState) => {
  //     return {
  //       priority: !prevState.priority,
  //     };
  //   });
  // };

  /**
   * handle various type of toggle in Attendance List.
   * @param {String} toggle type of toggle (qualification/noResponse/notattending/other)
   */
  toggleAttendance = (toggle) => {
    switch (toggle) {
      case "qualification":
        this.setState((prevState) => {
          return {
            layout: {
              ...this.state.layout,
              showQualification: !prevState.layout.showQualification,
            },
          };
        });
        break;
      case "noResponse":
        this.setState((prevState) => {
          return {
            layout: {
              ...this.state.layout,
              showNoResponse: !prevState.layout.showNoResponse,
            },
          };
        });
        break;
      case "notAttending":
        this.setState((prevState) => {
          return {
            layout: {
              ...this.state.layout,
              showNotAttending: !prevState.layout.showNotAttending,
            },
          };
        });
        break;
      case "other":
        this.setState((prevState) => {
          return {
            layout: {
              ...this.state.layout,
              showOther: !prevState.layout.showOther,
            },
          };
        });
        break;
      default:
        this.setState((prevState) => {
          return {
            layout: {
              ...this.state.layout,
              showAttendance: !prevState.layout.showAttendance,
            },
          };
        });
    }
  };

  /**
   * Update a word in the phonetics array.
   * @param {String} view new value from the input
   */
  changeDefaultView = (view) => {
    if (view === "FitToScreen") {
      this.setState({
        layout: {
          ...this.state.layout,
          default: "FitToScreen",
        },
      });
    } else {
      this.setState({
        layout: {
          ...this.state.layout,
          default: "Expanded",
        },
      });
    }
  };

  /**
   * Read the sample text
   */
  speak(word) {
    if (this.state.speechVoice) {
      const synth = window.speechSynthesis;
      let voice = new SpeechSynthesisUtterance();

      if (synth.speaking) {
        synth.cancel();
      }

      // apply tts settings
      voice.text = word;
      voice.rate = parseFloat(this.state.tts.speechRate);
      voice.volume = parseFloat(this.state.tts.volume);
      voice.voice = this.state.speechVoice;

      // queue this utterance
      synth.speak(voice);
    }
  }

  /**
   * Called to get the speech synthesis specific AU Voice
   */
  getFromSpeechSynthesis = () =>
    new Promise((resolve, reject) => {
      const voices = window.speechSynthesis.getVoices();
      if (voices) {
        let auVoice = voices.filter((x) => x.lang === "en-AU")[0];
        resolve(
          auVoice ? auVoice : voices.filter((x) => x.lang === "en-US")[0]
        );
      } else {
        reject(Error("Error: voices failed to load"));
      }
    });

  /**
   * set the state of the speech voice to be ready to use
   */
  async getVoice() {
    let voice = await this.getFromSpeechSynthesis().catch((error) =>
      console.log(error)
    );
    if (!voice || voice.length === 0) {
      voice = await this.getFromSpeechSynthesis().catch((error) =>
        console.log(error)
      );
    }
    this.setState({ speechVoice: voice });
  }

  /**
   * handle various type of toggle in text to speech.
   * @param {String} toggle type of toggle ("on/muteSpecific/muteBetween")
   */
  toggleTextToSpeech = (toggle) => {
    switch (toggle) {
      case "muteSpecific":
        if (!this.state.tts.toggleMuteSpecific) {
          if (this.state.tts.muteSpecific !== 0) {
            this.setState((prevState) => {
              return {
                tts: {
                  ...this.state.tts,
                  toggleMuteSpecific: true,
                  muteSpecificUntil: null,
                },
              };
            });
          } else {
            // validation and error handling
            this.setState(
              {
                tts: {
                  ...this.state.tts,
                  toggleMuteSpecific: false,
                },
                invalidSpecificTime: true,
              },
              () => {
                setTimeout(
                  function () {
                    this.setState({ invalidSpecificTime: false });
                  }.bind(this),
                  5000
                );
              }
            );
          }
        } else {
          this.setState({
            tts: {
              ...this.state.tts,
              toggleMuteSpecific: false,
              muteSpecificUntil: null,
            },
          });
        }
        break;
      case "muteBetween":
        if (
          this.state.tts.muteFrom !== null &&
          this.state.tts.muteTo !== null
        ) {
          this.setState((prevState) => {
            return {
              tts: {
                ...this.state.tts,
                toggleMuteBetween: !prevState.tts.toggleMuteBetween,
              },
            };
          });
        } else {
          // validation and error handling
          this.setState(
            {
              tts: {
                ...this.state.tts,
                toggleMuteBetween: false,
              },
              invalidMuteFrom: this.state.tts.muteFrom === null ? true : false,
              invalidMuteTo: this.state.tts.muteTo === null ? true : false,
            },
            () => {
              setTimeout(
                function () {
                  this.setState({
                    invalidMuteFrom: false,
                    invalidMuteTo: false,
                  });
                }.bind(this),
                5000
              );
            }
          );
        }
        break;
      default:
        this.setState((prevState) => {
          return {
            tts: {
              ...this.state.tts,
              toggleOn: !prevState.tts.toggleOn,
            },
          };
        });
    }
  };
  toggleTimerReadout = () => {
    this.setState((prevState) => {
      return {
        tts: {
          ...this.state.tts,
          timerReadout: !prevState.tts.timerReadout,
        },
      };
    });
  };

  /**
   * input handler for mute specific time.
   * @param {number} value new value from the input
   */
  selectMuteSpecific = (value) => {
    this.setState({
      tts: {
        ...this.state.tts,
        muteSpecific: value,
      },
    });
  };

  /**
   * work around to have consistent input range styling across different browser.
   * @param {String} value new value from the input range
   * @param {String} type range handler type (vol/speechRate)
   */
  setRangeStyle = (value, type) => {
    const rangeType = type === "vol" ? "volume" : "speechRate";
    const inputElement = document.getElementsByName(rangeType)[0];
    const percent =
      ((value - inputElement.min) / (inputElement.max - inputElement.min)) *
      100;
    inputElement.style.setProperty("--webkitProgressPercent", `${percent}%`);
  };

  // convert tone type from string to integer
  convertToInt = (
    adminAlertSound,
    emergencyAlertSound,
    nonEmergencyAlertSound
  ) => {
    adminAlertSound = +adminAlertSound;
    emergencyAlertSound = +emergencyAlertSound;
    nonEmergencyAlertSound = +nonEmergencyAlertSound;
    this.setState({
      tts: {
        ...this.state.tts,
        adminAlertSound,
        emergencyAlertSound,
        nonEmergencyAlertSound,
      },
    });
  };

  // Function to play the selected tone for a specified duration
  playSelectedTone = (audioFile) => {
    this.audioRef.current.src = audioFile;
    this.audioRef.current.play();
    // Pause the audio after 1 second
    setTimeout(() => {
      this.audioRef.current.pause();
    }, 1000);
  };

  /**
   * input handler for range and volume.
   * @param {String} value new value from the input
   * @param {String} type range handler type (vol/speechRate)
   */
  selectRange = (value, type) => {
    if (type === "vol") {
      this.setState({
        tts: {
          ...this.state.tts,
          volume: value,
        },
      });
    } else {
      this.setState({
        tts: {
          ...this.state.tts,
          speechRate: value,
        },
      });
    }
    this.setRangeStyle(value, type);
  };

  /**
   * Update a word in the phonetics array.
   * @param {String} value new value from the input
   * @param {number} index position of the word in the array
   * @param {String} type specifies the type of word it is updating to ("toreplace"/"replacewith")
   */
  onChangeWord = (value, index, type) => {
    const existingPhoneticsList = JSON.parse(
      JSON.stringify(
        this.initialSettings?.textToSpeech?.phonetics.sort((a, b) => {
          return a.wordToReplace - b.wordToReplace;
        })
      )
    );
    let phoneticsList = JSON.parse(
      JSON.stringify(
        this.state.tts.newPhonetics.sort((a, b) => {
          return a.wordToReplace - b.wordToReplace;
        })
      )
    );

    type === "toreplace"
      ? (phoneticsList[index].wordToReplace = value)
      : (phoneticsList[index].replaceWith = value);

    this.setState(
      {
        tts: {
          ...this.state.tts,
          newPhonetics: phoneticsList,
        },
      },
      () => {
        // check for word duplicates
        if (this.state.tts.newPhonetics.length > 1) {
          const duplicateWord =
            existingPhoneticsList.filter(
              (x) => x.wordToReplace.toLowerCase() === value.toLowerCase()
            ).length > 0;
          this.setState({ duplicateWordError: duplicateWord });
        }
      }
    );
  };

  /**
   * Add new line to the phonetics array.
   */
  addNewLine = () => {
    this.setState((prevState) => {
      return {
        tts: {
          ...this.state.tts,
          newPhonetics: [
            ...prevState.tts.newPhonetics,
            { wordToReplace: "", replaceWith: "" },
          ],
        },
      };
    });
  };

  /**
   * remove phonetics line from array.
   * @param {number} index position of object in the array
   */
  removeLine = (index) => {
    let phoneticsList = [...this.state.tts.newPhonetics];
    phoneticsList.splice(index, 1);

    // check if phonetics still invalid
    const word = this.state.tts.newPhonetics[index].wordToReplace;
    const duplicateWord =
      phoneticsList.filter(
        (x) => x.wordToReplace.toLowerCase() === word.toLowerCase()
      ).length > 1;

    this.setState({
      tts: {
        ...this.state.tts,
        newPhonetics: phoneticsList,
      },
      duplicateWordError: duplicateWord ? true : false,
    });
  };

  /**
   * Updates the to / from time.
   * @param {String} time Date object containing the selected time
   * @param {String} type specifies what time is updated ("to" / "from")
   */
  updateTime(time, type) {
    if (type === "from") {
      this.setState({
        tts: {
          ...this.state.tts,
          muteFrom: time,
        },
      });
    } else if (type === "to") {
      this.setState({
        tts: {
          ...this.state.tts,
          muteTo: time,
        },
      });
    }
  }

  // Removes timestamp to return a date in yyyy/mm/dd
  fixFormat = (timestamp) => {
    if (timestamp) {
      return timestamp
        .replace(/-/g, "/")
        .replace("T", " ")
        .replace(/\..*|\+.*/, "");
    } else {
      return "";
    }
  };

  getYear = (timestamp) => {
    if (timestamp) {
      let yyyymmdd = this.fixFormat(timestamp).substr(0, 10); // Removes the time;
      var datePart = yyyymmdd.match(/\d+/g);
      return datePart[0];
    } else {
      return "";
    }
  };

  getTime = (timestamp) => {
    if (timestamp) {
      let fixedFormat = this.fixFormat(timestamp);
      let time = fixedFormat.substr(10, 6); // Extracts the time in hh:mm to add back later after changing the format
      return time;
    } else {
      return "";
    }
  };

  getDate = (timestamp) => {
    if (timestamp) {
      let yyyymmdd = this.fixFormat(timestamp).substr(0, 10); // Removes the time;
      var datePart = yyyymmdd.match(/\d+/g),
        year = datePart[0],
        month = datePart[1],
        day = datePart[2];
      // returns dd/mm/yyyy
      return day + "/" + month + "/" + year;
    } else {
      return "";
    }
  };

  renderLayoutSettings() {
    return (
      <div className={styles.row + " " + styles.multi}>
        <div className={styles.title}>{copy.turnout.settings.layout.title}</div>
        <div className={styles.body}>
          <div className={styles.subRow}>
            <div className={styles.subTitle}>
              {copy.turnout.settings.layout.default}
            </div>
            <div className={styles.subBody}>
              <label aria-label="Default" className={styles.radio}>
                <input
                  type="radio"
                  name="fit to screen view"
                  checked={this.state.layout.default === "FitToScreen"}
                  onChange={() => this.changeDefaultView("FitToScreen")}
                />
                <span className={styles.checkmark}></span>
                {copy.turnout.settings.layout.fitToScreen}
              </label>
              <label aria-label="Default" className={styles.radio}>
                <input
                  type="radio"
                  name="expanded view"
                  checked={this.state.layout.default === "Expanded"}
                  onChange={() => this.changeDefaultView("Expanded")}
                />
                <span className={styles.checkmark}></span>
                {copy.turnout.settings.layout.expanded}
              </label>
            </div>
          </div>
          <div className={styles.subRow}>
            <div className={styles.subTitle}>
              {copy.turnout.settings.layout.route}
            </div>
            <div className={styles.subBody}>
              <div className={styles.input}>
                <span className={styles.inputName}>
                  {this.state.layout.route
                    ? copy.turnout.settings.on
                    : copy.turnout.settings.off}
                </span>
                <Toggle
                  key="10"
                  variant="toggle"
                  checked={this.state.layout.route}
                  buttonClick={this.toggleRouting}
                  label="Turn on or off routing by default"
                  fromTurnout
                />
              </div>
            </div>
          </div>
          {/* Attendance List  */}
          <div className={styles.subRow + " " + styles.noUnderline}>
            <div className={styles.subTitle}>
              {copy.turnout.settings.layout.attendanceList}
            </div>
            <div className={styles.subBody}>
              <div className={styles.input}>
                <span className={styles.inputName}>
                  {this.state.layout.showAttendance
                    ? copy.turnout.settings.show
                    : copy.turnout.settings.hide}{" "}
                  {copy.turnout.settings.layout.attendanceList}
                </span>
                <Toggle
                  key="1"
                  variant="toggle"
                  checked={this.state.layout.showAttendance}
                  buttonClick={this.toggleAttendance}
                  label="show or hide Attendance List"
                  fromTurnout
                />
              </div>
              {/* Show attendance options if not hidden */}
              {this.state.layout.showAttendance && (
                <div className={styles.attendanceOptions}>
                  <div className={styles.input}>
                    <span className={styles.inputName}>
                      {this.state.layout.showQualification
                        ? copy.turnout.settings.show
                        : copy.turnout.settings.hide}{" "}
                      {copy.turnout.settings.layout.qualification}
                    </span>
                    <Toggle
                      key="2"
                      variant="toggle"
                      checked={this.state.layout.showQualification}
                      buttonClick={() => this.toggleAttendance("qualification")}
                      label="Show or Hide Qualification"
                      fromTurnout
                    />
                  </div>
                  {/* No Response */}
                  <div className={styles.input}>
                    <span className={styles.inputName}>
                      {this.state.layout.showNoResponse
                        ? copy.turnout.settings.show
                        : copy.turnout.settings.hide}{" "}
                      {copy.turnout.settings.layout.noResponse}
                    </span>
                    <Toggle
                      key="3"
                      variant="toggle"
                      checked={this.state.layout.showNoResponse}
                      buttonClick={() => this.toggleAttendance("noResponse")}
                      label="Show or Hide No Response"
                      fromTurnout
                    />
                  </div>
                  {/* Not Attending */}
                  <div className={styles.input}>
                    <span className={styles.inputName}>
                      {this.state.layout.showNotAttending
                        ? copy.turnout.settings.show
                        : copy.turnout.settings.hide}{" "}
                      {copy.turnout.settings.layout.notAttending}
                    </span>
                    <Toggle
                      key="5"
                      variant="toggle"
                      checked={this.state.layout.showNotAttending}
                      buttonClick={() => this.toggleAttendance("notAttending")}
                      label="Show or Hide Not Attending"
                      fromTurnout
                    />
                  </div>
                  {/* Other */}
                  <div className={styles.input}>
                    <span className={styles.inputName}>
                      {this.state.layout.showOther
                        ? copy.turnout.settings.show
                        : copy.turnout.settings.hide}{" "}
                      {copy.turnout.settings.layout.other}
                    </span>
                    <Toggle
                      key="5"
                      variant="toggle"
                      checked={this.state.layout.showOther}
                      buttonClick={() => this.toggleAttendance("other")}
                      label="Show or Hide Other"
                      fromTurnout
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  // renderMessageSettings() {
  //   return (
  //     <div className={styles.row + " " + styles.multi}>
  //       <div className={styles.title}>{copy.turnout.settings.msg.title}</div>
  //       <div className={styles.body}>
  //         <div className={styles.subRow + " " + styles.noUnderline}>
  //           <div className={styles.subTitle}>
  //             {copy.turnout.settings.msg.sub}
  //           </div>
  //           <div className={styles.subBody}>
  //             <div className={styles.input}>
  //               <span className={styles.inputName}>
  //                 {this.state.priority
  //                   ? copy.turnout.settings.on
  //                   : copy.turnout.settings.off}
  //               </span>
  //               <Toggle
  //                 key="10"
  //                 variant="toggle"
  //                 checked={this.state.priority}
  //                 buttonClick={this.togglePriority}
  //                 label="Turn on or off message priority"
  //                 fromTurnout
  //               />
  //             </div>
  //             <p>{this.state.priority ? "Event Emergency Message is prioritised" : "Event messages are ordered as they arrived with most recent on top"}</p>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // }

  renderARsSettings() {
    let dateIsIndefinite =
      this.state.tts.muteSpecificUntil &&
      this.getYear(this.state.tts.muteSpecificUntil) >= 2120;

    return (
      <div className={styles.row + " " + styles.multi}>
        <div className={styles.title}>
          {copy.turnout.settings.options.title}
        </div>
        <div className={styles.body}>
          <div className={styles.subRow}>
            <div className={styles.subTitle}>
              {copy.turnout.settings.options.pagerEventReadout}
            </div>
            <div className={styles.subBody}>
              {/* on/off */}
              <div className={styles.input}>
                <span className={styles.inputName}>
                  {this.state.tts.toggleOn
                    ? copy.turnout.settings.on
                    : copy.turnout.settings.off}
                </span>
                <Toggle
                  key="11"
                  variant="toggle"
                  checked={this.state.tts.toggleOn}
                  buttonClick={this.toggleTextToSpeech}
                  label="Turn on or off text to speech"
                />
              </div>
            </div>
          </div>
          <div className={styles.subRow}>
            <div className={styles.subTitle}>
              {copy.turnout.settings.options.timerReadout}
            </div>
            <div className={styles.subBody}>
              {/* on/off */}
              <div className={styles.input}>
                <span className={styles.inputName}>
                  {this.state.tts.timerReadout
                    ? copy.turnout.settings.on
                    : copy.turnout.settings.off}
                </span>
                <Toggle
                  key="12"
                  variant="toggle"
                  checked={this.state.tts.timerReadout}
                  buttonClick={this.toggleTimerReadout}
                  label="Turn on or off timer readout"
                />
              </div>
            </div>
          </div>
          <div className={styles.subRow}>
            <div className={styles.subTitle}>
              {copy.turnout.settings.options.alertTones}
            </div>
            <div className={styles.subBody}>
              {/* on/off */}
              <div className={styles.input}>
                <span className={styles.inputName}>
                  {this.state.tts.alertTones
                    ? copy.turnout.settings.on
                    : copy.turnout.settings.off}
                </span>
                <Toggle
                  key="13"
                  variant="toggle"
                  checked={this.state.tts.alertTones}
                  buttonClick={this.toggleAlertTones}
                  label="Turn on or off alert tones"
                />
              </div>
            </div>
          </div>

          <div className={styles.subRow}>
            <div className={styles.subTitle}>
              {copy.turnout.settings.muteSpecific}
            </div>
            <div className={styles.subBody}>
              {/* mute for a specific time */}
              <Dropdown
                className={styles.frequencyButton}
                placeholder="Select"
                selection
                value={this.state.tts.muteSpecific}
                options={this.muteSpecificOptions}
                onChange={(e, { value }) => this.selectMuteSpecific(value)}
                disabled={this.state.tts.muteSpecificUntil ? true : false}
              />
              {/* on/off */}
              <div className={styles.input}>
                <span className={styles.inputName}>
                  {this.state.tts.toggleMuteSpecific
                    ? copy.turnout.settings.on
                    : copy.turnout.settings.off}
                </span>
                <Toggle
                  key="8"
                  checked={this.state.tts.toggleMuteSpecific}
                  fromTurnout
                  buttonClick={() => this.toggleTextToSpeech("muteSpecific")}
                  label="mute for a specific time"
                />
              </div>
              <div className={styles.errorWrapper}>
                {this.state.invalidSpecificTime && (
                  <p className={styles.errorMsg}>
                    {copy.turnout.settings.validation.timeSpecific}
                  </p>
                )}
              </div>
              <div>
                {this.state.tts.toggleMuteSpecific &&
                  this.state.tts.muteSpecificUntil !== null &&
                  this.state.tts.muteSpecificUntil !== 0 &&
                  (dateIsIndefinite ? (
                    <p>{copy.turnout.settings.tts.muteTtsIndefinite}</p>
                  ) : (
                    <p>
                      {copy.turnout.settings.tts.muteTtsActive}{" "}
                      {this.getTime(this.state.tts.muteSpecificUntil)} on{" "}
                      {this.getDate(this.state.tts.muteSpecificUntil)}
                    </p>
                  ))}
              </div>
            </div>
          </div>

          <div className={styles.subRow}>
            <div className={styles.subTitle}>
              {copy.turnout.settings.muteBetween}
            </div>
            <div className={styles.subBody}>
              {/* mute between */}
              <div className={styles.input}>
                <TimeInput
                  className={styles.dropdownTime}
                  value={
                    this.state.tts.muteFrom ? this.state.tts.muteFrom : null
                  }
                  onChange={(time) => this.updateTime(time, "from")}
                  isClearable={false}
                  timeSlot={30}
                  defaultSelectedValue={
                    this.state.tts.muteFrom ? this.state.muteFrom : null
                  }
                  colors={{
                    time: {
                      active: {
                        background: "#007454",
                        color: "#ffffff",
                      },
                      default: {},
                      disabled: {},
                    },
                    input: {
                      default: {
                        border: "#B4B4B4",
                        color: "#313131",
                        placeholder: "#B4B4B4",
                        tag: "#007454",
                      },
                      disabled: {
                        border: "#EFEFEF",
                        color: "#EFEFEF",
                        tag: "#B4B4B4",
                      },
                      error: {
                        border: "#C02908",
                        color: "#C02908",
                        background: "#FDDCDC",
                        tag: "#C02908",
                      },
                      focused: {
                        border: "#007454",
                        tag: "#007454",
                      },
                    },
                  }}
                />
              </div>
              <div className={styles.input}>
                <TimeInput
                  className={styles.dropdownTime}
                  value={this.state.tts.muteTo ? this.state.tts.muteTo : null}
                  onChange={(time) => this.updateTime(time, "to")}
                  isClearable={false}
                  timeSlot={30}
                  defaultSelectedValue={
                    this.state.tts.muteTo ? this.state.tts.muteTo : null
                  }
                  colors={{
                    time: {
                      active: { background: "#007454", color: "#ffffff" },
                      default: {},
                      disabled: {},
                    },
                    input: {
                      default: {
                        border: "#B4B4B4",
                        color: "#313131",
                        placeholder: "#B4B4B4",
                        tag: "#007454",
                      },
                      disabled: {
                        border: "#EFEFEF",
                        color: "#EFEFEF",
                        tag: "#B4B4B4",
                      },
                      error: {
                        border: "#C02908",
                        color: "#C02908",
                        background: "#FDDCDC",
                        tag: "#C02908",
                      },
                      focused: { border: "#007454", tag: "#007454" },
                    },
                  }}
                />
              </div>
              {/* on/off */}
              <div className={styles.input}>
                <span className={styles.inputName}>
                  {this.state.tts.toggleMuteBetween
                    ? copy.turnout.settings.on
                    : copy.turnout.settings.off}
                </span>
                <Toggle
                  key="9"
                  checked={this.state.tts.toggleMuteBetween}
                  buttonClick={() => this.toggleTextToSpeech("muteBetween")}
                  label="mute between"
                  fromTurnout
                />
              </div>

              <div className={styles.errorWrapper}>
                {this.state.invalidMuteFrom && (
                  <p className={styles.errorMsg}>
                    {copy.turnout.settings.validation.timeFrom}
                  </p>
                )}
                {this.state.invalidMuteTo && (
                  <p className={styles.errorMsg}>
                    {copy.turnout.settings.validation.timeTo}
                  </p>
                )}
              </div>
            </div>
          </div>

          <div className={styles.subRow}>
            <div className={styles.subTitle}>
              <label htmlFor="speechRate">
                {copy.turnout.settings.tts.speechRate}
              </label>
            </div>
            <div className={styles.subBody}>
              {/* speech rate */}
              <input
                type="range"
                min="0.5"
                max="2"
                step="0.1"
                name="speechRate"
                id="speechRate"
                value={this.state.tts.speechRate}
                onChange={(e) => this.selectRange(e.target.value, "rate")}
              />
            </div>
          </div>

          <div className={styles.subRow}>
            <div className={styles.subTitle}>
              <label htmlFor="volume">{copy.turnout.settings.volume}</label>
            </div>
            <div className={styles.subBody}>
              {/* volume */}
              <input
                type="range"
                min="0"
                max="1"
                step="0.1"
                name="volume"
                id="volume"
                value={this.state.tts.volume}
                onChange={(e) => this.selectRange(e.target.value, "vol")}
              />
            </div>
          </div>

          <div className={styles.subRow}>
            <div className={styles.subTitle}>
              <label htmlFor="repeats">{copy.turnout.settings.repeats}</label>
            </div>
            <div className={styles.subBody}>
              {/* repeats */}
              <Dropdown
                className={
                  styles.frequencyButton + " " + styles.dropdownShortItem
                }
                selection
                value={this.state.tts.repeat}
                options={this.repeats}
                onChange={(e, { value }) =>
                  this.setState({
                    tts: {
                      ...this.state.tts,
                      repeat: value,
                    },
                  })
                }
              />
              <span className={styles.repeats}>
                {copy.turnout.settings.times}
              </span>
            </div>
          </div>

          <div className={styles.subRow}>
            <div className={styles.subTitle}>
              <label htmlFor="repeats">{copy.turnout.settings.gaps}</label>
            </div>
            <div className={styles.subBody}>
              {/* repeats */}
              <Dropdown
                className={styles.frequencyButton}
                selection
                value={this.state.tts.gap}
                options={this.gaps}
                onChange={(e, { value }) =>
                  this.setState({
                    tts: {
                      ...this.state.tts,
                      gap: value,
                    },
                  })
                }
              />
            </div>
          </div>
          <div
            className={
              styles.subRow + " " + styles.multi + " " + styles.noUnderline
            }
          >
            <div className={styles.subTitle}>
              <span>{copy.turnout.settings.tts.phonetics}</span>
            </div>
            <div className={styles.subBody}>
              <div className={styles.phoneticsContainer}>
                <ul>
                  {this.state.tts.newPhonetics.map((x, i) => {
                    let invalidWordToReplace =
                      this.state.tts.newPhonetics.filter(
                        (x) =>
                          x.wordToReplace.toLowerCase() ===
                          this.state.tts.newPhonetics[
                            i
                          ].wordToReplace.toLowerCase()
                      ).length > 1;
                    return (
                      <li key={"phonetics-" + i}>
                        <div className={styles.subRow}>
                          <label htmlFor={"wordTo-" + i}>
                            {copy.turnout.settings.tts.replace}
                          </label>
                          <input
                            className={
                              invalidWordToReplace
                                ? styles.invalidInput
                                : "default_input "
                            }
                            name={"wordTo-" + i}
                            id={"wordTo-" + i}
                            type="text"
                            value={x.wordToReplace}
                            onChange={(e) =>
                              this.onChangeWord(e.target.value, i, "toreplace")
                            }
                          />
                          <label htmlFor={"replaceWith-" + i}>
                            {copy.turnout.settings.tts.with}
                          </label>
                          <input
                            className="default_input "
                            name={"replaceWith-" + i}
                            id={"replaceWith-" + i}
                            type="text"
                            value={x.replaceWith}
                            onChange={(e) =>
                              this.onChangeWord(
                                e.target.value,
                                i,
                                "replacewith"
                              )
                            }
                          />
                          <Button
                            label="Remove"
                            content=""
                            variant=""
                            styles="btn_sqaure"
                            icon="icon_remove"
                            disabled={false}
                            buttonClick={() => this.removeLine(i)}
                          />
                          <Button
                            label="Remove"
                            content=""
                            variant=""
                            styles="btn_sqaure"
                            icon="icon_sound"
                            disabled={false}
                            buttonClick={() => this.speak(x.replaceWith)}
                          />
                        </div>
                      </li>
                    );
                  })}
                </ul>
                {this.state.duplicateWordError && (
                  <p className={styles.errorMsg}>
                    {copy.turnout.settings.validation.duplicateWord}
                  </p>
                )}
                <div
                  className={
                    styles.subRow +
                    " " +
                    styles.addNewLine +
                    " " +
                    styles.noUnderline
                  }
                >
                  <span>Add New Line</span>
                  <Button
                    label="add new phonetics"
                    content=""
                    variant=""
                    styles="btn_sqaure"
                    icon="icon_add"
                    disabled={false}
                    buttonClick={this.addNewLine}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  toggleAlertTones = () => {
    this.setState((prevState) => {
      return {
        tts: {
          ...this.state.tts,
          alertTones: !prevState.tts.alertTones,
        },
      };
    });
  };
  renderAlertTones() {
    return (
      <div className={styles.row + " " + styles.multi}>
        <audio ref={this.audioRef}>
          Your browser does not support the audio element.
        </audio>
        <div className={styles.title}>
          {copy.turnout.settings.alertTones.title}
        </div>
        <div className={styles.body}>
          {/* Emergency settings */}
          <div className={styles.subRow}>
            <div className={styles.subTitle}>
              {copy.turnout.settings.alertTones.emerg}
            </div>
            <div className={styles.subBody}>
              {/* on/off */}
              <div className={styles.input}>
                <Dropdown
                  className={styles.frequencyButton}
                  selection
                  value={this.state.tts.emergencyAlertSound}
                  options={sounds}
                  onChange={(e, { value }) =>
                    this.setState(
                      {
                        tts: {
                          ...this.state.tts,
                          emergencyAlertSound: value,
                        },
                      },
                      () => {
                        this.playSelectedTone(sounds[value].audio);
                      }
                    )
                  }
                  // style={{ width: "300px" }}
                />
              </div>
            </div>
          </div>
          {/* nonEmergency settings */}
          <div className={styles.subRow}>
            <div className={styles.subTitle}>
              {copy.turnout.settings.alertTones.nonEmerg}
            </div>
            <div className={styles.subBody}>
              <div className={styles.input}>
                <Dropdown
                  className={styles.frequencyButton}
                  selection
                  value={this.state.tts.nonEmergencyAlertSound}
                  options={sounds}
                  onChange={(e, { value }) =>
                    this.setState(
                      {
                        tts: {
                          ...this.state.tts,
                          nonEmergencyAlertSound: value,
                        },
                      },
                      () => {
                        this.playSelectedTone(sounds[value].audio);
                      }
                    )
                  }
                  // style={{ width: "300px" }}
                />
              </div>
            </div>
          </div>
          {/* Admin settings */}
          <div className={styles.subRow + " " + styles.noUnderline}>
            <div className={styles.subTitle}>
              {copy.turnout.settings.alertTones.admin}
            </div>
            <div className={styles.subBody}>
              <div className={styles.input}>
                <Dropdown
                  className={styles.frequencyButton}
                  selection
                  value={this.state.tts.adminAlertSound}
                  options={sounds}
                  onChange={(e, { value }) =>
                    this.setState(
                      {
                        tts: {
                          ...this.state.tts,
                          adminAlertSound: value,
                        },
                      },
                      () => {
                        this.playSelectedTone(sounds[value].audio);
                      }
                    )
                  }
                  // style={{ width: "300px" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  render() {
    const initialSettings = JSON.parse(JSON.stringify(this.initialSettings));
    const payload = {
      routing: this.state.layout.route,
      defaultLayout: this.state.layout.default,
      showAttendance: this.state.layout.showAttendance,
      showQualification: this.state.layout.showQualification,
      showNoResponse: this.state.layout.showNoResponse,
      showNotAttending: this.state.layout.showNotAttending,
      showOther: this.state.layout.showOther,
      textToSpeech: {
        toggleOn: this.state.tts.toggleOn,
        muteSpecific:
          this.state.tts.muteSpecific !== 0
            ? this.state.tts.muteSpecific
            : this.state.tts.muteSpecificUntil,
        toggleMuteBetween: this.state.tts.toggleMuteBetween,
        muteFrom: this.state.tts.toggleMuteBetween
          ? this.state.tts.muteFrom
          : null,
        muteTo: this.state.tts.toggleMuteBetween ? this.state.tts.muteTo : null,
        speechRate: this.state.tts.speechRate,
        volume: this.state.tts.volume,
        gap: this.state.tts.gap,
        // togglePriority: this.state.priority,
        repeat: this.state.tts.repeat,
        phonetics: this.state.tts.newPhonetics,
        adminAlertSound: this.state.tts.adminAlertSound,
        emergencyAlertSound: this.state.tts.emergencyAlertSound,
        nonEmergencyAlertSound: this.state.tts.nonEmergencyAlertSound,
      },
    };
    const invalid = JSON.stringify(initialSettings) === JSON.stringify(payload);

    return (
      <Wrapper>
        <ErrorBanner
          errorType={this.state.errorType}
          isVisible={this.state.errorMessage}
          ErrorMessage={this.state.errorMessage}
        />
        <div className={styles.turnoutWrapper + " zoom bg3"}>
          <div className={styles.buttonContainer}>
            <button
              label="To Turnout"
              aria-label="open turnout page"
              className={"btn_tertiary "}
              onClick={() => {
                this.props.history.push({
                  pathname: "/turnout/events",
                });
              }}
            >
              View Turnout
            </button>
            <div className="btnHolder">
              <Button
                label="Cancel"
                content="Cancel"
                variant="btn_outline"
                styles="btn_secondary"
                icon=""
                disabled={invalid}
                buttonClick={this.onCancel}
              />
              <Button
                label="Save"
                content="Save"
                variant="btn_outline"
                styles="btn_secondary"
                icon=""
                disabled={
                  invalid ? true : this.state.duplicateWordError ? true : false
                }
                buttonClick={this.onSave}
              />
            </div>
          </div>
          <div className={styles.box_default + " bg2"}>
            <div className={styles.header}>
              <div className={styles.sectionTitle}>
                <h2>{copy.turnout.settings.title}</h2>
              </div>
            </div>
            {this.state.errorMessage && this.state.getSettingsError ? (
              <h4>
                <span className="noMessages">
                  {this.state.errorMessage}
                  <div>
                    <Button
                      label="Refresh"
                      content={copy.global.btnRefresh}
                      variant="btn_solid"
                      styles="greyBg"
                      icon="icon_refresh"
                      buttonClick={this.getSettings}
                    />
                  </div>
                </span>
              </h4>
            ) : this.state.loading ? (
              <div className="loadingContainer">
                <Loader />
              </div>
            ) : (
              <Wrapper>
                {/* Layout */}
                {this.renderLayoutSettings()}
                {/* Layout */}
                {/* {this.renderMessageSettings()} */}
                {/* Text To Speech */}
                {this.renderARsSettings()}
                {this.state.tts.alertTones && this.renderAlertTones()}
              </Wrapper>
            )}
          </div>
        </div>
      </Wrapper>
    );
  }
}
