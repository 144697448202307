import React from "react";
import Wrapper from "../../hoc/Wrapper/Wrapper";
import ErrorBanner from "../../hoc/ErrorBanner/ErrorBanner";
import { format, addDays } from "date-fns";
import Loader from "../../components/UI/Loading/Loading";
// UIs & styles
import Button from "../UI/Button/Button";

// Data
import { Requests } from "../../api/IdentityServerRequests/Requests";
import copy from "../../assets/copy/copy.json";

/*
 * This is the next 7 day view for personal Availability
 * This component is used in both the setting - availability section & below the calendar
 * & the admin user result - user details.
 * This component allows user view & delete timeslots
 */

export default class Days extends React.Component {
  constructor(props) {
    super(props);
    this.Requests = new Requests();
    this.state = {
      moreOptions: false,
      target: false,
      availability: [],
      sevenDayView: [],
      errorCode: null,
      ready: null, // Spinner will show on null
      //sevenDayView: this.props.sevenDayView
    };
  }

  componentDidMount() {
    this.getAvailabiity();
  }

  /**
   * Called when the page needs to be refreshed
   */
  handleRefresh = () => {
    this.getAvailabiity();
  };

  /*
   * This function sets all the data thats needed to add or edit an entry
   * Before sending it to the parent component
   */
  sendData = (
    id,
    date,
    start,
    end,
    repeat,
    availabilityTypes,
    editState,
    alreadyAvailable
  ) => {
    this.setState(
      {
        edit: {
          id: id, // (availability id to edit & delete time) integer($int32)
          date: date, // yyyy-MM-dd
          start: start, // (start time) HH:MM
          end: end, // (end time) HH:MM
          repeat: repeat, // true/false (recurring timeslot)
          types: availabilityTypes,
          alreadyAvailable: alreadyAvailable, // If there is an availability time already
        },
      },
      () => {
        this.props.parentCallback(this.state.edit);
        if (this.props.updateHeading) {
          this.props.updateHeading(editState);
        }
      }
    );
  };
  /*
   * This function send the data to the parent component
   * which is either the calendar (which then send on the data to Availability view)
   * OR straight to the Availability view
   */
  forceRefresh = () => {
    this.props.parentCallbackForce();
  };
  /*
   * This calls the API to render the currently logged in users Availability for the next seven days
   */
  getAvailabiity = () => {
    // Check if this component has been called by the calendar
    // This just shows one day - the day selected by the user
    if (this.props.calendarClick === true) {
      // Load the availability sent from the calendar
      let availability = this.props.availability;
      this.setState({
        sevenDayView: availability,
        ready: true,
      });
    } else {
      // This is not being called by the calendar
      // Show the next seven days
      let today = new Date();
      let day7 = addDays(today, 6);
      // Make sure you use capital MM for month
      let dates = {
        dateFrom: format(today, "yyyy-MM-dd"),
        dateTo: format(day7, "yyyy-MM-dd"),
      };

      let APICall;
      let id;
      let ErrorMessage = "";
      let ErrorCode = "";
      if (this.props.id !== "") {
        // if an id is given show the selected user - for admin section
        // profile/v1/User/{id}/availability
        APICall = this.Requests.getSelectedUsersAvailability;
        id = this.props.id;
        ErrorMessage =
          copy.admin.userDetailsView.getSelectedUserAvailAPIErrorMessage +
          ` (Error #${copy.errorCodes.getSelectedUserAvailAPIErrorMessage})`;
        ErrorCode = copy.errorCodes.getSelectedUserAvailAPIErrorMessage;
      } else {
        //otherwise show the current user next seven day - for setting section
        // profile/v1/User/availability
        APICall = this.Requests.getUsersAvailability;
        ErrorMessage =
          copy.settings.availability.getUserAvailAPIErrorMessage +
          ` (Error #${copy.errorCodes.getUserAvailAPIErrorMessage})`;
        ErrorCode = copy.errorCodes.getUserAvailAPIErrorMessage;
      }

      this.Requests.callAPI(APICall, dates, id).then((data) => {
        if (data && data.status && data.status === 200) {
          this.setState({ availability: data.data.dateAvailable }, () => {
            // after availability data has loaded
            // create an array for the next seven days
            let sevenDayView = [];
            // first date
            let day = new Date();
            let FirstDay = format(day, "yyyy-MM-dd");
            let expectedDate = FirstDay;
            // create week from availability data
            if (this.state.availability) {
              for (let i = 0; i < this.state.availability.length; i++) {
                // dates from the API
                let date = new Date(this.state.availability[i].date);
                let actualDate = format(date, "yyyy-MM-dd");
                // Check if there is data for the day

                // This loops forever when a date is out of order, as it never finds a match
                // Therefore to catch that error, I have set it to run it a max of 7 days (one week worth)
                // The API MUST return the data in date order for this function to work
                // Now if the dates are out of order again,
                // they will simply not show and not crash the app looping forever
                // This error has occured three times.

                let today = new Date(date);
                let maxOut = new Date(today);
                maxOut.setDate(today.getDate() + 6);
                let formattedMax = format(maxOut, "yyyy-MM-dd");

                if (expectedDate === actualDate) {
                  // if there is data for this date add it to the array
                  sevenDayView.push(this.state.availability[i]);
                  // create the next day using today's date
                  let today = new Date(date);
                  let tomorrow = new Date(today);
                  tomorrow.setDate(today.getDate() + 1);
                  // This is the new expected date
                  expectedDate = format(tomorrow, "yyyy-MM-dd");
                } else if (formattedMax > expectedDate) {
                  // if there is no data for this date, simply add the date
                  sevenDayView.push({
                    date: expectedDate,
                  });
                  // create the next day using the expected date
                  let today = new Date(expectedDate);
                  let tomorrow = new Date(today);
                  tomorrow.setDate(today.getDate() + 1);
                  // This is the new expected date
                  expectedDate = format(tomorrow, "yyyy-MM-dd");
                  // Check again if the expected date matches actual date
                  i = i - 1;
                }
              }
              // Load dates with no availability
              if (sevenDayView.length < 7) {
                // Check for remaining missing days at the end of the month
                let loopFor = 7 - sevenDayView.length;

                // add missing dates
                for (let ii = 0; ii < loopFor; ii++) {
                  sevenDayView.push({
                    date: expectedDate,
                  });
                  // create the next day using the expected date
                  let today = new Date(expectedDate);
                  let tomorrow = new Date(today);
                  tomorrow.setDate(today.getDate() + 1);
                  // This is the new expected date
                  expectedDate = format(tomorrow, "yyyy-MM-dd");
                }
              }
            } else {
              for (let ii = 0; ii < 7; ii++) {
                sevenDayView.push({
                  date: expectedDate,
                });
                // create the next day using the expected date
                let today = new Date(expectedDate);
                let tomorrow = new Date(today);
                tomorrow.setDate(today.getDate() + 1);
                // This is the new expected date
                expectedDate = format(tomorrow, "yyyy-MM-dd");
              }
            }
            // this is the data that will be used to create the seven day view
            this.setState({ sevenDayView: sevenDayView, ready: true });
          });
        } else {
          // let ErrorMessage =
          //   copy.settings.availability.getUserAvailAPIErrorMessage;
          // if (data && data.data && data.data.SASMessageClient) {
          //   ErrorMessage = data.data.SASMessageClient;
          // }
          this.setState(
            { errorMessage: ErrorMessage, ready: false, errorCode: ErrorCode },
            () => {
              setTimeout(
                function () {
                  this.setState({ errorMessage: null });
                }.bind(this),
                5000
              );
            }
          );
        }
      });
    }
  };

  // getNextDate = (selectedDate) => {
  //   const currentDayInMilli = new Date(selectedDate).getTime();
  //   const oneDay = 1000 * 60 * 60 * 24;
  //   const nextDayInMilli = currentDayInMilli + oneDay;
  //   const nextDate = new Date(nextDayInMilli);

  //   return nextDate;
  // };

  /*
   * Delete a single time
   */
  deleteDate = (availabilityId, availabilityDate, isSeries) => {
    this.Requests.callAPI(
      //profile/v1/User/availability/{availabilityid}
      this.Requests.removeSingleAvailability,
      availabilityId
    ).then((data) => {
      if (data && data.status && data.status === 200) {
        this.props.parentUpdate();
        this.getAvailabiity();
        //When deleting a series, need to also clear the states
        if (isSeries) {
          this.state.moreOptions[availabilityDate].target.length > 1 //Handles when there are multiple series on one day
            ? this.state.moreOptions[availabilityDate].target[
                availabilityId
              ].remove()
            : this.setState({
                moreOptions: false,
              });
        }
      } else {
        let ErrorMessage =
          copy.settings.availability.deleteUserAvailAPIErrorMessage +
          ` (Error #${copy.errorCodes.deleteUserAvailAPIErrorMessage})`;
        let ErrorCode = copy.errorCodes.deleteUserAvailAPIErrorMessage;

        if (isSeries) {
          ErrorMessage =
            copy.settings.availability.deleteUserRecurringAvailAPIErrorMessage +
            ` (Error #${copy.errorCodes.deleteUserRecurringAvailAPIErrorMessage})`;
          ErrorCode = copy.errorCodes.deleteUserRecurringAvailAPIErrorMessage;
        }
        // if (data && data.data && data.data.SASMessageClient) {
        //   ErrorMessage = data.data.SASMessageClient;
        // }
        this.setState(
          { errorMessage: ErrorMessage, errorCode: ErrorCode },
          () => {
            setTimeout(
              function () {
                this.setState({ errorMessage: null });
              }.bind(this),
              5000
            );
          }
        );
      }
    });
  };
  /*
   * Delete a single time from a set - recurring time
   */
  deleteSingleRecurringDate = (availabilityId, date) => {
    this.Requests.callAPI(
      // v1/User/availability/{availabilityId}/occurrence/{date}
      this.Requests.removeSingleRecurringAvailability,
      availabilityId,
      date.slice(0, 10)
    ).then((data) => {
      if (data && data.status && data.status === 200) {
        // after the deletion was successful
        // rerender the calendar
        this.props.parentUpdate();
        // reload the week view
        this.getAvailabiity();
      } else {
        let ErrorMessage =
          copy.settings.availability.deleteUserRecurringAvailAPIErrorMessage +
          ` (Error #${copy.errorCodes.deleteUserRecurringAvailAPIErrorMessage})`;
        let ErrorCode = copy.errorCodes.deleteUserRecurringAvailAPIErrorMessage;
        // if (data && data.data && data.data.SASMessageClient) {
        //   ErrorMessage = data.data.SASMessageClient;
        // }
        this.setState(
          { errorMessage: ErrorMessage, errorCode: ErrorCode },
          () => {
            setTimeout(
              function () {
                this.setState({ errorMessage: null });
              }.bind(this),
              5000
            );
          }
        );
      }
    });
  };

  renderAvailabilityTypes = (typesList) => {
    if (typesList && typesList.availabilityTypes) {
      let availabilityTypes = [];

      // Transform the API type name to UI name (eg. Local = Local / Home Branch)
      for (let i = 0; i < typesList.availabilityTypes.length; i++) {
        let typeToAdd = "";
        if (typesList.availabilityTypes[i] === "Local") {
          typeToAdd = "Local / Home Branch";
          availabilityTypes.push(typeToAdd);
        } else if (typesList.availabilityTypes[i] === "RegionShortHaul") {
          typeToAdd = "Region / Short Haul";
          availabilityTypes.push(typeToAdd);
        } else if (typesList.availabilityTypes[i] === "StateLongHaul") {
          typeToAdd = "State / Long Haul";
          availabilityTypes.push(typeToAdd);
        } else if (typesList.availabilityTypes[i] === "InterstateDeployment") {
          typeToAdd = "Interstate Deployment";
          availabilityTypes.push(typeToAdd);
        } else if (typesList.availabilityTypes[i] === "IMT") {
          typeToAdd = "IMT";
          availabilityTypes.push(typeToAdd);
        } else if (typesList.availabilityTypes[i] === "All_AVOnly") {
          typeToAdd = "All (AV Only)";
          availabilityTypes.push(typeToAdd);
        }
      }
      return (
        <div className="availabileForContainer">
          Available for&nbsp;
          {availabilityTypes.map((type, index) =>
            index === 0 ? (
              // First item in the list
              <span key={index}>{type}</span>
            ) : index !== availabilityTypes.length - 1 ? (
              // Not the last time in the list
              <span key={index}>, {type}</span>
            ) : (
              // Last item in the list
              <span key={index}> and {type}</span>
            )
          )}
        </div>
      );
    }
  };
  /*
   * This renders the UI for the week using the data from
   */
  renderWeek() {
    if (this.state.sevenDayView !== []) {
      return this.state.sevenDayView.map((item, index) => (
        <li key={index}>
          <div className="inline-flex">
            <div style={{display: "inline-block"}}>
              <span className="primaryText" style={{display: "inline-block", width: "60%"}}>
                {format(new Date(item.date), "iiii")}
              </span>
              <span className="subText" style={{display: "inline-block"}}>
                {format(new Date(item.date), "dd/MM/yyyy")}
              </span>
            </div>
            {this.props.viewOnlyMode === false && (
              <Wrapper>
                {this.props.id === "" &&
                  (item.timeAvailable ? (
                    <Button
                      label="Add"
                      content="Add"
                      variant="btn_secondary"
                      styles=""
                      icon="icon_add"
                      buttonClick={() =>
                        this.sendData(
                          "",
                          item.date,
                          "",
                          "",
                          "",
                          "",
                          false, // This signifies that this is Adding not Editing
                          true // This signifies that there's an exsisting availability time already
                        )
                      }
                    />
                  ) : (
                    <Button
                      label="Add"
                      content="Add"
                      variant="btn_secondary"
                      styles=""
                      icon="icon_add"
                      buttonClick={() =>
                        this.sendData(
                          "",
                          item.date,
                          "",
                          "",
                          "",
                          "",
                          false, // This signifies that this is Adding not Editing
                          false // This signifies that there's no exsisting availability
                        )
                      }
                    />
                  ))}
              </Wrapper>
            )}
          </div>
          {typeof item.timeAvailable == "object" &&
          item.timeAvailable.length > 0 ? (
            <ul>
              {item.timeAvailable.map((time, index) => (
                <li key={index}>
                  <div className="flex flex-wrap">
                    <p
                      className={
                        time.repeat !== null ? "repeating timeslot" : "timeslot"
                      }>
                      {time.timeStart === "00:00:00" &&
                      time.timeEnd === "23:59:59"
                        ? "All day"
                        : time.timeStart.slice(0, 5) +
                          " - " +
                          time.timeEnd.slice(0, 5)}
                    </p>
                    {this.props.viewOnlyMode === false && (
                      <Wrapper>
                        {this.props.id === "" && (
                          <div>
                            <Button
                              label="remove"
                              content=""
                              variant="btn_sqaure"
                              styles=""
                              icon="icon_remove"
                              buttonClick={() =>
                                time.repeat === null
                                  ? this.deleteDate(
                                      time.availableId,
                                      item.date,
                                      false
                                    )
                                  : this.setState({
                                      moreOptions: {
                                        ...this.state.moreOptions,
                                        [item.date]: this.state.moreOptions[
                                          item.date
                                        ]
                                          ? {
                                              // options: !this.state.moreOptions[
                                              //   item.date
                                              // ].options,
                                              target: {
                                                ...this.state.moreOptions[
                                                  item.date
                                                ].target,
                                                [time.availableId]: !this.state
                                                  .moreOptions[item.date]
                                                  .target[time.availableId],
                                              },
                                            }
                                          : {
                                              //options: true,
                                              target: {
                                                [time.availableId]: true,
                                              },
                                            },
                                      },
                                    })
                              }
                            />
                            <Button
                              label="edit"
                              content=""
                              variant="btn_sqaure"
                              styles=""
                              icon="icon_edit"
                              buttonClick={() =>
                                this.sendData(
                                  time.availableId,
                                  item.date,
                                  time.timeStart,
                                  time.timeEnd,
                                  time.repeat,
                                  time.availabilityTypes,
                                  true, // This signifies that this is Editing not Adding
                                  true // This signifies that there's an exsisting availability time already
                                )
                              }
                            />
                          </div>
                        )}
                      </Wrapper>
                    )}
                    {/* Loop over the availability types and display them */}
                    {this.renderAvailabilityTypes(time)}
                  </div>
                  {this.state.moreOptions[item.date] && (
                    // this.state.moreOptions[item.date].options === true &&
                    <Wrapper>
                      {this.state.moreOptions[item.date].target[
                        time.availableId
                      ] === true && (
                        <div className="btn_holder">
                          <Button
                            label="This day"
                            content="This day"
                            variant="red"
                            styles="btn_secondary"
                            icon="icon_remove"
                            buttonClick={() =>
                              this.deleteSingleRecurringDate(
                                time.availableId,
                                item.date
                              )
                            }
                          />
                          <Button
                            label="Series"
                            content="Series"
                            variant="red"
                            styles="btn_secondary"
                            icon="icon_remove"
                            buttonClick={() =>
                              this.deleteDate(time.availableId, item.date, true)
                            }
                          />
                          <Button
                            label="Cancel"
                            content="Cancel"
                            variant=""
                            styles="btn_secondary"
                            icon=""
                            buttonClick={() =>
                              this.setState({
                                moreOptions: {
                                  ...this.state.moreOptions,
                                  [item.date]: {
                                    // options: !this.state.moreOptions[item.date]
                                    //   .options,
                                    target: {
                                      ...this.state.moreOptions[item.date]
                                        .target,
                                      [time.availableId]: !this.state
                                        .moreOptions[item.date].target[
                                        time.availableId
                                      ],
                                    },
                                  },
                                },
                              })
                            }
                          />
                        </div>
                      )}
                    </Wrapper>
                  )}
                </li>
              ))}
            </ul>
          ) : (
            <p>There are no availability times set for this day</p>
          )}
        </li>
      ));
    }
  }

  render() {
    return (
      <Wrapper>
        <ErrorBanner
          isVisible={this.state.errorMessage ? true : false}
          ErrorMessage={this.state.errorMessage}
          onTop={true}
        />
        {this.state.ready !== null ? (
          this.state.ready === true ? (
            <ul className="settingOptions week">{this.renderWeek()}</ul>
          ) : (
            // API called failed
            <div className="settingsWrapper">
              <h4>
                <span className="noMessages">
                  {this.state.errorCode
                    ? copy.settings.availability.errorInAvailabilityFetch +
                      ` (Error #${this.state.errorCode})`
                    : copy.settings.availability.errorInAvailabilityFetch}
                  <div>
                    <Button
                      label="Refresh"
                      content={copy.global.btnRefresh}
                      variant="btn_solid"
                      styles="greyBg"
                      icon="icon_refresh"
                      buttonClick={this.handleRefresh}
                    />
                  </div>
                </span>
              </h4>
            </div>
          )
        ) : (
          <div className="inLineloadingContainer">
            <Loader />
          </div>
        )}
      </Wrapper>
    );
  }
}
