import React from "react";
import Wrapper from "../../hoc/Wrapper/Wrapper";
import { ReactComponent as AppLOGO } from "../../assets/img/logos/SAS-APP.svg";
import copy from "../../assets/copy/copy.json";
import { Requests } from "../../api/IdentityServerRequests/Requests";
export default class SASUpdateInProgress extends React.Component {
  constructor() {
    super();
    this.state = {
      upgradeInProgressPart1: copy.update_page.upgradeInProgressPart1,
      upgradeInProgressPart2: copy.update_page.upgradeInProgressPart2,
    };
    this.Requests = new Requests();
    this.getConfigsData();
  }

  getConfigsData = () => {
    this.Requests.getSASAppConfigs().then((data) => {
      if (data && data.data && data.status && data.status === 200) {
        if (
          data.data.upgradeInProgressPart1 &&
          data.data.upgradeInProgressPart2
        ) {
          this.setState({
            upgradeInProgressPart1: data.data.upgradeInProgressPart1,
            upgradeInProgressPart2: data.data.upgradeInProgressPart2,
          });
        }
      }
    });
  };

  render() {
    return (
      <Wrapper>
        <div className="pageHeader"></div>
        <AppLOGO />
        <div className={"box_default termsofuse"}>
          <p>{copy.update_page.greetingMessage}</p>
          <div>
            <p>{this.state.upgradeInProgressPart1}</p>
          </div>
          <div>
            <p>{this.state.upgradeInProgressPart2}</p>
          </div>
        </div>
      </Wrapper>
    );
  }
}
