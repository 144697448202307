import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import times from "../../../api/sampleData/times.json";
import TableCell from "./TableCell";

class TableRow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: this.props.data.nameFirst + " " + this.props.data.nameLast,
      pagingGroupOff: this.props.data.pagingGroupMuted,
      userAvailableTimeBlock: this.props.data.userAvailableTimeBlock,
      userUnavailableTimeBlock: this.props.data.userUnavailableTimeBlock,
      qualifications: this.props.data.qualifications,
      fullListQualifications: "",
    };
  }

  createTableHeader() {
    const userAvailableTimeBlock = this.state.userAvailableTimeBlock;
    const userUnavailableTimeBlock = this.state.userUnavailableTimeBlock;

    // * Get the timeslot(s) of member
    const timeSlot = times.map((time, timeIndex) => {
      let isEmptyCell = true;
      let component = null;

      // * Gets the available timeslot
      userAvailableTimeBlock.forEach((available, availableIndex) => {
        if (available.timeStart.substring(0, 5) === time.text) {
          isEmptyCell = false;
          component = (
            <TableCell
              key={`available-${availableIndex}`}
              type="CELL__AVAILABLE"
              value={available.emergencyLevels}
            />
          );
        }
      });

      // * Gets the unavailable timeslot
      userUnavailableTimeBlock.forEach((unavailable, unavailableIndex) => {
        if (unavailable.timeStart.substring(0, 5) === time.text) {
          isEmptyCell = false;
          component = (
            <TableCell
              key={`unavailable-${unavailableIndex}`}
              type="CELL__UNAVAILABLE"
            />
          );
        }
      });

      // * Return empty cell if no timeslot is found
      if (isEmptyCell) {
        return <TableCell key={`empty$-${timeIndex}`} type="EMPTY__CELL" />;
      } else {
        return component;
      }
    });

    // * Display Qualifications of member
    const QUALIFICATION__LIMIT = 4;
    let qualifications = this.state.qualifications.map(
      (qualificationItem, qualificationIndex) => {
        // * Determine if to truncate Qualifications
        if (this.state.qualifications.length < QUALIFICATION__LIMIT) {
          if (qualificationIndex === this.state.qualifications.length - 1) {
            return qualificationItem;
          } else {
            return `${qualificationItem}, `;
          }
        } else if (this.state.qualifications.length >= QUALIFICATION__LIMIT) {
          if (qualificationIndex === QUALIFICATION__LIMIT - 1) {
            return `${qualificationItem} ...`;
          } else if (qualificationIndex < QUALIFICATION__LIMIT) {
            return `${qualificationItem}, `;
          } else {
            return null;
          }
        } else {
          return null;
        }
      }
    );

    // * Get the full list of Qualifications
    let fullListQualifications = "";
    this.state.qualifications.forEach(
      (qualificationItem, qualificationIndex) => {
        fullListQualifications +=
          qualificationIndex === this.state.qualifications.length - 1
            ? qualificationItem
            : `${qualificationItem}, `;
      }
    );

    this.setState({
      timeSlot: timeSlot,
      qualifications: qualifications,
      fullListQualifications: fullListQualifications,
    });
  }

  componentDidMount() {
    this.createTableHeader();
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.data !== this.props.data) {
      this.setState(
        {
          name: this.props.data.nameFirst + " " + this.props.data.nameLast,
          pagingGroupOff: this.props.data.pagingGroupMuted,
          userAvailableTimeBlock: this.props.data.userAvailableTimeBlock,
          userUnavailableTimeBlock: this.props.data.userUnavailableTimeBlock,
          qualifications: this.props.data.qualifications,
          fullListQualifications: "",
        },
        () => {
          this.createTableHeader();
        }
      );
    }
  };

  render() {
    return (
      <tr>
        <th>
          {this.state.pagingGroupOff ? (
            <div className="availabilityDashboard__icon">
              <span className="sr-only">Paging group off</span>
              <i className="icon"></i>
            </div>
          ) : null}

          <p className="availabilityDashboard__name">{this.state.name}</p>

          <p
            className="availabilityDashboard__qualification"
            title={this.state.fullListQualifications}>
            {this.state.qualifications}
          </p>
        </th>
        {this.state.timeSlot}
      </tr>
    );
  }
}

export default withRouter(TableRow);
