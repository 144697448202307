import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {
  format,
  subWeeks,
  subDays,
  endOfMonth,
  startOfMonth,
  subMonths,
} from "date-fns";
import Wrapper from "../../../hoc/Wrapper/Wrapper";
import { Requests } from "../../../api/IdentityServerRequests/Requests";

import { Dropdown } from "semantic-ui-react";
import Checkbox from "../../../components/UI/Button/Checkbox.jsx";
import ErrorBanner from "../../../hoc/ErrorBanner/ErrorBanner";

import styles from "./Report.module.scss";
import copy from "../../../assets/copy/copy.json";
import Loading from "../../../components/UI/Loading/Loading";
import Accordion from "../../../components/UI/Accordion/Accordion";
import Button from "../../../components/UI/Button/Button";

const fileDownload = require("js-file-download");

export default class EventReportView extends React.Component {
  constructor(props) {
    super(props);
    this.Requests = new Requests();
    this.fileDownload = fileDownload;
    // this.fileDownload = fileDownload;
    this.state = {
      userAgenciesOption: [],
      userPagingGroupsOption: [],
      PagingGroupOption: [],
      userOption: [],
      qualificationID: [],
      typeID: [],
      searchData: {
        agency: [],
        pagingNumber: [],
      },
      Emergency: false,
      NonEmergency: false,
      Admin: false,
      Attended: false,
      Other: false,
      Unavailable: false,
      NoResponse: false,
      type: [],
      dateEnd: "",
      dateStart: "",
      dateStartObject: null,
      dateEndObject: null,
      scheduleStartDate: "",
      scheduleStartDateObject: null,
      dateType: "text",
      PagingGroups: [],
      UsersId: [], // Users which have been selected to generate the report for
      response: [],
      successful: null,
      errorMessage: "",
      reportType: "USERS",
      reportFrequency: null,
      scheduleFileType: null,
      exsistingScheduledReports: null, // Null intially to show spinner. false is API failed. Expected to be an array.
      existingScheduleReportsError: false,
      AgencyError: false,
      PagingGroupsError: false,
      AsnError: false,
      typeError: false,
      typeErrorMessage: null,
      dateStartError: false,
      reportFrequencyError: false,
      scheduleFileTypeError: false,
      scheduleStartDateError: false,
      emailAddressError: false,
      dateRangeValue: "",
      membersResultsError: false,
      availabilityError: false,
      QualificationsError: false,
      TypeError: false,
      Qualifications: false,
      Type: false,
      allQualifications: {},
      // File download data
      isDownloadingReport: false,
      report: null,
      dateTimeAfterOrEqual: null,
      dateTimeBeforeOrEqual: null,
      messageType: null,
      pagingNumber: null,
      pagingNumbers: null,
      attendanceStatus: null,
      agency: null,
      reportUsersId: null,
      qualificationIds: null,
      availabilityTypes: null,
      pagingGroupsPartOfWithAvailability: {}, // Paging groups user is part of which have availability enabled
      pagingGroupsOwnedWithAvailability: {}, // Paging groups user owns which have availability enabled

      userEmails: {}, // contains user's emails,
      generateAsnReportPermission: null,
      asnInput: null,
      invalidASN: false,
    };
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount() {
    if (
      window.innerWidth <= 1023 ||
      window.cordova ||
      !this.getViewPermission()
    ) {
      this.props.history.push({
        pathname: "/dashboard/events",
      });
    }
    // this.preparePagingGroupData();
    this.getPagingGroupsPartOfWithAvailability();
    this.getAllQualifications();
    this.getScheduledReports();

    // obtain agency emails from props
    let emails = {};
    if (this.props.userInfo.userAgencyInfo) {
      for (let i = 0; i < this.props.userInfo.userAgencyInfo.length; i++) {
        emails[this.props.userInfo.userAgencyInfo[i].agency] =
          this.props.userInfo.userAgencyInfo[i].email;
      }
    }
    this.setState({
      userEmails: emails,
    });
    this.setState({
      generateAsnReportPermission: this.getViewPermission("asn"),
    });
  }

  /**
   * This function determines whether the user has permissions to view report page / particular tab(s).
   * the default is the same as page level permission & availability permission (PGO + manager and above)
   */
  getViewPermission = (permissionFor = "default") => {
    let isPagingGroupOwner = false;
    Object.entries(this.props.pagingGroupsOwned).forEach((entry) => {
      if (entry[1].length > 0 && entry[0] !== "AV") {
        isPagingGroupOwner = true;
      }
    });

    let isManagerAbove =
      this.props.userRoleAgency.indexOf("SuperUser") !== -1 ||
      this.props.userRoleAgency.indexOf("Administrator_CFA") !== -1 ||
      this.props.userRoleAgency.indexOf("Manager_CFA") !== -1 ||
      this.props.userRoleAgency.indexOf("Administrator_SES") !== -1 ||
      this.props.userRoleAgency.indexOf("Manager_SES") !== -1 ||
      this.props.userRoleAgency.indexOf("Administrator_AV") !== -1 ||
      this.props.userRoleAgency.indexOf("Manager_AV") !== -1;

    if (permissionFor && permissionFor.toLowerCase() === "asn") {
      return isManagerAbove;
    }

    return isPagingGroupOwner || isManagerAbove;
  };

  // This will fetch all the exsisting scheduled reports for a user from the dB
  getScheduledReports = () => {
    this.Requests.callAPI(this.Requests.getScheduledReports).then((data) => {
      if (data && data.status && data.status === 200) {
        this.setState(
          {
            exsistingScheduledReports: [],
          },
          () => {
            this.setState({
              exsistingScheduledReports:
                this.state.exsistingScheduledReports.concat(data.data),
              existingScheduleReportsError: false,
            });
          }
        );
      } else {
        let ErrorMessage =
          copy.report.scheduledReportError +
          ` (Error #${copy.errorCodes.scheduledReportError})`;
        // if (data && data.data && data.data.SASMessageClient) {
        //   ErrorMessage = data.data.SASMessageClient;
        // }
        this.setState(
          {
            errorMessage: ErrorMessage,
            exsistingScheduledReports: [],
            existingScheduleReportsError: true,
          },
          () => {
            setTimeout(
              function () {
                this.setState({ errorMessage: null });
              }.bind(this),
              5000
            );
          }
        );
      }
    });
  };

  /**
   * This function handles any date changes - report start date, end date and scheduled start date
   * @param {String} dateIdentifier - identifier for what type of date got changed (e.g. dateStart,
   * dateEnd, scheduleStartDate)
   * @param {Date} date - Date object for the selected date
   */
  handleDateChange = (dateIdentifier, date) => {
    let correctedDate = new Date(date.toISOString().split("T")[0]);

    // Moves date to one day ahead at 00:00 hours to have the date as the very end of the selected day (very beginning of the nxt day)
    // Date is then formatted to the param date format of the API request using .toISOString()
    if (dateIdentifier === "dateEnd") {
      correctedDate.setDate(correctedDate.getDate() + 2);
    } else {
      correctedDate.setDate(correctedDate.getDate() + 1);
    }

    let formattedDate = new Date(correctedDate).toISOString();

    let dateObjectIdentifier = dateIdentifier + "Object";

    this.setState((prevState) => {
      return { [dateIdentifier]: formattedDate };
    });

    this.setState({
      [dateObjectIdentifier]: date,
      dateRangeValue: "",
      scheduleStartDateError: false,
      dateStartError: false,
      dateEndError: false,
    });
  };

  changeReport = (e) => {
    this.setState(
      {
        reportType: e.target.name,

        // Clear filters
        PagingGroups: [],
        searchData: {
          agency: [],
          pagingNumber: [],
        },
        reportFrequency: null,
        scheduleFileType: null,
        userOption: [],

        // Clear all error messages
        existingScheduleReportsError: false,
        AgencyError: false,
        PagingGroupsError: false,
        AsnError: false,
        typeError: false,
        typeErrorMessage: null,
        dateStartError: false,
        reportFrequencyError: false,
        scheduleFileTypeError: false,
        scheduleStartDateError: false,
        emailAddressError: false,
        dateRangeValue: "",
        membersResultsError: false,
        availabilityError: false,
        QualificationsError: false,
        ValidationErrorMessage: null,
        successful: null,
        scheduleStartDate: "",
        scheduleStartDateObject: null,
      },
      () => {
        this.prepareSearchGroupsAndUsers();
      }
    );
    // document.getElementById("emailId").value = "";

    // Clear the ASN text box if moving to a different radio button.
    if (e.target.name !== "ASN") {
      this.setState({ asnInput: "" });
    }

    // Clearing things which are specific to certain reports
    if (e.target.name === "USERS") {
      this.setState({
        // Clear filters
        Emergency: false,
        NonEmergency: false,
        Admin: false,
        Attended: false,
        Other: false,
        Unavailable: false,
        NoResponse: false,

        type: [],
        dateRangeValue: "",
        dateEnd: "",
        dateStart: "",
        dateStartObject: null,
        dateEndObject: null,

        // Clear errors
        existingScheduleReportsError: false,
        AgencyError: false,
        PagingGroupsError: false,
        AsnError: false,
        typeError: false,
        typeErrorMessage: null,
        dateStartError: false,
        reportFrequencyError: false,
        scheduleFileTypeError: false,
        scheduleStartDateError: false,
        emailAddressError: false,
        membersResultsError: false,
        availabilityError: false,
        QualificationsError: false,
        Qualifications: false,
        Type: false,
        qualificationID: [],
        typeID: [],
        UsersId: [],
      });
    } else if (e.target.name === "EVENT_OVERVIEW") {
      this.setState({
        // Clear filter
        UsersId: [],
        Emergency: false,
        NonEmergency: false,
        Admin: false,
        Attended: false,
        Other: false,
        Unavailable: false,
        NoResponse: false,
        type: [],
        dateRangeValue: "",
        dateEnd: "",
        dateStart: "",
        dateStartObject: null,
        dateEndObject: null,

        // Clear errors
        existingScheduleReportsError: false,
        AgencyError: false,
        PagingGroupsError: false,
        AsnError: false,
        typeError: false,
        typeErrorMessage: null,
        dateStartError: false,
        reportFrequencyError: false,
        scheduleFileTypeError: false,
        scheduleStartDateError: false,
        emailAddressError: false,

        membersResultsError: false,
        availabilityError: false,
        QualificationsError: false,
        Qualifications: false,
        Type: false,
        qualificationID: [],
        typeID: [],
      });
    } else if (e.target.name === "AVAILABILITY") {
      this.setState({
        // Clear filters
        UsersId: [],
        Emergency: false,
        NonEmergency: false,
        Admin: false,
        Attended: false,
        Other: false,
        Unavailable: false,
        NoResponse: false,

        type: [],
        dateRangeValue: "",
        dateEnd: "",
        dateStart: "",
        dateStartObject: null,
        dateEndObject: null,

        // Clear errors
        existingScheduleReportsError: false,
        AgencyError: false,
        PagingGroupsError: false,
        AsnError: false,
        typeError: false,
        typeErrorMessage: null,
        dateStartError: false,
        reportFrequencyError: false,
        scheduleFileTypeError: false,
        scheduleStartDateError: false,
        emailAddressError: false,

        membersResultsError: false,
        availabilityError: false,
        QualificationsError: false,
        Qualifications: false,
        Type: false,
        qualificationID: [],
        typeID: [],
      });
    }
  };

  /**
   * This function is used to prepare the data for the paging groups filter
   */
  getPagingGroupsPartOfWithAvailability = () => {
    // Fetch all the paging groups the user is a part of and has availability enabled
    let body = {
      availabilityEnabled: true,
    };
    // Request to get an array of the agencies the user belongs to
    this.Requests.callAPI(this.Requests.postUserPagingGroups, body).then(
      (data) => {
        if (data && data.status && data.status === 200) {
          let pagingGroupsPartOfWithAvailability = {};
          pagingGroupsPartOfWithAvailability.all = [];
          this.props.userAgencies.forEach((item) => {
            pagingGroupsPartOfWithAvailability[item] = [];
          });
          // This sorts all paging groups by their respective agencies into the pagingGroupsPartOf object.
          data.data.forEach((item) => {
            if (
              item.agency &&
              item.agency !== "AV" &&
              pagingGroupsPartOfWithAvailability[item.agency]
            ) {
              pagingGroupsPartOfWithAvailability[item.agency].push({
                key: item.number,
                text: item.name,
                value: item.number,
              });
              // Paging groups with no agency are placed in the 'all' child.
            } else if (!item.agency) {
              pagingGroupsPartOfWithAvailability.all.push({
                key: item.number,
                text: item.name,
                value: item.number,
              });
            }
          });
          this.setState({
            pagingGroupsPartOfWithAvailability:
              pagingGroupsPartOfWithAvailability,
          });
        } else {
          let ErrorMessage =
            copy.availability.gettingAvailabilityGroupsErrorMessage;
          this.setState(
            {
              pagingGroupsPartOfWithAvailability: false,
              errorMessage: ErrorMessage,
            },
            () => {
              setTimeout(
                function () {
                  this.setState({ errorMessage: null });
                }.bind(this),
                5000
              );
            }
          );
        }
        this.getPagingGroupsOwnedWithAvailability();
      }
    );
  };
  /**
   * This function is used to prepare the data for the paging groups filter,
   * specifically for paging groups the user owns which have availability enabled
   */
  getPagingGroupsOwnedWithAvailability = () => {
    let body = {
      availabilityEnabled: true,
    };
    // Request to get an array of the paging groups the user owns, which have availability enabled
    this.Requests.callAPI(this.Requests.postUserPagingGroupsOwned, body).then(
      (data) => {
        if (data && data.status && data.status === 200) {
          let pagingGroupsOwnedWithAvailability = {};
          pagingGroupsOwnedWithAvailability.all = [];
          this.props.userAgencies.forEach((item) => {
            pagingGroupsOwnedWithAvailability[item] = [];
          });
          // This sorts all paging groups by their respective agencies into the pagingGroupsOwned object.
          data.data.forEach((item) => {
            if (
              item.agency &&
              item.agency !== "AV" &&
              pagingGroupsOwnedWithAvailability[item.agency]
            ) {
              pagingGroupsOwnedWithAvailability[item.agency].push({
                key: item.number,
                text: item.name,
                value: item.number,
              });
              // Paging groups with no agency are placed in the 'all' child.
            } else if (!item.agency) {
              pagingGroupsOwnedWithAvailability.all.push({
                key: item.number,
                text: item.name,
                value: item.number,
              });
            }
          });
          this.setState(
            {
              pagingGroupsOwnedWithAvailability:
                pagingGroupsOwnedWithAvailability,
            },
            () => {
              // Now prepare the paging group data
              this.prepareSearchGroupsAndUsers();
            }
          );
        } else {
          let ErrorMessage =
            copy.availability.gettingAvailabilityGroupsErrorMessage;
          this.setState(
            {
              pagingGroupsOwnedWithAvailability: false,
              errorMessage: ErrorMessage,
            },
            () => {
              setTimeout(
                function () {
                  this.setState({ errorMessage: null });
                }.bind(this),
                5000
              );
            }
          );
        }
      }
    );
  };

  //This function prepares the filter data (agencies and paging groups) to ensure they are in a suitable format for the plugin
  prepareSearchGroupsAndUsers = () => {
    // Preparing agency data for the filter
    let userAgenciesOption = [];
    let pagingGroupsPartOf; // Will store the paging groups user is part of
    let pagingGroupsOwned; // Will store the paging groups user owns
    let pagingGroups = {}; // This is the paging groups which can be selected in the filter
    let allUserPagingGroups = {};

    // If it is an availability report, then the paging groups to select from in the filter needs
    // to be restricted
    if (this.state.reportType === "AVAILABILITY") {
      pagingGroupsPartOf = this.state.pagingGroupsPartOfWithAvailability;
      pagingGroupsOwned = this.state.pagingGroupsOwnedWithAvailability;
    } else {
      pagingGroupsPartOf = this.props.authorisedPagingGroups;
      pagingGroupsOwned = this.props.pagingGroupsOwned;
    }

    // GET AGENCIES USER HAS ADMIN RIGHTS FOR (i.e. superuser, manager, administrator)
    let adminAgencies = [];
    // If they are superuser, they will have admin rights to all agencies
    if (
      this.props.userRoleAgency !== undefined &&
      this.props.userRoleAgency.indexOf("SuperUser") !== -1
    ) {
      adminAgencies = ["CFA", "SES", "AV"];
      // If they are not a superuser, go through their list of roles to see
      // agency they do have admin rights to
    } else {
      let agencies = ["CFA", "SES", "AV"];
      agencies.forEach((agency) => {
        if (
          this.props.userRoleAgency.indexOf("Administrator_" + agency) !== -1 ||
          this.props.userRoleAgency.indexOf("Manager_" + agency) !== -1
        ) {
          adminAgencies.push(agency);
        }
      });
    }

    // Go through all the agencies which the user is admin of
    adminAgencies.forEach((agency, index) => {
      // Add the agency to the filter
      userAgenciesOption.push({
        key: index,
        text: agency,
        value: agency,
      });

      // If the user has permission to view paging groups of that agency, add the paging groups to the filter
      if (
        pagingGroupsPartOf &&
        pagingGroupsPartOf[agency] !== undefined &&
        pagingGroupsPartOf[agency].length > 0
      ) {
        pagingGroups[agency] = [...pagingGroupsPartOf[agency]];
        pagingGroups[agency].forEach((pagingGroup) => {
          allUserPagingGroups[pagingGroup.key] = {
            key: pagingGroup.key,
            text: pagingGroup.text,
            value: pagingGroup.key,
          };
        });
      }
    });

    // If user does not have admin rights (i.e. just a user), but is PGO, then add
    // the paging groups they own to the filter
    Object.entries(pagingGroupsOwned).forEach((entry) => {
      if (
        adminAgencies.indexOf(entry[0]) === -1 &&
        entry[0] !== "AV" &&
        entry[1].length > 0
      ) {
        // Adding agency to filter
        userAgenciesOption.push({
          key: userAgenciesOption.length,
          text: entry[0],
          value: entry[0],
        });

        // Adding paging groups to filter
        pagingGroups[entry[0]] = entry[1];
        pagingGroups[entry[0]].forEach((pagingGroup) => {
          allUserPagingGroups[pagingGroup.key] = {
            key: pagingGroup.key,
            text: pagingGroup.text,
            value: pagingGroup.key,
          };
        });
      }
    });

    // Add personal paging numbers to the paging group filter options
    userAgenciesOption.forEach((agency, index) => {
      // For each of the agency, add all of the personal paging numbers
      if (pagingGroupsPartOf) {
        pagingGroupsPartOf.all.forEach((personalPagingGroup, index) => {
          // Each agency will have the same set of personal paging groups
          // So whatever agency the user selects in Reports, they will see all the personal paging numbers
          if (pagingGroups[agency.value]) {
            var alreadyExsists = pagingGroups[agency.value].findIndex(
              (x) => x.key === personalPagingGroup.key
            );
            if (alreadyExsists === -1) {
              pagingGroups[agency.value].push({
                key: personalPagingGroup.key,
                text: personalPagingGroup.text,
                value: personalPagingGroup.value,
              });
            }
          }
        });
      }
    });

    // If user is trying to generate an availability report and the user only has access to
    // one agency, trigger the function to get qualifications for that agency
    if (
      this.state.reportType === "AVAILABILITY" &&
      userAgenciesOption.length === 1
    ) {
      this.selectAgency(userAgenciesOption[0].value);
    }

    this.setState({
      userAgenciesOption: userAgenciesOption ? userAgenciesOption : [],
      userPagingGroupsOption: pagingGroups ? pagingGroups : [],
      allUserPagingGroups: allUserPagingGroups,
    });
  };

  renderAgencies() {
    if (
      this.state.userAgenciesOption.length === 1 &&
      this.state.searchData.agency[0] !== this.state.userAgenciesOption[0].value
    ) {
      this.setState((prevState) => ({
        ...prevState,
        searchData: {
          ...prevState.searchData,
          agency: [this.state.userAgenciesOption[0].value],
        },
      }));
    }

    let userAgencies = this.state.userAgenciesOption;
    if (
      this.state.reportType === "EVENT_OVERVIEW" ||
      this.state.reportType === "AVAILABILITY"
    ) {
      userAgencies.forEach((agency, index) => {
        if (agency.text === "AV") {
          userAgencies.splice(index, 1);
        }
      });
    }

    return (
      <Wrapper>
        {userAgencies.length > 0 ? (
          <Wrapper>
            {this.state.multiAgency === true && (
              <span
                aria-label="Multiple agencies selected"
                className="multi"
                onClick={this.changeBack}
              >
                <span>{copy.searchAndFilter.multipleSelected}</span>
                <i aria-hidden="true" className="dropdown icon"></i>
              </span>
            )}
            <Dropdown
              id="AgencySelect"
              className={
                styles.pagingGroupDropdown +
                " " +
                (this.state.AgencyError && styles.error + " error")
              }
              placeholder="Agencies"
              fluid
              clearable
              search
              selection
              value={
                this.state.searchData.agency.length === 0
                  ? ""
                  : this.state.searchData.agency[0]
              }
              options={userAgencies}
              onChange={(e, { value }) => this.selectAgency(value)}
            />
          </Wrapper>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              maxWidth: "670px",
            }}
          >
            <Loading />
          </div>
        )}
      </Wrapper>
    );
  }

  renderPagingGroups() {
    let pagingGroupOption = [];
    // If no agencies has been selected, then all paging groups should be selectable
    if (this.state.searchData && this.state.searchData.agency.length === 0) {
      this.props.userAgencies &&
        this.props.userAgencies.forEach((agency) => {
          if (agency !== "AV" && this.state.userPagingGroupsOption[agency]) {
            pagingGroupOption = pagingGroupOption.concat(
              this.state.userPagingGroupsOption[agency]
            );
          }
        });
    } else if (
      this.state.searchData &&
      this.state.userPagingGroupsOption[this.state.searchData.agency]
    ) {
      // If agencies have been selected, only the paging groups from the agencies should be selectable
      // Go through all agencies the user is part of and add the paging groups to the search data
      pagingGroupOption = pagingGroupOption.concat(
        this.state.userPagingGroupsOption[this.state.searchData.agency]
      );
    }

    // Add personal paging groups to filter data
    if (this.state.userPagingGroupsOption.all) {
      pagingGroupOption = pagingGroupOption.concat(
        this.state.userPagingGroupsOption.all
      );
    }

    if (
      pagingGroupOption &&
      pagingGroupOption.length === 1 &&
      this.state.searchData.pagingNumber[0] &&
      this.state.searchData.pagingNumber[0] !== pagingGroupOption[0].value
    ) {
      this.setState((prevState) => ({
        ...prevState,
        searchData: {
          ...prevState.searchData,
          pagingNumber: [pagingGroupOption[0].value],
        },
      }));
    }

    return (
      <Wrapper>
        {
          pagingGroupOption.length > 0 &&
            // (this.state.reportType === "AVAILABILITY" ? (
            //   <Dropdown
            //     className={
            //       styles.pagingGroupDropdown +
            //       " " +
            //       (this.state.PagingGroupsError && styles.error + " error")
            //     }
            //     placeholder="Paging Groups"
            //     fluid
            //     search
            //     selection
            //     value={
            //       this.state.searchData.pagingNumber.length === 0
            //         ? ""
            //         : this.state.searchData.pagingNumber
            //     }
            //     options={pagingGroupOption}
            //     onChange={(e, { value }) => {
            //       this.setState(
            //         (prevState) => ({
            //           ...prevState,
            //           searchData: {
            //             ...prevState.searchData,
            //             pagingNumber: value,
            //           },
            //         }),
            //         () => {
            //           this.selectPagingGroup(value);
            //         }
            //       );
            //     }}
            //   />
            // ) : this.state.reportType === "USERS" ? (
            (this.state.searchData.agency.length ? (
              this.state.reportType === "EVENT_OVERVIEW" ? (
                <Dropdown
                  className={
                    styles.pagingGroupDropdown +
                    " " +
                    (this.state.PagingGroupsError && styles.error + " error")
                  }
                  placeholder="Paging Groups"
                  fluid
                  multiple
                  search
                  selection
                  value={this.state.searchData.pagingNumber}
                  options={pagingGroupOption}
                  onChange={(e, { value }) => {
                    this.setState(
                      (prevState) => ({
                        ...prevState,
                        searchData: {
                          ...prevState.searchData,
                          pagingNumber: value,
                        },
                      }),
                      () => {
                        this.selectPagingGroup(value);
                      }
                    );
                  }}
                />
              ) : (
                <Dropdown
                  className={
                    styles.pagingGroupDropdown +
                    " " +
                    (this.state.PagingGroupsError && styles.error + " error")
                  }
                  placeholder="Paging Group"
                  fluid
                  // multiple
                  search
                  selection
                  value={
                    this.state.searchData.pagingNumber.length === 0
                      ? ""
                      : this.state.searchData.pagingNumber
                  }
                  options={pagingGroupOption}
                  onChange={(e, { value }) => {
                    this.setState(
                      (prevState) => ({
                        ...prevState,
                        searchData: {
                          ...prevState.searchData,
                          pagingNumber: value,
                        },
                      }),
                      () => {
                        this.selectPagingGroup(value);
                      }
                    );
                  }}
                />
              )
            ) : (
              <div className={styles.disabledDropdown}>
                <Dropdown
                  className={
                    styles.pagingGroupDropdown +
                    " " +
                    (this.state.PagingGroupsError && styles.error + " error")
                  }
                  placeholder="An agency must be selected"
                  fluid
                  search
                  selection
                />
              </div>
            ))
          // ) : (
          //   <Dropdown
          //     className={
          //       styles.pagingGroupDropdown +
          //       " " +
          //       (this.state.PagingGroupsError && styles.error + " error")
          //     }
          //     placeholder="Paging Groups"
          //     fluid
          //     multiple
          //     search
          //     selection
          //     value={this.state.searchData.pagingNumber}
          //     options={pagingGroupOption}
          //     onChange={(e, { value }) => {
          //       this.setState(
          //         (prevState) => ({
          //           ...prevState,
          //           searchData: {
          //             ...prevState.searchData,
          //             pagingNumber: value,
          //           },
          //         }),
          //         () => {
          //           this.selectPagingGroup(value);
          //         }
          //       );
          //     }}
          //   />
          // ))}
        }
      </Wrapper>
    );
  }

  getQualifications() {
    if (this.state.searchData.agency.length) {
      let agency = this.state.searchData.agency[0];

      // if the agency's qualifications list already exists in allQualifications, populate dropdown with that
      if (
        this.state.allQualifications &&
        this.state.allQualifications[agency]
      ) {
        let qualificationList = [...this.state.allQualifications[agency]];
        this.setState({
          qualificationList: qualificationList,
        });
      } else {
        // otherwise, obtain agency's qualification list
        agency = {
          agency: this.state.searchData.agency,
        };

        this.Requests.callAPI(
          this.Requests.getUserQualificationsAgency,
          agency
        ).then((data) => {
          if (data && data.status && data.status === 200) {
            let qualifications = data.data;
            let qualificationList = [];

            for (let i = 0; i < qualifications.length; i++) {
              qualificationList.push({
                key: qualifications[i].code,
                value: qualifications[i].id,
                text: qualifications[i].name,
              });
            }
            this.setState({
              qualificationList: qualificationList,
              APICallFailed: false,
            });
          } else {
            let ErrorMessage =
              copy.report.getAvailQualInfoAPIErrorMessage +
              ` (Error #${copy.errorCodes.getAvailQualInfoAPIErrorMessage})`;
            // if (data && data.data && data.data.SASMessageClient) {
            //   ErrorMessage = data.data.SASMessageClient;
            // }
            this.setState(
              { errorMessage: ErrorMessage, APICallFailed: true },
              () => {
                setTimeout(
                  function () {
                    this.setState({ errorMessage: null });
                  }.bind(this),
                  5000
                );
              }
            );
          }
        });
      }
    }
  }

  // Called when a user selects a qualification
  selectQual = (value) => {
    // reset data shown
    this.setState({ qualificationID: [], QualificationsError: false }, () => {
      // set new paging group
      this.setState({ qualificationID: [value] });
    });
    // This send the paging group id to the calendar component to render the calendar view
  };

  // Called when a user selects a type
  selectType = (value) => {
    // reset data shown
    this.setState({ typeID: [], TypeError: false }, () => {
      // set new paging group
      this.setState({ typeID: [value] });
    });
    // This send the paging group id to the calendar component to render the calendar view
  };

  /**
   * Called when user selects an agency
   * @param {String} value - agency that the user has selected
   */
  selectAgency = (value) => {
    this.setState(
      (prevState) => ({
        ...prevState,
        searchData: {
          ...prevState.searchData,
          agency: value === "" ? [] : [value],
          pagingNumber: [],
        },
        AgencyError: false,
        PagingGroups: [],
      }),
      () => {
        this.getQualifications();
      }
    );

    // Changing paging group filter if there are currently selections and the agency filter was not cleared
    if (this.state.searchData.pagingNumber.length !== 0 && value !== "") {
      let tempPagingNumbers = [];
      // Any paging groups which are not from the selected agency should be removed
      this.state.userPagingGroupsOption[value].forEach((pagingGroup) => {
        let index = this.state.searchData.pagingNumber.indexOf(
          pagingGroup.value
        );
        if (index !== -1) {
          tempPagingNumbers.push(pagingGroup.value);
        }
      });

      this.setState((prevState) => ({
        ...prevState,
        searchData: {
          ...prevState.searchData,
          pagingNumber: tempPagingNumbers,
        },
      }));

      // Call selectPagingGroup function again to get the updated list of users
      this.selectPagingGroup(tempPagingNumbers);
    }
  };

  /**
   * Called when user selects a paging group
   */
  selectPagingGroup = (value) => {
    this.setState(
      {
        PagingGroups:
          this.state.reportType === "EVENT_OVERVIEW" ? value : [value],
        PagingGroupsError: false,
      },
      () => {
        // Make an API call to update user options every time a paging group is selected
        if (
          (this.state.reportType === "USERS" ||
            this.state.reportType === "ASN") &&
          this.state.PagingGroups.length !== 0
        ) {
          this.Requests.callAPI(
            this.Requests.getPagingGroupMembers,
            this.state.PagingGroups
          ).then((data) => {
            if (data && data.status && data.status === 200) {
              let userOption = [];
              let users = data.data ? data.data.users : [];
              users.forEach((user) => {
                userOption.push({
                  key: user.user.userId,
                  text: user.user.name,
                  value: user.user.userId,
                });
              });
              this.setState({ userOption: userOption }, () => {
                if (
                  this.state.reportType === "USERS" ||
                  this.state.reportType === "ASN"
                ) {
                  // Changing user filter if there are currently selections
                  if (this.state.UsersId && this.state.UsersId.length !== 0) {
                    let tempUsersId = [];

                    // Any users which are not from the selected paging groups are removed
                    this.state.userOption.forEach((user) => {
                      let index = this.state.UsersId.indexOf(user.value);
                      if (index !== -1) {
                        tempUsersId.push(user.value);
                      }
                    });

                    this.setState({ UsersId: tempUsersId });
                  }
                }
              });
            } else {
              let ErrorMessage =
                copy.report.filter.getUsersAPIErrorMessage +
                ` (Error #${copy.errorCodes.getUsersAPIErrorMessage})`;
              this.setState(
                {
                  errorMessage: ErrorMessage,
                  membersResults: [],
                },
                () => {
                  setTimeout(
                    function () {
                      this.setState({ errorMessage: null });
                    }.bind(this),
                    5000
                  );
                }
              );
            }
          });
        }
      }
    );
  };

  changeAvailabilityType = (type) => {
    let isSelected = !this.state[type];
    this.setState({
      [type]: isSelected,
    });

    // if availability type (type/qual) is not selected, it should be reset
    if (!isSelected) {
      if (type === "Qualifications") {
        this.setState({ qualificationID: [], QualificationsError: false });
      } else {
        this.setState({ typeID: [], TypeError: false });
      }
    }
  };

  // ----------------------------//
  //        EVENT REPORT         //
  // ----------------------------//
  selectEmergency = () => {
    this.setState(
      (prevState) => ({
        Emergency: !prevState.Emergency,
        typeErrorMessage: null,
      }),
      () => {
        if (this.state.Emergency === true) {
          var joined = this.state.type.concat("Emergency");
          this.setState({ type: joined });
        } else {
          var array = [...this.state.type]; // make a separate copy of the array
          var index = array.indexOf("Emergency");
          if (index !== -1) {
            array.splice(index, 1);
            this.setState({ type: array });
          }
        }
      }
    );
  };
  selectNonEmergency = () => {
    this.setState(
      (prevState) => ({
        NonEmergency: !prevState.NonEmergency,
        typeErrorMessage: null,
      }),
      () => {
        if (this.state.NonEmergency === true) {
          var joined = this.state.type.concat("NonEmergency");
          this.setState({ type: joined });
        } else {
          var array = [...this.state.type]; // make a separate copy of the array
          var index = array.indexOf("NonEmergency");
          if (index !== -1) {
            array.splice(index, 1);
            this.setState({ type: array });
          }
        }
      }
    );
  };
  selectAdmin = () => {
    this.setState(
      (prevState) => ({
        Admin: !prevState.Admin,
        typeErrorMessage: null,
      }),
      () => {
        if (this.state.Admin === true) {
          var joined = this.state.type.concat("Admin");
          this.setState({ type: joined });
        } else {
          var array = [...this.state.type]; // make a separate copy of the array
          var index = array.indexOf("Admin");
          if (index !== -1) {
            array.splice(index, 1);
            this.setState({ type: array });
          }
        }
      }
    );
  };

  selectFrequency = (value) => {
    this.setState({
      reportFrequency: value,
      reportFrequencyError: false,
    });
  };

  selectScheduleFileType = (value) => {
    this.setState({
      scheduleFileType: value,
      scheduleFileTypeError: false,
    });
  };

  selectDate = (value) => {
    let dateFormat = "yyyy-MM-dd";

    let todaysDate = new Date();
    let today = new Date(todaysDate);
    today.setDate(today.getDate() + 1);
    let yesterday = format(subDays(today, 1), dateFormat);

    let startDate;
    let endDate;

    if (value === "today") {
      startDate = format(today, dateFormat);

      this.setState({
        dateRangeValue: "today",
        dateStart: yesterday,
        dateEnd: startDate,
        dateStartError: false,
        dateStartObject: null,
        dateEndObject: null,
      });
    } else if (value === "yesterday") {
      let yesterdayStart = format(subDays(today, 2), dateFormat);

      this.setState({
        dateRangeValue: "yesterday",
        dateStart: yesterdayStart,
        dateEnd: yesterday,
        dateStartError: false,
        dateStartObject: null,
        dateEndObject: null,
      });
    } else if (value === "week") {
      startDate = format(subWeeks(today, 1), dateFormat);

      endDate = format(today, dateFormat);

      this.setState({
        dateRangeValue: "week",
        dateStart: startDate,
        dateEnd: endDate,
        dateStartError: false,
        dateStartObject: null,
        dateEndObject: null,
      });
    } else if (value === "fortnight") {
      startDate = format(subWeeks(today, 2), dateFormat);
      endDate = format(today, dateFormat);

      this.setState({
        dateRangeValue: "fortnight",
        dateStart: startDate,
        dateEnd: endDate,
        dateStartError: false,
        dateStartObject: null,
        dateEndObject: null,
      });
    } else if (value === "month") {
      let lastMonth = subMonths(today, 1);

      startDate = format(startOfMonth(lastMonth), dateFormat);
      let endDate = format(endOfMonth(lastMonth), dateFormat);

      this.setState({
        dateRangeValue: "month",
        dateStart: startDate,
        dateEnd: endDate,
        dateStartError: false,
        dateStartObject: null,
        dateEndObject: null,
      });
    }
  };
  selectAttended = () => {
    this.setState(
      (prevState) => ({ Attended: !prevState.Attended }),
      () => {
        if (this.state.Attended === true) {
          var joined = this.state.response.concat("attending");
          this.setState({ response: joined });
        } else {
          var array = [...this.state.response]; // make a separate copy of the array
          var index = array.indexOf("attending");
          if (index !== -1) {
            array.splice(index, 1);
            this.setState({ response: array });
          }
        }
      }
    );
  };
  selectOther = () => {
    this.setState(
      (prevState) => ({ Other: !prevState.Other }),
      () => {
        if (this.state.Other === true) {
          var joined = this.state.response.concat("other");
          this.setState({ response: joined });
        } else {
          var array = [...this.state.response]; // make a separate copy of the array
          var index = array.indexOf("other");
          if (index !== -1) {
            array.splice(index, 1);
            this.setState({ response: array });
          }
        }
      }
    );
  };
  selectUnavailable = () => {
    this.setState(
      (prevState) => ({ Unavailable: !prevState.Unavailable }),
      () => {
        if (this.state.Unavailable === true) {
          var joined = this.state.response.concat("unavailable");
          this.setState({ response: joined });
        } else {
          var array = [...this.state.response]; // make a separate copy of the array
          var index = array.indexOf("unavailable");
          if (index !== -1) {
            array.splice(index, 1);
            this.setState({ response: array });
          }
        }
      }
    );
  };
  selectNoResponse = () => {
    this.setState(
      (prevState) => ({ NoResponse: !prevState.NoResponse }),
      () => {
        if (this.state.NoResponse === true) {
          var joined = this.state.response.concat("no_Response");
          this.setState({ response: joined });
        } else {
          var array = [...this.state.response]; // make a separate copy of the array
          var index = array.indexOf("no_Response");
          if (index !== -1) {
            array.splice(index, 1);
            this.setState({ response: array });
          }
        }
      }
    );
  };

  scheduleReport = () => {
    this.setState({ exsistingScheduledReports: null }, () => {
      if (this.state.reportType === "EVENT_OVERVIEW") {
        /* DOWNLOAD EVENT REPORT */
        if (
          this.state.searchData.agency.length === 0 ||
          !this.state.PagingGroups.length ||
          !this.state.type.length ||
          this.state.dateStart === "" ||
          !this.state.reportFrequency ||
          !this.state.scheduleFileType ||
          !this.state.scheduleStartDate
          // || !document.getElementById("emailId").value
        ) {
          let ErrorMessage = copy.report.submitReportError;

          if (this.state.searchData.agency.length === 0) {
            this.setState({
              AgencyError: true,
            });
          } else {
            this.setState({
              AgencyError: false,
            });
          }

          if (this.state.PagingGroups.length === 0) {
            this.setState({
              PagingGroupsError: true,
            });
          } else {
            this.setState({
              PagingGroupsError: false,
            });
          }
          if (this.state.type.length === 0) {
            this.setState({
              typeErrorMessage: "Please select one or more Alert Types",
            });
          } else {
            this.setState({
              typeErrorMessage: null,
            });
          }
          if (this.state.dateRangeValue === "") {
            this.setState({
              dateStartError: true,
            });
          } else {
            this.setState({
              dateStartError: false,
            });
          }
          if (this.state.dateStart === "") {
            this.setState({
              dateStartError: true,
            });
          } else {
            this.setState({
              dateStartError: false,
            });
          }
          if (!this.state.reportFrequency) {
            this.setState({
              reportFrequencyError: true,
            });
          } else {
            this.setState({
              reportFrequencyError: false,
            });
          }
          if (!this.state.scheduleFileType) {
            this.setState({
              scheduleFileTypeError: true,
            });
          } else {
            this.setState({
              scheduleFileTypeError: false,
            });
          }
          if (!this.state.scheduleStartDate) {
            this.setState({
              scheduleStartDateError: true,
            });
          } else {
            this.setState({
              scheduleStartDateError: false,
            });
          }
          // if (!document.getElementById("emailId").value) {
          //   this.setState({
          //     emailAddressError: true,
          //   });
          // } else {
          //   this.setState({
          //     emailAddressError: false,
          //   });
          // }

          this.setState(
            {
              ValidationErrorMessage: ErrorMessage,
              successful: false,
              existingScheduleReportsError: false,
            },
            () => {
              setTimeout(
                function () {
                  this.setState({
                    ValidationErrorMessage: null,
                    successful: null,
                  });
                }.bind(this),
                5000
              );
            }
          );
          this.getScheduledReports();
        } else {
          let reportType = this.state.reportType,
            reportFrequency = this.state.reportFrequency,
            scheduleFileType = this.state.scheduleFileType;

          let startDateVal = "";
          if (this.state.scheduleStartDate !== null) {
            startDateVal = this.state.scheduleStartDate;
          }

          let emailAddressVal = "";
          if (
            this.state.searchData &&
            this.state.searchData.agency &&
            this.state.userEmails &&
            this.state.userEmails[this.state.searchData.agency]
          ) {
            emailAddressVal =
              this.state.userEmails[this.state.searchData.agency];
          }

          let msgBody = {
            startDate: startDateVal,
            emailAddress: emailAddressVal,
            reportTypeModel: {
              dateTimeAfterOrEqual: new Date(
                this.state.dateStart
              ).toISOString(),
              dateTimeBeforeOrEqual: new Date(this.state.dateEnd).toISOString(),
              messageType: this.state.type,
              pagingNumber: this.state.PagingGroups,
              attendanceStatus: this.state.response,
            },
          };
          this.Requests.callAPI(
            this.Requests.setReportSchedule,
            msgBody,
            reportType,
            reportFrequency,
            scheduleFileType
          ).then((data) => {
            if (data && data.status && data.status === 200) {
              // Fetch the new list of scheduled reports
              this.getScheduledReports();
            } else {
              let ErrorMessage =
                copy.report.createScheduleReportError +
                ` (Error #${copy.errorCodes.createScheduleReportError})`;
              // if (data && data.data && data.data.SASMessageClient) {
              //   ErrorMessage = data.data.SASMessageClient;
              // }
              this.setState(
                {
                  errorMessage: ErrorMessage,
                  existingScheduleReportsError: true,
                },
                () => {
                  setTimeout(
                    function () {
                      this.setState({ errorMessage: null });
                    }.bind(this),
                    5000
                  );
                }
              );
            }
          });
        }
      } else if (this.state.reportType === "USERS") {
        /* DOWNLOAD USER REPORT */
        if (
          this.state.searchData.agency.length === 0 ||
          !this.state.reportFrequency ||
          !this.state.scheduleFileType ||
          !this.state.scheduleStartDate
          // || !document.getElementById("emailId").value
        ) {
          let ErrorMessage = copy.report.submitReportError;
          if (this.state.searchData.agency.length === 0) {
            this.setState({
              AgencyError: true,
            });
          } else {
            this.setState({
              AgencyError: false,
            });
          }

          if (!this.state.reportFrequency) {
            this.setState({
              reportFrequencyError: true,
            });
          } else {
            this.setState({
              reportFrequencyError: false,
            });
          }
          if (!this.state.scheduleFileType) {
            this.setState({
              scheduleFileTypeError: true,
            });
          } else {
            this.setState({
              scheduleFileTypeError: false,
            });
          }
          if (!this.state.scheduleStartDate) {
            this.setState({
              scheduleStartDateError: true,
            });
          } else {
            this.setState({
              scheduleStartDateError: false,
            });
          }
          // if (!document.getElementById("emailId").value) {
          //   this.setState({
          //     emailAddressError: true,
          //   });
          // } else {
          //   this.setState({
          //     emailAddressError: false,
          //   });
          // }

          this.setState(
            {
              ValidationErrorMessage: ErrorMessage,
              successful: false,
              existingScheduleReportsError: false,
            },
            () => {
              setTimeout(
                function () {
                  this.setState({
                    ValidationErrorMessage: null,
                    successful: null,
                  });
                }.bind(this),
                5000
              );
            }
          );
          this.getScheduledReports();
        } else {
          let reportType = this.state.reportType,
            reportFrequency = this.state.reportFrequency,
            scheduleFileType = this.state.scheduleFileType;

          let startDateVal = "";
          if (this.state.scheduleStartDate !== null) {
            startDateVal = this.state.scheduleStartDate;
          }

          let emailAddressVal = "";
          if (
            this.state.searchData &&
            this.state.searchData.agency &&
            this.state.userEmails &&
            this.state.userEmails[this.state.searchData.agency]
          ) {
            emailAddressVal =
              this.state.userEmails[this.state.searchData.agency];
          }

          let msgBody = {
            startDate: startDateVal,
            emailAddress: emailAddressVal,
            reportTypeModel: {
              usersId: this.state.UsersId.length ? this.state.UsersId : null,
              agency: this.state.searchData.agency
                ? this.state.searchData.agency
                : null,
              pagingNumber: this.state.PagingGroups.length
                ? this.state.PagingGroups
                : null,
            },
          };
          this.Requests.callAPI(
            this.Requests.setReportSchedule,
            msgBody,
            reportType,
            reportFrequency,
            scheduleFileType
          ).then((data) => {
            if (data && data.status && data.status === 200) {
              // Fetch the new list of scheduled reports
              this.getScheduledReports();
            } else {
              let ErrorMessage =
                copy.report.createScheduleReportError +
                ` (Error #${copy.errorCodes.createScheduleReportError})`;
              // if (data && data.data && data.data.SASMessageClient) {
              //   ErrorMessage = data.data.SASMessageClient;
              // }
              this.setState(
                {
                  errorMessage: ErrorMessage,
                  existingScheduleReportsError: true,
                },
                () => {
                  setTimeout(
                    function () {
                      this.setState({ errorMessage: null });
                    }.bind(this),
                    5000
                  );
                }
              );
            }
          });
        }
      } else if (this.state.reportType === "ASN") {
        /* SCHEDULE ASN REPORT */
        if (
          !this.state.asnInput ||
          !this.state.reportFrequency ||
          !this.state.scheduleFileType ||
          !this.state.scheduleStartDate
        ) {
          let ErrorMessage = copy.report.submitReportError;
          if (!this.state.asnInput) {
            this.setState({
              AsnError: true,
            });
          } else {
            this.setState({
              AsnError: false,
            });
          }
          if (!this.state.reportFrequency) {
            this.setState({
              reportFrequencyError: true,
            });
          } else {
            this.setState({
              reportFrequencyError: false,
            });
          }
          if (!this.state.scheduleFileType) {
            this.setState({
              scheduleFileTypeError: true,
            });
          } else {
            this.setState({
              scheduleFileTypeError: false,
            });
          }
          if (!this.state.scheduleStartDate) {
            this.setState({
              scheduleStartDateError: true,
            });
          } else {
            this.setState({
              scheduleStartDateError: false,
            });
          }
          this.setState(
            {
              ValidationErrorMessage: ErrorMessage,
              successful: false,
              existingScheduleReportsError: false,
            },
            () => {
              setTimeout(
                function () {
                  this.setState({
                    ValidationErrorMessage: null,
                    successful: null,
                    AsnError: false,
                  });
                }.bind(this),
                5000
              );
            }
          );
          this.getScheduledReports();
        } else {
          let reportType = this.state.reportType,
            reportFrequency = this.state.reportFrequency,
            scheduleFileType = this.state.scheduleFileType;

          let startDateVal = "";
          if (this.state.scheduleStartDate !== null) {
            startDateVal = this.state.scheduleStartDate;
          }

          let emailAddressVal = "";
          if (
            this.state.searchData &&
            this.state.searchData.agency &&
            this.state.userEmails &&
            this.state.userEmails[this.state.searchData.agency]
          ) {
            emailAddressVal =
              this.state.userEmails[this.state.searchData.agency];
          }
          //as API takes ASN string in a list.
          let asnList = [];
          if (this.state.asnInput) asnList.push(this.state.asnInput);

          let msgBody = {
            startDate: startDateVal,
            emailAddress: emailAddressVal,
            reportTypeModel: {
              usersId:
                this.state.UsersId !== null && this.state.UsersId.length
                  ? this.state.UsersId
                  : null,
              agency: this.state.searchData.agency
                ? this.state.searchData.agency
                : null,
              pagingNumber:
                this.state.PagingGroups !== null &&
                this.state.PagingGroups.length
                  ? this.state.PagingGroups
                  : null,
              asn: asnList,
            },
          };
          this.Requests.callAPI(
            this.Requests.setReportSchedule,
            msgBody,
            reportType,
            reportFrequency,
            scheduleFileType
          ).then((data) => {
            if (data && data.status && data.status === 400) {
              this.setState({ isDownloadingReport: false });
              //get error messages and error type from API
              if (
                data.data.SASENumErrorCode === 17 ||
                data.data.SASENumErrorCode === 5
              ) {
                this.setState(
                  {
                    invalidASN: copy.settings.profile.invalidASN,
                  },
                  () => {
                    setTimeout(
                      function () {
                        this.setState({
                          invalidASN: false,
                        });
                      }.bind(this),
                      5000
                    );
                  }
                );
              }
              //if error is not due to invalid asn, revert to normal error handling
            } else if (data && data.status && data.status !== 200) {
              let ErrorMessage =
                copy.report.downloadUserReportError +
                ` (Error #${copy.errorCodes.downloadUserReportError})`;
              this.setState(
                {
                  isDownloadingReport: false,
                  errorMessage: ErrorMessage,
                },
                () => {
                  setTimeout(
                    function () {
                      this.setState({ errorMessage: null });
                    }.bind(this),
                    5000
                  );
                }
              );
            }
            // Fetch the new list of scheduled reports
            this.getScheduledReports();
          });
        }
      }
      if (this.state.reportType === "AVAILABILITY") {
        /* DOWNLOAD AVAILABILITY REPORT */
        if (
          this.state.searchData.agency.length === 0 ||
          !this.state.PagingGroups.length ||
          this.state.dateStart === "" ||
          !this.state.reportFrequency ||
          !this.state.scheduleFileType ||
          (this.state.Qualifications && !this.state.qualificationID.length) ||
          (this.state.Type && !this.state.typeID.length) ||
          !this.state.scheduleStartDate
          // || !document.getElementById("emailId").value
        ) {
          let ErrorMessage = copy.report.submitReportError;

          if (this.state.searchData.agency.length === 0) {
            this.setState({
              AgencyError: true,
            });
          } else {
            this.setState({
              AgencyError: false,
            });
          }

          if (this.state.PagingGroups.length === 0) {
            this.setState({
              PagingGroupsError: true,
            });
          } else {
            this.setState({
              PagingGroupsError: false,
            });
          }

          if (this.state.Qualifications && !this.state.qualificationID.length) {
            this.setState({
              QualificationsError: true,
            });
          } else {
            this.setState({
              QualificationsError: false,
            });
          }
          if (this.state.Type && !this.state.typeID.length) {
            this.setState({
              TypeError: true,
            });
          } else {
            this.setState({
              TypeError: false,
            });
          }
          if (this.state.dateRangeValue === "") {
            this.setState({
              dateStartError: true,
            });
          } else {
            this.setState({
              dateStartError: false,
            });
          }
          if (this.state.dateStart === "") {
            this.setState({
              dateStartError: true,
            });
          } else {
            this.setState({
              dateStartError: false,
            });
          }
          if (!this.state.reportFrequency) {
            this.setState({
              reportFrequencyError: true,
            });
          } else {
            this.setState({
              reportFrequencyError: false,
            });
          }
          if (!this.state.scheduleFileType) {
            this.setState({
              scheduleFileTypeError: true,
            });
          } else {
            this.setState({
              scheduleFileTypeError: false,
            });
          }
          if (!this.state.scheduleStartDate) {
            this.setState({
              scheduleStartDateError: true,
            });
          } else {
            this.setState({
              scheduleStartDateError: false,
            });
          }
          // if (!document.getElementById("emailId").value) {
          //   this.setState({
          //     emailAddressError: true,
          //   });
          // } else {
          //   this.setState({
          //     emailAddressError: false,
          //   });
          // }

          this.setState(
            {
              ValidationErrorMessage: ErrorMessage,
              successful: false,
              existingScheduleReportsError: false,
            },
            () => {
              setTimeout(
                function () {
                  this.setState({
                    ValidationErrorMessage: null,
                    successful: null,
                  });
                }.bind(this),
                5000
              );
            }
          );
          this.getScheduledReports();
        } else {
          let reportType = this.state.reportType,
            reportFrequency = this.state.reportFrequency,
            scheduleFileType = this.state.scheduleFileType;

          let startDateVal = "";
          if (this.state.scheduleStartDate) {
            startDateVal = this.state.scheduleStartDate;
          }

          let emailAddressVal = "";
          if (
            this.state.searchData &&
            this.state.searchData.agency &&
            this.state.userEmails &&
            this.state.userEmails[this.state.searchData.agency]
          ) {
            emailAddressVal =
              this.state.userEmails[this.state.searchData.agency];
          }

          let msgBody = {
            startDate: startDateVal,
            emailAddress: emailAddressVal,
            reportTypeModel: {
              dateTimeAfterOrEqual: new Date(
                this.state.dateStart
              ).toISOString(),
              dateTimeBeforeOrEqual: new Date(this.state.dateEnd).toISOString(),
              agency: this.state.searchData.agency
                ? this.state.searchData.agency
                : null,
              pagingNumbers: this.state.PagingGroups
                ? this.state.PagingGroups
                : null,
              qualificationIds: this.state.qualificationID
                ? this.state.qualificationID
                : null,
              availabilityTypes: this.state.typeID.length
                ? this.state.typeID
                : null,
            },
          };

          this.Requests.callAPI(
            this.Requests.setReportSchedule,
            msgBody,
            reportType,
            reportFrequency,
            scheduleFileType
          ).then((data) => {
            if (data && data.status && data.status === 200) {
              // Fetch the new list of scheduled reports
              this.getScheduledReports();
            } else {
              let ErrorMessage =
                copy.report.createScheduleReportError +
                ` (Error #${copy.errorCodes.createScheduleReportError})`;
              // if (data && data.data && data.data.SASMessageClient) {
              //   ErrorMessage = data.data.SASMessageClient;
              // }
              this.setState(
                {
                  errorMessage: ErrorMessage,
                  existingScheduleReportsError: true,
                },
                () => {
                  setTimeout(
                    function () {
                      this.setState({ errorMessage: null });
                    }.bind(this),
                    5000
                  );
                }
              );
            }
          });
        }
      } else if (this.state.reportType === "UNREGISTERED_USERS") {
        /* DOWNLOAD UNREGISTERED USERS REPORT */
        if (
          this.state.searchData.agency.length === 0 ||
          !this.state.reportFrequency ||
          !this.state.scheduleFileType ||
          !this.state.scheduleStartDate
        ) {
          let ErrorMessage = copy.report.submitReportError;
          if (this.state.searchData.agency.length === 0) {
            this.setState({
              AgencyError: true,
            });
          } else {
            this.setState({
              AgencyError: false,
            });
          }

          if (!this.state.reportFrequency) {
            this.setState({
              reportFrequencyError: true,
            });
          } else {
            this.setState({
              reportFrequencyError: false,
            });
          }
          if (!this.state.scheduleFileType) {
            this.setState({
              scheduleFileTypeError: true,
            });
          } else {
            this.setState({
              scheduleFileTypeError: false,
            });
          }
          if (!this.state.scheduleStartDate) {
            this.setState({
              scheduleStartDateError: true,
            });
          } else {
            this.setState({
              scheduleStartDateError: false,
            });
          }
          this.setState(
            {
              ValidationErrorMessage: ErrorMessage,
              successful: false,
            },
            () => {
              setTimeout(
                function () {
                  this.setState({
                    ValidationErrorMessage: null,
                    successful: null,
                    existingScheduleReportsError: false,
                  });
                }.bind(this),
                5000
              );
            }
          );
          this.getScheduledReports();
        } else {
          let reportType = this.state.reportType,
            reportFrequency = this.state.reportFrequency,
            scheduleFileType = this.state.scheduleFileType;

          let startDateVal = "";
          if (this.state.scheduleStartDate !== null) {
            startDateVal = this.state.scheduleStartDate;
          }

          let emailAddressVal = "";
          if (
            this.state.searchData &&
            this.state.searchData.agency &&
            this.state.userEmails &&
            this.state.userEmails[this.state.searchData.agency]
          ) {
            emailAddressVal =
              this.state.userEmails[this.state.searchData.agency];
          }

          let msgBody = {
            startDate: startDateVal,
            emailAddress: emailAddressVal,
            reportTypeModel: {
              agency: this.state.searchData.agency
                ? this.state.searchData.agency
                : null,
            },
          };
          this.Requests.callAPI(
            this.Requests.setReportSchedule,
            msgBody,
            reportType,
            reportFrequency,
            scheduleFileType
          ).then((data) => {
            if (data && data.status && data.status === 200) {
              // Fetch the new list of scheduled reports
              this.getScheduledReports();
            } else {
              let ErrorMessage =
                copy.report.createScheduleReportError +
                ` (Error #${copy.errorCodes.createScheduleReportError})`;
              this.setState(
                {
                  errorMessage: ErrorMessage,
                  existingScheduleReportsError: true,
                },
                () => {
                  setTimeout(
                    function () {
                      this.setState({ errorMessage: null });
                    }.bind(this),
                    5000
                  );
                }
              );
            }
          });
        }
      }
    });
  };

  // this will automatically set attendance response to 'No Response' if report is an AV Event Overview
  checkAVEventOverview = () => {
    if (
      this.state.searchData &&
      this.state.searchData.agency &&
      this.state.searchData.agency[0] &&
      this.state.searchData.agency[0] === "AV" &&
      this.state.reportType === "EVENT_OVERVIEW" &&
      this.state.NoResponse === false
    ) {
      this.selectNoResponse();
    }
  };

  DownloadCSV = () => {
    // localStorage.setItem("fileType", "CSV");
    this.setState({
      fileType: "CSV",
    });

    this.checkAVEventOverview();
    this.handleSubmit.bind(this);
  };

  DownloadPDF = () => {
    localStorage.setItem("fileType", "PDF");
    this.setState({
      fileType: "PDF",
    });

    this.checkAVEventOverview();
    this.handleSubmit.bind(this);
  };

  // This will create the placeholder for the input fields with the type date
  onFocus = () => {
    this.setState({
      dateType: "date",
    });
  };

  onBlurScheduleStartDate = () => {
    if (this.state.scheduleStartDate && this.state.scheduleStartDate === "") {
      this.setState({
        dateType: "text",
      });
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();

    if (this.state.reportType === "EVENT_OVERVIEW") {
      /* DOWNLOAD EVENT REPORT */
      if (
        this.state.searchData.agency.length === 0 ||
        this.state.PagingGroups.length === 0 ||
        this.state.type.length === 0 ||
        this.state.dateStart === ""
      ) {
        let ErrorMessage = copy.report.missingField;

        if (this.state.searchData.agency.length === 0) {
          this.setState({
            AgencyError: true,
          });
        } else {
          this.setState({
            AgencyError: false,
          });
        }
        if (this.state.PagingGroups.length === 0) {
          this.setState({
            PagingGroupsError: true,
          });
        } else {
          this.setState({
            PagingGroupsError: false,
          });
        }
        if (this.state.type.length === 0) {
          this.setState({
            typeErrorMessage: "Please select one or more Alert Types",
          });
        } else {
          this.setState({
            typeErrorMessage: null,
          });
        }
        if (this.state.dateStart === "") {
          this.setState({
            dateStartError: true,
          });
        } else {
          this.setState({
            dateStartError: false,
          });
        }

        this.setState(
          {
            reportFrequencyError: false,
            scheduleFileTypeError: false,
            scheduleStartDateError: false,
            emailAddressError: false,
            ValidationErrorMessage: ErrorMessage,
            successful: false,
          },
          () => {
            setTimeout(
              function () {
                this.setState({
                  ValidationErrorMessage: null,
                  successful: null,
                });
              }.bind(this),
              5000
            );
          }
        );
      } else {
        // localStorage.setItem("report", "event");
        let dateTimeAfterOrEqual = new Date(this.state.dateStart).toISOString();
        let dateTimeBeforeOrEqual = new Date(this.state.dateEnd).toISOString();
        let messageType = this.state.type;
        let pagingNumber = this.state.PagingGroups;
        let attendanceStatus = this.state.response;

        // Test data - to use comment out validation
        // let dateTimeAfterOrEqual = "2020-03-01",
        //   dateTimeBeforeOrEqual = "2020-03-31",
        //   messageType = ["Emergency", "NonEmergency", "Admin"],
        //   pagingNumber = [20000],
        //   attendanceStatus = ["no_Response"];

        // Save report criteria to local storage
        // Stored separately because nested object breaks the call to the API
        // localStorage.setItem("dateTimeAfterOrEqual", dateTimeAfterOrEqual);
        // localStorage.setItem("dateTimeBeforeOrEqual", dateTimeBeforeOrEqual);
        // localStorage.setItem("messageType", JSON.stringify(messageType));
        // localStorage.setItem("pagingNumber", JSON.stringify(pagingNumber));
        // localStorage.setItem(
        //   "attendanceStatus",
        //   JSON.stringify(attendanceStatus)
        // );
        this.setState(
          {
            report: "event",
            dateTimeAfterOrEqual: dateTimeAfterOrEqual,
            dateTimeBeforeOrEqual: dateTimeBeforeOrEqual,
            messageType: JSON.stringify(messageType),
            pagingNumber: JSON.stringify(pagingNumber),
            attendanceStatus: JSON.stringify(attendanceStatus),
          },
          () => {
            this.handleDownload();
          }
        );
        // route to new page to download
        // window.open("#/dashboard/report/download", "_blank");
      }
    } else if (this.state.reportType === "USERS") {
      /* DOWNLOAD USER REPORT */
      if (!this.state.searchData.agency.length) {
        let ErrorMessage = copy.report.missingField;

        if (this.state.searchData.agency.length === 0) {
          this.setState({
            AgencyError: true,
          });
        } else {
          this.setState({
            AgencyError: false,
          });
        }

        this.setState(
          {
            reportFrequencyError: false,
            scheduleFileTypeError: false,
            scheduleStartDateError: false,
            emailAddressError: false,
            ValidationErrorMessage: ErrorMessage,
            successful: false,
          },
          () => {
            setTimeout(
              function () {
                this.setState({
                  ValidationErrorMessage: null,
                  successful: null,
                });
              }.bind(this),
              5000
            );
          }
        );
      } else {
        // localStorage.setItem("report", "user");
        let agency = this.state.searchData.agency;
        let pagingNumber = this.state.PagingGroups;
        let UsersId = this.state.UsersId;

        // Save report criteria to local storage
        // Stored separately because nested object breaks the call to the API
        // localStorage.setItem("agency", JSON.stringify(agency));
        // localStorage.setItem("pagingNumber", JSON.stringify(pagingNumber));
        // localStorage.setItem("UsersId", JSON.stringify(UsersId));

        // route to new page to download
        // window.open("#/dashboard/report/download", "_blank");

        this.setState(
          {
            report: "user",
            agency: JSON.stringify(agency),
            pagingNumber: JSON.stringify(pagingNumber),
            reportUsersId: JSON.stringify(UsersId),
          },
          () => {
            this.handleDownload();
          }
        );
      }
    } else if (this.state.reportType === "ASN") {
      /* DOWNLOAD ASN REPORT */
      if (!this.state.asnInput) {
        let ErrorMessage = copy.report.missingField;
        this.setState({
          AsnError: true,
        });
        this.setState(
          {
            reportFrequencyError: false,
            scheduleFileTypeError: false,
            scheduleStartDateError: false,
            emailAddressError: false,
            ValidationErrorMessage: ErrorMessage,
            successful: false,
          },
          () => {
            setTimeout(
              function () {
                this.setState({
                  ValidationErrorMessage: null,
                  successful: null,
                  AsnError: false,
                });
              }.bind(this),
              5000
            );
          }
        );
      } else {
        let agency = this.state.searchData.agency;
        let pagingNumber = this.state.PagingGroups;
        let UsersId = this.state.UsersId;
        //as API takes ASN string in a list
        let asnList = [];
        if (this.state.asnInput) asnList.push(this.state.asnInput);

        this.setState(
          {
            report: "asn",
            agency: JSON.stringify(agency),
            pagingNumber: JSON.stringify(pagingNumber),
            reportUsersId: JSON.stringify(UsersId),
            asnList: JSON.stringify(asnList),
          },
          () => {
            this.handleDownload();
          }
        );
      }
    } else if (this.state.reportType === "AVAILABILITY") {
      /* DOWNLOAD EVENT REPORT */
      if (
        this.state.searchData.agency.length === 0 ||
        !this.state.PagingGroups.length ||
        (this.state.Qualifications && !this.state.qualificationID.length) ||
        (this.state.Type && !this.state.typeID.length) ||
        this.state.dateStart === ""
      ) {
        let ErrorMessage = copy.report.missingField;

        if (this.state.PagingGroups.length === 0) {
          this.setState({
            PagingGroupsError: true,
          });
        } else {
          this.setState({
            PagingGroupsError: false,
          });
        }
        if (this.state.searchData.agency.length === 0) {
          this.setState({
            AgencyError: true,
          });
        } else {
          this.setState({
            AgencyError: false,
          });
        }
        if (this.state.Qualifications && !this.state.qualificationID.length) {
          this.setState({
            QualificationsError: true,
          });
        } else {
          this.setState({
            QualificationsError: false,
          });
        }
        if (this.state.Type && !this.state.typeID.length) {
          this.setState({
            TypeError: true,
          });
        } else {
          this.setState({
            TypeError: false,
          });
        }
        if (this.state.dateStart === "") {
          this.setState({
            dateStartError: true,
          });
        } else {
          this.setState({
            dateStartError: false,
          });
        }

        this.setState(
          {
            reportFrequencyError: false,
            scheduleFileTypeError: false,
            scheduleStartDateError: false,
            emailAddressError: false,
            ValidationErrorMessage: ErrorMessage,
            successful: false,
          },
          () => {
            setTimeout(
              function () {
                this.setState({
                  ValidationErrorMessage: null,
                  successful: null,
                });
              }.bind(this),
              5000
            );
          }
        );
      } else {
        // localStorage.setItem("report", "availability");
        let dateTimeAfterOrEqual = new Date(this.state.dateStart).toISOString();
        let dateTimeBeforeOrEqual = new Date(this.state.dateEnd).toISOString();
        let agency = this.state.searchData.agency;
        let pagingNumber = this.state.PagingGroups;
        let qualification = this.state.qualificationID;
        let type = this.state.typeID;
        // Test data - to use comment out validation
        // let dateTimeAfterOrEqual = "2020-03-01",
        //   dateTimeBeforeOrEqual = "2020-03-31",
        //   messageType = ["Emergency", "NonEmergency", "Admin"],
        //   pagingNumber = [20000],
        //   attendanceStatus = ["no_Response"];
        // Save report criteria to local storage
        // Stored separately because nested object breaks the call to the API
        // localStorage.setItem("dateTimeAfterOrEqual", dateTimeAfterOrEqual);
        // localStorage.setItem("dateTimeBeforeOrEqual", dateTimeBeforeOrEqual);
        // localStorage.setItem("agency", JSON.stringify(agency));
        // localStorage.setItem("pagingNumbers", JSON.stringify(pagingNumber));
        // localStorage.setItem("qualificationIds", JSON.stringify(qualification));
        // localStorage.setItem("availabilityTypes", JSON.stringify(type));
        // route to new page to download
        // window.open("#/dashboard/report/download", "_blank");
        this.setState(
          {
            report: "availability",
            dateTimeAfterOrEqual: dateTimeAfterOrEqual,
            dateTimeBeforeOrEqual: dateTimeBeforeOrEqual,
            agency: JSON.stringify(agency),
            qualificationIds: JSON.stringify(qualification),
            pagingNumber: JSON.stringify(pagingNumber),
            availabilityTypes: JSON.stringify(type),
          },
          () => {
            this.handleDownload();
          }
        );
      }
    } else if (this.state.reportType === "UNREGISTERED_USERS") {
      /* DOWNLOAD UNREGISTERED USERS REPORT */
      if (this.state.searchData.agency.length === 0) {
        let ErrorMessage = copy.report.missingField;

        if (this.state.searchData.agency.length === 0) {
          this.setState({
            AgencyError: true,
          });
        } else {
          this.setState({
            AgencyError: false,
          });
        }

        this.setState(
          {
            reportFrequencyError: false,
            scheduleFileTypeError: false,
            scheduleStartDateError: false,
            emailAddressError: false,
            ValidationErrorMessage: ErrorMessage,
            successful: false,
            PagingGroupsError: false,
            QualificationsError: false,
            TypeError: false,
            dateStartError: false,
          },
          () => {
            setTimeout(
              function () {
                this.setState({
                  ValidationErrorMessage: null,
                  successful: null,
                });
              }.bind(this),
              5000
            );
          }
        );
      } else {
        let agency = this.state.searchData.agency;
        this.setState(
          {
            report: "unregistered_users",
            agency: JSON.stringify(agency),
          },
          () => {
            this.handleDownload();
          }
        );
      }
    } else {
      return;
    }
  };

  handleDownload = () => {
    // Get stored criterias from local storage
    this.setState(
      {
        isDownloadingReport: true,
      },
      () => {
        let fileType = this.state.fileType ? this.state.fileType : null;
        let UsersId = this.state.reportUsersId
          ? JSON.parse(this.state.reportUsersId)
          : null;
        let pagingNumber = this.state.pagingNumber
          ? JSON.parse(this.state.pagingNumber)
          : null;
        let agency = this.state.agency ? JSON.parse(this.state.agency) : null;
        //as API takes ASN as a list
        let asnList = this.state.asnList
          ? JSON.parse(this.state.asnList)
          : null;

        if (this.state.report !== null) {
          if (this.state.report === "event" && pagingNumber) {
            let details,
              dateTimeAfterOrEqual = this.state.dateTimeAfterOrEqual
                ? this.state.dateTimeAfterOrEqual
                : null,
              dateTimeBeforeOrEqual = this.state.dateTimeBeforeOrEqual
                ? this.state.dateTimeBeforeOrEqual
                : null,
              messageType = this.state.messageType
                ? JSON.parse(this.state.messageType)
                : null,
              pagingNumber = this.state.pagingNumber
                ? JSON.parse(this.state.pagingNumber)
                : null,
              attendanceStatus = this.state.attendanceStatus
                ? JSON.parse(this.state.attendanceStatus)
                : null;

            if (!attendanceStatus) {
              details = {
                agency: agency,
                dateTimeAfterOrEqual: dateTimeAfterOrEqual,
                dateTimeBeforeOrEqual: dateTimeBeforeOrEqual,
                messageType: messageType,
                pagingNumber: pagingNumber,
              };
            } else {
              details = {
                agency: agency,
                dateTimeAfterOrEqual: dateTimeAfterOrEqual,
                dateTimeBeforeOrEqual: dateTimeBeforeOrEqual,
                messageType: messageType,
                pagingNumber: pagingNumber,
                attendanceStatus: attendanceStatus,
              };
            }

            this.Requests.callAPI(
              this.Requests.downloadReport,
              "EVENT_OVERVIEW",
              fileType,
              details
            ).then((data) => {
              if (data && data.status && data.status === 200) {
                let date = format(new Date(), "dd-MM-yyyy");
                let fileName;
                if (data.data.type === "application/pdf") {
                  // This is if you want to show the PDF instead of downloading it
                  const file = new Blob([data.data], {
                    type: "application/pdf",
                  });
                  const fileURL = URL.createObjectURL(file);
                  window.open(fileURL);

                  fileName = "EventReport_" + date + ".pdf";
                } else {
                  fileName = "EventReport_" + date + ".csv";
                }
                this.fileDownload(data.data, fileName);
                this.setState({
                  isDownloadingReport: false,
                  eventReport: null,
                  report: null,
                  fileType: null,
                  pagingNumber: null,
                  reportUsersId: null,
                  dateTimeAfterOrEqual: null,
                  dateTimeBeforeOrEqual: null,
                  messageType: null,
                  attendanceStatus: null,
                });
              } else {
                let ErrorMessage =
                  copy.report.downloadEventOverviewReportError +
                  ` (Error #${copy.errorCodes.downloadEventOverviewReportError})`;
                // if (data && data.data && data.data.SASMessageClient) {
                //   ErrorMessage = data.data.SASMessageClient;
                // }
                this.setState(
                  {
                    isDownloadingReport: false,
                    errorMessage: ErrorMessage,
                  },
                  () => {
                    setTimeout(
                      function () {
                        this.setState({ errorMessage: null });
                      }.bind(this),
                      5000
                    );
                  }
                );
              }
            });
            // remove report criterias from local storage
          } else if (this.state.report === "user") {
            let details = {
              agency: agency,
              usersId: UsersId && UsersId.length ? UsersId : null,
              pagingNumber:
                pagingNumber && pagingNumber.length ? pagingNumber : null,
            };
            this.Requests.callAPI(
              this.Requests.downloadReport,
              "USERS",
              fileType,
              details
            ).then((data) => {
              if (data && data.status && data.status === 200) {
                let date = format(new Date(), "dd-MM-yyyy");
                let fileName;
                if (data.data.type === "application/pdf") {
                  // This is if you want to show the PDF instead of downloading it
                  const file = new Blob([data.data], {
                    type: "application/pdf",
                  });
                  const fileURL = URL.createObjectURL(file);
                  window.open(fileURL);

                  fileName = "UserReport_" + date + ".pdf";
                } else {
                  fileName = "UserReport_" + date + ".csv";
                }
                this.fileDownload(data.data, fileName);
                this.setState({
                  isDownloadingReport: false,
                  eventReport: null,
                  report: null,
                  fileType: null,
                  pagingNumber: null,
                  UsersId: null,
                  dateTimeAfterOrEqual: null,
                  dateTimeBeforeOrEqual: null,
                  messageType: null,
                  attendanceStatus: null,
                });
              } else {
                let ErrorMessage =
                  copy.report.downloadUserReportError +
                  ` (Error #${copy.errorCodes.downloadUserReportError})`;
                // if (data && data.data && data.data.SASMessageClient) {
                //   ErrorMessage = data.data.SASMessageClient;
                // }
                this.setState(
                  {
                    isDownloadingReport: false,
                    errorMessage: ErrorMessage,
                  },
                  () => {
                    setTimeout(
                      function () {
                        this.setState({ errorMessage: null });
                      }.bind(this),
                      5000
                    );
                  }
                );
              }
            });
          } else if (this.state.report === "availability" && agency) {
            let dateTimeAfterOrEqual = this.state.dateTimeAfterOrEqual
                ? this.state.dateTimeAfterOrEqual
                : null,
              dateTimeBeforeOrEqual = this.state.dateTimeBeforeOrEqual
                ? this.state.dateTimeBeforeOrEqual
                : null,
              pagingNumbers = this.state.pagingNumber
                ? JSON.parse(this.state.pagingNumber)
                : null,
              qualificationIds = this.state.qualificationIds
                ? JSON.parse(this.state.qualificationIds)
                : null,
              availabilityTypes = this.state.availabilityTypes
                ? JSON.parse(this.state.availabilityTypes)
                : null;

            let details = {
              dateTimeAfterOrEqual: dateTimeAfterOrEqual,
              dateTimeBeforeOrEqual: dateTimeBeforeOrEqual,
              pagingNumbers:
                pagingNumbers && pagingNumbers.length ? pagingNumbers : null,
              qualificationIds: qualificationIds.length
                ? qualificationIds
                : null,
              availabilityTypes: availabilityTypes.length
                ? availabilityTypes
                : null,
            };
            this.Requests.callAPI(
              this.Requests.downloadReport,
              "AVAILABILITY",
              fileType,
              details
            ).then((data) => {
              if (data && data.status && data.status === 200) {
                let date = format(new Date(), "dd-MM-yyyy");
                let fileName;
                if (data.data.type === "application/pdf") {
                  // This is if you want to show the PDF instead of downloading it
                  const file = new Blob([data.data], {
                    type: "application/pdf",
                  });
                  const fileURL = URL.createObjectURL(file);
                  window.open(fileURL);

                  fileName = "AvailabilityReport_" + date + ".pdf";
                } else {
                  fileName = "AvailabilityReport_" + date + ".csv";
                }
                this.fileDownload(data.data, fileName);
                this.setState({
                  isDownloadingReport: false,
                  eventReport: null,
                  report: null,
                  fileType: null,
                  pagingNumbers: null,
                  UsersId: null,
                  dateTimeAfterOrEqual: null,
                  dateTimeBeforeOrEqual: null,
                  messageType: null,
                  attendanceStatus: null,
                  qualificationIds: null,
                  availabilityTypes: null,
                });
              } else {
                let ErrorMessage =
                  copy.report.downloadAvailabilityReportError +
                  ` (Error #${copy.errorCodes.downloadAvailabilityReportError})`;
                // if (data && data.data && data.data.SASMessageClient) {
                //   ErrorMessage = data.data.SASMessageClient;
                // }
                this.setState(
                  {
                    isDownloadingReport: false,
                    errorMessage: ErrorMessage,
                  },
                  () => {
                    setTimeout(
                      function () {
                        this.setState({ errorMessage: null });
                      }.bind(this),
                      5000
                    );
                  }
                );
              }
            });
          } else if (this.state.report === "unregistered_users") {
            let details = {
              agency: agency,
            };
            this.Requests.callAPI(
              this.Requests.downloadReport,
              "UNREGISTERED_USERS",
              fileType,
              details
            ).then((data) => {
              if (data && data.status && data.status === 200) {
                let date = format(new Date(), "dd-MM-yyyy");
                let fileName;
                if (data.data.type === "application/pdf") {
                  // This is if you want to show the PDF instead of downloading it
                  const file = new Blob([data.data], {
                    type: "application/pdf",
                  });
                  const fileURL = URL.createObjectURL(file);
                  window.open(fileURL);

                  fileName = "UnregisteredUsersReport_" + date + ".pdf";
                } else {
                  fileName = "UnregisteredUsersReport_" + date + ".csv";
                }
                this.fileDownload(data.data, fileName);
                this.setState({
                  isDownloadingReport: false,
                  eventReport: null,
                  report: null,
                  fileType: null,
                  UsersId: null,
                });
              } else {
                let ErrorMessage =
                  copy.report.downloadUnregisteredUsersReportError +
                  ` (Error #${copy.errorCodes.downloadUnregisteredUsersReportError})`;
                this.setState(
                  {
                    isDownloadingReport: false,
                    errorMessage: ErrorMessage,
                  },
                  () => {
                    setTimeout(
                      function () {
                        this.setState({ errorMessage: null });
                      }.bind(this),
                      5000
                    );
                  }
                );
              }
            });
          } else if (this.state.report === "asn") {
            let details = {
              agency: agency,
              usersId: UsersId && UsersId.length ? UsersId : null,
              pagingNumber:
                pagingNumber && pagingNumber.length ? pagingNumber : null,
              asn: asnList && asnList.length ? asnList : null,
            };
            this.Requests.callAPI(
              this.Requests.downloadReport,
              "ASN",
              fileType,
              details
            ).then((data) => {
              if (data && data.status && data.status === 200) {
                let date = format(new Date(), "dd-MM-yyyy");
                let fileName;
                if (data.data.type === "application/pdf") {
                  // This is if you want to show the PDF instead of downloading it
                  const file = new Blob([data.data], {
                    type: "application/pdf",
                  });
                  const fileURL = URL.createObjectURL(file);
                  window.open(fileURL);

                  fileName = "AsnReport_" + date + ".pdf";
                } else {
                  fileName = "AsnReport_" + date + ".csv";
                }
                this.fileDownload(data.data, fileName);
                this.setState({
                  isDownloadingReport: false,
                  eventReport: null,
                  report: null,
                  fileType: null,
                  pagingNumber: null,
                  UsersId: null,
                  dateTimeAfterOrEqual: null,
                  dateTimeBeforeOrEqual: null,
                  messageType: null,
                  attendanceStatus: null,
                });
              } else if (data && data.status && data.status === 400) {
                this.setState({ isDownloadingReport: false });
                //get error messages and error type from API
                let is400InvalidAsn = false;
                if (data.data instanceof Blob) {
                  data.data.text().then((jsonResponse) => {
                    try {
                      const errorResponse = JSON.parse(jsonResponse);
                      if (
                        errorResponse.SASENumErrorCode === 17 ||
                        errorResponse.SASENumErrorCode === 5
                      ) {
                        is400InvalidAsn = true;
                        this.setState(
                          {
                            invalidASN: copy.settings.profile.invalidASN,
                          },
                          () => {
                            setTimeout(
                              function () {
                                this.setState({
                                  invalidASN: false,
                                });
                              }.bind(this),
                              5000
                            );
                          }
                        );
                      }
                    } catch {
                    } finally {
                      //if error is not due to invalid asn, revert to normal error handling
                      if (!is400InvalidAsn) {
                        let ErrorMessage =
                          copy.report.downloadUserReportError +
                          ` (Error #${copy.errorCodes.downloadUserReportError})`;
                        this.setState(
                          {
                            isDownloadingReport: false,
                            errorMessage: ErrorMessage,
                          },
                          () => {
                            setTimeout(
                              function () {
                                this.setState({ errorMessage: null });
                              }.bind(this),
                              5000
                            );
                          }
                        );
                      }
                    }
                  });
                }
              } else {
                let ErrorMessage =
                  copy.report.downloadUserReportError +
                  ` (Error #${copy.errorCodes.downloadUserReportError})`;
                this.setState(
                  {
                    isDownloadingReport: false,
                    errorMessage: ErrorMessage,
                  },
                  () => {
                    setTimeout(
                      function () {
                        this.setState({ errorMessage: null });
                      }.bind(this),
                      5000
                    );
                  }
                );
              }
            });
          }
        }
      }
    );
  };

  // This will convert a string to Title case
  toTitleCase = (str) => {
    if (str) {
      return str.replace(/\w\S*/g, (txt) => {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    } else {
      return "";
    }
  };

  // Removes timestamp to return a date in yyyy/mm/dd
  fixFormat = (timestamp) => {
    if (timestamp) {
      return timestamp
        .replace(/-/g, "/")
        .replace("T", " ")
        .replace(/\..*|\+.*/, "");
    } else {
      return "";
    }
  };

  // converts date/time so Safari reads it properly
  convertDate = (timestamp) => {
    if (timestamp) {
      let yyyymmdd = this.fixFormat(timestamp).substr(0, 10); // Removes the time;
      var datePart = yyyymmdd.match(/\d+/g),
        year = datePart[0],
        month = datePart[1],
        day = datePart[2];
      // returns dd/mm/yyyy
      return day + "/" + month + "/" + year;
    } else {
      return "";
    }
  };

  // find the paging group name from the list of paging groups
  findPagingGroupName(pagingGroupID) {
    let pagingGroupOption = [];
    // get all paging groups from the user's agency / agencies
    this.props.userAgencies &&
      this.props.userAgencies.forEach((agency) => {
        if (this.state.userPagingGroupsOption[agency]) {
          pagingGroupOption = pagingGroupOption.concat(
            this.state.userPagingGroupsOption[agency]
          );
        }
      });

    if (pagingGroupOption) {
      for (let i = 0; i < pagingGroupOption.length; i++) {
        if (pagingGroupOption[i].key === pagingGroupID) {
          return pagingGroupOption[i].text;
        }
      }
    } else {
      return "";
    }
  }

  /**
   * Gets all qualifications
   */
  getAllQualifications() {
    let agencies = ["SES", "CFA"];

    for (let i = 0; i < agencies.length; i++) {
      this.Requests.callAPI(this.Requests.getUserQualificationsAgency, {
        agency: [agencies[i]],
      }).then((data) => {
        if (data && data.status && data.status === 200) {
          let allQualifications = { ...this.state.allQualifications };
          let qualifications = data.data;
          allQualifications[agencies[i]] = [];

          for (let j = 0; j < qualifications.length; j++) {
            allQualifications[agencies[i]].push({
              key: qualifications[j].code,
              value: qualifications[j].id,
              text: qualifications[j].name,
            });
          }
          this.setState({
            allQualifications: allQualifications,
            APICallFailed: false,
          });
        } else {
          let ErrorMessage =
            copy.report.getAvailQualInfoAPIErrorMessage +
            ` (Error #${copy.errorCodes.getAvailQualInfoAPIErrorMessage})`;
          // if (data && data.data && data.data.SASMessageClient) {
          //   ErrorMessage = data.data.SASMessageClient;
          // }
          this.setState(
            { errorMessage: ErrorMessage, APICallFailed: true },
            () => {
              setTimeout(
                function () {
                  this.setState({ errorMessage: null });
                }.bind(this),
                5000
              );
            }
          );
        }
      });
    }
  }

  /**
   * Finds and returns qualification name using qualification ID
   * @param {Number} qualificationID
   */
  findQualification(qualificationID) {
    if (this.state.allQualifications) {
      for (let key in this.state.allQualifications) {
        for (let i = 0; i < this.state.allQualifications[key].length; i++) {
          if (this.state.allQualifications[key][i].value === qualificationID) {
            return this.state.allQualifications[key][i].text;
          }
        }
      }
    } else {
      return "";
    }
  }

  // delete a schedlued report
  deleteScheduledReport = (reportId) => {
    this.Requests.callAPI(this.Requests.deleteScheduledReports, reportId).then(
      (data) => {
        if (data && data.status && data.status === 200) {
          // Remove from state
          // this.removeReportFromState(reportId);
          this.getScheduledReports();
        } else {
          let ErrorMessage =
            copy.report.deleteScheduleReportError +
            ` (Error #${copy.errorCodes.deleteScheduleReportError})`;
          // if (data && data.data && data.data.SASMessageClient) {
          //   ErrorMessage = data.data.SASMessageClient;
          // }
          this.setState({ errorMessage: ErrorMessage }, () => {
            setTimeout(
              function () {
                this.setState({ errorMessage: null });
              }.bind(this),
              5000
            );
          });
        }
      }
    );
  };

  // This is called after the delete api is made in deleteScheduledReport
  // It will remove the deleted report from the state, the api will automatically update this on reload
  removeReportFromState = (reportIdToDelete) => {
    if (this.state.exsistingScheduledReports.length) {
      let indexToDelete;
      for (let i = 0; i < this.state.exsistingScheduledReports.length; i++) {
        if (
          this.state.exsistingScheduledReports[i].reportId === reportIdToDelete
        ) {
          indexToDelete = i;
        }
      }
      let currentReports = this.state.exsistingScheduledReports;
      // delete currentReports[indexToDelete];
      currentReports
        .slice(0, indexToDelete)
        .concat(currentReports.slice(indexToDelete + 1, currentReports.length));
      // currentReports = currentReports.slice(indexToDelete, 1); // removes the report from that position
      // this.setState({
      //   exsistingScheduledReports: currentReports,
      // });
    } else {
      return null;
    }
  };

  renderExisitingReports() {
    return (
      <Wrapper>
        {this.state.exsistingScheduledReports.length &&
          this.state.exsistingScheduledReports.map((item, index) => {
            let reportType;
            if (item.type === "EVENT_OVERVIEW") {
              reportType = "Event Overview";
            } else if (item.type === "USERS") {
              reportType = "Users";
            } else if (item.type === "AVAILABILITY") {
              reportType = "Availability";
            } else if (item.type === "UNREGISTERED_USERS") {
              reportType = "Unregistered Users";
            } else if (item.type === "ASN") {
              reportType = "ASN";
            }
            let startDate = this.convertDate(item.dateStart);
            let reportFrequency = this.toTitleCase(item.frequency);
            let reportFileType = item.fileType && item.fileType.toUpperCase();
            let reportOverview = (
              <div>
                {reportType} Report ({reportFileType}) sent {reportFrequency} to{" "}
                {item.emailAddress} from {startDate}.
                <span className={styles.reportDelete}>
                  <Button
                    label="Delete"
                    content="Delete"
                    variant="btn_secondary"
                    styles="btn_outline"
                    icon="icon_trash"
                    paramVal={item.reportId}
                    buttonClick={this.deleteScheduledReport}
                  />
                </span>
              </div>
            );
            let reportAgency = "";
            if (item.filterModel && item.filterModel.agency) {
              reportAgency = item.filterModel.agency;
            }

            let reportMessageType = "";
            if (item.filterModel && item.filterModel.messageType) {
              // eslint-disable-next-line
              item.filterModel.messageType.map((type) => {
                if (type === "NonEmergency") {
                  reportMessageType += "Non-Emergency, ";
                } else {
                  reportMessageType += type + ", ";
                }
              });
              reportMessageType = reportMessageType.slice(0, -2);
            }
            // Paging Groups
            let reportPagingGroups = "";
            if (item.filterModel && item.filterModel.pagingNumber) {
              // eslint-disable-next-line
              item.filterModel.pagingNumber.map((type) => {
                reportPagingGroups += this.findPagingGroupName(type) + ", ";
              });
              reportPagingGroups = reportPagingGroups.slice(0, -2);
            } else if (item.filterModel && item.filterModel.pagingNumbers) {
              // eslint-disable-next-line
              item.filterModel.pagingNumbers.map((type) => {
                reportPagingGroups += this.findPagingGroupName(type) + ", ";
              });
              reportPagingGroups = reportPagingGroups.slice(0, -2);
            }

            // Attendance status
            let reportAttendanceStatus = "";
            if (item.filterModel && item.filterModel.attendanceStatus) {
              if (item.filterModel.attendanceStatus.length === 0) {
                reportAttendanceStatus =
                  "Attending, Other, Unavailable, No Response";
              } else {
                // eslint-disable-next-line
                item.filterModel.attendanceStatus.map((type) => {
                  reportAttendanceStatus += type + ", ";
                });
                reportAttendanceStatus = reportAttendanceStatus.slice(0, -2);
              }
            }

            // Availability Type
            let reportAvailabilityType = "";
            if (
              item.filterModel &&
              item.filterModel.availabilityTypes &&
              item.filterModel.availabilityTypes[0]
            ) {
              switch (item.filterModel.availabilityTypes[0]) {
                case "RegionShortHaul":
                  reportAvailabilityType = "Region / Short Haul";
                  break;
                case "StateLongHaul":
                  reportAvailabilityType = "State / Long Haul";
                  break;
                case "InterstateDeployment":
                  reportAvailabilityType = "Interstate Deployment";
                  break;
                case "IMT":
                  reportAvailabilityType = "IMT";
                  break;
                default:
                  reportAvailabilityType = "";
              }
            }

            // Qualifications
            let reportQualifications = "";
            if (item.filterModel && item.filterModel.qualificationIds) {
              // eslint-disable-next-line
              item.filterModel.qualificationIds.map((id) => {
                reportQualifications += this.findQualification(id) + ", ";
              });
              reportQualifications = reportQualifications.slice(0, -2);
            }

            // Date range
            let reportDateFrom = "";
            let reportDateTo = "";
            if (item.filterModel && item.filterModel.dateTimeAfterOrEqual) {
              reportDateFrom = this.convertDate(
                item.filterModel.dateTimeAfterOrEqual
              );
            }
            if (item.filterModel && item.filterModel.dateTimeBeforeOrEqual) {
              reportDateTo = this.convertDate(
                item.filterModel.dateTimeBeforeOrEqual
              );
            }

            // flag for whether scheduled report has failed to send
            let hasReportFailedToSend = false;
            if (
              item.failOnLastSendCount &&
              parseInt(item.failOnLastSendCount) >= 0
            ) {
              hasReportFailedToSend = true;
            }

            return (
              <div
                key={item.reportId}
                id={item.reportId}
                className="scheduleRoportRow"
              >
                <Accordion key={index}>
                  <div label={reportOverview}>
                    <div className={styles.reportRowDetails}>
                      {/* Agency for each report */}
                      {reportAgency && (
                        <Wrapper>
                          <span className="txt4">Agency: </span>
                          {reportAgency}
                        </Wrapper>
                      )}
                    </div>
                    <div className={styles.reportRowDetails}>
                      {/* Alert Types for event reports */}
                      {reportMessageType && (
                        <Wrapper>
                          <span className="txt4">Alert Types: </span>
                          {reportMessageType}
                        </Wrapper>
                      )}
                    </div>
                    <div className={styles.reportRowDetails}>
                      {reportPagingGroups && (
                        <Wrapper>
                          {/* Paging Groups for each report */}
                          <span className="txt4">Paging Groups: </span>
                          {reportPagingGroups}
                        </Wrapper>
                      )}
                    </div>
                    <div className={styles.reportRowDetails}>
                      {reportAttendanceStatus && (
                        <Wrapper>
                          {/* Attendance Status for event reports */}
                          <span className="txt4">Attendance Status: </span>
                          {reportAttendanceStatus}
                        </Wrapper>
                      )}
                    </div>
                    <div className={styles.reportRowDetails}>
                      {reportAvailabilityType && (
                        <Wrapper>
                          {/* Availability type for availability reports */}
                          <span className="txt4">Availability Type: </span>
                          {reportAvailabilityType}
                        </Wrapper>
                      )}
                    </div>
                    <div className={styles.reportRowDetails}>
                      {reportQualifications && (
                        <Wrapper>
                          {/* Qualifications for availability reports */}
                          <span className="txt4">Qualification: </span>
                          {reportQualifications}
                        </Wrapper>
                      )}
                    </div>
                    <div className={styles.reportRowDetails}>
                      {reportDateFrom && reportDateTo && (
                        <Wrapper>
                          {/* Date from and to for each report */}
                          <span className="txt4">Date Range: </span>
                          From {reportDateFrom} to {reportDateTo}
                        </Wrapper>
                      )}
                    </div>
                    <div className={styles.reportRowDetails}>
                      {/* Scheduled report failed to send flag */}
                      {hasReportFailedToSend && (
                        <Wrapper>
                          {copy.report.scheduledReportFailedToSendError}
                        </Wrapper>
                      )}
                    </div>
                  </div>
                </Accordion>
              </div>
            );
          })}
      </Wrapper>
    );
  }

  // ----------------------------//
  //         USER REPORT         //
  // ----------------------------//
  selectUsers = (value) => {
    this.setState({
      UsersId: value,
      membersResultsError: false,
    });
  };
  render() {
    let dateRange = [
      { key: 1, text: "", value: "" },
      { key: 2, text: "Today", value: "today" },
      { key: 3, text: "Yesterday", value: "yesterday" },
      { key: 4, text: "Within one week", value: "week" },
      { key: 5, text: "Within a fortnight", value: "fortnight" },
      { key: 6, text: "Last month", value: "month" },
    ];

    let frequencyRange = [
      { key: 1, text: "Daily", value: "daily" },
      { key: 2, text: "Fortnightly", value: "fortnightly" },
      { key: 3, text: "Monthly", value: "monthly" },
    ];

    let documentTypes = [
      { key: 1, text: "PDF", value: "pdf" },
      { key: 2, text: "CSV", value: "csv" },
    ];

    const typeOptions = [
      {
        key: 1,
        text: "Local / Home Branch",
        value: "Local",
      },
      {
        key: 2,
        text: "Region / Short Haul",
        value: "RegionShortHaul",
      },
      {
        key: 3,
        text: "State / Long Haul",
        value: "StateLongHaul",
      },
      {
        key: 4,
        text: "Interstate / Deployment",
        value: "InterstateDeployment",
      },
      {
        key: 5,
        text: "IMT",
        value: "IMT",
      },
      // AV Only is no longer required since AV is not using availability at the moment
      // {
      //   key: 6,
      //   text: "All (AV Only)",
      //   value: "All_AVOnly",
      // },
    ];

    let startDate = this.state.dateStartObject;
    let endDate = this.state.dateEndObject;
    let scheduleStartDate = this.state.scheduleStartDateObject;

    // get user email for the agency selected
    let agencyEmail = "";
    if (
      this.state.searchData &&
      this.state.searchData.agency &&
      this.state.userEmails &&
      this.state.userEmails[this.state.searchData.agency]
    ) {
      agencyEmail = this.state.userEmails[this.state.searchData.agency];
    }

    return (
      <Wrapper>
        <ErrorBanner
          isVisible={this.state.errorMessage ? true : false}
          ErrorMessage={this.state.errorMessage}
        />
        <div className={styles.reportWrapper + " bg3"}>
          <div className={styles.buttonContainer}>
            <button
              label="To event statistics"
              aria-label="Generate Reports"
              className={"btn_tertiary icon_dashboard " + styles.disabled}
              onClick={() => {
                this.props.history.push({
                  pathname: "/dashboard/events",
                });
              }}
            >
              View Dashboard
            </button>
          </div>
          <div className={styles.box_default + " bg2"}>
            <form onSubmit={this.handleSubmit}>
              <div className={styles.sectionTitle}>
                <h2>{copy.report.createTitle}</h2>
              </div>
              {this.state.userAgenciesOption.length !== 0 ? (
                <div>
                  <div className={styles.row}>
                    <div className={styles.title}>{copy.report.basedOn}</div>
                    <div
                      className={styles.body}
                      //onChange={this.changeReport.bind(this)}
                    >
                      <label aria-label="USERS REPORT" className={styles.radio}>
                        <input
                          type="radio"
                          name="USERS"
                          checked={this.state.reportType === "USERS"}
                          onChange={this.changeReport}
                        />
                        <span className={styles.checkmark}></span>
                        USERS
                      </label>
                      <label
                        aria-label=" UNREGISTERED USERS REPORT"
                        className={styles.radio}
                      >
                        <input
                          type="radio"
                          name="UNREGISTERED_USERS"
                          checked={
                            this.state.reportType === "UNREGISTERED_USERS"
                          }
                          onChange={this.changeReport}
                        />
                        <span className={styles.checkmark}></span>
                        UNREGISTERED USERS
                      </label>
                      {/* Availability and Event Reports should only be visible for CFA and SES users */}
                      {(this.props.userAgencies.includes("CFA") ||
                        this.props.userAgencies.includes("SES")) && (
                        <>
                          <label
                            aria-label="EVENT OVERVIEW REPORT"
                            className={styles.radio}
                          >
                            <input
                              type="radio"
                              name="EVENT_OVERVIEW"
                              checked={
                                this.state.reportType === "EVENT_OVERVIEW"
                              }
                              onChange={this.changeReport}
                            />
                            <span className={styles.checkmark}></span>
                            EVENT OVERVIEW
                          </label>
                          <label
                            aria-label="AVAILABILITY REPORT"
                            className={styles.radio}
                          >
                            <input
                              type="radio"
                              name="AVAILABILITY"
                              checked={this.state.reportType === "AVAILABILITY"}
                              onChange={this.changeReport}
                            />
                            <span className={styles.checkmark}></span>
                            AVAILABILITY
                          </label>
                        </>
                      )}
                      {this.state.generateAsnReportPermission && (
                        <>
                          <label
                            aria-label="ASN REPORT"
                            className={styles.radio}
                            style={{ marginRight: "15px" }}
                          >
                            <input
                              type="radio"
                              name="ASN"
                              checked={this.state.reportType === "ASN"}
                              onChange={this.changeReport}
                            />
                            <span className={styles.checkmark}></span>
                            ASN
                          </label>
                        </>
                      )}
                    </div>
                  </div>
                  {this.state.reportType === "ASN" && (
                    <div className={styles.row}>
                      <div className={styles.title}>{copy.report.asn}</div>
                      <input
                        name="asnInput"
                        className={
                          styles.body +
                          " " +
                          styles.asnInput +
                          " " +
                          (this.state.AsnError && styles.error + " error")
                        }
                        pl
                        //className="default_input"
                        type="text"
                        placeholder="Empty"
                        maxLength="10"
                        value={this.state.asnInput}
                        onChange={(e) =>
                          this.setState({ asnInput: e.target.value })
                        }
                        disabled={this.state.reportType !== "ASN"}
                      />
                      {this.state.invalidASN && (
                        <div className={styles.invalid_ASN_error}>
                          {this.state.invalidASN}
                        </div>
                      )}
                    </div>
                  )}
                  {this.state.reportType === "USERS" ||
                  this.state.reportType === "ASN" ? (
                    <div className={styles.row}>
                      <div className={styles.title}>{copy.report.agencies}</div>
                      <div
                        className={styles.body + " " + styles.agencyDropdown}
                      >
                        {this.renderAgencies()}
                      </div>
                    </div>
                  ) : (
                    this.state.reportType !== "UNREGISTERED_USERS" && (
                      <div className={styles.row + " " + styles.noUnderline}>
                        <div className={styles.title}></div>
                        <div
                          className={styles.body + " " + styles.agencyDropdown}
                        >
                          {this.renderAgencies()}
                        </div>
                      </div>
                    )
                  )}
                  {this.state.reportType !== "UNREGISTERED_USERS" && (
                    <div className={styles.row}>
                      <div className={styles.title}>
                        {this.state.reportType === "EVENT_OVERVIEW"
                          ? copy.report.groups
                          : copy.report.group}
                      </div>
                      <div
                        className={styles.body + " " + styles.pagingDropdown}
                      >
                        {this.renderPagingGroups()}
                      </div>
                    </div>
                  )}
                  {this.state.reportType === "EVENT_OVERVIEW" ? (
                    <Wrapper>
                      <div className={styles.row + " " + styles.multi}>
                        <div className={styles.title}>{copy.report.limit}</div>

                        <div className={styles.body}>
                          <div className={styles.errorWrapper}>
                            {this.state.typeErrorMessage && (
                              <div className={styles.errorMessageBlock}>
                                <span>{this.state.typeErrorMessage}</span>
                              </div>
                            )}
                            <div className={styles.subRow}>
                              <div className={styles.subTitle}>
                                {copy.report.type}
                              </div>

                              <div className={styles.subBody}>
                                <Checkbox
                                  name="Emergency"
                                  label="Emergency"
                                  checked={
                                    this.state.Emergency === true
                                      ? "checked"
                                      : ""
                                  }
                                  onChange={this.selectEmergency}
                                />
                                <Checkbox
                                  name="Non-Emergency"
                                  label="Non-Emergency"
                                  checked={
                                    this.state.NonEmergency === true
                                      ? "checked"
                                      : ""
                                  }
                                  onChange={this.selectNonEmergency}
                                />
                                <Checkbox
                                  name="Admin"
                                  label="Admin"
                                  checked={
                                    this.state.Admin === true ? "checked" : ""
                                  }
                                  onChange={this.selectAdmin}
                                />
                              </div>
                            </div>
                          </div>

                          <div className={styles.subRow}>
                            <div className={styles.subTitle}>
                              {copy.report.date}
                            </div>
                            <div className={styles.subBody}>
                              <Dropdown
                                className={
                                  this.state.dateStartError
                                    ? styles.error + " error"
                                    : null
                                }
                                placeholder="Date range"
                                fluid
                                selection
                                value={this.state.dateRangeValue}
                                options={dateRange}
                                onChange={(e, { value }) =>
                                  this.selectDate(value)
                                }
                              />
                              <span className={styles.spacer}> or </span>
                              <div style={{}}></div>
                              <DatePicker
                                className={
                                  styles.firstDate +
                                  " ui fluid multiple search selection dropdown " +
                                  (this.state.dateStartError &&
                                    styles.error + " error")
                                }
                                selected={startDate}
                                onSelect={(date) =>
                                  this.handleDateChange("dateStart", date)
                                }
                                dateFormat="dd/MM/yyyy"
                                placeholderText="Start Date"
                              />
                              <span className={styles.spacer}></span>
                              <DatePicker
                                className={
                                  styles.firstDate +
                                  " ui fluid multiple search selection dropdown " +
                                  (this.state.dateStartError &&
                                    styles.error + " error")
                                }
                                selected={endDate}
                                onSelect={(date) =>
                                  this.handleDateChange("dateEnd", date)
                                }
                                dateFormat="dd/MM/yyyy"
                                placeholderText="End Date"
                              />
                            </div>
                          </div>
                          {
                            // do not show attendance options if AV agency is selected
                            this.state.searchData.agency[0] !== "AV" && (
                              <div
                                className={
                                  styles.subRow + " " + styles.noUnderline
                                }
                              >
                                <div className={styles.subTitle}>
                                  {copy.report.response}
                                </div>
                                <div
                                  className={
                                    styles.subBody + " " + styles.noLineHeight
                                  }
                                >
                                  <Checkbox
                                    name="Attended"
                                    label="Attended"
                                    checked={
                                      this.state.Attended === true
                                        ? "checked"
                                        : ""
                                    }
                                    onChange={this.selectAttended}
                                  />
                                  <Checkbox
                                    name="Other"
                                    label="Other"
                                    checked={
                                      this.state.Other === true ? "checked" : ""
                                    }
                                    onChange={this.selectOther}
                                  />
                                  <Checkbox
                                    name="Unavailable"
                                    label="Unavailable"
                                    checked={
                                      this.state.Unavailable === true
                                        ? "checked"
                                        : ""
                                    }
                                    onChange={this.selectUnavailable}
                                  />
                                  <Checkbox
                                    name="No Response / No Attendance Required"
                                    label="No Response / No Attendance Required"
                                    checked={
                                      this.state.NoResponse === true
                                        ? "checked"
                                        : ""
                                    }
                                    onChange={this.selectNoResponse}
                                  />
                                </div>
                              </div>
                            )
                          }
                          <div
                            className={styles.subRow + " " + styles.noUnderline}
                          >
                            <div className={styles.subTitle}></div>
                            <div
                              className={
                                styles.subBody + " " + styles.generateButtons
                              }
                            >
                              {this.state.isDownloadingReport ? (
                                <div className={styles.loadingContainer}>
                                  <Loading />
                                </div>
                              ) : (
                                <Wrapper>
                                  <button
                                    type="submit"
                                    className="btn_primary btn_outline"
                                    onClick={this.DownloadCSV}
                                  >
                                    Download report as a CSV
                                  </button>
                                  <button
                                    type="submit"
                                    className="btn_primary btn_outline"
                                    onClick={this.DownloadPDF}
                                  >
                                    Download report as a PDF
                                  </button>
                                </Wrapper>
                              )}
                            </div>
                          </div>
                          <div
                            className={
                              styles.validationErrorMessage +
                              " " +
                              styles.errorMsg
                            }
                          >
                            {this.state.successful === true ? (
                              <p className="fadeaway">
                                Your report will be ready shortly.
                              </p>
                            ) : this.state.successful === false ? (
                              <Wrapper>
                                <span className="fadeaway error">
                                  {this.state.ValidationErrorMessage}
                                </span>
                              </Wrapper>
                            ) : (
                              <div className={styles.disclaimer}>
                                {copy.report.largeReportsTxt}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </Wrapper>
                  ) : this.state.reportType === "USERS" ||
                    this.state.reportType === "ASN" ? (
                    <Wrapper>
                      <div className={styles.row + " " + styles.multi}>
                        <div className={styles.title}>{copy.report.users}</div>

                        <div className={styles.body}>
                          <div
                            className={styles.subRow + " " + styles.noUnderline}
                          >
                            <div className={styles.subTitle}></div>
                            <div
                              className={styles.subBody + " reportUserSearch"}
                            >
                              {this.state.searchData.agency.length &&
                              this.state.PagingGroups.length ? (
                                <Dropdown
                                  className={
                                    this.state.membersResultsError
                                      ? styles.error + " error"
                                      : null
                                  }
                                  placeholder="Select a member"
                                  fluid
                                  selection
                                  multiple
                                  search
                                  options={this.state.userOption}
                                  value={this.state.UsersId}
                                  onChange={(e, { value }) =>
                                    this.selectUsers(value)
                                  }
                                />
                              ) : (
                                <div className={styles.disabledDropdown}>
                                  <Dropdown
                                    className={
                                      styles.pagingGroupDropdown +
                                      " " +
                                      (this.state.QualificationsError &&
                                        styles.error + " error")
                                    }
                                    placeholder="A paging group must be selected"
                                    fluid
                                    search
                                    selection
                                  />
                                </div>
                              )}
                            </div>
                          </div>

                          <div
                            className={styles.subRow + " " + styles.noUnderline}
                          >
                            <div className={styles.subTitle}></div>
                            <div className={styles.subBody}>
                              {this.state.isDownloadingReport ? (
                                <div className={styles.loadingContainer}>
                                  <Loading />
                                </div>
                              ) : (
                                <Wrapper>
                                  <button
                                    type="submit"
                                    className="btn_primary btn_outline"
                                    onClick={this.DownloadCSV}
                                  >
                                    Download report as a CSV
                                  </button>
                                  <button
                                    type="submit"
                                    className="btn_primary btn_outline"
                                    onClick={this.DownloadPDF}
                                  >
                                    Download report as a PDF
                                  </button>
                                </Wrapper>
                              )}
                            </div>
                          </div>
                          <div
                            className={
                              styles.validationErrorMessage +
                              " " +
                              styles.errorMsg
                            }
                          >
                            {this.state.successful === true ? (
                              <p className="disclaimer">
                                Your report will be ready shortly.
                              </p>
                            ) : this.state.successful === false ? (
                              <Wrapper>
                                <span className="fadeaway error">
                                  Your report could not be generated.
                                </span>
                                <span className="fadeaway error">
                                  {this.state.validationErrorMessage}
                                </span>
                              </Wrapper>
                            ) : (
                              <div className={styles.disclaimer}>
                                {copy.report.largeReportsTxt}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </Wrapper>
                  ) : this.state.reportType === "AVAILABILITY" ? (
                    <Wrapper>
                      <div className={styles.row + " " + styles.multi}>
                        <div className={styles.title}>{copy.report.limit}</div>

                        <div className={styles.body}>
                          <div className={styles.errorWrapper}>
                            {this.state.typeErrorMessage && (
                              <div className={styles.errorMessageBlock}>
                                <span>{this.state.typeErrorMessage}</span>
                              </div>
                            )}
                            <div className={styles.subRow}>
                              <div className={styles.subTitle}>
                                {copy.report.availabilityType}
                              </div>

                              <div className={styles.subBody}>
                                <Checkbox
                                  name="Qualifications"
                                  label="Qualifications"
                                  checked={
                                    this.state.Qualifications === true
                                      ? "checked"
                                      : ""
                                  }
                                  onChange={() => {
                                    this.changeAvailabilityType(
                                      "Qualifications"
                                    );
                                  }}
                                />
                                <Checkbox
                                  name="Type"
                                  label="Type"
                                  checked={
                                    this.state.Type === true ? "checked" : ""
                                  }
                                  onChange={() => {
                                    this.changeAvailabilityType("Type");
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          {this.state.Qualifications && (
                            <div className={styles.subRow}>
                              <div className={styles.subTitle}>
                                {copy.report.qualification}
                              </div>
                              <div
                                className={
                                  styles.subBody + " " + styles.dropdownHolder
                                }
                              >
                                {this.state.searchData.agency.length ? (
                                  <Dropdown
                                    className={
                                      styles.pagingGroupDropdown +
                                      " " +
                                      (this.state.QualificationsError &&
                                        styles.error + " error")
                                    }
                                    placeholder="Select a qualification"
                                    fluid
                                    search
                                    selection
                                    options={this.state.qualificationList}
                                    onChange={(e, { value }) =>
                                      this.selectQual(value)
                                    }
                                  />
                                ) : (
                                  <div className={styles.disabledDropdown}>
                                    <Dropdown
                                      className={
                                        styles.pagingGroupDropdown +
                                        " " +
                                        (this.state.QualificationsError &&
                                          styles.error + " error")
                                      }
                                      placeholder="An agency must be selected"
                                      fluid
                                      search
                                      selection
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                          {this.state.Type && (
                            <div className={styles.subRow}>
                              <div className={styles.subTitle}>
                                {copy.report.type2}
                              </div>
                              <div
                                className={
                                  styles.subBody + " " + styles.dropdownHolder
                                }
                              >
                                <Dropdown
                                  className={
                                    styles.pagingGroupDropdown +
                                    " " +
                                    (this.state.TypeError &&
                                      styles.error + " error")
                                  }
                                  placeholder="Select a type"
                                  fluid
                                  search
                                  selection
                                  options={typeOptions}
                                  onChange={(e, { value }) =>
                                    this.selectType(value)
                                  }
                                />
                              </div>
                            </div>
                          )}
                          <div className={styles.subRow}>
                            <div className={styles.subTitle}>
                              {copy.report.date}
                            </div>
                            <div className={styles.subBody}>
                              <Dropdown
                                className={
                                  this.state.dateStartError
                                    ? styles.error + " error"
                                    : null
                                }
                                placeholder="Date range"
                                fluid
                                selection
                                value={this.state.dateRangeValue}
                                options={dateRange}
                                onChange={(e, { value }) =>
                                  this.selectDate(value)
                                }
                              />
                              <span className={styles.spacer}> or </span>
                              <DatePicker
                                className={
                                  styles.firstDate +
                                  " ui fluid multiple search selection dropdown " +
                                  (this.state.dateStartError &&
                                    styles.error + " error")
                                }
                                selected={startDate}
                                onSelect={(date) =>
                                  this.handleDateChange("dateStart", date)
                                }
                                dateFormat="dd/MM/yyyy"
                                placeholderText="Start Date"
                              />
                              <span className={styles.spacer}></span>
                              <DatePicker
                                className={
                                  styles.firstDate +
                                  " ui fluid multiple search selection dropdown " +
                                  (this.state.dateStartError &&
                                    styles.error + " error")
                                }
                                selected={endDate}
                                onSelect={(date) =>
                                  this.handleDateChange("dateEnd", date)
                                }
                                dateFormat="dd/MM/yyyy"
                                placeholderText="End Date"
                              />
                            </div>
                          </div>
                          <div
                            className={styles.subRow + " " + styles.noUnderline}
                          >
                            <div className={styles.subTitle}></div>
                            <div
                              className={
                                styles.subBody + " " + styles.generateButtons
                              }
                            >
                              {this.state.isDownloadingReport ? (
                                <div className={styles.loadingContainer}>
                                  <Loading />
                                </div>
                              ) : (
                                <Wrapper>
                                  <button
                                    type="submit"
                                    className="btn_primary btn_outline"
                                    onClick={this.DownloadCSV}
                                  >
                                    Download report as a CSV
                                  </button>
                                  <button
                                    type="submit"
                                    className="btn_primary btn_outline"
                                    onClick={this.DownloadPDF}
                                  >
                                    Download report as a PDF
                                  </button>
                                </Wrapper>
                              )}
                            </div>
                          </div>
                          <div
                            className={
                              styles.validationErrorMessage +
                              " " +
                              styles.errorMsg
                            }
                          >
                            {this.state.successful === true ? (
                              <p className="fadeaway">
                                Your report will be ready shortly.
                              </p>
                            ) : this.state.successful === false ? (
                              <Wrapper>
                                <span className="fadeaway error">
                                  {this.state.ValidationErrorMessage}
                                </span>
                              </Wrapper>
                            ) : (
                              <div className={styles.disclaimer}>
                                {copy.report.largeReportsTxt}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </Wrapper>
                  ) : this.state.reportType === "UNREGISTERED_USERS" ? (
                    <Wrapper>
                      <div className={styles.row}>
                        <div className={styles.title}>
                          {copy.report.agencies}
                        </div>
                        <div className={styles.body}>
                          {this.renderAgencies()}
                          <div
                            className={styles.subRow + " " + styles.noUnderline}
                          >
                            <div className={styles.subTitle}></div>
                            <div className={styles.subBodyUnregisteredUsers}>
                              {this.state.isDownloadingReport ? (
                                <div className={styles.loadingContainer}>
                                  <Loading />
                                </div>
                              ) : (
                                <Wrapper>
                                  <button
                                    type="submit"
                                    className="btn_primary btn_outline"
                                    onClick={this.DownloadCSV}
                                  >
                                    Download report as a CSV
                                  </button>
                                  <button
                                    type="submit"
                                    className="btn_primary btn_outline"
                                    onClick={this.DownloadPDF}
                                  >
                                    Download report as a PDF
                                  </button>
                                </Wrapper>
                              )}
                            </div>
                          </div>
                          <div className={styles.validationErrorMessage}>
                            {this.state.successful === true ? (
                              <p className="fadeaway">
                                Your report will be ready shortly.
                              </p>
                            ) : this.state.successful === false ? (
                              <Wrapper>
                                <span className="fadeaway error">
                                  Your report could not be generated.
                                </span>
                                <span className="fadeaway error">
                                  {this.state.validationErrorMessage}
                                </span>
                              </Wrapper>
                            ) : (
                              <div
                                className={styles.disclaimerUnregisteredUsers}
                              >
                                {copy.report.largeReportsTxt}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </Wrapper>
                  ) : null}
                </div>
              ) : (
                <div className={styles.loadingContainer}>
                  <Loading />
                </div>
              )}
            </form>
            <div className={styles.row}>
              <div className={styles.sectionTitle}>
                <h2>
                  {copy.report.scheduledTitle}
                  {/* agency email will only show if an agency is selected for the report */}
                  {agencyEmail && " (" + agencyEmail + ")"}
                </h2>
              </div>
              <div className={styles.body + " " + styles.bodyWrap}>
                <div className={styles.subBody + " " + styles.subBodyWrap}>
                  <Dropdown
                    className={
                      styles.frequencyButton +
                      " " +
                      styles.reportRowItem +
                      " " +
                      (this.state.reportFrequencyError &&
                        styles.error + " error")
                    }
                    placeholder="Set Frequency"
                    fluid
                    selection
                    value={this.state.reportFrequency}
                    options={frequencyRange}
                    onChange={(e, { value }) => this.selectFrequency(value)}
                  />
                  <Dropdown
                    className={
                      styles.frequencyButton +
                      " " +
                      styles.reportRowItem +
                      " " +
                      (this.state.scheduleFileTypeError &&
                        styles.error + " error")
                    }
                    placeholder="PDF or CSV"
                    fluid
                    selection
                    value={this.state.scheduleFileType}
                    options={documentTypes}
                    onChange={(e, { value }) =>
                      this.selectScheduleFileType(value)
                    }
                  />
                  <DatePicker
                    className={
                      (this.state.scheduleStartDateError &&
                        styles.error + " error") +
                      " " +
                      styles.frequencyEmail +
                      " " +
                      styles.reportRowItem +
                      " ui fluid multiple search selection dropdown"
                    }
                    selected={scheduleStartDate}
                    onSelect={(date) =>
                      this.handleDateChange("scheduleStartDate", date)
                    }
                    dateFormat="dd/MM/yyyy"
                    placeholderText="Schedule Start Date"
                  />
                  {/* <input
                    className={
                      (this.state.emailAddressError &&
                        styles.error + " error") +
                      " " +
                      styles.searchbox +
                      " highContrast"
                    }
                    type="email"
                    id="emailId"
                    name="emailId"
                    title="Enter your email"
                    placeholder="Enter your email"
                    // value={this.state.date}
                    onChange={() => {
                      if (this.state.emailAddressError) {
                        this.setState({
                          emailAddressError: false,
                        });
                      }
                    }}
                  /> */}
                  <button
                    className={
                      styles.saveButton +
                      " btn_primary btn_outline " +
                      styles.reportRowItem
                    }
                    type="submit"
                    onClick={this.scheduleReport}
                  >
                    Save
                  </button>
                </div>
                {/* Display error message if scheduled report is missing user input info */}
                <div className={styles.subBody + " " + styles.subBodyWrap}>
                  {(this.state.reportFrequencyError ||
                    this.state.scheduleFileTypeError ||
                    this.state.scheduleStartDateError) && (
                    <span className="fadeaway error">
                      {copy.report.missingScheduledReportInfoError}
                    </span>
                  )}
                </div>
              </div>
              <div className={styles.currentSchedules}>
                <div className={styles.sectionTitle}>
                  <h2>{copy.report.exsistingTitle}</h2>
                </div>
              </div>
              <div className={styles.currentSchedulesRow}>
                {this.state.exsistingScheduledReports === null ? (
                  // API call to scheduled reports is being made
                  <Loading />
                ) : this.state.existingScheduleReportsError ? (
                  // Error in the API GET call
                  <div className={styles.title}>
                    {copy.report.scheduledReportError +
                      ` (Error #${copy.errorCodes.scheduledReportError})`}
                  </div>
                ) : this.state.exsistingScheduledReports.length === 0 ? (
                  // No scheduled reports available
                  <div className={styles.title}>
                    {copy.report.noScheduledReports}
                  </div>
                ) : (
                  // Show the scheduled reports
                  this.renderExisitingReports()
                )}
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }
}
