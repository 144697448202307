import React from "react";

class Button extends React.Component {
  handleClick = () => {
    if (this.props.buttonClick) {
      this.props.buttonClick(this.props.paramVal);
    }
  };

  render() {
    const {
      ref,
      label,
      styles,
      variant,
      icon,
      content,
      disabled,
      type,
      send_disable,
      style,
      hidden
    } = this.props;
    if (disabled) {
      return (
        <button
          type={type}
          ref={ref}
          aria-label={label}
          className={styles + " " + variant + " " + icon}
          disabled
          style={style}
          hidden={hidden}>
          {content}
        </button>
      );
    } else {
      return (
        <button
          type={type}
          ref={ref}
          aria-label={label}
          {...{ onClick: this.handleClick }}
          className={
            send_disable
              ? styles + " " + variant + " " + icon + " disable"
              : styles + " " + variant + " " + icon
          }
          style={style}
          hidden={hidden}>
          {content}
        </button>
      );
    }
  }
}
export default Button;
