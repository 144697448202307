// Import all your audio files based on the sound names
import sasAlertTone1 from "../../../assets/tones_mp3/SAS Alert Tone 1.mp3";
import sasAlertTone2 from "../../../assets/tones_mp3/SAS Alert Tone 2.mp3";
import sasAlertTone3 from "../../../assets/tones_mp3/SAS Alert Tone 3.mp3";
import sasAlertTone4 from "../../../assets/tones_mp3/SAS Alert Tone 4.mp3";
import sasAlertTone5 from "../../../assets/tones_mp3/SAS Alert Tone 5.mp3";
import sasAlertTone6 from "../../../assets/tones_mp3/SAS Alert Tone 6.mp3";
import sasAlertTone7 from "../../../assets/tones_mp3/SAS Alert Tone 7.mp3";
import sasAlertTone8 from "../../../assets/tones_mp3/SAS Alert Tone 8.mp3";
import sasAlertTone9 from "../../../assets/tones_mp3/SAS Alert Tone 9.mp3";
import sasAlertTone10 from "../../../assets/tones_mp3/SAS Alert Tone 10.mp3";
import sasAlertTone11 from "../../../assets/tones_mp3/SAS Alert Tone 11.mp3";
import sasAlertTone12 from "../../../assets/tones_mp3/SAS Alert Tone 12.mp3";
import sasAlertTone13 from "../../../assets/tones_mp3/SAS Alert Tone 13.mp3";
import sasAlertTone14 from "../../../assets/tones_mp3/SAS Alert Tone 14.mp3";
import sasAlertTone15 from "../../../assets/tones_mp3/SAS Alert Tone 15.mp3";
import sasAlertTone16 from "../../../assets/tones_mp3/SAS Alert Tone 16.mp3";
import sasAlertTone17 from "../../../assets/tones_mp3/SAS Alert Tone 17.mp3";
import sasAlertTone18 from "../../../assets/tones_mp3/SAS Alert Tone 18.mp3";
import sasAlertTone19 from "../../../assets/tones_mp3/SAS Alert Tone 19.mp3";
import sasAlertTone20 from "../../../assets/tones_mp3/SAS Alert Tone 20.mp3";
import sasAlertTone21 from "../../../assets/tones_mp3/SAS Alert Tone 21.mp3";
import sasAlertTone22 from "../../../assets/tones_mp3/SAS Alert Tone 22.mp3";
import sasAlertTone23 from "../../../assets/tones_mp3/SAS Alert Tone 23.mp3";
import sasAlertTone24 from "../../../assets/tones_mp3/SAS Alert Tone 24.mp3";

const sounds = [
  {
    text: "SAS Alert Tone 1",
    value: 0,
    audio: sasAlertTone1,
  },
  {
    text: "SAS Alert Tone 2",
    value: 1,
    audio: sasAlertTone2,
  },
  {
    text: "SAS Alert Tone 3",
    value: 2,
    audio: sasAlertTone3,
  },
  {
    text: "SAS Alert Tone 4",
    value: 3,
    audio: sasAlertTone4,
  },
  {
    text: "SAS Alert Tone 5",
    value: 4,
    audio: sasAlertTone5,
  },
  {
    text: "SAS Alert Tone 6",
    value: 5,
    audio: sasAlertTone6,
  },
  {
    text: "SAS Alert Tone 7",
    value: 6,
    audio: sasAlertTone7,
  },
  {
    text: "SAS Alert Tone 8",
    value: 7,
    audio: sasAlertTone8,
  },
  {
    text: "SAS Alert Tone 9",
    value: 8,
    audio: sasAlertTone9,
  },
  {
    text: "SAS Alert Tone 10",
    value: 9,
    audio: sasAlertTone10,
  },
  {
    text: "SAS Alert Tone 11",
    value: 10,
    audio: sasAlertTone11,
  },
  {
    text: "SAS Alert Tone 12",
    value: 11,
    audio: sasAlertTone12,
  },
  {
    text: "SAS Alert Tone 13",
    value: 12,
    audio: sasAlertTone13,
  },
  {
    text: "SAS Alert Tone 14",
    value: 13,
    audio: sasAlertTone14,
  },
  {
    text: "SAS Alert Tone 15",
    value: 14,
    audio: sasAlertTone15,
  },
  {
    text: "SAS Alert Tone 16",
    value: 15,
    audio: sasAlertTone16,
  },
  {
    text: "SAS Alert Tone 17",
    value: 16,
    audio: sasAlertTone17,
  },
  {
    text: "SAS Alert Tone 18",
    value: 17,
    audio: sasAlertTone18,
  },
  {
    text: "SAS Alert Tone 19",
    value: 18,
    audio: sasAlertTone19,
  },
  {
    text: "SAS Alert Tone 20",
    value: 19,
    audio: sasAlertTone20,
  },
  {
    text: "SAS Alert Tone 21",
    value: 20,
    audio: sasAlertTone21,
  },
  {
    text: "SAS Alert Tone 22",
    value: 21,
    audio: sasAlertTone22,
  },
  {
    text: "SAS Alert Tone 23",
    value: 22,
    audio: sasAlertTone23,
  },
  {
    text: "SAS Alert Tone 24",
    value: 23,
    audio: sasAlertTone24,
  },
];

export default sounds;
