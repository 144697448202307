import React from "react";
import { format } from "date-fns";
import { Requests } from "../../../api/IdentityServerRequests/Requests";
import copy from "../../../assets/copy/copy.json";
import styles from "./Report.module.scss";
import Wrapper from "../../../hoc/Wrapper/Wrapper";

const fileDownload = require("js-file-download");

export default class CSVDownload extends React.Component {
  constructor(props) {
    super(props);
    this.Requests = new Requests();
    this.fileDownload = fileDownload;
    this.state = {
      errorMessage: null,
    };
  }
  componentDidMount() {
    if (localStorage.report) {
      // Get stored criterias from local storage
      let fileType;
      let UsersId;
      let pagingNumber;
      let agency;

      if (localStorage.getItem("fileType")) {
        fileType = localStorage.getItem("fileType");
      }

      if (localStorage.getItem("UsersId")) {
        let usersId = localStorage.getItem("UsersId");
        let parsedUsers = JSON.parse(usersId);

        UsersId = parsedUsers;
      }
      if (localStorage.getItem("agency")) {
        let Agency = localStorage.getItem("agency");
        let parsedAgency = JSON.parse(Agency);

        agency = parsedAgency;
      }
      if (localStorage.getItem("pagingNumber")) {
        let PagingNumber = localStorage.getItem("pagingNumber");
        let parsedPagingNumber = JSON.parse(PagingNumber);

        pagingNumber = parsedPagingNumber;
      }

      if (localStorage.getItem("report")) {
        if (localStorage.getItem("report") === "event" && pagingNumber) {
          let details,
            dateTimeAfterOrEqual = localStorage.getItem("dateTimeAfterOrEqual")
              ? localStorage.getItem("dateTimeAfterOrEqual")
              : null,
            dateTimeBeforeOrEqual = localStorage.getItem(
              "dateTimeBeforeOrEqual"
            )
              ? localStorage.getItem("dateTimeBeforeOrEqual")
              : null,
            messageType = localStorage.getItem("messageType")
              ? JSON.parse(localStorage.getItem("messageType"))
              : null,
            pagingNumber = localStorage.getItem("pagingNumber")
              ? JSON.parse(localStorage.getItem("pagingNumber"))
              : null,
            attendanceStatus = localStorage.getItem("attendanceStatus")
              ? JSON.parse(localStorage.getItem("attendanceStatus"))
              : null;

          if (!attendanceStatus) {
            details = {
              agency: agency,
              dateTimeAfterOrEqual: dateTimeAfterOrEqual,
              dateTimeBeforeOrEqual: dateTimeBeforeOrEqual,
              messageType: messageType,
              pagingNumber: pagingNumber,
            };
          } else {
            details = {
              agency: agency,
              dateTimeAfterOrEqual: dateTimeAfterOrEqual,
              dateTimeBeforeOrEqual: dateTimeBeforeOrEqual,
              messageType: messageType,
              pagingNumber: pagingNumber,
              attendanceStatus: attendanceStatus,
            };
          }

          this.Requests.callAPI(
            this.Requests.downloadReport,
            "EVENT_OVERVIEW",
            fileType,
            details
          ).then((data) => {
            if (data && data.status && data.status === 200) {
              let date = format(new Date(), "yyyy-MM-dd");
              let fileName;
              if (data.data.type === "application/pdf") {
                // This is if you want to show the PDF instead of downloading it
                const file = new Blob([data.data], {
                  type: "application/pdf",
                });
                const fileURL = URL.createObjectURL(file);
                window.open(fileURL);

                fileName = "EventReport_" + date + ".pdf";
              } else {
                fileName = "EventReport_" + date + ".csv";
              }
              this.fileDownload(data.data, fileName);
              // show success msg
              localStorage.removeItem("eventReport");
              localStorage.removeItem("report");
              localStorage.removeItem("fileType");
              localStorage.removeItem("pagingNumber");
              localStorage.removeItem("UsersId");
              localStorage.removeItem("dateTimeAfterOrEqual");
              localStorage.removeItem("dateTimeBeforeOrEqual");
              localStorage.removeItem("messageType");
              localStorage.removeItem("attendanceStatus");
              setTimeout(function () {
                window.close();
              }, 500);
            } else {
              let ErrorMessage =
                copy.report.downloadEventOverviewReportError +
                ` (Error #${copy.errorCodes.downloadEventOverviewReportError})`;
              // if (data && data.data && data.data.SASMessageClient) {
              //   ErrorMessage = data.data.SASMessageClient;
              // }
              this.setState({
                errorMessage: ErrorMessage,
              });
            }
          });
          // remove report criterias from local storage
        } else if (localStorage.getItem("report") === "user" && UsersId) {
          let details = {
            agency: agency,
            usersId: UsersId.length ? UsersId : null,
            pagingNumber: pagingNumber.length ? pagingNumber : null,
          };
          this.Requests.callAPI(
            this.Requests.downloadReport,
            "USERS",
            fileType,
            details
          ).then((data) => {
            if (data && data.status && data.status === 200) {
              let date = format(new Date(), "yyyy-MM-dd");
              let fileName;
              if (data.data.type === "application/pdf") {
                fileName = "UserReport_" + date + ".pdf";
              } else {
                fileName = "UserReport_" + date + ".csv";
              }
              this.fileDownload(data.data, fileName);
              // show success msg
              localStorage.removeItem("eventReport");
              localStorage.removeItem("report");
              localStorage.removeItem("fileType");
              localStorage.removeItem("pagingNumber");
              localStorage.removeItem("UsersId");
              localStorage.removeItem("dateTimeAfterOrEqual");
              localStorage.removeItem("dateTimeBeforeOrEqual");
              localStorage.removeItem("messageType");
              localStorage.removeItem("attendanceStatus");
              setTimeout(function () {
                window.close();
              }, 500);
            } else {
              let ErrorMessage =
                copy.report.downloadUserReportError +
                ` (Error #${copy.errorCodes.downloadUserReportError})`;
              // if (data && data.data && data.data.SASMessageClient) {
              //   ErrorMessage = data.data.SASMessageClient;
              // }
              this.setState({
                errorMessage: ErrorMessage,
              });
            }
          });
        } else if (
          localStorage.getItem("report") === "availability" &&
          agency
        ) {
          let dateTimeAfterOrEqual = localStorage.getItem(
              "dateTimeAfterOrEqual"
            )
              ? localStorage.getItem("dateTimeAfterOrEqual")
              : null,
            dateTimeBeforeOrEqual = localStorage.getItem(
              "dateTimeBeforeOrEqual"
            )
              ? localStorage.getItem("dateTimeBeforeOrEqual")
              : null,
            pagingNumbers = localStorage.getItem("pagingNumbers")
              ? JSON.parse(localStorage.getItem("pagingNumbers"))
              : null,
            qualificationIds = localStorage.getItem("qualificationIds")
              ? JSON.parse(localStorage.getItem("qualificationIds"))
              : null,
            availabilityTypes = localStorage.getItem("availabilityTypes")
              ? JSON.parse(localStorage.getItem("availabilityTypes"))
              : null;

          let details = {
            dateTimeAfterOrEqual: dateTimeAfterOrEqual,
            dateTimeBeforeOrEqual: dateTimeBeforeOrEqual,
            pagingNumbers: pagingNumbers.length ? pagingNumbers : null,
            qualificationIds: qualificationIds.length ? qualificationIds : null,
            availabilityTypes: availabilityTypes.length
              ? availabilityTypes
              : null,
          };
          this.Requests.callAPI(
            this.Requests.downloadReport,
            "AVAILABILITY",
            fileType,
            details
          ).then((data) => {
            if (data && data.status && data.status === 200) {
              let date = format(new Date(), "yyyy-MM-dd");
              let fileName;
              if (data.data.type === "application/pdf") {
                // This is if you want to show the PDF instead of downloading it
                const file = new Blob([data.data], {
                  type: "application/pdf",
                });
                const fileURL = URL.createObjectURL(file);
                window.open(fileURL);

                fileName = "AvailabilityReport_" + date + ".pdf";
              } else {
                fileName = "AvailabilityReport_" + date + ".csv";
              }
              this.fileDownload(data.data, fileName);
              // show success msg
              localStorage.removeItem("eventReport");
              localStorage.removeItem("report");
              localStorage.removeItem("fileType");
              localStorage.removeItem("pagingNumbers");
              localStorage.removeItem("UsersId");
              localStorage.removeItem("dateTimeAfterOrEqual");
              localStorage.removeItem("dateTimeBeforeOrEqual");
              localStorage.removeItem("messageType");
              localStorage.removeItem("attendanceStatus");
              localStorage.removeItem("qualificationIds");
              localStorage.removeItem("availabilityTypes");
              setTimeout(function () {
                window.close();
              }, 500);
            } else {
              let ErrorMessage =
                copy.report.downloadAvailabilityReportError +
                ` (Error #${copy.errorCodes.downloadAvailabilityReportError})`;
              // if (data && data.data && data.data.SASMessageClient) {
              //   ErrorMessage = data.data.SASMessageClient;
              // }
              this.setState({
                errorMessage: ErrorMessage,
              });
            }
          });
          // remove report criterias from local storage
        }
      }
    }
  }

  render() {
    return (
      <div className="main-content-holder">
        <div className={styles.DownloadReportPage}>
          {this.state.errorMessage ? (
            <Wrapper>
              <h1>Something went wrong</h1>
              <p>{this.state.errorMessage}</p>
            </Wrapper>
          ) : (
            <Wrapper>
              <h1>Your report download will begin shortly</h1>
              <p>Please close this window once saved</p>
            </Wrapper>
          )}
        </div>
      </div>
    );
  }
}
