import React, { Component } from "react";
import Wrapper from "../../hoc/Wrapper/Wrapper";
import ErrorBanner from "../../hoc/ErrorBanner/ErrorBanner";
import Loading from "../UI/Loading/Loading";
import Icon from "../UI/Icon/Icon";
import Button from "../UI/Button/Button";
import styles from "./Chats.module.scss";
import copy from "../../assets/copy/copy.json";
import { withRouter } from "react-router-dom";
import { findPhoneNumbersInText } from "libphonenumber-js";

import { Requests } from "../../api/IdentityServerRequests/Requests";

const reactStringReplace = require("react-string-replace");

class ChatThread extends Component {
  constructor(props) {
    super(props);
    this.Requests = new Requests();
    this.state = {
      UserId: this.props.UserId,
      isLoading: false,
      errorMessage: null,
      invalidFields: false,
      chatMessageData: this.props.chatMessages,
      chatMessageThread: this.props.chatMessages.chatMessage,
      originalChatMessage: null,
      chatThreadId: this.props.chatMessages.id,
      isSendingMessage: false,
      textAreaInput: null,
      textAreaCharacterCount: 0,
      messageHeaderHeight: null,
      isTablet: window.innerWidth <= 1023 && window.innerWidth >= 768,
    };
  }
  showPhoneNumberUrls = (originalMessage) => {
    let newMessage = originalMessage;
    // Use library to find all instances of a phone number
    let phoneNumbers = findPhoneNumbersInText(originalMessage, "AU");
    // Go through each instance of the phone number found from the plugin
    phoneNumbers.forEach((phoneNumber, index) => {
      // Keep the actual text string
      let text = originalMessage.slice(
        phoneNumber.startsAt,
        phoneNumber.endsAt
      );
      // Extract the correct phone number
      let number = "tel:" + phoneNumber.number.number;
      // Create a new message with a link for the phone number
      newMessage = reactStringReplace(newMessage, text, () => {
        return (
          <a
            className="hyperlink-theme enableCopyPaste"
            href={number}
            onClick={(e) => {
              e.stopPropagation();
            }}
            key={phoneNumber.number.number + index}
          >
            {" "}
            {text}
          </a>
        );
      });
    });

    // HANDLE LOCAL NUMBERS WITHOUT AREA CODES
    // Formats handled "XXXXXXXX", "XXXX-XXXX", "XXXX XXXX"
    let regexPhone = /(?:\D|^)(\d{4}[ -]{0,1}\d{4})(?=\D|$)/g;
    // Use replace method to find all instances of the captured group and change the text into telephone links
    originalMessage.replace(regexPhone, (m, p1) => {
      newMessage = reactStringReplace(newMessage, p1, (match) => {
        return (
          <a
            key={m}
            className="hyperlink-theme enableCopyPaste"
            href={"tel:" + match}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {" "}
            {match}
          </a>
        );
      });
    });

    // Detect links (urls, emails) from the text and convert it into a hyperlink
    let regex =
      /[(http(s)?):\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gim; // eslint-disable-line
    originalMessage.replace(regex, (url) => {
      let index = 0;
      if (url.includes("@")) {
        //for emails
        newMessage = reactStringReplace(newMessage, url, () => {
          return (
            <a
              key={url + index++}
              className="hyperlink-theme enableCopyPaste"
              href={"mailto:" + url}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {url}
            </a>
          );
        });
      } else {
        //for links
        if (
          url.startsWith("https") ||
          url.startsWith("http") ||
          url.startsWith("HTTP") ||
          url.startsWith("HTTPS")
        ) {
          newMessage = reactStringReplace(newMessage, url, () => {
            if (window.cordova) {
              return (
                <a
                  key={url + index++}
                  className="hyperlink-theme enableCopyPaste"
                  target="_system"
                  rel="noopener noreferrer"
                  href={url}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    window.cordova.InAppBrowser.open(url, "_system");
                  }}
                >
                  {url}
                </a>
              );
            } else {
              return (
                <a
                  key={url + index++}
                  className="hyperlink-theme enableCopyPaste"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={url}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  {url}
                </a>
              );
            }
          });
        } else if (url.startsWith("www") || url.startsWith("WWW")) {
          newMessage = reactStringReplace(newMessage, url, () => {
            if (window.cordova) {
              return (
                <a
                  key={url + index++}
                  className="hyperlink-theme enableCopyPaste"
                  target="_system"
                  rel="noopener noreferrer"
                  href={"https://" + url}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    window.cordova.InAppBrowser.open(
                      "https://" + url,
                      "_system"
                    );
                  }}
                >
                  {url}
                </a>
              );
            } else {
              return (
                <a
                  key={url + index++}
                  className="hyperlink-theme enableCopyPaste"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={"https://" + url}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  {url}
                </a>
              );
            }
          });
        }
      }
    });

    //Emergency Markers links
    let emergencyMarkerRegex = /\b[A-Z]{3}[0-9]{3}\b/gm; // eslint-disable-line
    originalMessage.replace(emergencyMarkerRegex, (url) => {
      newMessage = reactStringReplace(newMessage, url, () => {
        if (window.cordova) {
          return (
            <a
              key={url}
              className="hyperlink-theme enableCopyPaste"
              target="_system"
              rel="noopener noreferrer"
              href={
                "https://www.iconyx.com/meet-me-at-the-marker/?marker=" + url
              }
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                window.cordova.InAppBrowser.open(
                  "https://www.iconyx.com/meet-me-at-the-marker/?marker=" + url,
                  "_system"
                );
              }}
            >
              {url}
            </a>
          );
        } else {
          return (
            <a
              key={url}
              className="hyperlink-theme enableCopyPaste"
              target="_blank"
              rel="noopener noreferrer"
              href={
                "https://www.iconyx.com/meet-me-at-the-marker/?marker=" + url
              }
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {url}
            </a>
          );
        }
      });
    });
    return newMessage;
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.UserId !== prevState.UserId) {
      return {
        UserId: nextProps.UserId,
      };
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.UserId !== prevProps.UserId) {
      this.setState({
        UserId: this.props.UserId,
      });
    }
    if (this.state.originalChatMessage !== prevState.originalChatMessage) {
      this.calculateHeaderHeight();
    }
  }

  componentDidMount() {
    this.calculateHeaderHeight();
    this.messageArrayConstructor();
    window.addEventListener("resize", this.handleWindowSizeChange);
    window.addEventListener("orientationchange", this.handleWindowSizeChange);
    if (window.cordova) {
      window.localStorage.removeItem("redirectToChat");
    }
  }

  handleWindowSizeChange = () => {
    this.setState({
      isTablet: window.innerWidth <= 1023 && window.innerWidth >= 768,
    });
  };

  messageArrayConstructor = () => {
    if (this.state.chatMessageThread[0]) {
      if (this.state.chatMessageData.chatType !== "OneOnOne") {
        const array = [...this.state.chatMessageThread];
        const originalMessage =
          this.state.chatMessageThread[this.state.chatMessageThread.length - 1]
            .message;
        const messageArray = array.slice(0, -1);

        this.setState({
          originalChatMessage: originalMessage,
          chatMessageThread: messageArray,
        });
      }
    }
  };

  // These are the replies to different chats on the SAS CHat page
  characterCount = (event) => {
    var count = event.target.value.length;
    this.setState({
      textAreaInput: event.target.value,
      textAreaCharacterCount: count,
    });
  };

  calculateHeaderHeight = () => {
    let MessageHeading;
    let OriginalMessage;
    let InputHolder;

    if (document.getElementById("message_heading")) {
      MessageHeading = document.getElementById("message_heading").clientHeight;
    } else {
      MessageHeading = 0;
    }
    if (document.getElementById("original_message")) {
      OriginalMessage =
        document.getElementById("original_message").clientHeight;
    } else {
      OriginalMessage = 0;
    }
    if (document.getElementById("input_holder")) {
      InputHolder = document.getElementById("input_holder").clientHeight;
    } else {
      InputHolder = 0;
    }
    const totalHeaderHeight = InputHolder + MessageHeading + OriginalMessage;

    this.setState({
      messageHeaderHeight: totalHeaderHeight,
    });
  };

  changeThreadActiveState = () => {
    const id = this.state.chatThreadId;
    const currentActiveStatus = this.state.chatMessageData.isActive;
    const body = !currentActiveStatus;

    this.setState(
      {
        isLoading: true,
      },
      () => {
        this.Requests.callAPI(
          this.Requests.putThreadActiveState,
          id,
          body
        ).then((data) => {
          if (data && data.status && data.status === 200) {
            this.setState(
              {
                isLoading: false,
              },
              () => {
                this.updateThread();
              }
            );
          } else {
            let ErrorMessage;
            if (body) {
              //Error message when were opening chat
              ErrorMessage =
                copy.sasChat.openChatStatusAPIErrorMessage +
                ` (Error #${copy.errorCodes.openChatStatusAPIErrorMessage})`;
            } else {
              //Error message when were closing chat
              ErrorMessage =
                copy.sasChat.closeChatStatusAPIErrorMessage +
                ` (Error #${copy.errorCodes.closeChatStatusAPIErrorMessage})`;
            }

            // if (data && data.data && data.data.SASMessageClient) {
            //   ErrorMessage = data.data.SASMessageClient;
            // }
            this.setState(
              { isLoading: false, errorMessage: ErrorMessage },
              () => {
                setTimeout(
                  function () {
                    this.setState({ errorMessage: null });
                  }.bind(this),
                  5000
                );
              }
            );
          }
        });
      }
    );
  };

  updateThread = () => {
    const id = this.state.chatThreadId;

    if (id) {
      this.Requests.callAPI(this.Requests.getChatThread, id).then((data) => {
        if (data && data.status && data.status === 200) {
          this.setState(
            {
              chatMessageData: data.data,
            },
            () => {
              this.calculateHeaderHeight();
            }
          );
        } else {
          let ErrorMessage =
            copy.sasChat.updateChatThreadAPIErrorMessage +
            ` (Error #${copy.errorCodes.updateChatThreadAPIErrorMessage})`;
          // if (data && data.data && data.data.SASMessageClient) {
          //   ErrorMessage = data.data.SASMessageClient;
          // }
          this.setState({ errorMessage: ErrorMessage }, () => {
            setTimeout(
              function () {
                this.setState({ errorMessage: null });
              }.bind(this),
              5000
            );
          });
        }
      });
    }
  };

  updateThreadMessages = () => {
    const id = this.state.chatThreadId;

    if (id) {
      this.Requests.callAPI(this.Requests.getChatThread, id).then((data) => {
        if (data && data.status && data.status === 200) {
          this.setState(
            {
              chatMessageThread: data.data.chatMessage,
            },
            () => {
              this.messageArrayConstructor();
            }
          );
        } else {
          let ErrorMessage =
            copy.sasChat.updateChatThreadAPIErrorMessage +
            ` (Error #${copy.errorCodes.updateChatThreadAPIErrorMessage})`;
          // if (data && data.data && data.data.SASMessageClient) {
          //   ErrorMessage = data.data.SASMessageClient;
          // }
          this.setState({ errorMessage: ErrorMessage }, () => {
            setTimeout(
              function () {
                this.setState({ errorMessage: null });
              }.bind(this),
              5000
            );
          });
        }
      });
    }
  };

  sendChatMessage = () => {
    const id = this.state.chatThreadId;
    const body = {
      message: this.state.textAreaInput,
    };

    if (!this.state.textAreaInput) {
      this.setState({
        invalidFields: true,
      });
    } else {
      this.setState(
        {
          isSendingMessage: true,
        },
        () => {
          this.Requests.callAPI(this.Requests.putChatMessage, id, body).then(
            (data) => {
              if (data && data.status && data.status === 200) {
                this.updateThreadMessages();
                this.setState({
                  isSendingMessage: false,
                  textAreaCharacterCount: 0,
                });
                document.getElementById("chatThreadTextArea").value = "";
              } else {
                let ErrorMessage =
                  copy.sasChat.replyChatMessageAPIErrorMessage +
                  ` (Error #${copy.errorCodes.replyChatMessageAPIErrorMessage})`;

                if (
                  data &&
                  data.status &&
                  data.status === 400 &&
                  data.data.SASENumErrorCode === 53
                ) {
                  ErrorMessage =
                    copy.sasChat.prohibitedWordInChatMessageAPIErrorMessage;
                }
                this.setState(
                  { isSendingMessage: false, errorMessage: ErrorMessage },
                  () => {
                    setTimeout(
                      function () {
                        this.setState({ errorMessage: null });
                      }.bind(this),
                      5000
                    );
                  }
                );
              }
            }
          );
        }
      );
    }
  };

  redirectToEvent = (currentEvent) => {
    this.props.history.push({
      pathname: "/eventdetails/" + currentEvent,
      // pathname: "/settings/availability"
    });
  };

  // Scrolls the screen back to the top
  scrollToTop = () => {
    window.scrollTo(document.body.scrollLeft, document.body.scrollTop);
  };

  // converts date/time so Safari reads it properly
  convertDate = (date) => {
    if (date) {
      return date
        .replace(/-/g, "/")
        .replace("T", " ")
        .replace(/\..*|\+.*/, "");
    } else {
      return null;
    }
  };

  render() {
    const {
      chatType,
      title,
      subject,
      dateTimeCreated,
      isActive,
      threadOwner,
      isReadThread,
      recipientName,
    } = this.state.chatMessageData;
    let recipients;
    if (recipientName) {
      if (recipientName.length > 1) {
        recipients =
          recipientName[0] + " and " + recipientName.length + " more";
      } else {
        recipients = recipientName[0];
      }
    }

    const timeCreated = new Date(
      this.convertDate(dateTimeCreated)
    ).toLocaleTimeString();
    const dateCreated = new Date(this.convertDate(dateTimeCreated))
      .toLocaleDateString()
      .replace(/\//g, "-");

    if (this.state.chatMessageThread !== null) {
      this.ChatMessages = this.state.chatMessageThread.map((item, key) => {
        let messageOwnerInitials;
        if (item.userInfo) {
          messageOwnerInitials = item.userInfo.name.match(/\b(\w)/g);
        }
        const messageTime = new Date(
          this.convertDate(item.timeStamp)
        ).toLocaleTimeString();
        const messageDate = new Date(this.convertDate(item.timeStamp))
          .toLocaleDateString()
          .replace(/\//g, "-");
        return (
          <div
            key={key}
            className={styles.chatMessage + " " + styles.chatReplies}
          >
            <div className={styles.messageHeader}>
              <div className="timeStamp">
                <span className="time enableCopyPaste">{messageTime}</span>
                <span className="date enableCopyPaste">{messageDate}</span>
              </div>
            </div>
            <Icon
              label="Chat bubble"
              content={messageOwnerInitials}
              styles=""
              icon={"icon_chat"}
            />
            <div
              className={[styles.messageHolder, styles.chatThread].join(" ")}
            >
              <span
                className={styles.name + " txt2 enableCopyPaste"}
                itemProp="name"
              >
                {item.userInfo && item.userInfo.name}
              </span>
              <span
                className={
                  styles.message +
                  " enableCopyPaste smartlookMask smartlook-hide"
                }
              >
                {item.message
                  ? this.showPhoneNumberUrls(item.message)
                  : item.message}
              </span>
            </div>
          </div>
        );
      });
    }

    return (
      <Wrapper>
        <ErrorBanner
          isVisible={this.state.errorMessage ? true : false}
          ErrorMessage={this.state.errorMessage}
        />
        <div className={styles.threadHeader + " Section-titles"}>
          <div className={styles.title}>
            {chatType === "Event" && (
              <Wrapper>
                <span>
                  {title === "Emergency"
                    ? "EMERGNCY"
                    : title === "NonEmergency"
                    ? "NON EMERGENCY"
                    : title}
                </span>
                <h2>{subject.replace(/_/g, " ")}</h2>
              </Wrapper>
            )}
            {chatType === "Group" && (
              <Wrapper>
                <span>{threadOwner && threadOwner.name}</span>
                <h2>{title.replace(/_/g, " ")}</h2>
              </Wrapper>
            )}
            {chatType === "AdhocGroup" && (
              <Wrapper>
                <span>{threadOwner && threadOwner.name}</span>
                <h2>{recipients}</h2>
              </Wrapper>
            )}
            {chatType === "OneOnOne" && <h2>{this.props.RecipientName}</h2>}
          </div>
          {threadOwner &&
          threadOwner.userId &&
          this.state.UserId === threadOwner.userId ? (
            <div className={styles.changeThreadStatus}>
              {this.state.chatMessageData.isActive ? (
                <Button
                  label="end chat"
                  content={copy.global.btnEndChat}
                  variant="btn_outline"
                  styles="greenTxt"
                  style={{ minWidth: "84px" }}
                  icon=""
                  buttonClick={this.changeThreadActiveState}
                />
              ) : (
                <Button
                  label="open chat"
                  content={copy.global.btnOpenChat}
                  variant="btn_outline"
                  styles="greenTxt"
                  style={{ minWidth: "92px" }}
                  icon=""
                  buttonClick={this.changeThreadActiveState}
                />
              )}
            </div>
          ) : (
            this.state.isTablet && (
              <Button
                icon=""
                label="Back"
                content={copy.global.btnBack}
                variant="btn_outline"
                styles="btn_secondary"
                buttonClick={this.props.closeThread}
              />
            )
          )}
          {chatType === "Event" && (
            <Button
              label="View event details"
              content="View event"
              variant="btn_outline"
              styles="btn_secondary"
              buttonClick={this.redirectToEvent}
              paramVal={this.state.chatMessageData.eventId}
            />
          )}
        </div>
        <div className={styles.contentWrapper + " box_default"}>
          {this.state.isLoading ? (
            <div className={styles.loadingContainer}>
              <Loading />
            </div>
          ) : (
            <Wrapper>
              {chatType !== "Event" && (
                <div
                  id="message_heading"
                  className={styles.originalMessageHeading}
                >
                  <span className={styles.RE}>{copy.sasChat.replyShort}</span>
                  <span>{subject}</span>
                </div>
              )}
              {chatType !== "OneOnOne" && (
                <div
                  id="original_message"
                  className={[
                    styles.chatMessage,
                    styles.originalMessage,
                    styles.originalChat,
                  ].join(" ")}
                >
                  {chatType === "Event" ? (
                    <div className={styles.eventOriginalMessageWrapper}>
                      <div className={styles.icon + " enableCopyPaste"}>
                        <Icon
                          label="Chat bubble"
                          content={
                            title && title === "Emergency"
                              ? "E"
                              : title && title === "NonEmergency" && "NE"
                          }
                          variant={
                            isReadThread ? "unreadMessageIndicatorTemp" : ""
                          }
                          styles={[
                            "event ",
                            title && title === "Emergency"
                              ? "emergency"
                              : title &&
                                title === "NonEmergency" &&
                                "nonEmergency",
                          ].join(" ")}
                          icon={"icon_chat"}
                        />
                      </div>

                      <div>
                        <div
                          className={["timeStamp", styles.timeStamp].join(" ")}
                        >
                          {chatType !== "Event" && (
                            <span className={styles.original}>
                              {copy.sasChat.originalMsg}
                            </span>
                          )}
                          <div>
                            <span className="time enableCopyPaste">
                              {timeCreated}
                            </span>
                            <span className="date enableCopyPaste">
                              {dateCreated}
                            </span>
                          </div>
                        </div>
                        <span
                          className={[
                            styles.messageHolder,
                            styles.chatThread,
                          ].join(" ")}
                        >
                          <span
                            className={
                              styles.message +
                              " enableCopyPaste smartlookMask smartlook-hide"
                            }
                          >
                            {this.state.originalChatMessage
                              ? this.showPhoneNumberUrls(
                                  this.state.originalChatMessage
                                )
                              : this.state.originalChatMessage}
                          </span>
                        </span>
                      </div>
                    </div>
                  ) : (
                    <Wrapper>
                      <div
                        className={["timeStamp", styles.timeStamp].join(" ")}
                      >
                        {chatType !== "Event" && (
                          <span className={styles.original}>
                            {copy.sasChat.originalMsg}
                          </span>
                        )}
                        <div>
                          <span className="time enableCopyPaste">
                            {timeCreated}
                          </span>
                          <span className="date enableCopyPaste">
                            {dateCreated}
                          </span>
                        </div>
                      </div>
                      <span
                        className={[
                          styles.messageHolder,
                          styles.chatThread,
                        ].join(" ")}
                      >
                        <span
                          className={
                            styles.message +
                            " enableCopyPaste smartlookMask smartlook-hide"
                          }
                        >
                          {this.state.originalChatMessage
                            ? this.showPhoneNumberUrls(
                                this.state.originalChatMessage
                              )
                            : this.state.originalChatMessage}
                        </span>
                      </span>
                    </Wrapper>
                  )}
                </div>
              )}
              {isActive && (
                <div id="input_holder" className={styles.messageInput_holder}>
                  <div className={styles.chatHeader}>
                    {chatType === "AdhocGroup" ||
                    chatType === "Group" ||
                    chatType === "Event" ? (
                      <label>Enter your reply</label>
                    ) : (
                      <label>
                        {copy.sasChat.reply}
                        {this.props.RecipientName}
                      </label>
                    )}
                    <span>
                      {this.state.textAreaCharacterCount}
                      {copy.sasChat.characters}
                    </span>
                  </div>
                  <textarea
                    className={
                      this.state.invalidFields ? styles.errorTextField : ""
                    }
                    id="chatThreadTextArea"
                    aria-label="Reply to message here"
                    name="reply to message"
                    placeholder="Enter your message"
                    maxLength="512"
                    onChange={this.characterCount}
                    onBlur={this.scrollToTop}
                  />
                  {this.state.invalidFields && (
                    <span className={styles.errorMessage}>
                      {copy.error_handling.required}
                    </span>
                  )}
                  <div className={styles.postTxtBtn}>
                    <p>{copy.sasChat.messageDesc}</p>
                    {this.state.isSendingMessage ? (
                      <Loading />
                    ) : (
                      <Button
                        label="your location"
                        content={copy.global.btnSend}
                        variant="btn_outline"
                        styles="greenTxt"
                        icon="icon_post"
                        buttonClick={this.sendChatMessage}
                      />
                    )}
                  </div>
                </div>
              )}

              {this.state.chatMessageThread.length > 0 ? (
                <Wrapper>
                  <div className={styles.repliesHeading}>
                    <span>{copy.sasChat.replies}</span>
                  </div>
                  <div className={styles.messagesHolder}>
                    {this.state.chatMessageThread && this.ChatMessages}
                  </div>
                </Wrapper>
              ) : null}
            </Wrapper>
          )}
        </div>
      </Wrapper>
    );
  }
}

export default withRouter(ChatThread);
