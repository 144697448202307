import { GridLayer, withLeaflet } from 'react-leaflet'
import L from 'leaflet'

// This class is used to create a class which extends Leaflet's TileLayer class, so that custom tile URL naming schemes can be provided
class ExtendableTileLayer extends GridLayer {
    // Creation of a new Leaflet element instance
    createLeafletElement(props) {
        const ExtendableTileLayer = L.TileLayer.extend({
            // Alter the method used to return the URL for a tile
            getTileUrl: function(coords) {
                coords = props.urlFunction(coords)
                return props.returnUrl +coords.z+'/'+coords.y+'/'+coords.x+'.png';
            }
        })
        return new ExtendableTileLayer(props.url, this.getOptions(props))
    }
}

export default withLeaflet(ExtendableTileLayer)
