import React, { Component } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

class Tab extends Component {
  static propTypes = {
    activeTab: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    handleClick: PropTypes.func.isRequired,
  };

  handleClick = () => {
    const { content, handleClick, index } = this.props;
    handleClick(content, index);
  };

  render() {
    const {
      handleClick,
      props: {
        activeTab,
        taburl,
        content,
        label,
        style,
        variant,
        icon,
        index,
        noHistory,
      },
    } = this;

    let className = "tab-list-item";
    if (activeTab === content) {
      className += " tab-list-active";
    }

    if (!noHistory) {
      return (
        <NavLink
          key={index}
          aria-label={label}
          className={className + " " + style + " " + variant + " " + icon}
          to={taburl}
          onClick={this.props.customTabClick}>
          {content}
        </NavLink>
      );
    } else {
      return (
        <li
          key={index}
          aria-label={label}
          className={className + " " + style + " " + variant + " " + icon}
          // {...(window.cordova
          //   ? { onTouchStart: handleClick }
          //   : { onClick: handleClick })}
          {...{ onClick: handleClick }}>
          {content}
        </li>
      );
    }
  }
}

export default Tab;
