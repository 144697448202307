import React from "react";
import Wrapper from "../../hoc/Wrapper/Wrapper";
import { withRouter } from "react-router-dom";
import copy from "../../assets/copy/copy.json";
import { Requests } from "../../api/IdentityServerRequests/Requests";
import Timer from "../../components/Timer/Timer";
import Loader from "../../components/UI/Loading/Loading";
import styles from "./TurnoutView.module.scss";

class ActiveEventTimer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      event: this.props.isExpandedView ? this.props.event : null,
      isLoading: true,
      isExpandedView: this.props.isExpandedView,
      errorMessage: null, // Error message for the banner
    };
    this.Requests = new Requests();
  }

  componentDidUpdate(prevProps) {
    //only call API when timer is in the Fit to Screen View
    if (!this.state.isExpandedView) {
      if (
        prevProps.eventID !== this.props.eventID ||
        prevProps.updateEvent !== this.props.updateEvent
      ) {
        let activeEventID = this.props.eventID;
        if (activeEventID) {
          this.getActiveEventDetails(activeEventID, true);
        } else {
          this.setState({ event: null, isLoading: false });
        }
      }

      if (
        prevProps.loadTimer !== this.props.loadTimer &&
        this.props.loadTimer
      ) {
        this.setState({ event: null, isLoading: true });
      }
    }
  }

  getActiveEventDetails(id, showLoader) {
    this.setState(
      {
        isLoading: showLoader,
      },
      () => {
        this.Requests.callAPI(this.Requests.getEventDetails, id).then(
          (data) => {
            if (data && data.status && data.status === 200) {
              this.setState({ event: data.data, isLoading: false });
            } else {
              let ErrorMessage =
                copy.dashboard.getEventDetailsAPIErrorMessage +
                ` (Error #${copy.errorCodes.getEventDetailsAPIErrorMessage})`;
              this.setState({ errorMessage: ErrorMessage });
            }
          }
        );
      }
    );
  }

  render() {
    return (
      <Wrapper>
        <div
          className={
            (this.state.isExpandedView
              ? styles.expandedTimerHeader
              : styles.timerHeader) + " turnoutTimerBorder"
          }
        >
          {this.state.event && this.state.event.eventStatus === "Open" ? (
            <Timer
              initialTimestamp={this.state.event.messages}
              eventStatus={this.state.event.eventStatus}
              turnoutHeader={true}
              readableTimerText={this.props.readableTimerText}
            />
          ) : (
            <div className={styles.timerLoading}>
              {this.state.isLoading ? (
                <Loader />
              ) : (
                <h4>NO ACTIVE TIMER TO DISPLAY</h4>
              )}
            </div>
          )}
        </div>
      </Wrapper>
    );
  }
}
export default withRouter(ActiveEventTimer);
