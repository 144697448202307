import React, { Component } from "react";
// Layouts
import Accordion from "../UI/Accordion/Accordion";
// import Loader from "../UI/Loading/Loading";
// Data
import copy from "../../assets/copy/copy.json";
// styles
import styles from "./InsertHelpTile.module.scss";

export default class InsertHelpTile extends Component {
  render() {
    return (
      <div>
        <Accordion
          button={true}
          label="Close Help Section"
          content={copy.settings.profile.closeTermsOfUse}
          variant="btn_outline"
          styles="greenTxt"
          buttonClick={this.props.getHelpSection}
        >
          <div key="117" label={copy.settings.profile.helpSectionTitle}>
            {this.props.sections.map((section) => (
              <div className={styles.content}>
                <span className={styles.title}>{section.heading}</span>
                {section.helpSectionContent.map((content) => (
                  <div>
                    <span>{content.descriptor}: </span>
                    {content.renderAs === "hyperlink" ? 
                      window.cordova ? (
                        <a
                          className="hyperlink-theme"
                          target="_system"
                          rel="noopener noreferrer"
                          href={content.contentString}
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            window.cordova.InAppBrowser.open(content.contentString,"_system");
                          }}
                        >
                          {content.contentString}
                        </a>
                      ) : (
                        <a
                          className="hyperlink-theme"
                          href={content.contentString}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          {content.contentString}
                        </a>
                      )
                     : (
                      <a
                        className="hyperlink-theme"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={"mailto:" + content.contentString}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        {content.contentString}
                      </a>
                    )}
                  </div>
                ))}
              </div>
            ))}
          </div>
        </Accordion>
      </div>
    );
  }
}
