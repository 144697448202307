import React, { Component } from "react";
import Wrapper from "../../hoc/Wrapper/Wrapper";
import Icon from "../../components/UI/Icon/Icon";
import styles from "./AttendanceCount.module.scss";

import { matchPath } from "react-router";
import { withRouter } from "react-router-dom";

class AttendanceCount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timerOn: false,
    };
  }

  render() {
    const isTurnout = !!matchPath(this.props.location.pathname, "/turnout");
    return (
      <Wrapper>
        <div className={styles.attendanceCount}>
          <div className={isTurnout ? styles.countLarge : styles.count}>
            {this.props.attending !== null && (
              <Icon
                label="attending"
                content="attending"
                variant="icon_round"
                styles="greenBg"
                icon="icon_tick"
              />
            )}
            {this.props.attending !== null && this.props.attending}
          </div>
          <div className={isTurnout ? styles.countLarge : styles.count}>
            {this.props.other !== null && (
              <Icon
                label="other"
                content="other"
                variant="icon_round"
                styles="yellowBg"
                icon="icon_dots"
              />
            )}
            {this.props.other !== null && this.props.other}
          </div>
          <div className={isTurnout ? styles.countLarge : styles.count}>
            {this.props.notAttending !== null && (
              <Icon
                label="Not attending this event"
                content="unavailable"
                variant="icon_round"
                styles="redBg"
                icon="icon_cross"
              />
            )}
            {this.props.notAttending !== null && this.props.notAttending}
          </div>
        </div>
      </Wrapper>
    );
  }
}
export default withRouter(AttendanceCount);
