import React, { Component } from "react";
import Wrapper from "../../hoc/Wrapper/Wrapper";
// Layouts
//import Accordion from "../UI/Accordion/Accordion";
// Page UIs & styles
import Button from "../../components/UI/Button/Button";
import Loader from "../../components/UI/Loading/Loading";
import copy from "../../assets/copy/copy.json";
import { Requests } from "../../api/IdentityServerRequests/Requests";
import ErrorBanner from "../../hoc/ErrorBanner/ErrorBanner";
// styles
// import styles from "./AdvanceSettings.module.scss";
// Images
import SES from "../../assets/img/logos/logo_ses.png";
import CFA from "../../assets/img/logos/logo_cfa.png";
import AV from "../../assets/img/logos/logo_av.png";

export default class AdvanceSettingsTile extends Component {
  constructor(props) {
    super(props);
    this.Requests = new Requests();
    this.state = {
      errorMessage: null,
      AVUsers: null,
      SESUsers: null,
      CFAUsers: null,
      fetchingData: true,
      toggleAV: false,
      toggleSES: false,
      toggleCFA: false,
      userAgencies: this.props.userAgencies, // All agencies the user has permission to view
    };
  }

  componentDidMount() {
    // Fetch concurrent devices on componenet load
    this.Requests.callAPI(this.Requests.getApplicationsSettings).then(
      (data) => {
        if (data && data.data && data.status && data.status === 200) {
          // Null pointer checks to see if required data is returned
          let AVConcurrentDevices = false,
            SESConcurrentDevices = false,
            CFAConcurrentDevices = false;
          for (let i = 0; i < data.data.length; i++) {
            if (data.data[i]) {
              let currentAgency = data.data[i].agency;
              if (currentAgency === "AV") {
                AVConcurrentDevices = data.data[i].userConcurrentDevices;
              } else if (currentAgency === "SES") {
                SESConcurrentDevices = data.data[i].userConcurrentDevices;
              } else if (currentAgency === "CFA") {
                CFAConcurrentDevices = data.data[i].userConcurrentDevices;
              }
            }
          }
          // Sets state of the concurrent devices
          this.setState({
            AVUsers: AVConcurrentDevices,
            SESUsers: SESConcurrentDevices,
            CFAUsers: CFAConcurrentDevices,
            fetchingData: false,
          });
        } else {
          let ErrorMessage =
            copy.admin.advancedSettings.concurrentDevicesAPIErrorMessageGET +
            ` (Error #${copy.errorCodes.concurrentDevicesAPIErrorMessageGET})`;
          // if (data && data.data && data.data.SASMessageClient) {
          //   ErrorMessage = data.data.SASMessageClient;
          // }
          this.setState(
            {
              errorMessage: ErrorMessage,
              fetchingData: false,
              AVUsers: false,
              SESUsers: false,
              CFAUsers: false,
            },
            () => {
              setTimeout(
                function () {
                  this.setState({ errorMessage: null });
                }.bind(this),
                5000
              );
            }
          );
        }
      }
    );
  }

  /**
   * This function handles what happens when the AV edit button is clicked
   */
  AVButtonClick = () => {
    // Check if the toggle is on
    if (this.state.toggleAV) {
      // Make the API call to update the latest device count
      this.Requests.callAPI(
        this.Requests.setConcurrentUserDevices,
        "AV",
        this.state.AVUsers
      ).then((data) => {
        if (data && data.status && data.status === 200) {
          // The count has been updated, close the toggle
          this.setState({
            toggleAV: !this.state.toggleAV,
          });
        } else {
          // Show error message if API failed.
          let ErrorMessage =
            copy.admin.advancedSettings.savingDevicesAPIErrorMessage +
            ` (Error #${copy.errorCodes.savingDevicesAPIErrorMessage})`;
          // if (data && data.data && data.data.SASMessageClient) {
          //   ErrorMessage = data.data.SASMessageClient;
          // }
          this.setState(
            {
              errorMessage: ErrorMessage,
            },
            () => {
              setTimeout(
                function () {
                  this.setState({ errorMessage: null });
                }.bind(this),
                5000
              );
            }
          );
        }
      });
    } else {
      // Open the Edit Settings since the toggle is off right now
      this.setState({
        toggleAV: !this.state.toggleAV,
      });
    }
  };

  /**
   * This function handles what happens when the SES edit button is clicked
   */
  SESButtonClick = () => {
    // Check if the toggle is on
    if (this.state.toggleSES) {
      // Make the API call to update the latest device count
      this.Requests.callAPI(
        this.Requests.setConcurrentUserDevices,
        "SES",
        this.state.SESUsers
      ).then((data) => {
        if (data && data.status && data.status === 200) {
          // The count has been updated, close the toggle
          this.setState({
            toggleSES: !this.state.toggleSES,
          });
        } else {
          // Show error message if API failed.
          let ErrorMessage =
            copy.admin.advancedSettings.savingDevicesAPIErrorMessage +
            ` (Error #${copy.errorCodes.savingDevicesAPIErrorMessage})`;
          // if (data && data.data && data.data.SASMessageClient) {
          //   ErrorMessage = data.data.SASMessageClient;
          // }
          this.setState(
            {
              errorMessage: ErrorMessage,
            },
            () => {
              setTimeout(
                function () {
                  this.setState({ errorMessage: null });
                }.bind(this),
                5000
              );
            }
          );
        }
      });
    } else {
      // Open the Edit Settings since the toggle is off right now
      this.setState({
        toggleSES: !this.state.toggleSES,
      });
    }
  };

  /**
   * This function handles what happens when the CFA edit button is clicked
   */
  CFAButtonClick = () => {
    // Check if the toggle is on
    if (this.state.toggleCFA) {
      // Make the API call to update the latest device count
      this.Requests.callAPI(
        this.Requests.setConcurrentUserDevices,
        "CFA",
        this.state.CFAUsers
      ).then((data) => {
        if (data && data.status && data.status === 200) {
          // The count has been updated, close the toggle
          this.setState({
            toggleCFA: !this.state.toggleCFA,
          });
        } else {
          // Show error message if API failed.
          let ErrorMessage =
            copy.admin.advancedSettings.savingDevicesAPIErrorMessage +
            ` (Error #${copy.errorCodes.savingDevicesAPIErrorMessage})`;
          // if (data && data.data && data.data.SASMessageClient) {
          //   ErrorMessage = data.data.SASMessageClient;
          // }
          this.setState(
            {
              errorMessage: ErrorMessage,
            },
            () => {
              setTimeout(
                function () {
                  this.setState({ errorMessage: null });
                }.bind(this),
                5000
              );
            }
          );
        }
      });
    } else {
      // Open the Edit Settings since the toggle is off right now
      this.setState({
        toggleCFA: !this.state.toggleCFA,
      });
    }
  };
  render() {
    if (this.state.fetchingData) {
      return (
        <Wrapper>
          <div className="box_default">
            <div className="Sub-Section-titles">
              <h3>Advanced Settings</h3>
            </div>
            <Loader />
          </div>
        </Wrapper>
      );
    } else {
      let AVButtonText = copy.admin.advancedSettings.editDevices,
        CFAButtonText = copy.admin.advancedSettings.editDevices,
        SESButtonText = copy.admin.advancedSettings.editDevices;
      if (this.state.toggleAV) {
        AVButtonText = copy.admin.advancedSettings.saveDevices;
      }
      if (this.state.toggleCFA) {
        CFAButtonText = copy.admin.advancedSettings.saveDevices;
      }
      if (this.state.toggleSES) {
        SESButtonText = copy.admin.advancedSettings.saveDevices;
      }
      return (
        <Wrapper>
          <ErrorBanner
            isVisible={this.state.errorMessage ? true : false}
            ErrorMessage={this.state.errorMessage}
          />
          <div className="box_default">
            <div className="Sub-Section-titles">
              <h3>Advanced Settings</h3>
            </div>
            <ul className="settingOptions">
              {this.props.userAgencies.indexOf("AV") > -1 && (
                <li>
                  <div>
                    <div className="holder">
                      <span className="logoHolder">
                        <img alt="Ambulance Victoria logo" src={AV} />
                      </span>
                      <span>
                        <span className="primaryText">AV Concurrent Users</span>
                        <span className="subText">
                          {this.state.AVUsers !== false
                            ? "Set at a max of " +
                              this.state.AVUsers +
                              " logins per user"
                            : copy.admin.advancedSettings.agencyErrorMessage}
                        </span>
                      </span>
                    </div>
                    {this.state.AVUsers !== false && (
                      <Button
                        label={AVButtonText}
                        content={AVButtonText}
                        variant="btn_secondary"
                        styles=""
                        icon=""
                        buttonClick={this.AVButtonClick}
                      />
                    )}
                  </div>
                  <div
                    className={this.state.toggleAV === true ? "show" : "hide"}>
                    <div className="holder">
                      <p className="largeText">
                        {this.state.AVUsers} Logins / User
                      </p>
                      <div>
                        <Button
                          label="subtract"
                          content=""
                          variant="btn_sqaure"
                          styles={this.state.AVUsers <= 0 ? "disabled" : ""}
                          icon="icon_reduce"
                          buttonClick={() => {
                            if (this.state.AVUsers > 0) {
                              this.setState({
                                AVUsers: this.state.AVUsers - 1,
                              });
                            }
                          }}
                        />
                        <Button
                          label="add"
                          content=""
                          variant="btn_sqaure"
                          styles
                          icon="icon_add"
                          buttonClick={() =>
                            this.setState({ AVUsers: this.state.AVUsers + 1 })
                          }
                        />
                      </div>
                    </div>
                    <span className="subText">
                      Change the quantity of concurrent active logins a user can
                      have within your agency.
                    </span>
                  </div>
                </li>
              )}
              {this.props.userAgencies.indexOf("SES") > -1 && (
                <li>
                  <div>
                    <div>
                      <div className="holder">
                        <span className="logoHolder">
                          <img
                            alt="Victoria State Emergency Service logo"
                            src={SES}
                          />
                        </span>
                        <span>
                          <span className="primaryText">
                            SES Concurrent Users
                          </span>
                          <span className="subText">
                            {this.state.SESUsers !== false
                              ? "Set at a max of " +
                                this.state.SESUsers +
                                " logins per user"
                              : copy.admin.advancedSettings.agencyErrorMessage}
                          </span>
                        </span>
                      </div>
                    </div>
                    {this.state.SESUsers !== false && (
                      <Button
                        label={SESButtonText}
                        content={SESButtonText}
                        variant="btn_secondary"
                        styles=""
                        icon=""
                        buttonClick={this.SESButtonClick}
                      />
                    )}
                  </div>

                  <div
                    className={this.state.toggleSES === true ? "show" : "hide"}>
                    <div className="holder">
                      <p className="largeText">
                        {this.state.SESUsers} Logins / User
                      </p>

                      <div>
                        <Button
                          label="subtract"
                          content=""
                          variant="btn_sqaure"
                          styles={this.state.SESUsers <= 0 ? "disabled" : ""}
                          icon="icon_reduce"
                          buttonClick={() => {
                            if (this.state.SESUsers > 0) {
                              this.setState({
                                SESUsers: this.state.SESUsers - 1,
                              });
                            }
                          }}
                        />
                        <Button
                          label="add"
                          content=""
                          variant="btn_sqaure"
                          styles=" "
                          icon="icon_add"
                          buttonClick={() =>
                            this.setState({ SESUsers: this.state.SESUsers + 1 })
                          }
                        />
                      </div>
                    </div>
                    <span className="subText">
                      Change the quantity of concurrent active logins a user can
                      have within your agency.
                    </span>
                  </div>
                </li>
              )}
              {this.props.userAgencies.indexOf("CFA") > -1 && (
                <li>
                  <div>
                    <div>
                      <div className="holder">
                        <span className="logoHolder">
                          <img alt="Country Fire Authority logo" src={CFA} />
                        </span>
                        <span>
                          <span className="primaryText">
                            CFA Concurrent Users
                          </span>
                          <span className="subText">
                            {this.state.CFAUsers !== false
                              ? "Set at a max of " +
                                this.state.CFAUsers +
                                " logins per user"
                              : copy.admin.advancedSettings.agencyErrorMessage}
                          </span>
                        </span>
                      </div>
                    </div>
                    {this.state.CFAUsers !== false && (
                      <Button
                        label={CFAButtonText}
                        content={CFAButtonText}
                        variant="btn_secondary"
                        styles=""
                        icon=""
                        buttonClick={this.CFAButtonClick}
                      />
                    )}
                  </div>

                  <div
                    className={this.state.toggleCFA === true ? "show" : "hide"}>
                    <div className="holder">
                      <p className="largeText">
                        {this.state.CFAUsers} Logins / User
                      </p>

                      <div>
                        <Button
                          label="subtract"
                          content=""
                          variant="btn_sqaure"
                          styles={this.state.CFAUsers <= 0 ? "disabled" : ""}
                          icon="icon_reduce"
                          buttonClick={() => {
                            if (this.state.CFAUsers > 0) {
                              this.setState({
                                CFAUsers: this.state.CFAUsers - 1,
                              });
                            }
                          }}
                        />
                        <Button
                          label="add"
                          content=""
                          variant="btn_sqaure"
                          styles=" "
                          icon="icon_add"
                          buttonClick={() =>
                            this.setState({ CFAUsers: this.state.CFAUsers + 1 })
                          }
                        />
                      </div>
                    </div>
                    <span className="subText">
                      Change the quantity of concurrent active logins a user can
                      have within your agency.
                    </span>
                  </div>
                </li>
              )}{" "}
            </ul>
          </div>
        </Wrapper>
      );
    }
  }
}
