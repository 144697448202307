import React, { Component } from "react";
import PropTypes from "prop-types";

import Tab from "./Tab";

class Tabs extends Component {
  static propTypes = {
    children: PropTypes.instanceOf(Array).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      currentTab: this.props.children[0].props.content,
      tabCount: this.props.children.length,
    };
    this.slider = {
      ref: null,
    };
    this.initSlider = this.initSlider.bind(this);
  }

  componentDidMount = () => {
    if (!this.props.noHistory) {
      this.onClickTabItem(this.props.activeTab, this.props.activeTabNumber);
    }
  };

  componentDidUpdate = () => {
    if (!this.props.noHistory) {
      this.onClickTabItem(null, this.props.activeTabNumber);
    }
  };

  initSlider = () => {
    if (this.props.showSlider) {
      this.slider.ref = React.createRef();
      let width = parseFloat(100 / this.state.tabCount) + "%";
      let left = null;
      // localStorage manages previous index of tab slider. This is due to the component unmounting on route changes
      if (window.localStorage.getItem("tabSliderIndex")) {
        left =
          parseFloat(
            (100 / this.state.tabCount) *
              window.localStorage.getItem("tabSliderIndex")
          ).toFixed(2) + "%";
      } else {
        left =
          parseFloat(
            (100 / this.state.tabCount) * this.props.activeTabNumber
          ).toFixed(2) + "%";
      }
      // saves current tab index to localStorage
      window.localStorage.setItem("tabSliderIndex", this.props.activeTabNumber);
      return (
        <div
          className="slider"
          ref={this.slider.ref}
          style={{ width: width, left: left }}></div>
      );
    }
  };

  /**
   * This function is used to update the tab slider when it is clicked
   */
  onClickTabItem = (tab, index) => {
    if (this.props.buttonClick) {
      this.props.buttonClick(tab);
    }
    if (this.props.noHistory) {
      this.setState({ currentTab: tab });
    }
    if (this.props.showSlider) {
      let translation =
        parseFloat((100 / this.state.tabCount) * index).toFixed(2) + "%";
      this.slider.ref.current.style.left = translation;
    }
  };

  render() {
    const {
      props: { children, activeTab, noHistory },
      state: { currentTab },
    } = this;
    return (
      <div className="tabs">
        <ol className={"tab-list"}>
          {children.map((child, index) => {
            const { content, taburl, label, style, variant, icon } =
              child.props;

            return (
              <Tab
                activeTab={noHistory ? currentTab : activeTab}
                taburl={taburl}
                key={content}
                label={label}
                content={content}
                variant={variant}
                style={style}
                icon={icon}
                index={index}
                handleClick={this.onClickTabItem}
                noHistory={noHistory}
                customTabClick={this.props.clickOnTab}
              />
            );
          })}
          {this.initSlider()}
        </ol>
        <div className="tab-content">
          {children.map((child) => {
            if (noHistory) {
              if (child.props.content !== currentTab) return undefined;
              return child.props.children;
            } else {
              if (child.props.content !== activeTab) return undefined;
              return child.props.children;
            }
          })}
        </div>
      </div>
    );
  }
}

export default Tabs;
