import React from "react";
import Wrapper from "../../hoc/Wrapper/Wrapper";
import { ReactComponent as AppLOGO } from "../../assets/img/logos/SAS-APP.svg";
import ErrorBanner from "../../hoc/ErrorBanner/ErrorBanner";
import { Requests } from "../../api/IdentityServerRequests/Requests";
import styles from "./LoginView.module.scss";
import { AuthService } from "../../api/Authentication/AuthService";
import { ErrorHandler } from "../../hoc/ErrorBanner/ErrorHandler";
import copy from "../../assets/copy/copy";
import { matchPath } from "react-router";

/**
 * This screen displays the terms of conditions for Android Auto
 * It must be accepted to trigger the request to get AA permission.
 */
export default class AndroidAutoDisclosure extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: null,
    };
    this.Requests = new Requests();
    this.errorHandler = new ErrorHandler();
    this.authService = new AuthService();
  }

  componentDidMount = () => {
    if (!window.cordova) {
      // do not redirect to home if already in event details page
      if (!!matchPath(this.props.history.location.pathname, "/eventdetails")) {
        this.props.history.push({
          pathname: this.props.history.location.pathname,
        });
      } else {
        // Don't show Android auto on desktop or if user has seen the Android auto page already.
        this.goToHome();
      }
    }
    this.getDisclosureText();
  };

  getDisclosureText = () => {
    this.Requests.callAPI(
      this.Requests.getCurrentAcceptedTermsOfUseAndroidAuto
    ).then((data) => {
      if (data?.status === 200) {
        if (
          data.data.DateTimeAccepted === null &&
          data.data.DateTimeDeclined === null
        ) {
          //if the user has not responded to AA disclosure
          this.setState({
            termsconditionsID: data.data.id,
            disclosureText: data.data.Payload,
          });
        } else {
          this.goToHome();
        }
      } else {
        let ErrorMessage =
          copy.settings.profile.androidAutoPermissionError +
          ` (Error #${copy.errorCodes.androidAutoPermissionError})`;
        this.setState(
          {
            errorMessage: ErrorMessage,
          },
          () => {
            setTimeout(
              function () {
                this.setState({ errorMessage: null });
              }.bind(this),
              5000
            );
          }
        );
      }
    });
  };

  /**
   * Requests permission from Android Auto, marks acceptedAndroidAutoTerms as true, activates AA plugin
   */
  acceptDisclosure = () => {
    this.Requests.callAPI(
      this.Requests.termsOfUseAcceptance,
      this.state.termsconditionsID
    ).then((data) => {
      if (data.status === 200) {
        window.localStorage.setItem("acceptedAndroidAutoTerms", "true");
        window.SasFcmNotificationReceiverPlugin.setAutoStatus(true);
        this.goToHome();
      } else {
        window.localStorage.setItem("acceptedAndroidAutoTerms", "false");
        let ErrorMessage =
          copy.settings.profile.androidAutoPermissionError +
          ` (Error #${copy.errorCodes.androidAutoPermissionError})`;
        this.setState(
          {
            errorMessage: ErrorMessage,
          },
          () => {
            setTimeout(
              function () {
                this.setState({ errorMessage: null });
              }.bind(this),
              5000
            );
          }
        );
      }
    });
  };

  denyDisclosure = () => {
    this.Requests.callAPI(
      this.Requests.termsOfUseDeclined,
      this.state.termsconditionsID
    ).then((data) => {
      if (data.status === 200) {
        window.localStorage.setItem("acceptedAndroidAutoTerms", "true");
        window.SasFcmNotificationReceiverPlugin.setAutoStatus(false);
        this.goToHome();
      } else {
        window.localStorage.setItem("acceptedAndroidAutoTerms", "false");
        let ErrorMessage =
          copy.settings.profile.androidAutoPermissionError +
          ` (Error #${copy.errorCodes.androidAutoPermissionError})`;
        this.setState(
          {
            errorMessage: ErrorMessage,
          },
          () => {
            setTimeout(
              function () {
                this.setState({ errorMessage: null });
              }.bind(this),
              5000
            );
          }
        );
      }
    });
  };
  /**
   * Goes to home screen.
   */
  goToHome = () => {
    this.props.history.push({
      pathname: "/home",
    });
  };

  render() {
    return (
      <Wrapper>
        <ErrorBanner
          isVisible={this.state.errorMessage ? true : false}
          ErrorMessage={this.state.errorMessage}
          onTop={true}
        />
        <div className={styles.pageHeader}></div>
        <AppLOGO />
        <div className={"box_default " + styles.termsofuse}>
          <h2>{copy.androidAuto.title}</h2>
          <p>{this.state.disclosureText}</p>
          <div className={styles.buttonWrapper}>
            <button
              aria-label="I accept the Android Auto Disclosure"
              content="Accept"
              className="btn_primary"
              onClick={this.acceptDisclosure}
            >
              {" "}
              Accept{" "}
            </button>
            <button
              aria-label="I do not accept the Android Auto Disclosure"
              content="Deny"
              className="btn_primary"
              onClick={this.denyDisclosure}
            >
              {copy.androidAuto.denyBtn}
            </button>
          </div>
        </div>
      </Wrapper>
    );
  }
}
