import React from "react";
import Wrapper from "../../hoc/Wrapper/Wrapper";
import ErrorBanner from "../../hoc/ErrorBanner/ErrorBanner";
import Loader from "../../components/UI/Loading/Loading";
// Page layouts
import Tabs from "../../components/UI/Tabs/Tabs";
// Page Component
import EventHeader from "../../components/Headers/EventHeader/EventHeader";
import DefaultMap from "../../components/Map/DefaultMap";
import PagerEvent from "../../components/PagerEvents/PagerEvent";
import AttendanceList from "../../components/Attendance/List/AttendanceList";
import EventChat from "../../components/Chat/EventChat";
// Page UIs & styles
import ScrollButton from "../../components/UI/Button/BackToTop";
import Button from "../../components/UI/Button/Button";
import Icon from "../../components/UI/Icon/Icon";
import styles from "./EventOverview.module.scss";

// Sample Data

// import AVEvent from "../../api/sampleData/events/AV_event.json";
// import SESEvent2 from "../../api/sampleData/events/SES_event2.json";
// import CFAEventEMG from "../../api/sampleData/events/CFA_event_emg.json";

// import SESEvent from "../../api/sampleData/events/SES_event.json";
// import AVAdminEvent from "../../api/sampleData/events/AV_Admin_event.json";
// import SESAdminEvent from "../../api/sampleData/events/SES_Admin_event.json";
// import CFAEvent from "../../api/sampleData/events/CFA_event.json";
import { Requests } from "../../api/IdentityServerRequests/Requests";
import copy from "../../assets/copy/copy.json";

const queryString = require("query-string");

class EventOverviewView extends React.Component {
  constructor(props) {
    super(props);

    // Intialisation for location search prop, as it may be null
    let locationSearchProp = null;
    if (this.props.location && this.props.location.search) {
      locationSearchProp = this.props.location.search;
    }

    this.state = {
      isLoading: true,
      paramsId: null,
      queries: locationSearchProp,
      width: window.innerWidth,
      fullscreenMap: false,
      hideAttendanceMap: false,
      hideLocationMap: false,
      event: null,
      eventSettings: null,
      user: {
        attending: null,
        destination: null,
        eta: null,
      },
      userInformation: this.props.userInfo,
      verifiedCoords: true,
      initialLocation: null,
      userLocation: null,
      trackingStarted: false,
      close: false,
      headerHeight: null,
      refresh: false,
      errorMessage: false,
      eventError: false, //This is to stop the loader appearing again after the error message appears
      eventHeaderHeight: null,
      attendanceListLoading: false,
      eventChatData: null, // This is the event chat data returned from the chat API
    };
    this.etaTimer = null;
    this.Requests = new Requests();
    this.platformStr = this.getDevicePlatform();
    this.getUserSettings();

    // this.headerHeight = React.createRef();
  }

  componentDidMount() {
    this.Requests.checkIfForceLogoutIsEnabled();
    if (document.getElementsByClassName("dhx_cal_cover").length > 0) {
      document.getElementsByClassName("dhx_cal_cover")[0].style.display =
        "none";
    }

    window.scrollTo(0, 0);
    // This is used to stop redirecting to event from push notification tap
    if (window.cordova) {
      window.localStorage.removeItem("redirectToEvent");
    }
    // This will work out which event to grab the details of from the query params passed in
    if (this.props.match && this.props.match.params) {
      const params = this.props.match.params;
      this.getEvent(params.id);
      this.setState({
        paramsId: params.id,
      });
    }

    window.addEventListener("orientationchange", this.handleWindowSizeChange);
    window.addEventListener("resize", this.handleWindowSizeChange);
    window.sessionStorage.setItem("redirectHomeFromEvent", "true");

    // get the user's current location:
    this.getUsersCurrentLocation();
    this.calculateEventHeaderHeight();
    if (this.props.userInfo === null) {
      this.props.initialiseSASUserData();
    }
  }

  componentDidUpdate() {
    if (this.props.match && this.props.match.params) {
      let locationSearchProp = null;
      if (this.props.location && this.props.location.search) {
        locationSearchProp = this.props.location.search;
      }
      const params = this.props.match.params;
      if (this.state.paramsId !== params.id) {
        this.setState({
          event: null,
          paramsId: params.id,
          queries: locationSearchProp,
        });
        this.getCurrentEventDetails(params.id);
      }
    }
  }

  // get, store, and remove attended 'event' object from the native storage
  getFromMobileStorage = (key) =>
    new Promise((resolve, reject) => {
      window.NativeStorage.getObject(key, resolve, reject);
    });

  setInMobileStorage = (key, value) =>
    new Promise((resolve, reject) => {
      window.NativeStorage.putObject(key, value, resolve, reject);
    });

  removeFromMobileStorage = (key) =>
    new Promise((resolve, reject) => {
      window.NativeStorage.remove(key, resolve, reject);
    });

  async storeEventInStorage(key, value) {
    // Update information in storage
    if (window.cordova) {
      await this.setInMobileStorage(key, value);
    }
  }

  async removeEventInStorage(key) {
    // Update information in storage
    if (window.cordova) {
      await this.removeFromMobileStorage(key);
    }
  }

  /**
   * for desktop, call the getCurrentEventDetails function to get data
   * for mobile, check whether the specific event is stored in the native storage,
   * render the stored event overview from the storage then call the function to update.
   * @param id       event id.
   */
  async getEvent(id) {
    if (window.cordova) {
      let event = await this.getFromMobileStorage("lastAttendedEvent");
      if (event && event !== "null") {
        if (event.id === Number(id)) {
          this.setState(
            { event: event, isLoading: false, attendanceListLoading: false },
            () => {
              this.getCurrentEventDetails(id);
            }
          );
        } else {
          this.getCurrentEventDetails(id);
        }
      } else {
        this.getCurrentEventDetails(id);
      }
    } else {
      this.getCurrentEventDetails(id);
    }
  }

  getCurrentEventDetails(id, bypassTimer, attendance) {
    this.Requests.callAPI(this.Requests.getEventDetails, id).then((data) => {
      if (data && data.status && data.status === 200) {
        // For Attending Event, Store event data in the storage, else remove data.
        if (
          data?.data?.userAttendanceCurrentUser?.attendanceStatus !==
          "No_Response"
        ) {
          if (
            data?.data?.userAttendanceCurrentUser?.attendanceStatus ===
            "Attending"
          ) {
            this.storeEventInStorage("lastAttendedEvent", data.data);
          } else {
            this.removeEventInStorage("lastAttendedEvent");
          }
        }

        this.setState(
          { event: data.data, isLoading: false, attendanceListLoading: false },
          () => {
            this.calculateEventHeaderHeight();
            // Get event agency settings
            let agency;
            let AV;
            let CFA;
            let SES;

            if (this.state.event.paging.agency) {
              agency = this.state.event.paging.agency;
            }

            // Update the event chat only for SES Emergency and SES Non-Emergency messages
            if (
              agency === "SES" &&
              this.state.event &&
              this.state.event.messages &&
              this.state.event.messages[this.state.event.messages.length - 1] &&
              this.state.event.messages[this.state.event.messages.length - 1]
                .messageType !== "Admin"
            ) {
              // Update the Event Chat
              this.getChatMessagesByEvent(id);
            } else {
              this.setState({
                eventChatData: false,
              });
            }

            if (this.props.userInfo && this.props.userInfo.userAgencyInfo) {
              if (this.props.userInfo.userAgencyInfo[0]) {
                if (this.props.userInfo.userAgencyInfo[0].agency === "AV") {
                  AV = this.props.userInfo.userAgencyInfo[0];
                } else if (
                  this.props.userInfo.userAgencyInfo[0].agency === "CFA"
                ) {
                  CFA = this.props.userInfo.userAgencyInfo[0];
                } else if (
                  this.props.userInfo.userAgencyInfo[0].agency === "SES"
                ) {
                  SES = this.props.userInfo.userAgencyInfo[0];
                }
              }
              if (this.props.userInfo.userAgencyInfo[1]) {
                if (this.props.userInfo.userAgencyInfo[1].agency === "AV") {
                  AV = this.props.userInfo.userAgencyInfo[1];
                } else if (
                  this.props.userInfo.userAgencyInfo[1].agency === "CFA"
                ) {
                  CFA = this.props.userInfo.userAgencyInfo[1];
                } else if (
                  this.props.userInfo.userAgencyInfo[1].agency === "SES"
                ) {
                  SES = this.props.userInfo.userAgencyInfo[1];
                }
              }
              if (this.props.userInfo.userAgencyInfo[2]) {
                if (this.props.userInfo.userAgencyInfo[2].agency === "AV") {
                  AV = this.props.userInfo.userAgencyInfo[2];
                } else if (
                  this.props.userInfo.userAgencyInfo[2].agency === "CFA"
                ) {
                  CFA = this.props.userInfo.userAgencyInfo[2];
                } else if (
                  this.props.userInfo.userAgencyInfo[2].agency === "SES"
                ) {
                  SES = this.props.userInfo.userAgencyInfo[2];
                }
              }
              if (this.props.userInfo.userAgencyInfo[3]) {
                if (this.props.userInfo.userAgencyInfo[3].agency === "AV") {
                  AV = this.props.userInfo.userAgencyInfo[3];
                } else if (
                  this.props.userInfo.userAgencyInfo[3].agency === "CFA"
                ) {
                  CFA = this.props.userInfo.userAgencyInfo[3];
                } else if (
                  this.props.userInfo.userAgencyInfo[3].agency === "SES"
                ) {
                  SES = this.props.userInfo.userAgencyInfo[3];
                }
              }
            }

            if (agency && AV && agency === AV.agency) {
              this.setState({
                eventSettings: AV,
              });
            } else if (agency && CFA && agency === CFA.agency) {
              this.setState({
                eventSettings: CFA,
              });
            } else if (agency && SES && agency === SES.agency) {
              this.setState({
                eventSettings: SES,
              });
            } else {
              this.setState({
                eventSettings: null,
              });
            }

            if (this.state.event === undefined) {
              if (
                this.state.event.location === undefined ||
                this.state.event.location === null
              ) {
                this.setState({ verifiedCoords: false });
              }
            }

            //To check if current event has a location
            if (this.state.event !== undefined || this.state.event !== null) {
              if (
                this.state.event.location === null ||
                this.state.event.location === undefined
              ) {
                this.setState({ verifiedCoords: false });
              }
            }
            if (
              this.state.event &&
              this.state.event.userAttendanceCurrentUser &&
              this.state.event.paging &&
              this.state.event.messages &&
              this.state.event.messages[this.state.event.messages.length - 1]
            ) {
              const userAttendance = this.state.event.userAttendanceCurrentUser;
              if (
                (attendance === "Attending" &&
                  this.state.userSettings &&
                  this.state.userSettings.shareMyLocation) ||
                (userAttendance.attendanceStatus === "Attending" && // check if they are attending
                  userAttendance.eTA !== 0 && // check if the eta is not 0 so they have not already arrived
                  userAttendance.locationShareMyEnabledEvent === true && // check if they have shared location tracking permission for this event
                  userAttendance.destinationLocation !== null && // check if destination coords are present
                  this.state.event.paging.agency !== "AV" && // check if the event is not an AV event, since they dont have tracking enabled
                  this.state.event.eventStatus !== "Closed" && // check if the event is not closed
                  this.state.event.messages[
                    this.state.event.messages.length - 1
                  ].messageType !== "Admin") // check if event is not Admin
              ) {
                // Start tracking if its not already started
                this.startTracking();
                this.getETACurrentLocation(id, bypassTimer);
              } else {
                let currentlyAttendingEventId = window.localStorage.getItem(
                  "currentlyAttendingEventId"
                );
                if (id === currentlyAttendingEventId) {
                  this.stopTracking(currentlyAttendingEventId);
                  window.localStorage.removeItem("currentlyAttendingEventId");
                  window.localStorage.removeItem(
                    "currentlyAttendingEventDestinationCoords"
                  );
                  window.localStorage.removeItem("lastKLKey");
                }
              }
            }
          }
        );
      } else {
        let ErrorMessage =
          copy.event.getEventDetailsAPIErrorMessage +
          ` (Error #${copy.errorCodes.getEventDetailsAPIErrorMessage})`;
        // if (data && data.data && data.data.SASMessageClient) {
        //   ErrorMessage = data.data.SASMessageClient;
        // }
        this.setState(
          {
            errorMessage: ErrorMessage,
            attendanceListLoading: false,
            eventError: true,
          },
          () => {
            setTimeout(
              function () {
                this.setState({ errorMessage: null });
              }.bind(this),
              5000
            );
          }
        );
      }
    });
  }

  // Gets all current user settings from the backend
  getUserSettings = () => {
    this.Requests.callAPI(this.Requests.getUserSettings).then((data) => {
      if (data && data.status && data.status === 200) {
        this.setState({
          userSettings: data.data,
        });
      } else {
        let ErrorMessage =
          copy.login.userInfoAPIErrorMessage +
          ` (Error #${copy.errorCodes.userInfoSettingsAPIErrorMessage})`;
        // if (data && data.data && data.data.SASMessageClient) {
        //   ErrorMessage = data.data.SASMessageClient;
        // }
        this.setState({ errorMessage: ErrorMessage }, () => {
          setTimeout(
            function () {
              this.setState({ errorMessage: null });
            }.bind(this),
            5000
          );
        });
      }
    });
  };

  /*This function will let Event Chat know that the refresh button has been updated
  Allows Event Chat to call the Chat API again 
  
*/
  updateEventChat = (state) => {
    this.setState({ refresh: state });
  };

  getChatMessagesByEvent = (eventId) => {
    if (eventId) {
      this.Requests.callAPI(this.Requests.getChatThreadByEvent, eventId).then(
        (data) => {
          if (data && data.status && data.status === 200) {
            this.setState({
              eventChatData: data.data,
            });
          } else {
            let ErrorMessage =
              copy.sasChat.updateEventChatAPIErrorMessage +
              ` (Error #${copy.errorCodes.updateEventChatAPIErrorMessage})`;
            this.setState({ errorMessage: ErrorMessage }, () => {
              setTimeout(
                function () {
                  this.setState({ errorMessage: null });
                }.bind(this),
                5000
              );
            });
          }
        }
      );
    }
  };

  handleFullscreenMap = (state) => {
    this.setState({
      fullscreenMap: state,
    });
    return this.state.fullscreenMap;
  };

  handleAttendanceMap = (state) => {
    this.setState({
      hideAttendanceMap: state,
    });
    return state;
  };

  handleLocationMap = (state) => {
    this.setState({
      hideLocationMap: state,
    });
    return state;
  };

  handleCloseLocation = (state) => {
    this.setState({ close: state });
    return state;
  };

  dmsConversion(destination) {
    const lat =
      destination[0] > 0
        ? "N" + destination[0].toString()
        : "S" + Math.abs(destination[0]).toString();
    const long =
      destination[1] > 0
        ? "E" + destination[1].toString()
        : "W" + Math.abs(destination[1]).toString();
    const uri = "ozwpt://" + lat + long;

    return uri;
  }

  /**
   * Launches an external mapping application and shows navigation to the device's location.
   *
   * @param String A string that contains the lat and long value seperated by a comma.
   * */
  navigateToLocation = () => {
    // Changing destination based on user's choice
    let destination = null;

    if (this.state.verifiedCoords) {
      destination = this.state.event.location.geometry.coordinates; //event lat long
    }

    if (
      this.state.event.userAttendanceCurrentUser &&
      this.state.event.userAttendanceCurrentUser.navigateTo &&
      this.state.event.userAttendanceCurrentUser.navigateTo === "Station"
    ) {
      if (
        this.state.event.paging &&
        this.state.event.paging.GeoLocation !== null &&
        this.state.event.paging.GeoLocation.properties &&
        this.state.event.paging.GeoLocation.properties.PFI
      ) {
        let locations = JSON.parse(
          window.sessionStorage.getItem(
            this.state.event.paging.agency + "Locations"
          )
        );
        let coordinates =
          locations[this.state.event.paging.GeoLocation.properties.PFI];
        destination = [coordinates[1], coordinates[0]];
      }
    }

    if (this.platformStr === "ios" || this.platformStr === "android") {
      // check if OzRunways is available and DefaultNavApp is OzRunways
      if (
        this.platformStr === "ios" &&
        this.state.userSettings.defaultNavigationApplication === "OzRunways"
      ) {
        // check is OzRunways is available
        window.plugins.launcher.canLaunch(
          { uri: "ozwpt://" },
          () => {
            // construct uri and trigger OzRunways
            const uri = this.dmsConversion(destination);
            window.plugins.launcher.launch(
              { uri: uri },
              () => console.log("success running ozrunways"),
              (e) => console.log("error running ozrunways", e)
            );
          },
          (error) => {
            // OzRunways not available, launch navigator plugin
            const ln = window.launchnavigator;

            // Options specifying navigation apps to choose from
            let options = {
              appSelection: {
                list: [
                  ln.APP.GOOGLE_MAPS,
                  ln.APP.HERE_MAPS,
                  ln.APP.APPLE_MAPS,
                  ln.APP.WAZE,
                ],
                rememberChoice: {
                  enabled: "prompt",
                },
              },
            };

            // Go to navigation app
            ln.navigate(destination, options);
          }
        );
      } else {
        const ln = window.launchnavigator;

        // Options specifying navigation apps to choose from
        let options = {
          appSelection: {
            list: [
              ln.APP.GOOGLE_MAPS,
              ln.APP.HERE_MAPS,
              ln.APP.APPLE_MAPS,
              ln.APP.WAZE,
            ],
            rememberChoice: {
              enabled: "prompt",
            },
          },
        };

        // Go to navigation app
        ln.navigate(destination, options);
      }
    } else if (this.platformStr === "browser") {
      window.open(
        "https://www.google.com/maps/dir/?api=1&destination=" +
          destination[0].toString() +
          ", " +
          destination[1].toString()
      );
    }
  };

  checkLocationPermission = (success, error) => {
    if (window.cordova && this.props.userInfo) {
      // Gets device operating system location permission
      window.BackgroundGeolocation.getProviderState((state) => {
        if (state.status === 0 || state.status === 3 || state.status === 4) {
          success();
        } else {
          if (error) {
            error();
          }
        }
      });
    }
  };

  updateDetails = () => {
    // this.setLocation(this.state.event.id);
    this.Requests.callAPI(
      this.Requests.getEventDetails,
      this.state.event.id
    ).then((data) => {
      if (data && data.status && data.status === 200) {
        this.setState({ event: data.data });
        this.getETACurrentLocation(this.state.event.id);
      } else if (data && data.status && data.status === 401) {
        // Stop making the getEventDetails call if accesstoken expired
        clearInterval(this.detailsInterval);
      }
    });
  };

  startTracking = () => {
    let destination = window.localStorage.getItem(
      "currentlyAttendingEventDestinationCoords"
    );
    if (window.cordova) {
      // if destination exist then start tracking else wait until destination exist.
      if (destination !== "null" && destination !== null) {
        // retrieve latitude and longitude
        let latLong = destination.split(",");
        this.checkLocationPermission(
          () => {
            let currentUserId = window.localStorage.getItem("userId");
            let currentlyAttendingEventId = window.localStorage.getItem(
              "currentlyAttendingEventId"
            );
            let authKey = window.localStorage.getItem("lastKLKey");
            window.BackgroundGeolocation.setConfig({
              url:
                process.env.REACT_APP_EVENT_API_DEV +
                "/UserLocationTracking/LastKnownLocation",
              headers: {
                authorization: authKey,
              },
              method: "PUT",
              params: {
                eventId: currentlyAttendingEventId,
                userId: currentUserId,
                destLocation: {
                  coords: { latitude: latLong[0], longitude: latLong[1] },
                },
              },
            }).then((state) => {
              // Only start the location tracking if the auth key is present in localStorage
              if (authKey !== null) {
                window.BackgroundGeolocation.start();
                window.BackgroundGeolocation.getCurrentPosition().then(
                  (location) => {
                    this.setState({
                      initialLocation: [
                        location.coords.latitude,
                        location.coords.longitude,
                      ],
                      trackingStarted: true,
                    });
                    // This interval will update the event's attendance and the users ETA
                    this.detailsInterval = setInterval(
                      this.updateDetails,
                      10000
                    );
                  }
                );
              }
            });
          },
          () => {
            let currentlyAttendingEventId = window.localStorage.getItem(
              "currentlyAttendingEventId"
            );
            this.stopTracking(currentlyAttendingEventId);
          }
        );
      }
    }
  };

  stopTracking = (eventId) => {
    this.setState({
      initialLocation: null,
    });
    clearInterval(this.detailsInterval);
    if (window.cordova) {
      window.BackgroundGeolocation.stop();
    }
  };

  // Get's the user's current location on page load
  // Updates the state with the current latlong
  getUsersCurrentLocation = () => {
    // only get user location if it's on a mobile device and the event type isn't Admin
    if (window.cordova && this.props.location && this.props.location.search) {
      const parsedSearch = queryString.parse(this.props.location.search);
      if (
        parsedSearch["messageType"] &&
        parsedSearch["messageType"] !== "Admin"
      ) {
        this.checkLocationPermission(() => {
          window.BackgroundGeolocation.getCurrentPosition().then((location) => {
            this.setState({
              initialLocation: [
                location.coords.latitude,
                location.coords.longitude,
              ],
            });
          });
        });
      }
    }
  };

  // Gets ETA and user's current location for event
  getETACurrentLocation = (id, bypassTimer) => {
    // Check if the user has attendance data
    // Check if the user has event tracking enabled for this event (locationShareMyEnabledEvent)
    // Check if the user has location trackin enabled from settings (shareMyLocation)
    if (
      this.state.event &&
      this.state.event.userAttendanceCurrentUser &&
      this.state.event.userAttendanceCurrentUser.locationShareMyEnabledEvent ===
        true &&
      this.state.userSettings &&
      this.state.userSettings.shareMyLocation === true
    ) {
      if (this.etaTimer === null || bypassTimer) {
        this.Requests.callAPI(this.Requests.getETA, id).then((data) => {
          const userAttendance = this.state.event.userAttendanceCurrentUser;
          if (data && data.status && data.status === 200) {
            let eta = data.data;
            if (data.data === 0) {
              eta = "ARRIVED";
              clearTimeout(this.etaTimer);
              this.etaTimer = null;
            } else if (
              userAttendance.attendanceStatus === "Unavailable" ||
              userAttendance.attendanceStatus === "No_Response"
            ) {
              eta = null;
            }
            this.setState({
              user: {
                ...this.state.user,
                eta: eta,
              },
            });
            // Sets timer for 10 seconds until the next ETA call
            this.etaTimer = setTimeout(() => {
              this.etaTimer = null;
            }, 10000);
          } else if (data && data.status && data.status === 204) {
            // No ETA found
          } else {
            let ErrorMessage =
              copy.event.ETALocationAPIErrorMessage +
              ` (Error #${copy.errorCodes.ETALocationAPIErrorMessage})`;
            // if (data && data.data && data.data.SASMessageClient) {
            //   ErrorMessage = data.data.SASMessageClient;
            // }
            this.setState(
              {
                errorMessage: ErrorMessage,
              },
              () => {
                setTimeout(
                  function () {
                    this.setState({
                      errorMessage: null,
                    });
                  }.bind(this),
                  5000
                );
              }
            );
          }
        });
      }
    } else {
      let eta = null;
      clearTimeout(this.etaTimer);
      this.etaTimer = null;
      this.setState({
        user: {
          ...this.state.user,
          eta: eta,
        },
      });
    }
  };

  /**
   * Return the the device the operating system the user is opening the SAS application on.
   *
   * @return Sring A string that contains one of the possible options (ios, android or browser)
   * */
  getDevicePlatform = () => {
    if (window.device) {
      return window.device.platform.toLowerCase();
    } else {
      return "browser";
    }
  };

  // make sure to remove the listener
  // when the component is not mounted anymore
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
    clearTimeout(this.etaTimer);
    // clearTimeout(this.detailsInterval);
    // this.detailsInterval = null;
    this.etaTimer = null;
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
    this.calculateEventHeaderHeight();
  };

  map() {
    // Changing appearance of button based on user's choice
    const CurrentAgency = this.state.event.paging.agency;
    // This data is for the default map (no attendee locations)
    let mapData, userData;
    if (this.state.event) {
      let event = this.state.event;
      let unitLocation,
        eventLocation,
        attendees,
        agency,
        eventID,
        userAttending,
        userLocation,
        eta,
        destination = null;

      eventLocation = event.location;
      eventID = event.id;
      if (event.paging) {
        unitLocation = event.paging.GeoLocation;
        agency = event.paging.agency;
      }
      if (event.userAttendanceOtherUsersByAttendanceStatus) {
        attendees = event.userAttendanceOtherUsersByAttendanceStatus.attending;
      }
      mapData = {
        unitLocation: unitLocation,
        eventLocation: eventLocation,
        attendees: attendees,
        agency: agency,
        eventID: eventID,
      };
      if (event.userAttendanceCurrentUser) {
        userAttending = event.userAttendanceCurrentUser.attendanceStatus;
        eta = event.userAttendanceCurrentUser.eTA;
        destination = event.userAttendanceCurrentUser.navigateTo;
        if (event.userAttendanceCurrentUser.userLocation) {
          // Check the user's location from the API
          userLocation =
            event.userAttendanceCurrentUser.userLocation.geometry.coordinates;
        }
      }
      // Check the user's current location if not from the API
      if (!userLocation && window.cordova) {
        userLocation = this.state.initialLocation;
      }
      if (!window.cordova) {
        userLocation = null;
      }
      userData = {
        userAttending: userAttending,
        eta: eta,
        destination: destination,
        userLocation: userLocation,
      };
    }

    let buttonString = "Navigate to this event";
    // check if the API is freturning any user data
    if (this.state.event.userAttendanceCurrentUser !== null) {
      // check if there is a navigate to set
      if (this.state.event.userAttendanceCurrentUser.navigateTo !== null) {
        // check if it's set to station
        if (
          this.state.event.userAttendanceCurrentUser.navigateTo === "Station" &&
          this.state.event.paging &&
          this.state.event.paging.GeoLocation !== null
        ) {
          // If current agency is SES (2)
          if (CurrentAgency === "SES") {
            buttonString = copy.event.btnNavigateSES;
          } else {
            buttonString = copy.event.btnNavigateCFA;
          }
        }
      }
    }
    let button = null;
    if (
      this.state.event.location !== null &&
      this.state.event.location !== undefined
    ) {
      if (
        this.state.event.location.geometry !== null &&
        this.state.event.location.geometry !== undefined
      ) {
        button = (
          <Button
            label="click to open your default device navigation"
            buttonClick={this.navigateToLocation.bind(this)}
            content={buttonString}
            variant="btn_nav"
            styles=""
            icon="icon_nav"
          />
        );
      }
    }

    // Return map for event overview
    return (
      <div className="box_default">
        <div className="Sub-Section-titles">
          <h3>{copy.event.location}</h3>
          <span id={styles.addressVerification}>
            {this.state.verifiedCoords ? (
              <Icon
                label="the event address is"
                content={copy.event.verifiedBadge}
                variant="icon_solid"
                styles="verifiedIcon"
                icon="icon_verified"
              />
            ) : (
              <Icon
                label="the event address is"
                content={copy.event.notVerifiedBadge}
                variant="icon_solid"
                styles="notVerifiedIcon"
                icon="icon_unverified"
              />
            )}
          </span>
        </div>
        <div className="Labels">{this.state.event.address}</div>
        <DefaultMap
          mapData={mapData}
          userData={userData}
          userSettings={this.state.userSettings}
          userInfo={this.state.userInformation}
          height={"350px"}
          showLocationSelection={false}
          //LocationSelection = {this.props.LocationSelection}
          LocationMap={true}
          hideLocationMapFunction={this.handleLocationMap}
          hideLocationMap={this.state.hideLocationMap}
          hideAttendanceMapFunction={this.handleAttendanceMap}
          hideAttendanceMap={this.state.hideAttendanceMap}
          FullscreenMap={this.handleFullscreenMap}
          isFullscreen={this.state.fullscreenMap}
          handleArrived={this.handleArrived}
          closeFunction={this.handleCloseLocation}
          eventAgency={this.state.event.paging.agency}
          getUsersCurrentLocation={this.getUsersCurrentLocation}
          you
        />
        {button ? button : null}
      </div>
    );
  }

  // event details
  eDetails() {
    if (
      this.state.event &&
      this.state.event.messages &&
      this.state.event.messages[this.state.event.messages.length - 1]
    ) {
      let alertType =
        this.state.event.messages[this.state.event.messages.length - 1]
          .messageType;
      let agency = this.state.event.agency;

      // return the details section for event overview
      return (
        <div className={styles.EventDetails_section + " col-one-third"}>
          {/* <div className="Section-titles">
            <h2>{copy.event.details}</h2>
          </div> */}
          <div className={styles.pagerEvents}>
            <PagerEvent event={this.state.event.messages} />
          </div>
          {this.state.eventSettings &&
            this.state.eventSettings.chatEventEnabled &&
            window.innerWidth >= 1023 &&
            alertType !== "Admin" &&
            this.map()}
          {
            // IF NOT SES and NOT Admin
            agency !== "SES" && window.innerWidth >= 1023
              ? null
              : alertType !== "Admin"
              ? // Add a map here if
                // agency is NOT SES (2)
                // event is NOT ADMIN (3)
                // IS viewed on Desktop
                this.map()
              : null
          }
        </div>
      );
    }
  }
  /* ---------------------------------------------------------------------  
      ATTENDANCE LOGIC     
      Filters for attendance lives inside AttendanceList
  -----------------------------------------------------------------------*/
  attendance() {
    // This data is feed into the attendance map (attendance list second tab)
    let mapData;
    if (this.state.event) {
      let event = this.state.event;
      let unitLocation,
        eventLocation,
        attending,
        agency,
        eventID = null;

      eventLocation = event.location;
      eventID = event.id;
      if (event.paging) {
        unitLocation = event.paging.GeoLocation;
        agency = event.paging.agency;
      }
      if (event.userAttendanceOtherUsersByAttendanceStatus) {
        attending = event.userAttendanceOtherUsersByAttendanceStatus.attending;
      }

      mapData = {
        unitLocation: unitLocation,
        eventLocation: eventLocation,
        attending: attending,
        agency: agency,
        eventID: eventID,
      };
    }
    return (
      <AttendanceList
        mapData={mapData}
        userSettings={this.state.userSettings}
        eventStatus={this.state.event.eventStatus}
        agency={this.state.event.paging.agency}
        attendanceList={
          this.state.event.userAttendanceOtherUsersByAttendanceStatus
        } // This is the data used to create the List
        userAttendanceDestination={
          this.state.event.userAttendanceCurrentUser
            ? this.state.event.userAttendanceCurrentUser.navigateTo
            : null
        }
        // LocationSelection={() =>
        //   this.handleLocationSelection(this.props.event.userAttendanceCurrentUser.navigateTo)
        // }
        eventAgency={this.state.event.paging.agency}
        hideAttendanceMapFunction={this.handleAttendanceMap}
        hideAttendanceMap={this.state.hideAttendanceMap}
        hideLocationMapFunction={this.handleLocationMap}
        hideLocationMap={this.state.hideLocationMap}
        closeFunction={this.handleCloseLocation}
        FullscreenMap={this.handleFullscreenMap}
        eventId={this.state.event.id}
        messageType={
          this.state.event &&
          this.state.event.messages &&
          this.state.event.messages[this.state.event.messages.length - 1]
            ? this.state.event.messages[this.state.event.messages.length - 1]
                .messageType
            : null
        }
        attendanceListLoading={this.state.attendanceListLoading}
        event={this.state.event}
        //updateAttendance={this.updateAttendance}
        //onMount={this.onAttendanceListMount}
        //onUnmount={this.onAttendanceListUnmount}
      />
    );
  }
  setAttendanceListLoading = (loading) => {
    this.setState({ attendanceListLoading: loading });
  };

  calculateEventHeaderHeight = () => {
    if (this.eventHeaderHeight) {
      let EventHeaderHeight = this.eventHeaderHeight.clientHeight;
      this.setState({
        eventHeaderHeight: EventHeaderHeight,
      });
    } else {
      return;
    }
  };

  render() {
    const { width } = this.state;
    const isMobile = width <= 1023;
    const isMobileApp = window.cordova;

    if (this.state.event === null) {
      if (this.state.queries) {
        let pushNotificationPayload = queryString.parse(this.state.queries);
        let queryMessages = [
          {
            Id: pushNotificationPayload.eventId
              ? pushNotificationPayload.eventId
              : null,
            eventId: pushNotificationPayload.eventId
              ? pushNotificationPayload.eventId
              : null,
            eventStatus: pushNotificationPayload.eventStatus
              ? pushNotificationPayload.eventStatus
              : null,
            messageInputTimeStamp: pushNotificationPayload.messageInputTimeStamp
              ? pushNotificationPayload.messageInputTimeStamp
              : null,
            messageText: pushNotificationPayload.messageText
              ? pushNotificationPayload.messageText
              : null,
            messageType: pushNotificationPayload.messageType
              ? pushNotificationPayload.messageType
              : null,
            agency: pushNotificationPayload.agency
              ? pushNotificationPayload.agency
              : null,
            paging: {
              GeoLocation: {
                type: "Feature",
                geometry: null,
                properties: {
                  PFI: pushNotificationPayload.PFI
                    ? pushNotificationPayload.PFI
                    : "646681",
                },
              },
              agency: pushNotificationPayload.agency
                ? pushNotificationPayload.agency
                : null,
              name: pushNotificationPayload.pagingName
                ? pushNotificationPayload.pagingName
                : null,
            },
          },
        ];
        let attendingResponseNeeded = false;
        if (
          pushNotificationPayload.showResponseControls === "True" ||
          pushNotificationPayload.showResponseControls === "true"
        ) {
          attendingResponseNeeded = true;
        }
        return (
          <Wrapper>
            <EventHeader
              getCurrentEventDetails={(attendance) => {
                this.getCurrentEventDetails(
                  this.state.paramsId,
                  true,
                  attendance
                );
              }}
              queryEvent={queryString.parse(this.state.queries)}
            />
            {/* Tabs are displayed in the loading state of the mobile while the api call is going through. Only if attendance response is required. */}
            {attendingResponseNeeded === "True" ||
              (attendingResponseNeeded === true && isMobileApp && (
                <Tabs showSlider={true} noHistory>
                  <div content="Details"></div>
                  <div content="Attendance"></div>
                </Tabs>
              ))}
            {queryMessages && isMobileApp && (
              <div className={styles.EventDetails_section + " col-one-third"}>
                <div className={styles.pagerEvents}>
                  <PagerEvent event={queryMessages} />
                </div>
              </div>
            )}
            {this.state.errorMessage || this.state.eventError === true ? (
              <h4>
                <span className="noMessages">
                  {copy.event.getEventDetailsAPIErrorMessage +
                    ` (Error #${copy.errorCodes.getEventDetailsAPIErrorMessage})`}
                </span>
              </h4>
            ) : isMobileApp ? (
              // The spinner is displayed differently on the mobile app
              <div className={styles.loadingContainer_bottomScreen}>
                <Loader />
              </div>
            ) : (
              <div className={styles.loadingContainer}>
                <Loader />
              </div>
            )}
          </Wrapper>
        );
      } else {
        return (
          <Wrapper>
            {this.state.errorMessage || this.state.eventError === true ? (
              <h4>
                <span className="noMessages">
                  {copy.event.getEventDetailsAPIErrorMessage +
                    ` (Error #${copy.errorCodes.getEventDetailsAPIErrorMessage})`}
                </span>
              </h4>
            ) : (
              <div className={styles.loadingContainer}>
                <Loader />
              </div>
            )}
          </Wrapper>
        );
      }
    } else {
      if (
        this.state.event &&
        this.state.event.messages &&
        this.state.event.messages[this.state.event.messages.length - 1]
      ) {
        let alertType =
          this.state.event.messages[this.state.event.messages.length - 1]
            .messageType;
        const attendingResponseNeeded =
          this.state.event.paging.settings.attendingResponseNeeded;
        let eventChatNeeded;
        if (this.state.eventSettings !== null) {
          eventChatNeeded =
            this.state.eventSettings.chatEventEnabled &&
            attendingResponseNeeded;
        }

        let userAttending = false;
        if (this.state.event.userAttendanceCurrentUser) {
          userAttending =
            this.state.event.userAttendanceCurrentUser.attendanceStatus ===
              "Attending" ||
            this.state.event.userAttendanceCurrentUser.attendanceStatus ===
              "Other";
        }

        let eventLatLong = "";
        let defaultStationUnit = "";

        if (this.state.event.paging) {
          defaultStationUnit = this.state.event.paging; // Station name
        }
        if (this.state.event) {
          eventLatLong = this.state.event.location; // Event lat long
        }

        let destinationLocations = {
          eventLatLong: eventLatLong,
          defaultStationUnit: defaultStationUnit,
        };

        // Check the user's perfernce for to event or station
        let userDestinationPreference = "";
        if (
          this.state.userSettings &&
          this.state.userSettings.settingsByAgency &&
          this.state.event.paging
        ) {
          const eventAgency = this.state.event.paging.agency;
          for (const [
            // eslint-disable-next-line
            index,
            value,
          ] of this.state.userSettings.settingsByAgency.entries()) {
            if (value.agency === eventAgency) {
              userDestinationPreference = value.navigateTo;
            }
          }
        }

        if (isMobile) {
          // This is the mobile version of event overview page
          return (
            <div className={styles.eventOverviewWrapper}>
              <ErrorBanner
                isVisible={this.state.errorMessage ? true : false}
                ErrorMessage={this.state.errorMessage}
                errorType={this.state.errorType}
              />
              <div className={styles.eventOverview}>
                <EventHeader
                  destinationLocations={destinationLocations} // Event lat long + Station / Unit Name
                  userDestinationPreference={userDestinationPreference} // If they prefer station or event
                  getHeight={(size) => this.setState({ headerHeight: size })}
                  userInfo={this.state.userInformation}
                  history={this.props.history}
                  userRoleAgency={this.props.userRoleAgency}
                  userData={this.state.user}
                  event={this.state.event}
                  close={this.state.close}
                  pagingGroupsPartOf={this.props.pagingGroupsPartOf}
                  pagingGroupsOwned={this.props.pagingGroupsOwned}
                  getUsersCurrentLocation={this.getUsersCurrentLocation}
                  getCurrentEventDetails={(attendance) =>
                    this.getCurrentEventDetails(
                      this.state.paramsId,
                      true,
                      attendance
                    )
                  }
                  eventId={this.state.event.id}
                  startTracking={this.startTracking}
                  stopTracking={this.stopTracking}
                  updateEventChat={this.updateEventChat}
                  userSettings={this.state.userSettings}
                  setAttendanceListLoading={this.setAttendanceListLoading}
                />
                <div
                  className={
                    styles.EventOverview_sections +
                    " main-content-holder eventDetailsTabs"
                  }
                >
                  {alertType !== "Admin" ? (
                    <Wrapper>
                      {attendingResponseNeeded && eventChatNeeded && (
                        <Tabs showSlider={true} noHistory>
                          <div content="Details">{this.eDetails()}</div>

                          <div content="Attendance">{this.attendance()}</div>

                          <div content="Event Chats">
                            {this.state.eventChatData && (
                              <EventChat
                                chatData={this.state.eventChatData}
                                attending={userAttending}
                                eventStatus={
                                  this.state.event.eventStatus === "Closed"
                                }
                                refresh={this.state.refresh}
                                refreshFunc={this.updateEventChat}
                                getCurrentEventDetails={() =>
                                  this.getCurrentEventDetails(
                                    this.state.paramsId,
                                    true
                                  )
                                }
                              />
                            )}
                          </div>
                        </Tabs>
                      )}

                      {attendingResponseNeeded && !eventChatNeeded && (
                        <Tabs showSlider={true} noHistory>
                          <div content="Details">{this.eDetails()}</div>

                          <div content="Attendance">{this.attendance()}</div>
                        </Tabs>
                      )}

                      {!attendingResponseNeeded && eventChatNeeded && (
                        <Tabs showSlider={true} noHistory>
                          <div content="Details">{this.eDetails()}</div>

                          <div content="Event Chats">
                            {this.state.eventChatData && (
                              <EventChat
                                chatData={this.state.eventChatData}
                                attending={userAttending}
                                eventStatus={
                                  this.state.event.eventStatus === "Closed"
                                }
                                refreshFunc={this.updateEventChat}
                                refresh={this.state.refresh}
                                getCurrentEventDetails={() =>
                                  this.getCurrentEventDetails(
                                    this.state.paramsId,
                                    true
                                  )
                                }
                              />
                            )}
                          </div>
                        </Tabs>
                      )}
                      {!attendingResponseNeeded && !eventChatNeeded && (
                        <Wrapper> {this.eDetails()} </Wrapper>
                      )}
                    </Wrapper>
                  ) : (
                    // IS ADMIN on Mobile - tabs are only needed if attendance list needs to be displayed
                    <Wrapper>
                      {attendingResponseNeeded ? (
                        <Tabs showSlider={true} noHistory>
                          <div content="Details">{this.eDetails()}</div>

                          <div content="Attendance">{this.attendance()}</div>
                        </Tabs>
                      ) : (
                        this.eDetails()
                      )}
                    </Wrapper>
                  )}
                </div>
                <ScrollButton
                  scrollStepInPx="50"
                  delayInMs="16.66"
                  display={!this.state.fullscreenMap}
                />
              </div>
              <ScrollButton
                scrollStepInPx="50"
                delayInMs="16.66"
                display={!this.state.fullscreenMap}
              />
              {/* </div> */}
            </div>
          );
        } else {
          // This is the desktop version of event overview page
          return (
            <Wrapper>
              <ErrorBanner
                isVisible={this.state.errorMessage ? true : false}
                ErrorMessage={this.state.errorMessage}
                errorType={this.state.errorType}
              />
              <div className={styles.eventOverview}>
                <div
                  ref={(eventHeaderHeight) => {
                    this.eventHeaderHeight = eventHeaderHeight;
                  }}
                >
                  <EventHeader
                    destinationLocations={destinationLocations} // Event lat long + Station / Unit Name
                    userDestinationPreference={userDestinationPreference} // If they prefer station or event
                    getHeight={(size) => this.setState({ headerHeight: size })}
                    userData={this.state.userData}
                    userInfo={this.state.userInformation}
                    history={this.props.history}
                    userRoleAgency={this.props.userRoleAgency}
                    event={this.state.event}
                    close={this.state.close}
                    pagingGroupsPartOf={this.props.pagingGroupsPartOf}
                    pagingGroupsOwned={this.props.pagingGroupsOwned}
                    getCurrentEventDetails={(attendance) => {
                      this.getCurrentEventDetails(
                        this.state.paramsId,
                        false,
                        attendance
                      );
                    }}
                    calcEventHeaderHeight={this.calcEventHeaderHeight}
                    startTracking={this.startTracking}
                    stopTracking={this.stopTracking}
                    updateEventChat={this.updateEventChat}
                    userSettings={this.state.userSettings}
                    setAttendanceListLoading={this.setAttendanceListLoading}
                  />
                </div>
                <div
                  style={{
                    height: `calc(100% - (${this.state.eventHeaderHeight}px + 55px))`,
                  }}
                  className={
                    styles.EventOverview_sections + " main-content-holder"
                  }
                >
                  {this.eDetails()}
                  {/* // NOT an Admin (3) on Desktop - three columns */}
                  <Wrapper>
                    {attendingResponseNeeded && this.attendance()}

                    {alertType !== "Admin" &&
                      (eventChatNeeded && this.state.eventChatData ? (
                        <EventChat
                          chatData={this.state.eventChatData}
                          attending={userAttending}
                          eventStatus={
                            this.state.event.eventStatus === "Closed"
                          }
                          refresh={this.state.refresh}
                          refreshFunc={this.updateEventChat}
                          getCurrentEventDetails={() =>
                            this.getCurrentEventDetails(
                              this.state.paramsId,
                              true
                            )
                          }
                        />
                      ) : (
                        // Agency is NOT SES
                        // Show map in last column
                        this.state.event &&
                        this.state.event.paging &&
                        this.state.event.paging.agency &&
                        this.state.event.paging.agency !== "SES" && (
                          <div
                            className={
                              styles.EventDetails_section + " col-one-third"
                            }
                          >
                            {this.map()}
                          </div>
                        )
                      ))}
                  </Wrapper>
                </div>
                <ScrollButton
                  scrollStepInPx="50"
                  delayInMs="16.66"
                  display={!this.state.fullscreenMap}
                />
              </div>
            </Wrapper>
          );
        }
      }
    }
  }
}

export default EventOverviewView;
