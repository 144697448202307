// import _ from 'lodash'
import React, { Component } from "react";
import Wrapper from "../../hoc/Wrapper/Wrapper";
import Loader from "../../components/UI/Loading/Loading";
import { NavLink } from "react-router-dom";
import ErrorBanner from "../../hoc/ErrorBanner/ErrorBanner";
import InfiniteScroll from "react-infinite-scroll-component";

import { format } from "date-fns";

// Page Component
// import { Dropdown } from "semantic-ui-react";
import Tabs from "../../components/UI/Tabs/Tabs";
import GroupThreshold from "../../components/Availability/GroupThreshold";
import NewChat from "../../components/Chat/NewChat";

// Page UIs & styles
//import Radio from "../../components/UI/Button/Radio";
import Button from "../../components/UI/Button/Button";
import Toggle from "../../components/UI/Button/Toggle";
import { Dropdown } from "semantic-ui-react";

import styles from "./GroupDetails.module.scss";

// Requests for API calls
import copy from "../../assets/copy/copy.json";
import { Requests } from "../../api/IdentityServerRequests/Requests";
//import qualification from "../../api/sampleData/skills/CFA_skills.json";

/*
 * This is the group details section of Admin,
 * it will only appear after a user has completed a search and selected a paging group
 */

export default class GroupDetailsView extends Component {
  constructor(props) {
    super(props);
    this.Requests = new Requests();
    this.state = {
      isMobile: window.innerWidth <= 1023,

      pagingGroupLoading: true, // indicates whether the paging group info is loading or not
      membersLoading: true, // indicates whether the member info is loading or not

      successful: null, // Indicates that threshold was successfully updated
      errorMessage: null, // Error message for the banner

      PagingGroupID: this.props.pagingGroup // ID of the paging group being displayed
        ? this.props.pagingGroup.number
        : true,
      pagingGroupAgency: null,
      hasAccessToAvailabilityPage: false,

      toggle: {}, // Stores state of toggle for each member in the paging group
      qualList: [], // List of all qualifications for the paging group

      // EVENTS SECTION
      otherText: "OTHER", // Current value of 'other' text
      loadingOther: false, // Loader for when 'other' text is being set
      changeOtherText: false, // User is changing 'other' text
      alwaysActive: false, // State of the toggle for 'paging group always active'

      // MEMBER LIST SECTION
      memberlist: [], // List of members of paging group
      memberResults: [], // List of members returned from add member search
      searchValue: "", // Input value entered to find new member
      addMember: false, // Toggle to show/hide add member input
      updateResults: false, // Toggle to refresh add member results
      hasMoreUsersToLoad: true, // More users to load from pagination
      userListSkip: 0, // Required to skip users when paginating
      selectMember: {}, // Members to be added to the paging group
      added: false, // Show add member msg
      ready: false,

      // CREATING NEW MESSAGE
      createNewMessage: false,

      // AVAILABILITY THRESHOLD SECTION
      availabilityEnabled: null, // This will be used to check if the availabilty tab should be displayed or not
      threshold: null, // Used to store data on availability threshold
      date: null, // TO DO: Ask what this does

      thresholdType: "ByPeople", // what threshold should be based on
      // Following are for threshold by people count
      thresholdByPeopleCount: null, // people count
      requiredtoggle: false, // toggle for the set/edit button

      // Following are for threshold by qualification
      thresholdQualReady: false,
      requiredSkill1: {
        id: null,
        name: null,
        orderNum: 1,
        num: 0,
        toggle: false,
      },
      requiredSkill2: {
        id: null,
        name: null,
        orderNum: 2,
        num: 0,
        toggle: false,
      },
      requiredSkill3: {
        id: null,
        name: null,
        orderNum: 3,
        num: 0,
        toggle: false,
      },
      requiredSkill4: {
        id: null,
        name: null,
        orderNum: 4,
        num: 0,
        toggle: false,
      },
      requiredSkill5: {
        id: null,
        name: null,
        orderNum: 5,
        num: 0,
        toggle: false,
      },
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.pagingGroup.number !== state.PagingGroupID) {
      return {
        PagingGroupID: props.pagingGroup.number,
      };
    }
    return null;
  }

  componentDidUpdate(prevProps) {
    if (this.props.pagingGroup.number !== prevProps.pagingGroup.number) {
      // Close Add Member Search if it is open when a new paging group is clicked
      if (this.state.addMember) {
        this.closeAddMemberSearch();
      }
      this.setState(
        {
          PagingGroupID: this.props.pagingGroup.number,
          toggle: {},
          pagingGroupLoading: true,
          membersLoading: true,
        },
        () => {
          this.getPagingGroup();
          this.getMembers();
        }
      );
    }
  }

  componentDidMount() {
    if (this.state.PagingGroupID !== null) {
      this.getPagingGroup();
      this.getMembers();
    }
  }

  /**
   * This function gets information about the selectd paging group
   */
  getPagingGroup = () => {
    this.Requests.callAPI(
      this.Requests.getPagingGroup,
      this.state.PagingGroupID
    ).then((data) => {
      if (data && data.status && data.status === 200) {
        if (data.data.settings) {
          this.setState(
            {
              pagingGroupSettings: data.data,
              alwaysActive: !data.data.settings.mutable,
              otherText: data.data.settings.attendanceStatusOtherTitle,
              availabilityEnabled: data.data.settings.availabilityEnabled,
              pagingGroupLoading: false,
            },
            () => {
              if (data.data.settings.availabilityEnabled === true) {
                this.getAvailability();
              }
            }
          );
          if (data.data.settings.thresholdType) {
            let type = data.data.settings.thresholdType;
            let pagingAgency = data.data.agency;
            // If the paging group is an AV group, set the default to ByPeople
            if (pagingAgency === "AV") {
              type = "ByPeople";
            }
            this.setState({
              thresholdType: type,
              pagingGroupAgency: pagingAgency,
            });
          }
          let num = data.data.settings.thresholdByPeopleCount;
          this.setState({ thresholdByPeopleCount: num });

          // If there are qualifications
          if (data.data.settings.qualificationPaging.length !== 0) {
            this.setState(
              // add all the qualifications into a list
              { qualList: data.data.settings.qualificationPaging },
              () => {
                // Set the first skill required
                for (let i = 0; i < this.state.qualList.length; i++) {
                  if (
                    this.state.qualList[i].orderValue >= 1 &&
                    this.state.qualList[i].orderValue <= 5
                  ) {
                    let requiredSkill =
                      "requiredSkill" +
                      this.state.qualList[i].orderValue.toString();
                    this.setState({
                      [requiredSkill]: {
                        ...this.state[requiredSkill],
                        id: this.state.qualList[i].qualification.id,
                        name: this.state.qualList[i].qualification.name,
                        num: this.state.qualList[i].thresholdCount,
                      },
                    });
                  }
                  if (i + 1 === this.state.qualList.length) {
                    this.setState({ thresholdQualReady: true });
                  }
                }
              }
            );
          } else {
            // Reset to nothing when switching between paging groups
            for (let i = 1; i <= 5; i++) {
              let requiredSkill = "requiredSkill" + i.toString();
              this.setState({
                [requiredSkill]: {
                  ...this.state[requiredSkill],
                  id: null,
                  name: null,
                  num: 0,
                  checked: null,
                },
              });
            }
          }
          this.setAccessToAvailabilityPage();
        } else {
          this.setState({
            pagingGroupSettings: data.data,
          });
        }
      } else {
        let ErrorMessage =
          copy.admin.filter.pagingGroupInfoAPIErrorMessage +
          ` (Error #${copy.errorCodes.pagingGroupInfoAPIErrorMessage})`;
        // if (data && data.data && data.data.SASMessageClient) {
        //   ErrorMessage = data.data.SASMessageClient;
        // }
        this.setState({ errorMessage: ErrorMessage }, () => {
          setTimeout(
            function () {
              this.setState({ errorMessage: null });
            }.bind(this),
            5000
          );
        });
      }
    });
  };
  /**
   * This function gets data for the selected paging group's threshold availabiility
   */
  getAvailability() {
    let body = {
      pagingNumbers: [this.state.PagingGroupID],
      startDate: format(new Date(), "yyyy-MM-dd"),
    };

    // this sends a request to the get days API and telling it to return 7 days worth of data
    this.Requests.callAPI(this.Requests.getDays, body, 7).then((data) => {
      if (data && data.status && data.status === 200) {
        this.setState({ threshold: data.data }, () => {});
      } else {
        let ErrorMessage =
          copy.admin.getPagingGroupWeekAvailAPIMessageError +
          ` (Error #${copy.errorCodes.getPagingGroupWeekAvailAPIMessageError})`;
        // if (data && data.data && data.data.SASMessageClient) {
        //   ErrorMessage = data.data.SASMessageClient;
        // }
        this.setState({ errorMessage: ErrorMessage }, () => {
          setTimeout(
            function () {
              this.setState({ errorMessage: null });
            }.bind(this),
            5000
          );
        });
      }
    });
  }

  /**
   * This function changes what threshold is based on - whether it be by people or by qualification
   */
  setType = (value) => {
    this.setState(
      {
        thresholdType: value,
        errorMessage: null,
        successful: null,
      },
      () => {
        this.updatePeopleThreshold();
      }
    );
  };

  /**
   * This function updates the people count for availability threshold
   */
  updatePeopleThreshold() {
    let details = {
      thresholdByPeopleCount:
        this.state.thresholdByPeopleCount > 0
          ? this.state.thresholdByPeopleCount
          : null,
      thresholdType: this.state.thresholdType,
    };
    // Send API call to update threshold requirements
    this.Requests.callAPI(
      this.Requests.updatePeopleThreshold,
      this.state.PagingGroupID,
      details
    ).then((data) => {
      if (data && data.status && data.status === 200) {
        // Show successful message
        this.setState({ successful: true });
        // this.setState({ successful: true }, () => {
        //   setTimeout(
        //     function () {
        //       this.setState({
        //         successful: null,
        //       });
        //     }.bind(this),
        //     5000
        //   );
        // });
      } else {
        // API error
        let ErrorMessage =
          copy.admin.updatePeopleThresholdAPIErrorMessage +
          ` (Error #${copy.errorCodes.updatePeopleThresholdAPIErrorMessage})`;
        // if (data && data.data && data.data.SASMessageClient) {
        //   ErrorMessage = data.data.SASMessageClient;
        // }
        this.setState({ errorMessage: ErrorMessage, successful: false }, () => {
          setTimeout(
            function () {
              this.setState({ errorMessage: null, successful: null });
            }.bind(this),
            5000
          );
        });
      }
    });
  }
  /**
   * This function is triggered when the user wants to update the threshold for a particular qualification
   */
  updateQualificationThreshold(data) {
    if (data.id === null) {
      // No qualification selected error
      const ErrorMessage = "Please select a skill";
      this.setState({ errorMessage: ErrorMessage, successful: false }, () => {
        setTimeout(
          function () {
            this.setState({ errorMessage: null, successful: null });
          }.bind(this),
          5000
        );
      });
    } else if (data.num === null) {
      // Required number cannot be empty or 0
      const ErrorMessage = "Please add the required number";
      this.setState({ errorMessage: ErrorMessage, successful: false }, () => {
        setTimeout(
          function () {
            this.setState({ errorMessage: null, successful: null });
          }.bind(this),
          5000
        );
      });
    } else {
      // send update
      let pagingID = this.state.PagingGroupID;

      let details = {
        qualificationId: data.id,
        orderNum: data.orderNum,
        thresholdCount: data.num,
      };
      // Send API call to update threshold requirements

      this.Requests.callAPI(
        this.Requests.updateQualificationThreshold,
        pagingID,
        details
      ).then((data) => {
        if (data && data.status && data.status === 200) {
          // Show successful message
          this.setState({ successful: true });
          // this.setState({ successful: true }, () => {
          //   setTimeout(
          //     function () {
          //       this.setState({
          //         successful: null,
          //       });
          //     }.bind(this),
          //     5000
          //   );
          // });
        } else {
          // API error
          let ErrorMessage =
            copy.admin.updateQualsThresholdAPIErrorMessage +
            ` (Error #${copy.errorCodes.updateQualsThresholdAPIErrorMessage})`;
          // if (data && data.data && data.data.SASMessageClient) {
          //   ErrorMessage = data.data.SASMessageClient;
          // }
          this.setState(
            { errorMessage: ErrorMessage, successful: false },
            () => {
              setTimeout(
                function () {
                  this.setState({ errorMessage: null, successful: null });
                }.bind(this),
                5000
              );
            }
          );
        }
      });
    }
  }

  /**
   * The following functions returns the list of qualificatons for a required skill
   * count - the Required Skill (e.g. If count = 1, the qualifications being returned is for Required Skill 1)
   * The required skill will have it's radio button selected
   */
  getQuals = (count) => {
    let skills = [];
    let requiredSkill = "requiredSkill" + count;
    for (let i = 0; i < this.state.qualList.length; i++) {
      skills.push(
        <li key={i}>
          <label
            aria-label={this.state.qualList[i].qualification.name}
            className={styles.radio}
          >
            <input
              type="radio"
              name={
                "0" + count + "_" + this.state.qualList[i].qualification.name
              }
              checked={
                this.state[requiredSkill].name ===
                this.state.qualList[i].qualification.name
              }
              onChange={() => {
                this.setState({
                  [requiredSkill]: {
                    ...this.state[requiredSkill],
                    id: this.state.qualList[i].qualification.id,
                    name: this.state.qualList[i].qualification.name,
                  },
                });
              }}
            />
            <span className={styles.checkmark}></span>
            {this.state.qualList[i].qualification.name}
          </label>
        </li>
      );
    }

    return <ul>{skills}</ul>;
  };
  /**
   * This function is used for to search for people to add as a new member
   * It is triggered whenever the user types something into the search input
   */
  handleChange = (e) => {
    this.setState(
      {
        searchValue: e.target.value,
        added: false,
        memberResults: [],
        userListSkip: 0,
      },
      () => {
        // set up body for the initial user search for the entered keyword
        let body = {
          take: 30,
          keywords: [this.state.searchValue],
          agency: [this.props.pagingGroup.agency],
        };

        // load user search results
        this.getUserResults(body);
      }
    );
  };

  /**
   * This function is called to load more user search results - triggered by pagination
   * or new user keyword search.
   */
  getUserResults = (body) => {
    this.Requests.callAPI(this.Requests.filterUsers, body).then((data) => {
      if (data && data.status && data.status === 200 && data.data) {
        let memberResults;
        memberResults = this.state.memberResults.concat(data.data);

        if (data.data.length < 30) {
          this.setState({
            hasMoreUsersToLoad: false,
          });
        }
        // fallback in case pagination triggers this to false before all data is finished loading
        if (data.data.length === 30) {
          this.setState({
            hasMoreUsersToLoad: true,
          });
        }

        this.setState({
          memberResults: memberResults,
          memberResultsLoading: false,
          updateResults: true,
        });
      } else {
        let ErrorMessage =
          copy.admin.filter.filterInfoAPIErrorMessage +
          ` (Error #${copy.errorCodes.filterChangeAPIErrorMessage})`;
        this.setState(
          {
            errorMessage: ErrorMessage,
          },
          () => {
            setTimeout(
              function () {
                this.setState({
                  errorMessage: null,
                });
              }.bind(this),
              5000
            );
          }
        );
      }
    });
  };

  /**
   * This function loads more user results from pagination.
   */
  triggerUserPagination = () => {
    if (
      this.state.memberResults.length >= 30 &&
      this.state.hasMoreUsersToLoad
    ) {
      this.setState(
        {
          isLoadingUserData: true,
          userListSkip: this.state.userListSkip + 30,
        },
        () => {
          // Setting up body of the API call
          let body = {
            take: 30,
            skip: this.state.userListSkip,
            keywords: [this.state.searchValue],
            agency: [this.props.pagingGroup.agency],
          };

          // load more user search results
          this.getUserResults(body);
        }
      );
    } else {
      if (this.state.hasMoreUsersToLoad) {
        this.setState({
          hasMoreUsersToLoad: false,
        });
      }
    }
  };

  /**
   * This function will update the search results displayed
   * It also provides a confirmation message when member(s) are successfully added
   */
  returnSearchResults() {
    if (this.state.added) {
      return this.state.added === "success" ? (
        <p className={styles.description + " " + styles.success}>
          {copy.admin.memberList.memberAdded +
            this.state.pagingGroupSettings.name}{" "}
        </p>
      ) : this.state.added === "memberExists" ? (
        <p className={styles.description + " " + styles.fail}>
          {copy.admin.memberList.memberExists +
            this.state.pagingGroupSettings.name}{" "}
        </p>
      ) : null;
    } else {
      if (this.state.memberResults.length === 0) {
        return <p>No results found</p>;
      } else if (this.state.memberResults.length > 0) {
        return (
          <Wrapper>
            <p className={styles.label}>{copy.admin.filter.memberResults}</p>
            <ul
              id="pagingGroupUserSearch"
              className={
                styles.searchResults +
                " " +
                (this.state.isMobile ? styles.mobile : "")
              }
            >
              <Wrapper>
                {this.state.memberResults &&
                this.state.memberResults.length > 0 ? (
                  <Wrapper>
                    <InfiniteScroll
                      dataLength={this.state.memberResults.length}
                      next={this.triggerUserPagination}
                      hasMore={this.state.hasMoreUsersToLoad}
                      loader={
                        <div className={styles.paginationLoader}>
                          <Loader />
                        </div>
                      }
                      scrollableTarget="pagingGroupUserSearch"
                      scrollThreshold="100%"
                    >
                      {this.state.memberResults.map((item, key) => {
                        return (
                          <li
                            key={key}
                            onClick={() => {
                              this.setState({ ready: null });
                              this.addNewMember(item.userId);
                            }}
                          >
                            {item.name}
                          </li>
                        );
                      })}
                    </InfiniteScroll>
                  </Wrapper>
                ) : (
                  <p>{copy.searchAndFilter.noResults}</p>
                )}
              </Wrapper>
            </ul>
          </Wrapper>
        );
      }
    }
  }

  // Called when the Add Member Search is closed
  closeAddMemberSearch = () => {
    this.setState({
      addMember: false, // Stop rendering of box
      searchValue: "", // Clear search bar
      added: false, // Set to false to remove success message
      updateResults: false,
      memberResults: [], // Clear search results
    });
  };
  /**
   * The following functions are related to adding/removing memebers from a paging group
   * - addNewMember
   * - removeMember
   */
  addNewMember = (userID) => {
    if (
      this.state.memberlist.every((user) => {
        if (userID === user.user.userId) {
          this.setState({ ready: true, added: "memberExists" }, () => {
            this.getMembers();
          });
          return false;
        }
        return true;
      })
    ) {
      this.Requests.callAPI(
        this.Requests.addUsertoPagingGroup,
        this.state.PagingGroupID,
        userID
      ).then((data) => {
        if (data && data.status && data.status === 200) {
          this.setState({ ready: true, added: "success" }, () => {
            this.getMembers();
            // update props' paging groups if the searched user's ID matches the logged in user's ID
            if (userID === this.props.userId) {
              window.localStorage.setItem("updateAgencyAndGroups", "true");
            }
          });
        } else {
          let ErrorMessage =
            copy.admin.addMemberAPIMessageError +
            ` (Error #${copy.errorCodes.addMemberAPIMessageError})`;
          // if (data && data.data && data.data.SASMessageClient) {
          //   ErrorMessage = data.data.SASMessageClient;
          // }
          this.setState(
            {
              errorMessage: ErrorMessage,
              membersResults: [],
            },
            () => {
              setTimeout(
                function () {
                  this.setState({ errorMessage: null });
                }.bind(this),
                5000
              );
            }
          );
        }
      });
    }
  };
  removeMember = (userID) => {
    this.Requests.callAPI(
      this.Requests.deleteUserfromPagingGroup,
      this.state.PagingGroupID,
      userID
    ).then((data) => {
      if (data && data.status && data.status === 200) {
        this.setState(
          {
            ready: true,
            toggle: {
              ...this.state.toggle,
              [userID]: !this.state.toggle[userID],
            },
          },
          () => {
            this.getMembers();
            // update props' paging groups if the searched user's ID matches the logged in user's ID
            if (userID === this.props.userId) {
              window.localStorage.setItem("updateAgencyAndGroups", "true");
            }
          }
        );
      } else {
        let ErrorMessage =
          copy.admin.removeMemberAPIMessageError +
          ` (Error #${copy.errorCodes.removeMemberAPIMessageError})`;
        // if (data && data.data && data.data.SASMessageClient) {
        //   ErrorMessage = data.data.SASMessageClient;
        // }
        if (data && data.data && data.data.SASENumErrorCode) {
          if (data.data.SASENumErrorCode === 9) {
            ErrorMessage =
              copy.admin.removeMemberAPIMessageErrorASN +
              ` (Error #${copy.errorCodes.removeMemberAPIMessageError})`;
          }
        }
        this.setState(
          {
            errorMessage: ErrorMessage,
            membersResults: [],
          },
          () => {
            setTimeout(
              function () {
                this.setState({ errorMessage: null });
              }.bind(this),
              5000
            );
          }
        );
      }
    });
  };
  /**
   * The following functions are related to ownership of a paging group
   * - makeOwner
   * - removeOwner
   */
  makeOwner = (userID) => {
    this.Requests.callAPI(
      this.Requests.makeUserPagingGroupsOwner,
      this.state.PagingGroupID,
      userID
    ).then((data) => {
      if (data && data.status && data.status === 200) {
        this.setState({ ready: true }, () => {
          this.getMembers();
        });
      } else {
        let ErrorMessage =
          copy.admin.makeOwnerAPIMessageError +
          ` (Error #${copy.errorCodes.makeOwnerAPIMessageError})`;
        // if (data && data.data && data.data.SASMessageClient) {
        //   ErrorMessage = data.data.SASMessageClient;
        // }
        this.setState(
          {
            errorMessage: ErrorMessage,
            membersResults: [],
          },
          () => {
            setTimeout(
              function () {
                this.setState({ errorMessage: null });
              }.bind(this),
              5000
            );
          }
        );
      }
    });
  };
  removeOwner = (userID) => {
    this.Requests.callAPI(
      this.Requests.removePagingGroupsOwner,
      this.state.PagingGroupID,
      userID
    ).then((data) => {
      if (data && data.status && data.status === 200) {
        this.setState({ ready: true }, () => {
          this.getMembers();
        });
      } else {
        let ErrorMessage =
          copy.admin.removeOwnerAPIMessageError +
          ` (Error #${copy.errorCodes.removeOwnerAPIMessageError})`;
        // if (data && data.data && data.data.SASMessageClient) {
        //   ErrorMessage = data.data.SASMessageClient;
        // }
        this.setState(
          {
            errorMessage: ErrorMessage,
            membersResults: [],
          },
          () => {
            setTimeout(
              function () {
                this.setState({ errorMessage: null });
              }.bind(this),
              5000
            );
          }
        );
      }
    });
  };
  /**
   * This function gets all the members of the paging group
   */
  getMembers = () => {
    this.Requests.callAPI(
      this.Requests.getPagingGroupMembers,
      this.state.PagingGroupID
    ).then((data) => {
      if (data && data.status && data.status === 200) {
        this.setState({
          memberlist: data.data.users,
          ready: true,
          membersLoading: false,
        });
      } else {
        let ErrorMessage =
          copy.admin.getPaginGroupMembersAPIMessageError +
          ` (Error #${copy.errorCodes.getPaginGroupMembersAPIMessageError})`;
        // if (data && data.data && data.data.SASMessageClient) {
        //   ErrorMessage = data.data.SASMessageClient;
        // }
        this.setState({ errorMessage: ErrorMessage }, () => {
          setTimeout(
            function () {
              this.setState({ errorMessage: null });
            }.bind(this),
            5000
          );
        });
      }
    });
  };

  /**
   * Handler for setting text for 'other' option
   * Triggered when user saves their change
   */
  setOtherText = () => {
    this.setState({
      loadingOther: true,
    });
    if (this.props.pagingGroup) {
      const groupID = this.props.pagingGroup.number;
      const other = document.getElementById("OtherInput").value.toUpperCase();
      this.Requests.callAPI(this.Requests.setOtherText, groupID, other).then(
        (data) => {
          if (data && data.status && data.status === 200) {
            this.setState({
              changeOtherText: false,
              loadingOther: false,
              otherText: other,
            });
          } else {
            this.setState({
              changeOtherText: false,
              loadingOther: false,
              otherText: other,
            });
            let ErrorMessage =
              copy.admin.setOtherAPIMessageError +
              ` (Error #${copy.errorCodes.setOtherAPIMessageError})`;
            // if (data && data.data && data.data.SASMessageClient) {
            //   ErrorMessage = data.data.SASMessageClient;
            // }
            this.setState({ errorMessage: ErrorMessage }, () => {
              setTimeout(
                function () {
                  this.setState({ errorMessage: null });
                }.bind(this),
                5000
              );
            });
          }
        }
      );
    }
  };
  /**
   * Handler for toggle to set paging group to always on
   */
  setAlwaysOn = () => {
    if (this.props.pagingGroup) {
      const groupID = this.props.pagingGroup.number;
      this.setState(
        {
          alwaysActive: !this.state.alwaysActive,
        },
        () => {
          this.Requests.callAPI(
            this.Requests.setPagingGroupAlwaysOn,
            groupID,
            !this.state.alwaysActive
          ).then((data) => {
            if (data.status !== 200) {
              let ErrorMessage =
                copy.admin.setAlwaysActiveAPIMessageError +
                ` (Error #${copy.errorCodes.setAlwaysActiveAPIMessageError})`;
              // if (data && data.data && data.data.SASMessageClient) {
              //   ErrorMessage = data.data.SASMessageClient;
              // }
              this.setState(
                {
                  errorMessage: ErrorMessage,
                  alwaysActive: !this.state.alwaysActive,
                },
                () => {
                  setTimeout(
                    function () {
                      this.setState({ errorMessage: null });
                    }.bind(this),
                    5000
                  );
                }
              );
            }
          });
        }
      );
    }
  };

  /**
   * The following functionsa are related to creating new message to the selected paging group
   * - createNewMessage
   * - closeNewMessage
   */
  createNewMessage = () => {
    this.setState({
      createNewMessage: true,
    });
  };
  closeNewMessage = () => {
    this.setState({
      createNewMessage: false,
    });
  };

  /**
   * This function sets the user access to the Availability page based on their role.
   */
  setAccessToAvailabilityPage() {
    /*
    let agency = this.state.pagingGroupAgency;
    // if the user is Global Manager for the selected paging group's agency,
    // then they do not have access to the Availability page
    if (
      this.props.userRoleAgency &&
      this.props.userRoleAgency.indexOf("Manager_" + agency) !== -1
    ) {
      // checks if user is a PGO of the selected group
      if (
        this.props.pagingGroupsOwnedNumberOnly &&
        this.props.pagingGroupsOwnedNumberOnly.indexOf(
          this.state.PagingGroupID
        ) === -1
      ) {
        this.setState({ hasAccessToAvailabilityPage: false });
        return;
      }
    }
    */
    this.setState({ hasAccessToAvailabilityPage: true });

    // only store the PagingGroupID in local storage when the paging group has availability enabled
    if (this.state.availabilityEnabled) {
      localStorage.setItem("PagingGroupID", this.state.PagingGroupID);
      localStorage.setItem("PagingGroupAgency", this.state.pagingGroupAgency);
    }
  }

  render() {
    // Options for setting thresholds by
    let thresholdTypeOptions = [];

    if (this.state.pagingGroupAgency === "AV") {
      thresholdTypeOptions = [{ key: 1, text: "By People", value: "ByPeople" }];
    } else {
      thresholdTypeOptions = [
        { key: 1, text: "By People", value: "ByPeople" },
        { key: 2, text: "By Qualifications", value: "ByQualification" },
      ];
    }

    // Render list of paging group members
    this.members = this.state.memberlist
      ? this.state.memberlist.map((item) => (
          <li
            key={item.user.userId}
            className={this.state.toggle[item.user.userId] ? styles.isOpen : ""}
          >
            <div>
              <div className={styles.center}>
                <span className="primaryText">{item.user.name}</span>
                {item.group_Owner === true && (
                  <span className="subText">Group Owner</span>
                )}
              </div>
              <Button
                label="edit"
                content=""
                variant=""
                styles=""
                icon="icon_option"
                buttonClick={() =>
                  this.setState({
                    toggle: {
                      ...this.state.toggle,
                      [item.user.userId]: !this.state.toggle[item.user.userId],
                    },
                  })
                }
              />
            </div>
            <div className={styles.option}>
              <label
                aria-label=""
                className={styles.checkbox}
                {...{ onClick: this.handleClick }}
              >
                {item.group_Owner === true ? (
                  <input
                    type="checkbox"
                    checked={item.group_Owner}
                    name="Set group owner"
                    onChange={() => {
                      this.removeOwner(item.user.userId);
                    }}
                  />
                ) : (
                  <input
                    type="checkbox"
                    checked={item.group_Owner}
                    name="Set group owner"
                    onChange={() => {
                      this.makeOwner(item.user.userId);
                    }}
                  />
                )}

                <span className="primaryText">
                  {copy.admin.memberList.setGroupOwner}
                </span>
                {/* <span className="subText">
              Can send both brigade and custom chats
            </span> */}
                <span className={styles.checkmark}></span>
              </label>
              <div className={styles.btnHolder}>
                <Button
                  label="Remove member from group"
                  content={copy.admin.memberList.removeMember}
                  variant="red"
                  styles="btn_secondary"
                  icon=""
                  disabled={!item.isMember}
                  hidden={!item.isMember}
                  buttonClick={() => {
                    this.removeMember(item.user.userId);
                  }}
                />
                {/* <Button
              label="Edit this user's details"
              content="Manage user"
              variant=""
              styles="btn_secondary"
              icon=""
            /> */}
                {!item.isMember && (
                  <span className={styles.fail}>
                    {item.user.name} could not be removed as the user is linked
                    to it via ASN.
                  </span>
                )}
              </div>
            </div>
          </li>
        ))
      : null;

    let validRoles = ["SuperUser", "Manager", "Administrator"];
    let viewEventSettings = false;
    // If the user is a super user, manager or administrator, they should be able to view
    // and edit event settings
    for (let i = 0; i < this.props.userRoles.length; i++) {
      if (validRoles.includes(this.props.userRoles[i])) {
        viewEventSettings = true;
      }
    }
    // If the user is only a user, but their own the paging group, they should also be able to view
    // and edit event settings
    if (!viewEventSettings) {
      if (
        this.props.pagingGroupsOwnedNumberOnly.indexOf(
          this.state.PagingGroupID
        ) !== -1
      ) {
        viewEventSettings = true;
      }
    }

    return (
      <Wrapper>
        <ErrorBanner
          isVisible={this.state.errorMessage ? true : false}
          ErrorMessage={this.state.errorMessage}
        />
        <div className={"columnTab " + styles.adminColumn + " attendance"}>
          <div className="Section-titles">
            <h2>{this.props.pagingGroup.name.replace(/_/g, " ")}</h2>
            <div>
              {this.state.pagingGroupAgency === "CFA" ||
              this.state.pagingGroupAgency === "SES" ? (
                this.state.createNewMessage ? (
                  <Button
                    label="Back"
                    content="Back"
                    variant="btn_secondary"
                    styles="green"
                    icon="icon_chat"
                    buttonClick={this.closeNewMessage}
                  />
                ) : (
                  <Button
                    label="Chat"
                    content="Chat"
                    variant="btn_secondary"
                    styles="green"
                    icon="icon_chat"
                    buttonClick={this.createNewMessage}
                  />
                )
              ) : null}
            </div>
          </div>
          {this.state.pagingGroupLoading || this.state.membersLoading ? (
            <div className={styles.loadingContainer}>
              <Loader />
            </div>
          ) : (
            <Wrapper>
              {this.state.addMember === true && (
                <div className={styles.addMember}>
                  <div className={"cardBg3 " + styles.addMemberHolder}>
                    <Button
                      label="Close add member search"
                      content=""
                      variant=""
                      styles=""
                      icon="icon_closeRed"
                      buttonClick={this.closeAddMemberSearch}
                    />
                    <p className={styles.label}>
                      {copy.admin.memberList.findMember}
                    </p>
                    <input
                      autoComplete="off"
                      id="userAndPagingGroupSearch"
                      placeholder={copy.admin.memberList.searchPlaceholder}
                      className={styles.searchbox}
                      value={this.state.searchValue}
                      onChange={this.handleChange.bind(this)}
                    />

                    {this.state.updateResults === true ? (
                      this.returnSearchResults()
                    ) : (
                      <p className={styles.description}>
                        {copy.admin.memberList.noSearch}
                      </p>
                    )}
                  </div>
                </div>
              )}
              <div className={styles.scrollableContent}>
                {this.state.createNewMessage ? (
                  <NewChat
                    location="Admin"
                    newMessagePagingGroup={[this.state.PagingGroupID]}
                    chatRefresh={this.handleRefresh}
                  ></NewChat>
                ) : (
                  <Wrapper>
                    <div className={styles.adminTabs}>
                      {/* Only show availability for groups that have it enabled */}
                      {this.state.availabilityEnabled ? (
                        <Tabs noHistory>
                          <div content="Availability" variant="btn_solid">
                            {this.state.threshold !== null && (
                              // /components/Availability/GroupThreshold.jsx
                              <GroupThreshold
                                threshold={
                                  this.state.threshold[0].dateAvailable
                                } // this sends the data to create the view
                                // date={this.state.date}
                                shorten={false} // shorten refers to the day formate. This means Monday will be written out in full
                              />
                            )}
                            {this.state.hasAccessToAvailabilityPage && (
                              <NavLink to="/availability/threshold">
                                <Button
                                  label="Review availability dashboard"
                                  content={
                                    copy.admin.memberList.reviewDashboard
                                  }
                                  variant=""
                                  styles={
                                    "btn_primary " + styles.adminAvailabilityBtn
                                  }
                                  icon=""
                                />
                              </NavLink>
                            )}
                          </div>
                          <div content="Member list" variant="btn_solid">
                            <div className={styles.membersList}>
                              <Button
                                label="Add a new member"
                                content={copy.admin.memberList.addNewMember}
                                variant=""
                                styles="btn_primary"
                                icon=""
                                buttonClick={() => {
                                  this.setState({ addMember: true });
                                }}
                              />
                              {this.state.ready === true ? (
                                <ul className="settingOptions">
                                  {this.members}
                                </ul>
                              ) : (
                                <div className="inLineloadingContainer">
                                  <Loader />
                                </div>
                              )}
                            </div>
                          </div>
                        </Tabs>
                      ) : (
                        <div
                          content="Member list"
                          variant="btn_solid"
                          className="box_default"
                        >
                          <div
                            className={styles.membersList + " " + styles.noTabs}
                          >
                            <Button
                              label="Add a new member"
                              content={copy.admin.memberList.addNewMember}
                              variant=""
                              styles="btn_primary"
                              icon=""
                              buttonClick={() => {
                                this.setState({ addMember: true });
                              }}
                            />
                            {this.state.ready === true ? (
                              <ul className="settingOptions">{this.members}</ul>
                            ) : (
                              <div className="inLineloadingContainer">
                                <Loader />
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                    {/* If user is a manager, the Event settings will show */}
                    {viewEventSettings ? (
                      <div className="box_default">
                        <div className="Sub-Section-titles">
                          <h3>{copy.admin.groupSettingsTitle}</h3>
                        </div>
                        <ul className="settingOptions">
                          <li>
                            <div>
                              <div>
                                <span className="primaryText">
                                  {copy.admin.setAlwaysOnTitle}
                                </span>
                                <span className="subText">
                                  {copy.admin.setAlwaysOnDesc}
                                </span>
                              </div>
                              <Toggle
                                label="Always Active"
                                content={copy.admin.memberList.alwaysActive}
                                variant="btn_secondary"
                                styles=" "
                                icon=" "
                                fromAdmin
                                checked={this.state.alwaysActive}
                                buttonClick={() => {
                                  this.setAlwaysOn();
                                }}
                              />
                            </div>
                          </li>
                          {this.props.pagingGroup.agency !== "AV" && (
                            <li>
                              <div>
                                {this.state.changeOtherText ? (
                                  <Wrapper>
                                    <div>
                                      <span className="primaryText">
                                        {copy.admin.setOtherTextTitle}
                                      </span>
                                      <input
                                        className={styles.otherBox}
                                        id="OtherInput"
                                        type="text"
                                        placeholder={this.state.otherText}
                                        maxLength="12"
                                      />
                                    </div>
                                    {this.state.loadingOther ? (
                                      <Loader />
                                    ) : (
                                      <Button
                                        label="Edit"
                                        content={copy.global.btnSave}
                                        variant="btn_secondary"
                                        styles=" "
                                        icon=" "
                                        buttonClick={() => {
                                          this.setOtherText();
                                        }}
                                      />
                                    )}
                                  </Wrapper>
                                ) : (
                                  <Wrapper>
                                    <div>
                                      <span className="primaryText">
                                        {copy.admin.setOtherTextTitle}
                                      </span>
                                      <span className="subText">
                                        {this.state.otherText}{" "}
                                        {/* Needs to consume actual text content */}
                                      </span>
                                    </div>
                                    <Button
                                      label="Edit"
                                      content={copy.global.btnEdit}
                                      variant="btn_secondary"
                                      styles=""
                                      icon=""
                                      buttonClick={() => {
                                        this.setState({
                                          changeOtherText: true,
                                        });
                                      }}
                                    />
                                  </Wrapper>
                                )}
                              </div>
                            </li>
                          )}
                        </ul>
                      </div>
                    ) : null}
                    {/* Availability Threshold Section */}
                    {/* Only show availability for groups that have it enabled */}
                    {this.state.availabilityEnabled && (
                      <div className="box_default skills">
                        <div className="Sub-Section-titles">
                          <h3>Availability threshold</h3>
                        </div>
                        {/* Rendering if threshold is based on people count */}
                        {this.state.thresholdType === "ByPeople" ? (
                          <Wrapper>
                            <p className="description">
                              {copy.admin.setThresholdPeopleDesc}
                            </p>

                            <Dropdown
                              className={styles.thresholdType}
                              value={this.state.thresholdType}
                              fluid
                              placeholder=""
                              selection
                              options={thresholdTypeOptions}
                              onChange={(e, { value }) => this.setType(value)}
                            />
                            {this.state.successful === true ? (
                              <p className="fadeaway">
                                {copy.admin.thresholdPeopleUpdated}
                              </p>
                            ) : this.state.successful === false ? (
                              <Wrapper>
                                <p className="fadeaway error">
                                  {copy.admin.thresholdNotUpdated}
                                </p>
                              </Wrapper>
                            ) : null}
                            <ul className="settingOptions">
                              <li>
                                <div>
                                  <div className="holder">
                                    <span>
                                      <span className="primaryText">
                                        Required
                                      </span>
                                      {this.state.thresholdByPeopleCount ===
                                        null ||
                                      this.state.thresholdByPeopleCount <= 0 ? (
                                        <span className="subText">Not Set</span>
                                      ) : (
                                        <span className="subText">
                                          {this.state.thresholdByPeopleCount} x
                                          members{" "}
                                        </span>
                                      )}
                                    </span>
                                  </div>
                                  <Button
                                    label={
                                      this.state.requiredtoggle === false
                                        ? "Edit"
                                        : "Set"
                                    }
                                    content={
                                      this.state.requiredtoggle === false
                                        ? copy.global.btnEdit
                                        : copy.global.btnSet
                                    }
                                    variant="btn_secondary"
                                    styles={
                                      this.state.requiredtoggle === false
                                        ? "btn_outline"
                                        : "btn_solid"
                                    }
                                    icon=""
                                    buttonClick={() =>
                                      this.setState(
                                        {
                                          requiredtoggle:
                                            !this.state.requiredtoggle,
                                        },
                                        () => {
                                          if (
                                            this.state.requiredtoggle === false
                                          ) {
                                            this.updatePeopleThreshold();
                                          }
                                        }
                                      )
                                    }
                                  />
                                </div>
                                <div
                                  className={
                                    this.state.requiredtoggle === true
                                      ? "show"
                                      : "hide"
                                  }
                                >
                                  <div className="holder">
                                    <p>
                                      {copy.admin.memberList.requiredNumber}
                                      <span className="bold">
                                        {this.state.thresholdByPeopleCount}{" "}
                                        {"x "}
                                      </span>
                                    </p>
                                    <div>
                                      <Button
                                        label="subtract"
                                        content=""
                                        variant="btn_sqaure"
                                        icon="icon_reduce"
                                        styles={
                                          this.state.thresholdByPeopleCount <= 0
                                            ? "disabled"
                                            : ""
                                        }
                                        buttonClick={() => {
                                          if (
                                            this.state.thresholdByPeopleCount >
                                            0
                                          ) {
                                            this.setState({
                                              thresholdByPeopleCount:
                                                this.state
                                                  .thresholdByPeopleCount - 1,
                                            });
                                          }
                                        }}
                                      />
                                      <Button
                                        label="add"
                                        content=""
                                        variant="btn_sqaure"
                                        styles
                                        icon="icon_add"
                                        buttonClick={() => {
                                          this.setState({
                                            thresholdByPeopleCount:
                                              this.state
                                                .thresholdByPeopleCount + 1,
                                          });
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </Wrapper>
                        ) : // Rendering if threshold is based on qualifications
                        this.state.thresholdType === "ByQualification" ? (
                          <Wrapper>
                            <p className="description">
                              {copy.admin.setThresholdQualDesc}
                            </p>
                            <Dropdown
                              className={styles.thresholdType}
                              value={this.state.thresholdType}
                              fluid
                              placeholder=""
                              selection
                              options={thresholdTypeOptions}
                              onChange={(e, { value }) => this.setType(value)}
                            />
                            {this.state.successful === true ? (
                              <p className="fadeaway">
                                {copy.admin.thresholdQualUpdated}
                              </p>
                            ) : this.state.successful === false ? (
                              <Wrapper>
                                <p className="fadeaway error">
                                  {copy.admin.thresholdNotUpdated}
                                </p>
                              </Wrapper>
                            ) : null}
                            <ul className="settingOptions">
                              {["1", "2", "3", "4", "5"].map((value, key) => {
                                let requiredSkill = "requiredSkill" + value;
                                return (
                                  <li key={key}>
                                    <div>
                                      <div className="holder">
                                        <span>
                                          <span className="primaryText">
                                            {copy.admin.memberList
                                              .requiredSkill + value}
                                          </span>
                                          {this.state[requiredSkill].num ===
                                            0 ||
                                          this.state[requiredSkill].num ===
                                            null ? (
                                            <span className="subText">
                                              Not Set
                                            </span>
                                          ) : (
                                            <span className="subText">
                                              {this.state[requiredSkill].num}{" "}
                                              {"x "}
                                              {this.state[requiredSkill].name}
                                            </span>
                                          )}
                                        </span>
                                      </div>
                                      <Button
                                        label={
                                          this.state[requiredSkill].toggle ===
                                          false
                                            ? "Edit"
                                            : "Set"
                                        }
                                        content={
                                          this.state[requiredSkill].toggle ===
                                          false
                                            ? "Edit"
                                            : "Set"
                                        }
                                        variant="btn_secondary"
                                        styles={
                                          this.state[requiredSkill].toggle ===
                                          false
                                            ? "btn_outline"
                                            : "btn_solid"
                                        }
                                        icon=""
                                        buttonClick={() =>
                                          this.setState(
                                            {
                                              [requiredSkill]: {
                                                ...this.state[requiredSkill],
                                                toggle:
                                                  !this.state[requiredSkill]
                                                    .toggle,
                                              },
                                            },
                                            () => {
                                              if (
                                                this.state[requiredSkill]
                                                  .toggle === false
                                              ) {
                                                this.updateQualificationThreshold(
                                                  this.state[requiredSkill]
                                                );
                                              }
                                            }
                                          )
                                        }
                                      />
                                    </div>
                                    <div
                                      className={
                                        this.state[requiredSkill].toggle ===
                                        true
                                          ? "show"
                                          : "hide"
                                      }
                                    >
                                      {this.state.thresholdQualReady === true &&
                                        this.getQuals(value)}
                                      <div className="holder">
                                        <p>
                                          {copy.admin.memberList.requiredNumber}
                                          <span className="bold">
                                            {this.state[requiredSkill].num}{" "}
                                            {"x "}
                                          </span>
                                        </p>
                                        <div>
                                          <Button
                                            label="subtract"
                                            content=""
                                            variant="btn_sqaure"
                                            icon="icon_reduce"
                                            styles={
                                              this.state[requiredSkill].num <= 0
                                                ? "disabled"
                                                : ""
                                            }
                                            buttonClick={() => {
                                              if (
                                                this.state[requiredSkill].num >
                                                0
                                              ) {
                                                this.setState({
                                                  [requiredSkill]: {
                                                    ...this.state[
                                                      requiredSkill
                                                    ],
                                                    num:
                                                      this.state[requiredSkill]
                                                        .num - 1,
                                                  },
                                                });
                                              }
                                            }}
                                          />
                                          <Button
                                            label="add"
                                            content=""
                                            variant="btn_sqaure"
                                            styles
                                            icon="icon_add"
                                            buttonClick={() => {
                                              this.setState({
                                                [requiredSkill]: {
                                                  ...this.state[requiredSkill],
                                                  num:
                                                    this.state[requiredSkill]
                                                      .num + 1,
                                                },
                                              });
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                );
                              })}
                            </ul>
                          </Wrapper>
                        ) : null}
                      </div>
                    )}
                  </Wrapper>
                )}
              </div>
            </Wrapper>
          )}
        </div>
      </Wrapper>
    );
  }
}
