import React from "react";
import Wrapper from "../../hoc/Wrapper/Wrapper";

// Page Component
import styles from "./Availability.module.scss";
import MetIcon from "../../assets/img/icons/Threshold-Met.svg";
import NotMetIcon from "../../assets/img/icons/Threshold-NotMet.svg";

export default class HalfHrView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
      isMobile: window.innerWidth <= 1024,
      overview: this.props.overview,
      firstOne: this.props.firstOne,
      date: this.props.date,
      times: this.props.times,
      details: "",
      target: this.props.target ? this.props.target : "", // The selected time slot
    };
    this.myRef = React.createRef();
  }
  componentDidMount() {
    // let currentTime = roundToNearestMinutes(new Date(), { nearestTo: 30 });
    // let targetTime = format(currentTime, "HH:mm");
    // this.setState({ target: targetTime }, () => {
    //   if (this.state.overview === false) {
    //     this.myRef.current.scrollIntoView();
    //   }
    // });
    // if (!this.state.isMobile) {
    //   this.sendData(targetTime);
    // }
  }
  sendData = (start) => {
    if (this.state.overview === false) {
      this.setState(
        {
          target: start,
          details: {
            start: start,
          },
        },
        () => {
          this.props.parentCallback(this.state.details);
        }
      );
    }
  };
  renderDay() {
    if (this.props.times) {
      return this.props.times.map((item, key) => (
        <Wrapper key={key}>
          {item.timeStart === "00:00:00" ? (
            <li className={styles.header + " cardBg2"}>
              <span className={styles.primaryText + " txt2"}>
                Dawn - 00:00 to 06:00
              </span>
            </li>
          ) : item.timeStart === "06:00:00" ? (
            <li className={styles.header + " cardBg2"}>
              <span className={styles.primaryText + " txt2"}>
                Morning - 06:00 to 12:00
              </span>
            </li>
          ) : item.timeStart === "12:00:00" ? (
            <li className={styles.header + " cardBg2"}>
              <span className={styles.primaryText + " txt2"}>
                Afternoon - 12:00 to 18:00
              </span>
            </li>
          ) : item.timeStart === "18:00:00" ? (
            <li className={styles.header + " cardBg2"}>
              <span className={styles.primaryText + " txt2"}>
                Evening - 18:00 to 24:00
              </span>
            </li>
          ) : null}
          {this.state.target === item.timeStart.slice(0, 5) ? (
            <li
              ref={this.myRef}
              className={[
                item.thresholdReached === true && styles.reached,
                styles.selected,
              ].join(" ")}
              onClick={() => this.sendData(item.timeStart.slice(0, 5))}>
              <span className={styles.primaryText}>
                {item.timeStart.slice(0, 5)}
              </span>
              {item.thresholdReached === true ? (
                <img
                  className={styles.icon}
                  src={MetIcon}
                  alt="Threshold Met"
                />
              ) : (
                <img
                  className={styles.icon}
                  src={NotMetIcon}
                  alt="Threshold Has Not been Met"
                />
              )}
            </li>
          ) : (
            <li
              className={[
                item.thresholdReached === true && styles.reached,
                // this.state.target === item.timeStart.slice(0, 5)
                //   ? styles.selected
                //   : ""
              ].join(" ")}
              onClick={() => this.sendData(item.timeStart.slice(0, 5))}>
              <span className={styles.primaryText}>
                {item.timeStart.slice(0, 5)}
              </span>
              {item.thresholdReached === true ? (
                <img
                  className={styles.icon}
                  src={MetIcon}
                  alt="Threshold Met"
                />
              ) : (
                <img
                  className={styles.icon}
                  src={NotMetIcon}
                  alt="Threshold Has Not been Met"
                />
              )}
            </li>
          )}
        </Wrapper>
      ));
    }
  }

  renderOverview() {
    return this.props.times.map((item, key) => (
      <Wrapper key={key}>
        {this.props.firstOne === true ? (
          <li>
            {key === 0 && (
              (["23:30:00", "05:30:00", "11:30:00", "17:30:00"].indexOf(item.timeStart) === -1)
              ) ? (
              <span className={styles.timeLabels}>{item.timeStart.slice(0, -3)}</span>
            ) : item.timeStart === "00:00:00" ? (
              <span className={styles.timeLabels + " " + styles.midnight}>
                Midnight {/*format(new Date(), "iiii")*/}
              </span>
            ) : item.timeStart === "06:00:00" ? (
              <span className={styles.timeLabels}>06:00</span>
            ) : item.timeStart === "12:00:00" ? (
              <span className={styles.timeLabels}>
                Midday {/*format(new Date(), "iiii")*/}
              </span>
            ) : item.timeStart === "18:00:00" ? (
              <span className={styles.timeLabels}>18:00</span>
            ) : null}

            {item.thresholdReached === true ? (
              <img className={styles.icon} src={MetIcon} alt="Threshold Met" />
            ) : (
              <img
                className={styles.icon}
                src={NotMetIcon}
                alt="Threshold Has Not been Met"
              />
            )}
          </li>
        ) : (
          <li
            className={[
              item.timeStart === "00:00:00"
                ? styles.midnight
                : item.timeStart === "06:00:00"
                ? styles.morning
                : item.timeStart === "12:00:00"
                ? styles.midday
                : item.timeStart === "18:00:00"
                ? styles.evening
                : null,
            ]}>
            {item.thresholdReached === true ? (
              <img className={styles.icon} src={MetIcon} alt="Threshold Met" />
            ) : (
              <img
                className={styles.icon}
                src={NotMetIcon}
                alt="Threshold Has Not been Met"
              />
            )}
          </li>
        )}
      </Wrapper>
    ));
  }

  render() {
    return (
      <Wrapper>
        {this.state.overview === true ? (
          <ul className={styles.overview}>{this.renderOverview()}</ul>
        ) : (
          <ul
            className={
              styles.groupThreshold + " " + styles.hrs + " lightHighlight"
            }>
            {this.renderDay()}
          </ul>
        )}
      </Wrapper>
    );
  }
}
