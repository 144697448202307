import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "./Accordion.module.scss";
import Wrapper from "../../../hoc/Wrapper/Wrapper";
import Radio from "../Button/Radio";
import copy from "../../../assets/copy/copy.json";

class AccordionSection extends Component {
  static propTypes = {
    children: PropTypes.instanceOf(Object).isRequired,
    isOpen: PropTypes.bool.isRequired,
    handleClick: PropTypes.func.isRequired,
  };

  handleClick = () => {
    this.props.handleClick(this.props.label);
  };

  render() {
    const {
      handleClick,
      props: { isOpen, logo, label, txt, subtxt, keyValue, navigation },
    } = this;

    let classOpen = isOpen ? " open" : " close";
    let isLogo = logo ? " isLogo" : "";
    let isDynamic = label === "Dynamic Paging Groups" ? " isDynamic" : "";

    return (
      <Wrapper>
        {logo ? (
          <Wrapper>
            <div
              key={keyValue}
              className={
                styles.Accordion_header + classOpen + isLogo + " " + label
              }>
              <div {...{ onClick: handleClick }} className="accordion_header">
                <span className={styles.logo_holder}>
                  <img src={logo} alt="logo" />
                </span>
                {label}
              </div>
              {navigation && label !== "AV" && (
                <div
                  className={
                    isOpen
                      ? styles.navigationPreferenceOpen
                      : styles.navigationPreference
                  }>
                  <p className={styles.txt}>Navigate me to</p>
                  <div className={"btn_holder " + styles.buttons}>
                    {label === "CFA" ? (
                      <Radio
                        name={label}
                        onClick={handleClick}
                        label={copy.settings.profile.CFAstation}
                        id={"station"}
                        paramVal={"station or unit"}
                        checked={true}
                      />
                    ) : (
                      <Radio
                        name={label}
                        onClick={handleClick}
                        label={copy.settings.profile.SESunit}
                        id={"station"}
                        paramVal={"station or unit"}
                        checked={true}
                      />
                    )}
                    <Radio
                      name={label}
                      onClick={handleClick}
                      label={copy.settings.profile.event}
                      id={"event"}
                      paramVal={"event"}
                    />
                  </div>
                  <p className={styles.subtxt}>
                    {copy.settings.profile.navigateMeTo}
                  </p>
                </div>
              )}
              <div className="accordion_content">
                {!logo && <Wrapper>{label}</Wrapper>}
                {txt && <p className={styles.txt}>{txt}</p>}
                {subtxt && <p className={styles.subtxt}>{subtxt}</p>}
                {/* {!isOpen && <span>Accordion section is closed</span>}
                {isOpen && <span>Accordion section is open</span>} */}
              </div>
            </div>
          </Wrapper>
        ) : (
          <div
            key={keyValue}
            {...{ onClick: handleClick }}
            className={styles.Accordion_header + classOpen + isDynamic}>
            <div {...{ onClick: handleClick }} className="accordion_header">
              {label}
            </div>
            <div className="accordion_content">
              {txt && <p className={styles.txt}>{txt}</p>}
              {subtxt && <p className={styles.subtxt}>{subtxt}</p>}
              {/* {!isOpen && <span>Accordion section is closed</span>}
            {isOpen && <span>Accordion section is open</span>} */}
            </div>
          </div>
        )}
        {isOpen && (
          <div className={styles.Accordion_content}>{this.props.children}</div>
        )}
      </Wrapper>
    );
  }
}

export default AccordionSection;
