import React, { Component } from "react";
// Layouts
//import Accordion from "../UI/Accordion/Accordion";
// Page UIs & styles
import Button from "../UI/Button/Button";

// styles
import styles from "./NewSASUserTile.module.scss";
// Requests for API calls
import copy from "../../assets/copy/copy.json";
import { Requests } from "../../api/IdentityServerRequests/Requests";
// Images
import SES from "../../assets/img/logos/logo_ses.png";
import CFA from "../../assets/img/logos/logo_cfa.png";
import AV from "../../assets/img/logos/logo_av.png";
import Wrapper from "../../hoc/Wrapper/Wrapper";
import Loader from "../UI/Loading/Loading";

export default class NewSASUSerTile extends Component {
  constructor(props) {
    super(props);
    this.Requests = new Requests();
    this.state = {
      isLoading: false,
      // Inputs into the new user forrm
      newSASUser: {
        nameFirst: null,
        nameLast: null,
        email: null,
        locationPrimaryDescription: null,
        roles: [],
      },
      errorMessage: null,
      successful: null, // Indicates whether adding a new user iis successful

      // state variables used to store the current state of the agency checkboxes
      allowAV: false,
      allowCFA: false,
      allowSES: false,

      // state variables to indicate which agency tiles they can view based on their role permissions
      showAV: false,
      showCFA: false,
      showSES: false
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  
  componentDidMount() {
    // check user role permissions for what agency tiles they can see
    if (this.props.userRoles) {
      let agencies = ["AV", "CFA", "SES"];
      let permissions = {
        "AV": false,
        "CFA": false,
        "SES": false
      }

      if (this.props.userRoles.indexOf("SuperUser") !== -1) {
        // superusers has access to all agencies
        permissions = {
          "AV": true,
          "CFA": true,
          "SES": true
        }
      } else {
        // check individual agency permissions
        for (let i = 0; i < agencies.length; i++) {
          if (this.props.userRoles.indexOf("Manager_" + agencies[i]) !== -1 || 
            this.props.userRoles.indexOf("Administrator_" + agencies[i]) !== -1) {
            permissions[agencies[i]] = true;
          }
        }
      }
      this.setState({
        showAV: permissions["AV"],
        showCFA: permissions["CFA"],
        showSES: permissions["SES"]
      });
    }
  }

  /**
   * Function which handle when inputs to the form change
   */
  handleInputChange(e) {
    let value = e.target.value;
    let name = e.target.name;
    this.setState((prevState) => {
      return {
        newSASUser: {
          ...prevState.newSASUser,
          [name]: value,
        },
      };
    });
  }

  /**
   * Handles what happens when the AV checkbox is toggled
   */
  onChangeAV = () => {
    this.setState(
      (prevState) => ({
        // Toggle AV checkbox
        allowAV: !prevState.allowAV,
      }),
      () => {
        if (this.state.allowAV === true) {
          // Checkbox checked
          // Add agency AV basic user role to array
          var joined = this.state.newSASUser.roles.concat("User_AV");
          this.setState({
            newSASUser: {
              ...this.state.newSASUser,
              roles: joined,
            },
          });
        } else {
          // Checkbox unchecked
          // Remove agency AV basic user role from array
          var array = [...this.state.newSASUser.roles]; // make a separate copy of the array
          var index = array.indexOf("User_AV");
          if (index !== -1) {
            array.splice(index, 1);
            this.setState({
              newSASUser: {
                ...this.state.newSASUser,
                roles: array,
              },
            });
          }
        }
      }
    );
  };

  /**
   * Handles what happens when the CFA checkbox is toggled
   */
  onChangeCFA = () => {
    this.setState(
      (prevState) => ({
        // Toggle CFA checkbox
        allowCFA: !prevState.allowCFA,
      }),
      () => {
        if (this.state.allowCFA === true) {
          // Checkbox checked
          // Add agency CFA basic user role to array
          var joined = this.state.newSASUser.roles.concat("User_CFA");
          this.setState({
            newSASUser: {
              ...this.state.newSASUser,
              roles: joined,
            },
          });
        } else {
          // Checkbox unchecked
          // Remove agency CFA basic user role from array
          var array = [...this.state.newSASUser.roles]; // make a separate copy of the array
          var index = array.indexOf("User_CFA");
          if (index !== -1) {
            array.splice(index, 1);
            this.setState({
              newSASUser: {
                ...this.state.newSASUser,
                roles: array,
              },
            });
          }
        }
      }
    );
  };

  /**
   * Handles what happens when the SES checkbox is toggled
   */
  onChangeSES = () => {
    this.setState(
      (prevState) => ({
        // Toggle SES checkbox
        allowSES: !prevState.allowSES,
      }),
      () => {
        if (this.state.allowSES === true) {
          // Checkbox checked
          // Add agency SES basic user role to array
          var joined = this.state.newSASUser.roles.concat("User_SES");
          this.setState({
            newSASUser: {
              ...this.state.newSASUser,
              roles: joined,
            },
          });
        } else {
          // Checkbox unchecked
          // Remove agency SES basic user role from array
          var array = [...this.state.newSASUser.roles]; // make a separate copy of the array
          var index = array.indexOf("User_SES");
          if (index !== -1) {
            array.splice(index, 1);
            this.setState({
              newSASUser: {
                ...this.state.newSASUser,
                roles: array,
              },
            });
          }
        }
      }
    );
  };

  /**
   * Clears the new user account details
   */
  clearForm = () => {
    this.setState({
      newSASUser: {
        nameFirst: null,
        nameLast: null,
        email: null,
        locationPrimaryDescription: null,
        roles: [],
      },
    });
    document.getElementById("nameFirst").value = "";
    document.getElementById("nameLast").value = "";
    document.getElementById("email").value = "";
    document.getElementById("locationPrimaryDescription").value = "";
    document.getElementById("AVCheckbox").checked = false;
    document.getElementById("CFACheckbox").checked = false;
    document.getElementById("SESCheckbox").checked = false;
  }

  /**
   * Called when the user clicks 'Submit' button on the form
   *
   * @param {object} Event The click event triggering this function.
   */
  handleSubmit = (event) => {
    // Prevents reloading of page or multiple submits.
    event.preventDefault();
    // Prevents calling API if any fields are empty or the email is invalid.
    this.setState({ isLoading: true }, () => {
      if (
        this.state.newSASUser.nameFirst &&
        this.state.newSASUser.nameLast &&
        this.state.newSASUser.email &&
        this.validateEmail(this.state.newSASUser.email) &&
        this.state.newSASUser.roles.length > 0 &&
        this.state.newSASUser.locationPrimaryDescription
      ) {
        // Calls API.
        this.Requests.callAPI(
          this.Requests.addSASonlyUser,
          this.state.newSASUser
        ).then((data) => {
          if (data && data.status && data.status === 200) {
            // Successful call.
            this.setState({ successful: true, isLoading: false });
            this.clearForm();
          } else {
            // Unsuccessful call.
            // Create error message with the HTTPS response.
            this.setState({ isLoading: false });
            let ErrorMessage =
              copy.admin.newSASUserTile.newSASUserAPIFail +
              ` (Error #${copy.errorCodes.newSASUserAPIFail})`;

            //Error for when the email is already in SAS
            if (data && data.data && data.data.SASENumErrorCode) {
              if (data.data.SASENumErrorCode === 15) {
                ErrorMessage =
                  data.data.SASMessageClient +
                  ` (Error #${copy.errorCodes.newSASUserAPIFail})`;
              }
            }
            this.throwErrorMessage(ErrorMessage);
          }
        });
      } else {
        this.setState({ successful: true, isLoading: false });
        // Create error message for having missing fields.
        this.throwErrorMessage();
      }
    });
  };

  /**
   * Checks an email address to see if it is valid.
   * Requirements include containing an @ and a valid domain.
   * @param {String} email
   */
  validateEmail = (email) => {
    return /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(email);
  };

  /**
   * Creates an error message formatted as an unordered list and sets it in the state.
   *
   * @param {String} apiError The error message returned from the API (if any)
   */
  throwErrorMessage = (apiError) => {
    // Creates the error message. If an error does not exist it will not be shown.
    // Messages are from the copy file, except for apiError which is the HTTPS response.
    let ErrorMessage = (
      <ul className="fadeaway error">
        <li>{apiError ? null : copy.admin.newSASUserTile.failedMessage}</li>
        <li>{apiError}</li>
        <li>
          {!this.state.newSASUser.nameFirst &&
            copy.admin.newSASUserTile.noFirstNameError}
        </li>
        <li>
          {!this.state.newSASUser.nameLast &&
            copy.admin.newSASUserTile.noLastNameError}
        </li>
        <li>
          {!this.state.newSASUser.email
            ? copy.admin.newSASUserTile.noEmailError
            : !this.validateEmail(this.state.newSASUser.email) &&
              copy.admin.newSASUserTile.validateEmailError}
        </li>
        <li>
          {!this.state.newSASUser.locationPrimaryDescription &&
            copy.admin.newSASUserTile.noLocationError}
        </li>
        <li>
          {this.state.newSASUser.roles.length === 0 &&
            copy.admin.newSASUserTile.noAgencyError}
        </li>
      </ul>
    );
    // Sets state. If there are any errors this.state.successful will be false.
    // Times out after 8.5 seconds (0.5 seconds longer than animation).
    this.setState(
      {
        successful: false,
        errorMessage: ErrorMessage,
      },
      () => {
        setTimeout(
          function () {
            this.setState({
              successful: null,
              errorMessage: null,
            });
          }.bind(this),
          8500
        );
      }
    );
  };

  render() {
    return (
      <div className="box_default">
        <div className="Sub-Section-titles">
          <h3>{copy.admin.newSASUserTile.sectionTitle}</h3>
        </div>
        {this.state.isLoading ? (
          <div className={styles.loadingContainer}>
            <Loader />
          </div>
        ) : (
          <Wrapper>
            {this.state.successful === true ? (
              <p className="fadeaway">
                {copy.admin.newSASUserTile.successMessage}
              </p>
            ) : this.state.successful === false ? (
              <Wrapper>
                <p className="fadeaway error">{this.state.errorMessage}</p>
              </Wrapper>
            ) : null}
            <form className={styles.addSASUser} onSubmit={this.handleSubmit}>
              <p>{copy.admin.newSASUserTile.formTitle}</p>
              <label htmlFor="nameFirst">
                {copy.admin.newSASUserTile.firstName}
              </label>
              <input
                required
                id="nameFirst"
                name="nameFirst"
                type="text"
                value={
                  this.state.newSASUser.nameFirst
                    ? this.state.newSASUser.nameFirst
                    : ""
                }
                onChange={this.handleInputChange}
              />
              <label htmlFor="nameLast">
                {copy.admin.newSASUserTile.lastName}
              </label>
              <input
                required
                id="nameLast"
                name="nameLast"
                type="text"
                value={
                  this.state.newSASUser.nameLast
                    ? this.state.newSASUser.nameLast
                    : ""
                }
                onChange={this.handleInputChange}
              />
              <label htmlFor="email">{copy.admin.newSASUserTile.email}</label>
              <input
                required
                id="email"
                name="email"
                type="email"
                value={
                  this.state.newSASUser.email ? this.state.newSASUser.email : ""
                }
                onChange={this.handleInputChange}
              />
              <label htmlFor="freeTxt">
                {copy.admin.newSASUserTile.location}
              </label>
              <input
                id="locationPrimaryDescription"
                name="locationPrimaryDescription"
                type="locationPrimaryDescription"
                value={
                  this.state.newSASUser.locationPrimaryDescription
                    ? this.state.newSASUser.locationPrimaryDescription
                    : ""
                }
                onChange={this.handleInputChange}
              />
              <label className={styles.separator}>
                {copy.admin.newSASUserTile.CheckboxTitle}
              </label>
              <div className={styles.checkboxHolder} style={{justifyContent: "space-evenly"}}>
                {/* AV Checkbox*/}
                { this.state.showAV &&
                <label
                  aria-label={copy.admin.newSASUserTile.AVcheckbox}
                  className={styles.checkbox + " " + styles.AV}
                  {...{ onClick: this.handleClick }}>
                  <input
                    id="AVCheckbox"
                    type="checkbox"
                    name={copy.admin.newSASUserTile.AVcheckbox}
                    checked={this.state.allowAV === true ? "checked" : ""}
                    onChange={this.onChangeAV}
                  />
                  <div className={styles.imgHolder}>
                    <img alt="Ambulance Victoria logo" src={AV} />
                  </div>
                  <span className={styles.hidden}>
                    {copy.admin.newSASUserTile.AVcheckbox}
                  </span>
                </label>
                }
                {/* CFA Checkbox*/}
                { this.state.showCFA &&
                <label
                  aria-label={copy.admin.newSASUserTile.CFAcheckbox}
                  className={styles.checkbox}
                  {...{ onClick: this.handleClick }}>
                  <input
                    id="CFACheckbox"
                    type="checkbox"
                    name={copy.admin.newSASUserTile.CFAcheckbox}
                    checked={this.state.allowCFA === true ? "checked" : ""}
                    onChange={this.onChangeCFA}
                  />
                  <div className={styles.imgHolder}>
                    <img alt="Country Fire Authority logo" src={CFA} />
                  </div>
                  <span className={styles.hidden}>
                    {" "}
                    {copy.admin.newSASUserTile.CFAcheckbox}
                  </span>
                </label>
                }
                {/* SES Checkbox*/}
                { this.state.showSES &&
                <label
                  aria-label={copy.admin.SEScheckbox}
                  className={styles.checkbox}
                  {...{ onClick: this.handleClick }}>
                  <input
                    id="SESCheckbox"
                    type="checkbox"
                    name={copy.admin.newSASUserTile.SEScheckbox}
                    checked={this.state.allowSES === true ? "checked" : ""}
                    onChange={this.onChangeSES}
                  />
                  <div className={styles.imgHolder}>
                    <img
                      alt="Victoria State Emergency Service logo"
                      src={SES}
                    />
                  </div>
                  <span className={styles.hidden}>
                    {" "}
                    {copy.admin.newSASUserTile.SEScheckbox}
                  </span>
                </label>
                }
              </div>
              <button
                aria-label="Add new SAS user"
                type="submit"
                onClick={this.handleSubmit.bind(this)}>
                Add
              </button>
            </form>
            <Button
                label="Cancel"
                content="Cancel"
                variant="btn_secondary"
                styles=""
                icon=""
                value="Cancel"
                buttonClick={this.clearForm}
              />
          </Wrapper>
        )}
      </div>
    );
  }
}
