import React from "react";
import ErrorBanner from "../../hoc/ErrorBanner/ErrorBanner";
import copy from "../../assets/copy/copy.json";
import {
  format,
  isToday,
  subDays,
  addDays,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  isSameDay,
  isSameMonth,
  addMonths,
  subMonths,
  differenceInCalendarDays,
  differenceInCalendarMonths,
} from "date-fns";
import Loader from "../../components/UI/Loading/Loading";
import Button from "../UI/Button/Button";

// UIs & styles
import WeekView from "./WeekView";
import styles from "./Calendar.module.scss";

// Data
import { Requests } from "../../api/IdentityServerRequests/Requests";
import Wrapper from "../../hoc/Wrapper/Wrapper";

/*
 * This is the Calendar component, this component is used in three sections
 * in the setting availability section (this.state.type === "personal")
 * sasAppReact/src/views/Settings/AvailabilityView.jsx
 *
 * the admin user result - user details (this.state.type === "personal")
 * sasAppReact/src/views/Admin/UserDetailsView.jsx
 *
 * the availability "dashboard" (this.state.type === "threshold" or "qualification" or "type" )
 * sasAppReact/src/components/Availability
 *
 * This component allows user view timeslots on a the calendar
 */

export default class Calendar extends React.Component {
  constructor(props) {
    super(props);
    this.Requests = new Requests();
    this.state = {
      type: this.props.type,
      showtimes: false,
      /**
       * NOTE:
       * this.props.selectedDate is present for Calendar that is used in Availability dashboard
       * Whenever the user clicks refresh, the current date and month must be correctly displayed (if the user has
       * selected one), therefore, there is a condition for the variables monthPosition, currentMonth and selectedDate
       */
      monthPosition: this.props.selectedDate
        ? differenceInCalendarMonths(
            new Date(this.props.selectedDate),
            new Date()
          ) + 1
        : 1,
      today: new Date(),
      currentMonth: this.props.selectedDate
        ? new Date(this.props.selectedDate)
        : new Date(),
      selectedDate: this.props.selectedDate
        ? new Date(this.props.selectedDate)
        : new Date(),
      viewOnlyMode: this.props.viewOnlyMode, // Turn ability to edit on (settings page) and off (everywhere else)
      availability: [], // Data sent back from API
      selectedDayAvailability: [], // All time slots for the selected day
      monthAvailability: [], // create the month's avialability from what's sent from the API
      repeatClass: "", // recurring event class
      metClass: "", // threshold reached class
      ready: null, // the data has loaded
      numberofDays: null, //  number of days in the month
      errorMessage: null,
      errorCode: null,
    };
    this.messageTimeout = null;
  }

  componentDidMount() {
    this.renderAvailability();
  }

  componentWillUnmount = () => {
    clearTimeout(this.messageTimeout);
  };

  /**
   * Called when the page needs to be refreshed
   */
  handleRefresh = () => {
    this.renderAvailability();
  };

  /*
   * This function fills in the missing day (days without any availability set)
   * This is to reduce the size of the availability cache object
   * So the calculations are done in react instead of the database
   */
  renderAvailability = () => {
    // one month view includes the days of last months and next months to fill out a week
    const { currentMonth } = this.state;
    const monthStart = startOfMonth(currentMonth); // first day of the month
    const monthEnd = endOfMonth(monthStart); // last day of the month
    const startDate = startOfWeek(monthStart); // this includes days from last months
    const endDate = endOfWeek(monthEnd); // this include days of next month

    let dateFrom = format(startDate, "yyyy-MM-dd");
    let dateTo = format(endDate, "yyyy-MM-dd");

    let dates = {
      dateFrom: dateFrom, // start date of month period - ie 2020-01-26 (26 jan)
      dateTo: dateTo, // end date of month period - ie 2020-02-29 (29 Feb)
    };

    let numberofDays = differenceInCalendarDays(endDate, startDate);
    this.setState({ numberofDays: numberofDays }); // number of days in a month
    let APICall;
    let id; //  id of the person or group
    let id2; // qualification or availability type id
    let ErrorMessage = ""; //Error Message if any of these fail
    let ErrorCode = "";
    if (this.props.id !== "") {
      // This is for the admin & availability "dashboard" sections
      id = this.props.id;
      // if an id is given show the data for the selected user or paging group
      if (this.state.type === "threshold") {
        // show threshold calendar for selected paging group (used on the availability "dashboard")
        // availability/v1/availability/paging/{number}
        APICall = this.Requests.getThresholdMonth;
        ErrorMessage =
          copy.availability.getPaginGroupMonthThresholdAvailAPIErrorMessage +
          ` (Error #${copy.errorCodes.getPaginGroupMonthThresholdAvailAPIErrorMessage})`;
        ErrorCode =
          copy.errorCodes.getPaginGroupMonthThresholdAvailAPIErrorMessage;
      } else if (this.state.type === "personal") {
        // show availability calendar for selected user (used in Admin group search)
        // profile/v1/User/{id}/availability
        APICall = this.Requests.getSelectedUsersAvailability;
        ErrorMessage =
          copy.admin.userDetailsView.getSelectedUserAvailAPIErrorMessage +
          ` (Error #${copy.errorCodes.getSelectedUserAvailAPIErrorMessage})`;
        ErrorCode = copy.errorCodes.getSelectedUserAvailAPIErrorMessage;
      } else if (this.state.type === "qualification") {
        // show qualification​ calendar for selected qualification​ (used on the availability "dashboard")
        // /v1/Availability/paging/{number}/qualification/{qualificationId}/thresholdachieved
        APICall = this.Requests.getQualificationMonth;
        id2 = this.props.qualificationID;
        ErrorMessage =
          copy.availability.getPaginGroupMonthQualsAvailAPIErrorMessage +
          ` (Error #${copy.errorCodes.getPaginGroupMonthQualsAvailAPIErrorMessage})`;
        ErrorCode = copy.errorCodes.getPaginGroupMonthQualsAvailAPIErrorMessage;
      } else if (this.state.type === "type") {
        // show qualification​ calendar for selected availability type (used on the availability "dashboard")
        // /v1​/Availability​/paging​/{number}​/type​/{availabilityType}​/thresholdachieved
        APICall = this.Requests.getAvailTypeMonth;
        id2 = this.props.typeID;
        ErrorMessage =
          copy.availability.getPaginGroupMonthTypeAvailAPIErrorMessage +
          ` (Error #${copy.errorCodes.getPaginGroupMonthTypeAvailAPIErrorMessage})`;
        ErrorCode = copy.errorCodes.getPaginGroupMonthTypeAvailAPIErrorMessage;
      } else {
        return;
      }
    } else {
      // otherwise show the current user
      // show availability calendar for logged in user (used in Settings)
      // profile/v1/User/availability
      APICall = this.Requests.getUsersAvailability;
      ErrorMessage =
        copy.settings.availability.getUserAvailAPIErrorMessage +
        ` (Error #${copy.errorCodes.getUserAvailAPIErrorMessage})`;
      ErrorCode = copy.errorCodes.getUserAvailAPIErrorMessage;
    }
    // id & id2 is allowed to be null
    this.Requests.callAPI(APICall, dates, id, id2).then((data) => {
      if (data && data.status && data.status === 200) {
        // Get availability for the month
        this.setState({ availability: data.data.dateAvailable }, () => {
          //Now fill in the gaps
          let days = [];

          // first date
          let FirstDay = format(startDate, "yyyy-MM-dd");
          let expectedDate = FirstDay;

          let date; // use as the date returned from API
          let actualDate; // date returned formatted

          if (this.state.availability) {
            // Check if any data has been returned
            // eg there are availability set
            for (let ii = 0; ii < this.state.availability.length; ii++) {
              // loop through the months' worth of data
              date = new Date(this.state.availability[ii].date);
              actualDate = format(date, "yyyy-MM-dd");

              // This loops forever (crashes the app) when a date is out of order as it never finds a match
              // Therefore to catch that error, I have set it to run for a max of 35 days (one month worth)
              // The API must send dates in the order of start of the month to the end of the month.
              // Dates sent out of order will not show up.

              // This error may occur again in the future
              // (┛ಠ_ಠ)┛彡┻━┻

              let today = new Date(date);
              let maxOut = new Date(today);
              maxOut.setDate(today.getDate() + 34);
              let formattedMax = format(maxOut, "yyyy-MM-dd");

              // Check if there is data for the day
              if (expectedDate === actualDate) {
                // if there is data for this date add it to the array
                days.push(this.state.availability[ii]);

                // create the next day using today's date
                let today = new Date(date);
                let tomorrow = new Date(today);
                tomorrow.setDate(today.getDate() + 1);
                // This is the new expected date
                expectedDate = format(tomorrow, "yyyy-MM-dd");
              } else if (formattedMax > expectedDate) {
                // if there is no data for this date, simply add the date

                days.push({
                  date: expectedDate,
                });

                // create the next day using the expected date
                let today = new Date(expectedDate);
                let tomorrow = new Date(today);
                tomorrow.setDate(today.getDate() + 1);
                // This is the new expected date
                expectedDate = format(tomorrow, "yyyy-MM-dd");
                // Check again if this expected date matches the date in the API
                ii = ii - 1;
              }
            }
            if (days.length !== numberofDays + 1) {
              // Check if the end of the momth has been reached after the data from the array has ended
              let loopFor = numberofDays + 1 - days.length;
              // add missing dates
              for (let iii = 0; iii < loopFor; iii++) {
                days.push({
                  date: expectedDate,
                });

                // create the next day using the expected date
                let today = new Date(expectedDate);
                let tomorrow = new Date(today);
                tomorrow.setDate(today.getDate() + 1);
                // This is the new expected date
                expectedDate = format(tomorrow, "yyyy-MM-dd");
              }
            }
          } else {
            // There was nothing return, create the days without data

            // add missing dates
            for (let iii = 0; iii < 7; iii++) {
              days.push({
                date: expectedDate,
              });

              // create the next day using the expected date
              let today = new Date(expectedDate);
              let tomorrow = new Date(today);
              tomorrow.setDate(today.getDate() + 1);
              // This is the new expected date
              expectedDate = format(tomorrow, "yyyy-MM-dd");
            }
            //}
          }

          // All the days added together make a month worth of data
          this.setState({ monthAvailability: days }, () => {
            // Data is ready to create the calendar
            this.setState({
              ready: true,
            });
            // if this calendar is being used in the settings page
            // allow users to choose dates from the calendar to view and edit their times
            this.state.type === "personal" &&
              this.onDateClick(this.state.selectedDate);
          });
        });
      } else {
        // let ErrorMessage =
        //   copy.settings.availability.getUserAvailAPIErrorMessage;
        // if (data && data.data && data.data.SASMessageClient) {
        //   ErrorMessage = data.data.SASMessageClient;
        // }
        this.setState(
          { errorMessage: ErrorMessage, ready: false, errorCode: ErrorCode },
          () => {
            this.messageTimeout = setTimeout(
              function () {
                this.setState({ errorMessage: null });
              }.bind(this),
              5000
            );
          }
        );
      }
    });
  };
  /*
   * This renders the current month and year above the calendar &
   * the buttons allows users to move to the previous and next month
   */
  renderHeader() {
    const dateFormat = "MMMM yyyy";

    return (
      <div className={[styles.header, styles.row, styles.flexMiddle].join(" ")}>
        <div className={[styles.col, styles.colStart].join(" ")}>
          <div
            className={[
              styles.icon,
              styles.iconLeft,
              this.state.monthPosition === 1 ? styles.disabled : "", // this stops users from going past the current month
            ].join(" ")}
            onClick={this.prevMonth}>
            chevron_left
          </div>
        </div>
        <div className={[styles.col, styles.colCenter].join(" ")}>
          <span>{format(this.state.currentMonth, dateFormat)}</span>
        </div>
        <div className={[styles.col, styles.colEnd].join(" ")}>
          <div
            className={[
              styles.icon,
              styles.iconRight,
              this.state.monthPosition > 3 ? styles.disabled : "", // this limits the user to three months in the future
            ].join(" ")} // Future: Backend SHOULD check this is well, as making an availability cache object too large would cause trouble
            onClick={this.nextMonth}>
            chevron_right
          </div>
        </div>
      </div>
    );
  }
  /*
   * This renders the days (mon, tue, etc) under the current month year
   */
  renderDays() {
    const dateFormat = "iii"; // mon, tue, etc.
    const days = [];

    let startDate = startOfWeek(this.state.currentMonth);

    for (let i = 0; i < 7; i++) {
      days.push(
        <div className={[styles.col, styles.colCenter].join(" ")} key={i}>
          {format(addDays(startDate, i), dateFormat)}
        </div>
      );
    }

    return <div className={[styles.days, styles.row].join(" ")}>{days}</div>;
  }
  /*
   * This renders the days in the calendar (current month) for Personal availability
   * including the days before and after the current month so all day for that week is shown
   * This also includes the repeat class that show the timeslot is a part of a recurring set
   */
  renderPersonalAvailability() {
    const { currentMonth, selectedDate } = this.state;
    const monthStart = startOfMonth(currentMonth); // first day of the month
    const monthEnd = endOfMonth(monthStart); // last day of the month
    const startDate = startOfWeek(monthStart); // this can includes days from last months
    const endDate = endOfWeek(monthEnd); // this can include days of next month

    const rows = [];

    let days = [];
    let day = startDate;
    let formattedDate = "";

    let repeatPosition = 0;
    let repeatClass;

    let yesterday = subDays(new Date(), 1);

    while (day <= endDate) {
      // loop until the month has been created

      for (let i = 0; i < 7; i++) {
        // create one week at a time

        formattedDate = format(day, "d");
        const cloneDay = day;

        // set recurring timeslot class
        if (repeatPosition < this.state.numberofDays) {
          // loop 0 to totaly number of days in this calendar month
          // include the end of last month and start of next month
          if (
            // Check for repeating class
            this.state.monthAvailability[repeatPosition].repeat !== undefined
          ) {
            // add the clas if it exist
            repeatClass = this.state.monthAvailability[repeatPosition].repeat;
          } else {
            // Leave it empty if there's nothing
            repeatClass = "";
          }
          // Move on to the next
          repeatPosition++;
        }

        // create the day
        days.push(
          <div
            className={[
              styles.col,
              styles.cell,
              "border_calendar", // this is for the light/dark theme
              day < yesterday ? styles.disabled : "", // disable click for past dates not including today
              !isSameMonth(day, monthStart)
                ? styles.disabled // disable last and next month days
                : isSameDay(day, selectedDate)
                ? styles.selected
                : "",
              // This adds the classes that adds the icons for the different types of time
              repeatClass === "Repeat" // this is apart of a recurring timeslot
                ? styles.repeat
                : repeatClass === "RepeatNoRepeat" // this has both recurring timeslot & a one off time
                ? styles.both
                : repeatClass === "NoRepeat" // this is a one off time
                ? styles.NoRepeat
                : "",
              isToday(day) ? styles.today + " grey8" : "", // add class for 'today' on calendar
              day > addMonths(new Date(), 3) && styles.disabled,
            ].join(" ")}
            key={day}
            onClick={() => this.onDateClick(cloneDay)} // link date to data
          >
            <span className={styles.number}>{formattedDate}</span>
          </div>
        );

        day = addDays(day, 1);
        // dayPosition++;
      }
      rows.push(
        <div className={styles.row} key={day}>
          {days}
        </div>
      );
      days = [];
    }

    return <div className={styles.body}>{rows}</div>;
  }
  /*
   * This renders the days in the calendar (current month) for Paging Group Threshold availability
   * including the days before and after the current month so all day for that week is shown
   * This also includes the threshold met class that show if availability threshold has been met for each day
   */
  renderThreshold() {
    const { currentMonth, selectedDate } = this.state;
    const monthStart = startOfMonth(currentMonth); // first day of the month
    const monthEnd = endOfMonth(monthStart); // last day of the month
    const startDate = startOfWeek(monthStart); // this can includes days from last months
    const endDate = endOfWeek(monthEnd); // this can include days of next month

    const rows = [];

    let days = [];
    let day = startDate;
    let formattedDate = "";

    let repeatPosition = 0;
    let metClass;

    let yesterday = subDays(new Date(), 1);

    while (day <= endDate) {
      // loop until the month has been created

      for (let i = 0; i < 7; i++) {
        // create one week at a time

        formattedDate = format(day, "d");
        const cloneDay = day;

        // set met or not met class
        if (repeatPosition < this.state.numberofDays) {
          // loop 0 to totaly number of days in this calendar month
          // include the end of last month and start of next month

          if (
            // Check for repeating class
            this.state.monthAvailability[repeatPosition].thresholdReached !==
            undefined
          ) {
            // add the clas if it exist
            metClass = this.state.monthAvailability[repeatPosition]
              .thresholdReached;
          } else {
            // Leave it empty if there's nothing
            metClass = "";
          }
          // Move on to the next
          repeatPosition++;
        }
        // create the day
        days.push(
          <div
            className={[
              styles.col,
              styles.cell,
              "border_calendar",
              day < yesterday ? styles.disabled : "", // disable click for past dates not including today
              !isSameMonth(day, monthStart)
                ? styles.disabled // disable last and next month days
                : isSameDay(day, selectedDate)
                ? styles.selected
                : "",
              metClass === true ? styles.thresholdMet : "", // update when real data is ready !!!
              isToday(day) ? styles.today : "", // add class for 'today' on calendar
            ].join(" ")}
            key={day}
            onClick={() => this.onDateClick(cloneDay)} // link date to data
          >
            <span className={styles.number}>{formattedDate}</span>
          </div>
        );

        day = addDays(day, 1);
        // dayPosition++;
      }
      rows.push(
        <div className={styles.row} key={day}>
          {days}
        </div>
      );
      days = [];
    }

    return <div className={styles.body}>{rows}</div>;
  }
  /*
   * This function sends the details of the clicked item to the weekView component
   */
  onDateClick = (day) => {
    if (
      this.state.type === "threshold" ||
      this.state.type === "qualification" ||
      this.state.type === "type"
    ) {
      this.sendDate(day);
      this.setState({
        selectedDate: day,
      });
    } else {
      this.setState(
        {
          showtimes: false,
          selectedDate: day,
        },
        () => {
          // change date formate
          let day = format(this.state.selectedDate, "yyyy-MM-dd");
          // read availability
          for (let i = 0; i < this.state.monthAvailability.length; i++) {
            // find matching date
            let matchDay = this.state.monthAvailability[i].date.slice(0, 10);
            if (matchDay === day) {
              // get the availabilty for that date
              let selectedDayAvailability = this.state.monthAvailability[i];
              this.setState({
                selectedDayAvailability: [selectedDayAvailability],
                showtimes: true,
              });
            }
          }
        }
      );
    }
  };
  /*
   * This function renders the calendar for the next month
   */
  nextMonth = () => {
    let monthShowing = this.state.monthPosition;
    let newMonth = monthShowing + 1;

    this.setState(
      {
        monthPosition: newMonth,
        ready: null, // loading state until data has loaded
        showtimes: false, // remove selected date
        currentMonth: addMonths(this.state.currentMonth, 1),
      },
      () => {
        this.renderAvailability();
      }
    );
  };
  /*
   * This function renders the calendar for the previous month
   */
  prevMonth = () => {
    let monthShowing = this.state.monthPosition;
    let newMonth = monthShowing - 1;
    this.setState(
      {
        monthPosition: newMonth,
        ready: null, // loading state until data has loaded
        showtimes: false, // remove selected date
        currentMonth: subMonths(this.state.currentMonth, 1),
      },
      () => {
        this.renderAvailability();
      }
    );
  };

  /* This function takes the (click on date) data from the calendar and passes it the availability "dashboard" */
  sendDate = (date) => {
    this.props.overviewParentCallback(date);
  };

  /* This function takes the (click on time) data from weekview to past up to the add new time component */

  callbackFunction = (childData) => {
    this.props.parentCallback(childData);
  };

  render() {
    return (
      <Wrapper>
        <ErrorBanner
          isVisible={this.state.errorMessage ? true : false}
          ErrorMessage={this.state.errorMessage}
          onTop={true}
        />
        {this.state.type === "threshold" ||
        this.state.type === "qualification" ||
        this.state.type === "type" ? (
          <div className={styles.holder + " " + styles.dashboard}>
            <div className={styles.calendar}>
              {this.renderHeader()}
              {this.renderDays()}
              {this.state.ready !== null ? (
                this.state.ready === true ? (
                  this.renderThreshold()
                ) : (
                  // API called failed
                  <div className="settingsWrapper">
                    <h4>
                      <span className={styles.noMessages}>
                        {this.state.errorCode
                          ? copy.settings.availability
                              .errorInAvailabilityFetch +
                            ` (Error #${this.state.errorCode})`
                          : copy.settings.availability.errorInAvailabilityFetch}
                        <div>
                          <Button
                            label="Refresh"
                            content={copy.global.btnRefresh}
                            variant="btn_solid"
                            styles="greyBg"
                            icon="icon_refresh"
                            buttonClick={this.handleRefresh}
                          />
                        </div>
                      </span>
                    </h4>
                  </div>
                )
              ) : (
                <div className="inLineloadingContainer">
                  <Loader />
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className={styles.holder}>
            <div className={styles.calendar}>
              {this.renderHeader()}
              {this.renderDays()}
              {this.state.ready !== null ? (
                this.state.ready === true ? (
                  this.renderPersonalAvailability()
                ) : (
                  // API called failed
                  <div className="settingsWrapper">
                    <h4>
                      <span className={styles.noMessages}>
                        {this.state.errorCode
                          ? copy.settings.availability
                              .errorInAvailabilityFetch +
                            ` (Error #${this.state.errorCode})`
                          : copy.settings.availability.errorInAvailabilityFetch}
                        <div>
                          <Button
                            label="Refresh"
                            content={copy.global.btnRefresh}
                            variant="btn_solid"
                            styles="greyBg"
                            icon="icon_refresh"
                            buttonClick={this.handleRefresh}
                          />
                        </div>
                      </span>
                    </h4>
                  </div>
                )
              ) : (
                <div className="inLineloadingContainer">
                  <Loader />
                </div>
              )}
            </div>
            {this.state.showtimes === true && (
              <Wrapper>
                <WeekView
                  availability={this.state.selectedDayAvailability}
                  viewOnlyMode={false}
                  parentCallback={this.callbackFunction}
                  parentUpdate={this.renderAvailability}
                  //update={true}
                  calendarClick={true}
                  id={this.props.id} // not a selected user
                  updateHeading={this.props.updateHeading}
                />
              </Wrapper>
            )}
          </div>
        )}
      </Wrapper>
    );
  }
}
