import React from "react";
import Wrapper from "../../hoc/Wrapper/Wrapper";
import { ReactComponent as AppLOGO } from "../../assets/img/logos/SAS-APP.svg";
import ErrorBanner from "../../hoc/ErrorBanner/ErrorBanner";
import { matchPath } from "react-router";

// import { AuthService } from "../../api/Authentication/AuthService";
import styles from "./LoginView.module.scss";
import stylesBtn from "../../components/UI/Button/Button.module.scss";
import { Requests } from "../../api/IdentityServerRequests/Requests";
import { AuthService } from "../../api/Authentication/AuthService";
import { ErrorHandler } from "../../hoc/ErrorBanner/ErrorHandler";
import copy from "../../assets/copy/copy";

import Loader from "../../components/UI/Loading/Loading";

/**
 * Once the user is on the Terms of Use page, they cannont navigate to any other page until the accept the terms.
 * The logic for this is within the accessAllowed function of Routes.jsx
 */
export default class TermsOfUse extends React.Component {
  constructor(props) {
    super(props);

    let isFromOtherPage = undefined;
    if (this.props.location && this.props.location.state) {
      isFromOtherPage = this.props.location.state;
    }
    this.state = {
      termsOfUse: null,
      errorType: null,
      errorUpdatingAcceptance: false,
      disabled: [true, true, true],
      fromOtherPage: isFromOtherPage,
    };
    this.Requests = new Requests();
    this.errorHandler = new ErrorHandler();
    this.authService = new AuthService();
  }

  /**
   * API call is made on page load to check if the user has accepted the latest version of terms of use or not.
   * If they have, we check if their onboarding is complete, if it is they are redirected to home, or else the ASN page.
   */
  componentDidMount() {
    this.checkingRoles();
  }

  /**
   * This retrieves the most recent terms of use and whether the user has accepted it or not.
   */
  getTermsOfUse = () => {
    this.Requests.callAPI(this.Requests.getTermsOfUseAcceptance).then(
      (data) => {
        if (data && data.status && data.status === 200) {
          if (data.data.DateTimeAccepted) {
            window.localStorage.setItem("termsOfUseAccepted", "true");
            // Check if onabording is already complete to show ASN page or not
            if (data.data.OnBoardingComplete) {
              // if user is opening SAS and viewing the event details page, don't load home too
              if (
                !!matchPath(
                  this.props.history.location.pathname,
                  "/eventdetails"
                )
              ) {
                this.props.history.push({
                  pathname: this.props.history.location.pathname,
                });
              } else if (
                !window.cordova ||
                window.localStorage.getItem("seenLocationDisclosure") === "true"
              ) {
                // Don't show location disclosure on desktop or if user has seen the location disclosure page already.
                this.props.history.push({
                  pathname: "/home",
                });
              } else {
                this.props.history.push({
                  pathname: "/location-disclosure",
                });
              }
            } else {
              this.props.history.push({
                pathname: "/ASN",
              });
            }
          } else {
            this.setState({
              termsOfUse: data.data,
            });
          }
        } else {
          this.setState({ errorType: false });
        }
      }
    );
  };

  redirectToASN = () => {
    // If there was no error with getting the latest terms of use, clicking the button should allow the user to accept the terms of use
    // Else, the user should not be able to do anything when they click the button
    if (!this.state.errorType) {
      this.Requests.callAPI(
        this.Requests.termsOfUseAcceptance,
        this.state.termsOfUse.id
      ).then((data) => {
        if (data && data.status && data.status === 200) {
          // Save in localstorage that the user has now accepted terms of use so accessAllowed function in Routes.jsx knows
          window.localStorage.setItem("termsOfUseAccepted", "true");
          if (this.state.fromOtherPage !== undefined) {
            this.props.history.goBack();
          } else {
            // Check - Does user have a saved ASN? - No - show page
            if (this.state.termsOfUse.OnBoardingComplete) {
              this.props.history.push({
                pathname: "/location-dislosure",
              });
            } else {
              this.props.history.push({
                pathname: "/ASN",
              });
            }
          }
        } else {
          this.setState(
            { errorType: false, errorUpdatingAcceptance: true },
            () => {
              setTimeout(
                function () {
                  this.setState({ errorUpdatingAcceptance: false });
                }.bind(this),
                5000
              );
            }
          );
        }
      });
    }
  };

  /**
   * Onchange function for the checkboxes that stops the user from accepting until all three checkboxes are selected before accepting the terms of use.
   */
  onChangeDisabled = (index) => {
    this.setState((state) => {
      const disabledState = state.disabled.map((item, j) => {
        if (j === index) {
          return (state.disabled[index] = !state.disabled[index]);
        } else {
          return item;
        }
      });
      return {
        disabledState,
      };
    });
  };

  /**
   * Activates and deactivates the submit button for the terms if all checkboxes have been selected.
   */
  disableAccept() {
    if (
      this.state.disabled[0] === true ||
      this.state.disabled[1] === true ||
      this.state.disabled[2] === true
    ) {
      return true;
    }
    return false;
  }

  /**
   * If there's a network error in fetching the terms, this will allow the user to refresh the screen in the mobile app to try again.
   */
  retryButtonClick() {
    window.location.reload();
  }

  getFromMobileStorage = (key) =>
    new Promise((resolve, reject) => {
      window.NativeStorage.getObject(key, resolve, reject);
    });

  setInMobileStorage = (key, value) =>
    new Promise((resolve, reject) => {
      window.NativeStorage.putObject(key, value, resolve, reject);
    });

  async storeInitialData(key, value) {
    if (window.cordova) {
      await this.setInMobileStorage(key, value);
    }
  }

  /**
   ** Mobile - grab user roles information from the native storage.
   * if information does not exist in the storage, then call this.checkRoles().
   ** Web - call this.checkRoles().
   */
  async checkingRoles() {
    if (window.cordova) {
      let roles = await this.getFromMobileStorage("roles");
      roles && roles !== "null"
        ? this.checkRolesFromStorage(roles)
        : this.checkRoles();
    } else {
      this.checkRoles();
    }
  }

  /**
   * Checks to see if the user has the roles to be allowed access to the application.
   * This will run when the login screen loads with an access token.
   * logic taken mostly from this.checkRoles()
   * @param roles  information loaded from the native storage
   
   */
  checkRolesFromStorage(roles) {
    let isValid = false;
    let validRoles = [
      "SuperUser",
      "User_CFA",
      "User_AV",
      "User_SES",
      "Manager_CFA",
      "Manager_AV",
      "Manager_SES",
      "Administrator_CFA",
      "Administrator_AV",
      "Administrator_SES",
    ];
    let userRoles;
    if (roles.length > 0) {
      // If user only has one role, the role will be a string
      // If user has multiple roles, it will be a list
      if (typeof roles !== "string") {
        // Filter out the invalid roles from the user's list of roles
        userRoles = roles.filter((role) => {
          return validRoles.indexOf(role) !== -1;
        });
        // Check if the user has any valid roles
        if (userRoles.length > 0) {
          isValid = true;
        }
      } else {
        // Check if the user's role is valid
        if (validRoles.indexOf(roles) !== -1) {
          isValid = true;
        }
      }
    }
    if (!isValid) {
      window.localStorage.setItem("rolesNotValid", true);
      this.props.history.push({
        pathname: "/login",
      });
    } else {
      window.localStorage.removeItem("rolesNotValid");
      this.getTermsOfUse();
    }
  }

  /**
   * Checks to see if the user has the roles to be allowed access to the application.
   * This will run when the login screen loads with an access token.
   * Logic mostly taken from getUserRoles() in Routes.jsx.
   */
  checkRoles = () => {
    this.Requests.callAPI(this.Requests.getCurrentUserRoles).then((data) => {
      if (data && data.status && data.status === 200) {
        this.storeInitialData("roles", data.data);
        let isValid = false;
        let validRoles = [
          "SuperUser",
          "User_CFA",
          "User_AV",
          "User_SES",
          "Manager_CFA",
          "Manager_AV",
          "Manager_SES",
          "Administrator_CFA",
          "Administrator_AV",
          "Administrator_SES",
        ];
        let userRoles;
        if (data.data.length > 0) {
          // If user only has one role, the role will be a string
          // If user has multiple roles, it will be a list
          if (typeof data.data !== "string") {
            // Filter out the invalid roles from the user's list of roles
            userRoles = data.data.filter((role) => {
              return validRoles.indexOf(role) !== -1;
            });
            // Check if the user has any valid roles
            if (userRoles.length > 0) {
              isValid = true;
            }
          } else {
            // Check if the user's role is valid
            if (validRoles.indexOf(data.data) !== -1) {
              isValid = true;
            }
          }
        }
        if (!isValid) {
          window.localStorage.setItem("rolesNotValid", true);
          this.props.history.push({
            pathname: "/login",
          });
        } else {
          window.localStorage.removeItem("rolesNotValid");
          this.getTermsOfUse();
        }
      } else {
        this.setState({ errorType: false });
        window.localStorage.setItem("rolesNotValid", false);
        this.props.history.push({
          pathname: "/login",
        });
      }
    });
  };

  /**
   * This function will delete the FCM token first before starting the logout process for mobile
   */
  handleLogout = () => {
    if (window.cordova) {
      // Delete the mobile app device
      if (window.device.uuid !== undefined) {
        let deviceId = window.device.uuid;
        this.Requests.callAPI(this.Requests.deleteDevice, deviceId).then(
          (data) => {
            if (data && data.status && data.status === 200) {
              //FCM token has been deleted, update it again the next time the user logs in
              window.localStorage.setItem("updateFCMToken", "true");
            }
          }
        );
      }

      this.authService.logout();
    } else {
      // Delete the browser device
      if (navigator.platform && navigator.userAgent) {
        // gets the browser name as the device name
        let browserVersion = null;
        var Sys = {};
        var ua = navigator.userAgent.toLowerCase(); // eslint-disable-next-line
        var s;
        s = ua.match(/msie ([\d.]+)/)
          ? (Sys.ie = s[1]) // eslint-disable-next-line
          : (s = ua.match(/firefox\/([\d.]+)/))
          ? (Sys.firefox = s[1]) // eslint-disable-next-line
          : (s = ua.match(/chrome\/([\d.]+)/))
          ? (Sys.chrome = s[1]) // eslint-disable-next-line
          : (s = ua.match(/opera.([\d.]+)/))
          ? (Sys.opera = s[1]) // eslint-disable-next-line
          : (s = ua.match(/version\/([\d.]+).*safari/))
          ? (Sys.safari = s[1])
          : 0;
        if (Sys.ie) {
          browserVersion = "IE";
        } else if (Sys.firefox) {
          browserVersion = "Firefox";
        } else if (Sys.chrome) {
          browserVersion = "Chrome";
        } else if (Sys.opera) {
          browserVersion = "Opera";
        } else if (Sys.safari) {
          browserVersion = "Safari";
        }

        let deviceId = [navigator.platform, navigator.vendor, browserVersion]
          .join(" ")
          .replace(/\s/g, "");
        this.Requests.callAPI(this.Requests.deleteDevice, deviceId).then(
          (data) => {
            if (data && data.status && data.status === 200) {
              console.log("Device has been deleted.");
            }
          }
        );
      }

      this.authService.logout();
      window.localStorage.removeItem("accessToken");
      let mapLayers = [
        "SES Units",
        "Ambulance Stations",
        "Emergency Markers",
        "Fire Stations",
        "Fire Stations (Forest)",
        "General Hospitals",
        "Emergency Hospitals",
        "CFALocations",
        "SESLocations",
      ];
      mapLayers.forEach((layer) => {
        window.sessionStorage.removeItem(layer);
      });
    }
  };

  render() {
    // Render the terms of use once they are returned from the API
    if (this.state.termsOfUse !== null) {
      return (
        <Wrapper>
          {/*To show if we're having trouble pressing on "Accept" in the Terms of Use page*/}
          <ErrorBanner
            isVisible={this.state.errorUpdatingAcceptance ? true : false}
            ErrorMessage={
              copy.termsOfUse.updatingTOUAPIError +
              ` (Error #${copy.errorCodes.updatingTOUAPIError})`
            }
          />
          <div className={styles.pageHeader}></div>
          <AppLOGO />
          <div className={"box_default " + styles.termsofuse}>
            <h2>Terms of use</h2>
            <p>{this.state.termsOfUse.Payload}</p>
            <div className={styles.holder}>
              <div className={styles.termsOfUseContent}>
                <label
                  aria-label={copy.termsOfUse.personalInformation}
                  className={stylesBtn.checkbox}
                >
                  <input
                    type="checkbox"
                    id="input1"
                    onChange={() => this.onChangeDisabled(0)}
                    name="Agree"
                  />
                  {copy.termsOfUse.personalInformation}
                  <span className={stylesBtn.checkmark}></span>
                </label>
                <label
                  aria-label={copy.termsOfUse.sensitiveInformation}
                  className={stylesBtn.checkbox}
                >
                  <input
                    type="checkbox"
                    id="input2"
                    onChange={() => this.onChangeDisabled(1)}
                    name="Agree"
                  />
                  {copy.termsOfUse.sensitiveInformation}
                  <span className={stylesBtn.checkmark}></span>
                </label>
                <label
                  aria-label={copy.termsOfUse.agree}
                  className={stylesBtn.checkbox}
                >
                  <input
                    type="checkbox"
                    id="input3"
                    onChange={() => this.onChangeDisabled(2)}
                    name="Agree"
                  />
                  {copy.termsOfUse.agree}
                  <span className={stylesBtn.checkmark}></span>
                </label>
              </div>
              <div className={styles.buttonWrapper}>
                <button
                  aria-label="I do not accept the terms of use"
                  content="Back"
                  className="btn_primary"
                  onClick={this.handleLogout}
                >
                  Back
                </button>
                <button
                  aria-label="I accept the terms of use"
                  content="Accept"
                  className="btn_primary"
                  disabled={this.disableAccept()}
                  onClick={this.redirectToASN}
                >
                  {" "}
                  Accept{" "}
                </button>
              </div>
            </div>
          </div>
        </Wrapper>
      );
    } else if (this.state.errorType === false) {
      // Render the call failed page and retry the terms of use call.
      return (
        <Wrapper>
          <div className={styles.pageHeader}></div>
          <AppLOGO />
          <div className={"box_default " + styles.termsofuse}>
            <h2>{copy.termsOfUse.networkError}</h2>
            <p>
              {this.state.errorUpdatingAcceptance
                ? copy.termsOfUse.updatingTOUAPIError
                : copy.termsOfUse.acceptanceAPIError}
            </p>
            <p>
              {copy.termsOfUse.tryAgain +
                ` (Error #${
                  this.state.errorUpdatingAcceptance
                    ? copy.errorCodes.updatingTOUAPIError
                    : copy.errorCodes.acceptanceAPIError
                })`}
            </p>
            <div className={styles.retryActions}>
              <button
                aria-label={copy.termsOfUse.retryBtn}
                content={copy.termsOfUse.retryBtn}
                className="btn_secondary"
                onClick={this.retryButtonClick}
              >
                {" "}
                {copy.termsOfUse.retryBtn}{" "}
              </button>
              <button
                aria-label={copy.termsOfUse.returnBtn}
                content={copy.termsOfUse.returnBtn}
                className="btn_secondary"
                onClick={this.handleLogout}
              >
                {" "}
                {copy.termsOfUse.returnBtn}{" "}
              </button>
            </div>
          </div>
        </Wrapper>
      );
    } else {
      // Loading state while the API is fetching the latest terms
      return (
        <div className="loadingContainer">
          <Loader />
        </div>
      );
    }
  }
}
