import React from "react";
import styles from "./MapLegend.module.scss";
import { ReactComponent as BackButton } from "../../assets/img/icons/back-arrow.svg";
import { ReactComponent as ForwardButton } from "../../assets/img/icons/forwards-arrow.svg";

/**
 * Renders the fullscreen button on the map. handleFullscreenClick must be passed in as onTouchStart to work.
 *
 * @prop    onTouchStart - contains the onTouchStart function from parent element
 */
export default class FullscreenButton extends React.Component {
  render() {
    return (
      <div className={styles.fsStyles}>
        <button
          className={"fullscreenButton mapButton"}
          aria-label="click to view full screen map"
          title={
            this.props.isFullscreen ? "Exit Full Screen Map" : "Full Screen Map"
          }
          //   {...(window.cordova
          //     ? {
          //         onTouchStart: () => {
          //           this.props.onFSClick();
          //         }
          //       }
          //     : {
          //         onClick: () => {
          //           this.props.onFSClick();
          //         }
          //       })}
          {...{
            onClick: () => {
              this.props.onFSClick();
            },
          }}
        >
          <img
            src={this.props.icon}
            alt="full screen icon"
            aria-label="click to view full screen map"
          />
        </button>
        <button
          className={styles.resetStyles + " mapButton"}
          //   {...(window.cordova
          //     ? {
          //         onTouchStart: () => {
          //           this.props.onRSClick(this.props.defaultBounds);
          //         }
          //       }
          //     : {
          //         onClick: () => {
          //           this.props.onRSClick(this.props.defaultBounds);
          //         }
          //       })}
          {...{
            onClick: () => {
              this.props.onRSClick();
            },
          }}
        >
          <img
            src={require("../../assets/img/map/reset.svg")}
            alt="reset"
            title="Reset"
            aria-label="click to reset to original map view"
          />
        </button>
        {this.props.showSearchButton && (
          <button
            aria-label="click to search for POIs"
            className={styles.searchStyles + " mapButton"}
            //   {...(window.cordova
            //     ? {
            //         onTouchStart: () => {
            //           this.props.onRSClick(this.props.defaultBounds);
            //         }
            //       }
            //     : {
            //         onClick: () => {
            //           this.props.onRSClick(this.props.defaultBounds);
            //         }
            //       })}
            {...{
              onClick: () => {
                this.props.onSearchPOIClick();
              },
            }}
          >
            <img
              src={require("../../assets/img/icons/search.svg")}
              alt="search POIs"
              title="Search POIs"
            />
          </button>
        )}
        {this.props.showExpandButton && (
          <button
            title={this.props?.isExpandedView ? "Collapse Map" : "Expand Map"}
            className={styles.turnoutBtn + " fullscreenButton mapButton"}
            {...{
              onClick: () => {
                this.props.onTurnoutExpandClick();
              },
            }}
          >
            {this.props.isExpandedView ? <ForwardButton /> : <BackButton />}
          </button>
        )}
      </div>
    );
  }
}
