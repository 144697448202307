import React from "react";
import { format, isValid, isPast, addMonths, parse } from "date-fns";
import { Dropdown } from "semantic-ui-react";
import Wrapper from "../../hoc/Wrapper/Wrapper";
import Loader from "../../components/UI/Loading/Loading";
import styles from "./Availability.module.scss";
// Page UIs & styles
import Toggle from "../../components/UI/Button/Toggle";
import Checkbox from "../../components/UI/Button/Checkbox.jsx";
// Data
import { Requests } from "../../api/IdentityServerRequests/Requests";

// Sample Data
import times from "../../api/sampleData/times.json";

// Copy
import copy from "../../assets/copy/copy.json";

/*
 * This component allows you to edit or add availability
 * It is only used in settings
 * and it is feed details from the weekview component
 */

export default class AddNewTime extends React.Component {
  constructor(props) {
    super(props);
    this.Requests = new Requests();
    this.state = {
      isLoading: false,
      dateFrom: this.props.date,
      dateTo: "",
      timeStart: this.props.start,
      timeEnd: this.props.end,
      twentyfourhr: false,
      recurring: false,
      repeat: this.props.repeat,
      Monday: false,
      Tuesday: false,
      Wednesday: false,
      Thursday: false,
      Friday: false,
      daysOfWeek: [],
      errorMessage: null,
      successful: null,
      Local: true,
      Region: false,
      // State: false,
      Interstate: false,
      IMT: false,
      StateLongHaul: false,
      All_AVOnly: false,
      Types: ["Local"],
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.messageTimeout = null;
  }

  /*
   * On load all the data from the datetime selected by the user is feed into this component
   * It checks if the user has selected a recurring time or a single entry
   * On load it also sets the max date on recurring timeslots to three months from today
   */
  componentDidMount() {
    if (this.props.repeat) {
      // User has selected a recurring time
      this.setState({
        recurring: true,
        dateTo: format(
          new Date(this.props.repeat.dateEffectiveTo),
          "yyyy-MM-dd"
        ),
        daysOfWeek: this.props.repeat.dayOfWeek,
      });
      // tick all the checkboxes with the dates in the recurring timeslot
      let array = this.props.repeat.dayOfWeek; // make a separate copy of the array
      let Monday = array.indexOf("Monday");
      let Tuesday = array.indexOf("Tuesday");
      let Wednesday = array.indexOf("Wednesday");
      let Thursday = array.indexOf("Thursday");
      let Friday = array.indexOf("Friday");
      let Saturday = array.indexOf("Saturday");
      let Sunday = array.indexOf("Sunday");
      if (Monday !== -1) {
        this.setState({
          Monday: true,
        });
      }
      if (Tuesday !== -1) {
        this.setState({
          Tuesday: true,
        });
      }
      if (Wednesday !== -1) {
        this.setState({
          Wednesday: true,
        });
      }
      if (Thursday !== -1) {
        this.setState({
          Thursday: true,
        });
      }
      if (Friday !== -1) {
        this.setState({
          Friday: true,
        });
      }
      if (Saturday !== -1) {
        this.setState({
          Saturday: true,
        });
      }
      if (Sunday !== -1) {
        this.setState({
          Sunday: true,
        });
      }
    }

    if (this.props.end === "23:59:59") {
      this.setState({ twentyfourhr: true });
    }

    if (this.props.types) {
      // Current values are: Local, RegionShortHaul, StateLongHaul, InterstateDeployment, IMT
      // AV are planning to add a value that will only apply to AV - just put it in the AVONLY section
      let array = this.props.types; // make a separate copy of the array
      let Local = array.indexOf("Local");
      let Region = array.indexOf("RegionShortHaul");
      let Interstate = array.indexOf("InterstateDeployment");
      let StateLongHaul = array.indexOf("StateLongHaul");
      let All_AVOnly = array.indexOf("All_AVOnly");
      let IMT = array.indexOf("IMT");
      this.setState({
        Types: array,
        Local: false,
      });

      if (Local !== -1) {
        this.setState({
          Local: true,
        });
      }
      if (Region !== -1) {
        this.setState({
          Region: true,
        });
      }
      if (Interstate !== -1) {
        this.setState({
          Interstate: true,
        });
      }
      if (IMT !== -1) {
        this.setState({
          IMT: true,
        });
      }
      if (StateLongHaul !== -1) {
        this.setState({
          StateLongHaul: true,
        });
      }
      if (All_AVOnly !== -1) {
        this.setState({
          All_AVOnly: true,
        });
      }
    }

    // This is so the user is not able to input a date longer than 3 months in the future from the current date
    let maxRecurring = format(addMonths(new Date(), 3), "yyyy-MM-dd");
    this.setState({ threeMonths: maxRecurring });

    // This check if the user is only apart of AV, and therefore is only allowed the local availability type option
    // This might change in the future to include one more option
  }

  componentWillUnmount = () => {
    clearTimeout(this.messageTimeout);
  };

  /* This function set the start and end time when the value changes */
  handleInputChange(e) {
    let value = e.target.value;
    let name = e.target.name;

    this.setState((prevState) => {
      return { [name]: value };
    });
  }
  /*
   * This function sets the time for the day to 24 hrs
   * it hides all other values as time is not needed
   * start and end time can be null,
   * which equal to start: 00:00, end: 23:59:59 in the database
   */
  toggle = () => {
    // Toggle filters - show/hide the filters on mobile/tablets
    this.setState((prevState) => ({
      twentyfourhr: !prevState.twentyfourhr,
    }));
  };
  /*
   * This function turns on more options to allow the users to set recurring times
   * this means users must now select recurring days and end date for the submit to be valid
   */
  toggleRecurring = () => {
    // Toggle filters - show/hide the filters on mobile/tablets
    this.setState((prevState) => ({
      recurring: !prevState.recurring,
    }));
  };

  // TO DO combine into one call
  /* Add or remove Monday from the days of the week array */
  selectMonday = () => {
    this.setState(
      (prevState) => ({ Monday: !prevState.Monday }),
      () => {
        if (this.state.Monday === true) {
          var joined = this.state.daysOfWeek.concat("Monday");
          this.setState({ daysOfWeek: joined });
        } else {
          var array = [...this.state.daysOfWeek]; // make a separate copy of the array
          var index = array.indexOf("Monday");
          if (index !== -1) {
            array.splice(index, 1);
            this.setState({ daysOfWeek: array });
          }
        }
      }
    );
  };
  /* Add or remove Tuesday from the days of the week array */
  selectTuesday = () => {
    this.setState(
      (prevState) => ({ Tuesday: !prevState.Tuesday }),
      () => {
        if (this.state.Tuesday === true) {
          var joined = this.state.daysOfWeek.concat("Tuesday");
          this.setState({ daysOfWeek: joined });
        } else {
          var array = [...this.state.daysOfWeek]; // make a separate copy of the array
          var index = array.indexOf("Tuesday");
          if (index !== -1) {
            array.splice(index, 1);
            this.setState({ daysOfWeek: array });
          }
        }
      }
    );
  };
  /* Add or remove Wednesday from the days of the week array */
  selectWednesday = () => {
    this.setState(
      (prevState) => ({ Wednesday: !prevState.Wednesday }),
      () => {
        if (this.state.Wednesday === true) {
          var joined = this.state.daysOfWeek.concat("Wednesday");
          this.setState({ daysOfWeek: joined });
        } else {
          var array = [...this.state.daysOfWeek]; // make a separate copy of the array
          var index = array.indexOf("Wednesday");
          if (index !== -1) {
            array.splice(index, 1);
            this.setState({ daysOfWeek: array });
          }
        }
      }
    );
  };
  /* Add or remove Thursday from the days of the week array */
  selectThursday = () => {
    this.setState(
      (prevState) => ({ Thursday: !prevState.Thursday }),
      () => {
        if (this.state.Thursday === true) {
          var joined = this.state.daysOfWeek.concat("Thursday");
          this.setState({ daysOfWeek: joined });
        } else {
          var array = [...this.state.daysOfWeek]; // make a separate copy of the array
          var index = array.indexOf("Thursday");
          if (index !== -1) {
            array.splice(index, 1);
            this.setState({ daysOfWeek: array });
          }
        }
      }
    );
  };
  /* Add or remove Friday from the days of the week array */
  selectFriday = () => {
    this.setState(
      (prevState) => ({ Friday: !prevState.Friday }),
      () => {
        if (this.state.Friday === true) {
          var joined = this.state.daysOfWeek.concat("Friday");
          this.setState({ daysOfWeek: joined });
        } else {
          var array = [...this.state.daysOfWeek]; // make a separate copy of the array
          var index = array.indexOf("Friday");
          if (index !== -1) {
            array.splice(index, 1);
            this.setState({ daysOfWeek: array });
          }
        }
      }
    );
  };
  /* Add or remove Saturday from the days of the week array */
  selectSaturday = () => {
    this.setState(
      (prevState) => ({ Saturday: !prevState.Saturday }),
      () => {
        if (this.state.Saturday === true) {
          var joined = this.state.daysOfWeek.concat("Saturday");
          this.setState({ daysOfWeek: joined });
        } else {
          var array = [...this.state.daysOfWeek]; // make a separate copy of the array
          var index = array.indexOf("Saturday");
          if (index !== -1) {
            array.splice(index, 1);
            this.setState({ daysOfWeek: array });
          }
        }
      }
    );
  };
  /* Add or remove Sunday from the days of the week array */
  selectSunday = () => {
    this.setState(
      (prevState) => ({ Sunday: !prevState.Sunday }),
      () => {
        if (this.state.Sunday === true) {
          var joined = this.state.daysOfWeek.concat("Sunday");
          this.setState({ daysOfWeek: joined });
        } else {
          var array = [...this.state.daysOfWeek]; // make a separate copy of the array
          var index = array.indexOf("Sunday");
          if (index !== -1) {
            array.splice(index, 1);
            this.setState({ daysOfWeek: array });
          }
        }
      }
    );
  };
  /*
   * This validates and submits the form to add a time
   */
  handleSubmit = (event) => {
    event.preventDefault();
    // This resets the error message
    this.setState(
      {
        errorMessage: null,
        successful: null,
        isLoading: true,
      },
      () => {
        if (this.state.Types.length === 0) {
          // No availability type selected
          const errorMessage = copy.settings.availability.errorNoType;
          this.updateAvailabilityError(errorMessage);
        } else if (
          this.state.twentyfourhr === true &&
          this.state.recurring === true
        ) {
          this.submitRecurring24hrs();
        } else if (
          this.state.twentyfourhr === true &&
          this.state.recurring === false
        ) {
          // 24 hr toggle is on - submit time as full day
          // start & end time not needed
          this.submit24hr();
        } else if (this.state.timeStart === "") {
          // Not 24hrs
          // make sure there is a start time set
          const errorMessage = copy.settings.availability.errorNoStart;
          this.updateAvailabilityError(errorMessage);
        } else if (this.state.timeEnd === "") {
          // Not 24hrs
          // make sure there is a end time set
          const errorMessage = copy.settings.availability.errorNoEnd;
          this.updateAvailabilityError(errorMessage);
        } else if (this.state.timeStart >= this.state.timeEnd) {
          // the start time must be before end time
          const errorMessage = copy.settings.availability.errorEndBeforeStart;
          this.updateAvailabilityError(errorMessage);
        } else if (this.state.recurring === false) {
          // This is not a recurring time
          // Single entry

          //let startDate = new Date(this.state.dateFrom);

          // Did the user pick a date in the past?
          let formattedDateTime = format(
            new Date(this.state.dateFrom),
            "yyyy-MM-dd"
          );

          let startDateTime;
          // to handle when the start time is hh:mm:ss - this time happens when we are editing availability without changing the start time
          if (this.state.timeStart.length === 8) {
            startDateTime = parse(
              formattedDateTime + " " + this.state.timeStart.slice(0, 5),
              "yyyy-MM-dd HH:mm",
              new Date()
            );
            // this handles when time is hh:mm
          } else {
            startDateTime = parse(
              formattedDateTime + " " + this.state.timeStart,
              "yyyy-MM-dd HH:mm",
              new Date()
            );
          }

          if (startDateTime > new Date()) {
            // The start date and time is not before the current date and time
            this.submitSingleTime();

            // Check if they are putting in the same time twice
            // Currently turned off, if you put this in, you don't need this.submitSingleTime();
            // It will be called once the check has been completed
            // this.CheckExisting();
          } else {
            const errorMessage = copy.settings.availability.errorPast;
            this.updateAvailabilityError(errorMessage);
          }
        } else {
          // Recurring time requires further validation
          if (this.state.dateTo) {
            let endDate = new Date(this.state.dateTo);

            // Did the user pick a date further than 3 months in the future?
            let threeMonths = parse(
              this.state.threeMonths + " " + this.state.timeEnd,
              "yyyy-MM-dd HH:mm",
              new Date()
            );
            // IE date input check
            if (endDate)
              if (!isValid(endDate)) {
                // Was what was entered really a date
                const errorMessage = copy.settings.availability.errorInvalid;
                this.updateAvailabilityError(errorMessage);
              } else if (isPast(endDate)) {
                // Did the user pick an end date in the past?
                const errorMessage = copy.settings.availability.errorPast;
                this.updateAvailabilityError(errorMessage);
              } else if (endDate > threeMonths) {
                // IE date input check
                // the end date can't be more than 3 months in the future
                const errorMessage = copy.settings.availability.errorFuture;
                this.updateAvailabilityError(errorMessage);
              } else {
                // All checks done
                // good to submit
                this.submitRecurringTime();
              }
          } else {
            const errorMessage = copy.settings.availability.errorNoEndDate;
            this.updateAvailabilityError(errorMessage);
          }
        }
      }
    );
  };

  /* This function shows the error message than clears it after 5 seconds */
  updateAvailabilityError = (errorMessage) => {
    this.setState(
      {
        errorMessage: errorMessage,
        successful: false,
        isLoading: false,
      },
      () => {
        this.messageTimeout = setTimeout(
          function () {
            this.setState({
              errorMessage: null,
              successful: null,
            });
          }.bind(this),
          5000
        );
      }
    );
  };
  /* This function is not used at all - This function check if the time already exist */
  CheckExisting() {
    let dates = {
      dateFrom: this.state.dateFrom,
    };
    // profile/v1/User/availability
    this.Requests.callAPI(this.Requests.getUsersAvailability, dates).then(
      (data) => {
        if (data && data.status && data.status === 200) {
          for (let i = 0; i > data.data.dateAvailable.length; i++) {
            if (
              data.data.dateAvailable[i].timeAvailable.timeStart ===
                this.state.timeStart &&
              data.data.dateAvailable[i].timeAvailable.timeEnd ===
                this.state.timeEnd
            ) {
              // same date
              // Same start & end time
              // Don't save it again
              const errorMessage = copy.settings.availability.errorConflict;
              this.updateAvailabilityError(errorMessage);
            } else {
              // Otherwise good to go
              this.submitSingleTime();
            }
          }
        } else {
          // Couldn't check the system
          let ErrorMessage =
            copy.settings.availability.getUserAvailAPIErrorMessage;
          if (data && data.data && data.data.SASMessageClient) {
            ErrorMessage = data.data.SASMessageClient;
          }
          this.setState(
            { errorMessage: "Availability " + ErrorMessage },
            () => {
              this.messageTimeout = setTimeout(
                function () {
                  this.setState({ errorMessage: null });
                }.bind(this),
                5000
              );
            }
          );
        }
      }
    );
  }
  // Splitting up each call into it's own function, so the submit isn't crazy big.
  /* This function adds a 24hr timeslot */
  submit24hr() {
    // Sets available for the whole day - send time as null
    let details = {
      dateFrom: this.state.dateFrom,
      timeStart: null,
      timeEnd: null,
      availabilityTypes: this.state.Types,
    };

    let editID;
    let APICall;
    let ErrorMessage = "";

    if (this.props.id) {
      // If there's an ID then the user is editing a time
      editID = this.props.id;
      APICall = this.Requests.editAvailability;
      ErrorMessage =
        copy.settings.availability.editingUserAvailAPIErrorMessage +
        ` (Error #${copy.errorCodes.editingUserAvailAPIErrorMessage})`;
    } else {
      // Otherwise this is a new entry
      APICall = this.Requests.addAvailability;
      ErrorMessage =
        copy.settings.availability.addingUserAvailAPIErrorMessage +
        ` (Error #${copy.errorCodes.addingUserAvailAPIErrorMessage})`;
    }

    // Add a single time to the currently logged in user's availability
    // profile/v1/User/v1/User/availability
    this.Requests.callAPI(APICall, details, editID).then((data) => {
      if (data && data.status && data.status === 200) {
        // show success msg
        this.setState({ successful: true, isLoading: false });
        this.messageTimeout = setTimeout(
          function () {
            this.setState({
              successful: null,
            });
          }.bind(this),
          5000
        );
      } else {
        // let ErrorMessage = copy.settings.availability.addAvailAPIErrorMessage;
        // if (data && data.data && data.data.SASMessageClient) {
        //   ErrorMessage = data.data.SASMessageClient;
        // }

        this.updateAvailabilityError(ErrorMessage);
      }
    });
  }

  /* This function adds or edits recurring 24 hours time slots*/
  submitRecurring24hrs() {
    // Sets available for the whole day for a time period- send time as null

    let details = {
      dateFrom: this.state.dateFrom,
      dateTo: this.state.dateTo,
      timeStart: null,
      timeEnd: null,
      daysOfWeek: this.state.daysOfWeek,
      availabilityTypes: this.state.Types,
    };
    let editID;
    let APICall;
    let ErrorMessage = "";

    if (this.props.id) {
      // If there's an ID then the user is editing a recurring time
      editID = this.props.id;
      APICall = this.Requests.editAvailability;
      ErrorMessage =
        copy.settings.availability.editingUserAvailAPIErrorMessage +
        ` (Error #${copy.errorCodes.editingUserAvailAPIErrorMessage})`;
    } else {
      // Otherwise this is a new entry
      APICall = this.Requests.addAvailability;
      ErrorMessage =
        copy.settings.availability.addingUserAvailAPIErrorMessage +
        ` (Error #${copy.errorCodes.addingUserAvailAPIErrorMessage})`;
    }

    if (this.state.dateTo) {
      let endDate = new Date(this.state.dateTo);

      // Did the user pick a date further than 3 months in the future?
      let threeMonths = parse(
        this.state.threeMonths,
        "yyyy-MM-dd",
        new Date()
      );
      // IE date input check
      if (endDate)
        if (!isValid(endDate)) {
          // Was what was entered really a date
          const errorMessage = copy.settings.availability.errorInvalid;
          this.updateAvailabilityError(errorMessage);
        } else if (isPast(endDate)) {
          // Did the user pick an end date in the past?
          const errorMessage = copy.settings.availability.errorPast;
          this.updateAvailabilityError(errorMessage);
        } else if (endDate > threeMonths) {
          // IE date input check
          // the end date can't be more than 3 months in the future
          const errorMessage = copy.settings.availability.errorFuture;
          this.updateAvailabilityError(errorMessage);
        } else {
          // All checks done
          // good to submit
          this.Requests.callAPI(APICall, details, editID).then((data) => {
            if (data && data.status && data.status === 200) {
              // show success msg
              this.setState({ successful: true, isLoading: false });
              this.messageTimeout = setTimeout(
                function () {
                  this.setState({
                    successful: null,
                  });
                }.bind(this),
                5000
              );
            } else {
              // let ErrorMessage =
              //   copy.settings.availability.updateAvailAPIErrorMessage;
              // if (data && data.data && data.data.SASMessageClient) {
              //   ErrorMessage = data.data.SASMessageClient;
              // }

              this.updateAvailabilityError(ErrorMessage);
            }
          });
        }
    } else {
      const errorMessage = copy.settings.availability.errorNoEndDate;
      this.updateAvailabilityError(errorMessage);
    }
  }
  /* This function adds or edits a single timeslot */
  submitSingleTime() {
    // this is a single entry
    let details = {
      dateFrom: this.state.dateFrom,
      timeStart: this.state.timeStart,
      timeEnd: this.state.timeEnd,
      availabilityTypes: this.state.Types,
    };

    let editID;
    let APICall;
    let ErrorMessage = "";

    if (this.props.id) {
      // If there's an ID then the user is editing a time
      editID = this.props.id;
      APICall = this.Requests.editAvailability;
      ErrorMessage =
        copy.settings.availability.editingUserAvailAPIErrorMessage +
        ` (Error #${copy.errorCodes.editingUserAvailAPIErrorMessage})`;
    } else {
      // Otherwise this is a new entry
      APICall = this.Requests.addAvailability;
      ErrorMessage =
        copy.settings.availability.addingUserAvailAPIErrorMessage +
        ` (Error #${copy.errorCodes.addingUserAvailAPIErrorMessage})`;
    }

    this.Requests.callAPI(APICall, details, editID).then((data) => {
      if (data && data.status && data.status === 200) {
        // show success msg
        this.setState({ successful: true, isLoading: false });
        this.messageTimeout = setTimeout(
          function () {
            this.setState({
              successful: null,
            });
          }.bind(this),
          5000
        );
      } else {
        // let ErrorMessage =
        //   copy.settings.availability.updateAvailAPIErrorMessage;
        // if (data && data.data && data.data.SASMessageClient) {
        //   ErrorMessage = data.data.SASMessageClient;
        // }
        this.updateAvailabilityError(ErrorMessage);
      }
    });
  }
  /* This function adds or edits a recurring timeslot */
  submitRecurringTime() {
    let details = {
      dateFrom: this.state.dateFrom,
      dateTo: this.state.dateTo,
      timeStart: this.state.timeStart,
      timeEnd: this.state.timeEnd,
      daysOfWeek: this.state.daysOfWeek,
      availabilityTypes: this.state.Types,
    };

    let editID;
    let APICall;
    let ErrorMessage = "";

    if (this.props.id) {
      // If there's an ID then the user is editing a recurring time
      editID = this.props.id;
      APICall = this.Requests.editAvailability;
      ErrorMessage =
        copy.settings.availability.editingUserAvailAPIErrorMessage +
        ` (Error #${copy.errorCodes.editingUserAvailAPIErrorMessage})`;
    } else {
      // Otherwise this is a new entry
      APICall = this.Requests.addAvailability;
      ErrorMessage =
        copy.settings.availability.addingUserAvailAPIErrorMessage +
        ` (Error #${copy.errorCodes.addingUserAvailAPIErrorMessage})`;
    }

    this.Requests.callAPI(APICall, details, editID).then((data) => {
      if (data && data.status && data.status === 200) {
        // show success msg
        this.setState({ successful: true, isLoading: false });
        this.messageTimeout = setTimeout(
          function () {
            this.setState({
              successful: null,
            });
          }.bind(this),
          5000
        );
      } else {
        // let ErrorMessage =
        //   copy.settings.availability.updateAvailAPIErrorMessage;
        // if (data && data.data && data.data.SASMessageClient) {
        //   ErrorMessage = data.data.SASMessageClient;
        // }
        this.updateAvailabilityError(ErrorMessage);
      }
    });
  }

  handleStartTimeChange(value) {
    this.setState({ timeStart: value });
  }

  handleEndTimeChange(value) {
    this.setState({ timeEnd: value });
  }

  // TO DO combine into one call
  /* Add or remove Local type */
  selectLocal = () => {
    this.setState(
      (prevState) => ({ Local: !prevState.Local }),
      () => {
        if (this.state.Local === true) {
          var joined = this.state.Types.concat("Local");
          this.setState({ Types: joined });
        } else {
          var array = [...this.state.Types]; // make a separate copy of the array
          var index = array.indexOf("Local");
          if (index !== -1) {
            array.splice(index, 1);
            this.setState({ Types: array });
          }
        }
      }
    );
  };
  /* Add or remove Local type */
  selectRegion = () => {
    this.setState(
      (prevState) => ({ Region: !prevState.Region }),
      () => {
        if (this.state.Region === true) {
          var joined = this.state.Types.concat("RegionShortHaul");
          this.setState({ Types: joined });
        } else {
          var array = [...this.state.Types]; // make a separate copy of the array
          var index = array.indexOf("RegionShortHaul");
          if (index !== -1) {
            array.splice(index, 1);
            this.setState({ Types: array });
          }
        }
      }
    );
  };
  /* Add or remove State type */
  // selectState = () => {
  //   this.setState(
  //     (prevState) => ({ State: !prevState.State }),
  //     () => {
  //       if (this.state.State === true) {
  //         var joined = this.state.Types.concat("StateLongHaul");
  //         this.setState({ Types: joined });
  //       } else {
  //         var array = [...this.state.Types]; // make a separate copy of the array
  //         var index = array.indexOf("StateLongHaul");
  //         if (index !== -1) {
  //           array.splice(index, 1);
  //           this.setState({ Types: array });
  //         }
  //       }
  //     }
  //   );
  // };
  /* Add or remove Interstate type */
  selectInterstate = () => {
    this.setState(
      (prevState) => ({ Interstate: !prevState.Interstate }),
      () => {
        if (this.state.Interstate === true) {
          var joined = this.state.Types.concat("InterstateDeployment");
          this.setState({ Types: joined });
        } else {
          var array = [...this.state.Types]; // make a separate copy of the array
          var index = array.indexOf("InterstateDeployment");
          if (index !== -1) {
            array.splice(index, 1);
            this.setState({ Types: array });
          }
        }
      }
    );
  };

  /* Add or remove IMT type */
  selectIMT = () => {
    this.setState(
      (prevState) => ({ IMT: !prevState.IMT }),
      () => {
        if (this.state.IMT === true) {
          var joined = this.state.Types.concat("IMT");
          this.setState({ Types: joined });
        } else {
          var array = [...this.state.Types]; // make a separate copy of the array
          var index = array.indexOf("IMT");
          if (index !== -1) {
            array.splice(index, 1);
            this.setState({ Types: array });
          }
        }
      }
    );
  };

  /* Add or remove StateLongHaul Type */
  selectStateLongHaul = () => {
    this.setState(
      (prevState) => ({ StateLongHaul: !prevState.StateLongHaul }),
      () => {
        if (this.state.StateLongHaul === true) {
          var joined = this.state.Types.concat("StateLongHaul");
          this.setState({ Types: joined });
        } else {
          var array = [...this.state.Types]; // make a separate copy of the array
          var index = array.indexOf("StateLongHaul");
          if (index !== -1) {
            array.splice(index, 1);
            this.setState({ Types: array });
          }
        }
      }
    );
  };

  // This option is only for AV, will automatically select Local for this user
  selectAll_AVOnly = () => {
    this.setState(
      (prevState) => ({ All_AVOnly: !prevState.All_AVOnly }),
      () => {
        if (this.state.All_AVOnly === true) {
          var joined = this.state.Types.concat("All_AVOnly");
          this.setState({ Types: joined });
        } else {
          var array = [...this.state.Types]; // make a separate copy of the array
          var index = array.indexOf("All_AVOnly");
          if (index !== -1) {
            array.splice(index, 1);
            this.setState({ Types: array });
          }
        }
        // Automatically select Local with All
        if (this.state.Local === false) {
          this.selectLocal();
        }
      }
    );
  };

  render() {
    let date;
    if (this.props.date) {
      // This is the date selcted by the user
      // it is used in the title of this component
      date = format(new Date(this.state.dateFrom), "iiii dd/MM/yyy");
    }

    // Local should be disabled if AV Only is enabled.
    let disableLocal = this.state.All_AVOnly;
    return (
      <Wrapper>
        <div className="Section-titles">
          <h2>
            {this.props.isEditTitle
              ? copy.settings.availability.editAvailability
              : copy.settings.availability.addAvailability}
          </h2>
        </div>
        <form onSubmit={this.handleSubmit}>
          <div className="box_default">
            <div className="Sub-Section-titles">
              <h3>{date}</h3>
            </div>
            <div className="toggleHolder">
              <span>Available all day</span>
              <Toggle
                checked={this.state.twentyfourhr === true}
                buttonClick={this.toggle}
                label="Available all day"
                fromAvailability={true}
              />
            </div>

            {
              // No other options need to be shown if availability is set to 24 hrs
              <Wrapper>
                {this.state.twentyfourhr === false ? (
                  <p className="label">SET YOUR AVAILABILITY TIME</p>
                ) : null}
                <ul className="addTimeSlot">
                  {this.state.twentyfourhr === false ? (
                    <li className="flex">
                      <span className="primaryText">Start time</span>
                      {this.props.start ? (
                        // If editing a time - put start time here
                        <Dropdown
                          placeholder="e.g. 00:00"
                          fluid
                          search
                          selection
                          options={times}
                          onChange={(e, { value }) =>
                            this.handleStartTimeChange(value)
                          }
                          defaultValue={this.props.start.slice(0, 5)}
                        />
                      ) : (
                        <Dropdown
                          placeholder="e.g. 00:00"
                          fluid
                          search
                          selection
                          options={times}
                          onChange={(e, { value }) =>
                            this.handleStartTimeChange(value)
                          }
                        />
                      )}
                    </li>
                  ) : null}
                  {this.state.twentyfourhr === false ? (
                    <li className="flex">
                      <span className="primaryText">End time</span>
                      {this.props.end ? (
                        // If editing a time - put end time here
                        // If time is 24hrs - end time is equal to 23.59.59
                        <Dropdown
                          placeholder="e.g. 23:30"
                          fluid
                          search
                          selection
                          options={times}
                          onChange={(e, { value }) =>
                            this.handleEndTimeChange(value)
                          }
                          defaultValue={
                            this.props.end === "23:59:59"
                              ? this.props.end
                              : this.props.end.slice(0, 5)
                          }
                        />
                      ) : (
                        <Dropdown
                          placeholder="e.g. 23:30"
                          fluid
                          search
                          selection
                          options={times}
                          onChange={(e, { value }) =>
                            this.handleEndTimeChange(value)
                          }
                        />
                      )}
                    </li>
                  ) : null}

                  <li>
                    <div className="toggleHolder">
                      <span className="repeating">Repeat this weekly</span>
                      <Toggle
                        checked={this.state.recurring}
                        buttonClick={this.toggleRecurring}
                        label="Repeat this weekly"
                        fromAvailability={true}
                      />
                    </div>
                  </li>
                  {this.state.recurring === true && (
                    // Only show options if user has selected recurring
                    <Wrapper>
                      <li>
                        <p className="description">
                          {copy.settings.availability.repeatingTime}
                        </p>
                      </li>

                      <li className="noline">
                        <Checkbox
                          name="Monday"
                          label="Monday"
                          checked={this.state.Monday === true ? "checked" : ""}
                          onChange={this.selectMonday}
                        />
                      </li>
                      <li className="noline">
                        <Checkbox
                          name="Tuesday"
                          label="Tuesday"
                          checked={this.state.Tuesday === true ? "checked" : ""}
                          onChange={this.selectTuesday}
                        />
                      </li>
                      <li className="noline">
                        <Checkbox
                          name="Wednesday"
                          label="Wednesday"
                          checked={
                            this.state.Wednesday === true ? "checked" : ""
                          }
                          onChange={this.selectWednesday}
                        />
                      </li>
                      <li className="noline">
                        <Checkbox
                          name="Thursday"
                          label="Thursday"
                          checked={
                            this.state.Thursday === true ? "checked" : ""
                          }
                          onChange={this.selectThursday}
                        />
                      </li>
                      <li className="noline">
                        <Checkbox
                          name="Friday"
                          label="Friday"
                          checked={this.state.Friday === true ? "checked" : ""}
                          onChange={this.selectFriday}
                        />
                      </li>
                      <li className="noline">
                        <Checkbox
                          name="Saturday"
                          label="Saturday"
                          checked={
                            this.state.Saturday === true ? "checked" : ""
                          }
                          onChange={this.selectSaturday}
                        />
                      </li>
                      <li className="noline">
                        <Checkbox
                          name="Sunday"
                          label="Sunday"
                          checked={this.state.Sunday === true ? "checked" : ""}
                          onChange={this.selectSunday}
                        />
                      </li>
                      <li className={"flex " + styles.dateSelect}>
                        <span className="primaryText">Stop repeating</span>
                        <input
                          className="ui fluid multiple search selection dropdown"
                          required
                          type="date"
                          max={this.state.threeMonths}
                          id="dateTo"
                          name="dateTo"
                          title="stop repeating date"
                          placeholder="dd/mm/yyyy"
                          minLength="8"
                          maxLength="10"
                          value={this.state.dateTo}
                          onChange={this.handleInputChange}
                        />
                      </li>
                    </Wrapper>
                  )}
                </ul>
              </Wrapper>
            }
            {this.props.userAgencies && (
              <ul className="padding">
                <li>
                  <p className="description">
                    {copy.settings.availability.availabilityTypes}
                  </p>
                </li>
                <li className="availabilityTypes">
                  <Checkbox
                    name="Local"
                    label="Local / Home Branch"
                    disabled={disableLocal}
                    checked={this.state.Local === true ? "checked" : ""}
                    onChange={this.selectLocal}
                  />
                </li>
                {(this.props.userAgencies.includes("CFA") ||
                  this.props.userAgencies.includes("SES")) && (
                  // If CFA and SES are included for this user
                  <Wrapper>
                    <li>
                      <Checkbox
                        name="RegionShortHaul"
                        label="Region / Short Haul"
                        checked={this.state.Region === true ? "checked" : ""}
                        onChange={this.selectRegion}
                      />
                    </li>
                    <li>
                      <Checkbox
                        name="StateLongHaul"
                        label="State / Long Haul"
                        checked={
                          this.state.StateLongHaul === true ? "checked" : ""
                        }
                        onChange={this.selectStateLongHaul}
                      />
                    </li>
                    <li>
                      <Checkbox
                        name="Interstate"
                        label="Interstate / Deployment"
                        checked={
                          this.state.Interstate === true ? "checked" : ""
                        }
                        onChange={this.selectInterstate}
                      />
                    </li>
                    <li>
                      <Checkbox
                        name="IMT"
                        label="IMT"
                        checked={this.state.IMT === true ? "checked" : ""}
                        onChange={this.selectIMT}
                      />
                    </li>
                  </Wrapper>
                )}
                {/* AV only is not required at the moment since AV is no longer using Availability
                {this.props.userAgencies.includes("AV") && (
                  // Only show this option for AV
                  <li>
                    <Checkbox
                      name="All_AVOnly"
                      label="All (AV Only)"
                      checked={this.state.All_AVOnly === true ? "checked" : ""}
                      onChange={this.selectAll_AVOnly}
                    />
                  </li>
                )}
                 */}
              </ul>
            )}
            {this.state.successful === true ? (
              this.props.alreadyAvailable ? (
                <Wrapper>
                  {this.props.isEditTitle ? (
                    <p className="fadeaway">
                      Your availability has been updated
                    </p>
                  ) : (
                    <p className="fadeaway">Your availability has been added</p>
                  )}
                  <p className="fadeaway">
                    Please note you already have existing availability times for
                    this day
                  </p>
                </Wrapper>
              ) : this.props.isEditTitle ? (
                <p className="fadeaway">Your availability has been updated</p>
              ) : (
                <p className="fadeaway">Your availability has been added</p>
              )
            ) : this.state.successful === false ? (
              <Wrapper>
                <p className="fadeaway error">
                  Your availability could not be updated.
                </p>
                <p className="fadeaway error">{this.state.errorMessage}</p>
              </Wrapper>
            ) : null}
            {this.state.isLoading ? (
              <div className={styles.loadingWrapper}>
                <Loader />
              </div>
            ) : (
              <button
                type="submit"
                className="btn_primary btn_addtime"
                onClick={this.handleSubmit.bind(this)}>
                {this.props.isEditTitle
                  ? "Update Availability"
                  : "Add Availability"}
              </button>
            )}
          </div>
        </form>
      </Wrapper>
    );
  }
}
