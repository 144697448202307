import React, { Component } from "react";
import { GeoJSON } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";
import L from "leaflet";

/**
 * PointsOfInterest - fetches the points of interest from EMV's service and displays the GeoJSON objects on top of the map.
 *
 * @state externalData - pulls in the relevant point of interest on top of the map
 */
export default class PointsOfInterest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      externalData: null,
    };
  }

  /**
   * Fetches the relevant Point of Interest on top of the Map
   *
   * @return - updates the state 'external data' to store the data returned by EMV's service.
   */
  componentDidMount() {
    let layer, layerURL;
    switch (this.props.layer) {
      case "ses_unit":
        layer = "SES Units";
        layerURL =
          "https://maps.em.vic.gov.au/geojson_layers/sas/ses_unit.json";
        this.iconClassName = "SESUnits";
        break;
      case "ambulance_station":
        layer = "Ambulance Stations";
        layerURL =
          "https://maps.em.vic.gov.au/geojson_layers/sas/ambulance_station.json";
        this.iconClassName = "ambulance";
        break;
      case "emergency_marker":
        layer = "Emergency Markers";
        layerURL =
          "https://maps.em.vic.gov.au/geojson_layers/sas/emergency_marker.json";
        this.iconClassName = "marker";
        break;
      case "fire_station":
        layer = "Fire Stations";
        layerURL =
          "https://maps.em.vic.gov.au/geojson_layers/sas/fire_station.json";
        this.iconClassName = "fireStation";
        break;
      case "fire_station_forest":
        layer = "Fire Stations (Forest)";
        layerURL =
          "https://maps.em.vic.gov.au/geojson_layers/sas/fire_station_forest.json";
        this.iconClassName = "fireStationForest";
        break;
      case "general_hospital":
        layer = "General Hospitals";
        layerURL =
          "https://maps.em.vic.gov.au/geojson_layers/sas/general_hospital.json";
        this.iconClassName = "hospital";
        break;
      case "hospital_emergency":
        layer = "Emergency Hospitals";
        layerURL =
          "https://maps.em.vic.gov.au/geojson_layers/sas/hospital_emergency.json";
        this.iconClassName = "emergency";
        break;
      default:
        layer = null;
        break;
    }
    if (layer) {
      let results = JSON.parse(window.sessionStorage.getItem(layer));
      if (results !== null) {
        this.setState({ externalData: results });
      } else {
        const request = new Request(layerURL);
        fetch(request, {
          cache: "no-store",
        })
          .then((res) => res.json())
          .then(
            (result) => {
              this.setState({ externalData: result });
              window.sessionStorage.setItem(layer, JSON.stringify(result));

              // Saving the coordinates in session storage so that they can be easily accessed later on
              // SES Units...
              if (layer === "SES Units") {
                let locations = {};
                result.features.forEach((feature) => {
                  locations[feature.properties.PFI] =
                    feature.geometry.coordinates[0];
                });
                window.sessionStorage.setItem(
                  "SESLocations",
                  JSON.stringify(locations)
                );
                // CFA stations...
              } else if (
                layer === "Fire Stations" ||
                layer === "Fire Stations (Forest)"
              ) {
                let locations = JSON.parse(
                  window.sessionStorage.getItem("CFALocations")
                );
                // If the CFA stations are not in session storage yet, make a new object to store all the locations
                if (locations === null) {
                  locations = {};
                }
                result.features.forEach((feature) => {
                  locations[feature.properties.PFI] =
                    feature.geometry.coordinates[0];
                });
                window.sessionStorage.setItem(
                  "CFALocations",
                  JSON.stringify(locations)
                );
              }
              // Call the setBounds function of Default Map again to reset the unit locations
              this.props.setBounds();
            },
            (error) => {
              console.log(error);
            }
          );
      }
    }
  }

  isBrowserIE() {
    let ua = navigator.userAgent;
    /* MSIE used to detect old browsers and Trident used to newer ones*/
    var is_ie = ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;

    return is_ie;
  }

  createIcon() {
    return new L.divIcon({
      className: "pointOfInterest " + this.iconClassName,
      iconSize: new L.Point(36, 36),
      iconAnchor: new L.Point(14, 28),
      popupAnchor: new L.Point(0, -33),
    });
  }

  createPeopleClusterIcon(cluster) {
    return new L.divIcon({
      className: "pointOfInterest " + this.iconClassName + "-multi",
      iconSize: new L.Point(48, 48),
    });
  }

  pointToLayer(feature, latlng) {
    let marker = L.marker(latlng, { icon: this.createIcon() }).on(
      "click",
      () => {
        this.props.showPopup(feature, this.iconClassName);
        // make icon bigger
      }
    );
    return marker;
  }

  /**
   * Renders the points of interests only if the data has been fetched from the service.
   */
  render() {
    if (this.state.externalData === null) {
      // Render loading state ...
      return null;
    } else {
      // Render real UI, with Marker Clustering only for browsers without IE...
      let currentBrowserIsIE = this.isBrowserIE();
      if (currentBrowserIsIE) {
        return (
          <GeoJSON
            data={this.state.externalData}
            pointToLayer={this.pointToLayer.bind(this)}
          />
        );
      } else {
        return (
          <MarkerClusterGroup
            iconCreateFunction={this.createPeopleClusterIcon.bind(this)}
            showCoverageOnHover={false}
            disableClusteringAtZoom={18}>
            <GeoJSON
              data={this.state.externalData}
              pointToLayer={this.pointToLayer.bind(this)}
            />
          </MarkerClusterGroup>
        );
      }
    }
  }
}
