import React from 'react';
import Wrapper from '../../hoc/Wrapper/Wrapper';
import ErrorBanner from '../../hoc/ErrorBanner/ErrorBanner';
import styles from './Settings.module.scss';

// layout
// import Tabs from '../../components/UI/Tabs/Tabs';

// Page Component
import AddNewTime from '../../components/Availability/AddNewTime';
// import Calendar from '../../components/Availability/Calendar';
// import WeekView from '../../components/Availability/WeekView';

// UIs & styles
import Button from '../../components/UI/Button/Button';

// Data
import { Requests } from '../../api/IdentityServerRequests/Requests';
import { AuthService } from '../../api/Authentication/AuthService';
import copy from '../../assets/copy/copy.json';
import Scheduler from '../../components/Scheduler/Scheduler';

/*
 * This is the Personal Availability Section, this refers to the logged in user's availability.
 * It holds the next 7 day view (WeekView Component) &
 * the calendar view (Calendar Component) &
 * the add new time form (AddNewTime Component)
 *
 * This section allows user to view, edit, & add personal availability.
 */

export default class AvailabilityView extends React.Component {
    constructor(props) {
        super(props);
        this.authService = new AuthService();
        this.Requests = new Requests();
        //const user = this.getUserData();
        this.state = {
            editState: false,
            edit: '',
            deleted: false,
            isEditTitle: false,
        };
    }

    /*
     * The callbackFunction is used to return the details of the the day the user has clicked on, so they may edit the details.
     * These details are feed into the AddNewTime component, which is also used to edit availability.
     * edit childDate = (availability) id integer($int32), date yyyy-MM-dd, start (time) HH:MM, end (time) HH:MM, repeat (recurring timeslot) true/false
     */

    callbackFunction = (childData) => {
        this.setState({
            edit: childData, // This is a data object used in addNewTime
            editState: true, // This refresh the screen
        });
    };

    updateHeading = (state) => {
        this.setState({
            isEditTitle: state,
        });
    };

    closeAddNewTime = () => {
        this.setState({ editState: false });
    };

    /* This force rerenders availability to add new times or remove deleted times */
    renderAvailability = () => {
        this.setState({
            deleted: !this.state.deleted,
        });
    };

    render() {
        const isMobile = window.innerWidth <= 1023;

        return (
            <Wrapper>
                <ErrorBanner
                    isVisible={this.state.errorMessage ? true : false}
                    ErrorMessage={this.state.errorMessage}
                />

                {this.state.editState === true ? (
                    <div className="col-one-third columnTab relative">
                        <Button
                            icon=""
                            label="Back"
                            content={copy.global.btnBack}
                            variant="btn_outline"
                            styles="btn_secondary"
                            buttonClick={this.closeAddNewTime}
                        />
                        <AddNewTime
                            id={this.state.edit.id} //  if you are editing a time it needs the id
                            date={this.state.edit.date} // this send starts date
                            start={this.state.edit.start} // this send start time
                            end={this.state.edit.end} // this sends end time
                            repeat={this.state.edit.repeat} // this send the days of the week and also the end date
                            types={this.state.edit.types} // this send the availability types for editing
                            alreadyAvailable={this.state.edit.alreadyAvailable} // Checks if availability already exsists for this day
                            parentCallback={this.closeAddNewTime} // causes a state change to remove the add new time component and change back to week view
                            userAgencies={this.props.userAgencies}
                            isEditTitle={this.state.isEditTitle}
                        />
                    </div>
                ) : (
                    <div
                        className={
                            'col-two-third columnTab ' + styles.adminColumn
                        }
                    >
                        {isMobile ? null : (
                            <div className="Section-titles">
                                <h2>{copy.settings.availability.availTitle}</h2>
                            </div>
                        )}
                        <div className={styles.scrollableContent}>
                            <Scheduler
                                userAgencies={this.props.userAgencies}
                                pagingGroupsOwned={this.props.pagingGroupsOwned}
                            />

                            {/* <Tabs noHistory>
                                <div content="7 days" variant="btn_solid">
                                    <WeekView
                                        viewOnlyMode={false} // This means the add, delete, edit buttons will appear
                                        parentCallback={this.callbackFunction} // this gets the data for add & edit to give to the addNewTime component
                                        calendarClick={false} // This means the calendar is not deciding what this component shows
                                        id="" // this is used if you are targeting a selected user and not the logged in user
                                        parentUpdate={this.renderAvailability} // data to delete the time
                                        updateHeading={this.updateHeading}
                                    />
                                </div>
                                <div content="MONTH" variant="btn_solid">
                                    <Calendar
                                        type="personal"
                                        parentCallback={this.callbackFunction} //  Send data back from the weekView conponent to the addNewTime component
                                        viewOnlyMode={false} // This means you can click on the days and have it return something
                                        id="" // this is used if you are targeting a selected user or paging group
                                        updateHeading={this.updateHeading}
                                    />
                                </div>
                            </Tabs> */}
                        </div>
                    </div>
                )}
            </Wrapper>
        );
    }
}
