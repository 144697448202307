import React, { Component } from "react";
import Tabs from "../../../components/UI/Tabs/Tabs";
import Accordion from "../../../components/UI/Accordion/Accordion";
import AttendanceCount from "../AttendanceCount";

import { matchPath } from "react-router";
import { withRouter } from "react-router-dom";

import DefaultMap from "../../../components/Map/DefaultMap";
// Page UIs & styles
import Button from "../../../components/UI/Button/Button";
import Icon from "../../../components/UI/Icon/Icon";
import Loader from "../../../components/UI/Loading/Loading";

import { Dropdown } from "semantic-ui-react";
import styles from "./AttendanceList.module.scss";
// Data
import copy from "../../../assets/copy/copy.json";
// img & icons
import icon from "../../../assets/img/icons/timer.svg";

import { Requests } from "../../../api/IdentityServerRequests/Requests";
import Wrapper from "../../../hoc/Wrapper/Wrapper";

/**
 * AttendanceList - creates a list of attendees to an event and shows their attendance status, ETA, destination, and qualification. Also places markers of attending attendees on the map.
 */

class AttendanceList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mapData: this.props.mapData,
      width: window.innerWidth,
      active: false,
      attending: {},
      other: {},
      unavailable: {},
      not: {},
      event: this.props.event,

      // SORTING
      sortDirection: "DESC",
      sortIcon: "icon_sort",

      // FILTER
      showFilters: false,
      filterData: {
        destination: null,
        qualification: [],
      },
      filterLoading: false,
      attendanceList: this.props.attendanceList,

      userQualificationsOption: null,
      isEventDashboard: false,
      activeTab: "List View",
      currentTabNumber: 1,
    };
    this.Requests = new Requests();
  }

  componentDidMount() {
    const isEventDetails = matchPath(
      this.props.location.pathname,
      "/eventdetails"
    );

    if (isEventDetails) {
      this.attendanceInterval = setInterval(this.updateFilterAndSort, 30000);
    }

    const isEventDashboard = !!matchPath(
      this.props.location.pathname,
      "/dashboard"
    );

    if (isEventDashboard) {
      this.setState({
        isEventDashboard: true,
      });
    }
    window.addEventListener("orientationchange", this.handleWindowSizeChange);
    window.addEventListener("resize", this.handleWindowSizeChange);

    let temporaryDictionary;

    temporaryDictionary = {};
    if (this.state.attendanceList) {
      if (this.state.attendanceList.attending !== null) {
        for (let i = 0; i < this.state.attendanceList.attending.length; i++) {
          temporaryDictionary[i] = false;
        }
        this.setState({
          attending: temporaryDictionary,
        });
      }

      temporaryDictionary = {};
      if (this.state.attendanceList.other !== null) {
        for (let i = 0; i < this.state.attendanceList.other.length; i++) {
          temporaryDictionary[i] = false;
        }
        this.setState({
          other: temporaryDictionary,
        });
      }

      temporaryDictionary = {};
      if (this.state.attendanceList.unavailable !== null) {
        for (let i = 0; i < this.state.attendanceList.unavailable.length; i++) {
          temporaryDictionary[i] = false;
        }
        this.setState({
          unavailable: temporaryDictionary,
        });
      }

      temporaryDictionary = {};

      if (this.state.attendanceList.no_Response !== null) {
        for (let i = 0; i < this.state.attendanceList.no_Response.length; i++) {
          temporaryDictionary[i] = false;
        }
        this.setState({
          not: temporaryDictionary,
        });
      }

      this.prepareFilterData();
    }
  }

  componentWillUnmount() {
    const isEventDetails = matchPath(
      this.props.location.pathname,
      "/eventdetails"
    );
    if (isEventDetails) {
      clearInterval(this.attendanceInterval);
    }

    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  // New props will be received if the user changes their attending status or changes their destination
  // Will also receive new props when a update occurs
  componentDidUpdate = (prevProps) => {
    // Updates to attendance list may need to occur whenever new props are received if
    // - No filter is on
    // - The filtered destination is the same as the destination the attendance list is for
    // - THe user has changed destinations
    if (
      this.state.filterData.destination === null ||
      (this.props.userAttendanceDestination &&
        this.props.userAttendanceDestination ===
          this.state.filterData.destination)
    ) {
      // Update attendance list if there are any differences
      if (this.props.attendanceList) {
        if (this.props.attendanceList !== prevProps.attendanceList) {
          this.setState({
            attendanceList: this.props.attendanceList,
          });
        }
      }
    } else if (
      this.props.userAttendanceDestination &&
      this.props.userAttendanceDestination !==
        prevProps.userAttendanceDestination
    ) {
      this.updateFilterAndSort(true);
    }

    if (this.props.mapData !== prevProps.mapData) {
      this.setState({
        mapData: this.props.mapData,
      });
    }
  };

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  ETA(time) {
    if (time === "0") {
      return (
        <span className="greenTxt" itemProp="ETA">
          {copy.event.arrived}
        </span>
      );
    } else if (time === "") {
      return <span itemProp="ETA">{copy.event.unknown}</span>;
    } else {
      return (
        <span itemProp="ETA">
          <img alt="timer icon" src={icon} />
          {time} {copy.event.minutes}
        </span>
      );
    }
  }

  /** This function prepares the data for the attendance filter */
  prepareFilterData = () => {
    let body = {
      agency: [this.props.agency],
    };
    // Preparing qualifications data
    this.Requests.callAPI(this.Requests.getUserQualificationsAgency, body).then(
      (data) => {
        if (data && data.status && data.status === 200) {
          let qualificationsOption = [];
          for (let i = 0; i < data.data.length; i++) {
            qualificationsOption.push({
              key: data.data[i].id,
              text: data.data[i].name,
              value: data.data[i].id,
            });
          }
          this.setState({ userQualificationsOption: qualificationsOption });
        } else {
          let ErrorMessage =
            copy.event.attendanceList.getQualificationsAPIErrorMessage +
            ` (Error #${copy.errorCodes.getQualificationsAPIErrorMessage})`;
          // if (data && data.data && data.data.SASMessageClient) {
          //   ErrorMessage = data.data.SASMessageClient;
          // }
          this.setState(
            { errorMessage: ErrorMessage, userQualificationsOption: {} },
            () => {
              setTimeout(
                function () {
                  this.setState({ errorMessage: null });
                }.bind(this),
                5000
              );
            }
          );
        }
      }
    );
  };

  /**
   * setIcon - creates an icon to display either next to an attendee in the list or a Leaflet divIcon which displays on the map.
   *
   * @param  {type} statusIcon status of the attendee (attending, other, etc.)
   * @return {type}            an icon (or divIcon)
   */
  setIcon(statusIcon, index) {
    switch (statusIcon) {
      case "attending":
        return (
          <Icon
            label="attending"
            content="attending"
            variant="icon_round"
            styles="greenBg"
            icon="icon_tick"
          />
        );
      case "other":
        return (
          <Icon
            label="other"
            content="other"
            variant="icon_round"
            styles="yellowBg"
            icon="icon_dots"
          />
        );
      case "unavailable":
        return (
          <Icon
            label="Not attending this event"
            content="unavailable"
            variant="icon_round"
            styles="redBg"
            icon="icon_cross"
          />
        );
      case "attendingMap":
        return (
          <Icon
            label="attending"
            content=""
            variant="icon_round"
            styles="greenBg"
            icon=""
            value={index + 1}
          />
        );
      default:
        return (
          <Icon
            label="Has not responded"
            content="Has not responded"
            variant="icon_round"
            styles="greyBg"
            icon="icon_unknown"
          />
        );
    }
  }

  /**
   * setDestination - displays a destination icon beside the attendees
   *
   * @param  {type} destination the destination of the attendee (station or event)
   * @return {type}             an icon
   */
  setDestination(destination) {
    switch (destination) {
      case "Station":
        if (this.props.agency !== "SES") {
          return (
            <Icon
              label="travelling to station location"
              content={copy.map.labelCFAstation}
              variant="icon_outline"
              styles="blueTxt"
              icon=""
            />
          );
        } else {
          return (
            <Icon
              label="travelling to unit location"
              content={copy.map.labelSESunit}
              variant="icon_outline"
              styles="blueTxt"
              icon=""
            />
          );
        }
      case "Event":
        return (
          <Icon
            label="travelling directly to the event"
            content={copy.map.labelEvent}
            variant="icon_outline"
            styles="pinkTxt"
            icon=""
          />
        );
      default:
        return;
    }
  }

  toggleQuals(key) {
    key.classList.toggle(key);
  }

  toggleFilter = () => {
    // Toggle filters - show/hide the filters
    this.setState((prevState) => ({ showFilters: !prevState.showFilters }));
  };

  /**
   * This function sorts the attendance based on ETA
   */
  sortAttendance = () => {
    this.setState(
      (prevState) => ({
        ...prevState,
        sortDirection: prevState.sortDirection === "DESC" ? "ASC" : "DESC",
        sortIcon:
          prevState.sortDirection === "DESC" ? "icon_sort flip" : "icon_sort",
      }),
      () => {
        this.updateFilterAndSort(true);
      }
    );

    // Change (ETA) direction - code here
    // closest to farthest
    // farthest to closest
  };

  /**
   * This function changes changes the user's destination in the filter and updates the attendance list
   */
  handleDestinationChange = (type) => {
    if (this.state.filterData.destination !== type) {
      this.setState(
        (prevState) => ({
          ...prevState,
          filterData: {
            ...prevState.filterData,
            destination: type,
          },
        }),
        () => {
          this.updateFilterAndSort(true);
        }
      );
    }
  };

  /**
   * This function makes an API call to get an updated attendance list based on
   * the user's filter and sort
   */
  updateFilterAndSort = (clicked) => {
    // Change to loading state
    this.setState(
      {
        filterLoading: clicked,
      },
      // Make API call
      () => {
        // Set up the body of the API call
        let body = {
          navigateTo: this.state.filterData.destination,
          qualificationId: this.state.filterData.qualification,
          sortType: "ETA",
          sortDirection: this.state.sortDirection,
        };

        this.Requests.callAPI(
          this.Requests.filterAndSortEventAttendees,
          this.props.eventId,
          body
        ).then((data) => {
          if (data && data.status && data.status === 200) {
            //this.props.updateAttendance(data.data);
            this.setState({ attendanceList: data.data, filterLoading: false });
          } else if (data && data.status && data.status === 204) {
            // if there is no content returned for the filter request, show an empty attendance list
            this.setState({
              attendanceList: {
                no_Response: [],
                attending: [],
                unavailable: [],
                other: [],
              },
              filterLoading: false,
            });
          } else if (data && data.status && data.status === 401) {
            // Stop making the filterAndSortEventAttendees call if accesstoken expired
            clearInterval(this.attendanceInterval);
          } else {
            let ErrorMessage =
              copy.event.attendanceList.getAttendanceListAPIErrorMessage +
              ` (Error #${copy.errorCodes.getAttendanceListAPIErrorMessage})`;
            // if (data && data.data && data.data.SASMessageClient) {
            //   ErrorMessage = data.data.SASMessageClient;
            // }
            this.setState(
              { errorMessage: ErrorMessage, filterLoading: false },
              () => {
                setTimeout(
                  function () {
                    this.setState({ errorMessage: null });
                  }.bind(this),
                  5000
                );
              }
            );
          }
        });
      }
    );
  };

  /**
   * This function resets the filter
   */
  handleResetClick = () => {
    this.setState((prevState) => ({
      ...prevState,
      filterData: {
        ...prevState.filterData,
        destination: null,
        qualification: [],
      },
      attendanceList: this.props.attendanceList,
    }));
  };

  renderFilters = () => {
    // save agency qualification in session storage,
    // add selected qual to filter qualification
    // rebuild attendance list based on selection

    const breakpoint = this.state.width <= 500;

    const destinationOptions = (
      <div
        className={
          styles.destination_options +
          " " +
          (breakpoint ? styles.small_screen : null)
        }
      >
        <label
          aria-label="no filter of attendance by destination"
          className={styles.radio}
        >
          <input
            type="radio"
            name="station or unit"
            checked={
              this.state.filterData.destination === null ? "checked" : ""
            }
            onChange={() => this.handleDestinationChange(null)}
          />
          <span className={styles.checkmark}></span>
          All
        </label>
        <label
          aria-label="filter by attendance by destination station or unit"
          className={styles.radio}
        >
          <input
            type="radio"
            name="station or unit"
            checked={
              this.state.filterData.destination === "Station" ? "checked" : ""
            }
            onChange={() => this.handleDestinationChange("Station")}
          />
          <span className={styles.checkmark}></span>
          {this.props.agency === "SES" ? "Unit" : "Station"}
        </label>
        <label
          aria-label="filter by attendance by destination event"
          className={styles.radio}
        >
          <input
            type="radio"
            name="event"
            checked={
              this.state.filterData.destination === "Event" ? "checked" : ""
            }
            onChange={() => this.handleDestinationChange("Event")}
          />
          <span className={styles.checkmark}></span>
          Event
        </label>
      </div>
    );

    return (
      <div className={styles.filterControls + " tileBg"}>
        <p>LIMIT ATTENDANCE TO</p>

        <div
          className={
            styles.holder + " " + (breakpoint ? styles.small_screen : null)
          }
        >
          <span className={styles.label}>Travelling to: </span>
          {breakpoint ? null : destinationOptions}
        </div>
        {breakpoint ? destinationOptions : null}
        <div className={[styles.holder, styles.dropDownItem].join(" ")}>
          <span className={styles.dropdownLabel}>Qualification</span>
          {this.state.userQualificationsOption.length > 1 ? (
            <Dropdown
              placeholder=""
              fluid
              multiple
              search
              selection
              className={styles.dropdown}
              value={this.state.filterData.qualification}
              options={this.state.userQualificationsOption}
              onChange={(e, { value }) => {
                this.setState(
                  (prevState) => ({
                    ...prevState,
                    filterData: {
                      ...prevState.filterData,
                      qualification: value,
                    },
                  }),
                  () => {
                    this.updateFilterAndSort(true);
                  }
                );
              }}
            />
          ) : this.state.userQualificationsOption.length === 1 ? (
            <span>{this.state.userQualificationsOption[0].text}</span>
          ) : (
            <span>
              <p>No selectable qualifications</p>
            </span>
          )}
        </div>
        <div className={styles.filterButtons}>
          <Button
            label="reset"
            content={copy.global.btnReset}
            variant="btn_outline"
            styles="btn_secondary"
            icon=""
            buttonClick={this.handleResetClick}
          />
          <Button
            label="close"
            content={copy.global.btnClose}
            variant="green"
            styles={"btn_secondary " + styles.closeBtn}
            icon=""
            buttonClick={this.toggleFilter}
          />
        </div>
      </div>
    );
  };

  mapTabClick = (tab) => {
    let tabIndex = tab === "Map View" ? 2 : 1;
    this.setState({
      activeTab: tab,
      currentTabNumber: tabIndex,
    });
  };

  /**
   * render - renders a list of attendees and markers for the map
   * @return {type}  those things above */
  render() {
    if (this.state.userQualificationsOption === null) {
      return (
        <div className={styles.loadingContainer}>
          <Loader />
        </div>
      );
    }
    if (this.state.attendanceList) {
      let attending = false;
      // If eventUserAttendance isn't null
      if (this.state.attendanceList.attending !== null) {
        this.attendeeMapList = this.state.attendanceList.attending.map(
          (item, key) => (
            <li key={item.userInfo.userId}>
              {this.setIcon("attendingMap", key)}
              <span className={styles.person} itemProp="person">
                <span className={styles.name} itemProp="name">
                  {item.userInfo.name}
                </span>
              </span>
              <span className={styles.ETA} itemProp="tracking">
                {item.eTA !== null ? (
                  item.eTA !== 0 ? (
                    this.ETA(item.eTA)
                  ) : (
                    <span className="eTAArrived">Arrived</span>
                  )
                ) : (
                  " "
                )}
                {this.setDestination(item.navigateTo)}
              </span>
            </li>
          )
        );

        //let className = {this.props.condition ? (styles.skillList+""+styles.open) : (styles.skillList+""+styles.close)};
        // If eventUserAttendance isn't null
        // const isEventDashboard = !!matchPath(
        //   this.props.location.pathname,
        //   "/dashboard"
        // );
        this.attendeeList = this.state.attendanceList.attending.map(
          (item, key) => (
            <li key={item.userInfo.userId}>
              {/* {() => {this.setState({attending: {...this.state.attending, [key]: false}})}} */}
              <div>
                {this.state.isEventDashboard
                  ? this.setIcon("attendingMap", key)
                  : this.setIcon("attending")}
                {/* {this.setIcon("attending")} */}
                {/* {this.setIcon("attendingMap", key)} */}
                <span className={styles.person} itemProp="person">
                  <span className={styles.name} itemProp="name">
                    {item.userInfo.name}
                  </span>
                </span>
                <span className={styles.ETA} itemProp="tracking">
                  {item.eTA !== null ? (
                    item.eTA !== 0 ? (
                      this.ETA(item.eTA)
                    ) : (
                      <span className="eTAArrived">Arrived</span>
                    )
                  ) : (
                    " "
                  )}
                  {this.setDestination(item.navigateTo)}
                </span>
              </div>
              {item.qualifications !== null &&
              typeof item.qualifications == "object" &&
              item.qualifications.length > 1 ? (
                <Wrapper>
                  <ul
                    className={
                      styles.skillList +
                      " " +
                      styles.close +
                      " " +
                      (this.state.attending[key] ? styles.hide : styles.show)
                    }
                    {...{
                      onClick: () =>
                        this.setState({
                          attending: {
                            ...this.state.attending,
                            [key]: !this.state.attending[key],
                          },
                        }),
                    }}
                  >
                    {item.qualifications.slice(0, 4).map((skill) => (
                      <li key={skill.id}>
                        {skill.name.split(" ").slice(0, 2).join(" ")}
                      </li>
                    ))}
                    <span className={styles.moreButton}>MORE...</span>
                  </ul>
                  <ul
                    className={
                      styles.skillList +
                      " " +
                      styles.open +
                      " " +
                      (this.state.attending[key] ? styles.show : styles.hide) +
                      " " +
                      (this.state.isEventDashboard && styles.dashboard)
                    }
                    {...{
                      onClick: () =>
                        this.setState({
                          attending: {
                            ...this.state.attending,
                            [key]: !this.state.attending[key],
                          },
                        }),
                    }}
                  >
                    {item.qualifications.map((skill) => (
                      <li key={skill.id}>{skill.name}</li>
                    ))}
                    <span className={styles.hideButton}>HIDE</span>
                  </ul>
                </Wrapper>
              ) : item.qualifications !== null &&
                typeof item.qualifications == "object" &&
                item.qualifications.length === 1 ? (
                <p className={styles.noSkills} key={item.qualifications[0].id}>
                  {item.qualifications[0].name}
                </p>
              ) : (
                <p className={styles.noSkills}>No qualification</p>
              )}
            </li>
          )
        );
        attending = true;
      }
      if (!attending) {
        this.attendeeList = null;
        this.attendeeMapList = null;
      }

      // This creates the list of attendees that have clicked 'Other' as their response
      // It starts as false;

      let other = false;
      // If eventUserAttendanceOther isn't null
      if (this.state.attendanceList.other !== null) {
        this.otherList = this.state.attendanceList.other.map((item, key) => (
          <li key={item.userInfo.userId}>
            {/* {() => {this.setState({other: {...this.state.other, [key]: false}})}} */}
            <div>
              {this.setIcon("other")}
              <span className={styles.person} itemProp="person">
                <span className={styles.name} itemProp="name">
                  {item.userInfo.name}
                </span>
              </span>
            </div>
            {item.qualifications !== null &&
            typeof item.qualifications == "object" &&
            item.qualifications.length > 1 ? (
              <Wrapper>
                <ul
                  className={
                    styles.skillList +
                    " " +
                    styles.close +
                    " " +
                    (this.state.other[key] ? styles.hide : styles.show)
                  }
                  {...{
                    onClick: () =>
                      this.setState({
                        other: {
                          ...this.state.other,
                          [key]: !this.state.other[key],
                        },
                      }),
                  }}
                >
                  {item.qualifications.slice(0, 4).map((skill) => (
                    <li key={skill.id}>
                      {skill.name.split(" ").slice(0, 2).join(" ")}
                    </li>
                  ))}
                  <span className={styles.moreButton}>MORE...</span>
                </ul>
                <ul
                  className={
                    styles.skillList +
                    " " +
                    styles.open +
                    " " +
                    (this.state.other[key] ? styles.show : styles.hide) +
                    " " +
                    (this.state.isEventDashboard && styles.dashboard)
                  }
                  {...{
                    onClick: () =>
                      this.setState({
                        other: {
                          ...this.state.other,
                          [key]: !this.state.other[key],
                        },
                      }),
                  }}
                >
                  {item.qualifications.map((skill) => (
                    <li key={skill.id}>{skill.name}</li>
                  ))}
                  <span className={styles.hideButton}>HIDE</span>
                </ul>
              </Wrapper>
            ) : item.qualifications !== null &&
              typeof item.qualifications == "object" &&
              item.qualifications.length === 1 ? (
              <p className={styles.noSkills} key={item.qualifications[0].id}>
                {item.qualifications[0].name}
              </p>
            ) : (
              <p className={styles.noSkills}>No qualification</p>
            )}
          </li>
        ));
        if (this.otherList !== null) {
          if (this.otherList.length > 0) {
            // Retrieving Other text
            let otherText = copy.event.other;
            if (
              this.state.event &&
              this.state.event.paging &&
              this.state.event.paging.settings &&
              this.state.event.paging.settings.attendanceStatusOtherTitle
            ) {
              const newText =
                this.state.event.paging.settings.attendanceStatusOtherTitle;
              otherText =
                newText.charAt(0).toUpperCase() +
                newText.slice(1).toLowerCase();
            }

            other = (
              <div
                isOpen
                key="111"
                label={otherText + " (" + this.otherList.length + ")"}
              >
                <ul>{this.otherList}</ul>
              </div>
            );
          }
        }
      }

      // This creates the list of members that have clicked 'Unavailable' as their response
      // It starts as false;
      let unavailable = false;
      // If eventUserAttendanceUnavailable isn't null
      if (this.state.attendanceList.unavailable !== null) {
        this.unavailableList = this.state.attendanceList.unavailable.map(
          (item, key) => (
            <li key={item.userInfo.userId}>
              {/* {() => {this.setState({unavailable: {...this.state.unavailable, [key]: false}})}} */}
              <div>
                {this.setIcon("unavailable")}
                <span className={styles.person} itemProp="person">
                  <span className={styles.name} itemProp="name">
                    {item.userInfo.name}
                  </span>
                </span>
              </div>
              {item.qualifications !== null &&
              typeof item.qualifications == "object" &&
              item.qualifications.length > 1 ? (
                <Wrapper>
                  <ul
                    className={
                      styles.skillList +
                      " " +
                      styles.close +
                      " " +
                      (this.state.unavailable[key] ? styles.hide : styles.show)
                    }
                    {...{
                      onClick: () =>
                        this.setState({
                          unavailable: {
                            ...this.state.unavailable,
                            [key]: !this.state.unavailable[key],
                          },
                        }),
                    }}
                  >
                    {item.qualifications.slice(0, 4).map((skill) => (
                      <li key={skill.id}>
                        {skill.name.split(" ").slice(0, 2).join(" ")}
                      </li>
                    ))}
                    <span className={styles.moreButton}>MORE...</span>
                  </ul>
                  <ul
                    className={
                      styles.skillList +
                      " " +
                      styles.open +
                      " " +
                      (this.state.unavailable[key]
                        ? styles.show
                        : styles.hide) +
                      " " +
                      (this.state.isEventDashboard && styles.dashboard)
                    }
                    {...{
                      onClick: () =>
                        this.setState({
                          unavailable: {
                            ...this.state.unavailable,
                            [key]: !this.state.unavailable[key],
                          },
                        }),
                    }}
                  >
                    {item.qualifications.map((skill) => (
                      <li key={skill.id}>{skill.name}</li>
                    ))}
                    <span className={styles.hideButton}>HIDE</span>
                  </ul>
                </Wrapper>
              ) : item.qualifications !== null &&
                typeof item.qualifications == "object" &&
                item.qualifications.length === 1 ? (
                <p className={styles.noSkills} key={item.qualifications[0].id}>
                  {item.qualifications[0].name}
                </p>
              ) : (
                <p className={styles.noSkills}>No qualification</p>
              )}
            </li>
          )
        );
        if (this.unavailableList !== null) {
          if (this.unavailableList.length > 0) {
            unavailable = (
              <div
                isOpen
                key="112"
                label={
                  copy.event.unavail + " (" + this.unavailableList.length + ")"
                }
              >
                <ul>{this.unavailableList}</ul>
              </div>
            );
          }
        }
      }

      // This creates the list of members have Not Responded yet
      // It starts as false;
      let not = false;
      // If eventUserAttendanceNotResponded isn't null

      if (this.state.attendanceList.no_Response !== null) {
        this.not = this.state.attendanceList.no_Response.map((item, key) => (
          <li key={item.userInfo.userId}>
            {/* {() => {this.setState({not: {...this.state.not, [key]: false}})}} */}
            <div>
              {this.setIcon()}
              <span className={styles.person} itemProp="person">
                <span className={styles.name} itemProp="name">
                  {item.userInfo.name}
                </span>
              </span>
            </div>
            {item.qualifications !== null &&
            typeof item.qualifications == "object" &&
            item.qualifications.length > 1 ? (
              <Wrapper>
                <ul
                  className={
                    styles.skillList +
                    " " +
                    styles.close +
                    " " +
                    (this.state.not[key] ? styles.hide : styles.show)
                  }
                  {...{
                    onClick: () =>
                      this.setState({
                        not: {
                          ...this.state.not,
                          [key]: !this.state.not[key],
                        },
                      }),
                  }}
                >
                  {item.qualifications.slice(0, 4).map((skill) => (
                    <li key={skill.id}>
                      {skill.name.split(" ").slice(0, 2).join(" ")}
                    </li>
                  ))}
                  <span className={styles.moreButton}>MORE...</span>
                </ul>
                <ul
                  className={
                    styles.skillList +
                    " " +
                    styles.open +
                    " " +
                    (this.state.not[key] ? styles.show : styles.hide) +
                    " " +
                    (this.state.isEventDashboard && styles.dashboard)
                  }
                  {...{
                    onClick: () =>
                      this.setState({
                        not: {
                          ...this.state.not,
                          [key]: !this.state.not[key],
                        },
                      }),
                  }}
                >
                  {item.qualifications.map((skill) => (
                    <li key={skill.id}>{skill.name}</li>
                  ))}
                  <span className={styles.hideButton}>HIDE</span>
                </ul>
              </Wrapper>
            ) : item.qualifications !== null &&
              typeof item.qualifications == "object" &&
              item.qualifications.length === 1 ? (
              <p className={styles.noSkills} key={item.qualifications[0].id}>
                {item.qualifications[0].name}
              </p>
            ) : (
              <p className={styles.noSkills}>No qualification</p>
            )}
          </li>
        ));

        if (this.not) {
          if (this.not.length > 0) {
            not = (
              <div
                isOpen
                key="113"
                label={copy.event.notResp + " (" + this.not.length + ")"}
              >
                <ul>{this.not}</ul>
              </div>
            );
          }
        }
      }

      let agencyType = this.props.agency;
      let eventStatus = this.props.eventStatus;
      // AV does NOT show attendance list because all AV Paging Groups are NOT on the response needed list

      // The attendance list is rendered differently on dashboard compared to other pages.
      const isEventDashboard = !!matchPath(
        this.props.location.pathname,
        "/dashboard"
      );

      if (isEventDashboard) {
        // Render for Dashboard - only the compact attendee map list
        return (
          <Wrapper>
            {/* <ul className={styles.attending + " " + styles.compact}>
            {this.attendeeMapList}
          </ul> */}
            <div className={styles.Attendance_section}>
              <div className={styles.attendance}>
                {this.props.attendanceList && (
                  <AttendanceCount
                    attending={
                      this.props.attendanceList.attending
                        ? this.props.attendanceList.attending.length
                        : "0"
                    }
                    other={
                      this.props.attendanceList.other
                        ? this.props.attendanceList.other.length
                        : "0"
                    }
                    notAttending={
                      this.props.attendanceList.unavailable
                        ? this.props.attendanceList.unavailable.length
                        : "0"
                    }
                  />
                )}
                <Button
                  label={copy.global.btnFilter}
                  // content={copy.global.btnFilter}
                  variant="btn_outline"
                  styles={
                    "grey no_text" +
                    " " +
                    (this.state.showFilters === true ? styles.isActive : "")
                  }
                  icon="icon_filter"
                  buttonClick={this.toggleFilter}
                />
              </div>
              <div
                className={
                  this.state.showFilters === true ? styles.controls : "hidden"
                }
              >
                {this.renderFilters()}
              </div>
              <ul className={styles.attending}>{this.attendeeList}</ul>
              {other}
              {unavailable}
              {not}
            </div>
          </Wrapper>
        );
      } else {
        // Render for all pages except dashboard - the full tabs
        return (
          <div
            className={
              styles.Attendance_section + " col-one-third columnTab attendance"
            }
          >
            <div className="Section-titles">
              <h2>{copy.event.attendance}</h2>
              <div className={styles.center}>
                <Button
                  label={copy.global.btnFilter}
                  content={copy.global.btnFilter}
                  variant="btn_solid"
                  styles={
                    this.state.showFilters === true ? styles.isActive : ""
                  }
                  icon="icon_filter"
                  buttonClick={this.toggleFilter}
                />
                <Button
                  label={copy.global.btnSort}
                  content={copy.global.btnSort}
                  variant="btn_solid"
                  styles="greyBg"
                  icon={this.state.sortIcon}
                  buttonClick={this.sortAttendance}
                />
              </div>
            </div>
            <div
              className={
                this.state.showFilters === true ? styles.controls : "hidden"
              }
            >
              {this.renderFilters()}
            </div>
            <div className={styles.scrollableContent}>
              {eventStatus === "Open" ? (
                // This event has NOT ended! Open (0)
                agencyType !== "AV" && this.props.messageType !== "Admin" ? (
                  // This event is not AV (0) and not an Admin event
                  <Tabs
                    noHistory
                    activeTab={this.state.activeTab}
                    activeTabNumber={this.state.currentTabNumber}
                    buttonClick={this.mapTabClick}
                  >
                    <div
                      label="switch to"
                      content={copy.event.listView}
                      taburl="List View"
                      variant="btn_solid"
                      icon="icon_list"
                    >
                      {this.props.attendanceListLoading || (
                        <Wrapper>
                          {this.state.filterLoading ? (
                            <div className={styles.loadingContainer}>
                              <Loader />
                            </div>
                          ) : (
                            <div>
                              <ul className={styles.attending}>
                                {this.attendeeList}
                              </ul>
                              <Accordion allowMultipleOpen>
                                {other}
                                {unavailable}
                                {not}
                              </Accordion>
                            </div>
                          )}
                        </Wrapper>
                      )}
                    </div>
                    <div
                      label="switch to"
                      content={copy.event.mapView}
                      taburl="Map View"
                      variant="btn_solid"
                      icon="icon_map"
                    >
                      <div className={styles.map}>
                        <DefaultMap
                          showAttendeeMarkers={true}
                          mapData={this.state.mapData} // The attendees are a part of the mapData
                          height={"400px"}
                          userSettings={this.props.userSettings}
                          userData={{ userLocation: null }}
                          hideAttendanceMap={this.props.hideAttendanceMap}
                          hideLocationMapFunction={
                            this.props.hideLocationMapFunction
                          }
                          hideLocationMap={this.props.hideLocationMap}
                          closeFunction={this.props.closeFunction}
                          FullscreenMap={this.props.FullscreenMap}
                          eventAgency={this.props.eventAgency}
                          AttendanceMap={true}
                          hideAttendanceMapFunction={
                            this.props.hideAttendanceMapFunction
                          }
                        />
                      </div>
                      <ul className={styles.attending + " " + styles.compact}>
                        {this.attendeeMapList}
                      </ul>
                    </div>
                  </Tabs>
                ) : (
                  // This event is AV or Admin (no tabs needed because map is not displayed)
                  <div className="tab-content">
                    <ul
                      className={
                        styles.attending + " " + this.props.MessageType ===
                        "Admin"
                          ? ""
                          : styles.av
                      }
                    >
                      {this.attendeeList}
                    </ul>
                    <Accordion allowMultipleOpen>
                      {other}
                      {unavailable}
                      {not}
                    </Accordion>
                  </div>
                )
              ) : (
                // This event has ended
                <div className={"tab-content " + styles.ended}>
                  <ul className={styles.attending}>{this.attendeeList}</ul>
                  <Accordion allowMultipleOpen>
                    {other}
                    {unavailable}
                    {not}
                  </Accordion>
                </div>
              )}
            </div>
          </div>
        );
      }
    } else {
      return null;
    }
  }
}

export default withRouter(AttendanceList);
