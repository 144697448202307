import React, { Component } from "react";
import Wrapper from "../../hoc/Wrapper/Wrapper";
import ErrorBanner from "../../hoc/ErrorBanner/ErrorBanner";
import { Map, TileLayer, LayersControl } from "react-leaflet";
import MapLegend from "./MapLegend";
import PointsOfInterest from "./PointsOfInterest";
import AttendanceLegend from "./AttendanceLegend";
import FullscreenButton from "./FullscreenButton";
import ExtendableTileLayer from "./ExtendableTileLayer";

import Button from "../../components/UI/Button/Button";

import copy from "../../assets/copy/copy.json";
import styles from "./DefaultMap.module.scss";

import { Requests } from "../../api/IdentityServerRequests/Requests";
import { GestureHandling } from "leaflet-gesture-handling";
import { Dropdown } from "semantic-ui-react";

import "leaflet/dist/leaflet.css";
import "leaflet-gesture-handling/dist/leaflet-gesture-handling.css";
import MapRouting from "./MapRouting";

import SES from "../../assets/img/logos/logo_ses.png";
import CFA from "../../assets/img/logos/logo_cfa.png";
import AV from "../../assets/img/logos/logo_av.png";
import EMV from "../../assets/img/logos/logo_emv.svg";
import { format } from "date-fns";
import Timer from "../../components/Timer/Timer";
import AttendanceCount from "../Attendance/AttendanceCount";

var classNames = require("classnames");

const { BaseLayer } = LayersControl;

/**
 * DefaultMap - creates a Leaflet map containing you, station, event, and optionally attendee markers.
 *
 * @prop  height - determines height of the map (required)
 * @prop  event - contains event latlongs for you, station, event (required)
 * @prop  you - contains latlong for you (pass in false if you is not required for the map) (optional)
 * @prop  attendees - contains a list of attendees for the attendance map (optional)
 */
export default class DefaultMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: null,
      defaultBounds: [[-37.814, 144.96332]],
      fullscreen: false,
      icon: require("../../assets/img/map/Fullscreen.svg"),
      height: this.props.height,
      position: "default",
      mapError: false,
      mapRef: React.createRef(),
      layersControlRef: React.createRef(),
      melwaysShowing: false,
      layersControlShowing: false,
      userLocation: this.props.userData
        ? this.props.userData.userLocation
        : null,
      eventLocation: null,
      unitLocation: null,
      locationPermission: false,
      popup: {
        showing: false,
        name: null,
        type: null,
        icon: "marker",
        destination: null,
      },
      event: this.props.event,
      eventBanner: false,
      searchBarShowing: false,
      searchData: [],
      searchButtonShowing: false,
      defaultRoute: this.props.showRoute,
      showRoute: true,
      overlayClicked: false,
    };

    this.locationAPITimer = null;
    this.mapTapped = null;
    this.mapWatch = null;
    this.map = null;
    this.initialised = false;
    this.Requests = new Requests();
    this.activeOverlays = [];
  }

  componentDidMount = () => {
    this.generateMap();

    if (this.props.isFullscreen) {
      this.handleFullscreenClick.bind(this);
    }

    if (!this.initialised) {
      // Sets bounds for first time location is retrieved
      this.setBounds();
      this.initialised = true;
    }

    // ActiveEventDashboardView does not have userData being passed in
    if (
      (this.props.mapData && this.props.userData) ||
      ((this.props.height === "dashboard" || this.props.height === "turnout") &&
        this.props.mapData)
    ) {
      this.setBounds();
    } else if (this.map) {
      this.map.fitBounds(this.state.defaultBounds, {
        padding: [74, 74],
        maxZoom: 15,
      });
    }
    this.checkLocation();
  };

  /**
   * Required for the map to toggle between fullscreen. Changes the map viewport to adjust for change to size.
   */
  componentDidUpdate = (prevProps) => {
    if (!this.state.mapError) {
      if (this.map) {
        this.map.invalidateSize();
      } else {
        this.generateMap();
      }
    }
    if (this.props.userData) {
      if (
        prevProps.userData.userLocation !== this.props.userData.userLocation
      ) {
        this.setBounds();
        this.setState({
          userLocation: this.props.userData.userLocation,
        });
      }
    }
  };

  componentWillUnmount = () => {
    if (this.state.fullscreen) {
      this.handleFullscreenClick();
    }
  };

  componentDidCatch = () => {
    this.setState(
      {
        mapError: true,
      },
      () => {
        this.map = null;
        this.mapTimeout = setTimeout(() => {
          this.setState({ mapError: false });
        }, 10000);
      }
    );
  };

  checkLocation = () => {
    if (
      this.props.you &&
      this.props.userData &&
      this.props.userData.userLocation === null
    ) {
      this.props.getUsersCurrentLocation();
      this.locationTimeout = setTimeout(this.checkLocation, 3000);
    } else {
      clearTimeout(this.locationTimeout);
    }
  };

  generateMap = () => {
    if (
      this.state.mapRef &&
      this.state.mapRef.current &&
      this.state.mapRef.current.leafletElement
    ) {
      this.map = this.state.mapRef.current.leafletElement;

      // adds 2-finger scroll
      this.map.addHandler("gestureHandling", GestureHandling);
      // disables 2-finger scroll on desktop
      if (window.innerWidth < parseInt(styles.mediumScreen)) {
        this.map.gestureHandling.enable();
      }
    }
  };

  // Sets bounds for the map. Use whenever you want to reset the bounds on the map (e.g. on load, hitting reset button, etc.)
  setBounds = () => {
    let boundsArray = [];
    let fitBoundsOptions = {
      padding: [74, 74],
      maxZoom: 15,
    };
    /*--------------------------------------------------*/
    /* DEFAULT MAP LOCATION - EVENT & UNIT STATION ONLY */
    /*--------------------------------------------------*/

    let unitLocation, eventLocation;

    try {
      // Only CFA and SES will have map data
      if (
        this.props.mapData.agency === "CFA" ||
        this.props.mapData.agency === "SES"
      ) {
        let locations = JSON.parse(
          window.sessionStorage.getItem(this.props.mapData.agency + "Locations")
        );
        let coordinates =
          locations[this.props.mapData.unitLocation.properties.PFI];
        unitLocation = [coordinates[1], coordinates[0]];
      } else {
        unitLocation = null;
      }
    } catch {
      unitLocation = null;
    }

    try {
      eventLocation = this.props.mapData.eventLocation.geometry.coordinates;
    } catch {
      eventLocation = null;
    }
    this.setState(
      { unitLocation: unitLocation, eventLocation: eventLocation },
      () => {
        // Sets bounds for the map
        try {
          if (this.props.mapData) {
            if (this.state.eventLocation !== null) {
              // eventLocation = event.location
              //If there's an event location, add it to the bounds array
              boundsArray.push(this.state.eventLocation); // this is the event location
            }
            // If agency is NOT AV (0)
            if (
              this.props.mapData.agency !== "AV" &&
              this.state.unitLocation !== null // unitLocation = event.paging.GeoLocation (is this empty, hence also has no PFI)
            ) {
              // There is a PFI, but no unit location
              boundsArray.push(this.state.unitLocation); // this is the station/unit
            }
          }
          // User location
          if (this.state.userLocation !== null) {
            boundsArray.push(this.state.userLocation);
          }
          /*--------------------------------------------------*/
          /* USER ATTENDANCE MAP LOCATION */
          /*--------------------------------------------------*/
          if (this.props.showAttendeeMarkers && this.props.mapData.attending) {
            // attending = event.userAttendanceOtherUsersByAttendanceStatus.attending
            // Check - Are there any attendees?
            this.props.mapData.attending.forEach((value) => {
              // Check if attendees have locations
              if (value.userLocation !== null) {
                boundsArray.push(value.userLocation.geometry.coordinates);
              }
            });
          }
        } catch {
          boundsArray = this.state.defaultBounds;
        }
        if (!boundsArray[0]) {
          boundsArray = this.state.defaultBounds;
          fitBoundsOptions = { ...fitBoundsOptions, maxZoom: 8 };
        } else {
          this.setState({ defaultBounds: boundsArray });
        }
        try {
          if (this.map) {
            this.map.fitBounds(boundsArray, fitBoundsOptions);
          }
        } catch (error) {
          console.log(error);
        }
      }
    );
  };

  // This method converts a number to hexadecimal and zeropads it
  zeroPad = (num, len, radix) => {
    let str = num.toString(radix || 10);
    while (str.length < len) {
      str = "0" + str;
    }
    return str;
  };

  // This method is used to prepare the coordinates required for the Melways URL
  melwaysCoordinates = (coords) => {
    coords.x = "C" + this.zeroPad(coords.x, 8, 16);
    coords.y = "R" + this.zeroPad(coords.y, 8, 16);
    coords.z = "L" + this.zeroPad(coords.z, 2, 10);
    return coords;
  };

  // This method is used to prepare the coordinates required for the Vicmaps Satellite URL
  vicmapSatelliteCoordinates = (coords) => {
    coords.x = "C" + this.zeroPad(coords.x, 8, 16);
    coords.y = "R" + this.zeroPad(coords.y, 8, 16);
    coords.z = "L" + this.zeroPad(coords.z + 1, 2, 10);
    return coords;
  };

  /**
   * Handles clicks on station, event, you, and reset labels. Changes bounds to recentre the map.
   *
   * @param   newBounds - the new bounds for the map. Passes in marker label latlong clicked on or all markers for reset
   */
  handleLabelClick = (newBounds) => {
    this.map.setView(newBounds, 18, { easeLinearity: 0.01, animate: true });
  };

  /**
   * Handles clicks on event icon on the map.
   */
  handleEventClick = () => {
    this.setState((prevState) => {
      return {
        eventBanner: !prevState.eventBanner,
      };
    });
  };

  /**
   * Handles click on reset button. Resets bounds.
   */
  handleResetClick = () => {
    this.setBounds();
  };
  /**
   * Function which updates the POI data being searched
   * It gets all the active POIs from session storage and updates state.searchData
   */
  updateSearchData = () => {
    let data = [];
    for (var i = 0; i < this.activeOverlays.length; i++) {
      let features = JSON.parse(
        sessionStorage.getItem(this.activeOverlays[i])
      )?.features;
      for (let i = 0; i < features?.length; i++) {
        // Creating data to be used in dropdown search
        data.push({
          key: features[i].properties.PFI,
          value: features[i],
          text: features[i].properties.NAME,
        });
      }
      this.setState({ searchData: data });
    }
  };

  // If an overlay is added, search data should be updated
  addOverlay = (e) => {
    this.activeOverlays.push(e.name);

    if (e.name === "Routing") {
      this.setState({ showRoute: true, overlayClicked: true }, () =>
        this.setState({ overlayClicked: false })
      );
    } else {
      if (!this.state.showSearchButton)
        this.setState({ showSearchButton: true });
      this.updateSearchData();
    }
  };

  // If an overlay is removed, search data should be updated
  removeOverlay = (e) => {
    this.activeOverlays.splice(this.activeOverlays.indexOf(e.name), 1);
    if (this.activeOverlays.length === 0) {
      this.setState({ showSearchButton: false });
    }
    if (e.name === "Routing") {
      this.setState({ showRoute: false, overlayClicked: true }, () =>
        this.setState({ overlayClicked: false })
      );
    } else {
      this.updateSearchData();
    }
  };

  /**
   * Handles click on the search button. Will open a pop up which allows the user to
   * search for POIs
   */
  handleSearchPOIClick = () => {
    this.setState((prevState) => {
      return { searchBarShowing: !prevState.searchBarShowing };
    });
  };

  /**
   * Function to handle when the user clicks on a search result
   * - Search bar should contain the name of the result
   * - Map should be centered on the POI
   * - POI popup should be opened (WIP)
   */
  handleResultSelect = (e, result) => {
    // This centres the map on the POI selected
    this.handleLabelClick([
      result.value.geometry.coordinates[0][1],
      result.value.geometry.coordinates[0][0],
    ]);

    let iconClassName;
    iconClassName = "hospital";
    switch (result.value.properties.FEATURE_SU) {
      case "ses unit":
        iconClassName = copy.poiIconClassNames.SESUnits;
        break;
      case "ambulance station":
        iconClassName = copy.poiIconClassNames.ambulanceStations;
        break;
      case "fire station (forest industry)":
        iconClassName = copy.poiIconClassNames.fireStationsForest;
        break;
      case "general hospital":
        iconClassName = copy.poiIconClassNames.generalHospitals;
        break;
      case "general hospital (emergency)":
        iconClassName = copy.poiIconClassNames.emergencyHospitals;
        break;
      case "emergency marker":
        iconClassName = copy.poiIconClassNames.emergencyMarkers;
        break;
      case "fire station":
        iconClassName = copy.poiIconClassNames.fireStations;
        break;
      default:
        break;
    }
    this.showPopup(result.value, iconClassName);
  };

  /**
   * Handles clicks on fullscreen button. Toggles between states and resizes the window.
   */
  handleFullscreenClick = () => {
    if (this.state.fullscreen) {
      if (this.props.closeFunction) {
        this.props.closeFunction(false);
      }
      if (this.props.eventAgency === "CFA") {
        if (this.props.AttendanceMap) {
          this.props.hideLocationMapFunction(false);
        }
      }

      if (this.props.eventAgency === "SES") {
        if (this.props.LocationMap) {
          this.props.hideAttendanceMapFunction(false);
        }
      }

      this.setState({
        fullscreen: false,
        icon: require("../../assets/img/map/Fullscreen.svg"),
      });
      this.props.FullscreenMap(false);
      document.body.classList.remove("fullscreen-map-mode");
      if (window.innerWidth < parseInt(styles.mediumScreen)) {
        this.map.gestureHandling.enable();
      }
      if (this.state.melwaysShowing) {
        this.map.setMinZoom(7);
      }
    } else {
      // If the location selection is open before the fullscreen button is pressed, close it
      // if (this.props.showLocationSelection) {
      //   this.props.LocationSelection();
      // }
      if (this.props.closeFunction) {
        this.props.closeFunction(true);
      }

      if (this.props.eventAgency === "CFA") {
        if (this.props.AttendanceMap) {
          this.props.hideLocationMapFunction(true);
        }
      }

      if (this.props.eventAgency === "SES") {
        if (this.props.LocationMap) {
          this.props.hideAttendanceMapFunction(true);
        }
      }

      this.setState({
        fullscreen: true,
        icon: require("../../assets/img/map/Minimise.svg"),
      });

      this.props.FullscreenMap(true);
      document.body.classList.add("fullscreen-map-mode");
      if (window.innerWidth < parseInt(styles.mediumScreen)) {
        this.map.gestureHandling.disable();
      }
      if (this.state.melwaysShowing) {
        this.map.setMinZoom(8);
      }
    }
  };

  /**
   * This method will change the min and max zoom of the map depending on which baselayer the
   * user has switched to and whether the map is fullscreen or not
   *
   * @param e - map reference
   * @param fullscreen - boolean whether the map is fullscreen
   */
  handleMapRestrictions = (e, fullscreen) => {
    if (e.name === "Melway" || e.name === "Vicmap Aerial") {
      this.setState({ melwaysShowing: true });
      if (fullscreen) {
        e.target.setMinZoom(8);
      } else {
        e.target.setMinZoom(7);
      }
      e.target.setMaxZoom(17);
      e.target.setMaxBounds([
        [-39.19934, 150.472738],
        [-33.386096, 140.548096],
      ]);
    } else {
      this.setState({ melwaysShowing: false });
      e.target.setMinZoom(6);
      e.target.setMaxZoom(18);
      e.target.setMaxBounds([
        [-45.083491, 157.482815],
        [-5.69048, 112.504849],
      ]);
    }

    this.closeLayersControl();
  };

  /**
   * This will close the layer control
   */
  closeLayersControl = () => {
    this.state.layersControlRef.current.leafletElement.collapse();
    // document.getElementsByClassName('leaflet-control-layers')[0].classList.remove('leaflet-control-layers-expanded');
    this.setState({ layersControlShowing: false });
  };

  /**
   * This is used to toggle the layer control
   */
  toggleLayerControl = () => {
    if (this.state.layersControlShowing) {
      this.closeLayersControl();
    } else {
      this.state.layersControlRef.current.leafletElement.expand();
      this.setState({ layersControlShowing: true });
    }
  };

  showPopup = (feature, icon) => {
    // update info
    this.setState({
      popup: {
        ...this.state.popup,
        name: feature.properties.NAME_LABEL,
        type: feature.properties.FEATURE_SU.toUpperCase(),
        icon: icon,
        destination: feature.geometry.coordinates[0],
        showing: true,
      },
    });
  };

  hidePopup = () => {
    this.setState({ popup: { ...this.state.popup, showing: false } });
  };

  navigateToLocation = (destination) => {
    if (window.device) {
      const ln = window.launchnavigator;

      let appList = [
        ln.APP.GOOGLE_MAPS,
        ln.APP.HERE_MAPS,
        ln.APP.WAZE,
        ln.APP.APPLE_MAPS,
      ];

      // Options specifying navigation apps to choose from
      let options = {
        appSelection: {
          list: appList,
          rememberChoice: {
            enabled: "prompt",
          },
        },
      };

      // Go to navigation app
      ln.navigate([destination[1], destination[0]], options);
    } else {
      if (destination) {
        window.open(
          "https://www.google.com/maps/dir/?api=1&destination=" +
            destination[1].toString() +
            ", " +
            destination[0].toString()
        );
      }
    }
  };

  openEmergencyMarkerLink = (marker) => {
    if (window.cordova) {
      window.SafariViewController.isAvailable(function (available) {
        if (available) {
          window.SafariViewController.show(
            {
              url:
                "https://www.iconyx.com/meet-me-at-the-marker/?marker=" +
                marker,
            },
            function (result) {},
            function (error) {}
          );
        }
      });
    }
    return false;
  };

  // Sets the map layer to the default map layer selected by the user
  // Add any new layers to the copy file
  mapLayers = copy.map.mapLayers.map((value, index) => {
    let checked,
      urlFunction = false;
    if (
      (!this.props.userSettings && index === 0) ||
      (this.props.userSettings &&
        !this.props.userSettings.defaultMappingTiles &&
        index === 0)
    ) {
      checked = true;
    } else if (
      this.props.userSettings &&
      value.settingsName === this.props.userSettings.defaultMappingTiles
    ) {
      checked = true;
    }
    // Add urlFunction here
    switch (value.name) {
      case "Melway":
        urlFunction = this.melwaysCoordinates;
        break;
      case "Vicmap Aerial":
        urlFunction = this.vicmapSatelliteCoordinates;
        break;
      default:
        urlFunction = false;
        break;
    }
    if (urlFunction) {
      return (
        <BaseLayer name={value.name} checked={checked} key={index}>
          <ExtendableTileLayer
            url={value.url}
            returnUrl={value.returnUrl}
            urlFunction={urlFunction}
          />
        </BaseLayer>
      );
    } else {
      return (
        <BaseLayer name={value.name} checked={checked} key={index}>
          <TileLayer url={value.url} />
        </BaseLayer>
      );
    }
  });

  agencyLogo(agency) {
    switch (agency) {
      case "AV": // 0
        return <img src={AV} alt="Ambulance Victoria logo" />;
      case "CFA": // 1
        return <img src={CFA} alt="CFA logo" />;
      case "SES": // 2
        return <img src={SES} alt="SES logo" />;
      default:
        return <img src={EMV} alt="EMV logo" />;
    }
  }

  renderPagerEvent() {
    let isTurnout = this.props.height === "turnout" && styles.dashboardMap;
    let AlertTypeStyle;
    let AlertTypeText;

    if (
      this.state.event.messages[0] &&
      this.state.event.messages[0].messageType
    ) {
      if (this.state.event.messages[0].messageType === "NonEmergency") {
        AlertTypeStyle = styles.nonEmergency;
        AlertTypeText = "NON EMERGENCY";
      } else if (this.state.event.messages[0].messageType === "Emergency") {
        AlertTypeStyle = styles.emergency;
        AlertTypeText = "EMERGENCY";
      } else if (this.state.event.messages[0].messageType === "Admin") {
        AlertTypeStyle = styles.admin;
        AlertTypeText = "ADMIN";
      }
    }
    return (
      <div className={"popup visible"}>
        <div className="popup_background" onClick={this.handleEventClick}></div>
        <div className="popup_content">
          <div
            className={styles.pagerHeader + " " + AlertTypeStyle + " tileBg"}
          >
            <div className={styles.top}>
              <div className={styles.agencyLogo}>
                {this.agencyLogo(
                  this.state.event &&
                    this.state.event.messages[0] &&
                    this.state.event.messages[0].agency
                )}
              </div>
              <div className={styles.alertType}>
                <span className={AlertTypeStyle}>{AlertTypeText}</span>
              </div>
              <div className={styles.timeStamp}>
                <span className={styles.time}>
                  {format(
                    new Date(
                      this.state.event &&
                        this.state.event.messages[0] &&
                        this.state.event.messages[0].messageInputTimeStamp
                    ),
                    "HH:mm:ss dd-MM-yyyy"
                  )}
                </span>
              </div>
            </div>
            <div className={styles.bottom}>
              <div className={styles.pagerGroup + " txt"}>
                <span>
                  {this.state.event &&
                    this.state.event.messages[0] &&
                    this.state.event.messages[0].paging.name}
                </span>
              </div>
            </div>
            <div className={styles.details}>
              <div
                className={
                  styles.pagerDetails + " txt4 smartlook-hide smartlookMask  "
                }
              >
                <span>
                  {this.state.event &&
                    this.state.event.messages[0] &&
                    this.state.event.messages[0].messageText}
                </span>
              </div>
            </div>
            <div className={styles.footerWrapper + " txt"}>
              <Wrapper>
                {this.state.event && this.state.event.eventStatus === "Open" ? (
                  <Timer
                    initialTimestamp={this.state.event.messages}
                    eventStatus={this.state.event.eventStatus}
                    pagerMessage={true}
                    showMobileView={true}
                  />
                ) : (
                  <div className={styles.inactiveIcon}>
                    <span className="grey7 txt4">Inactive</span>
                  </div>
                )}
                {/* Attendance count is only displayed on the dashboard and not for AV */}
                {isTurnout &&
                  this.state.event.messages[0].agency !== "AV" &&
                  this.state.event
                    .userAttendanceOtherUsersByAttendanceStatus !== null && (
                    <AttendanceCount
                      attending={
                        this.state.event
                          ?.userAttendanceOtherUsersByAttendanceStatus
                          ?.attending?.length
                      }
                      other={
                        this.state.event
                          ?.userAttendanceOtherUsersByAttendanceStatus?.other
                          ?.length
                      }
                      notAttending={
                        this.state.event
                          ?.userAttendanceOtherUsersByAttendanceStatus
                          ?.unavailable?.length
                      }
                    />
                  )}
              </Wrapper>
            </div>
          </div>
        </div>
      </div>
    );
  }

  /**
   * Renders the map.
   *
   * this.state.attendance must be set to true for attendees to be rendered.
   * this.props.you must be true for you label and marker to be rendered.
   *
   * @return  the map.
   */
  render() {
    let mapStyles = classNames(styles.defaultMapRegular, {
      [styles.detailsMap]: this.props.height === "350px",
      [styles.attendanceMap]: this.props.height === "400px",
      [styles.eventdashboard]: this.props.height === "dashboard",
      [styles.eventturnout]: this.props.height === "turnout",
      [styles.fullscreen]: this.state.fullscreen,
    });

    let mapPopup = "hidden";
    if (this.state.popup.showing) {
      mapPopup = "visible";
    } else {
      mapPopup = "hidden";
    }

    let isDashboard =
      (this.props.height === "dashboard" || this.props.height === "turnout") &&
      styles.dashboardMap;
    let isTurnout = this.props.height === "turnout" && styles.dashboardMap;
    return (
      // The default map is loaded with Mapscape. // The data for default map
      //  is fed from event overview
      <Wrapper>
        <ErrorBanner
          isVisible={this.state.errorMessage ? true : false}
          ErrorMessage={this.state.errorMessage}
        />
        {!this.state.mapError && (
          <div
            className={["default-map", isDashboard, styles.defaultMap].join(
              " "
            )}
            style={
              this.props.hideAttendanceMap && this.props.AttendanceMap
                ? { display: "none" }
                : this.props.hideLocationMap && !this.props.AttendanceMap
                ? { display: "none" }
                : null
            }
          >
            {this.state.searchBarShowing && (
              <div className="overlay">
                <div className="poi-search-box">
                  <label>Find points of interest</label>
                  <Button
                    label="close search for POIs"
                    content={copy.global.btnClose}
                    variant="btn_outline"
                    styles="btn_secondary"
                    icon="icon_close"
                    buttonClick={() => {
                      this.handleSearchPOIClick();
                      this.hidePopup();
                    }}
                  />
                  <Dropdown
                    placeholder="Search active map layers"
                    fluid
                    search
                    selection
                    options={this.state.searchData}
                    value=""
                    onChange={(e, data) => {
                      this.handleResultSelect(e, data);
                    }}
                  />
                </div>
              </div>
            )}
            <Map
              className={mapStyles}
              maxBounds={[
                [-45.083491, 157.482815],
                [-5.69048, 112.504849],
              ]}
              boundsOptions={{ padding: [74, 74], maxZoom: 14 }}
              maxZoom={18}
              minZoom={6}
              animate={true}
              tap={true}
              touchZoom={true}
              inertia={false}
              dragging={true}
              ref={this.state.mapRef}
              attributionControl={false}
              onBaseLayerChange={(e) => {
                this.handleMapRestrictions(e, this.state.fullscreen);
              }}
              onOverlayAdd={(e) => {
                this.addOverlay(e);
              }}
              onOverlayRemove={(e) => {
                this.removeOverlay(e);
              }}
              gestureHandling={false}
            >
              {window.cordova && (
                <button
                  className="hiddenControlLayers"
                  onClick={this.toggleLayerControl}
                ></button>
              )}
              {/* Layer Control */}
              <LayersControl
                ref={this.state.layersControlRef}
                position="topright"
              >
                {/* Base Layers */}
                {this.mapLayers}
                {this.map !== null &&
                  this.state.unitLocation &&
                  this.state.eventLocation &&
                  this.state.defaultRoute &&
                  isTurnout && (
                    <LayersControl.Overlay name={copy.map.routing}>
                      <MapRouting
                        unit={this.state.unitLocation}
                        event={this.state.eventLocation}
                        map={this.state.mapRef.current}
                        show={this.state.showRoute}
                        overlayClicked={this.state.overlayClicked}
                      />
                    </LayersControl.Overlay>
                  )}
                <LayersControl.Overlay
                  checked={this.props?.userSettings?.defaultPointsOfInterest.includes(
                    copy.map.poiLayers[0].key
                  )}
                  name={copy.map.layerSESunits}
                >
                  <PointsOfInterest
                    layer="ses_unit"
                    showPopup={this.showPopup}
                    setBounds={this.setBounds}
                  />
                </LayersControl.Overlay>
                <LayersControl.Overlay
                  checked={this.props?.userSettings?.defaultPointsOfInterest.includes(
                    copy.map.poiLayers[1].key
                  )}
                  name={copy.map.layerAmbulanceStation}
                >
                  <PointsOfInterest
                    layer="ambulance_station"
                    showPopup={this.showPopup}
                    setBounds={this.setBounds}
                  />
                </LayersControl.Overlay>
                <LayersControl.Overlay
                  checked={this.props?.userSettings?.defaultPointsOfInterest.includes(
                    copy.map.poiLayers[2].key
                  )}
                  name={copy.map.layerEmergencyMarker}
                >
                  <PointsOfInterest
                    layer="emergency_marker"
                    showPopup={this.showPopup}
                    setBounds={this.setBounds}
                  />
                </LayersControl.Overlay>
                <LayersControl.Overlay
                  checked={this.props?.userSettings?.defaultPointsOfInterest.includes(
                    copy.map.poiLayers[3].key
                  )}
                  name={copy.map.layerFireStation}
                >
                  <PointsOfInterest
                    layer="fire_station"
                    showPopup={this.showPopup}
                    setBounds={this.setBounds}
                  />
                </LayersControl.Overlay>
                <LayersControl.Overlay
                  checked={this.props?.userSettings?.defaultPointsOfInterest.includes(
                    copy.map.poiLayers[4].key
                  )}
                  name={copy.map.layerFireStationForest}
                >
                  <PointsOfInterest
                    layer="fire_station_forest"
                    showPopup={this.showPopup}
                    setBounds={this.setBounds}
                  />
                </LayersControl.Overlay>
                <LayersControl.Overlay
                  checked={this.props?.userSettings?.defaultPointsOfInterest.includes(
                    copy.map.poiLayers[5].key
                  )}
                  name={copy.map.layerGeneralHospital}
                >
                  <PointsOfInterest
                    layer="general_hospital"
                    showPopup={this.showPopup}
                    setBounds={this.setBounds}
                  />
                </LayersControl.Overlay>
                <LayersControl.Overlay
                  checked={this.props?.userSettings?.defaultPointsOfInterest.includes(
                    copy.map.poiLayers[6].key
                  )}
                  name={copy.map.layerHospitalEmergency}
                >
                  <PointsOfInterest
                    layer="hospital_emergency"
                    showPopup={this.showPopup}
                    setBounds={this.setBounds}
                  />
                </LayersControl.Overlay>
              </LayersControl>
              {this.props.showAttendeeMarkers === true ? (
                // This data comes from the attendance list component
                <div>
                  {this.props.mapData && (
                    <MapLegend
                      positions={{
                        event: this.state.eventLocation, // eventLocation = event.location
                        station: this.state.unitLocation, // unitLocation = event.paging.GeoLocation
                      }}
                      handleLabelClick={this.handleLabelClick}
                      handleEventClick={this.handleEventClick}
                      defaultBounds={this.state.defaultBounds}
                      agency={this.props.mapData.agency}
                    />
                  )}
                  {this.props.mapData && (
                    <AttendanceLegend
                      attendees={this.props.mapData.attending}
                    />
                  )}
                </div>
              ) : (
                <div>
                  {this.props.mapData && (
                    <MapLegend
                      positions={{
                        you: this.state.userLocation,
                        event: this.state.eventLocation, // eventLocation = event.location
                        station: this.state.unitLocation, // unitLocation = event.paging.GeoLocation
                      }}
                      handleLabelClick={this.handleLabelClick}
                      defaultBounds={this.state.defaultBounds}
                      agency={this.props.mapData.agency} // agency = event.agency
                    />
                  )}
                </div>
              )}
              <div className={["popup", mapPopup].join(" ")}>
                <div
                  className="popup_background"
                  onClick={this.hidePopup}
                ></div>
                <div className="popup_content">
                  <div className="content">
                    <p className="title"> {this.state.popup.name}</p>
                    <div className="icon_section">
                      <img
                        src={require("../../assets/img/map/poi_" +
                          this.state.popup.icon +
                          ".svg")}
                        width="25px"
                        alt={this.state.popup.icon + "icon"}
                      />
                      <p>{this.state.popup.type}</p>
                    </div>
                  </div>
                  <div className="controls">
                    {this.state.popup.type === "EMERGENCY MARKER" ? (
                      <div className="two_section">
                        <div
                          className="navigate_button"
                          onClick={() =>
                            this.navigateToLocation(
                              this.state.popup.destination
                            )
                          }
                        >
                          <button>
                            <img
                              src={require("../../assets/img/icons/navigateto.svg")}
                              alt="navigate button"
                            />{" "}
                          </button>
                          <p>Navigate Here</p>
                        </div>
                        <a
                          className="access_button"
                          {...(window.cordova
                            ? {
                                onClick: () => {
                                  this.openEmergencyMarkerLink(
                                    this.state.popup.name
                                  );
                                },
                              }
                            : {
                                href:
                                  "https://www.iconyx.com/meet-me-at-the-marker/?marker=" +
                                  this.state.popup.name,
                              })}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <button>
                            <img
                              src={require("../../assets/img/icons/accessinfo.svg")}
                              alt="navigate button"
                            />{" "}
                          </button>
                          <p>Access Details</p>
                        </a>
                      </div>
                    ) : (
                      <div className="one_section">
                        <div
                          className="navigate_button"
                          onClick={() =>
                            this.navigateToLocation(
                              this.state.popup.destination
                            )
                          }
                        >
                          <button>
                            <img
                              src={require("../../assets/img/icons/navigateto.svg")}
                              alt="navigate button"
                            />{" "}
                          </button>
                          <p>Navigate Here</p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {this.state.eventBanner && isTurnout && this.renderPagerEvent()}

              <FullscreenButton
                onFSClick={this.handleFullscreenClick}
                isFullscreen={this.props.isFullscreen}
                icon={this.state.icon}
                onRSClick={this.handleResetClick}
                onSearchPOIClick={this.handleSearchPOIClick}
                showSearchButton={this.state.showSearchButton}
                showExpandButton={isTurnout && !this.props.isFullscreen}
                onTurnoutExpandClick={this.props.expandMap}
                isExpandedView={this.props.isMapExpanded}
              />
            </Map>
          </div>
        )}
      </Wrapper>
    );
  }
}
