import React from "react";
import Wrapper from "../../hoc/Wrapper/Wrapper";
import styles from "./DashboardView.module.scss";
import { withRouter } from "react-router-dom";
import { format } from "date-fns";

import Loader from "../../components/UI/Loading/Loading";
import copy from "../../assets/copy/copy.json";

import { Requests } from "../../api/IdentityServerRequests/Requests";
import Button from "../../components/UI/Button/Button";
import AttendanceList from "../../components/Attendance/List/AttendanceList";
import DefaultMap from "../../components/Map/DefaultMap";
import Timer from "../../components/Timer/Timer";

import SES from "../../assets/img/logos/logo_ses.png";
import CFA from "../../assets/img/logos/logo_cfa.png";
import AV from "../../assets/img/logos/logo_av.png";
import EMV from "../../assets/img/logos/logo_emv.svg";

var classNames = require("classnames");

class ActiveEventDashboardView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pagerHeaderHeight: 0,
      event: null,
      isLoading: true,
      userAgencyInfo: null,
      eventSettings: null,
      errorMessage: null, // Error message for the banner
      userSettings: null,
      close: false,
    };
    this.timeout = null;
    this.Requests = new Requests();
    this.getUserSettings();
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    let activeEventID = this.props.eventID;

    if (activeEventID) {
      this.getActiveEventDetails(activeEventID, true);
    }
    this.calculateUserSearchHeaderHeight();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.eventID !== this.props.eventID ||
      prevProps.updateEvent !== this.props.updateEvent
    ) {
      let activeEventID = this.props.eventID;

      if (activeEventID) {
        this.getActiveEventDetails(activeEventID, false);
      }
    }
    if (prevProps.isMapExpanded !== this.props.isMapExpanded) {
      if (this.props.isMapExpanded) {
        this.timeout = setTimeout(
          function () {
            this.calculateUserSearchHeaderHeight();
          }.bind(this),
          500
        );
      } else {
        this.timeout = setTimeout(
          function () {
            this.calculateUserSearchHeaderHeight();
          }.bind(this),
          500
        );
      }
    }
  }

  componentWillUnmount = () => {
    clearTimeout(this.timeout);
  };

  /**
   * Called when the page needs to be refreshed
   * Due to API call(s) failing at the start
   */
  handleRefresh = () => {
    this.setState({ errorMessage: null });

    // Only remake API call to get user settings if it wasn't successful
    if (!this.state.userSettings) {
      this.getUserSettings();
    }

    let activeEventID = this.props.eventID;

    if (activeEventID) {
      this.getActiveEventDetails(activeEventID, true);
    }

    // Trigger refresh of other components of dashboard (if needed)
    this.props.dashboardRefresh();
  };

  getActiveEventDetails(id, showLoader) {
    this.setState(
      {
        isLoading: showLoader,
      },
      () => {
        this.Requests.callAPI(this.Requests.getEventDetails, id).then(
          (data) => {
            if (data && data.status && data.status === 200) {
              this.setState({ event: data.data, isLoading: false }, () => {
                if (this.state.event === undefined) {
                  if (
                    this.state.event.location === undefined ||
                    this.state.event.location === null
                  ) {
                    this.setState({ verifiedCoords: false });
                  }
                }
                this.getUserAgencySettings();
                this.calculateUserSearchHeaderHeight();
              });
            } else {
              let ErrorMessage =
                copy.dashboard.getEventDetailsAPIErrorMessage +
                ` (Error #${copy.errorCodes.getEventDetailsAPIErrorMessage})`;
              // if (data && data.data && data.data.SASMessageClient) {
              //   ErrorMessage = data.data.SASMessageClient;
              // }
              this.setState({ errorMessage: ErrorMessage });
              this.calculateUserSearchHeaderHeight();
            }
          }
        );
      }
    );
  }

  getUserAgencySettings = () => {
    let agency;
    if (this.state.event.paging.agency) {
      agency = this.state.event.paging.agency;
    }

    let AV;
    let CFA;
    let SES;

    if (this.props.userAgencyInfo) {
      if (this.props.userAgencyInfo[0]) {
        if (this.props.userAgencyInfo[0].agency === "AV") {
          AV = this.props.userAgencyInfo[0];
        } else if (this.props.userAgencyInfo[0].agency === "CFA") {
          CFA = this.props.userAgencyInfo[0];
        } else if (this.props.userAgencyInfo[0].agency === "SES") {
          SES = this.props.userAgencyInfo[0];
        }
      }
      if (this.props.userAgencyInfo[1]) {
        if (this.props.userAgencyInfo[1].agency === "AV") {
          AV = this.props.userAgencyInfo[1];
        } else if (this.props.userAgencyInfo[1].agency === "CFA") {
          CFA = this.props.userAgencyInfo[1];
        } else if (this.props.userAgencyInfo[1].agency === "SES") {
          SES = this.props.userAgencyInfo[1];
        }
      }
      if (this.props.userAgencyInfo[2]) {
        if (this.props.userAgencyInfo[2].agency === "AV") {
          AV = this.props.userAgencyInfo[2];
        } else if (this.props.userAgencyInfo[2].agency === "CFA") {
          CFA = this.props.userAgencyInfo[2];
        } else if (this.props.userAgencyInfo[2].agency === "SES") {
          SES = this.props.userAgencyInfo[2];
        }
      }
      if (this.props.userAgencyInfo[3]) {
        if (this.props.userAgencyInfo[3].agency === "AV") {
          AV = this.props.userAgencyInfo[3];
        } else if (this.props.userAgencyInfo[3].agency === "CFA") {
          CFA = this.props.userAgencyInfo[3];
        } else if (this.props.userAgencyInfo[3].agency === "SES") {
          SES = this.props.userAgencyInfo[3];
        }
      }
    }

    if (agency && AV && agency === AV.agency) {
      this.setState({
        eventSettings: AV,
      });
    } else if (agency && CFA && agency === CFA.agency) {
      this.setState({
        eventSettings: CFA,
      });
    } else if (agency && SES && agency === SES.agency) {
      this.setState({
        eventSettings: SES,
      });
    } else {
      this.setState({
        eventSettings: null,
      });
    }
  };

  /**
   * This function will redirect the user to the event details page
   */
  redirectToEvent = (currentEvent) => {
    let isMobileApp = window.cordova;
    // query string contain information about the event to display before the event API loads
    if (isMobileApp) {
      this.props.history.push({
        pathname: "/eventdetails/" + currentEvent.id,
        search:
          "eventStatus=" +
          currentEvent.eventStatus +
          "&agency=" +
          currentEvent.messages[0].agency +
          "&cADEventId=" +
          currentEvent.messages[0].cADEventId +
          "&messageInputTimeStamp=" +
          currentEvent.messages[0].messageInputTimeStamp +
          "&messageType=" +
          currentEvent.messages[0].messageType +
          "&pagingName=" +
          currentEvent.messages[0].paging.name +
          "&messageText=" +
          currentEvent.messages[0].messageText,
      });
    } else {
      this.props.history.push({
        pathname: "/eventdetails/" + currentEvent.id,
        search:
          "eventStatus=" +
          currentEvent.eventStatus +
          "&messageType=" +
          currentEvent.messages[0].messageType,
      });
    }
  };

  // Gets all current user settings from the backend
  getUserSettings = () => {
    this.Requests.callAPI(this.Requests.getUserSettings).then((data) => {
      if (data && data.status && data.status === 200) {
        this.setState({
          userSettings: data.data,
        });
      } else {
        // Check if this is required
        let ErrorMessage =
          copy.dashboard.userInfoAPIErrorMessage +
          ` (Error #${copy.errorCodes.userInfoSettingsAPIErrorMessage})`;
        // if (data && data.data && data.data.SASMessageClient) {
        //   ErrorMessage = data.data.SASMessageClient;
        // }
        this.setState({ errorMessage: ErrorMessage });
      }
    });
  };

  calculateUserSearchHeaderHeight = () => {
    if (this.pagerHeaderHeight) {
      let PagerHeaderHeight = this.pagerHeaderHeight.clientHeight;
      this.setState({
        pagerHeaderHeight: PagerHeaderHeight,
      });
    } else {
      return;
    }
  };

  agencyLogo(agency) {
    switch (agency) {
      case "AV": // 0
        return <img src={AV} alt="Ambulance Victoria logo" />;
      case "CFA": // 1
        return <img src={CFA} alt="CFA logo" />;
      case "SES": // 2
        return <img src={SES} alt="SES logo" />;
      default:
        return <img src={EMV} alt="EMV logo" />;
    }
  }

  handleFullscreenMap = (state) => {
    this.setState({
      fullscreenMap: state,
    });
    return this.state.fullscreenMap;
  };

  handleCloseLocation = (state) => {
    this.setState({ close: state });
    return state;
  };

  render() {
    if (this.state.event) {
      // This data is feed into the attendance map (attendance list second tab)
      let mapData = {
        unitLocation:
          this.state.event.paging && this.state.event.paging.GeoLocation
            ? this.state.event.paging.GeoLocation
            : null,
        eventLocation: this.state.event.location
          ? this.state.event.location
          : null,
        attending:
          this.state.event.userAttendanceOtherUsersByAttendanceStatus &&
          this.state.event.userAttendanceOtherUsersByAttendanceStatus.attending
            ? this.state.event.userAttendanceOtherUsersByAttendanceStatus
                .attending
            : null,
        agency:
          this.state.event.paging && this.state.event.paging.agency
            ? this.state.event.paging.agency
            : null,
        eventID: this.state.event.id ? this.state.event.id : null,
      };

      let mapBtnLabel = this.props.isMapExpanded
        ? "Collapse map"
        : "Expand map";

      let mapBtnIcon = this.props.isMapExpanded
        ? "icon_mapColapse"
        : "icon_mapExpand";

      let eventLockedState = classNames(
        styles.eventLockedState,
        this.props.className,
        {
          [styles.locked]: this.props.eventIsLocked,
        }
      );

      let AlertTypeStyle;
      let AlertTypeText;

      if (
        this.state.event.messages[0] &&
        this.state.event.messages[0].messageType
      ) {
        if (this.state.event.messages[0].messageType === "NonEmergency") {
          AlertTypeStyle = styles.nonEmergency;
          AlertTypeText = "NON EMERGENCY";
        } else if (this.state.event.messages[0].messageType === "Emergency") {
          AlertTypeStyle = styles.emergency;
          AlertTypeText = "EMERGENCY";
        } else if (this.state.event.messages[0].messageType === "Admin") {
          AlertTypeStyle = styles.admin;
          AlertTypeText = "ADMIN";
        }
      }

      if (this.state.isLoading) {
        return (
          <div className={styles.loadingContainer}>
            <Loader />
          </div>
        );
      } else {
        return (
          <Wrapper>
            <div
              className={[
                styles.activeEventWrapper,
                eventLockedState,
                AlertTypeStyle,
                this.props.isMapExpanded && styles.expandedMap,
                "tileBg",
              ].join(" ")}>
              {this.props.eventIsLocked ? (
                <div
                  ref={(pagerHeaderHeight) => {
                    this.pagerHeaderHeight = pagerHeaderHeight;
                  }}
                  className={
                    styles.pagerHeader +
                    " " +
                    AlertTypeStyle +
                    " " +
                    (this.state.event &&
                      this.state.event.eventStatus === "Closed" &&
                      styles.ended)
                    // closed (1) event status
                  }>
                  <div className={styles.top}>
                    <div className={styles.agencyLogo}>
                      {this.agencyLogo(
                        this.state.event &&
                          this.state.event.messages[0] &&
                          this.state.event.messages[0].agency
                      )}
                    </div>
                    <div className={styles.alertType}>
                      <span className={AlertTypeStyle}>{AlertTypeText}</span>
                    </div>
                    <div className={styles.timer}>
                      {this.state.event &&
                      this.state.event.eventStatus === "Open" ? (
                        <Timer
                          initialTimestamp={this.state.event.messages}
                          dashboardHeader={true}
                          eventStatus={this.state.event.eventStatus}
                          pagerMessage={true}
                          showMobileView={true}
                        />
                      ) : (
                        <div className={styles.inactiveIcon}>
                          <span className="grey7 txt4">Inactive</span>
                        </div>
                      )}
                    </div>
                    <div className={styles.timeStamp}>
                      <span className={styles.time}>
                        {format(
                          new Date(
                            this.state.event &&
                              this.state.event.messages[0] &&
                              this.state.event.messages[0].messageInputTimeStamp
                          ),
                          "HH:mm:ss dd-MM-yyyy"
                        )}
                      </span>
                    </div>

                    <div className={styles.controls}>
                      <Button
                        label="Return to latest events"
                        content="Return to latest events"
                        variant="btn_outline"
                        styles="btn_secondary"
                        buttonClick={this.props.removeLockedEvent}
                      />
                    </div>
                  </div>
                  <div className={styles.bottom}>
                    <div className={styles.pagerGroup + " tileBg"}>
                      <span>
                        {this.state.event &&
                          this.state.event.messages[0] &&
                          this.state.event.messages[0].paging.name}
                      </span>
                    </div>
                  </div>
                  <div className={styles.controlsAndDetails}>
                    <div className={styles.buttons}>
                      <Button
                        label={mapBtnLabel}
                        content={mapBtnLabel}
                        variant="btn_outline"
                        styles="btn_secondary"
                        icon={mapBtnIcon}
                        buttonClick={this.props.expandMap}
                      />
                      <Button
                        label="View Event Details"
                        content="View Event Details"
                        variant="btn_outline"
                        styles="btn_secondary"
                        buttonClick={this.redirectToEvent}
                        paramVal={this.state.event}
                      />
                    </div>
                    <div className={styles.pagerDetails}>
                      <span>
                        {this.state.event &&
                          this.state.event.messages[0] &&
                          this.state.event.messages[0].messageText}
                      </span>
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  ref={(pagerHeaderHeight) => {
                    this.pagerHeaderHeight = pagerHeaderHeight;
                  }}
                  className={styles.activeEventHeader}>
                  <div className={styles.buttons}>
                    <Button
                      label={mapBtnLabel}
                      content={mapBtnLabel}
                      variant="btn_outline"
                      styles="btn_secondary"
                      icon={mapBtnIcon}
                      buttonClick={this.props.expandMap}
                    />
                    <Button
                      label="View Event Details"
                      content="View Event Details"
                      variant="btn_outline"
                      styles="btn_secondary"
                      buttonClick={this.redirectToEvent}
                      paramVal={this.state.event}
                    />
                  </div>
                </div>
              )}

              <div
                style={{
                  height: `calc(100% - ${this.state.pagerHeaderHeight}px)`,
                }}
                className={styles.eventDetails}>
                <div className={styles.eventDetailsMap}>
                  <DefaultMap
                    key={this.state.event.id}
                    showAttendeeMarkers={true}
                    mapData={mapData}
                    height={"dashboard"}
                    userSettings={this.state.userSettings}
                    FullscreenMap={this.handleFullscreenMap}
                    isFullscreen={this.state.fullscreenMap}
                  />
                </div>
                {/* Attendance information is not displayed for AV. */}
                {this.state.eventSettings &&
                  this.state.eventSettings.eventAttendanceEnabled &&
                  this.state.event.paging.settings.attendingResponseNeeded && (
                    <div
                      className={[
                        styles.eventDetailsAttendance,
                        eventLockedState,
                      ].join(" ")}>
                      <AttendanceList
                        key={this.state.event.id}
                        mapData={mapData}
                        userSettings={this.state.userSettings}
                        eventStatus={this.state.event.eventStatus}
                        agency={this.state.event.paging.agency}
                        attendanceList={
                          this.state.event
                            .userAttendanceOtherUsersByAttendanceStatus
                        } // This is the data used to create the List
                        // LocationSelection={() =>
                        //   this.handleLocationSelection(this.props.event.userAttendanceCurrentUser.navigateTo)
                        // }
                        FullscreenMap={this.handleFullscreenMap}
                        closeFunction={this.handleCloseLocation}
                        eventId={this.state.event.id}
                      />
                    </div>
                  )}
              </div>
            </div>
          </Wrapper>
        );
      }
      // Situation where API calls fail on initial load
    } else {
      if (
        this.state.errorMessage !== null || // If an error with API call for the active event occurred
        this.props.allActiveEvents === false // If no events could be initially loaded
      ) {
        // API failed to fetch event
        return (
          <h4>
            <span className="noMessages">
              {copy.dashboard.getEventDetailsAPIErrorMessage +
                ` (Error #${copy.errorCodes.getEventDetailsAPIErrorMessage})`}
              <div>
                <Button
                  label="Refresh"
                  content={copy.global.btnRefresh}
                  variant="btn_solid"
                  styles="greyBg"
                  icon="icon_refresh"
                  buttonClick={this.handleRefresh}
                />
              </div>
            </span>
          </h4>
        );
      } else {
        // Loading state
        return (
          <div className={styles.loadingContainer}>
            <Loader />
          </div>
        );
      }
    }
  }
}
export default withRouter(ActiveEventDashboardView);
