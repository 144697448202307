import React from "react";
import "react-datepicker/dist/react-datepicker.css";

import Radio from "../UI/Button/Radio";

import Checkbox from "../UI/Button/Checkbox";

// import { Dropdown } from "semantic-ui-react";
import { FormControl, Select, MenuItem } from "@material-ui/core";

import moment from "moment";
import { format, parse } from "date-fns";

// import times from "../../../src/api/sampleData/times";
import copy from "../../assets/copy/copy.json";

import "@dmuy/toast/dist/mdtoast.css";
import mdtoast from "@dmuy/toast";
import { TimeInput, DateInput, Day, Week } from "@idui/react-date-components";
const defaultTitle = "Add new availability";
const defaultPagingName = "";
let currentAvailabilityTypes = [];
let currentEventType = [];
let currentPagingName = [];
export default class SchedulerModal extends React.Component {
  constructor(props) {
    super(props);

    let dropdownValue = [];
    if (this.props.pagingGroupsOwned) {
      this.props.userAgencies.forEach((agency) => {
        if (this.props.pagingGroupsOwned[agency].length > 0) {
          this.props.pagingGroupsOwned[agency].forEach((group) => {
            if (group.availabilityenabled) {
              dropdownValue.push(group);
            }
          });
        }
      });
    }

    this.state = {
      nearest30Min: "",
      id: "",
      eventID: "",
      date: "",
      text: defaultTitle,
      dropdownValue: dropdownValue,
      pagingName: defaultPagingName,
      pagingNumber: null,
      selectedPagingGroup: "All",
      pagingGroupHasEmergencyLevels: true,
      dayOfWeek: [],
      dateFrom: "",
      dateTo: "",
      recurring: false,
      available: true,
      prevAvailability: true,
      availabilityTypes: [],
      eventType: [],
      timeFrom: "",
      timeTo: "",
      timeFromDate: null,
      timeToDate: null,
      nearest30MinDate: null,
      next30MinDate: null,

      // endRecurringDate: "",
      currentTimeBlockDate: "",
      eventStartDate: "",
      allDay: false,
    };

    this.updateTime = this.updateTime.bind(this);
    this.updatePagingGroup = this.updatePagingGroup.bind(this);
  }

  componentDidMount() {
    // * Rounded up nearest 30 minute interval
    const timeNow = moment(new Date());
    const remainder = 30 - (timeNow.minute() % 30);

    const nearest30MinDate = moment(timeNow)
      .add(remainder + 1, "minutes")
      .toDate();

    const next30MinDate = moment(nearest30MinDate).add(30, "minutes").toDate();

    const nearest30Min = moment(timeNow)
      .add(remainder, "minutes")
      .format("HH:mm");

    this.setState({
      nearest30Min: nearest30Min,
      nearest30MinDate: nearest30MinDate,
      next30MinDate: next30MinDate,
    });

    if (this.props.canAccessEmergencyLevels) {
      this.setState({
        eventType: [],
      });
    }
  }

  /**
   * Displays a toaster on the lower left of the screen
   * @param {String} message Message to be displayed in the toaster
   * @param {String} type Type of toaster (info, error, warning, success)
   * @param {Number} time Duration of the toastie
   */
  toastie = (message = "Hello world!", type = "info", time = 5000) => {
    mdtoast(message, {
      duration: time,
      type: type,
      interaction: false,
    });
  };

  getBrowserClient() {
    // * gets the browser name as the device name
    let Sys = {};
    let ua = navigator.userAgent.toLowerCase();
    var s = ua.match(/msie ([\d.]+)/)
      ? // eslint-disable-next-line no-use-before-define
        (Sys.ie = s[1])
      : // eslint-disable-next-line
      (s = ua.match(/firefox\/([\d.]+)/))
      ? // eslint-disable-next-line no-use-before-define
        (Sys.firefox = s[1])
      : // eslint-disable-next-line
      (s = ua.match(/chrome\/([\d.]+)/))
      ? // eslint-disable-next-line no-use-before-define
        (Sys.chrome = s[1])
      : // eslint-disable-next-line
      (s = ua.match(/opera.([\d.]+)/))
      ? // eslint-disable-next-line no-use-before-define
        (Sys.opera = s[1])
      : // eslint-disable-next-line
      (s = ua.match(/version\/([\d.]+).*safari/))
      ? // eslint-disable-next-line no-use-before-define
        (Sys.safari = s[1])
      : 0;

    let browserVersion = null;
    if (Sys.ie) {
      browserVersion = "IE";
    } else if (Sys.firefox) {
      browserVersion = "Firefox";
    } else if (Sys.chrome) {
      browserVersion = "Chrome";
    } else if (Sys.opera) {
      browserVersion = "Opera";
    } else if (Sys.safari) {
      browserVersion = "Safari";
    }
    return browserVersion;
  }

  componentDidUpdate(prevProps) {
    const prevPropsString = JSON.stringify(prevProps);
    const thisPropsString = JSON.stringify(this.props);
    const eventData = this.props.eventData;
    let dateFormat = null;
    dateFormat = "dd-MM-yyyy";
    if (JSON.stringify(eventData) === "{}") {
      return false;
    }

    if (prevPropsString !== thisPropsString) {
      // * Set state from properties
      this.setState({
        id: eventData.id === undefined ? "" : eventData.id,
        eventID: eventData.eventID === undefined ? "" : eventData.eventID,
        date:
          eventData.date === undefined
            ? ""
            : format(new Date(eventData.date), dateFormat),
        text:
          eventData.pagingName === undefined
            ? defaultTitle
            : eventData.pagingName,
        pagingName:
          eventData.pagingName === undefined
            ? defaultPagingName
            : eventData.pagingName,
        pagingNumber:
          eventData.pagingNumber === undefined ? null : eventData.pagingNumber,
        pagingGroup:
          eventData.pagingGroup === undefined ? null : eventData.pagingGroup,
        dayOfWeek: eventData.dayOfWeek === undefined ? [] : eventData.dayOfWeek,
        dateFrom:
          eventData.event_start_date === undefined ||
          eventData.event_start_date === null
            ? format(eventData.start_date, dateFormat)
            : format(parse("", "", new Date(eventData.start_date)), dateFormat),
        dateTo:
          eventData.effective_end_date === undefined ||
          eventData.effective_end_date === null
            ? format(eventData.end_date, dateFormat)
            : format(moment(eventData.effective_end_date).toDate(), dateFormat),
        recurring:
          eventData.dayOfWeek === undefined || eventData.dayOfWeek.length === 0
            ? false
            : true,
        available:
          eventData.available === undefined || eventData.available
            ? true
            : false,
        prevAvailability:
          eventData.available === undefined || eventData.available
            ? true
            : false,
        availabilityTypes:
          eventData.availabilityTypes === undefined
            ? []
            : eventData.availabilityTypes,
        eventType: this.props.canAccessEmergencyLevels
          ? eventData.eventType === undefined &&
            this.props.canAccessEmergencyLevels
            ? []
            : eventData.eventType
          : [],
        timeFrom: format(eventData.start_date, "HH:mm"),
        timeTo:
          format(eventData.end_date, "HH:mm") === "23:59"
            ? "24:00"
            : format(eventData.end_date, "HH:mm"),
        timeFromDate: eventData.start_date,
        timeToDate: eventData.end_date,
        // endRecurringDate:
        //   eventData.dateEffectiveTo === undefined
        //     ? format(eventData.end_date, dateFormat)
        //     : format(eventData.dateEffectiveTo, dateFormat),
        currentTimeBlockDate: format(eventData.end_date, dateFormat),
        eventStartDate:
          eventData.event_start_date === undefined ||
          eventData.event_start_date === null
            ? format(eventData.start_date, dateFormat)
            : format(parse("", "", new Date(eventData.start_date)), dateFormat),
        dateFormat,
        allDay:
          format(eventData.start_date, "HH:mm") === "00:00" &&
          format(eventData.end_date, "HH:mm") === "23:59",
      });

      if (eventData.type === "SpanOverDates") {
        let availableSpanOverDates = [];
        let unavailableSpanOverDates = [];

        // * Get all timings from the available event
        if (eventData.available) {
          this.props.allData.dateAvailable.forEach((availability) => {
            const _timeAvailable = availability.timeAvailable;

            _timeAvailable.forEach((timeAvailable) => {
              if (timeAvailable.availableId === eventData.eventID) {
                availableSpanOverDates.push({
                  date: availability.date,
                  startTime: timeAvailable.timeStart,
                  endTime: timeAvailable.timeEnd,
                });
              }
            });
          });

          // * Assign start and end times to the event
          availableSpanOverDates.forEach((event, eventIndex) => {
            // * Assign Start Date
            if (eventIndex === 0) {
              this.setState({
                dateFrom: format(new Date(event.date), dateFormat),
                timeFrom: event.startTime.substring(0, 5),
              });
            }

            // * Assign End Date
            if (eventIndex === availableSpanOverDates.length - 1) {
              this.setState({
                dateTo: format(new Date(event.date), dateFormat),
                timeTo: event.endTime.substring(0, 5),
              });
            }
          });
        }

        // * Get all timings from the unavailable event
        if (!eventData.available && this.props.allData.dateUnavailable) {
          this.props.allData.dateUnavailable.forEach((unavailability) => {
            const _timeUnvailable = unavailability.timeUnavailable;

            _timeUnvailable.forEach((timeUnavailable) => {
              if (timeUnavailable.availableId === eventData.eventID) {
                unavailableSpanOverDates.push({
                  date: unavailability.date,
                  startTime: timeUnavailable.timeStart,
                  endTime: timeUnavailable.timeEnd,
                });
              }
            });
          });

          // * Assign start and end times to the event
          unavailableSpanOverDates.forEach((event, eventIndex) => {
            // * Assign Start Date
            if (eventIndex === 0) {
              this.setState({
                dateFrom: format(new Date(event.date), dateFormat),
                timeFrom: event.startTime.substring(0, 5),
              });
            }

            // * Assign End Date
            if (eventIndex === unavailableSpanOverDates.length - 1) {
              this.setState({
                dateTo: format(new Date(event.date), dateFormat),
                timeTo: event.endTime.substring(0, 5),
              });
            }
          });
        }
      }
    }
  }

  updateAvailability(event) {
    const _this = event.currentTarget;
    const value = _this.value;

    this.setState({
      available: value === "true" ? true : false,
    });

    if (this.state.available) {
      this.setState({
        availabilityTypes: currentAvailabilityTypes,
        eventType: currentEventType,
        pagingName: currentPagingName,
      });
    } else {
      currentAvailabilityTypes = this.state.availabilityTypes;
      currentEventType = this.state.eventType;
      currentPagingName = this.state.pagingName;

      this.setState({
        availabilityTypes: [],
        eventType: [],
        pagingName: ["Unavailable"],
      });
    }
  }

  /*
  updateTime(_this) {
    const className = _this.className;
    const value = _this.value;

    if (className.indexOf("-to") > -1) {
      this.setState({
        timeTo: value,
      });
    } else if (className.indexOf("-from") > -1) {
      this.setState({
        timeFrom: value,
      });
    }
  }
  */

  /**
   * Updates the to / from time.
   * @param {Date} time Date object containing the selected time
   * @param {String} type specifies what time is updated ("to" / "from")
   */
  updateTime(time, type) {
    if (type === "from") {
      // if (time > this.state.timeToDate) {
      //   this.setState({
      //     timeFrom: time,
      //     timeFromDate: time,
      //     timeTo: time,
      //     timeToDate: time,
      //   });
      // } else {
      this.setState({
        timeFrom: time,
        timeFromDate: time,
      });
      // }
    } else if (type === "to") {
      // if (time < this.state.timeFromDate) {
      //   this.setState({
      //     timeFrom: time,
      //     timeFromDate: time,
      //     timeTo: time,
      //     timeToDate: time,
      //   });
      // } else {
      this.setState({
        timeTo: time,
        timeToDate: time,
      });
      // }
    }
  }

  updateDate = (event, field) => {
    if (this.state.allDay && !this.state.recurring) {
      this.setState({
        dateFrom: event,
        dateTo: event,
        eventStartDate: event,
      });
    } else {
      if (field === "from") {
        if (
          moment(event, "DD-MM-YYYY").toDate() >
          moment(this.state.dateTo, "DD-MM-YYYY").toDate()
        ) {
          this.setState({
            dateFrom: event,
            dateTo: event,
            eventStartDate: event,
          });
        } else {
          this.setState({
            dateFrom: event,
            eventStartDate: event,
          });
        }
      } else if (field === "to") {
        if (
          moment(event, "DD-MM-YYYY").toDate() <
          moment(this.state.dateFrom, "DD-MM-YYYY").toDate()
        ) {
          this.setState({
            dateFrom: event,
            dateTo: event,
            eventStartDate: event,
          });
        } else {
          this.setState({
            dateTo: event,
            eventStartDate: event,
          });
        }
      }
    }
  };

  setAllDay = () => {
    if (!this.state.allDay) {
      const midnight = moment(new Date()).startOf("day").toDate();
      this.setState({
        timeFrom: "00:00",
        timeFromDate: midnight,
        timeTo: "24:00",
        timeToDate: midnight,
        dateFrom: this.state.eventStartDate,
        dateTo: this.state.eventStartDate,
        allDay: !this.state.allDay,
      });
    } else {
      this.setState({
        allDay: !this.state.allDay,
      });
    }
  };

  updateRecurring(event) {
    const _this = event.currentTarget;
    const value = _this.value;

    this.setState({
      recurring: value === "true" ? true : false,
      dayOfWeek: [],
    });
  }

  updateDaysOfWeek(event) {
    const _this = event.currentTarget;
    const value = _this.value;
    const isChecked = _this.checked;
    let dayOfWeek = this.state.dayOfWeek;

    if (isChecked) {
      this.setState({
        dayOfWeek: dayOfWeek.concat(value),
      });
    } else {
      let arrayIndex = dayOfWeek.indexOf(value);

      if (arrayIndex > -1) {
        dayOfWeek.splice(arrayIndex, 1);
      }

      this.setState({
        dayOfWeek: dayOfWeek,
      });
    }
  }

  updateAvailabilityType(event) {
    const _this = event.currentTarget;
    const value = _this.value;
    const isChecked = _this.checked;
    let availabilityTypes = this.state.availabilityTypes;

    if (isChecked) {
      if (value === "All") {
        this.setState({
          availabilityTypes: [
            "Local",
            "RegionShortHaul",
            "InterstateDeployment",
            "IMT",
            "StateLongHaul",
          ],
        });
      } else {
        this.setState({
          availabilityTypes: availabilityTypes.concat(value),
        });
      }
    } else {
      if (value === "All") {
        this.setState({
          availabilityTypes: [],
        });
      } else {
        let arrayIndex = availabilityTypes.indexOf(value);

        if (arrayIndex > -1) {
          availabilityTypes.splice(arrayIndex, 1);
        }

        this.setState({
          availabilityTypes: availabilityTypes,
        });
      }
    }
  }

  updateEventType(event) {
    const _this = event.currentTarget;
    const value = _this.value;
    const isChecked = _this.checked;
    let eventType = this.state.eventType;

    if (isChecked) {
      if (value === "All") {
        this.setState({
          eventType: ["Emergency", "Non-Emergency", "Admin"],
        });
      } else {
        this.setState({
          eventType: eventType.concat(value),
        });
      }
    } else {
      if (value === "All") {
        this.setState({
          eventType: [],
        });
      } else {
        let arrayIndex = eventType.indexOf(value);

        if (arrayIndex > -1) {
          eventType.splice(arrayIndex, 1);
        }

        this.setState({
          eventType: eventType,
        });
      }
    }
  }

  updateSelectedPagingGroup(event) {
    const _this = event.currentTarget;
    const value = _this.value;

    this.setState({
      selectedPagingGroup: value,
    });

    if (value === "All") {
      this.setState({
        pagingNumber: null,
        pagingGroupHasEmergencyLevels: true,
      });
    }
  }

  /*
  updatePagingGroup(event, value) {
    let pagingName = "All paging groups";
    const dropdownValue = this.state.dropdownValue;

    for (let index = 0; index < dropdownValue.length; index++) {
      const dropdown = dropdownValue[index];

      if (dropdown.key === value.value) {
        pagingName = dropdown.text;

        // hide emergency levels if the selected group is not from SES
        if (dropdown.agency !== "SES") {
          this.setState({
            eventType: [],
            pagingGroupHasEmergencyLevels: false,
          });
        } else {
          this.setState({
            eventType: ["Emergency", "Non-Emergency", "Admin"],
            pagingGroupHasEmergencyLevels: true,
          });
        }
        break;
      }
    }

    this.setState({
      pagingName: pagingName,
      pagingNumber: value.value,
    });
  }
  */
  updatePagingGroup(event) {
    let value = event.target.value;
    let pagingName = "All paging groups";
    const dropdownValue = this.state.dropdownValue;

    for (let index = 0; index < dropdownValue.length; index++) {
      const dropdown = dropdownValue[index];

      if (dropdown.key === value) {
        pagingName = dropdown.text;

        // hide emergency levels if the selected group is not from SES
        if (dropdown.agency !== "SES") {
          this.setState({
            eventType: [],
            pagingGroupHasEmergencyLevels: false,
          });
        } else {
          this.setState({
            eventType: ["Emergency", "Non-Emergency", "Admin"],
            pagingGroupHasEmergencyLevels: true,
          });
        }
        break;
      }
    }

    this.setState({
      pagingName: pagingName,
      pagingNumber: value,
    });
  }

  onSaveClick(event) {
    const {
      available,
      availabilityTypes,
      eventType,
      dateFrom,
      dateTo,
      timeFrom,
      timeTo,
    } = event;

    // * Check if the dates are in the past
    // allow users to edit time block that are 30 mins in the past
    let today = moment(new Date()).subtract(30, "minutes").toDate();

    let startDateTime = new moment(dateFrom, "DD-MM-YYYY").toDate();
    startDateTime.setHours(
      parseInt(timeFrom.substring(0, 2)),
      parseInt(timeFrom.substring(3, 5))
    );

    if (startDateTime.getTime() < today.getTime()) {
      this.toastie(copy.availability.validation.datePast, "error");
      return false;
    }

    // * Check if the times are the same
    if (timeFrom === timeTo && dateFrom === dateTo) {
      this.toastie(copy.availability.validation.sameStartEndTimes, "error");
      return false;
    }

    // * check if the start date-time is after the end date-time
    let startDate = moment(dateFrom, "DD-MM-YYYY").toDate();
    let endDate = moment(dateTo, "DD-MM-YYYY").toDate();

    if (startDate > endDate) {
      this.toastie(copy.availability.validation.startAfterEndDate, "error");
      return false;
    }
    // * check if start time is after end time for the same date
    else if (
      startDate.getTime() === endDate.getTime() ||
      this.state.recurring
    ) {
      let startTimeAfterEndTime = false;

      if (
        parseInt(timeFrom.substring(0, 2)) > parseInt(timeTo.substring(0, 2))
      ) {
        startTimeAfterEndTime = true;
      } else if (
        parseInt(timeFrom.substring(0, 2)) ===
          parseInt(timeTo.substring(0, 2)) &&
        parseInt(timeFrom.substring(3, 5)) > parseInt(timeTo.substring(3, 5))
      ) {
        startTimeAfterEndTime = true;
      }

      if (startTimeAfterEndTime) {
        if (this.state.recurring) {
          this.toastie(
            copy.availability.validation.recurringOvernight1 +
              this.state.timeFrom +
              copy.availability.validation.recurringOvernight2 +
              this.state.timeTo +
              copy.availability.validation.recurringOvernight3,
            "error",
            10000
          );
        } else {
          this.toastie(copy.availability.validation.startAfterEndTime, "error");
        }
        return false;
      }
    }

    // * Check if Weekly is checked and at least one (1) day is selected
    if (this.state.recurring && this.state.dayOfWeek.length === 0) {
      this.toastie(copy.availability.validation.noDays, "error");
      return false;
    }

    // * Check if event is available and has availability types chekced
    if (available && availabilityTypes.length === 0) {
      this.toastie(copy.availability.validation.noTypes, "error");
      return false;
    }

    // * Check if event is available and has a level selected
    if (
      available &&
      availabilityTypes.indexOf("Local") > -1 &&
      eventType.length === 0 &&
      this.props.canAccessEmergencyLevels &&
      this.state.pagingGroupHasEmergencyLevels
    ) {
      this.toastie(copy.availability.validation.noLevels, "error");
      return false;
    }
    this.props.onSaveClick(event);
  }

  dateInputWeekRender = () => {
    return (weekDays) => (
      <Week>
        {["Su", "M", "Tu", "W", "Th", "F", "Sa"].map((item) => (
          <Day key={item}>{item}</Day>
        ))}
      </Week>
    );
  };

  render() {
    const {
      onDeleteClick,
      onDeleteSingleOccurrenceClick,
      onCloseClick,
      isEventEdit,
    } = this.props;

    let threeMonthsAdvance = new Date();
    threeMonthsAdvance.setMonth(threeMonthsAdvance.getMonth() + 3);

    return (
      <div id="schedulerModal" className="schedulerModal">
        <div className="schedulerModal__header Sub-Section-titles">
          <h3>{this.state.text}</h3>
          <button
            className="schedulerModal__btn -close"
            aria-label="Add availability"
            onClick={() => onCloseClick(this.state.id)}></button>
        </div>

        <div className="schedulerModal__body">
          <div className="schedulerModal__row">
            <div className="schedulerModal__item">
              <div className="schedulerModal__label">Set as</div>
              <ul className="-horizontal">
                <li>
                  <Radio
                    styles="schedulerModal__radio"
                    name="availability"
                    setChecked={this.state.available ? "checked" : ""}
                    label="Available"
                    onChange={(event) => {
                      this.updateAvailability(event);
                    }}
                    value="true"
                  />
                </li>
                <li>
                  <Radio
                    styles="schedulerModal__radio"
                    name="availability"
                    setChecked={!this.state.available ? "checked" : ""}
                    label="Unavailable"
                    onChange={(event) => {
                      this.updateAvailability(event);
                    }}
                    value="false"
                  />
                </li>
              </ul>
            </div>
          </div>

          <div className="schedulerModal__row">
            <div className="schedulerModal__item">
              <div className="schedulerModal__label">Recurring</div>
              <ul className="-horizontal">
                <li>
                  <Radio
                    styles="schedulerModal__radio"
                    name="recurring"
                    setChecked={!this.state.recurring ? "checked" : ""}
                    label="None"
                    value="false"
                    onChange={(event) => {
                      this.updateRecurring(event);
                    }}
                  />
                </li>
                <li>
                  <Radio
                    styles="schedulerModal__radio"
                    name="recurring"
                    setChecked={this.state.recurring ? "checked" : ""}
                    label="Weekly"
                    value="true"
                    onChange={(event) => {
                      this.updateRecurring(event);
                    }}
                  />
                </li>
              </ul>
            </div>
          </div>

          {this.state.recurring ? (
            <div className="schedulerModal__row">
              <div className="schedulerModal__item -recurring">
                <div className="schedulerModal__item">
                  <div className="schedulerModal__label" />
                  <ul className="-horizontal">
                    <li>
                      <Checkbox
                        name="all day"
                        label="All Day"
                        value="All Day"
                        checked={this.state.allDay ? true : false}
                        onChange={(event) => {
                          this.setAllDay();
                        }}
                      />
                    </li>
                  </ul>
                </div>
                <div className="schedulerModal__item">
                  {this.props.isMobile ? (
                    <ul className="-horizontal">
                      <li>
                        <div className="schedulerModal__label">From</div>
                        <ul className="-horizontal">
                          <li>
                            <TimeInput
                              className="dropdownTime -from"
                              value={
                                this.state.timeFrom
                                  ? this.state.timeFrom
                                  : this.state.nearest30Min
                              }
                              onChange={(date) => this.updateTime(date, "from")}
                              disabled={this.state.allDay}
                              isClearable={false}
                              timeSlot={30}
                              defaultSelectedValue={
                                this.state.timeFrom
                                  ? this.state.timeFrom
                                  : this.state.nearest30Min
                              }
                              readOnly={this.props.isMobile}
                              required
                              colors={{
                                time: {
                                  active: {
                                    background: "#007454",
                                    color: "#ffffff",
                                  },
                                  default: {},
                                  disabled: {},
                                },
                                input: {
                                  default: {
                                    border: "#B4B4B4",
                                    color: "#313131",
                                    placeholder: "#B4B4B4",
                                    tag: "#007454",
                                  },
                                  disabled: {
                                    border: "#EFEFEF",
                                    color: "#EFEFEF",
                                    tag: "#B4B4B4",
                                  },
                                  error: {
                                    border: "#C02908",
                                    color: "#C02908",
                                    background: "#FDDCDC",
                                    tag: "#C02908",
                                  },
                                  focused: {
                                    border: "#007454",
                                    tag: "#007454",
                                  },
                                },
                              }}
                            />
                          </li>
                        </ul>
                      </li>
                      <li>
                        <div className="schedulerModal__label">Until</div>
                        <ul className="-horizontal">
                          <li>
                            <TimeInput
                              className="dropdownTime -to"
                              value={
                                this.state.timeTo
                                  ? this.state.timeTo
                                  : this.state.nearest30Min
                              }
                              onChange={(date) => this.updateTime(date, "to")}
                              disabled={this.state.allDay}
                              isClearable={false}
                              timeSlot={30}
                              defaultSelectedValue={
                                this.state.timeTo
                                  ? this.state.timeTo
                                  : this.state.nearest30Min
                              }
                              readOnly={this.props.isMobile}
                              required
                              colors={{
                                time: {
                                  active: {
                                    background: "#007454",
                                    color: "#ffffff",
                                  },
                                  default: {},
                                  disabled: {},
                                },
                                input: {
                                  default: {
                                    border: "#B4B4B4",
                                    color: "#313131",
                                    placeholder: "#B4B4B4",
                                    tag: "#007454",
                                  },
                                  disabled: {
                                    border: "#EFEFEF",
                                    color: "#EFEFEF",
                                    tag: "#B4B4B4",
                                  },
                                  error: {
                                    border: "#C02908",
                                    color: "#C02908",
                                    background: "#FDDCDC",
                                    tag: "#C02908",
                                  },
                                  focused: {
                                    border: "#007454",
                                    tag: "#007454",
                                  },
                                },
                              }}
                            />
                          </li>
                        </ul>
                      </li>
                    </ul>
                  ) : (
                    <div className="schedulerModal__label">From</div>
                  )}
                  {!this.props.isMobile && (
                    <ul className="-horizontal">
                      <li>
                        <TimeInput
                          className="dropdownTime -from"
                          value={
                            this.state.timeFrom
                              ? this.state.timeFrom
                              : this.state.nearest30Min
                          }
                          onChange={(date) => this.updateTime(date, "from")}
                          disabled={this.state.allDay}
                          isClearable={false}
                          timeSlot={30}
                          defaultSelectedValue={
                            this.state.timeFrom
                              ? this.state.timeFrom
                              : this.state.nearest30Min
                          }
                          readOnly={this.props.isMobile}
                          required
                          colors={{
                            time: {
                              active: {
                                background: "#007454",
                                color: "#ffffff",
                              },
                              default: {},
                              disabled: {},
                            },
                            input: {
                              default: {
                                border: "#B4B4B4",
                                color: "#313131",
                                placeholder: "#B4B4B4",
                                tag: "#007454",
                              },
                              disabled: {
                                border: "#EFEFEF",
                                color: "#EFEFEF",
                                tag: "#B4B4B4",
                              },
                              error: {
                                border: "#C02908",
                                color: "#C02908",
                                background: "#FDDCDC",
                                tag: "#C02908",
                              },
                              focused: { border: "#007454", tag: "#007454" },
                            },
                          }}
                        />
                      </li>
                      <li>
                        <div
                          className="schedulerModal__label"
                          style={{ width: "60px" }}>
                          Until
                        </div>
                      </li>
                      <li>
                        <TimeInput
                          className="dropdownTime -to"
                          value={
                            this.state.timeTo
                              ? this.state.timeTo
                              : this.state.nearest30Min
                          }
                          onChange={(date) => this.updateTime(date, "to")}
                          disabled={this.state.allDay}
                          isClearable={false}
                          timeSlot={30}
                          defaultSelectedValue={
                            this.state.timeTo
                              ? this.state.timeTo
                              : this.state.nearest30Min
                          }
                          readOnly={this.props.isMobile}
                          required
                          colors={{
                            time: {
                              active: {
                                background: "#007454",
                                color: "#ffffff",
                              },
                              default: {},
                              disabled: {},
                            },
                            input: {
                              default: {
                                border: "#B4B4B4",
                                color: "#313131",
                                placeholder: "#B4B4B4",
                                tag: "#007454",
                              },
                              disabled: {
                                border: "#EFEFEF",
                                color: "#EFEFEF",
                                tag: "#B4B4B4",
                              },
                              error: {
                                border: "#C02908",
                                color: "#C02908",
                                background: "#FDDCDC",
                                tag: "#C02908",
                              },
                              focused: { border: "#007454", tag: "#007454" },
                            },
                          }}
                        />
                      </li>
                    </ul>
                  )}
                </div>
                <div className="schedulerModal__item">
                  <div className="schedulerModal__label">Every</div>
                  <ul className="-days">
                    <li>
                      <Checkbox
                        name="day"
                        label="Monday"
                        value="Monday"
                        checked={
                          this.state.dayOfWeek.indexOf("Monday") > -1
                            ? "checked"
                            : ""
                        }
                        onChange={(event) => {
                          this.updateDaysOfWeek(event);
                        }}
                      />
                    </li>
                    <li>
                      <Checkbox
                        name="day"
                        label="Tuesday"
                        value="Tuesday"
                        checked={
                          this.state.dayOfWeek.indexOf("Tuesday") > -1
                            ? "checked"
                            : ""
                        }
                        onChange={(event) => {
                          this.updateDaysOfWeek(event);
                        }}
                      />
                    </li>
                    <li>
                      <Checkbox
                        name="day"
                        label="Wednesday"
                        value="Wednesday"
                        checked={
                          this.state.dayOfWeek.indexOf("Wednesday") > -1
                            ? "checked"
                            : ""
                        }
                        onChange={(event) => {
                          this.updateDaysOfWeek(event);
                        }}
                      />
                    </li>
                    <li>
                      <Checkbox
                        name="day"
                        label="Thursday"
                        value="Thursday"
                        checked={
                          this.state.dayOfWeek.indexOf("Thursday") > -1
                            ? "checked"
                            : ""
                        }
                        onChange={(event) => {
                          this.updateDaysOfWeek(event);
                        }}
                      />
                    </li>
                    <li>
                      <Checkbox
                        name="day"
                        label="Friday"
                        value="Friday"
                        checked={
                          this.state.dayOfWeek.indexOf("Friday") > -1
                            ? "checked"
                            : ""
                        }
                        onChange={(event) => {
                          this.updateDaysOfWeek(event);
                        }}
                      />
                    </li>
                    <li>
                      <Checkbox
                        name="day"
                        label="Saturday"
                        value="Saturday"
                        checked={
                          this.state.dayOfWeek.indexOf("Saturday") > -1
                            ? "checked"
                            : ""
                        }
                        onChange={(event) => {
                          this.updateDaysOfWeek(event);
                        }}
                      />
                    </li>
                    <li>
                      <Checkbox
                        name="day"
                        label="Sunday"
                        value="Sunday"
                        checked={
                          this.state.dayOfWeek.indexOf("Sunday") > -1
                            ? "checked"
                            : ""
                        }
                        onChange={(event) => {
                          this.updateDaysOfWeek(event);
                        }}
                      />
                    </li>
                  </ul>
                </div>

                <div className="schedulerModal__item">
                  <div className="schedulerModal__label">Starting on</div>
                  <ul className="-horizontal">
                    <li>
                      <DateInput
                        className="ui fluid multiple search selection dropdown dropdownDate -from"
                        isClearable={false}
                        onChange={(event) => {
                          this.updateDate(event, "from");
                        }}
                        renderWeek={this.dateInputWeekRender()}
                        outputFormat={"DD-MM-YYYY"}
                        value={this.state.dateFrom && this.state.dateFrom}
                        readOnly={this.props.isMobile}
                        required
                        colors={{
                          day: {
                            default: {},
                            active: { background: "#007454", color: "#ffffff" },
                            current: { color: "#007454" },
                            disabled: { color: "#efefef" },
                            hover: { background: "#007454", color: "#ffffff" },
                          },
                          input: {
                            default: {
                              border: "#B4B4B4",
                              color: "#313131",
                              placeholder: "#B4B4B4",
                              tag: "#007454",
                            },
                            disabled: {
                              border: "#EFEFEF",
                              color: "#EFEFEF",
                              tag: "#B4B4B4",
                            },
                            error: {
                              border: "#C02908",
                              color: "#C02908",
                              background: "#FDDCDC",
                              tag: "#C02908",
                            },
                            focused: { border: "#007454", tag: "#007454" },
                          },
                          controls: {
                            color: "#007454",
                            hoverColor: "#007454",
                            monthAndYear: "#007454",
                          },
                        }}
                      />
                    </li>
                  </ul>
                </div>
                <div className="schedulerModal__item">
                  <div className="schedulerModal__label">Ending on</div>
                  <ul className="-horizontal">
                    <li>
                      <DateInput
                        className="ui fluid multiple search selection dropdown dropdownDate -to"
                        isClearable={false}
                        onChange={(event) => {
                          this.updateDate(event, "to");
                        }}
                        renderWeek={this.dateInputWeekRender()}
                        outputFormat={"DD-MM-YYYY"}
                        value={this.state.dateTo && this.state.dateTo}
                        readOnly={this.props.isMobile}
                        required
                        colors={{
                          day: {
                            default: {},
                            active: { background: "#007454", color: "#ffffff" },
                            current: { color: "#007454" },
                            disabled: { color: "#efefef" },
                            hover: { background: "#007454", color: "#ffffff" },
                          },
                          input: {
                            default: {
                              border: "#B4B4B4",
                              color: "#313131",
                              placeholder: "#B4B4B4",
                              tag: "#007454",
                            },
                            disabled: {
                              border: "#EFEFEF",
                              color: "#EFEFEF",
                              tag: "#B4B4B4",
                            },
                            error: {
                              border: "#C02908",
                              color: "#C02908",
                              background: "#FDDCDC",
                              tag: "#C02908",
                            },
                            focused: { border: "#007454", tag: "#007454" },
                          },
                          controls: {
                            color: "#007454",
                            hoverColor: "#007454",
                            monthAndYear: "#007454",
                          },
                        }}
                      />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          ) : (
            <div className="schedulerModal__row">
              <div className="schedulerModal__item">
                <div className="schedulerModal__label" />
                <ul className="-horizontal">
                  <li>
                    <Checkbox
                      name="all day"
                      label="All Day"
                      value="All Day"
                      checked={this.state.allDay ? true : false}
                      onChange={(event) => {
                        this.setAllDay();
                      }}
                    />
                  </li>
                </ul>
              </div>
              <div className="schedulerModal__item">
                <div className="schedulerModal__label">From</div>
                <ul className="-horizontal">
                  <li>
                    <DateInput
                      className="ui fluid multiple search selection dropdown dropdownDate -from"
                      isClearable={false}
                      onChange={(event) => {
                        this.updateDate(event, "from");
                      }}
                      renderWeek={this.dateInputWeekRender()}
                      outputFormat={"DD-MM-YYYY"}
                      value={this.state.dateFrom && this.state.dateFrom}
                      readOnly={this.props.isMobile}
                      required
                      colors={{
                        day: {
                          default: {},
                          active: { background: "#007454", color: "#ffffff" },
                          current: { color: "#007454" },
                          disabled: { color: "#efefef" },
                          hover: { background: "#007454", color: "#ffffff" },
                        },
                        input: {
                          default: {
                            border: "#B4B4B4",
                            color: "#313131",
                            placeholder: "#B4B4B4",
                            tag: "#007454",
                          },
                          disabled: {
                            border: "#EFEFEF",
                            color: "#EFEFEF",
                            tag: "#B4B4B4",
                          },
                          error: {
                            border: "#C02908",
                            color: "#C02908",
                            background: "#FDDCDC",
                            tag: "#C02908",
                          },
                          focused: { border: "#007454", tag: "#007454" },
                        },
                        controls: {
                          color: "#007454",
                          hoverColor: "#007454",
                          monthAndYear: "#007454",
                        },
                      }}
                    />
                  </li>
                  <li>
                    <TimeInput
                      className="dropdownTime -from"
                      value={
                        this.state.timeFrom
                          ? this.state.timeFrom
                          : this.state.nearest30Min
                      }
                      onChange={(date) => this.updateTime(date, "from")}
                      disabled={this.state.allDay}
                      isClearable={false}
                      timeSlot={30}
                      defaultSelectedValue={
                        this.state.timeFrom
                          ? this.state.timeFrom
                          : this.state.nearest30Min
                      }
                      readOnly={this.props.isMobile}
                      required
                      colors={{
                        time: {
                          active: { background: "#007454", color: "#ffffff" },
                          default: {},
                          disabled: {},
                        },
                        input: {
                          default: {
                            border: "#B4B4B4",
                            color: "#313131",
                            placeholder: "#B4B4B4",
                            tag: "#007454",
                          },
                          disabled: {
                            border: "#EFEFEF",
                            color: "#EFEFEF",
                            tag: "#B4B4B4",
                          },
                          error: {
                            border: "#C02908",
                            color: "#C02908",
                            background: "#FDDCDC",
                            tag: "#C02908",
                          },
                          focused: { border: "#007454", tag: "#007454" },
                        },
                      }}
                    />
                  </li>
                </ul>
              </div>

              <div className="schedulerModal__item">
                <div className="schedulerModal__label">Until</div>
                <ul className="-horizontal">
                  <li>
                    <DateInput
                      className="ui fluid multiple search selection dropdown dropdownDate -to"
                      isClearable={false}
                      onChange={(event) => {
                        this.updateDate(event, "to");
                      }}
                      renderWeek={this.dateInputWeekRender()}
                      outputFormat={"DD-MM-YYYY"}
                      value={this.state.dateTo && this.state.dateTo}
                      readOnly={this.props.isMobile}
                      required
                      colors={{
                        day: {
                          default: {},
                          active: { background: "#007454", color: "#ffffff" },
                          current: { color: "#007454" },
                          disabled: { color: "#efefef" },
                          hover: { background: "#007454", color: "#ffffff" },
                        },
                        input: {
                          default: {
                            border: "#B4B4B4",
                            color: "#313131",
                            placeholder: "#B4B4B4",
                            tag: "#007454",
                          },
                          disabled: {
                            border: "#EFEFEF",
                            color: "#EFEFEF",
                            tag: "#B4B4B4",
                          },
                          error: {
                            border: "#C02908",
                            color: "#C02908",
                            background: "#FDDCDC",
                            tag: "#C02908",
                          },
                          focused: { border: "#007454", tag: "#007454" },
                        },
                        controls: {
                          color: "#007454",
                          hoverColor: "#007454",
                          monthAndYear: "#007454",
                        },
                      }}
                    />
                  </li>
                  <li>
                    <TimeInput
                      className="dropdownTime -to"
                      value={
                        this.state.timeTo
                          ? this.state.timeTo
                          : this.state.nearest30Min
                      }
                      onChange={(date) => this.updateTime(date, "to")}
                      disabled={this.state.allDay}
                      isClearable={false}
                      timeSlot={30}
                      defaultSelectedValue={
                        this.state.timeTo
                          ? this.state.timeTo
                          : this.state.nearest30Min
                      }
                      readOnly={this.props.isMobile}
                      required
                      colors={{
                        time: {
                          active: { background: "#007454", color: "#ffffff" },
                          default: {},
                          disabled: {},
                        },
                        input: {
                          default: {
                            border: "#B4B4B4",
                            color: "#313131",
                            placeholder: "#B4B4B4",
                            tag: "#007454",
                          },
                          disabled: {
                            border: "#EFEFEF",
                            color: "#EFEFEF",
                            tag: "#B4B4B4",
                          },
                          error: {
                            border: "#C02908",
                            color: "#C02908",
                            background: "#FDDCDC",
                            tag: "#C02908",
                          },
                          focused: { border: "#007454", tag: "#007454" },
                        },
                      }}
                    />
                  </li>
                </ul>
              </div>
            </div>
          )}
          <div
            className="schedulerModal__row"
            style={{
              display: this.state.available ? "block" : "none",
            }}>
            <div className="schedulerModal__item -column">
              <div className="schedulerModal__label">Available for</div>

              <div className="schedulerModal__column">
                <ul>
                  <li>
                    <Checkbox
                      name="availabilityTypes"
                      label="Select All"
                      value="All"
                      checked={
                        this.state.availabilityTypes.length === 5 ? true : false
                      }
                      onChange={(event) => {
                        this.updateAvailabilityType(event);
                      }}
                    />
                  </li>
                  <li>
                    <Checkbox
                      name="availabilityTypes"
                      label="Local / Home Branch"
                      value="Local"
                      checked={
                        this.state.availabilityTypes.indexOf("Local") > -1
                          ? true
                          : false
                      }
                      onChange={(event) => {
                        this.updateAvailabilityType(event);
                      }}
                    />
                  </li>
                  <li>
                    <Checkbox
                      name="availabilityTypes"
                      label="Region / Short Haul"
                      value="RegionShortHaul"
                      checked={
                        this.state.availabilityTypes.indexOf(
                          "RegionShortHaul"
                        ) > -1
                          ? true
                          : false
                      }
                      onChange={(event) => {
                        this.updateAvailabilityType(event);
                      }}
                    />
                  </li>
                  <li>
                    <Checkbox
                      name="availabilityTypes"
                      label="State / Long Haul"
                      value="StateLongHaul"
                      checked={
                        this.state.availabilityTypes.indexOf("StateLongHaul") >
                        -1
                          ? true
                          : false
                      }
                      onChange={(event) => {
                        this.updateAvailabilityType(event);
                      }}
                    />
                  </li>
                  <li>
                    <Checkbox
                      name="availabilityTypes"
                      label="Interstate / Deployment"
                      value="InterstateDeployment"
                      checked={
                        this.state.availabilityTypes.indexOf(
                          "InterstateDeployment"
                        ) > -1
                          ? true
                          : false
                      }
                      onChange={(event) => {
                        this.updateAvailabilityType(event);
                      }}
                    />
                  </li>
                  <li>
                    <Checkbox
                      name="availabilityTypes"
                      label="IMT"
                      value="IMT"
                      checked={
                        this.state.availabilityTypes.indexOf("IMT") > -1
                          ? true
                          : false
                      }
                      onChange={(event) => {
                        this.updateAvailabilityType(event);
                      }}
                    />
                  </li>
                </ul>
              </div>

              <div
                className="schedulerModal__column"
                style={{
                  display:
                    // this.state.availabilityTypes.indexOf("Local") > -1 &&
                    this.props.canAccessEmergencyLevels && // user must not be CFA only
                    this.state.pagingGroupHasEmergencyLevels // group selected must not be CFA
                      ? "block"
                      : "none",
                }}>
                <div className="schedulerModal__label">
                  Level (select one or more)
                </div>

                <ul>
                  <li>
                    <Checkbox
                      name="levels"
                      label="Select All"
                      value="All"
                      checked={this.state.eventType.length === 3 ? true : false}
                      onChange={(event) => {
                        this.updateEventType(event);
                      }}
                      disabled={
                        this.state.availabilityTypes.indexOf("Local") > -1
                          ? false
                          : true
                      }
                    />
                  </li>
                  <li>
                    <Checkbox
                      name="levels"
                      label="Emergency"
                      value="Emergency"
                      checked={
                        this.state.eventType.indexOf("Emergency") > -1
                          ? true
                          : false
                      }
                      onChange={(event) => {
                        this.updateEventType(event);
                      }}
                      disabled={
                        this.state.availabilityTypes.indexOf("Local") > -1
                          ? false
                          : true
                      }
                    />
                  </li>
                  <li>
                    <Checkbox
                      name="levels"
                      label="Non-Emergency"
                      value="Non-Emergency"
                      checked={
                        this.state.eventType.indexOf("Non-Emergency") > -1
                          ? true
                          : false
                      }
                      onChange={(event) => {
                        this.updateEventType(event);
                      }}
                      disabled={
                        this.state.availabilityTypes.indexOf("Local") > -1
                          ? false
                          : true
                      }
                    />
                  </li>
                  <li>
                    <Checkbox
                      name="levels"
                      label="Admin"
                      value="Admin"
                      checked={
                        this.state.eventType.indexOf("Admin") > -1
                          ? true
                          : false
                      }
                      onChange={(event) => {
                        this.updateEventType(event);
                      }}
                      disabled={
                        this.state.availabilityTypes.indexOf("Local") > -1
                          ? false
                          : true
                      }
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="schedulerModal__row">
            <div className="schedulerModal__item">
              <div className="schedulerModal__label">Paging Group(s)</div>
              <ul className="-horizontal">
                <li>
                  <Radio
                    styles="schedulerModal__radio"
                    name="PagingName"
                    value="All"
                    label="All paging groups"
                    onChange={(event) => {
                      this.updateSelectedPagingGroup(event);
                    }}
                    checked={this.state.pagingNumber === null ? "checked" : ""}
                  />
                </li>
                <li>
                  <Radio
                    styles="schedulerModal__radio"
                    name="PagingName"
                    value="Select"
                    label="Select paging group"
                    onChange={(event) => {
                      this.updateSelectedPagingGroup(event);
                    }}
                    checked={this.state.pagingNumber !== null ? "checked" : ""}
                  />
                </li>
              </ul>
              <div
                className="schedulerModal__input"
                style={{
                  display:
                    this.state.selectedPagingGroup === "All" ? "none" : "block",
                }}>
                {/*
                <Dropdown
                  fluid
                  selection
                  scrolling
                  placeholder="Please select a paging group"
                  value={this.state.pagingNumber}
                  options={this.state.dropdownValue}
                  onChange={(event, value) =>
                    this.updatePagingGroup(event, value)
                  }
                />
                */}
                <FormControl variant="outlined" style={{ width: "80%" }}>
                  <Select
                    className="MuiSelect-root MuiSelect-select MuiSelect-selectMenu MuiSelect-outlined MuiInputBase-input MuiOutlinedInput-input ui fluid selection scrolling dropdown"
                    value={this.state.pagingNumber}
                    onChange={(event) => this.updatePagingGroup(event)}
                    displayEmpty>
                    <MenuItem disabled value="null">
                      Please select a paging group
                    </MenuItem>
                    {this.state.dropdownValue.map((pagingGroup) => {
                      return (
                        <MenuItem
                          // className="select option"
                          value={pagingGroup.value}>
                          {pagingGroup.text}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
        </div>

        <div className="schedulerModal__footer">
          <button
            className="schedulerModal__btn -delete"
            label="Delete"
            disabled={isEventEdit ? "" : "disabled"}
            onClick={() => {
              onDeleteClick(this.state);
            }}>
            {this.state.recurring ? "Delete Series" : "Delete"}
          </button>

          <button
            className="schedulerModal__btn -delete"
            label="Delete"
            style={{
              display: isEventEdit && this.state.recurring ? "block" : "none",
            }}
            onClick={() => {
              onDeleteSingleOccurrenceClick(this.state);
            }}>
            Delete Single Time
          </button>

          <button
            className="schedulerModal__btn -save"
            label="Save"
            onClick={() => {
              this.onSaveClick(this.state);
            }}>
            Save
          </button>
        </div>
      </div>
    );
  }
}
