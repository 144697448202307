import React from "react";
import { format } from "date-fns";
import { Requests } from "../../api/IdentityServerRequests/Requests";
import ErrorBanner from "../../hoc/ErrorBanner/ErrorBanner";
// Layout
import Tabs from "../../components/UI/Tabs/Tabs";
import Button from "../../components/UI/Button/Button";
// Page Component
import { Dropdown } from "semantic-ui-react";
import HalfHrView from "../../components/Availability/HalfHourView";
import GroupThreshold from "../../components/Availability/GroupThreshold";
import copy from "../../assets/copy/copy.json";
import styles from "./Availability.module.scss";
import Wrapper from "../../hoc/Wrapper/Wrapper";
import Loading from "../../components/UI/Loading/Loading";

/*
 * This is the tab for availability threshold overview
 * It is the fourth and last tab and only shows on desktop
 *
 * It uses the HalfHrView component which shows a 24 hr view &
 * the GroupThreshold component which shows a week (7 days) & fortnight (14 days) view
 *
 * A user is allowed to selected multiple paging groups in this section.
 */

export default class AvailabilityOverview extends React.Component {
  constructor(props) {
    super(props);
    this.Requests = new Requests();
    this.state = {
      date: this.props.date,
      thresholdDay: null, // days
      thresholdWeek: null, // one week
      thresholdMultiWeek: null, // two week
      details: "",
      errorMessage: null,
      pagingNumbers: [],
      loading: { day: false, week: false, fortnight: false },
    };
  }

  selectPagingGroup = (value) => {
    let numbers = [];
    value.forEach((item) => {
      numbers.push(item.number);
    });
    this.setState({
      pagingNumbers: numbers,
      loading: { day: true, week: true, fortnight: true },
    });
    this.handleRefresh(numbers);
  };

  handleRefresh = (value) => {
    this.setState({ loading: { day: true, week: true, fortnight: true } });
    // Check if array of paging groups is empty
    if (!Array.isArray(value) || !value.length) {
      this.setState({
        thresholdDay: null,
        thresholdWeek: null,
        thresholdMultiWeek: null,
      });
      return;
    }

    let hrsBody = {
      pagingNumbers: value,
      startDate: format(new Date(), "yyyy-MM-dd"),
      startTime: format(new Date(), "HH:mm"),
    };

    this.Requests.callAPI(
      this.Requests.getHrs, // availability/v1/Availability/paging/hours/{hours}
      hrsBody, // multi select
      24 // number of hrs
    ).then((data) => {
      if (data && data.status && data.status === 200) {
        this.setState({
          thresholdDay: data.data,
          loading: { ...this.state.loading, day: false },
        });
      } else {
        let ErrorMessage =
          copy.availability.getPaginGroupHourThresholdAvailAPIErrorMessage +
          ` (Error #${copy.errorCodes.getPaginGroupHourThresholdAvailAPIErrorMessage})`;
        // if (data && data.data && data.data.SASMessageClient) {
        //   ErrorMessage = data.data.SASMessageClient;
        // }
        this.setState(
          {
            errorMessage: ErrorMessage,
            loading: { ...this.state.loading, day: false },
          },
          () => {
            setTimeout(
              function () {
                this.setState({ errorMessage: null });
              }.bind(this),
              5000
            );
          }
        );
      }
    });

    let daysBody = {
      pagingNumbers: value,
      startDate: format(new Date(), "yyyy-MM-dd"),
    };

    this.Requests.callAPI(
      this.Requests.getDays, // availability/v1​/Availability​/paging​/days​/{numberOfDays}
      daysBody, // multi select
      7 // number of days
    ).then((data) => {
      if (data && data.status && data.status === 200) {
        this.setState({
          thresholdWeek: data.data,
          loading: { ...this.state.loading, week: false },
        });
      } else {
        let ErrorMessage =
          copy.availability.getPaginGroupWeekThresholdAvailAPIErrorMessage +
          ` (Error #${copy.errorCodes.getPaginGroupWeekThresholdAvailAPIErrorMessage})`;
        // if (data && data.data && data.data.SASMessageClient) {
        //   ErrorMessage = data.data.SASMessageClient;
        // }
        this.setState(
          {
            errorMessage: ErrorMessage,
            loading: { ...this.state.loading, week: false },
          },
          () => {
            setTimeout(
              function () {
                this.setState({ errorMessage: null });
              }.bind(this),
              5000
            );
          }
        );
      }
    });

    this.Requests.callAPI(
      this.Requests.getDays, // availability/v1​/Availability​/paging​/days​/{numberOfDays}
      daysBody, // multi select
      14 // number of days
    ).then((data) => {
      if (data && data.status && data.status === 200) {
        this.setState({
          thresholdMultiWeek: data.data,
          loading: { ...this.state.loading, fortnight: false },
        });
      } else {
        let ErrorMessage =
          copy.availability.getPaginGroup2WeekThresholdAvailAPIErrorMessage +
          ` (Error #${copy.errorCodes.getPaginGroup2WeekThresholdAvailAPIErrorMessage})`;
        // if (data && data.data && data.data.SASMessageClient) {
        //   ErrorMessage = data.data.SASMessageClient;
        // }
        this.setState(
          {
            errorMessage: "Availability " + ErrorMessage,
            loading: { ...this.state.loading, fortnight: false },
          },
          () => {
            setTimeout(
              function () {
                this.setState({ errorMessage: null });
              }.bind(this),
              5000
            );
          }
        );
      }
    });
  };

  renderMultiple24hrs() {
    let pagingGroup24hr = [];
    for (let i = 0; i < this.state.thresholdDay.length; i++) {
      pagingGroup24hr.push(
        <div className={styles.row}>
          <span className="PagingGroupName">
            {this.state.thresholdDay[i].pagingGroupName}
          </span>
          <HalfHrView
            //parentCallback={this.callbackFunction}
            overview={true}
            times={this.state.thresholdDay[i].timeAvailable}
            firstOne={i === 0} // add header (time split) to the first row
          />
        </div>
      );
    }
    return pagingGroup24hr;
  }
  renderMultipleDays() {
    let pagingGroupDays = [];
    for (let i = 0; i < this.state.thresholdWeek.length; i++) {
      pagingGroupDays.push(
        <div className={styles.row}>
          <span className={styles.PagingGroupName}>
            {this.state.thresholdWeek[i].pagingGroupName}
          </span>
          <GroupThreshold
            overview={true}
            threshold={this.state.thresholdWeek[i].dateAvailable}
            shorten={false}
            firstOne={i === 0} // add header (dates) to the first row
          />
        </div>
      );
    }

    return pagingGroupDays;
  }

  renderMultipleWeeks() {
    let pagingGroupDays = [];
    for (let i = 0; i < this.state.thresholdMultiWeek.length; i++) {
      pagingGroupDays.push(
        <div className={styles.row}>
          <span className={styles.PagingGroupName}>
            {this.state.thresholdMultiWeek[i].pagingGroupName}
          </span>
          <GroupThreshold
            overview={true}
            threshold={this.state.thresholdMultiWeek[i].dateAvailable}
            shorten={true}
            firstOne={i === 0} // add header (dates) to the first row
          />
        </div>
      );
    }

    return pagingGroupDays;
  }

  render() {
    if (this.props.noPagingGroups) {
      return (
        <Wrapper>
          <ErrorBanner
            isVisible={this.state.errorMessage ? true : false}
            ErrorMessage={this.state.errorMessage}
          />
          <h4>
            <span className="noMessages">
              {copy.availability.noPagingGroupsWithAvailability}
            </span>
          </h4>
        </Wrapper>
      );
    }
    return (
      <Wrapper>
        <ErrorBanner
          isVisible={this.state.errorMessage ? true : false}
          ErrorMessage={this.state.errorMessage}
        />
        <div className="columnTab Overview">
          <div className={styles.overviewDropdownHolder}>
            <div className="availabilityOverviewTabsHolder">
              <Tabs noHistory>
                <div content="24 HOURS" variant="btn_solid">
                  <div className={styles.DayOverviewBG}>
                    {this.state.thresholdDay !== null &&
                      this.renderMultiple24hrs()}
                    {this.state.loading.day && (
                      <div
                        style={{ display: "flex", justifyContent: "center" }}>
                        <Loading />
                      </div>
                    )}
                  </div>
                </div>
                <div content="7 DAYS" variant="btn_solid">
                  <div className={styles.WeekOverviewBG}>
                    {this.state.thresholdWeek !== null &&
                      this.renderMultipleDays()}
                    {this.state.loading.week && (
                      <div
                        style={{ display: "flex", justifyContent: "center" }}>
                        <Loading />
                      </div>
                    )}
                  </div>
                </div>
                <div content="2 WEEKS" variant="btn_solid">
                  <div
                    className={styles.WeekOverviewBG + " " + styles.MultiWeek}>
                    {this.state.thresholdMultiWeek !== null &&
                      this.renderMultipleWeeks()}
                    {this.state.loading.fortnight && (
                      <div
                        style={{ display: "flex", justifyContent: "center" }}>
                        <Loading />
                      </div>
                    )}
                  </div>
                </div>
              </Tabs>
            </div>
            <div className={styles.contentRight}>
              <div className={styles.pagingGroupDropdown}>
                <span className={styles.title}>
                  Get an overview for the following group(s)
                </span>
                <div
                  className={
                    styles.dropdown + " availabilityMultiGroupDropdown"
                  }>
                  <div className="pagingGroupDropdown">
                    {this.props.userPagingGroupsOption && (
                      <Dropdown
                        placeholder="Select Paging Groups"
                        fluid
                        selection
                        multiple
                        search
                        options={this.props.userPagingGroupsOption}
                        onChange={(e, { value }) =>
                          this.selectPagingGroup(value)
                        }
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className={styles.AvailHeader}>
                <div className={styles.overviewHeader}>
                  <Button
                    label="Refresh"
                    content={copy.global.btnRefresh}
                    variant="btn_solid"
                    styles="greyBg"
                    icon="icon_refresh"
                    buttonClick={() => {
                      this.handleRefresh(this.state.pagingNumbers);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }
}
