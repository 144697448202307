import React from "react";

class ScrollButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      intervalId: 0,
      isHidden: true,
    };
    // this.hideBar = this.hideBar.bind(this)
  }

  // hideBar(){
  //    let {isHide} = this.state

  //    window.scrollY < this.prev?
  //    !isHide && this.setState({isHide:true})
  //    :
  //    isHide && this.setState({isHide:false})

  //    this.prev = window.scrollY;
  // }
  // componentDidMount(){
  //     window.addEventListener('scroll',this.hideBar);

  // }
  // componentWillUnmount(){
  //     window.removeEventListener('scroll',this.hideBar);
  // }

  componentDidMount() {
    if (this.props.scrollableElement) {
      this.props.scrollableElement.onscroll = () => {
        if (this.props.scrollableElement.scrollTop === 0) {
          this.setState({ isHidden: true });
        }
        if (this.props.scrollableElement.scrollTop > 200) {
          this.setState({ isHidden: false });
        }
      };
    } else {
      window.onscroll = () => {
        if (window.pageYOffset === 0) {
          this.setState({ isHidden: true });
        }
        if (window.pageYOffset > 200) {
          this.setState({ isHidden: false });
        }
      };
    }
  }

  componentWillUnmount() {
    if (this.props.scrollableElement) {
      this.props.scrollableElement.onscroll = null;
    } else {
      window.onscroll = null;
    }
  }

  scrollStep() {
    if (this.props.scrollableElement) {
      if (this.props.scrollableElement.scrollTop === 0) {
        clearInterval(this.state.intervalId);
        // Enable scrolling again once the top is reached
        this.props.setScrollable(true);
      }
      this.props.scrollableElement.scroll(
        0,
        this.props.scrollableElement.scrollTop - this.props.scrollStepInPx
      );

      //Prevents addition scrolling once reached the top - makes back to top less glitchy
      //this.props.scrollableElement.scroll(0, 0);
    } else {
      if (window.pageYOffset === 0) {
        clearInterval(this.state.intervalId);
      }
      window.scroll(0, window.pageYOffset - this.props.scrollStepInPx);
      //Prevents addition scrolling once reached the top - makes back to top less glitchy
      //window.scroll(0, 0);
    }
  }

  scrollToTop() {
    if (this.props.scrollableElement) {
      // Disable scrolling to stop user's scrolling momentum
      this.props.setScrollable(false);
    }

    // Start the scrolling upwards
    let intervalId = setInterval(
      this.scrollStep.bind(this),
      this.props.delayInMs
    );
    this.setState({ intervalId: intervalId });
  }

  render() {
    //let classHide=this.state.isHide?"hidden":""

    if (this.props.display) {
      return (
        <div
          className={
            (this.state.isHidden ? "hidden" : "visible") + " backTop-holder"
          }>
          <button
            title="Return to the top of the page"
            className={"scroll"}
            // {...(window.cordova
            //   ? {
            //       onTouchStart: () => {
            //         this.scrollToTop();
            //       }
            //     }
            //   : {
            //       onClick: () => {
            //         this.scrollToTop();
            //       }
            //     })}
            {...{
              onClick: () => {
                this.scrollToTop();
              },
            }}>
            <span>
              BACK
              <br />
              TO TOP
            </span>
          </button>
        </div>
      );
    } else {
      return null;
    }
  }
}

export default ScrollButton;
