import React from "react";
import Wrapper from "../../hoc/Wrapper/Wrapper";
import { Requests } from "../../api/IdentityServerRequests/Requests";
import Loader from "../../components/UI/Loading/Loading";
import { format } from "date-fns";
import ErrorBanner from "../../hoc/ErrorBanner/ErrorBanner";

// UIs & styles
import Button from "../../components/UI/Button/Button";
import { Dropdown } from "semantic-ui-react";
// Page Component
import Calendar from "../../components/Availability/Calendar";
//import GroupThreshold from "../../components/Availability/GroupThreshold";
import HalfHrView from "../../components/Availability/HalfHourView";
import ResultList from "../../components/Availability/ResultList";
import styles from "./Availability.module.scss";

// Requests for API calls
import copy from "../../assets/copy/copy.json";

import MetIcon from "../../assets/img/icons/Threshold-Met.svg";
import NotMetIcon from "../../assets/img/icons/Threshold-NotMet.svg";

/*
 * This is the tab for availability type "dashboard"
 * It is the third of four tabs
 *
 * It shows non local availability based off what the user has selected when setting their availability in settings
 * ui/sasApp/sasAppReact/src/views/Settings/AvailabilityView.jsx
 * ui/sasApp/sasAppReact/src/components/Availability/AddNewTime.jsx
 *
 * It does not show local availability
 * Users must choose a availability type to begin
 *
 * It uses the same code as Availability threshold "dashboard" (first tab) but calls different APIs
 * ui/sasApp/sasAppReact/src/views/Availability/AvailabilityView.jsx
 */

export default class TypeView extends React.Component {
  constructor(props) {
    super(props);
    this.Requests = new Requests();
    this.state = {
      width: window.innerWidth,
      ready: false,
      //paramsId: null,
      details: {
        start: null,
      },
      callbackDate: null,
      date: null,
      day: null, // half hr view data
      dayLoading: false,
      results: null, // detail results
      resultsLoading: false,
      //dayOverview: null,
      //thresholdWeek: null,
      //thresholdMultiWeek: null,
      agency: null,
      pagingGroup: this.props.pagingGroup.number,
      thresholdReached: false,
      typeID: null,
      errorMessage: null,
    };
  }

  componentDidMount() {
    window.addEventListener("orientationchange", this.handleWindowSizeChange);
    window.addEventListener("resize", this.handleWindowSizeChange);

    let time = format(new Date(), "HH:MM");
    this.setState({
      details: {
        start: time,
      },
    });
  }

  selectType = (value) => {
    // reset data shown
    this.setState({ typeID: null, day: null, results: null }, () => {
      // set new paging group
      this.setState({ typeID: value });
    });
    // This send the paging group id to the calendar component to render the calendar view
  };

  calendarCallbackFunction = (date) => {
    //reset
    this.setState({
      day: null,
      dayLoading: true,
      results: null,
      // Clear the previously selected time slot whenever a new date is clicked
      details: {
        start: null,
      },
    });
    let formattedDate = format(date, "iiii dd/MM/yyyy");
    let myCallBackDate = format(date, "yyyy-MM-dd");

    this.setState({
      date: formattedDate,
      callbackDate: myCallBackDate,
    });

    this.Requests.callAPI(
      this.Requests.getAvailTypeDay,
      this.state.pagingGroup,
      myCallBackDate,
      this.state.typeID
    ).then((data) => {
      if (data && data.status && data.status === 200) {
        // Get availability for the month
        this.setState({ day: data.data.timeAvailable, dayLoading: false });
      } else {
        let ErrorMessage =
          copy.availability.getPaginGroupDayTypeAvailAPIErrorMessage +
          ` (Error #${copy.errorCodes.getPaginGroupDayTypeAvailAPIErrorMessage})`;
        // if (data && data.data && data.data.SASMessageClient) {
        //   ErrorMessage = data.data.SASMessageClient;
        // }
        this.setState({ errorMessage: ErrorMessage, dayLoading: false }, () => {
          setTimeout(
            function () {
              this.setState({ errorMessage: null });
            }.bind(this),
            5000
          );
        });
      }
    });
  };

  dayCallbackFunction = (childData) => {
    this.setState({
      results: null,
      resultsLoading: true,
      details: { start: childData.start },
    });

    let details = {
      date: this.state.callbackDate,
      timeStart: childData.start,
    };

    this.Requests.callAPI(
      this.Requests.getAvailTypeDetails,
      this.state.pagingGroup, // single select
      details, // date and time
      this.state.typeID
    ).then((data) => {
      if (data && data.status && data.status === 200) {
        // // Get availability for the month
        this.setState({ results: data.data, resultsLoading: false }, () => {
          if (this.state.results.missingAvailabilityTypes.length === 0) {
            this.setState({ thresholdReached: true });
          } else {
            this.setState({ thresholdReached: false });
          }
        });
      } else {
        let ErrorMessage =
          copy.availability.getPagingGroupTimeSlotAvailAPIErrorMessage +
          ` (Error #${copy.errorCodes.getPagingGroupTypeTimeSlotAvailAPIErrorMessage})`;
        // if (data && data.data && data.data.SASMessageClient) {
        //   ErrorMessage = data.data.SASMessageClient;
        // }

        this.setState(
          {
            errorMessage: ErrorMessage,
            resultsLoading: false,
          },
          () => {
            setTimeout(
              function () {
                this.setState({ errorMessage: null });
              }.bind(this),
              5000
            );
          }
        );
      }
    });
  };
  renderMissingTypes() {
    if (this.state.results.missingAvailabilityTypes.length > 0) {
      return this.state.results.missingAvailabilityTypes.map((type) => (
        <span className={styles.skill}>{type}</span>
      ));
    }
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({
      width: window.innerWidth,
    });
  };

  closeDay = () => {
    this.setState({ day: null, results: null });
  };
  closeDetails = () => {
    this.setState({ results: null });
  };

  handleRefresh = () => {
    // remember paging group selected
    let pagingGroup = this.state.pagingGroup;

    const isMobile = this.state.width <= 1023;

    // Only call these functions if user has paging groups
    if (pagingGroup) {
      if (isMobile) {
        if (this.state.results) {
          let selectedTime = this.state.details;
          this.dayCallbackFunction(selectedTime);
        } else if (this.state.day) {
          let selectedDay = new Date(this.state.callbackDate);
          this.calendarCallbackFunction(selectedDay);
        } else {
          // Reset state to trigger calendar to render again and remake API calls
          this.setState({ pagingGroup: null }, () => {
            this.setState({ pagingGroup: pagingGroup });
          });
        }
      } else {
        // reset all values to refresh components
        this.setState({ pagingGroup: null }, () => {
          // show the calendar
          this.setState({ pagingGroup: pagingGroup }, () => {
            // Call calendar and day callback functions only if user has previously seleted a date
            if (this.state.callbackDate !== null && this.state.day !== null) {
              // show the half hr blocks
              let selectedDay = new Date(this.state.callbackDate);
              this.calendarCallbackFunction(selectedDay);
              if (this.state.results !== null) {
                // show the results
                let selectedTime = this.state.details;
                this.dayCallbackFunction(selectedTime);
              }
            }
          });
        });
      }
    }
  };

  render() {
    if (this.props.noPagingGroups) {
      return (
        <Wrapper>
          <ErrorBanner
            isVisible={this.state.errorMessage ? true : false}
            ErrorMessage={this.state.errorMessage}
          />
          <h4>
            <span className="noMessages">
              {copy.availability.noPagingGroupsWithAvailability}
            </span>
          </h4>
        </Wrapper>
      );
    }
    const isMobile = this.state.width <= 1023;
    const typeOptions = [
      {
        key: 1,
        text: "Local / Home Branch",
        value: "Local",
      },
      {
        key: 2,
        text: "Region / Short Haul",
        value: "RegionShortHaul",
      },
      {
        key: 3,
        text: "State / Long Haul",
        value: "StateLongHaul",
      },
      {
        key: 4,
        text: "Interstate / Deployment",
        value: "InterstateDeployment",
      },
      {
        key: 5,
        text: "IMT",
        value: "IMT",
      },
      // AV Only is no longer required since AV is not using availability at the moment
      // {
      //   key: 6,
      //   text: "All (AV Only)",
      //   value: "All_AVOnly",
      // },
    ];

    if (isMobile) {
      return (
        <Wrapper>
          <ErrorBanner
            isVisible={this.state.errorMessage ? true : false}
            ErrorMessage={this.state.errorMessage}
            onTop={true}
          />
          <div
            content={copy.availability.threshold}
            taburl={copy.taburls.threshold}>
            <div className={styles.refreshButtonWrapper}>
              <Button
                label="Refresh"
                content={copy.global.btnRefresh}
                variant="btn_solid"
                styles="greyBg"
                icon="icon_refresh"
                buttonClick={this.handleRefresh}
              />
            </div>
            <div className="col-one-third">
              {this.state.pagingGroup !== null ? (
                <div className={styles.dropdownHolder}>
                  <span className={styles.title}>REVIEW AVAILABILITY OF</span>
                  <Dropdown
                    placeholder="Paging Group"
                    fluid
                    selection
                    search
                    options={this.props.userPagingGroupsOption}
                    onChange={(e, { value }) =>
                      this.props.selectPagingGroup(value)
                    }
                    defaultValue={this.props.pagingGroup}
                  />
                </div>
              ) : (
                <Loader />
              )}

              <div className={styles.dropdownHolder}>
                <span className={styles.title}>
                  CHOOSE AN AVAILABILITY TYPE
                </span>
                <Dropdown
                  placeholder="Select a type"
                  fluid
                  search
                  selection
                  options={typeOptions}
                  onChange={(e, { value }) => this.selectType(value)}
                />
              </div>
            </div>
            {this.state.results ? (
              <Wrapper>
                <div className="box_default">
                  <div className={styles.AvailHeader}>
                    <h2>{this.state.date}</h2>
                    <Button
                      icon=""
                      label={copy.global.btnBack}
                      content={copy.global.btnBack}
                      variant={styles.btn_back}
                      styles="btn_secondary"
                      buttonClick={this.closeDetails}
                    />
                  </div>
                  <div className="Sub-Section-titles">
                    <h3>AT {this.state.details.start}</h3>
                    <span>{this.state.results.available.length} AVAILABLE</span>
                    <span>
                      {this.state.results.unavailable.length} UNAVAILABLE
                    </span>
                  </div>

                  <div
                    className={[
                      styles.missingQuail,
                      "lightHighlight",
                      this.state.thresholdReached === true && styles.reached,
                    ].join(" ")}>
                    {this.state.thresholdReached === true ? (
                      <Wrapper>
                        <img
                          className={styles.icon}
                          src={MetIcon}
                          alt="Threshold Met"
                        />
                        <p> Threshold has been met</p>
                      </Wrapper>
                    ) : (
                      <Wrapper>
                        <img
                          className={styles.icon}
                          src={NotMetIcon}
                          alt="Threshold Has Not been Met"
                        />
                        <p>
                          The availability type missing is:
                          {this.renderMissingTypes()}
                        </p>
                      </Wrapper>
                    )}
                  </div>
                  <ResultList
                    results={this.state.results}
                    timeblock={this.state.start}
                  />
                </div>
              </Wrapper>
            ) : this.state.resultsLoading ? (
              <div className={styles.loadingContainer}>
                <Loader />
              </div>
            ) : this.state.day ? (
              <Wrapper>
                <div className="box_default">
                  <div className={styles.AvailHeader}>
                    <div>
                      <h2>{this.state.date}</h2>
                      <Button
                        icon=""
                        label="Calendar"
                        content="Calendar"
                        variant="btn_back"
                        styles="btn_secondary"
                        buttonClick={this.closeDay}
                      />
                    </div>
                    <div className={styles.iconKey}>
                      <span>Icons below show if threshold is </span>
                      <span className={styles.Achieved}>Achieved</span> or
                      <span className={styles.NotAchieved}>Not Achieved</span>
                      <div className={styles.updatesDesc}>
                        {copy.availability.thresholdUpdateDesc}
                      </div>
                    </div>
                  </div>
                  <HalfHrView
                    overview={false}
                    times={this.state.day}
                    date={this.state.date}
                    parentCallback={this.dayCallbackFunction}
                    target={this.state.details.start}
                  />
                </div>
              </Wrapper>
            ) : (
              <div className="box_default">
                <div className={styles.AvailHeader}>
                  <h2>Availability per month</h2>
                  <div className={styles.iconKey}>
                    <span>Icons below show if threshold is</span>
                    <span className={styles.Achieved}>Achieved</span> or
                    <span className={styles.NotAchieved}>Not Achieved</span>
                    <div className={styles.updatesDesc}>
                      {copy.availability.thresholdUpdateDesc}
                    </div>
                  </div>
                </div>

                {this.state.pagingGroup && this.state.typeID !== null ? (
                  <Calendar
                    type="type"
                    viewOnlyMode={true}
                    overviewParentCallback={this.calendarCallbackFunction}
                    id={this.state.pagingGroup}
                    typeID={this.state.typeID}
                    selectedDate={this.state.callbackDate}
                  />
                ) : (
                  <p className="title">{copy.availability.typeMSG}</p>
                )}
              </div>
            )}
          </div>
        </Wrapper>
      );
    } else {
      return (
        <Wrapper>
          <ErrorBanner
            isVisible={this.state.errorMessage ? true : false}
            ErrorMessage={this.state.errorMessage}
            onTop={true}
          />
          <div className={styles.AvailHeader}>
            <div>
              <h2>{copy.availability.byTypeTitle}</h2>
              <p>{copy.availability.byTypeDesc}</p>
            </div>
            <div className={styles.iconKey}>
              Icons below show if timeslot
              <span className={styles.Achieved}>Has the selected type</span>
              or
              <span className={styles.NotAchieved}>
                Does NOT have the selected type
              </span>
              <div className={styles.updatesDesc}>
                {copy.availability.thresholdUpdateDesc}
              </div>
            </div>

            <div className={styles.rightAligned}>
              <div className={styles.dropdownHolder}>
                <span className={styles.title}>
                  CHOOSE AN AVAILABILITY TYPE
                </span>
                <Dropdown
                  placeholder="Select a type"
                  fluid
                  search
                  selection
                  options={typeOptions}
                  onChange={(e, { value }) => this.selectType(value)}
                />
              </div>
              <div className={styles.refreshButtonWrapper}>
                <Button
                  label="Refresh"
                  content={copy.global.btnRefresh}
                  variant="btn_solid"
                  styles="greyBg"
                  icon="icon_refresh"
                  buttonClick={this.handleRefresh}
                />
              </div>
            </div>

            {/* <div className={styles.refreshButtonWrapper}>
              
            </div> */}
          </div>
          <div>
            <div className="col-one-third">
              <div className="box_default">
                <div className="minheight">
                  {this.state.pagingGroup && this.state.typeID !== null ? (
                    <Calendar
                      type="type"
                      viewOnlyMode={true}
                      overviewParentCallback={this.calendarCallbackFunction}
                      id={this.state.pagingGroup}
                      typeID={this.state.typeID}
                      selectedDate={this.state.callbackDate}
                    />
                  ) : (
                    <p className="title">{copy.availability.typeMSG}</p>
                  )}
                </div>
              </div>
            </div>
            <div className="col-one-third">
              <div className={styles.availabilityColumn}>
                {this.state.day !== null ? (
                  <div>
                    <div className="Sub-Section-titles">
                      <h3>{this.state.date}</h3> {/* replace this date */}
                    </div>
                    <div className="maxheight">
                      <HalfHrView
                        overview={false}
                        times={this.state.day}
                        date={this.state.date}
                        parentCallback={this.dayCallbackFunction}
                        target={this.state.details.start}
                      />
                    </div>
                  </div>
                ) : this.state.dayLoading ? (
                  <div className={styles.loadingContainer}>
                    <Loader />
                  </div>
                ) : null}
              </div>
            </div>
            <div className="col-one-third">
              <div className={styles.availabilityColumn}>
                {this.state.results ? (
                  <Wrapper>
                    <div className="Sub-Section-titles">
                      <h3>AT {this.state.details.start}</h3>

                      <span>
                        {this.state.results.available.length}
                        {copy.availability.AVAILABLE}
                      </span>
                      <span>
                        {this.state.results.unavailable.length}
                        {copy.availability.UNAVAILABLE}
                      </span>
                    </div>
                    <div
                      className={[
                        styles.missingQuail,
                        "lightHighlight",
                        this.state.thresholdReached === true && styles.reached,
                      ].join(" ")}>
                      {this.state.thresholdReached === true ? (
                        <Wrapper>
                          <img
                            className={styles.icon}
                            src={MetIcon}
                            alt="Threshold Met"
                          />
                          <p> Threshold has been met</p>
                        </Wrapper>
                      ) : (
                        <Wrapper>
                          <img
                            className={styles.icon}
                            src={NotMetIcon}
                            alt="Threshold Has Not been Met"
                          />
                          <p>
                            The availability type missing is:
                            {this.renderMissingTypes()}
                          </p>
                        </Wrapper>
                      )}
                    </div>

                    <div className="maxheight">
                      {/* {this.state.results && ( */}
                      <ResultList
                        results={this.state.results}
                        timeblock={this.state.start}
                      />
                      {/* // )} */}
                    </div>
                  </Wrapper>
                ) : this.state.resultsLoading ? (
                  <div className={styles.loadingContainer}>
                    <Loader />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </Wrapper>
      );
    }
  }
}
