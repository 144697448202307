/* eslint no-fallthrough: 0 */
import { LogglyTracker } from "loggly-jslogger";

// const logger = new LogglyTracker();

// logger.push({
//   logglyKey: "ecc6659f-4bb9-49b6-a800-11a0e310eeb3",
//   sendConsoleErrors: true,
// });

export const LoggerLevel = {
  NONE: 0,
  ERROR: 1,
  LOG: 2,
  DEBUG: 3,
  VERBOSE: 4,
};

const DEVELOPMENT_ENV = "development";

class Logger {
  constructor() {
    this.userId = null;
    this.deviceId = null;
    this.platform = null;

    // Set default empty functions so that it doesn't crash if they are not set
    this._resetLoggingFunctions();

    this.setLogLevel(LoggerLevel.ERROR);

    // Set up loggly when not using the development environment
    if (process.env.NODE_ENV !== DEVELOPMENT_ENV) {
      this.loggly = new LogglyTracker();
      this.loggly.push({
        logglyKey: process.env.REACT_APP_LOGGLY_KEY,
        sendConsoleErrors: true,
        useUtfEncoding: true
      });
    }
  }

  /**
   * Setting the user id, this is separate from the device id and OS, since we will
   * only be able to get the user id once logged in.
   * @param {string} userId The user id that will need to be logged.
   */
  setUserId(userId) {
    this.userId = userId;
  }

  /**
   * This is to set the device info that will be useful in the remote logging.
   * @param {string|null} deviceId The device id that is assigned to the device.
   * @param {string|null} platform The platform the user is using the application on.
   */
  setDeviceInfo(deviceId, platform) {
    if (deviceId !== null || deviceId !== "null") {
      this.deviceId = deviceId;
    }

    if (platform !== null || platform !== "null") {
      this.platform = platform;
    }
  }

  /**
   * This will set the logging level that will be configured.
   * @param {int} logLevel The logger level that should be configured.
   */
  setLogLevel(logLevel) {
    // Setting up the time stamp function so that the timestamp will be able to track when logs are being saved
    const timestamp = () => {};
    timestamp.toString = () => {
      const dateObject = new Date();
      const dateTimeStamp = `${dateObject.toLocaleTimeString()}.${dateObject.getMilliseconds()}::`;

      return dateTimeStamp;
    };

    // Need to reset the logging functions if the logging level is none
    if (logLevel === LoggerLevel.NONE) {
      this._resetLoggingFunctions();
    } else {
      // Depending on the log log level, will need to set functions
      switch (logLevel) {
        case LoggerLevel.VERBOSE:
          if (process.env.NODE_ENV === DEVELOPMENT_ENV) {
            this.verbose = window.console.log.bind(
              window.console,
              "[VER]:: %s",
              timestamp
            );
          } else {
            this.verbose = (message, ...args) => {
              this._pushToLoggly("verbose", message, ...args);
            };
          }
        case LoggerLevel.DEBUG:
          if (process.env.NODE_ENV === DEVELOPMENT_ENV) {
            this.debug = window.console.log.bind(
              window.console,
              `[DEB]:: %s`,
              timestamp
            );
          } else {
            this.debug = (message, ...args) => {
              this._pushToLoggly("debug", message, ...args);
            };
          }
        case LoggerLevel.LOG:
          if (process.env.NODE_ENV === DEVELOPMENT_ENV) {
            this.log = window.console.log.bind(
              window.console,
              `[LOG]:: %s`,
              timestamp
            );
          } else {
            this.log = (message, ...args) => {
              this._pushToLoggly("log", message, ...args);
            };
          }
        case LoggerLevel.ERROR:
          if (process.env.NODE_ENV === DEVELOPMENT_ENV) {
            this.error = window.console.error.bind(
              window.console,
              `[ERR]:: %s`,
              timestamp
            );
          } else {
            this.error = (message, ...args) => {
              this._pushToLoggly("error", message, ...args);
            };
          }
        default:
        // Do nothing
      }
    }
  }

  /**
   * Private function that is used to reset the different logging functions that will be used.
   * This should not be used outside the logger class.
   */
  _resetLoggingFunctions() {
    this.verbose = () => {};
    this.debug = () => {};
    this.log = () => {};
    this.error = () => {};
  }

  /**
   * This function is a private function that should only be used within the logger class. It
   * will send the data to Loggly so that information can be remotely logged.
   * @param {string} loggingLevel The logging level to be pushed to Loggly.
   * @param {string|null} message The message that will be logged in Loggly.
   * @param  {...any} args The additional parameters that are being logged.
   */
  _pushToLoggly(loggingLevel, message, ...args) {
    let level = loggingLevel;
    if (loggingLevel === null || loggingLevel === undefined) {
      level = "unknown";
    }

    // Set up the object that will be sent to
    const objectToSend = { level };

    // Add the user id
    if (this.userId !== null) {
      objectToSend.userId = this.userId;
    }

    // Add the device id
    if (this.deviceId !== null) {
      objectToSend.deviceId = this.deviceId;
    }

    // Add the firebase id
    if (this.platform !== null) {
      objectToSend.platform = this.platform;
    }

    // Put the message into the object if it exists
    if (message !== null || message !== undefined) {
      objectToSend.message = message;
    }

    // Add all the extra args into a list
    if (args.length > 0) {
      objectToSend.arguments = args;
    }

    // Push the object created to loggly.
    // TODO: Uncomment this line once Loggly has been procured and can be tested
    this.loggly.push(objectToSend);
  }
}

// Singleton logger object
export const logger = new Logger();
