/**
 * This class is used to handle all the error status codes we are receiving.
 * For example, it can be used to classify status codes into certain types which can then be passed into the ErrorBanner so that
 * the display can be tailored to the error.
 */
export class ErrorHandler {
  constructor() {
    // Grouping of status codes
    this.applicationErrors = [401, 500];
    this.termsOfUseErrors = [403];
  }

  /**
   * This function will return a string which describes what kind of error it is
   */
  determineErrorType(statusCode) {
    if (this.applicationErrors.indexOf(statusCode) !== -1) {
      return "application";
    } else if (this.termsOfUseErrors.indexOf(statusCode) !== -1) {
      return "acceptTermsOfUse";

      // If the error has not been accounted for, assume it is an application error
    } else {
      return "application";
    }
  }
}
