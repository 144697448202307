import React, { Component } from "react";
import { withRouter } from "react-router";

class TableCell extends Component {
  render() {
    const {
      props: { peopleData, qualificationData, thresholdType },
    } = this;

    switch (this.props.type) {
      case "HEADER__TIME":
        let timeValue =
          this.props.value.text === "23:59:59"
            ? "24:00"
            : this.props.value.text;

        let qualificationsCount = 0;
        let requiredQualifications = <span>&nbsp;</span>;
        if (thresholdType === "ByQualification") {
          if (peopleData.requiredQualifications !== undefined) {
            for (let i = 0; i < peopleData.requiredQualifications.length; i++) {
              qualificationsCount += peopleData.requiredQualifications[i].count;
            }
            requiredQualifications = peopleData.requiredQualifications.map(
              // eslint-disable-next-line
              (qualification) => {
                if (qualification.count > 0) {
                  return (
                    <li
                      title={qualification.desc}
                      style={{
                        fontSize: "11px",
                        whiteSpace: "initial",
                        padding: "0 0 2px 0",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        textAlign: "initial",
                      }}>
                      {qualification.desc + " (" + qualification.count + ")"}
                      <br />
                    </li>
                  );
                }
              }
            );
          }
        }

        return (
          <th
            className="availabilityDashboard__table-header -time"
            data-time={timeValue}>
            {thresholdType === "ByPeople" && this.props.filter === "Local" ? (
              <ul>
                <li
                  className={
                    qualificationData.thresholdReached ||
                    peopleData.thresholdReached
                      ? "-achieved"
                      : undefined
                  }>
                  {timeValue}
                </li>
                {this.props.memberParam === "Type" && (
                  <div>
                    <li
                      style={{
                        minHeight:
                          this.props.membersCountHeight === 0
                            ? "auto"
                            : this.props.membersCountHeight,
                      }}>
                      {peopleData.length === 0 ? (
                        <span>&nbsp;</span>
                      ) : (
                        peopleData.availableMembers
                      )}
                    </li>
                    <li>
                      {peopleData.length === 0 ? (
                        <span>&nbsp;</span>
                      ) : (
                        peopleData.requiredMembers
                      )}
                    </li>
                  </div>
                )}
              </ul>
            ) : (
              <ul>
                <li>{timeValue}</li>
              </ul>
            )}

            {thresholdType === "ByQualification" && (
              <ul>
                <div>
                  {this.props.filter === "Local" &&
                    this.props.memberParam === "Type" && (
                      <div>
                        <li
                          style={{
                            minHeight:
                              this.props.qualsCountHeight === 0
                                ? "auto"
                                : this.props.qualsCountHeight,
                          }}>
                          {qualificationsCount}
                        </li>
                        <li
                          style={{
                            display: this.props.showQualificationDetails
                              ? "block"
                              : "none",
                          }}>
                          {requiredQualifications}
                        </li>
                      </div>
                    )}
                </div>
              </ul>
            )}
          </th>
        );

      case "CELL__AVAILABLE":
        let levels = this.props.value;
        if (levels && levels.length > 0) {
          let listLevels = levels.map((level, levelIndex) => {
            return (
              <li
                key={`level-${levelIndex}`}
                className={"-" + level.toLowerCase()}>
                <span className="sr-only">{level.toLowerCase()}</span>
              </li>
            );
          });
          return (
            <td>
              <ul className="availabilityDashboard__status">{listLevels}</ul>
            </td>
          );
        } else {
          return (
            <td>
              <ul className="availabilityDashboard__status">
                <li key={`level-0`} className={"-notLocal"}>
                  <span className="sr-only"></span>
                </li>
              </ul>
            </td>
          );
        }

      case "CELL__UNAVAILABLE":
        return (
          <td>
            <ul className="availabilityDashboard__status">
              <li className="-unavailable">
                <span className="sr-only">Unavailable</span>
              </li>
            </ul>
          </td>
        );

      case "EMPTY__HEADER__CELL":
        return <th></th>;

      case "EMPTY__CELL":
        return <td></td>;

      case "HEADER__CELL":
        return <th>{this.props.content}</th>;

      case "CELL":
        return <td>{this.props.content}</td>;

      default:
        // * Return nothing
        return null;
    }
  }
}

export default withRouter(TableCell);
