import React, { Component } from "react";
import Wrapper from "../../hoc/Wrapper/Wrapper";
import ErrorBanner from "../../hoc/ErrorBanner/ErrorBanner";
import Loading from "../UI/Loading/Loading";
import Icon from "../UI/Icon/Icon";
import Button from "../../components/UI/Button/Button";
import styles from "./Chats.module.scss";
import copy from "../../assets/copy/copy.json";

import { Requests } from "../../api/IdentityServerRequests/Requests";

class EventChat extends Component {
  constructor(props) {
    super(props);
    this.Requests = new Requests();
    this.state = {
      width: window.innerWidth,
      isLoading: false,
      errorMessage: null,
      invalidFields: false,
      chatData: this.props.chatData,
      chatMessageThread: this.props.chatData
        ? this.props.chatData.chatMessage
        : null,
      chatThreadId: this.props.chatData ? this.props.chatData.id : null,
      textAreaCharacterCount: 0,
      isSendingMessage: false,
      textAreaInput: null,
      messageHeaderHeight: null,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.chatData !== this.props.chatData) {
      this.setState({
        chatData: this.props.chatData,
      });
    }
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
    this.messageArrayConstructor();
    this.calculateHeaderHeight();
    if (window.cordova) {
      window.localStorage.removeItem("redirectToChat");
    }
  }

  messageArrayConstructor = () => {
    if (this.state.chatMessageThread && this.state.chatMessageThread[0]) {
      if (this.state.chatData.chatType !== "OneOnOne") {
        const array = [...this.state.chatMessageThread];
        const originalMessage =
          this.state.chatMessageThread[this.state.chatMessageThread.length - 1]
            .message;
        const messageArray = array.slice(0, -1);

        this.setState({
          originalChatMessage: originalMessage,
          chatMessageThread: messageArray,
        });
      }
    }
  };

  // This the event chat on the event overview page
  characterCount = (event) => {
    var count = event.target.value.length;
    this.setState({
      textAreaInput: event.target.value,
      textAreaCharacterCount: count,
    });
  };

  calculateHeaderHeight = () => {
    let InputHolder;

    if (document.getElementById("input_holder")) {
      InputHolder = document.getElementById("input_holder").clientHeight;
    } else {
      InputHolder = 0;
    }

    this.setState({
      messageHeaderHeight: InputHolder,
    });
  };

  updateThreadMessages = () => {
    const id = this.state.chatThreadId;

    if (id) {
      this.Requests.callAPI(this.Requests.getChatThread, id).then((data) => {
        if (data && data.status && data.status === 200) {
          this.setState(
            {
              chatMessageThread: data.data.chatMessage,
            },
            () => {
              //if the refresh button has been clicked, then we don't need to refresh anymore, as API has been called
              if (this.props.refresh) {
                this.props.refreshFunc(false);
              }
              this.props.getCurrentEventDetails();
              this.messageArrayConstructor();
            }
          );
        } else {
          this.props.getCurrentEventDetails();
          if (this.props.refresh) {
            this.props.refreshFunc(false);
          }
          let ErrorMessage =
            copy.sasChat.updateChatThreadAPIErrorMessage +
            ` (Error #${copy.errorCodes.updateChatThreadAPIErrorMessage})`;
          // if (data && data.data && data.data.SASMessageClient) {
          //   ErrorMessage = data.data.SASMessageClient;
          // }
          this.setState({ errorMessage: ErrorMessage }, () => {
            setTimeout(
              function () {
                this.setState({ errorMessage: null });
              }.bind(this),
              5000
            );
          });
        }
      });
    }
  };

  sendChatMessage = () => {
    const id = this.state.chatThreadId;
    const body = {
      message: this.state.textAreaInput,
    };

    if (!this.state.textAreaInput) {
      this.setState({
        invalidFields: true,
      });
    } else {
      this.setState(
        {
          isSendingMessage: true,
        },
        () => {
          this.Requests.callAPI(this.Requests.putChatMessage, id, body).then(
            (data) => {
              if (data && data.status && data.status === 200) {
                this.updateThreadMessages();
                this.setState({
                  isSendingMessage: false,
                });
                document.getElementById("chatThreadTextArea").value = "";
              } else {
                let ErrorMessage =
                  copy.sasChat.sendingEventChatMessageAPIErrorMessage +
                  ` (Error #${copy.errorCodes.sendingEventChatMessageAPIErrorMessage})`;
                if (
                  data &&
                  data.status &&
                  data.status === 400 &&
                  data.data.SASENumErrorCode === 53
                ) {
                  ErrorMessage =
                    copy.sasChat.prohibitedWordInChatMessageAPIErrorMessage;
                }
                this.setState(
                  { isSendingMessage: false, errorMessage: ErrorMessage },
                  () => {
                    setTimeout(
                      function () {
                        this.setState({ errorMessage: null });
                      }.bind(this),
                      5000
                    );
                  }
                );
              }
            }
          );
        }
      );
    }
  };

  // converts date/time so Safari reads it properly
  convertDate = (date) => {
    if (date) {
      return date
        .replace(/-/g, "/")
        .replace("T", " ")
        .replace(/\..*|\+.*/, "");
    } else {
      return null;
    }
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  render() {
    const { width } = this.state;
    const isMobile = width <= 1023;

    if (this.state.chatData) {
      const { chatType, subject, isActive, recipientName } =
        this.state.chatData;

      let hasMessages;
      if (this.state.chatMessageThread.length > 0) {
        hasMessages = styles.hasMessages;
      }
      if (this.state.chatMessageThread !== null) {
        this.ChatMessages = this.state.chatMessageThread.map((item, key) => {
          let messageOwnerInitials;
          if (item.userInfo) {
            messageOwnerInitials = item.userInfo.name.match(/\b(\w)/g);
          }
          const messageTime = new Date(
            this.convertDate(item.timeStamp)
          ).toLocaleTimeString();

          const messageDate = new Date(this.convertDate(item.timeStamp))
            .toLocaleDateString("en-GB")
            .replace(/\//g, "-");

          return (
            <div key={key} className={styles.chatMessage}>
              <div className={styles.messageHeader}>
                <div className="timeStamp">
                  <span className="time">{messageTime}</span>
                  <span className="date">{messageDate}</span>
                </div>
              </div>
              <Icon
                label="Chat bubble"
                content={messageOwnerInitials}
                variant={item.IsRead ? "" : "unreadMessageIndicatorTemp"}
                styles=""
                icon={"icon_chat"}
              />
              <div
                className={[styles.messageHolder, styles.chatThread].join(" ")}
              >
                <span className={styles.name + " txt2"} itemProp="name">
                  {item.userInfo && item.userInfo.name}
                </span>
                <span className={styles.message}>{item.message}</span>
              </div>
            </div>
          );
        });
      } else {
        this.ChatMessages = (
          <div className={styles.chatMessage}>
            <Icon
              label="Chat bubble"
              content="SAS"
              variant="read"
              styles=""
              icon="icon_chat"
            />
            <span className={styles.messageHolder}>
              <span className={styles.message}>No chat messages to view.</span>
            </span>
          </div>
        );
      }
      // let eventActive = this.props.event.messages[0].eventStatus;
      return (
        <div
          className={styles.EventChat_section + " col-one-third " + hasMessages}
        >
          <ErrorBanner
            isVisible={this.state.errorMessage ? true : false}
            ErrorMessage={this.state.errorMessage}
          />
          <div className={styles.threadHeader + " Section-titles"}>
            <h2>{copy.event.chats}</h2>
          </div>
          <div
            className={
              styles.contentWrapper + " box_default " + styles.eventChatContent
            }
          >
            {this.state.isLoading ? (
              <div className={styles.loadingContainer}>
                <Loading />
              </div>
            ) : (
              <Wrapper>
                {chatType !== "Event" && (
                  <div
                    id="message_heading"
                    className={styles.originalMessageHeading}
                  >
                    <span className={styles.RE}>{copy.sasChat.replyShort}</span>
                    <span>{subject}</span>
                  </div>
                )}
                {isActive && (
                  <div
                    id="input_holder"
                    className={
                      styles.messageInput_holder +
                      " " +
                      (this.props.attending && !this.props.eventStatus
                        ? this.state.invalidFields && styles.errorTextField
                        : " not-allowed")
                    }
                  >
                    <div className={styles.chatHeader}>
                      {chatType === "AdhocGroup" ||
                      chatType === "Group" ||
                      chatType === "Event" ? (
                        <label>Enter your reply</label>
                      ) : (
                        <label>
                          {copy.sasChat.reply}
                          {recipientName}
                        </label>
                      )}
                      <span>
                        {this.state.textAreaCharacterCount}
                        {copy.sasChat.characters}
                      </span>
                    </div>
                    <textarea
                      className={
                        this.props.attending && !this.props.eventStatus
                          ? this.state.invalidFields && styles.errorTextField
                          : "disable"
                      }
                      id="chatThreadTextArea"
                      aria-label="Reply to message here"
                      name="reply to message"
                      placeholder="Enter your message"
                      maxLength="512"
                      onChange={this.characterCount}
                    />
                    {this.state.invalidFields && (
                      <span className={styles.errorMessage}>
                        {copy.error_handling.required}
                      </span>
                    )}
                    <div className={styles.postTxtBtn}>
                      <p>{copy.sasChat.messageDesc}</p>
                      {this.state.isSendingMessage ? (
                        <Loading />
                      ) : this.props.attending && !this.props.eventStatus ? (
                        <Button
                          label="your location"
                          content={copy.global.btnSend}
                          variant="btn_outline"
                          styles="greenTxt"
                          icon="icon_post"
                          buttonClick={this.sendChatMessage}
                        />
                      ) : (
                        <Button
                          label="your location"
                          content={copy.global.btnSend}
                          variant="btn_outline"
                          styles="greenTxt"
                          icon="icon_post"
                          send_disable={true}
                        />
                      )}
                    </div>
                  </div>
                )}

                {this.state.chatMessageThread.length > 0 ? (
                  <Wrapper>
                    <div className={styles.repliesHeading}>
                      <span>{copy.sasChat.replies}</span>
                    </div>
                    {isMobile ? (
                      <div
                        style={{
                          maxHeight: `calc(100vh - (${this.state.messageHeaderHeight}px + 218px))`,
                        }}
                        className={styles.messagesHolder}
                      >
                        {this.state.chatMessageThread && this.ChatMessages}
                      </div>
                    ) : (
                      <div
                        style={{
                          height: `calc(100% - (${this.state.messageHeaderHeight}px + 30px))`,
                        }}
                        className={styles.messagesHolder}
                      >
                        {this.state.chatMessageThread && this.ChatMessages}
                      </div>
                    )}
                  </Wrapper>
                ) : null}
              </Wrapper>
            )}
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}

export default EventChat;
