import React, { Component } from "react";
import styles from "./Timer.module.scss";
import copy from "../../assets/copy/copy.json";

export default class EventHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      intervalId: null,
      currentIntervalSeconds: 30,
      isCheckingForEvents: false,
    };
  }

  // ComponentDidUpdate updates user agency info if the data comes through as null or undefined initially
  componentDidUpdate(prevProps, prevState) {
    if (this.props.isCheckingForEvents !== prevProps.isCheckingForEvents) {
      this.setState({
        isCheckingForEvents: this.props.isCheckingForEvents,
      });
    }
    if (
      this.props.getActivePagerMessagesIsFinished !==
      prevProps.getActivePagerMessagesIsFinished
    ) {
      if (this.props.getActivePagerMessagesIsFinished) {
        this.setState({
          isCheckingForEvents: false,
          currentIntervalSeconds: 30,
        });
      }
    }
  }

  componentDidMount() {
    let intervalId = setInterval(this.dashboardRefreshTimer, 1000);
    this.setState({ intervalId: intervalId });
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
  }

  dashboardRefreshTimer = () => {
    let currentTime = this.state.currentIntervalSeconds;
    if (currentTime > 1) {
      this.setState({
        currentIntervalSeconds: currentTime - 1,
      });
    } else if (currentTime === 1) {
      this.setState(
        {
          currentIntervalSeconds: "Updating now...",
          isCheckingForEvents: true,
        },
        () => {
          setTimeout(
            function() {
              this.props.updateEventCounter();
              this.props.getActivePagerMessages();
              if (!this.props.userPagingGroupsOption) {
                this.props.prepareFilterData();
              }
            }.bind(this),
            25
          );
        }
      );
    }
  };

  render() {
    return (
      <div className={styles.refreshTimer + " refreshTimer"}>
        <div className={styles.heading}>{copy.dashboard.dashboardUpdate}</div>
        <div>
          {this.state.currentIntervalSeconds}{" "}
          {!this.state.isCheckingForEvents
            ? copy.dashboard.dashboaradTimer
            : ""}
        </div>
      </div>
    );
  }
}
