import React from "react";
import Wrapper from "../../hoc/Wrapper/Wrapper";
import { ReactComponent as AppLOGO } from "../../assets/img/logos/SAS-APP.svg";
import iOSBadge from "../../assets/img/logos/iOS_Badge.svg";
import androidBadge from "../../assets/img/logos/Android_Badge.png";
import copy from "../../assets/copy/copy.json";

export default class ForceUpdate extends React.Component {
  render() {
    return (
      <Wrapper>
        <div className="pageHeader"></div>
        <AppLOGO />
        <div className={"box_default termsofuse"}>
          <h3>{copy.update_page.updateMessage}</h3>
          <p>{copy.update_page.greetingMessage}</p>
          {window.device && window.device.platform === "iOS" && (
            <div>
              <p>{copy.update_page.iOSUpdateMessage}</p>
              <div className="appstoreLogosiOS">
                <a href={this.props.location.state.result.iOSAppUpdateURL}>
                  <img
                    alt="Download on the Apple App Store badge"
                    src={iOSBadge}
                  />
                </a>
              </div>
            </div>
          )}
          {window.device && window.device.platform === "Android" && (
            <div>
              <p>{copy.update_page.androidUpdateMessage}</p>
              <div className="appstoreLogosAndroid">
                <a href={this.props.location.state.result.androidAppUpdateURL}>
                  <img alt="Get it on Google Play badge" src={androidBadge} />
                </a>
              </div>
            </div>
          )}
        </div>
      </Wrapper>
    );
  }
}
