import React from "react";
// import PropTypes from "prop-types";
import Wrapper from "../../hoc/Wrapper/Wrapper";
import ErrorBanner from "../../hoc/ErrorBanner/ErrorBanner";
import { ReactComponent as AppLOGO } from "../../assets/img/logos/SAS-APP.svg";
import Button from "../../components/UI/Button/Button";

// import { AuthService } from "../../api/Authentication/AuthService";
import { Requests } from "../../api/IdentityServerRequests/Requests";
import styles from "./LoginView.module.scss";
import pager from "../../assets/img/pager-image-small.jpg";
import copy from "../../assets/copy/copy.json";

export default class ASN extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: null,
      inputValue: null,
    };
    this.Requests = new Requests();
  }

  /**
   * Saves the ASN value to the state, entered by the user.
   */
  handleChange = (e) => {
    this.setState({
      inputValue: e.target.value,
    });
  };

  /**
   * Sends the ASN value to the backend using an endpoint defined in Requests.jsx
   * Error message is displayed if the call fails.
   */
  addASN = () => {
    let input = this.state.inputValue;

    if (input !== null) {
      // This function has error handling for PUT, POST & DELETE requests.
      // This function's error handling will NOT work for GET requests
      this.Requests.callAPI(this.Requests.sendSingleUserASN, input).then(
        (data) => {
          if (data && data.status && data.status === 200) {
            this.redirectToHome();
          } else {
            let ErrorMessage =
              copy.asn.addASNAPIErrorMessage +
              ` (Error #${copy.errorCodes.addASNAPIErrorMessage})`;

            if (data && data.data && data.data.SASENumErrorCode) {
              if (
                data.data.SASENumErrorCode === 1 ||
                data.data.SASENumErrorCode === 4 ||
                data.data.SASENumErrorCode === 17
              ) {
                ErrorMessage = data.data.SASMessageClient;
              }
            }
            this.setState({ errorMessage: ErrorMessage }, () => {
              setTimeout(
                function () {
                  this.setState({ errorMessage: null });
                }.bind(this),
                5000
              );
            });
          }
        }
      );
    }
  };

  /**
   * This method is called to redirect to Home if the user clicks Skip Pager or after the ASN has been added.
   * It makes an API call to the backend to save the fact that the user has completed onboarding - so they will not be taken to this screen again.
   * ASN is only displayed if the user hasn't been onboarded.
   */
  redirectToHome = () => {
    // Need to mark obnboarding as complete before sending to homepage
    let flag = "true";
    this.Requests.callAPI(this.Requests.sendOnboardingCompletion, flag).then(
      (data) => {
        if (data && data.status && data.status === 200) {
          if (
            !window.cordova ||
            window.localStorage.getItem("seenLocationDisclosure") === "true"
          ) {
            // Don't show location disclosure on desktop or if user has seen the location disclosure page already.
            this.props.history.push({
              pathname: "/home",
            });
          } else {
            this.props.history.push({
              pathname: "/location-disclosure",
            });
          }
        } else {
          let ErrorMessage =
            copy.login.onboardingAPIErrorMessage +
            ` (Error #${copy.errorCodes.onboardingAPIErrorMessage})`;
          // if (data && data.data && data.data.SASMessageClient) {
          //   ErrorMessage = data.data.SASMessageClient;
          // }
          this.setState({ errorMessage: ErrorMessage }, () => {
            setTimeout(
              function () {
                this.setState({ errorMessage: null });
              }.bind(this),
              5000
            );
          });
        }
      }
    );
  };

  render() {
    return (
      <Wrapper>
        <ErrorBanner
          isVisible={this.state.errorMessage ? true : false}
          ErrorMessage={this.state.errorMessage}
          errorType={this.state.errorType}
        />
        <div className={styles.pageHeader}></div>
        <AppLOGO />
        <div className={"box_default " + styles.termsofuse}>
          <h2>{copy.asn.title}</h2>
          <p>{copy.asn.body}</p>
          <label
            className={styles.smallLabel}
            aria-label={copy.asn.asnInputLabel}
          >
            {copy.asn.asnInputLabel}
          </label>
          <input
            onChange={this.handleChange}
            type="text"
            placeholder="ASNPPUA000"
            maxLength="10"
          />
          <p className={styles.sub}>{copy.asn.asnInputNote}</p>
          <label
            className={styles.smallLabel + " " + styles.underline}
            aria-label={copy.asn.locateASN}
          >
            {copy.asn.locateASN}
          </label>
          <p>{copy.asn.locateASNDesc}</p>
          <img src={pager} alt={"pager"} />
          <div className={styles.btnHolder}>
            <Button
              label={copy.asn.skipAddingBtn}
              buttonClick={this.redirectToHome}
              content={copy.asn.skipAddingBtn}
              variant="btn_primary "
              styles="login"
              className="skipAdding"
            />
            <Button
              label={copy.asn.linkPagerBtn}
              buttonClick={this.addASN}
              content={copy.asn.linkPagerBtn}
              variant="btn_primary "
              styles="login"
              className="linkPager"
            />
          </div>
        </div>
      </Wrapper>
    );
  }
}
