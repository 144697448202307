import React from "react";

class Icon extends React.Component {
  handleClick = e => {
    if (this.props.buttonClick) {
      this.props.buttonClick(this.props.paramVal);
      e.stopPropagation();
    }
  };

  render() {
    const { label, styles, variant, icon, content, value } = this.props;

    return (
      <span
        aria-label={label}
        className={styles + " " + variant + " " + icon}
        // {...(window.cordova
        //   ? { onTouchStart: this.handleClick }
        //   : { onClick: this.handleClick })}
        {...{ onClick: this.handleClick }}
      >
        <span>{content}</span>
        {value}
      </span>
    );
  }
}

export default Icon;
