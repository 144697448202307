import React, { Component } from "react";
// Layouts
import Accordion from "../../components/UI/Accordion/Accordion";
import Loader from "../../components/UI/Loading/Loading";
// Data
import copy from "../../assets/copy/copy.json";
// styles
import styles from "./QualificationTile.module.scss";

export default class QualificationTile extends Component {
  render() {
    return (
      <div className="box_default">
        <Accordion
          ref={this.props.refProp}
          button={true}
          label="Close Qualification list"
          content={copy.global.btnClose}
          variant="btn_outline"
          styles="greenTxt"
          buttonClick={this.props.getQuals}>
          {this.props.QualList === null || this.props.QualList === undefined ? (
            <div
              key="0001"
              label={copy.settings.profile.yourQualificationsTitle}>
              <Loader />
            </div>
          ) : this.props.QualList.length === 0 ? (
            <div
              key="0001"
              label={copy.settings.profile.yourQualificationsTitle}>
              <p>No qualifications found</p>
            </div>
          ) : (
            <div
              key="0001"
              label={copy.settings.profile.yourQualificationsTitle}>
              <ul className={styles.qual}>
                {this.props.QualList.map((item, index) => {
                  return <li key={index}>{item.name}</li>;
                })}
              </ul>
            </div>
          )}
        </Accordion>

        <p>{copy.settings.profile.yourQualificationsDesc}</p>
      </div>
    );
  }
}
