import moment from "moment";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";

// Sample Data
import times from "../../api/sampleData/times.json";
import TableCell from "./Table/TableCell";
import TableRow from "./Table/TableRow";
import Radio from "../../components/UI/Button/Radio";
import Icon from "../UI/Icon/Icon";
import { Dropdown } from "semantic-ui-react";
import { Requests } from "../../api/IdentityServerRequests/Requests";
import Loading from "../UI/Loading/Loading";
import copy from "../../assets/copy/copy.json";

class AvailabilityDashboard extends Component {
  constructor(props) {
    super(props);

    this.Requests = new Requests();
    require("moment/locale/en-au");

    this.state = {
      dropdownValuesQualification: [],
      qualificationData: [],
      peopleData: [],
      membersData: [],
      filteredMembersData: [],
      memberParam: "Type",
      thresholdType: "",
      dropdownOptions: [],
      filter: "Local",
      isLocal: true,
      date: moment().format("YYYY-MM-DD"),
      dateName: null,
      isLoading: true,
      showQualificationDetails: false,

      updateCountHeaderHeight: true,
      membersCountHeight: 0,
      qualsCountHeight: 0,
    };
  }

  componentDidMount() {
    this.scrollDashboard();

    if (this.props.pagingGroup) {
      this.getDashboardInfo(moment().format("YYYY-MM-DD"));
    }
  }

  componentDidUpdate = (prevProps) => {
    if (
      (prevProps.pagingGroup !== this.props.pagingGroup &&
        this.props.pagingGroup) ||
      prevProps.date !== this.props.date
    ) {
      this.getDashboardInfo(this.props.date);
    }

    if (this.state.updateCountHeaderHeight) {
      this.updateMemberCountHeaderHeight();
    }
  };

  scrollDashboard = () => {
    // * Gets todays date
    const today = moment(new Date());

    // * Gets the current hour
    const timeNow = today.format("HH") + ":00";

    // * Finds the cell of the current hour
    const currentCellTime = document.querySelectorAll(
      `[data-time="${timeNow}"]`
    )[0];

    // * Gets the cell's width
    const currentCellWidth = currentCellTime ? currentCellTime.offsetWidth : 0;
    let currentCellIndex = -1;

    // * Gets the table container
    const availabilityDashboardCont = document.querySelectorAll(
      ".availabilityDashboard__container"
    )[0];

    // * Get the current time's index from the list
    // do not include the 24:00 time for the table
    for (let index = 0; index < times.length - 1; index++) {
      const time = times[index];
      if (time.text === timeNow) {
        currentCellIndex = index;
        break;
      }
    }
    // * Scroll the table to the current spot
    availabilityDashboardCont.scrollLeft = currentCellWidth * currentCellIndex;
  };

  getDashboardInfo = (date) => {
    let apiDate = date !== null ? date : this.state.date;
    let filter = "Local";
    if (this.state.memberParam === "Type") {
      filter = this.state.filter;
    }

    this.setState({
      isLoading: true,
      date: apiDate,
      dateName: moment(apiDate, "YYYY-MM-DD").format("dddd, DD MMMM YYYY"),
    });
    this.Requests.callAPI(
      this.Requests.getPagingGroupAvailability,
      { skip: 0, take: 100, order: "ASC" },
      this.props.pagingGroup,
      apiDate,
      filter
    ).then((data) => {
      if (data && data.status && data.status === 200) {
        this.setState(
          {
            membersData: data.data,
            filteredMembersData: data.data,
            isLoading: false,
          },
          () => {
            this.scrollDashboard();
            let dropdownValuesQualification = [];
            // Preparing qualifications data
            this.Requests.callAPI(this.Requests.getUserQualifications).then(
              (data) => {
                if (data && data.status && data.status === 200) {
                  data.data.forEach((qualification) => {
                    if (qualification.agency === this.props.pagingGroupAgency) {
                      dropdownValuesQualification.push({
                        key: qualification.id,
                        text: qualification.name,
                        value: qualification.name,
                      });
                    }
                  });
                } else {
                  let ErrorMessage =
                    copy.admin.filter.filterQualsInfoAPIErrorMessage +
                    ` (Error #${copy.errorCodes.filterQualsInfoAPIErrorMessage})`;
                  // if (data && data.data && data.data.SASMessageClient) {
                  //   ErrorMessage = data.data.SASMessageClient;
                  // }
                  this.setState(
                    {
                      errorMessage: ErrorMessage,
                      userQualificationsOption: {},
                    },
                    () => {
                      setTimeout(
                        function () {
                          this.setState({ errorMessage: null });
                        }.bind(this),
                        5000
                      );
                    }
                  );
                }
              }
            );
            this.setState({
              dropdownValuesQualification: dropdownValuesQualification,
            });
          }
        );
      } else if (data && data.status && data.status === 401) {
      }
    });

    this.Requests.callAPI(
      this.Requests.getThresholdDayDetailed,
      this.props.pagingGroup,
      apiDate
    ).then((data) => {
      if (data && data.status && data.status === 200) {
        this.setState({
          thresholdType: data.data.thresholdType,
          peopleData: data.data.timeAvailable,
          updateCountHeaderHeight: true,
        });
      } else if (data && data.status && data.status === 401) {
        // error
      }
    });
  };

  toggleMemberParam(event) {
    // * Gets todays date
    this.setState({
      memberParam: event.currentTarget.value,
      filteredMembersData: this.state.membersData,
      filter: "Local",
    });
  }

  filterMembers(value) {
    this.setState(
      {
        filter: value,
        isLocal: this.state.memberParam === "Type" && value === "Local",
      },
      () => {
        if (this.state.memberParam === "Type") {
          this.getDashboardInfo(this.state.date);
        } else if (this.state.memberParam === "Qualification") {
          let filteredMembers = [];
          if (value.length === 0) {
            filteredMembers = this.state.membersData;
          } else {
            this.state.membersData.forEach((member) => {
              if (
                member.qualifications &&
                member.qualifications.some((r) => value.includes(r))
              ) {
                filteredMembers.push(member);
              }
            });
          }
          this.setState({ filteredMembersData: filteredMembers });
        }
      }
    );
  }

  handleQualificationsOnClick() {
    this.setState((prevState) => ({
      showQualificationDetails: !prevState.showQualificationDetails,
    }));
  }

  /**
   * This updates the height of the members available / qualifications required
   * row.
   */
  updateMemberCountHeaderHeight() {
    if (document.getElementById("membersAvailable")) {
      let countHeight =
        document.getElementById("membersAvailable").clientHeight;
      this.setState({
        membersCountHeight: countHeight,
        updateCountHeaderHeight: false,
      });
    } else if (document.getElementById("qualificationsRequired")) {
      let countHeight = document.getElementById(
        "qualificationsRequired"
      ).clientHeight;
      this.setState({
        qualsCountHeight: countHeight,
        updateCountHeaderHeight: false,
      });
    }
  }

  render() {
    // * Creates the table rows
    let tableContent = [];

    if (this.state.filteredMembersData.length > 0) {
      tableContent = this.state.filteredMembersData.map((data, dataIndex) => {
        return (
          <TableRow
            key={dataIndex}
            data={data}
            thresholdType={this.state.thresholdType}
          />
        );
      });
    }

    // * Creates the timestamps in table header
    // do not include the 24:00 time for the table
    const cellTimes = times.slice(0, times.length - 1);
    const timeCell = cellTimes.map((time, timeIndex) => {
      let _peopleData = [];
      this.state.peopleData.forEach((peopleData) => {
        if (peopleData.timeStart.substring(0, 5) === time.text) {
          _peopleData = peopleData;
        }
      });

      let _qualificationData = [];

      return (
        <TableCell
          key={timeIndex}
          peopleData={_peopleData}
          qualificationData={_qualificationData}
          memberParam={this.state.memberParam}
          thresholdType={this.state.thresholdType}
          type="HEADER__TIME"
          value={time}
          filter={this.state.filter}
          showQualificationDetails={this.state.showQualificationDetails}
          membersCountHeight={this.state.membersCountHeight}
          qualsCountHeight={this.state.qualsCountHeight}
        />
      );
    });

    // * Hard-coded values for Type
    let dropdownValuesType = [
      {
        key: 0,
        text: "Local / Home Branch",
        value: "Local",
      },
      {
        key: 1,
        text: "Region / Short Haul",
        value: "RegionShortHaul",
      },
      {
        key: 2,
        text: "State / Long Haul",
        value: "StateLongHaul",
      },
      {
        key: 3,
        text: "Interstate Deployment",
        value: "InterstateDeployment",
      },
      {
        key: 4,
        text: "IMT",
        value: "IMT",
      },
    ];

    if (this.props.agency === "AV") {
      dropdownValuesType.push({
        key: 5,
        text: "All",
        value: "All_AVOnly",
      });
    }

    let dropdownValuesQualification = [
      { key: 1, text: "No Qualifications Available", value: "None" },
    ];
    if (this.state.dropdownValuesQualification.length !== 0) {
      dropdownValuesQualification = this.state.dropdownValuesQualification;
    }

    return (
      <div className="availabilityDashboard">
        <div className="availabilityDashboard__header">
          <div className="-date">{this.state.dateName}</div>
          <div className="-radio">
            <ul>
              <li>
                <Radio
                  name="member"
                  label="Type"
                  value="Type"
                  checked={this.state.memberParam === "Type" ? "checked" : ""}
                  onChange={(event) => {
                    this.toggleMemberParam(event);
                  }}
                />
              </li>
              <li>
                <Radio
                  name="member"
                  label="Qualification"
                  value="Qualification"
                  checked={
                    this.state.memberParam === "Qualification" ? "checked" : ""
                  }
                  onChange={(event) => {
                    this.toggleMemberParam(event);
                  }}
                />
              </li>
            </ul>
          </div>
          <div className="-dropdown">
            <span>{this.state.memberParam}</span>

            {this.state.memberParam === "Type" ? (
              <Dropdown
                fluid
                selection
                placeholder={dropdownValuesType[0].text}
                options={dropdownValuesType}
                onChange={(event, { value }) => {
                  this.filterMembers(value);
                }}
                defaultValue={dropdownValuesType[0].value}
              />
            ) : (
              <Dropdown
                fluid
                selection
                placeholder={
                  this.state.dropdownValuesQualification.length !== 0
                    ? "ALL"
                    : "N/A"
                }
                options={dropdownValuesQualification}
                multiple={this.state.dropdownValuesQualification.length !== 0}
                disabled={this.state.dropdownValuesQualification.length === 0}
                onChange={(event, { value }) => {
                  this.filterMembers(value);
                }}
              />
            )}
          </div>
        </div>

        <div
          className="availabilityDashboard__container"
          role="region"
          aria-labelledby="caption"
          tabIndex="0">
          {this.state.isLoading ? (
            <div className="dashboardLoadingContainer">
              <div className="loadingSidebar" />
              <div className="loadingIcon">
                <div className="loadingHeader" />
                <Loading />
              </div>
            </div>
          ) : this.state.filteredMembersData.length === 0 ? (
            <div className="noFilterContainer">
              <div className="loadingSidebar" />
              <div className="noFilter">{copy.availability.noFilterDesc}</div>
            </div>
          ) : (
            <table>
              <thead>
                <tr>
                  <th>
                    {this.state.filter === "Local" &&
                      this.state.memberParam === "Type" &&
                      (this.state.thresholdType === "ByPeople" ? (
                        <ul>
                          <li>&nbsp;</li>
                          <li id="membersAvailable">Members available</li>
                          <li>Members required</li>
                        </ul>
                      ) : (
                        this.state.thresholdType === "ByQualification" && (
                          <ul>
                            <li>&nbsp;</li>
                            <li
                              id="qualificationsRequired"
                              onClick={() =>
                                this.handleQualificationsOnClick()
                              }>
                              Qualifications required
                              <Icon
                                label="attending"
                                content=""
                                variant=""
                                styles=""
                                icon="icon_arrowDownSolid"
                              />
                            </li>
                            <li
                              style={{
                                display: this.state.showQualificationDetails
                                  ? "block"
                                  : "none",
                              }}>
                              Outstanding threshold requirements
                            </li>
                          </ul>
                        )
                      ))}
                  </th>
                  {timeCell}
                </tr>
              </thead>
              <tbody>{tableContent}</tbody>
            </table>
          )}
        </div>

        <div className="availabilityDashboard__legend">
          <ul>
            <li className="availabilityDashboard__level">
              <div className="availabilityDashboard__icon">
                <i className="icon"></i>
                Paging group off
              </div>
            </li>

            <li className="availabilityDashboard__level -unavailable">
              Unavailable
            </li>
            {this.props.pagingGroupAgency !== "CFA" && this.state.isLocal && (
              <li className="availabilityDashboard__level -emergency">
                Emergency
              </li>
            )}
            {this.props.pagingGroupAgency !== "CFA" && this.state.isLocal && (
              <li className="availabilityDashboard__level -non-emergency">
                Non-Emergency
              </li>
            )}
            {this.props.pagingGroupAgency !== "CFA" && this.state.isLocal && (
              <li className="availabilityDashboard__level -admin">Admin</li>
            )}
            <li className="availabilityDashboard__level -notLocal">
              Available
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default withRouter(AvailabilityDashboard);
