import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "./Accordion.module.scss";
import Toggle from "../Button/Toggle";
import Radio from "../Button/Radio";
import copy from "../../../assets/copy/copy.json";

class ToggleSection extends Component {
  static propTypes = {
    children: PropTypes.instanceOf(Object).isRequired,
    isOpen: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    handleClick: PropTypes.func.isRequired
  };

  handleClick = () => {
    this.props.handleClick(this.props.label);
  };

  render() {
    const {
      handleClick,
      props: { isOpen, logo, label, txt, subtxt, keyValue, navigation }
    } = this;

    let classOpen = isOpen ? " open" : " close";

    return (
      <div className="setPosition">
        <div key={keyValue} className={styles.Accordion_header + classOpen}>
          <div className={"Accordion_content " + (logo ? "logo" : "")}>
            {logo ? (
              <div className="content_with_logo">
                <div className="logo_header">
                  <div className="content_logo">
                    <span className={styles.logo_holder}>
                      <img src={logo} alt="logo" />
                    </span>
                    {label}
                  </div>
                  <Toggle
                    buttonClick={handleClick}
                    variant="toggle"
                    checked={isOpen && "true"}
                    label="open close"
                  />
                </div>
                {navigation && label !== "AV" && (
                  <div
                    className={
                      isOpen
                        ? styles.navigationPreferenceOpen
                        : styles.navigationPreference
                    }
                  >
                    <p className={styles.txt}>Navigate me to</p>
                    <div className={"btn_holder " + styles.buttons}>
                      {label === "CFA" ? (
                        <Radio
                          name={label}
                          onClick={handleClick}
                          label={copy.settings.profile.CFAstation}
                          id={"station"}
                          paramVal={"station or unit"}
                          checked={true}
                        />
                      ) : (
                        <Radio
                          name={label}
                          onClick={handleClick}
                          label={copy.settings.profile.SESunit}
                          id={"station"}
                          paramVal={"station or unit"}
                          checked={true}
                        />
                      )}
                      <Radio
                        name={label}
                        onClick={handleClick}
                        label={copy.settings.profile.event}
                        id={"event"}
                        paramVal={"event"}
                      />
                    </div>
                    <p className={styles.subtxt}>
                      {copy.settings.profile.navigateMeTo}
                    </p>
                  </div>
                )}

                {txt && <p className={styles.txt}>{txt}</p>}
                {subtxt && <p className={styles.subtxt}>{subtxt}</p>}
              </div>
            ) : (
              <div>
                {label}
                {txt && <p className={styles.txt}>{txt}</p>}
                {subtxt && <p className={styles.subtxt}>{subtxt}</p>}
              </div>
            )}

            <div className="sronly">
              {!isOpen && <span>Accordion section is closed</span>}
              {isOpen && <span>Accordion section is open</span>}
            </div>
          </div>
          {logo ? null : (
            <Toggle
              buttonClick={handleClick}
              variant="toggle"
              checked={isOpen && "true"}
              label="open close"
            />
          )}
        </div>
        {isOpen && (
          <div className={styles.Accordion_content}>{this.props.children}</div>
        )}
      </div>
    );
  }
}

export default ToggleSection;
