import React from "react";
import Wrapper from "../../hoc/Wrapper/Wrapper";
import App from "../Home/HomeView";

export default class PagerHistoryView extends React.Component {
  // Call to Pager History API occurs in the home page
  // getCurrentPagerMessages
  // Pager History takes everything, hence does not use dateTimeAfter

  render() {
    return (
      <Wrapper>
        <App {...this.props} pagerHistory />
      </Wrapper>
    );
  }
}
