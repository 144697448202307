import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "whatwg-fetch";
import React from "react";
import ReactDOM from "react-dom";
import Routes from "./Routes";
import { HashRouter } from "react-router-dom";
import Wrapper from "./hoc/Wrapper/Wrapper";
import "./index.scss";
import * as serviceWorker from "./serviceWorker";

import { logger, LoggerLevel } from "./utils";

function startApp() {
  ReactDOM.render(
    <Wrapper>
      <HashRouter>
        <Routes />
      </HashRouter>
    </Wrapper>,
    document.getElementById("root")
  );
}

if (!window.cordova) {
  startApp();
} else {
  document.addEventListener("deviceready", startApp, false);
}

// Set the logging level for the app
logger.setLogLevel(LoggerLevel.ERROR);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
