import React from "react";
import Wrapper from "../../hoc/Wrapper/Wrapper";
import { Requests } from "../../api/IdentityServerRequests/Requests";
import Loader from "../../components/UI/Loading/Loading";
import { format } from "date-fns";
import ErrorBanner from "../../hoc/ErrorBanner/ErrorBanner";
// Layout
import Tabs from "../../components/UI/Tabs/Tabs";
// UIs & styles
import Button from "../../components/UI/Button/Button";
import { Dropdown } from "semantic-ui-react";
// Page Component
import Calendar from "../../components/Availability/Calendar";
//import GroupThreshold from "../../components/Availability/GroupThreshold";
import HalfHrView from "../../components/Availability/HalfHourView";
import ResultList from "../../components/Availability/ResultList";
import QualificationView from "./QualificationView";
import TypeView from "./TypeView";
import AvailabilityOverview from "./AvailabilityOverview";

import AvailabilityDashboard from "../../components/AvailabilityDashboard/AvailabilityDashboard";

import styles from "./Availability.module.scss";

// Requests for API calls
import copy from "../../assets/copy/copy.json";
// sample data
// import results from "../../api/sampleData/availability/availabilityResults.json";
// import times from "../../api/sampleData/availability/availability-hrs.json";
// import dayOverview from "../../api/sampleData/availability/availability-overview-day.json";
// import thresholdWeek from "../../api/sampleData/availability/availability-group.json";
// import thresholdMultiWeek from "../../api/sampleData/availability/availability-group2.json";

import MetIcon from "../../assets/img/icons/Threshold-Met.svg";
import NotMetIcon from "../../assets/img/icons/Threshold-NotMet.svg";

/*
 * This is the paging group availability threshold section of Availability "dashboard",
 * It is the first of four tabs
 *
 * It shows paging group threshold met or not met
 * based off the criteria set in admin paging group details
 * ui/sasApp/sasAppReact/src/views/Admin/GroupDetailsView.jsx
 *
 * If nothing has been set, it will show not set in the title and description
 */

export default class AvailabilityDBView extends React.Component {
  constructor(props) {
    super(props);
    this.Requests = new Requests();
    this.state = {
      failedAPI: false,
      errorCode: null,
      width: window.innerWidth,
      ready: false,
      paramsId: null,
      currentTab: "dashboard",
      currentTabVal: null,
      currentTabNumber: null,
      details: {
        start: null,
      },
      //start: null,
      callbackDate: null,
      date: null,
      day: null, // half hr view data
      dayLoading: false,
      results: null, // detail results
      resultsLoading: false,
      //dayOverview: dayOverview,
      //thresholdWeek: thresholdWeek,
      //thresholdMultiWeek: thresholdMultiWeek,
      userPagingGroupsOption: [],
      userPagingGroupsOptionNoAV: [],
      pagingGroupsPartOfWithAvailability: [],
      onQualificationsTab: false, // If on Qualifications tab, no AV paging groups should be shown
      pagingGroupAgency: null,
      pagingGroup: null, // If false, then the user has no paging groups
      defaultPagingGroup: null,
      thresholdReached: false,
      thresholdType: "ByPeople",
      thresholdByPeopleCount: 0,
      qualList: null,
      requiredSkill1: {
        id: null,
        name: null,
        num: 0,
      },
      requiredSkill2: {
        id: null,
        name: null,
        num: 0,
      },
      requiredSkill3: {
        id: null,
        name: null,
        num: 0,
      },
      requiredSkill4: {
        id: null,
        name: null,
        num: 0,
      },
      requiredSkill5: {
        id: null,
        name: null,
        num: 0,
      },
    };
  }

  componentDidMount() {
    window.addEventListener("orientationchange", this.handleWindowSizeChange);
    window.addEventListener("resize", this.handleWindowSizeChange);
    window.sessionStorage.setItem("redirectHomeFromEvent", "");
    this.getPagingGroupsWithAvailability();

    if (this.props.match) {
      if (this.props.match.params) {
        this.updateTab(this.props.match.params.tab);
      }
    }
    // This finds the current time to set the default load
    let time = new Date();

    // round time to the current half-hr block
    if (time.getMinutes() >= 0 && time.getMinutes() < 30) {
      time.setMinutes(0);
    } else {
      time.setMinutes(30);
    }

    time = format(time, "HH:mm");

    this.setState({
      details: {
        start: time,
      },
    });
  }

  // This function checks which tab to show
  componentDidUpdate(prevProps) {
    if (this.props.match) {
      if (this.props.match.params) {
        if (
          typeof this.props.match.params.tab !== "undefined" &&
          typeof prevProps.match.params.tab !== "undefined" &&
          this.props.match.params.tab !== prevProps.match.params.tab
        ) {
          this.updateTab(this.props.match.params.tab);
        }
      }
    }
  }
  /**
   * This function will be called whenever a new tab is clicked (which leads to the URL changing)
   * It will update the current tab
   */
  updateTab(taburl) {
    const isMobile = this.state.width <= 1023;

    if (taburl === copy.taburls.threshold) {
      if (isMobile) {
        this.setState({
          currentTab: copy.availability.dashboard,
          currentTabNumber: copy.tabIndices.dashboard,
          onQualificationsTab: false,
        });
      } else {
        this.setState({
          currentTab: copy.availability.threshold,
          currentTabNumber: copy.tabIndices.threshold,
          onQualificationsTab: false,
        });
      }
      // } else if (taburl === copy.taburls.qualification) {
      //   this.setState({
      //     currentTab: copy.availability.qualification,
      //     currentTabNumber: copy.tabIndices.qualification,
      //     onQualificationsTab: true,
      //   });
      // } else if (taburl === copy.taburls.type) {
      //   let userRoleAgency = this.props.userRoleAgency.filter((role) => {
      //     return role.split("_")[0] === "Administrator";
      //   });
      //   // If the user is only part of AV or is not a superuser and only an admin of AV, then they cannot see the Qualification tab
      //   if (
      //     (this.props.userAgencies.length === 1 &&
      //       this.props.userAgencies.indexOf("AV") !== -1) ||
      //     (this.props.userRoleAgency.indexOf("SuperUser") === -1 &&
      //       userRoleAgency === ["Administrator_AV"])
      //   ) {
      //     this.setState({
      //       currentTab: copy.availability.type,
      //       currentTabNumber: copy.tabIndices.type - 1,
      //       onQualificationsTab: false,
      //     });
      //   } else {
      //     this.setState({
      //       currentTab: copy.availability.type,
      //       currentTabNumber: copy.tabIndices.type,
      //       onQualificationsTab: false,
      //     });
      //   }
    } else if (taburl === copy.taburls.overview) {
      this.setState({
        currentTab: copy.availability.overview,
        currentTabNumber: copy.tabIndices.overview,
        onQualificationsTab: false,
      });
    } else if (taburl === copy.taburls.calendar) {
      this.setState({
        currentTab: copy.availability.calendar,
        currentTabNumber: copy.tabIndices.calendar,
        onQualificationsTab: false,
      });
    } else {
      this.props.history.push({
        pathname: "/*",
        state: { errorMessage: copy.error_page.errorMessage },
      });
    }
  }

  /**
   * This function is used to prepare the data for the paging groups filter
   */
  getPagingGroupsWithAvailability = () => {
    // Fetch all the paging groups the user is a part of and has availability enabled
    let body = {
      availabilityEnabled: true,
    };
    // Request to get an array of the agencies the user belongs to
    this.Requests.callAPI(this.Requests.postUserPagingGroups, body).then(
      (data) => {
        if (data && data.status && data.status === 200) {
          let pagingGroupsPartOfWithAvailability = {};
          pagingGroupsPartOfWithAvailability.all = [];
          this.props.userAgencies.forEach((item) => {
            pagingGroupsPartOfWithAvailability[item] = [];
          });
          // This sorts all paging groups by their respective agencies into the pagingGroupsPartOf object.
          data.data.forEach((item) => {
            if (
              item.agency &&
              pagingGroupsPartOfWithAvailability[item.agency]
            ) {
              pagingGroupsPartOfWithAvailability[item.agency].push({
                key: item.number,
                text: item.name,
                value: { number: item.number, agency: item.agency },
              });
              // Paging groups with no agency are placed in the 'all' child.
            } else if (!item.agency) {
              pagingGroupsPartOfWithAvailability.all.push({
                key: item.number,
                text: item.name,
                value: { number: item.number, agency: null },
              });
            }
          });
          this.setState(
            {
              pagingGroupsPartOfWithAvailability:
                pagingGroupsPartOfWithAvailability,
            },
            () => {
              // Now prepare the paging group data
              this.preparePagingGroupData();
            }
          );
        } else {
          let ErrorMessage =
            copy.availability.gettingAvailabilityGroupsErrorMessage;
          this.setState(
            {
              pagingGroupsPartOfWithAvailability: false,
              errorMessage: ErrorMessage,
            },
            () => {
              setTimeout(
                function () {
                  this.setState({ errorMessage: null });
                }.bind(this),
                5000
              );
            }
          );
        }
      }
    );
  };

  /**
   * This function is used to prepare the data for the paging groups filter
   */
  preparePagingGroupData = () => {
    // Change in R4 - all CFA and SES users can use dashboard
    let adminAgencies = ["CFA", "SES"];

    // // SPECIAL CASE
    // // If the user is in AV, then they should be able to see all paging groups regardless of their permission
    // if (
    //   this.props.userAgencies.indexOf("AV") !== -1 &&
    //   adminAgencies.indexOf("AV") === -1
    // ) {
    //   adminAgencies.push("AV");
    // }

    // Getting all paging groups the user has access to
    let pagingGroups = [];
    let pagingGroupsNoAV = [];
    adminAgencies.forEach((agency) => {
      // If the user has permission to view paging groups of that agency, add the paging groups to the filter
      if (this.state.pagingGroupsPartOfWithAvailability) {
        if (
          this.state.pagingGroupsPartOfWithAvailability[agency] &&
          this.state.pagingGroupsPartOfWithAvailability[agency].length > 0
        ) {
          pagingGroups = pagingGroups.concat(
            this.state.pagingGroupsPartOfWithAvailability[agency]
          );
          if (agency !== "AV") {
            pagingGroupsNoAV = pagingGroupsNoAV.concat(
              this.state.pagingGroupsPartOfWithAvailability[agency]
            );
          }
        }
      }
    });
    if (this.props.pagingGroupsOwned) {
      Object.entries(this.props.pagingGroupsOwned).forEach((entry) => {
        // If user is not admin of the agency but owns paging groups of that agency, add the paging groups they own to the filter
        // Check to see if the paging group the user is an owner has availabilityenabled
        if (adminAgencies.indexOf(entry[0]) === -1 && entry[1].length > 0) {
          Object.entries(entry[1]).forEach((group) => {
            if (group[1] && group[1].availabilityenabled === "true") {
              // Adding paging group to filter
              pagingGroups = pagingGroups.concat(group[1]);

              if (entry[0] !== "AV") {
                pagingGroupsNoAV = pagingGroupsNoAV.concat(group[1]);
              }
            }
          });
        }
      });

      let defaultPagingGroup = pagingGroups[0] ? pagingGroups[0].value : null;
      let pagingGroup = pagingGroups[0] ? pagingGroups[0].value.number : null;
      let pagingGroupAgency = null;
      const pagingGroupID = parseInt(
        window.localStorage.getItem("PagingGroupID")
      );
      const pagingGroupAgencyID =
        window.localStorage.getItem("PagingGroupAgency");
      if (pagingGroupID && pagingGroupAgencyID) {
        defaultPagingGroup = {
          agency: pagingGroupAgencyID,
          number: pagingGroupID,
        };
        pagingGroup = pagingGroupID;
        pagingGroupAgency = pagingGroupAgencyID;
      }
      // This puts the results in the paging group dropdown
      this.setState(
        {
          userPagingGroupsOption: pagingGroups,
          userPagingGroupsOptionNoAV: pagingGroupsNoAV,
          defaultPagingGroup: defaultPagingGroup,
          pagingGroupAgency: pagingGroupAgency,
        },
        () => {
          window.localStorage.removeItem("PagingGroupID");
          window.localStorage.removeItem("PagingGroupAgency");
          // this selects a paging group from the dropdown and activates the calendar
          this.setState(
            {
              pagingGroup: pagingGroup,
            },
            () => {
              // Only call these functions if the user has paging groups
              if (this.state.pagingGroup) {
                // This loads the threshold criterias set in the admin paging group details section
                this.getThresholdRequirements();

                // This acts as a calendar click event, loading today's timeslots
                let date = new Date();
                const calendarDate =
                  window.localStorage.getItem("dashboardDate");
                if (calendarDate) {
                  date = new Date(calendarDate);
                }
                window.localStorage.removeItem("dashboardDate");
                this.calendarCallbackFunction(date, true);

                // this checks if the user is viewing this on a mobile
                const isMobile = this.state.width <= 1023;
                if (!isMobile) {
                  // If this is NOT on a mobile, then the details of the current time need to be loaded as well
                  this.setState({ ready: true }, () => {
                    this.dayCallbackFunction(this.state.details);
                  });
                }
              }
            }
          );
        }
      );
    }
  };

  // This function loads the threshold criterias set in the admin paging group details section
  // It actually gets all the information on the selected paging group, but we only need the threshold criterias
  // And there is no other APIs with that information

  getThresholdRequirements = () => {
    this.Requests.callAPI(
      this.Requests.getPagingGroup,
      this.state.pagingGroup
    ).then((data) => {
      if (data && data.status && data.status === 200) {
        if (data.data.settings) {
          if (data.data.settings.thresholdType) {
            let type = data.data.settings.thresholdType;
            // This checks to see if the threshold is based off People count

            if (type === "ByPeople") {
              // The default is by people
              // No need to change the the state for thresholdType
              if (data.data.settings.thresholdByPeopleCount) {
                this.setState({
                  thresholdByPeopleCount:
                    data.data.settings.thresholdByPeopleCount,
                  thresholdType: data.data.settings.thresholdType,
                  thresholdNotSet: false,
                });
              }
            } else {
              // If this is not set to people, it's set to qualification
              // Change the type to qualification
              this.setState({
                thresholdType: data.data.settings.thresholdType,
              });

              if (data.data.settings.qualificationPaging.length !== 0) {
                this.setState({
                  qualList: data.data.settings.qualificationPaging,
                  thresholdNotSet: false,
                });
              } else {
                this.setState({
                  thresholdNotSet: true,
                });
              }
            }
            this.setState({ failedAPI: false });
          }
        }
      } else {
        let ErrorMessage =
          copy.availability.getPagingGroupThresholdAPIErrorMessage +
          ` (Error #${copy.errorCodes.getPagingGroupThresholdAPIErrorMessage})`;
        let ErrorCode = copy.errorCodes.getPagingGroupThresholdAPIErrorMessage;
        // if (data && data.data && data.data.SASMessageClient) {
        //   ErrorMessage = data.data.SASMessageClient;
        // }
        this.setState(
          { errorMessage: ErrorMessage, failedAPI: true, errorCode: ErrorCode },
          () => {
            setTimeout(
              function () {
                this.setState({ errorMessage: null });
              }.bind(this),
              5000
            );
          }
        );
      }
    });
  };

  requiredList() {
    let requiredList = [];
    if (this.state.qualList) {
      for (let i = 0; i < this.state.qualList.length; i++) {
        if (
          this.state.qualList[i].orderValue === 1 ||
          this.state.qualList[i].orderValue === 2 ||
          this.state.qualList[i].orderValue === 3 ||
          this.state.qualList[i].orderValue === 4
        ) {
          requiredList.push(
            <Wrapper>
              <span>
                {this.state.qualList[i].thresholdCount}
                {" x "}
                {this.state.qualList[i].qualification.name}
              </span>
            </Wrapper>
          );
        }
      }
    }

    return <p>{requiredList}</p>;
  }
  selectPagingGroup = (value) => {
    // this reset data shown
    this.setState(
      { thresholdNotSet: null, pagingGroup: null, day: null, results: null },
      () => {
        // set new paging group
        this.setState(
          {
            pagingGroup: value.number,
            pagingGroupAgency: value.agency,
            defaultPagingGroup: value,
          },
          () => {
            this.getThresholdRequirements();
          }
        );
      }
    );
    // This send the paging group id to the calendar component to render the calendar view
  };

  /**
   * Called whenever a new date on the calendar is clicked
   * @param {bool} initialSetup - whether the function was called when the page first loads
   */
  calendarCallbackFunction = (date, initialSetup) => {
    const isMobile = this.state.width <= 1023;
    if (!initialSetup && isMobile) {
      if (this.state.defaultPagingGroup) {
        window.localStorage.setItem(
          "PagingGroupID",
          this.state.defaultPagingGroup.number
        );
        window.localStorage.setItem(
          "PagingGroupAgency",
          this.state.defaultPagingGroup.agency
        );
      }
      window.localStorage.setItem("dashboardDate", date);
      this.props.history.push({
        pathname: "/availability/threshold",
        state: { errorMessage: copy.error_page.errorMessage },
      });
    } else {
      //reset
      this.setState({
        day: null,
        dayLoading: true,
        results: null,
      });
      // Clear the previously selected time slot whenever a new date is clicked,
      // only if this function was not called as as a result of page initialisation
      if (!initialSetup) {
        this.setState({
          details: {
            start: null,
          },
        });
      }
      let formattedDate = format(date, "iiii dd/MM/yyyy");
      let myCallBackDate = format(date, "yyyy-MM-dd");

      this.setState({
        date: formattedDate,
        callbackDate: myCallBackDate,
      });

      this.Requests.callAPI(
        this.Requests.getThresholdDay,
        this.state.pagingGroup,
        myCallBackDate
      ).then((data) => {
        if (data && data.status && data.status === 200) {
          // Get availability for the month
          this.setState({ day: data.data.timeAvailable, dayLoading: false });
        } else {
          let ErrorMessage =
            copy.availability.getPaginGroupDayThresholdAvailAPIErrorMessage +
            ` (Error #${copy.errorCodes.getPaginGroupDayThresholdAvailAPIErrorMessage})`;
          let ErrorCode =
            copy.errorCodes.getPaginGroupDayThresholdAvailAPIErrorMessage;
          // if (data && data.data && data.data.SASMessageClient) {
          //   ErrorMessage = data.data.SASMessageClient;
          // }
          this.setState(
            {
              errorMessage: ErrorMessage,
              dayLoading: false,
              errorCode: ErrorCode,
            },
            () => {
              setTimeout(
                function () {
                  this.setState({ errorMessage: null });
                }.bind(this),
                5000
              );
            }
          );
        }
      });
    }
  };

  dayCallbackFunction = (childData) => {
    this.setState({
      results: null,
      resultsLoading: true,
      details: { start: childData.start },
    });

    let details = {
      date: this.state.callbackDate,
      timeStart: childData.start,
    };

    this.Requests.callAPI(
      this.Requests.getThresholdDetails,
      this.state.pagingGroup,
      details
    ).then((data) => {
      if (data && data.status && data.status === 200) {
        // Get availability for the month
        this.setState({ results: data.data, resultsLoading: false }, () => {
          if (this.state.thresholdType === "ByPeople") {
            if (
              this.state.thresholdByPeopleCount <=
              this.state.results.available.length
            ) {
              this.setState({ thresholdReached: true });
            } else {
              this.setState({ thresholdReached: false });
            }
          } else {
            if (this.state.results.missingQualifications.length === 0) {
              this.setState({ thresholdReached: true });
            } else {
              this.setState({ thresholdReached: false });
            }
          }
        });
      } else {
        let ErrorMessage =
          copy.availability.getPagingGroupTimeSlotAvailAPIErrorMessage +
          ` (Error #${copy.errorCodes.getPagingGroupThresholdTimeSlotAvailAPIErrorMessage})`;
        let ErrorCode =
          copy.errorCodes.getPagingGroupThresholdTimeSlotAvailAPIErrorMessage;
        // if (data && data.data && data.data.SASMessageClient) {
        //   ErrorMessage = data.data.SASMessageClient;
        // }
        this.setState(
          {
            errorMessage: ErrorMessage,
            resultsLoading: false,
            errorCode: ErrorCode,
          },
          () => {
            setTimeout(
              function () {
                this.setState({ errorMessage: null });
              }.bind(this),
              5000
            );
          }
        );
      }
    });
  };

  renderMissingQual() {
    if (this.state.results.missingQualifications.length > 0) {
      return this.state.results.missingQualifications.map((skill) => (
        <span className={styles.skill}>
          {skill.thresholdDeviation + " " + skill.name}
        </span>
      ));
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
    window.removeEventListener(
      "orientationchange",
      this.handleWindowSizeChange
    );
  }

  handleWindowSizeChange = () => {
    this.setState({
      width: window.innerWidth,
    });
    if (this.props.match) {
      if (this.props.match.params) {
        if (typeof this.props.match.params.tab !== "undefined") {
          this.updateTab(this.props.match.params.tab);
        }
      }
    }
  };

  closeDay = () => {
    this.setState({ day: null, results: null });
  };
  closeDetails = () => {
    this.setState({ results: null });
  };

  handleRefresh = () => {
    // remember paging group selected
    let pagingGroup = this.state.pagingGroup;

    // Only call these functions if the user has paging groups
    if (pagingGroup) {
      // reset all values to refresh components
      this.setState({ pagingGroup: null }, () => {
        // show the calendar
        this.setState({ pagingGroup: pagingGroup }, () => {
          if (this.state.day !== null) {
            // show the half hr blocks
            let selectedDay = new Date(this.state.callbackDate);
            this.calendarCallbackFunction(selectedDay);
            if (this.state.results !== null) {
              // show the results
              let selectedTime = this.state.details;
              this.dayCallbackFunction(selectedTime);
            }
          }
        });
      });
    }
  };

  handleTabClick = () => {
    if (this.state.width <= 1023 && this.state.defaultPagingGroup) {
      window.localStorage.setItem(
        "PagingGroupID",
        this.state.defaultPagingGroup.number
      );
      window.localStorage.setItem(
        "PagingGroupAgency",
        this.state.defaultPagingGroup.agency
      );
    }
  };

  /**
   * Handle refresh of page when API call fails for Threshold tab in Mobile
   */
  handleThresholdViewRefresh = () => {
    this.getThresholdRequirements();
  };

  // Render the Threshold tab for mobile
  renderThresholdViewMobile = () => {
    return this.state.failedAPI ? (
      // Display error message and refresh button if API call fails
      <div
        content={copy.availability.threshold}
        taburl={copy.taburls.threshold}
      >
        <h4>
          <span className="noMessages">
            {this.state.errorCode
              ? copy.settings.availability.errorInAvailabilityFetch +
                ` (Error #${this.state.errorCode})`
              : copy.settings.availability.errorInAvailabilityFetch}
            <div>
              <Button
                label="Refresh"
                content={copy.global.btnRefresh}
                variant="btn_solid"
                styles="greyBg"
                icon="icon_refresh"
                buttonClick={this.handleThresholdViewRefresh}
              />
            </div>
          </span>
        </h4>
      </div>
    ) : (
      <div
        content={copy.availability.threshold}
        taburl={copy.taburls.threshold}
      >
        {this.state.userPagingGroupsOptionNoAV &&
        this.state.userPagingGroupsOptionNoAV.length === 0 ? (
          this.state.pagingGroup !== null ? (
            <Wrapper>
              <ErrorBanner
                isVisible={this.state.errorMessage ? true : false}
                ErrorMessage={this.state.errorMessage}
              />
              <h4>
                <span className="noMessages">
                  {copy.availability.noPagingGroupsWithAvailability}
                </span>
              </h4>
            </Wrapper>
          ) : null
        ) : (
          <div>
            <div className={styles.refreshButtonWrapper}>
              <Button
                label="Refresh"
                content={copy.global.btnRefresh}
                variant="btn_solid"
                styles="greyBg"
                icon="icon_refresh"
                buttonClick={this.handleRefresh}
              />
            </div>

            <div className="col-one-third">
              {this.state.pagingGroup !== null ? (
                <div className={styles.dropdownHolder}>
                  <span className={styles.title}>REVIEW AVAILABILITY OF</span>

                  <Dropdown
                    placeholder="Paging Group"
                    fluid
                    selection
                    search
                    options={
                      this.state.onQualificationsTab
                        ? this.state.userPagingGroupsOptionNoAV
                        : this.state.userPagingGroupsOption
                    }
                    onChange={(e, { value }) => this.selectPagingGroup(value)}
                    defaultValue={
                      this.state.defaultPagingGroup
                        ? this.state.defaultPagingGroup
                        : this.state.userPagingGroupsOption[0].value
                    }
                  />
                </div>
              ) : (
                <Loader />
              )}
              {this.state.results ? (
                <Wrapper>
                  <div className="box_default">
                    <div className={styles.AvailHeader}>
                      <h2>{this.state.date}</h2>
                      <Button
                        icon=""
                        label={copy.global.btnBack}
                        content={copy.global.btnBack}
                        variant={styles.btn_back}
                        styles="btn_secondary"
                        buttonClick={this.closeDetails}
                      />
                    </div>
                    <div className="Sub-Section-titles">
                      <h3>AT {this.state.details.start}</h3>
                      <span>
                        {this.state.results.available.length}
                        {copy.availability.AVAILABLE}
                      </span>
                      <span>
                        {this.state.results.unavailable.length}
                        {copy.availability.UNAVAILABLE}
                      </span>
                    </div>
                    {this.state.results.missingQualifications && (
                      <div
                        className={[
                          styles.missingQuail,
                          this.state.thresholdReached === true &&
                            styles.reached,
                          "lightHighlight",
                        ].join(" ")}
                      >
                        {this.state.thresholdReached === true ? (
                          <Wrapper>
                            <img
                              className={styles.icon}
                              src={MetIcon}
                              alt="Threshold Met"
                            />
                            <p>{copy.availability.metMsg}</p>
                          </Wrapper>
                        ) : (
                          <Wrapper>
                            {this.state.thresholdType === "ByPeople" ? (
                              <Wrapper>
                                <img
                                  className={styles.icon}
                                  src={NotMetIcon}
                                  alt="Threshold Has Not been Met"
                                />
                                <p>
                                  {this.state.thresholdByPeopleCount -
                                    this.state.results.available.length}{" "}
                                  members needed
                                </p>
                              </Wrapper>
                            ) : (
                              <Wrapper>
                                <img
                                  className={styles.icon}
                                  src={NotMetIcon}
                                  alt="Threshold Has Not been Met"
                                />
                                <p>
                                  {copy.availability.missingQual}
                                  {this.renderMissingQual()}
                                </p>
                              </Wrapper>
                            )}
                          </Wrapper>
                        )}
                      </div>
                    )}
                    <ResultList
                      results={this.state.results}
                      timeblock={this.state.start}
                    />
                  </div>
                </Wrapper>
              ) : this.state.resultsLoading ? (
                <div className={styles.loadingContainer}>
                  <Loader />
                </div>
              ) : this.state.day ? (
                <Wrapper>
                  <div className="box_default">
                    <div className={styles.AvailHeader}>
                      <div>
                        <h2>{this.state.date}</h2>
                        <Button
                          icon=""
                          label="Calendar"
                          content="Calendar"
                          variant="btn_back"
                          styles="btn_secondary"
                          buttonClick={this.closeDay}
                        />
                      </div>
                      <div className={styles.iconKey}>
                        <span>Icons below show if threshold is </span>
                        <span className={styles.Achieved}>Achieved</span> or
                        <span className={styles.NotAchieved}>
                          {copy.availability.notMet}
                        </span>
                        <div className={styles.updatesDesc}>
                          {copy.availability.thresholdUpdateDesc}
                        </div>
                      </div>
                    </div>
                    <HalfHrView
                      overview={false}
                      times={this.state.day}
                      date={this.state.date}
                      parentCallback={this.dayCallbackFunction}
                      target={this.state.details.start}
                    />
                  </div>
                </Wrapper>
              ) : (
                <div className="box_default">
                  <div className={styles.AvailHeader}>
                    {this.state.thresholdNotSet === true ? (
                      <Wrapper>
                        <h2>Availability threshold not set</h2>
                        <p>{copy.availability.thresholdNotSet}</p>
                      </Wrapper>
                    ) : (
                      <Wrapper>
                        {this.state.thresholdType === "ByPeople" ? (
                          <Wrapper>
                            <h2>Availability by number of people</h2>
                            <p>
                              {this.state.thresholdByPeopleCount} members
                              required to meet threshold
                            </p>
                          </Wrapper>
                        ) : (
                          <Wrapper>
                            <h2>Availability by qualifications</h2>
                            {this.requiredList()}
                          </Wrapper>
                        )}
                      </Wrapper>
                    )}
                    <div className={styles.iconKey}>
                      <span>Icons below show if threshold is</span>
                      <span className={styles.Achieved}>Achieved</span> or
                      <span className={styles.NotAchieved}>
                        {copy.availability.notMet}
                      </span>
                      <div className={styles.updatesDesc}>
                        {copy.availability.thresholdUpdateDesc}
                      </div>
                    </div>
                  </div>

                  {this.state.pagingGroup ? (
                    <Calendar
                      type="threshold"
                      viewOnlyMode={true}
                      overviewParentCallback={this.calendarCallbackFunction}
                      id={this.state.pagingGroup}
                      selectedDate={this.state.callbackDate}
                    />
                  ) : (
                    <p className="title">copy.availability.thresholdMSG</p>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  };

  // Render Qualfication tab for mobile
  renderQualificationViewMobile = () => {
    return (
      <div
        content={copy.availability.qualification}
        taburl={copy.taburls.qualification}
      >
        {this.state.pagingGroup !== null && (
          <QualificationView
            userPagingGroupsOption={this.state.userPagingGroupsOption}
            pagingGroup={this.state.defaultPagingGroup}
            noPagingGroups={
              this.state.userPagingGroupsOptionNoAV &&
              this.state.userPagingGroupsOptionNoAV.length === 0
            }
            selectPagingGroup={this.selectPagingGroup}
          />
        )}
      </div>
    );
  };

  // Render Type tab for mobile
  renderTypeViewMobile = () => {
    return (
      <div content={copy.availability.type} taburl={copy.taburls.type}>
        {this.state.pagingGroup !== null && (
          <TypeView
            userPagingGroupsOption={this.state.userPagingGroupsOption}
            pagingGroup={this.state.defaultPagingGroup}
            selectPagingGroup={this.selectPagingGroup}
            noPagingGroups={
              this.state.userPagingGroupsOptionNoAV &&
              this.state.userPagingGroupsOptionNoAV.length === 0
            }
          />
        )}
      </div>
    );
  };

  renderDashboardViewMobile = () => {
    return (
      <div
        content={copy.availability.dashboard}
        taburl={copy.taburls.threshold}
      >
        <div className="col-one-third">
          <div className={styles.mobileDropdownHolder}>
            <div
              className={
                styles.dropdownHolder + " " + styles.absolute + " cardBg2"
              }
            >
              <span className={styles.title}>REVIEW AVAILABILITY OF</span>
              {this.state.pagingGroup !== null ? (
                <Dropdown
                  placeholder="Paging Group"
                  fluid
                  selection
                  search
                  options={
                    this.state.onQualificationsTab
                      ? this.state.userPagingGroupsOptionNoAV
                      : this.state.userPagingGroupsOption
                  }
                  onChange={(e, { value }) => this.selectPagingGroup(value)}
                  defaultValue={
                    this.state.defaultPagingGroup
                      ? this.state.defaultPagingGroup
                      : this.state.userPagingGroupsOption[0].value
                  }
                />
              ) : (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Loader />
                </div>
              )}
            </div>
            <div className={styles.refreshButtonWrapper}>
              <Button
                label="Refresh"
                variant="btn_solid"
                styles="greyBg"
                icon="icon_refresh"
                buttonClick={this.handleRefresh}
              />
            </div>
          </div>
          <AvailabilityDashboard
            pagingGroup={this.state.pagingGroup}
            pagingGroupAgency={
              this.state.defaultPagingGroup
                ? this.state.defaultPagingGroup.agency
                : null
            }
            agency={this.state.pagingGroupAgency}
            date={this.state.callbackDate}
          />
        </div>
      </div>
    );
  };

  renderCalendarViewMobile = () => {
    return (
      <div content={copy.availability.calendar} taburl={copy.taburls.calendar}>
        <div className="col-one-third">
          <div className={styles.mobileDropdownHolder}>
            <div
              className={
                styles.dropdownHolder + " " + styles.absolute + " cardBg2"
              }
            >
              <span className={styles.title}>REVIEW AVAILABILITY OF</span>
              {this.state.pagingGroup !== null ? (
                <Dropdown
                  placeholder="Paging Group"
                  fluid
                  selection
                  search
                  options={
                    this.state.onQualificationsTab
                      ? this.state.userPagingGroupsOptionNoAV
                      : this.state.userPagingGroupsOption
                  }
                  onChange={(e, { value }) => this.selectPagingGroup(value)}
                  defaultValue={
                    this.state.defaultPagingGroup
                      ? this.state.defaultPagingGroup
                      : this.state.userPagingGroupsOption[0].value
                  }
                />
              ) : (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Loader />
                </div>
              )}
            </div>
            <div className={styles.refreshButtonWrapper}>
              <Button
                label="Refresh"
                variant="btn_solid"
                styles="greyBg"
                icon="icon_refresh"
                buttonClick={this.handleRefresh}
              />
            </div>
          </div>
          {this.state.results ? (
            <Wrapper>
              <div className="box_default">
                <div className={styles.AvailHeader}>
                  <h2>{this.state.date}</h2>
                  <Button
                    icon=""
                    label={copy.global.btnBack}
                    content={copy.global.btnBack}
                    variant={styles.btn_back}
                    styles="btn_secondary"
                    buttonClick={this.closeDetails}
                  />
                </div>
                <div className="Sub-Section-titles">
                  <h3>AT {this.state.details.start}</h3>
                  <span>
                    {this.state.results.available.length}
                    {copy.availability.AVAILABLE}
                  </span>
                  <span>
                    {this.state.results.unavailable.length}
                    {copy.availability.UNAVAILABLE}
                  </span>
                </div>
                {this.state.results.missingQualifications && (
                  <div
                    className={[
                      styles.missingQuail,
                      this.state.thresholdReached === true && styles.reached,
                      "lightHighlight",
                    ].join(" ")}
                  >
                    {this.state.thresholdReached === true ? (
                      <Wrapper>
                        <img
                          className={styles.icon}
                          src={MetIcon}
                          alt="Threshold Met"
                        />
                        <p>{copy.availability.metMsg}</p>
                      </Wrapper>
                    ) : (
                      <Wrapper>
                        {this.state.thresholdType === "ByPeople" ? (
                          <Wrapper>
                            <img
                              className={styles.icon}
                              src={NotMetIcon}
                              alt="Threshold Has Not been Met"
                            />
                            <p>
                              {this.state.thresholdByPeopleCount -
                                this.state.results.available.length}{" "}
                              members needed
                            </p>
                          </Wrapper>
                        ) : (
                          <Wrapper>
                            <img
                              className={styles.icon}
                              src={NotMetIcon}
                              alt="Threshold Has Not been Met"
                            />
                            <p>
                              {copy.availability.missingQual}
                              {this.renderMissingQual()}
                            </p>
                          </Wrapper>
                        )}
                      </Wrapper>
                    )}
                  </div>
                )}
                <ResultList
                  results={this.state.results}
                  timeblock={this.state.start}
                />
              </div>
            </Wrapper>
          ) : this.state.resultsLoading ? (
            <div className={styles.loadingContainer}>
              <Loader />
            </div>
          ) : (
            <div className="box_default">
              <div className={styles.AvailHeader}>
                {this.state.thresholdNotSet === true ? (
                  <Wrapper>
                    <h2>Availability threshold not set</h2>
                    <p>{copy.availability.thresholdNotSet}</p>
                  </Wrapper>
                ) : (
                  <Wrapper>
                    {this.state.thresholdType === "ByPeople" ? (
                      <Wrapper>
                        <h2>Availability by number of people</h2>
                        <p>
                          {this.state.thresholdByPeopleCount} members required
                          to meet threshold
                        </p>
                      </Wrapper>
                    ) : (
                      <Wrapper>
                        <h2>Availability by qualifications</h2>
                        {this.requiredList()}
                      </Wrapper>
                    )}
                  </Wrapper>
                )}
                <div className={styles.iconKey}>
                  <span>Icons below show if threshold is</span>
                  <span className={styles.Achieved}>Achieved</span> or
                  <span className={styles.NotAchieved}>
                    {copy.availability.notMet}
                  </span>
                  <div className={styles.updatesDesc}>
                    {copy.availability.thresholdUpdateDesc}
                  </div>
                </div>
              </div>

              {this.state.pagingGroup ? (
                <Calendar
                  type="threshold"
                  viewOnlyMode={true}
                  overviewParentCallback={this.calendarCallbackFunction}
                  id={this.state.pagingGroup}
                  selectedDate={this.state.callbackDate}
                />
              ) : (
                <p className="title">{copy.availability.thresholdMSG}</p>
              )}
            </div>
          )}
        </div>
      </div>
    );
  };

  // Render tabs for mobile
  renderTabsMobile = () => {
    return (
      <Tabs
        showSlider={true}
        activeTab={this.state.currentTab}
        activeTabNumber={this.state.currentTabNumber}
        clickOnTab={this.handleTabClick}
      >
        {this.renderDashboardViewMobile()}
        {this.renderCalendarViewMobile()}
      </Tabs>
    );

    // Old views:
    // // Get all agency's user is administrator of
    // let userRoleAgency = this.props.userRoleAgency.filter((role) => {
    //   return role.split("_")[0] === "Administrator";
    // });
    // // If the user is only part of AV or is not a superuser and only an admin of AV, then they cannot see the Qualification tab
    // if (
    //   (this.props.userAgencies.length === 1 &&
    //     this.props.userAgencies.indexOf("AV") !== -1) ||
    //   (this.props.userRoleAgency.indexOf("SuperUser") === -1 &&
    //     userRoleAgency === ["Administrator_AV"])
    // ) {
    //   return (
    //     <Tabs
    //       showSlider={true}
    //       activeTab={this.state.currentTab}
    //       activeTabNumber={this.state.currentTabNumber}>
    //       {this.renderThresholdViewMobile()}
    //       {this.renderTypeViewMobile()}
    //     </Tabs>
    //   );
    // } else {
    //   return (
    //     <Tabs
    //       showSlider={true}
    //       activeTab={this.state.currentTab}
    //       activeTabNumber={this.state.currentTabNumber}>
    //       {this.renderThresholdViewMobile()}
    //       {this.renderQualificationViewMobile()}
    //       {this.renderTypeViewMobile()}
    //     </Tabs>
    //   );
    // }
  };

  // Render the Threshold tab
  renderThresholdView = () => {
    let divStyling = this.state.currentTab;
    return (
      <div
        content={copy.availability.threshold}
        taburl={copy.taburls.threshold}
        variant="btn_solid"
      >
        {this.state.userPagingGroupsOptionNoAV &&
        this.state.userPagingGroupsOptionNoAV.length === 0 ? (
          this.state.pagingGroup !== null ? (
            <div className={divStyling}>
              <Wrapper>
                <ErrorBanner
                  isVisible={this.state.errorMessage ? true : false}
                  ErrorMessage={this.state.errorMessage}
                />
                <h4>
                  <span className="noMessages">
                    {copy.availability.noPagingGroupsWithAvailability}
                  </span>
                </h4>
              </Wrapper>
            </div>
          ) : null
        ) : (
          <Wrapper>
            <div className={styles.AvailHeader}>
              <div className={styles.holder}>
                <div className={styles.HeadingHolder}>
                  {/* // TODO: Remove after markup is ready */}
                  {/* {this.state.thresholdNotSet === true ? (
                <h2>Availability threshold not set</h2>
              ) : (
                <Wrapper>
                  {this.state.thresholdType === "ByPeople" ? (
                    <h2>{copy.availability.thresholdByPeople}</h2>
                  ) : (
                    <h2>{copy.availability.thresholdByQuals}</h2>
                  )}
                </Wrapper>
              )} */}

                  <div className={styles.iconKey} style={{ marginLeft: "40%" }}>
                    Icons below show if threshold is
                    <span className={styles.Achieved}>Achieved</span> or
                    <span className={styles.NotAchieved}>
                      {copy.availability.notMet}
                    </span>
                    <div className={styles.updatesDesc}>
                      {copy.availability.thresholdUpdateDesc}
                    </div>
                  </div>
                </div>
                {/* // TODO: Remove after markup is ready */}
                {/* {this.state.pagingGroup !== null && (
              <Wrapper>
                {this.state.thresholdNotSet === true ? (
                  <p>{copy.availability.thresholdNotSet}</p>
                ) : (
                  <Wrapper>
                    {this.state.thresholdType === "ByPeople" ? (
                      <p>
                        {this.state.thresholdByPeopleCount +
                          " " +
                          copy.availability.required}
                      </p>
                    ) : (
                      <Wrapper>{this.requiredList()}</Wrapper>
                    )}
                  </Wrapper>
                )}
              </Wrapper>
            )} */}
              </div>
              <div className={styles.refreshButtonWrapper}>
                <Button
                  label="Refresh"
                  content={copy.global.btnRefresh}
                  variant="btn_solid"
                  styles="greyBg"
                  icon="icon_refresh"
                  buttonClick={this.handleRefresh}
                />
              </div>
            </div>
            <div className={divStyling}>
              <div className="col-one-third" style={{ overflowY: "auto" }}>
                <div className="box_default">
                  <div className="minheight">
                    {this.state.pagingGroup ? (
                      <Calendar
                        type="threshold"
                        viewOnlyMode={true}
                        overviewParentCallback={this.calendarCallbackFunction}
                        id={this.state.pagingGroup}
                        selectedDate={this.state.callbackDate}
                      />
                    ) : (
                      <p className="title">{copy.availability.thresholdMSG}</p>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-two-third">
                <AvailabilityDashboard
                  pagingGroup={this.state.pagingGroup}
                  pagingGroupAgency={this.state.defaultPagingGroup.agency}
                  agency={this.state.pagingGroupAgency}
                  date={this.state.callbackDate}
                />
              </div>

              {/* // TODO: Remove after markup is ready */}
              {/*
          <div className="col-one-third">
            <div className={styles.availabilityColumn}>
              {this.state.day !== null ? (
                <div>
                  <div className="Sub-Section-titles">
                    <h3>{this.state.date}</h3>
                  </div>
                  <div className="maxheight">
                    <HalfHrView
                      overview={false}
                      times={this.state.day}
                      date={this.state.date}
                      parentCallback={this.dayCallbackFunction}
                      target={this.state.details.start}
                    />
                  </div>
                </div>
              ) : this.state.dayLoading ? (
                <div className={styles.loadingContainer}>
                  <Loader />
                </div>
              ) : null}
            </div>
          </div>

          <div className="col-one-third">
            <div className={styles.availabilityColumn}>
              {this.state.results ? (
                <Wrapper>
                  <div className="Sub-Section-titles">
                    <h3>AT {this.state.details.start}</h3>

                    <span>
                      {this.state.results.available.length}
                      {copy.availability.AVAILABLE}
                    </span>
                    <span>
                      {this.state.results.unavailable.length}
                      {copy.availability.UNAVAILABLE}
                    </span>
                  </div>
                  {this.state.thresholdNotSet === false && (
                    <div
                      className={[
                        styles.missingQuail,
                        "lightHighlight",
                        this.state.thresholdReached === true && styles.reached,
                      ].join(" ")}>
                      {this.state.thresholdReached === true ? (
                        <Wrapper>
                          <img
                            className={styles.icon}
                            src={MetIcon}
                            alt="Threshold Met"
                          />
                          <p>{copy.availability.metMsg}</p>
                        </Wrapper>
                      ) : (
                        <Wrapper>
                          {this.state.thresholdType === "ByPeople" ? (
                            <Wrapper>
                              <img
                                className={styles.icon}
                                src={NotMetIcon}
                                alt="Threshold Has Not been Met"
                              />
                              <p>
                                {this.state.thresholdByPeopleCount -
                                  this.state.results.available.length}{" "}
                                members required
                              </p>
                            </Wrapper>
                          ) : (
                            <Wrapper>
                              <img
                                className={styles.icon}
                                src={NotMetIcon}
                                alt="Threshold Has Not been Met"
                              />
                              <p>
                                {copy.availability.missingQual}
                                {this.renderMissingQual()}
                              </p>
                            </Wrapper>
                          )}
                        </Wrapper>
                      )}
                    </div>
                  )}

                  <div className="maxheight">
                    <ResultList
                      results={this.state.results}
                      timeblock={this.state.start}
                    />
                  </div>
                </Wrapper>
              ) : this.state.resultsLoading ? (
                <div className={styles.loadingContainer}>
                  <Loader />
                </div>
              ) : null}
            </div>
          </div>
          */}
            </div>
          </Wrapper>
        )}
      </div>
    );
  };

  // Render Qualifcation tab
  renderQualificationView = () => {
    return (
      <div
        content={copy.availability.qualification}
        taburl={copy.taburls.qualification}
        variant="btn_solid"
      >
        {this.state.pagingGroup !== null && (
          <QualificationView
            pagingGroup={this.state.pagingGroup}
            noPagingGroups={
              this.state.userPagingGroupsOptionNoAV &&
              this.state.userPagingGroupsOptionNoAV.length === 0
            }
          />
        )}
      </div>
    );
  };

  // Render the Type tab
  renderTypeView = () => {
    return (
      <div
        content={copy.availability.type}
        taburl={copy.taburls.type}
        variant="btn_solid"
      >
        {this.state.pagingGroup !== null && (
          <TypeView
            pagingGroup={this.state.pagingGroup}
            noPagingGroups={
              this.state.userPagingGroupsOptionNoAV &&
              this.state.userPagingGroupsOptionNoAV.length === 0
            }
          />
        )}
      </div>
    );
  };

  // Render Availablity Overview tab (Multi Group Overview tab on the app)
  renderAvailabilityOverview = () => {
    return (
      <div
        content={copy.availability.overview}
        taburl={copy.taburls.overview}
        variant="btn_solid"
      >
        {this.state.pagingGroup !== null && (
          <AvailabilityOverview
            // dayOverview={this.state.dayOverview}
            // thresholdWeek={this.state.thresholdWeek}
            // thresholdMultiWeek={this.state.thresholdMultiWeek}
            userPagingGroupsOption={this.state.userPagingGroupsOption}
            noPagingGroups={
              this.state.userPagingGroupsOptionNoAV &&
              this.state.userPagingGroupsOptionNoAV.length === 0
            }
          />
        )}
      </div>
    );
  };

  /**
   * Rendering of tab content
   * If a user is only part of AV, then they will not see the Qualifications tab
   */
  renderTabs = () => {
    // Get all agency's user is administrator of
    let userRoleAgency = this.props.userRoleAgency.filter((role) => {
      return role.split("_")[0] === "Administrator";
    });

    // If the user is only part of AV or is not a superuser and only an admin of AV, then they cannot see the Qualification tab
    if (
      (this.props.userAgencies.length === 1 &&
        this.props.userAgencies.indexOf("AV") !== -1) ||
      (this.props.userAgencies.length === 2 &&
        this.props.userAgencies.indexOf("AV") !== -1 &&
        this.props.userAgencies.indexOf("DYNAMIC") !== -1) ||
      (this.props.userRoleAgency.indexOf("SuperUser") === -1 &&
        userRoleAgency === ["Administrator_AV"])
    ) {
      return (
        <Tabs
          showSlider={false}
          activeTab={this.state.currentTab}
          activeTabNumber={this.state.currentTabNumber}
        >
          {this.renderThresholdView()}
          {/* {this.renderTypeView()} */}
          {this.renderAvailabilityOverview()}
        </Tabs>
      );
    } else {
      return (
        <Tabs
          showSlider={false}
          activeTab={this.state.currentTab}
          activeTabNumber={this.state.currentTabNumber}
        >
          {this.renderThresholdView()}
          {/* {this.renderQualificationView()}
          {this.renderTypeView()} */}
          {this.renderAvailabilityOverview()}
        </Tabs>
      );
    }
  };

  render() {
    const isMobile = this.state.width <= 1023;
    if (isMobile) {
      return (
        <Wrapper>
          <ErrorBanner
            isVisible={this.state.errorMessage ? true : false}
            ErrorMessage={this.state.errorMessage}
            onTop={true}
          />
          <div
            className={
              "main-content-holder availability " + styles.availabilityWrapper
            }
          >
            {this.renderTabsMobile()}
          </div>
        </Wrapper>
      );
      // END THE MOBILE VERSION
    } else {
      // START THE DESKTOP VERSION
      return (
        <Wrapper>
          <ErrorBanner
            isVisible={this.state.errorMessage ? true : false}
            ErrorMessage={this.state.errorMessage}
            onTop={true}
          />
          <div
            className={
              "main-content-holder columnTab availability  " +
              styles.availabilityWrapper
            }
          >
            {this.state.currentTab === "Threshold" && (
              <div
                className={
                  styles.dropdownHolder + " " + styles.absolute + " cardBg2"
                }
              >
                <span className={styles.title}>REVIEW AVAILABILITY OF</span>
                {this.state.pagingGroup !== null ? (
                  <Dropdown
                    placeholder="Paging Group"
                    fluid
                    selection
                    search
                    options={
                      this.state.onQualificationsTab
                        ? this.state.userPagingGroupsOptionNoAV
                        : this.state.userPagingGroupsOption
                    }
                    onChange={(e, { value }) => this.selectPagingGroup(value)}
                    defaultValue={
                      this.state.defaultPagingGroup
                        ? this.state.defaultPagingGroup
                        : this.state.userPagingGroupsOption[0].value
                    }
                  />
                ) : (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Loader />
                  </div>
                )}
              </div>
            )}
            {this.renderTabs()}
          </div>
        </Wrapper>
      );
    }
  }
}
