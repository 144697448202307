import React from "react";

class DefaultHeader extends React.Component {
  // This is a where you would define a js function needed for the component. @SM
  randomTestFunctionForThisComponent() {
    // This is an example only. Can be deleted.
  }

  render() {
    return <div></div>;
  }
}

export default DefaultHeader;
