import React from "react";
import { Marker } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";
import L from "leaflet";

export default class AttendanceLegend extends React.Component {
  constructor(props) {
    super(props);
    this.createIcon = this.createIcon.bind(this);
  }

  createIcon(index) {
    return new L.divIcon({
      className: "greenBg icon_round border",
      iconSize: new L.Point(28, 28),
      iconAnchor: new L.Point(14, 28),
      popupAnchor: new L.Point(0, -33),
      html: index + 1,
    });
  }

  isBrowserIE() {
    let ua = navigator.userAgent;
    /* MSIE used to detect old browsers and Trident used to newer ones*/
    var is_ie = ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;

    return is_ie;
  }

  createPeopleClusterIcon(cluster) {
    return new L.divIcon({
      className: "marker-cluster-people greenBg",
      iconSize: new L.Point(44, 44),
    });
  }

  render() {
    // Render real UI, with Marker Clustering only for browsers without IE...
    let currentBrowserIsIE = this.isBrowserIE();
    if (this.props.attendees) {
      if (currentBrowserIsIE) {
        return (
          <div>
            {this.props.attendees.map((item, index) => {
              if (item.userLocation !== null) {
                if (item.userLocation.geometry.coordinates) {
                  return (
                    <Marker
                      icon={this.createIcon(index)}
                      position={item.userLocation.geometry.coordinates}
                    />
                  );
                } else {
                  return null;
                }
              } else {
                return null;
              }
            })}
          </div>
        );
      } else {
        return (
          <MarkerClusterGroup
            iconCreateFunction={this.createPeopleClusterIcon}
            showCoverageOnHover={false}>
            {this.props.attendees.map((item, index) => {
              if (item.userLocation !== null) {
                if (item.userLocation.geometry.coordinates) {
                  return (
                    <Marker
                      key={index}
                      icon={this.createIcon(index)}
                      position={item.userLocation.geometry.coordinates}
                    />
                  );
                } else {
                  return null;
                }
              } else {
                return null;
              }
            })}
          </MarkerClusterGroup>
        );
      }
    } else {
      return null;
    }
  }
}
